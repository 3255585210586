/**
 * クラス名：板金の加工速度画面
 * 作成者：チュー
 * 作成日：2023/06/21
 * バージョン：1.0
 */
import React, { useEffect, forwardRef, useState, useRef } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Col, Input, Space, Button, Popover } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { isEmpty } from '@rc-component/mini-decimal/es/numberUtil';
import { commonModal } from '../../../common/CommonModal';
import {
  deleteListColData,
  getInputBorderStyle,
  getListItemByCustomData,
  handleKeyPress,
  isDigit,
  isDigitZenkaku,
  regexExp,
  sortListByMasterData,
  sortListData,
  toHalfWidth,
  updateList,
  updateListColData,
  updateSizeList,
} from '../../../common/Common';
const Device_KakouSokudo = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);

  const [isLength, setIsLength] = useState(false);
  const [isItaatsu, setIsItaatsu] = useState(false);

  const [itaatsuData, setItaatsuData] = useState([]);
  const [lengthData, setLengthData] = useState([]);
  const [detailsLengthTemp, setDetailsLengthTemp] = useState([]);
  const [detailsItaatsuTemp, setDetailsItaatsuTemp] = useState([]);
  const [detailsList, setDetailsList] = useState([]);

  const [errIndexVal, setErrIndexVal] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const inputRef = useRef([]);
  useEffect(() => {
    setErrIndexVal([]);
    setErrorMessage('');
    setEditMode(props.editMode);

    if (props?.detailsData?.workSpeedThickThMaster) {
      setItaatsuData(props?.detailsData?.workSpeedThickThMaster);
      setDetailsItaatsuTemp(props?.detailsData?.workSpeedThickThMaster);
    }
    if (props?.detailsData?.workSpeedLenThMaster) {
      setLengthData(props?.detailsData?.workSpeedLenThMaster);
      setDetailsLengthTemp(props?.detailsData?.workSpeedLenThMaster);
    }

    if (props?.detailsData?.workSpeedItems) {
      setDetailsList(props?.detailsData?.workSpeedItems);
    }
  }, [props.editMode, props.detailsData]);
  const addlength = () => {
    setErrIndexVal([]);
    setErrorMessage('');
    setIsLength(true);
  };
  const addItaatsu = () => {
    setErrIndexVal([]);
    setErrorMessage('');
    setIsItaatsu(true);
  };
  const addLengthModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>mm</p>
    </div>
  );
  const addItaatsuModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>板厚</p>
    </div>
  );
  const addData = () => {
    setIsUpdateData(true);
  };

  const addLengthRow = (id, index) => {
    inputRef.current[index].focus();
    const copyData = detailsLengthTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsLengthTemp?.findIndex((item) => item.no === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: detailsLengthTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsLengthTemp(copyData);
    }
  };
  const deleteLength = (id, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);
    if (detailsLengthTemp?.filter((item) => item.isOutOfRange === false)?.length > 2) {
      const updatedData = detailsLengthTemp?.map((row) => {
        if (row.no === id) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setDetailsLengthTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };
  const deleteItaatsu = (id, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);
    if (detailsItaatsuTemp?.filter((item) => item.isOutOfRange === false)?.length > 2) {
      const updatedData = detailsItaatsuTemp?.map((row) => {
        if (row.no === id) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setDetailsItaatsuTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };
  const addItaatsuRow = (id, index) => {
    inputRef.current[index].focus();
    const copyData = detailsItaatsuTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsItaatsuTemp?.findIndex((item) => item.no === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: detailsItaatsuTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsItaatsuTemp(copyData);
    }
  };
  const closeAddModal = () => {
    if (isLength === true) {
      setIsLength(false);
    } else if (isItaatsu === true) {
      setIsItaatsu(false);
    }
  };
  const handleLengthChange = (event, id, field) => {
    if (regexExp.test(event.target.value) && !isDigitZenkaku(event.target.value)) return;
    const updatedData = detailsLengthTemp?.map((row) => {
      if (row.no === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsLengthTemp(updatedData);
  };
  const handleItaatsuChange = (event, id, field) => {
    if (regexExp.test(event.target.value) && !isDigitZenkaku(event.target.value)) return;
    const updatedData = detailsItaatsuTemp?.map((row) => {
      if (row.no === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsItaatsuTemp(updatedData);
  };
  const cancelLengthMaster = () => {
    setDetailsLengthTemp(lengthData);
    setIsLength(false);
  };
  const addItaatsuMaster = () => {
    const deletedId = [];

    itaatsuData?.forEach((item1, index) => {
      const found = detailsItaatsuTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange === true);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = detailsItaatsuTemp
      ?.filter((item) => !item.isOutOfRange && !itaatsuData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    // const updatedList = updateList(detailsList, deletedId, newId);
    // const sortedList = sortListByMasterData(updatedList, detailsItaatsuTemp);
    const deleteData = detailsList?.map((item) => {
      return {
        ...item,
        itaatsuCoeffList: deleteListColData(item.itaatsuCoeffList, deletedId),
      };
    });

    const updatedData = deleteData?.map((item) => {
      newId?.forEach((key) => {
        // Add new objects to lstData
        item.itaatsuCoeffList.push({
          no: key,
          value: 0,
          isOutOfRange: false,
        });
      });
      return item;
    });

    const sortedList = updatedData?.map((item) => {
      const sortLstData = [...item.itaatsuCoeffList];
      const sortedLstData = sortListData(sortLstData, detailsItaatsuTemp);
      return {
        ...item,
        itaatsuCoeffList: sortedLstData,
      };
    });
    setIsItaatsu(false);
    setItaatsuData(detailsItaatsuTemp);

    setDetailsList(sortedList);
    props.updatedItaatsuData(detailsItaatsuTemp);

    props.updateShudobariKakousokudoList(sortedList);
  };

  const cancelWeightMaster = () => {
    setDetailsItaatsuTemp(itaatsuData);
    setIsItaatsu(false);
  };
  const addLengthMaster = () => {
    const deletedId = [];

    lengthData?.forEach((item1, index) => {
      const found = detailsLengthTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange === true);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = detailsLengthTemp
      ?.filter((item) => !item.isOutOfRange && !lengthData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    const customItem = { lengthId: 0, itaatsuCoeffList: getListItemByCustomData(lengthData) };

    // const updatedList = updateSizeList(detailsList, deletedId, newId);
    const deleteData = deleteListColData(detailsList, deletedId, false, 'lengthId');
    const updatedData = updateListColData(deleteData, newId, false, { ...customItem }, 'lengthId');
    const result = sortListData(updatedData, detailsLengthTemp, false, 'lengthId');

    setIsLength(false);
    setLengthData(detailsLengthTemp);
    props.updateWorkSpeedLenThMaster(detailsLengthTemp);
    setDetailsList(result);
    props.updateShudobariKakousokudoList(result);
  };

  const isValueOrderCorrect = (e, no, field, tblName) => {
    let temp = [];
    if (tblName === 'mm') {
      temp = detailsLengthTemp?.filter((i) => i.isOutOfRange === false);
    } else {
      temp = detailsItaatsuTemp?.filter((i) => i.isOutOfRange === false);
    }

    const insertIndex = temp?.findIndex((item) => item.no === no);
    if (regexExp.test(temp[insertIndex].value) && !isDigitZenkaku(temp[insertIndex].value)) return;
    // IQX_WEBEST-220の対応-START
    if (regexExp.test(temp[insertIndex].value)) {
      // 全角の場合、
      temp[insertIndex].value = toHalfWidth(temp[insertIndex].value); // 半角へ変換
      e.target.value = temp[insertIndex].value;
      tblName === 'mm' ? handleLengthChange(e, no, field) : handleItaatsuChange(e, no, field);
    }
    if (isNaN(parseFloat(temp[insertIndex].value))) {
      setErrIndexVal([...errIndexVal, insertIndex]);
      setErrorMessage('数字値を入力してください！');
      return;
    }
    // IQX_WEBEST-220の対応-END

    if (insertIndex > 0) {
      const prevValue = parseFloat(temp[insertIndex - 1].value);
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);
      if (currentValue > prevValue && currentValue < nextValue) {
        let temp = [insertIndex - 1, insertIndex, insertIndex + 1];

        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex && item != insertIndex - 1));

        setErrorMessage('前のしきい値より大きい値を入力してください！');
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);

        if (currentValue <= prevValue) {
          setErrorMessage('前のしきい値より大きい値を入力してください！');
        } else if (currentValue >= nextValue) {
          setErrorMessage('次のしきい値より小さい値を入力してください！');
        }
      }
    } else {
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);

      if (currentValue < nextValue) {
        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex));
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);
        setErrorMessage('次のしきい値より小さい値を入力してください！');
      }
    }
  };
  const addLengthContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsLengthTemp
                  ?.filter((subitem) => subitem.isOutOfRange === false)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleLengthChange(e, item.no, 'value')}
                          onBlur={(e) => isValueOrderCorrect(e, item.no, 'value', 'mm')}
                          onPressEnter={(e) => isValueOrderCorrect(e, item.no, 'value', 'mm')}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, detailsLengthTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addLengthRow(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteLength(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={addLengthMaster}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelLengthMaster}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  const addItaatsuContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsItaatsuTemp
                  ?.filter((subitem) => subitem.isOutOfRange === false)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleItaatsuChange(e, item.no, 'value')}
                          onBlur={(e) => isValueOrderCorrect(e, item.no, 'value', 'itaatsu')}
                          onPressEnter={(e) => isValueOrderCorrect(e, item.no, 'value', 'itaatsu')}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, detailsItaatsuTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addItaatsuRow(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteItaatsu(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={addItaatsuMaster}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelWeightMaster}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  const listDataChange = (value, wId, no, isCheckZenkaku = true) => {
    if (regexExp.test(value) && !isDigitZenkaku(value)) return;
    if (isCheckZenkaku && regexExp.test(value)) {
      // 全角の場合、
      value = toHalfWidth(value); // 半角へ変換
      if (!isDigit(value)) value = 0;
    }
    // const newList = { ...detailsList };
    // const itemIndex = newList[key]?.findIndex((item) => item.no === no);

    // if (itemIndex !== -1) {
    //   newList[key][itemIndex].value = event.target.value;
    //   setDetailsList(newList);
    //   props.updateShudobariKakousokudoList(newList);
    // }
    const copyData = [...detailsList];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData?.map((list) => {
      if (list?.lengthId === wId) {
        list?.itaatsuCoeffList?.map((data) => {
          if (data.no === no) {
            data.value = parseInt(value) > 0 ? value : 1;
          }
        });
      }
    });
    setDetailsList(updatedData);
    props.updateShudobariKakousokudoList(updatedData);
  };
  return (
    <>
      <div className="table-container" style={{ marginLeft: 10, marginRight: 10 }}>
        <Table
          style={{
            width:
              itaatsuData?.filter((i) => i.isOutOfRange === false)?.length > 8
                ? '100%'
                : itaatsuData?.filter((i) => i.isOutOfRange === false)?.length * 98 + 196 + 'px',
          }}
        >
          <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
            <td style={{ width: '196px' }} className="fixedCol">
              <Row justify="center" className="tbl-header">
                <Space>
                  {editMode ? (
                    <>
                      <Button className="cancleButton" onClick={addlength} style={{ pointerEvents: 'auto' }}>
                        mm
                      </Button>
                      /
                      <Button className="cancleButton" onClick={addItaatsu} style={{ pointerEvents: 'auto' }}>
                        板厚
                      </Button>
                    </>
                  ) : (
                    <>
                      <label>mm / 板厚</label>
                    </>
                  )}
                </Space>
              </Row>
            </td>

            {itaatsuData
              ?.filter((subitem) => subitem.isOutOfRange === false)
              ?.map((item, index) => (
                <td key={index} style={{ backgroundColor: '#1063aa', width: '98px' }}>
                  ～{item.value}t {/**IQX_WEBEST-269*/}
                </td>
              ))}
          </tr>

          {/* {Object.keys(detailsList)?.map((key) => (
            <tr key={`${key}`} style={{ textAlign: 'center' }}>
              <td style={{ color: 'white', width: '196px' }} className="tbl-header fixedCol">
           
                {lengthData?.find((item) => item.no === parseInt(key))?.value}
              </td>
              {detailsList[key]?.map((item) => (
                <td style={{ verticalAlign: 'middle', textAlign: 'center', width: 210 }}>
                  <Input
                    value={item.value}
                    onChange={(e) => listDataChange(e, key, item.no)}
                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                  />
                </td>
              ))}
            </tr>
          ))} */}
          {detailsList?.map((row, index) => (
            <tr key={index} style={{ textAlign: 'center' }}>
              <td style={{ width: '196px' }} className="tbl-header fixedCol">
                {lengthData?.filter((wItem) => wItem.no === row?.lengthId)?.[0]?.value}mm {/**IQX_WEBEST-269*/}
              </td>

              {row?.itaatsuCoeffList?.map((col, index) => (
                <td key={index} style={{ width: '98px' }}>
                  <Input
                    value={col.value}
                    onChange={(e) => listDataChange(e.target.value, row?.lengthId, col.no, false)}
                    onBlur={(e) => listDataChange(e.target.value, row?.lengthId, col.no)}
                    onPressEnter={(e) => listDataChange(e.target.value, row?.lengthId, col.no)}
                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                  />
                </td>
              ))}
            </tr>
          ))}
        </Table>
      </div>
      {/* 加工長 */}
      {isLength
        ? commonModal(
            isLength,
            addLengthModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addLengthContent,
            null,
            null,
            true
          )
        : ''}
      {/* 板厚 */}
      {isItaatsu
        ? commonModal(
            isItaatsu,
            addItaatsuModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addItaatsuContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default Device_KakouSokudo;
