/**
 * クラス名：担当者パラメータ
 * 説明：担当者情報詳細画面
 * 作成者：ケイ
 * 作成日：2023/05/01
 * バージョン：1.0
 */
import React, { Component, useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { Modal, Row, Col, Divider, Button, Radio, Image, Form, DatePicker, Input } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { WithRouter } from '../../../../components/WithRouter';
import { useLocation, useNavigate } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {
  confirmDiscardContent,
  confirmUpdateContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  error,
} from '../../../common/CommonModal';
import {
  today,
  formatDate,
  isEmail,
  isDigit,
  isTelAndFax,
  formValidatorMode,
  TextArea,
  regexExp,
  toHalfWidth,
} from '../../../common/Common';
import checked from '../../../../assets/images/checked.png';
import { ErrorMessage } from '../../../common/Message';
import { getIsExistCodeName } from '../../../common/CommonAPI';

const RadioGroup = Radio.Group;
dayjs.extend(customParseFormat);
const dateFormat = 'YYYY/MM/DD';

const PersonInChargeDetails = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    discardConfirmOk: discardConfirmOk,
    discardConfirmCancel: discardConfirmCancel,
    displayDetailInfo: displayDetailInfo,
    formValidation: formValidation,
  }));

  const inputItemRef = {};

  const editMode = props.editMode;

  const [id, setID] = useState('');
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [isUsed, setIsUsed] = useState();
  const [nameKana, setNameKana] = useState('');
  const [department, setDepartment] = useState('');
  const [tel, setTel] = useState('');
  const [fax, setFax] = useState('');
  const [mail, setMail] = useState('');
  const [remarks, setRemarks] = useState('');
  const [sortNum, setSortNum] = useState();
  const [created, setCreated] = useState();
  const [modified, setModified] = useState('');
  const [modifier, setModifier] = useState('');

  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isNoData, setIsNoData] = useState(false);

  useEffect(() => {
    let selectedRowData = props.selectedData;
    if (selectedRowData == null) {
      document.getElementById('edit').style.display = 'none';
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
      setIsNoData(false);
    } else if (props.selectedData?.length === 0) {
      // 一覧の検索結果が無い場合、
      resetDetailInfo();
      setIsNoData(true);
      props.updateEditMode(false);
    } else if (id !== selectedRowData?.id) {
      // 一覧の検索結果がある場合、
      displayDetailInfo(selectedRowData);
      setIsNoData(false);
      props.updateEditMode(false);
    }
  }, [props.selectedData]);

  // 編集モードより、「更新、破壊、編集」ボタンの表示・非表示を判断する
  useEffect(() => {
    if (props.editMode) {
      document.getElementById('edit').style.display = 'none';
      document.getElementById('update').style.display = 'block';
      document.getElementById('discard').style.display = 'block';
    } else {
      document.getElementById('edit').style.display = 'block';
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
    }
  }, [props.editMode]);

  // 詳細情報をリセットする。
  const resetDetailInfo = () => {
    setID('');
    setCode('');
    setName('');
    setIsUsed();
    setNameKana('');
    setDepartment('');
    setTel('');
    setFax('');
    setMail('');
    setRemarks('');
    setSortNum('');
    setCreated('');
    setModified('');
  };

  // 詳細情報を表示する
  const displayDetailInfo = (data) => {
    setID(data.id);
    setCode(data.code);
    setName(data.name);
    setIsUsed(data.info.isUsed);
    setNameKana(data.info.nameKana);
    setDepartment(data.info.department);
    setTel(data.info.tel);
    setFax(data.info.fax);
    setMail(data.info.mail);
    setRemarks(data.info.remarks);
    setSortNum(data.info.sortNum);
    setCreated(data.created);
    setModified(data.modified);
    setModifier(data.modifier);
  };

  // 編集ボタンの押下
  const editData = () => {
    props.updateEditMode(true);
  };

  // 更新ボタンの押下
  const updateData = () => {
    setUpdateConfirm(props.showEdtConfirmOK);
    if (!props.showEdtConfirmOK) {
      updConfirmOk();
    }
    setIsUpdate(true);
  };

  // 画面の入力チェック
  const formValidation = async (inputItems, procMode) => {
    let ret = true;
    let message = [];
    // コード
    let input = inputItems['code']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('コード').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 名前
    input = inputItems['name']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('名前').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    let staffId = procMode === formValidatorMode.Create ? 0 : id;
    let code = inputItems['code']?.input?.value;
    let name = inputItems['name']?.input?.value;
    if (code !== '' && name !== '') {
      let isExistCodeName = await getIsExistCodeName(staffId, code, name);
      if (isExistCodeName) {
        message.push(<li>{ErrorMessage('コードと名前').E020}</li>);
        input = inputItems['code']?.input;
        input?.classList?.remove('input-error');
        input?.classList?.add('input-error');
        input = inputItems['name']?.input;
        input?.classList.add('input-error');
        ret = false;
      }
    }

    // Tel
    input = inputItems['tel']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length > 0 && !isTelAndFax(input?.value)) {
      message.push(<li>{ErrorMessage('Tel').E008}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // Fax
    input = inputItems['fax']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length > 0 && !isTelAndFax(input?.value)) {
      message.push(<li>{ErrorMessage('Fax').E008}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // メール
    input = inputItems['mail']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length > 0 && !isEmail(input?.value)) {
      message.push(<li>{ErrorMessage('メール').E008}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // ソート番号
    input = inputItems['sortNum']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length > 0 && !isDigit(input?.value)) {
      message.push(<li>{ErrorMessage('ソート番号').E009}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    if (message?.length > 0) error(<ul>{message}</ul>);

    return ret;
  };

  // 破壊ボタンの押下
  const discardData = () => {
    setCancelConfirm(props.showEdtConfirmCancle);
    if (!props.showEdtConfirmCancle) {
      discardConfirmOk();
    }
  };

  // 更新確認ポップアップのOKボタン押下
  const updConfirmOk = async (e) => {
    setUpdateConfirm(false);
    // 画面の入力チェック
    if (!(await formValidation(inputItemRef, formValidatorMode.Edit))) {
      return;
    }
    if (isUpdate) {
      props.updateEditMode(false);
      setIsUpdate(false);
      submitData();
    }
  };

  // 更新確認ポップアップのCancelボタン押下
  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  // 破壊確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    props.updateEditMode(false);
    displayDetailInfo(props.selectedData);
    setCancelConfirm(false);
  };

  // 破壊確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setCancelConfirm(false);
  };

  // 入力された情報を更新する
  const submitData = () => {
    if (props.selectedData && props.selectedData.id === id) {
      props.selectedData.code = code;
      props.selectedData.name = name;
      props.selectedData.info.isUsed = isUsed;
      props.selectedData.info.nameKana = nameKana;
      props.selectedData.info.department = department;
      props.selectedData.info.tel = tel;
      props.selectedData.info.fax = fax;
      props.selectedData.info.mail = mail;
      props.selectedData.info.remarks = remarks;
      props.selectedData.info.sortNum = sortNum;
      props.selectedData.created = created;
      props.selectedData.modified = today;
      setModified(today);
      props.submitData(props.selectedData);
    }
  };

  // ソート番号を変更した場合、
  const onChangeSortNum = (value, isCheckZenkaku = true) => {
    if (isCheckZenkaku && regexExp.test(value)) {
      // 全角の場合、
      value = toHalfWidth(value); // 半角へ変換
    }
    setSortNum(value);
  };

  return (
    <>
      <Form>
        {/* ボタン部分 */}
        <div className="d-flex" style={{ marginTop: 9, marginRight: 10 }}>
          <div>
            <Button className="mainButton" style={{ marginLeft: 10, display: 'none' }} id="update" onClick={updateData}>
              更新
            </Button>
          </div>
          <div>
            <Button
              className="cancelButton"
              style={{ marginLeft: 10, display: 'none' }}
              id="discard"
              onClick={discardData}
            >
              破棄
            </Button>
          </div>
          <div className="ms-auto">
            <Button className={isNoData ? 'disabledButton' : 'mainButton'} id="edit" onClick={editData}>
              編集
            </Button>
          </div>
        </div>
        {/* プロパティ表示 */}
        <div className="overflow-auto" style={{ height: 760, marginTop: 10 }}>
          <div id="showData">
            <Table id="param_detail_tbl" className="propertiesTbl">
              <thead>
                {/* コード */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">コード</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="code"
                      id="code"
                      value={code}
                      type="text"
                      ref={(ref) => {
                        inputItemRef['code'] = ref;
                      }}
                      maxLength={50}
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </td>
                </tr>
                {/* 名前 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">名前</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="name"
                      id="name"
                      value={name}
                      type="text"
                      ref={(ref) => {
                        inputItemRef['name'] = ref;
                      }}
                      maxLength={50}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </td>
                </tr>
                {/* カナ */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">カナ</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="nameKana"
                      id="nameKana"
                      value={nameKana}
                      type="text"
                      maxLength={50}
                      onChange={(e) => setNameKana(e.target.value)}
                    />
                  </td>
                </tr>
                {/* 部署 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">部署</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="department"
                      id="department"
                      value={department}
                      type="text"
                      maxLength={50}
                      onChange={(e) => setDepartment(e.target.value)}
                    />
                  </td>
                </tr>
                {/* Tel */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">Tel</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="tel"
                      id="tel"
                      value={tel}
                      type="text"
                      ref={(ref) => {
                        inputItemRef['tel'] = ref;
                      }}
                      maxLength={20}
                      onChange={(e) => setTel(e.target.value)}
                    />
                  </td>
                </tr>
                {/* Fax */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">Fax</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="fax"
                      id="fax"
                      value={fax}
                      type="text"
                      ref={(ref) => {
                        inputItemRef['fax'] = ref;
                      }}
                      maxLength={20}
                      onChange={(e) => setFax(e.target.value)}
                    />
                  </td>
                </tr>
                {/* メール */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">メール</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="mail"
                      id="mail"
                      value={mail}
                      type="text"
                      ref={(ref) => {
                        inputItemRef['mail'] = ref;
                      }}
                      maxLength={100}
                      onChange={(e) => setMail(e.target.value)}
                    />
                  </td>
                </tr>

                {/* ソート番号 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">ソート番号</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="sortNum"
                      id="sortNum"
                      value={sortNum}
                      type="text"
                      ref={(ref) => {
                        inputItemRef['sortNum'] = ref;
                      }}
                      maxLength={Number.MAX_SAFE_INTEGER}
                      onPressEnter={(e) => onChangeSortNum(e.target.value)}
                      onBlur={(e) => onChangeSortNum(e.target.value)}
                      onChange={(e) => onChangeSortNum(e.target.value, false)}
                    />
                  </td>
                </tr>
                {/* 作成日 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">作成日</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <label className="param_detail-title-label">{created ? formatDate(created) : ''}</label>
                  </td>
                </tr>
                {/* 更新日 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">更新日</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <label className="param_detail-title-label">{modified ? formatDate(modified) : ''}</label>
                  </td>
                </tr>
                {/* 更新者 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">更新者</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <label className="param_detail-title-label">{modifier}</label>
                  </td>
                </tr>
                {/* 備考 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">備考</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <TextArea
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="remarks"
                      id="remarks"
                      value={remarks}
                      type="text"
                      maxLength={2048}
                      style={{ margin: 5, padding: 5, width: '98.3%' }}
                      onChange={(e) => setRemarks(e.target.value)}
                    />
                  </td>
                </tr>

                {/* 表示 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">表示</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    {editMode ? (
                      <RadioGroup
                        name="isUsed"
                        defaultValue={isUsed}
                        className="radioCustomerGpEdit"
                        onChange={(e) => setIsUsed(e.target.value)}
                        value={isUsed}
                      >
                        <Radio value={true}>オン</Radio>
                        <Radio value={false}>オフ</Radio>
                      </RadioGroup>
                    ) : isUsed === true ? (
                      <Image preview={false} width={13} src={checked} id="imgOutsourcingFg" />
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              </thead>
            </Table>
          </div>
        </div>
      </Form>
      {/* 更新確認_Modal */}
      {updateConfirm
        ? commonModal(
            updateConfirm,
            confirmModalTitle,
            commonFooter(updConfirmOk, updConfirmCancel),
            null,
            400,
            updConfirmOk,
            updConfirmCancel,
            confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}
      {/* 破棄確認_Modal */}
      {cancelConfirm
        ? commonModal(
            cancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default PersonInChargeDetails;
