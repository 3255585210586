import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';

import { Row, Col, Space } from 'antd';
import { CaretUpOutlined, CaretDownFilled, CaretDownOutlined } from '@ant-design/icons';
import '../../assets/styles/common.css';
import ParentKouteiInput_Kumitate from './ParentKouteiInput_Kumitate';
import ParentKouteiInput_Kensa from './ParentKouteiInput_Kensa';
import ParentKouteiInput_Konpou from './ParentKouteiInput_Konpou';
import { commonTaiochuModal } from '../common/CommonModal';
import IQ3KouteiInput_Hyoumenshori from '../iq3/IQ3KouteiInput_Hyoumenshori';
import { WorkType } from '../common/enums';
import { toDecimal } from '../common/Common';

const surfaceData = [];

for (let i = 1; i < 10; i++) {
  surfaceData.push({
    key: i,
    parentname: `iQ3板金`,
    childname: `Test-${i}`,
    surface: '100',
    weight: 10,
    quality: 'SPCC',
    thick: 1.6,
  });
}

const yousetsuCondition = ['zerofour-02'];
const KouteiInput = forwardRef((props, ref) => {
  const [isHyoumenShori, setIsHyoumenShori] = useState(false);
  const [isAssembly, setIsAssembly] = useState(false);
  const [isPacking, setIsPacking] = useState(false);
  const [isSurvey, setIsSurvey] = useState(false);

  const [editMode, setEditMode] = useState(false);

  const [tblSurface, setTblSurface] = useState(0);
  const [version, setVersion] = useState('iq3');

  const [kumitateTbl, setKumitateTbl] = useState([]);
  const [kensaTbl, setKensaTbl] = useState([]);
  const [konpouTbl, setKonpouTbl] = useState([]);

  // 開発中ポップアップ
  const [openCommonTaiochuPopup, setOpenCommonTaiochuPopup] = useState(false);

  const [kouteiInputInfo, setKouteiInputInfo] = useState({});
  const [kouteiInputInfoTemp, setKouteiInputInfoTemp] = useState({});

  // 溶接
  const yousetsu = useRef();

  // 組立
  const kumitate = useRef();

  // 表面処理
  const hyoumenshori = useRef();

  // 検査
  const kensa = useRef();

  // 梱包
  const konpou = useRef();

  const [purchasesData, setPurchasesData] = useState([]);
  const [purchaseCategoryInfo, setPurchaseCategoryInfo] = useState([]);
  const [surfaceData, setSurfaceData] = useState([]);

  const [displaySurface, setDisplaySurface] = useState(false);
  const [displayAssenble, setDisplayAssenble] = useState(false);
  const [displayInspection, setDisplayInspection] = useState(false);
  const [displayPacking, setDisplayPacking] = useState(false);
  const [kouteiSentakuProcessesId, setKouteiSentakuProcessesId] = useState([]);

  useEffect(() => {
    let parameters = props?.selectedDataDetail?.estimateProducts?.calcParameters?.parameters;
    if (parameters?.purchaseCategory) {
      setPurchaseCategoryInfo(parameters?.purchaseCategory);
    }
    const surfaceDetails = parameters?.process?.filter((i) => i.workType === WorkType.PaSurface);
    setSurfaceData(surfaceDetails);

    let surfaceId = surfaceDetails?.[0]?.id;
    let assenbleId = parameters?.process?.filter((i) => i.workType === WorkType.PaAssenble)[0]?.id;

    let inspectionId = parameters?.process?.filter((i) => i.workType === WorkType.PaInspection)?.[0]?.id;
    let packingId = parameters?.process?.filter((i) => i.workType === WorkType.PaPacking)?.[0]?.id;

    setTblSurface(surfaceData);
    setKouteiInputInfoTemp(props.selectedDataDetail.estimateProducts?.processInput);
    // // setYouSetsuTbl1(yousetsu1Data1);//親部品工程から溶接工程を削除対応

    //親部品リストから呼び出した場合
    if (props?.selectedDataDetail?.estimateProducts != undefined) {
      let surface = props?.selectedDataDetail?.estimateProducts?.processSelect?.details?.filter(
        (i) => i.processId === surfaceId
      );
      let surfaceDataFromSelect = [];
      if (surface != undefined) {
        setDisplaySurface(surface?.[0]?.isUsedForCalc);
        surfaceDataFromSelect = surface?.[0]?.multiprocesses?.filter(
          (i) => i.processDetailGroup != 0 && i.processDetailTypeId != 0
        );
      }

      let assenble = props?.selectedDataDetail?.estimateProducts?.processSelect?.details?.filter(
        (i) => i.processId === assenbleId
      );
      if (assenble != undefined) {
        setDisplayAssenble(assenble?.[0]?.isUsedForCalc);
      }

      let inspection = props?.selectedDataDetail?.estimateProducts?.processSelect?.details?.filter(
        (i) => i.processId === inspectionId
      );
      if (inspection != undefined) {
        setDisplayInspection(inspection?.[0]?.isUsedForCalc);
      }

      let packing = props?.selectedDataDetail?.estimateProducts?.processSelect?.details?.filter(
        (i) => i.processId === packingId
      );
      if (packing != undefined) {
        setDisplayPacking(packing?.[0]?.isUsedForCalc);
      }

      if (parameters?.device?.length > 0) {
        let assenbleWorkBasicTime = 0;
        let inspectionWorkBasicTime = 0;
        let packingWorkBasicTime = 0;
        if (assenble != undefined) {
          assenbleWorkBasicTime = parameters?.device?.filter((i) => i.id === assenble?.[0]?.deviceId)?.[0]?.details
            .workBasicTime;
        }
        if (inspection != undefined) {
          inspectionWorkBasicTime = parameters?.device?.filter((i) => i.id === inspection?.[0]?.deviceId)?.[0]?.details
            .workBasicTime;
        }
        if (packing != undefined) {
          packingWorkBasicTime = parameters?.device?.filter((i) => i.id === packing?.[0]?.deviceId)?.[0]?.details
            ?.workBasicTime;
        }
        let result = [
          { assenbleTime: assenbleWorkBasicTime },
          { inspectionTime: inspectionWorkBasicTime },
          { packingTime: packingWorkBasicTime },
        ];
        setKouteiSentakuProcessesId(result);
      }
      setKouteiInputInfoTemp(props.selectedDataDetail?.estimateProducts?.processInput);

      let weight = props?.selectedDataDetail?.estimateProducts?.totalWeight;

      let area = props?.selectedDataDetail?.estimateProducts?.totalSurfaceArea;

      const data = props?.selectedDataDetail?.estimateProducts?.processInput?.surfaceProcessInputInfo;

      // IQX_WEBEST-226
      const surfaceDeviceDetails = parameters?.device?.filter((i) => i.id === surface?.[0]?.deviceId)?.[0]?.details;

      const result = surfaceDataFromSelect?.map((item1) => {
        const matchingData2 = data?.find((item2) => item2.no === item1.processNo);
        const unit = surfaceDeviceDetails?.charges[item1?.processDetailTypeId]?.unit; // IQX_WEBEST-226
        if (matchingData2) {
          return {
            ...matchingData2,
            calcType: unit !== undefined ? unit : 1, // IQX_WEBEST-226
            processDetailWorkTypeGroup: item1?.processDetailGroup,
            processDetailTypeNo: item1?.processDetailTypeId,
          };
        } else {
          return {
            no: item1.processNo,
            calcType: unit !== undefined ? unit : 1, // IQX_WEBEST-226
            processDetailWorkTypeGroup: item1?.processDetailGroup,
            processDetailTypeNo: item1?.processDetailTypeId,
            areaUnitPrice: toDecimal(area, 2),
            weightUnitPrice: toDecimal(weight, 2),
            weightCount: 1,
            weightCountAutoExtract: 1,
            areaCount: 1,
            areaCountAutoExtract: 1,
            areaAutoExtract: area,
            weightAutoExtract: weight,
            totalArea: toDecimal(area, 2) * 1,
            totalWeight: toDecimal(weight, 2) * 1,
          };
        }
      });

      props.selectedDataDetail.estimateProducts.processInput.surfaceProcessInputInfo = result ? result : [];
      setKouteiInputInfo(props?.selectedDataDetail?.estimateProducts?.processInput);

      setPurchasesData(props?.selectedDataDetail?.estimateProducts?.purchases);
    }
  }, [props.selectedDataDetail, props.activeTabKey, props.selectedDataDetail?.esIq3Info]);

  const openAll = () => {
    setIsHyoumenShori(true);
    setIsAssembly(true);
    setIsSurvey(true);
    setIsPacking(true);
  };
  const closeAll = () => {
    setIsHyoumenShori(false);
    setIsAssembly(false);
    setIsSurvey(false);
    setIsPacking(false);
  };

  const hyoumenShori = () => {
    if (isHyoumenShori === true) {
      setIsHyoumenShori(false);
    } else {
      setIsHyoumenShori(true);
    }
  };
  const assembly = () => {
    if (isAssembly === true) {
      setIsAssembly(false);
    } else {
      setIsAssembly(true);
    }
  };
  const packing = () => {
    if (isPacking === true) {
      setIsPacking(false);
    } else {
      setIsPacking(true);
    }
  };
  const survey = () => {
    if (isSurvey === true) {
      setIsSurvey(false);
    } else {
      setIsSurvey(true);
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      getKouteiInputInfo: () => {
        return kouteiInputInfo;
      },
    }),
    [kouteiInputInfo]
  );
  // 開発中ポップアップ
  const closeCommonTaiochuPopup = () => {
    setOpenCommonTaiochuPopup(false);
  };
  // 呼出
  const commonTaiochuPopupOpen = () => {
    setOpenCommonTaiochuPopup(true);
  };

  const updatedSurfaceProcessInput = (result) => {
    if (props?.selectedDataDetail?.estimateProducts != undefined) {
      props.selectedDataDetail.estimateProducts.processInput.surfaceProcessInputInfo = result;
    }
  };
  const updatedKumitateProcessInput = (result) => {
    if (props?.selectedDataDetail?.estimateProducts != undefined) {
      props.selectedDataDetail.estimateProducts.processInput.assenbleProcessInputInfo = result;
    }
  };
  const updatedKensaProcessInput = (result) => {
    if (props?.selectedDataDetail?.estimateProducts != undefined) {
      props.selectedDataDetail.estimateProducts.processInput.inspectionProcessInputInfo = result;
    }
  };
  const updatedKonpouProcessInput = (result) => {
    if (props?.selectedDataDetail?.estimateProducts != undefined) {
      props.selectedDataDetail.estimateProducts.processInput.packingProcessInputInfo = result;
    }
  };
  return (
    <>
      {!displaySurface && !displayAssenble && !displayInspection && !displayPacking ? (
        <></>
      ) : (
        <>
          <Row className="mb-1">
            <Col style={{ paddingLeft: 2 }}>
              <Space size="small">
                <CaretUpOutlined
                  style={{ color: 'white', backgroundColor: '#1063aa', fontSize: 18 }}
                  onClick={closeAll}
                />

                <CaretDownFilled
                  style={{ color: 'white', backgroundColor: '#1063aa', fontSize: 18 }}
                  onClick={openAll}
                />
              </Space>
            </Col>
          </Row>
        </>
      )}
      <div className="overflow-auto kouteical timecharge" style={{ height: '78vh' }}>
        {/* 溶接 */}
        {/* <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={welding}>
          <Col span={20} className="deli-col">
            <div style={{ display: 'flex' }}>
              {isWelding ? (
                <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
              ) : (
                <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
              )}
              <label style={{ marginLeft: 5 }}>溶接</label>
            </div>
          </Col>
        </Row>
        <div style={{ display: isWelding ? 'block' : 'none', margin: '5px' }}>
          <ParentKouteiInput_Yousetsu
            editMode={props.editMode}
            yousetsu1Datas={yousetsuTbl1}
            commonTaiochuPopupOpen={commonTaiochuPopupOpen}
          />
        </div> */}

        {/* 表面処理 */}
        {displaySurface ? (
          <>
            <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={hyoumenShori}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  {isHyoumenShori ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  )}
                  <label style={{ marginLeft: 5 }}>表面処理</label>
                </div>
              </Col>
            </Row>
            <div style={{ display: isHyoumenShori ? 'block' : 'none', margin: '5px' }}>
              <IQ3KouteiInput_Hyoumenshori
                // editMode={props.editMode}
                type={'親部品'}
                // hyoumenShoriVal={hyoumenshoriTable}
                kouteiInputInfo={kouteiInputInfo}
                surfaceData={surfaceData}
                updatedSurfaceProcessInput={updatedSurfaceProcessInput}
              />
            </div>
          </>
        ) : (
          <></>
        )}
        {/* 組立 */}
        {displayAssenble ? (
          <>
            <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={assembly}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  {isAssembly ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  )}
                  <label style={{ marginLeft: 5 }}>組立</label>
                </div>
              </Col>
            </Row>
            <div style={{ display: isAssembly ? 'block' : 'none', margin: '5px' }}>
              <ParentKouteiInput_Kumitate
                editMode={props.editMode}
                kouteiInputInfo={kouteiInputInfo}
                purchasesData={purchasesData}
                purchaseCategoryInfo={purchaseCategoryInfo}
                updatedKumitateProcessInput={updatedKumitateProcessInput}
                kouteiSentakuProcessesId={kouteiSentakuProcessesId}
              />
            </div>
          </>
        ) : (
          <></>
        )}
        {/* 検査　 */}
        {displayInspection ? (
          <>
            <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={survey}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  {isSurvey ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  )}
                  <label style={{ marginLeft: 5 }}>検査</label>
                </div>
              </Col>
            </Row>
            <div style={{ display: isSurvey ? 'block' : 'none', margin: '5px' }}>
              <ParentKouteiInput_Kensa
                editMode={props.editMode}
                kouteiInputInfo={kouteiInputInfo}
                kensaData={kensaTbl}
                commonTaiochuPopupOpen={commonTaiochuPopupOpen}
                updatedKensaProcessInput={updatedKensaProcessInput}
                deviceMasterData={props.deviceMasterData}
                kouteiSentakuProcessesId={kouteiSentakuProcessesId}
              />
            </div>
          </>
        ) : (
          <></>
        )}
        {/* 梱包 */}
        {displayPacking ? (
          <>
            <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={packing}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  {isPacking ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  )}
                  <label style={{ marginLeft: 5 }}>梱包</label>
                </div>
              </Col>
            </Row>
            <div style={{ display: isPacking ? 'block' : 'none', margin: '5px' }}>
              <ParentKouteiInput_Konpou
                editMode={props.editMode}
                kensaData={konpouTbl}
                kouteiInputInfo={kouteiInputInfo}
                commonTaiochuPopupOpen={commonTaiochuPopupOpen}
                updatedKonpouProcessInput={updatedKonpouProcessInput}
                kouteiSentakuProcessesId={kouteiSentakuProcessesId}
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      {/** 開発中ポップアップ */}
      {openCommonTaiochuPopup ? commonTaiochuModal(openCommonTaiochuPopup, closeCommonTaiochuPopup) : ''}
    </>
  );
});

export default KouteiInput;
