/**
 * クラス名：材料表面詳細画面
 * 説明：iQ3板金に使用の材料表面詳細画面です。
 * 作成者：テッ
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Radio, Image, Select, Input } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import CurrencyInput from 'react-currency-input-field';

import checkedImg from '../../../../assets/images/checked.png';
import {
  confirmDiscardContent,
  confirmUpdateContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  error,
} from '../../../common/CommonModal';
import {
  defaultValue,
  formValidatorMode,
  formatDate,
  isDecimal,
  regexExp,
  toHalfWidth,
  unitTypes,
} from '../../../common/Common.js';
import { ErrorMessage } from '../../../common/Message';
import { getIsExistMaterialSurfaceName } from '../../../common/CommonAPI.js';

dayjs.extend(customParseFormat);
const RadioGroup = Radio.Group;
const { TextArea } = Input;

const MaterialSurfaceDetail = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    discardConfirmOk: discardConfirmOk,
    discardConfirmCancel: discardConfirmCancel,
    formValidation: formValidation,
  }));

  const editMode = props.editMode;

  const [selectedAutoPurchase, setSelectedAutoPurchase] = useState('');
  const [selectedAutoPrice, setSelectedAutoPrice] = useState('');

  const [selectedRowData, setSelectedRowData] = useState([]);
  const [originData, setOriginData] = useState([]);

  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updDateFlg, setUpdDateFlg] = useState(false);

  const [materialTypeIQ3List, setMaterialTypeIQ3List] = useState([]);

  const [isNoData, setIsNoData] = useState(false);

  const inputItemRef = {};

  useEffect(() => {
    if (props.selectedData && Object.keys(props.selectedData)?.length > 0) {
      let selectedData = JSON.parse(JSON.stringify(props.selectedData));
      setOriginData(props.selectedData);
      setSelectedRowData(selectedData);
      /* setUpdDate(selectedData.modified); */
      setIsNoData(false);
    } else {
      setIsNoData(true);
      setSelectedRowData([]);
    }
  }, [props.selectedData]);

  useEffect(() => {
    setOriginData(selectedRowData);
    if (props.editMode) {
      document.getElementById('edit').style.display = 'none';
      document.getElementById('update').style.display = 'block';
      document.getElementById('discard').style.display = 'block';
    } else {
      document.getElementById('edit').style.display = 'block';
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
    }
  }, [props.editMode]);

  useEffect(() => {
    setMaterialTypeIQ3List(props?.materialTypeIQ3List);
  }, [props.materialTypeIQ3List]);

  const editDetailInput = (objKey, value) => {
    const isValueChanged = String(originData[objKey]) !== String(value);
    setUpdDateFlg(isValueChanged);
    setSelectedRowData((prevData) => {
      const updatedData = { ...prevData, info: { ...prevData.info } };
      Object.keys(updatedData?.info)?.filter((v) => v === objKey)?.length > 0
        ? (updatedData.info[objKey] = value)
        : (updatedData[objKey] = value);
      return updatedData;
    });
  };

  const editData = () => {
    props.updateEditMode(true);
    document.getElementById('update').style.display = 'block';
    document.getElementById('discard').style.display = 'block';
    document.getElementById('edit').style.display = 'none';
  };

  const updateData = () => {
    /* setUpdateConfirm(true);
    setIsUpdate(true); */
    setUpdateConfirm(props.showEdtConfirmOK);
    if (!props.showEdtConfirmOK) {
      updConfirmOk();
    }
    setIsUpdate(true);
  };

  const discardData = () => {
    //setCancelConfirm(true);
    setCancelConfirm(props.showEdtConfirmCancle);
    if (!props.showEdtConfirmCancle) {
      discardConfirmOk();
    }
  };

  // 画面の入力チェック
  const formValidation = async (inputItems, procMode, isDetail = true) => {
    let ret = true;
    let message = [];
    // コード
    let input = inputItems['code']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('コード').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 材料表面保護名
    input = inputItems['name']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('材料表面保護名').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 材質名称
    if (isDetail) {
      input = document.getElementsByName('materialTypeIq3Id')?.[0];
    } else {
      input = document.getElementsByName('materialTypeIq3Id')?.[0];
    }
    input?.childNodes?.[0]?.classList?.remove('input-error');
    if (input?.innerText === defaultValue.materialTypeIq3 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('材質名称').E017}</li>);
      input?.childNodes?.[0]?.classList.add('input-error');
      ret = false;
    }

    let materialSurfaceId = procMode === formValidatorMode.Create ? 0 : selectedRowData.id;
    let materialClassId = document.getElementsByName('materialTypeIq3Id')?.[0]?.innerText;
    let name = inputItems['name']?.input?.value;
    if (materialClassId !== '' && name !== '') {
      let isExistCodeName = await getIsExistMaterialSurfaceName(materialSurfaceId, name, materialClassId);
      if (isExistCodeName) {
        message.push(<li>{ErrorMessage('材料表面保護名と材質名称').E020}</li>);
        input = inputItems['name']?.input;
        input?.classList?.remove('input-error');
        input?.classList?.add('input-error');
        input = document.getElementsByName('materialTypeIq3Id')?.[0];
        input?.childNodes?.[0]?.classList?.remove('input-error');
        input?.childNodes?.[0]?.classList.add('input-error');
        ret = false;
      }
    }

    // 加算方法
    if (isDetail) {
      input = document.getElementsByName('unit')?.[0];
    } else {
      input = document.getElementsByName('unit')?.[0];
    }
    input?.childNodes?.[0]?.classList?.remove('input-error');
    if (input?.innerText === defaultValue.unitType && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('加算方法').E017}</li>);
      input?.childNodes?.[0]?.classList.add('input-error');
      ret = false;
    }

    // 加算金額
    input = inputItems['price'];
    input?.classList?.remove('input-error');

    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('加算金額').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 2度切り加工速度(mm/分)
    input = inputItems['workSpeed']?.input;
    input?.classList?.remove('input-error');

    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('2度切り加工速度(mm/分)').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    if (input?.value?.length > 0 && !isDecimal(input?.value)) {
      message.push(<li>{ErrorMessage('2度切り加工速度(mm/分)').E009}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    if (message?.length > 0) error(<ul>{message}</ul>);

    return ret;
  };

  const updConfirmOk = async (e) => {
    if (isUpdate) {
      setUpdateConfirm(false);
      // 画面の入力チェック
      if (!(await formValidation(inputItemRef, formValidatorMode.Edit))) {
        return;
      }
      props.updateEditMode(false);
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
      document.getElementById('edit').style.display = 'block';
      let updateData = { ...selectedRowData };

      if (updDateFlg) {
        updateData.modified = dayjs(dayjs()).format('YYYY-MM-DD');
      }
      setSelectedRowData(updateData);
      props.submitData(updateData);
    }
  };

  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  const discardConfirmOk = () => {
    props.updateEditMode(false);
    document.getElementById('update').style.display = 'none';
    document.getElementById('discard').style.display = 'none';
    document.getElementById('edit').style.display = 'block';
    setSelectedRowData(originData);
    setCancelConfirm(false);
  };

  const discardConfirmCancel = () => {
    setCancelConfirm(false);
  };
  // 材料名称検索
  const filterOption = (input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;

  // 項目値を変更した場合、
  const onChangeInputForm = (name, value, isCheckZenkaku = true) => {
    if (isCheckZenkaku && regexExp.test(value)) {
      // 全角の場合、
      value = toHalfWidth(value); // 半角へ変換
    }
    editDetailInput(name, value);
  };

  return (
    <>
      <div className="d-flex" style={{ marginTop: 9, marginRight: 10 }}>
        <div>
          <Button
            className="mainButton"
            style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
            id="update"
            onClick={updateData}
          >
            更新
          </Button>
        </div>
        <div>
          <Button
            className="cancelButton"
            style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
            id="discard"
            onClick={discardData}
          >
            破棄
          </Button>
        </div>
        <div className="ms-auto">
          <Button className={isNoData ? 'disabledButton' : 'mainButton'} id="edit" onClick={editData}>
            編集
          </Button>
        </div>
      </div>

      <div className="overflow-auto materialClass" style={{ height: 760, marginTop: 10 }}>
        <div id="showData">
          <Table id="param_detail_tbl" className="propertiesTbl">
            <thead>
              {/* <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">No</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label className="param_detail-title-label">{props.itemNo}</label>
                </td>
              </tr> */}
              {/* コード */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">コード</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="code"
                    id="code"
                    value={selectedRowData?.code}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['code'] = ref;
                    }}
                    maxLength={50}
                    onChange={(e) => editDetailInput('code', e.target.value)}
                  />
                </td>
              </tr>
              {/* 材料表面属性 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">材料表面保護名</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="name"
                    id="name"
                    value={selectedRowData?.name}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['name'] = ref;
                    }}
                    maxLength={50}
                    onChange={(e) => editDetailInput('name', e.target.value)}
                  />
                </td>
              </tr>
              {/* 材質名称 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">材質名称</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <Select
                      showSearch
                      style={{ marginLeft: 5, width: '98.2%' }}
                      onChange={(e) => {
                        editDetailInput('materialTypeIq3Id', e);
                      }}
                      filterOption={filterOption}
                      placeholder="材質名称"
                      id="materialTypeIq3Id"
                      name="materialTypeIq3Id"
                      value={selectedRowData?.materialTypeIq3Id}
                    >
                      {materialTypeIQ3List?.map((item, index) => (
                        <Select.Option key={index} id={'materialTypeIq3Id' + item.value} value={item.value}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <label className="param_detail-title-label">
                      {props?.getMaterilTypeIQ3Name(selectedRowData?.materialTypeIq3Id)}
                    </label>
                  )}
                </td>
              </tr>
              {/* 属性種別 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">属性種別</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="class"
                    id="class"
                    value={selectedRowData?.class}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['class'] = ref;
                    }}
                    maxLength={50}
                    onChange={(e) => editDetailInput('class', e.target.value)}
                  />
                </td>
              </tr>
              {/* 加算方法 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">加算方法</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <Select
                      style={{ marginLeft: 5, width: '98.2%' }}
                      onChange={(e) => {
                        editDetailInput('unit', e);
                      }}
                      placeholder="加算方法"
                      id="unit"
                      name="unit"
                      value={selectedRowData?.info?.unit}
                    >
                      {unitTypes?.map((item, index) => (
                        <Select.Option key={index} id={'unit' + item.value} value={item.value}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <label className="param_detail-title-label">
                      {props?.getUnitName(selectedRowData?.info?.unit)}
                    </label>
                  )}
                </td>
              </tr>
              {/* 加算金額 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">加算金額</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <CurrencyInput
                    name="price"
                    id="price"
                    type="text"
                    value={selectedRowData?.info?.price}
                    defaultValue={0}
                    onValueChange={(e) => editDetailInput('price', e)}
                    ref={(ref) => {
                      inputItemRef['price'] = ref;
                    }}
                    style={{ borderRadius: '6px' }}
                    className={
                      editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable chargeAmount_currency'
                    }
                    prefix="¥"
                    decimalsLimit={100}
                    maxLength={Number.MAX_SAFE_INTEGER}
                  />
                </td>
              </tr>
              {/* レーザー2度切り */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">レーザー2度切り</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <RadioGroup
                      name="isTwiceCut"
                      className="radioCustomerGpEdit"
                      onChange={(e) => {
                        editDetailInput('isTwiceCut', e.target.value);
                      }}
                      value={selectedRowData?.info?.isTwiceCut}
                    >
                      <Radio value={true}>オン</Radio>
                      <Radio value={false}>オフ</Radio>
                    </RadioGroup>
                  ) : (
                    selectedRowData?.info?.isTwiceCut && (
                      <Image preview={false} width={13} src={checkedImg} id="isTwiceCut" />
                    )
                  )}
                </td>
              </tr>
              {/* 2度切り加工速度(mm/分) IQX_WEBEST-360 パラメータ > 材料 > 板金 > 材料表面 > "ビニール加工速度" 名称変更　*/}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">
                    2度切り加工速度
                    <br />
                    (mm/分)
                  </label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="workSpeed"
                    id="workSpeed"
                    value={selectedRowData?.info?.workSpeed}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['workSpeed'] = ref;
                    }}
                    maxLength={50}
                    onBlur={(e) => onChangeInputForm(e.target.name, e.target.value)}
                    onPressEnter={(e) => onChangeInputForm(e.target.name, e.target.value)}
                    onChange={(e) => onChangeInputForm(e.target.name, e.target.value, false)}
                  />
                </td>
              </tr>
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">作成日</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                    {selectedRowData?.created ? formatDate(selectedRowData.created) : ''}
                  </label>
                </td>
              </tr>
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">更新日</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                    {selectedRowData?.modified ? formatDate(selectedRowData?.modified) : ''}
                  </label>
                </td>
              </tr>
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">更新者</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label style={{ fontSize: '13px', paddingLeft: 10 }}>{selectedRowData?.modifier}</label>
                </td>
              </tr>
              {/* 備考 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">備考</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <TextArea
                    type="text"
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    data-name="name"
                    name="remarks"
                    id="remarks"
                    style={{ margin: 5, padding: 5, width: '98.3%' }}
                    value={selectedRowData?.info?.remarks}
                    maxLength={2048}
                    onChange={(e) => editDetailInput('remarks', e.target.value)}
                  />
                </td>
              </tr>
              {/* 表示 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">表示</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <RadioGroup
                      name="isUsed"
                      className="radioCustomerGpEdit"
                      onChange={(e) => {
                        editDetailInput('isUsed', e.target.value);
                      }}
                      value={selectedRowData?.info?.isUsed}
                    >
                      <Radio value={true}>オン</Radio>
                      <Radio value={false}>オフ</Radio>
                    </RadioGroup>
                  ) : (
                    selectedRowData?.info?.isUsed && (
                      <Image preview={false} width={13} src={checkedImg} id="imgOutsourcingFg" />
                    )
                  )}
                </td>
              </tr>
            </thead>
          </Table>
          <div>
            {/* 更新確認_Modal */}
            {updateConfirm
              ? commonModal(
                  updateConfirm,
                  confirmModalTitle,
                  commonFooter(updConfirmOk, updConfirmCancel),
                  null,
                  400,
                  updConfirmOk,
                  updConfirmCancel,
                  confirmUpdateContent,
                  null,
                  null,
                  false
                )
              : ''}
            {/* 破棄確認_Modal */}
            {cancelConfirm
              ? commonModal(
                  cancelConfirm,
                  confirmModalTitle,
                  commonFooter(discardConfirmOk, discardConfirmCancel),
                  null,
                  400,
                  discardConfirmOk,
                  discardConfirmCancel,
                  confirmDiscardContent,
                  null,
                  null,
                  false
                )
              : ''}
          </div>
        </div>
      </div>
    </>
  );
});

export default MaterialSurfaceDetail;
