/**
 * クラス名：板金のブランクの2工程穴加工時間画面
 * 作成者：チュー
 * 作成日：2023/06/28
 * バージョン：1.0
 */
import React, { useEffect, forwardRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Col, Input } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

import { souchiHandleIncreaseTime, souchiHandleDecreaseTime, secondsToHms, setTimetoSec } from '../../../common/Common';

const Device_Blank_DrillingTime = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);
  const [drillingTime, setDrillingTime] = useState();
  useEffect(() => {
    setEditMode(props.editMode);
    setDrillingTime(props?.detailsData?.twoStepHoleworkTime);
  }, [props.editMode, props.detailsData]);
  const changeTime = (e) => {
    setDrillingTime(e);
    props.updateDrillingTime(e);
  };
  return (
    <>
      <Row className="chargeAmount" style={{ marginLeft: 10, marginRight: 10 }}>
        <Col style={{ width: '20%' }}>
          <Table>
            <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
              <tr style={{ textAlign: 'center', height: 30 }}>
                <th className="tbl-header">2工程穴加工時間</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                <td>
                  <Row align="middle">
                    {!editMode ? (
                      <>
                        <Col span={24}>
                          <label>{secondsToHms(drillingTime)}</label>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col span={10}>
                          <Input
                            value={secondsToHms(drillingTime)}
                            onChange={(event) => changeTime(setTimetoSec(event.target.value))}
                          ></Input>
                        </Col>
                        <Col span={14}>
                          <CaretUpOutlined
                            style={{
                              fontSize: 16 + 'px',
                              marginLeft: 3,
                              pointerEvents: editMode ? 'auto' : 'none',
                            }}
                            onClick={() => {
                              let editedTime = souchiHandleIncreaseTime(secondsToHms(drillingTime), '10min');
                              changeTime(setTimetoSec(editedTime));
                            }}
                          />
                          <CaretDownOutlined
                            style={{
                              fontSize: 17 + 'px',
                              color: '#000',
                              pointerEvents: editMode ? 'auto' : 'none',
                            }}
                            onClick={() => {
                              let editedTime = souchiHandleDecreaseTime(secondsToHms(drillingTime), '10min');
                              changeTime(setTimetoSec(editedTime));
                            }}
                          />
                          <CaretUpOutlined
                            style={{
                              fontSize: 17 + 'px',
                              color: '#000',
                              pointerEvents: editMode ? 'auto' : 'none',
                            }}
                            onClick={() => {
                              let editedTime = souchiHandleIncreaseTime(secondsToHms(drillingTime), 'min');
                              changeTime(setTimetoSec(editedTime));
                            }}
                          />
                          <CaretDownOutlined
                            style={{
                              fontSize: 17 + 'px',
                              color: '#000',
                              pointerEvents: editMode ? 'auto' : 'none',
                            }}
                            onClick={() => {
                              let editedTime = souchiHandleDecreaseTime(secondsToHms(drillingTime), 'min');
                              changeTime(setTimetoSec(editedTime));
                            }}
                          />
                          <CaretUpOutlined
                            style={{
                              fontSize: 17 + 'px',
                              color: '#000',
                              pointerEvents: editMode ? 'auto' : 'none',
                            }}
                            onClick={() => {
                              let editedTime = souchiHandleIncreaseTime(secondsToHms(drillingTime), 'sec');
                              changeTime(setTimetoSec(editedTime));
                            }}
                          />
                          <CaretDownOutlined
                            style={{
                              fontSize: 17 + 'px',
                              color: '#000',
                              pointerEvents: editMode ? 'auto' : 'none',
                            }}
                            onClick={() => {
                              let editedTime = souchiHandleDecreaseTime(secondsToHms(drillingTime), 'sec');
                              changeTime(setTimetoSec(editedTime));
                            }}
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
});

export default Device_Blank_DrillingTime;
