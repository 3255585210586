/**
 * クラス名：自動引当詳細画面
 * 説明：共通に使用の自動引当詳細画面です。
 * 作成者：テッ
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Radio, Image, Select, Input, Row, Col, Tabs } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { StarFilled } from '@ant-design/icons';

import checkedImg from '../../../../assets/images/checked.png';
import {
  confirmDiscardContent,
  confirmUpdateContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  modalTitle,
  error,
} from '../../../common/CommonModal';
import {
  defaultValue,
  formatDate,
  formValidatorMode,
  isDigit,
  JPYs,
  regexExp,
  secondsToHms,
  ServiceClasses,
  toHalfWidth,
  WorkTypes,
} from '../../../common/Common';
import CommonPurchaseList from '../../../common/CommonPurchaseList';
import { ServiceClass, WorkType } from '../../../common/enums';
import { ErrorMessage } from '../../../common/Message';

dayjs.extend(customParseFormat);
const RadioGroup = Radio.Group;
const { TextArea } = Input;

const AutoReserveDetails = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    discardConfirmOk: discardConfirmOk,
    discardConfirmCancel: discardConfirmCancel,
    formValidation: formValidation,
  }));

  const editMode = props.editMode;

  const [selectedRowData, setSelectedRowData] = useState([]);
  const [originData, setOriginData] = useState([]);

  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updDateFlg, setUpdDateFlg] = useState(false);
  const [changePurchase, setChangePurchase] = useState(false);
  const [selectedPurchaseDetail, setSelectedPurchaseDetail] = useState([]);

  const [serviceId, setServiceId] = useState('');
  const [processTypeId, setProcessTypeId] = useState('');

  const [processTypeNoList, setProcessTypeNoList] = useState([]);

  const [visiblePurchaseTbl, setVisiblePurchaseTbl] = useState();
  const [activeTabKey, setActiveTabKey] = useState('1');
  const [checkedPurchasesData, setCheckedPurchasesData] = useState(false);

  const [purchaseMstInfo, setPurchaseMstInfo] = useState([]);
  const [purchaseMstInfoByAllocation, setPurchaseMstInfoByAllocation] = useState([]);
  const [isNoData, setIsNoData] = useState(false);

  const inputItemRef = {};

  // Properties情報の初期値
  useEffect(() => {
    if (props.selectedData && Object.keys(props.selectedData)?.length > 0) {
      let selectedData = JSON.parse(JSON.stringify(props.selectedData));
      setOriginData(props.selectedData);
      setSelectedRowData(selectedData);
      setIsNoData(false);
      initFormValue(selectedData);
    } else {
      setIsNoData(true);
      initFormValue({});
      setSelectedRowData([]);
    }
  }, [props.selectedData]);

  useEffect(() => {
    setOriginData(selectedRowData);
    if (props.editMode) {
      document.getElementById('edit').style.display = 'none';
      document.getElementById('update').style.display = 'block';
      document.getElementById('discard').style.display = 'block';
    } else {
      document.getElementById('edit').style.display = 'block';
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
    }
  }, [props.editMode]);

  // 購入品マスタ情報を取得する
  useEffect(() => {
    setPurchaseMstInfo(props?.purchaseMstInfo);
    setPurchaseMstInfoByAllocation(props?.purchaseMstInfoByAllocation);
  }, [props?.purchaseMstInfo, props?.purchaseMstInfoByAllocation]);

  // 購入品一覧
  const purchaseTblContent = (activeTabKey) => {
    return (
      <div id="addNewAutoReserve" style={{ marginTop: 8 }}>
        <table className="table table-bordered upperReserveDetail">
          <thead style={{ backgroundColor: '#fafafa', color: '#1063aa', height: 20 }}>
            <tr>
              <td className="param-detail-tbl-val-title">種別</td>
              <td className="param-detail-tbl-val-title">工程</td>
              <td className="param-detail-tbl-val-title">加工</td>
              <td className="param-detail-tbl-val-title">材質区分名</td>
              <td className="param-detail-tbl-val-title">サイズ</td>
            </tr>
          </thead>
          <tr>
            <td>
              <label>{getNameByValue(ServiceClasses, getServiceId(selectedRowData?.processId))}</label>
            </td>
            <td>
              <label>
                {getNameByValue(WorkTypes, getProcessTypeId(props?.getProcessRecordId(serviceId, processTypeId)))}
              </label>
            </td>
            <td>
              <label>
                {getNameByValue(props?.getProcessTypeNoInfo(serviceId, processTypeId), selectedRowData?.processTypeNo)}
              </label>
            </td>
            <td>
              <label>{getNameByValue(props?.materialTypeList, selectedRowData?.materialTypeId)}</label>
            </td>
            <td>
              <label>{selectedRowData?.size}</label>
            </td>
          </tr>
        </table>
        {/** 一覧 */}
        <div>
          <Tabs
            className="estimate-detail-tab"
            type="card"
            defaultActiveKey="1"
            activeKey={activeTabKey}
            items={tabItems}
            onChange={onChange}
          />
        </div>
      </div>
    );
  };

  // 購入品一覧のメニュータブを変更した場合、
  const onChange = (key) => {
    setActiveTabKey(key);
  };

  // 種別・工程・加工・購入品の初期値
  const initFormValue = (selectedData) => {
    if (selectedData) {
      // 種別情報
      let serviceId = getServiceId(selectedData?.processId);
      setServiceId(serviceId);
      // 工程情報
      let processTypeId = getProcessTypeId(selectedData?.processId);
      setProcessTypeId(processTypeId);
      // 加工情報
      let processTypeNoList = props?.getProcessTypeNoInfo(serviceId, processTypeId);
      setProcessTypeNoList(processTypeNoList);
      // 購入品情報
      setSelectedPurchaseDetail(props?.getPurchaseDetailInfo(selectedData?.purchaseId));
    } else {
      setServiceId('');
      setProcessTypeId('');
      setProcessTypeNoList([]);
      setSelectedPurchaseDetail([]);
    }
  };

  // 入力項目の値は変更された場合、
  const editDetailInput = (objKey, value) => {
    const isValueChanged = String(originData[objKey]) !== String(value);
    setUpdDateFlg(isValueChanged);
    setSelectedRowData((prevData) => {
      const updatedData = { ...prevData, info: { ...prevData.info } };
      Object.keys(updatedData?.info)?.filter((v) => v === objKey)?.length > 0
        ? (updatedData.info[objKey] = value)
        : (updatedData[objKey] = value);
      return updatedData;
    });
  };

  // 購入品一覧の選択中行データ
  const getPurchaseData = (data) => {
    setCheckedPurchasesData(data[0]);
  };

  // 購入品一覧のメニュータブ
  const tabItems = [
    {
      key: '1',
      label: `検索`,
      children: <CommonPurchaseList data={purchaseMstInfo} getPurchaseData={getPurchaseData} />,
    },
    {
      key: '2',
      label: <StarFilled />,
      children: <CommonPurchaseList data={purchaseMstInfoByAllocation} getPurchaseData={getPurchaseData} />,
    },
  ];

  // 購入品名称ボタン押下
  const showPurchaseTbl = () => {
    setVisiblePurchaseTbl(true);
  };

  // 編集ボタン押下
  const editData = () => {
    props.updateEditMode(true);
    document.getElementById('update').style.display = 'block';
    document.getElementById('discard').style.display = 'block';
    document.getElementById('edit').style.display = 'none';
  };

  // 更新ボタン押下
  const updateData = () => {
    setUpdateConfirm(props.showEdtConfirmOK);
    if (!props.showEdtConfirmOK) {
      updConfirmOk();
    }
    setIsUpdate(true);
  };

  // 破壊ボタン押下
  const discardData = () => {
    setCancelConfirm(props.showEdtConfirmCancle);
    if (!props.showEdtConfirmCancle) {
      discardConfirmOk();
    }
  };

  // 画面の入力チェック
  const formValidation = (inputItems, procMode) => {
    let ret = true;
    let message = [];
    let input;

    // 種別
    input = document.getElementsByName('service')?.[0];
    input?.childNodes?.[0]?.classList?.remove('input-error');
    if (input?.innerText === defaultValue.service && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('種別').E017}</li>);
      input?.childNodes?.[0]?.classList.add('input-error');
      ret = false;
    }

    // 工程
    input = document.getElementsByName('processType')?.[0];
    input?.childNodes?.[0]?.classList?.remove('input-error');
    if (input?.innerText === defaultValue.process && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('工程').E017}</li>);
      input?.childNodes?.[0]?.classList.add('input-error');
      ret = false;
    }

    // 加工
    input = document.getElementsByName('processTypeNo')?.[0];
    input?.childNodes?.[0]?.classList?.remove('input-error');
    if (input?.innerText === defaultValue.processType && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('加工').E017}</li>);
      input?.childNodes?.[0]?.classList.add('input-error');
      ret = false;
    }

    // 材質区分名
    input = document.getElementsByName('materialType')?.[0];
    input?.childNodes?.[0]?.classList?.remove('input-error');
    if (input?.innerText === defaultValue.materialType && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('材質区分名').E017}</li>);
      input?.childNodes?.[0]?.classList.add('input-error');
      ret = false;
    }

    // サイズ
    input = inputItems['size']?.input;
    input?.classList?.remove('input-error');

    if (input?.value?.length <= 0) {
      // 必須
      message.push(<li>{ErrorMessage('サイズ').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    if (input?.value?.length > 0 && !isDigit(input?.value)) {
      message.push(<li>{ErrorMessage('サイズ').E009}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    if (message?.length > 0) error(<ul>{message}</ul>);

    return ret;
  };

  // 更新確認メッセージのOKボタン押下
  const updConfirmOk = (e) => {
    if (isUpdate) {
      setUpdateConfirm(false);
      // 画面の入力チェック
      if (!formValidation(inputItemRef, formValidatorMode.Edit)) {
        return;
      }
      props.updateEditMode(false);
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
      document.getElementById('edit').style.display = 'block';
      let updateData = { ...selectedRowData };
      // 工程マスタID
      updateData.processId = props?.getProcessRecordId(serviceId, processTypeId);
      // 購入品ID
      updateData.purchaseId = selectedPurchaseDetail?.id;
      if (updDateFlg || changePurchase) {
        updateData.modified = dayjs(dayjs()).format('YYYY-MM-DD');
      }
      setSelectedRowData(updateData);
      props.submitData(updateData);
    }
  };

  // 更新確認メッセージのCancelボタン押下
  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  // 破壊確認メッセージのOKボタン押下
  const discardConfirmOk = () => {
    props.updateEditMode(false);
    document.getElementById('update').style.display = 'none';
    document.getElementById('discard').style.display = 'none';
    document.getElementById('edit').style.display = 'block';
    setSelectedRowData(props.selectedData);
    initFormValue(props.selectedData); // 種別・工程・加工の初期値
    setCancelConfirm(false);
  };

  // 破壊確認メッセージのCancelボタン押下
  const discardConfirmCancel = () => {
    setCancelConfirm(false);
  };

  // 購入品一覧のOKボタン押下
  const purchaseOK = (e) => {
    if (selectedPurchaseDetail.code !== checkedPurchasesData.code) {
      setSelectedPurchaseDetail(checkedPurchasesData);
      setChangePurchase(true);
    }
    setVisiblePurchaseTbl(false);
  };

  // 購入品一覧のCancelボタン押下
  const purchaseCancel = () => {
    setVisiblePurchaseTbl(false);
  };

  // 種別のIDを得る
  const getServiceId = (processId) => {
    if (!processId) return;
    return props?.processMstInfo?.filter((record) => record?.id === processId)?.[0]?.class;
  };

  // 工程のIDを得る
  const getProcessTypeId = (processId) => {
    if (!processId) return;
    return props?.processMstInfo?.filter((record) => record?.id === processId)?.[0]?.workType;
  };

  // リストから名所をIDで得る
  const getNameByValue = (data, value) => {
    if (data?.length <= 0 || value === undefined) return;
    return data?.filter((record) => record?.value === value)?.[0]?.label;
  };

  // サイズ値を変更した場合、
  const onChangeInputForm = (value, isCheckZenkaku = true) => {
    if (isCheckZenkaku && regexExp.test(value)) {
      // 全角の場合、
      value = toHalfWidth(value); // 半角へ変換
    }
    editDetailInput('size', value);
  };

  return (
    <>
      <div className="d-flex" style={{ marginTop: 9, marginRight: 6 }}>
        <div>
          <Button
            className="mainButton"
            style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
            id="update"
            onClick={updateData}
          >
            更新
          </Button>
        </div>
        <div>
          <Button
            className="cancelButton"
            style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
            id="discard"
            onClick={discardData}
          >
            破棄
          </Button>
        </div>
        <div className="ms-auto">
          <Button className={isNoData ? 'disabledButton' : 'mainButton'} id="edit" onClick={editData}>
            編集
          </Button>
        </div>
      </div>

      <div className="overflow-auto materialClass" style={{ height: 760, marginTop: 6 }}>
        <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
          <Col span={24} className="deli-col" style={{ textAlign: 'center' }}>
            <label style={{ marginRight: 5, textAlign: 'center' }}>加工選択</label>
          </Col>
        </Row>
        <div id="showData">
          <Table id="param_detail_tbl" className="propertiesTbl" style={{ marginBottom: 0 }}>
            <thead>
              {/* 種別 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">種別</label>
                </td>

                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <div style={{ padding: '0 5px' }}>
                      <Select
                        style={{ marginLeft: 0, width: '99.6%' }}
                        onChange={(e) => {
                          setServiceId(e);
                          setProcessTypeId('');
                          editDetailInput('processTypeNo', '');
                        }}
                        id="service"
                        name="service"
                        value={serviceId}
                        defaultValue=""
                      >
                        <Select.Option id="defaultValue" value="">
                          {defaultValue.service}
                        </Select.Option>
                        {ServiceClasses?.filter(
                          // 親部品・板金子部品
                          (item) => item.value === ServiceClass.SheetMetal
                        )?.map((record, index) => (
                          <Select.Option key={index} value={record?.value} id={record?.value}>
                            {record?.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  ) : (
                    <label className="param_detail-title-label">{getNameByValue(ServiceClasses, serviceId)}</label>
                  )}
                </td>
              </tr>
              {/* 工程 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">工程</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <div style={{ padding: '0 5px' }}>
                      <Select
                        style={{ marginLeft: 0, width: '99.6%' }}
                        onChange={(e) => {
                          setProcessTypeId(e);
                          editDetailInput('processTypeNo', '');
                        }}
                        id="processType"
                        name="processType"
                        value={processTypeId}
                        defaultValue=""
                      >
                        <Select.Option id="defaultValue" value="">
                          {defaultValue.process}
                        </Select.Option>
                        {serviceId === ServiceClass.Parent ? (
                          // 親部品の場合、溶接を扱う
                          WorkTypes?.filter((item) => item.value === WorkType.PaWelding)?.map((record, index) => (
                            <Select.Option key={index} id={'processType' + record?.value} value={record?.value}>
                              {record?.label}
                            </Select.Option>
                          ))
                        ) : serviceId === ServiceClass.SheetMetal ? (
                          // 板金子部品の場合、溶接・2次加工を扱う
                          WorkTypes?.filter(
                            (item) => item.value === WorkType.SmWelding || item.value === WorkType.SmSecondaryWork
                          )?.map((record, index) => (
                            <Select.Option key={index} id={'processType' + record?.value} value={record?.value}>
                              {record?.label}
                            </Select.Option>
                          ))
                        ) : (
                          <></>
                        )}
                      </Select>
                    </div>
                  ) : (
                    <label className="param_detail-title-label">{getNameByValue(WorkTypes, processTypeId)}</label>
                  )}
                </td>
              </tr>
              {/* 加工 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">加工</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <div style={{ padding: '0 5px' }}>
                      <Select
                        style={{ marginLeft: 0, width: '99.6%' }}
                        onChange={(e) => {
                          editDetailInput('processTypeNo', e);
                        }}
                        placeholder="加工名"
                        id="processTypeNo"
                        name="processTypeNo"
                        value={selectedRowData?.processTypeNo}
                        defaultValue=""
                      >
                        <Select.Option id="defaultValue" value="">
                          {defaultValue.processType}
                        </Select.Option>
                        {/* 種別・工程より、加工情報を扱う */}
                        {props?.getProcessTypeNoInfo(serviceId, processTypeId)?.map((item, index) => (
                          <Select.Option key={index} id={'processType' + item?.value} value={item?.value}>
                            {item?.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  ) : (
                    <label className="param_detail-title-label">
                      {getNameByValue(processTypeNoList, selectedRowData?.processTypeNo)}
                    </label>
                  )}
                </td>
              </tr>
              {/* 材質区分名 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">材質区分名</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <div style={{ padding: '0 5px' }}>
                      <Select
                        style={{ marginLeft: 0, width: '99.6%' }}
                        onChange={(e) => {
                          editDetailInput('materialTypeId', e);
                        }}
                        id="materialType"
                        name="materialType"
                        value={selectedRowData?.materialTypeId}
                        defaultValue=""
                      >
                        <Select.Option id="defaultValue" value="">
                          {defaultValue.materialType}
                        </Select.Option>
                        {props?.materialTypeList?.map((item, index) => (
                          <Select.Option key={index} id={'materialTypeId' + item.value} value={item.value}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  ) : (
                    <label className="param_detail-title-label">
                      {getNameByValue(props?.materialTypeList, selectedRowData.materialTypeId)}
                    </label>
                  )}
                </td>
              </tr>
              {/* サイズ */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">サイズ</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="size"
                    id="size"
                    value={selectedRowData?.size}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['size'] = ref;
                    }}
                    maxLength={Number.MAX_SAFE_INTEGER}
                    onBlur={(e) => onChangeInputForm(e.target.value)}
                    onPressEnter={(e) => onChangeInputForm(e.target.value)}
                    onChange={(e) => onChangeInputForm(e.target.value, false)}
                  />
                </td>
              </tr>
            </thead>
          </Table>
          <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
            <Col span={24} className="deli-col" style={{ textAlign: 'center' }}>
              <label style={{ marginRight: 5, textAlign: 'center' }}>購入品選択</label>
            </Col>
          </Row>

          <Table id="param_detail_tbl" className="propertiesTbl">
            <thead>
              {/* 購入品名称 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  {editMode ? (
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button className="cancleButton" onClick={showPurchaseTbl} style={{ pointerEvents: 'auto' }}>
                        購入品名称
                      </Button>
                    </span>
                  ) : (
                    <>
                      <label className="param_detail-title-label">購入品名称</label>
                    </>
                  )}
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={'input-non-editable'}
                    name="purchaseName"
                    id="purchaseName"
                    value={selectedPurchaseDetail?.name}
                    type="text"
                  />
                </td>
              </tr>
              {/* 仕入先 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">仕入先</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={'input-non-editable'}
                    name="supplierName"
                    id="supplierName"
                    value={selectedPurchaseDetail?.info?.supplierName}
                    type="text"
                  />
                </td>
              </tr>
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">単価</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={'input-non-editable'}
                    name="price"
                    id="price"
                    value={
                      selectedPurchaseDetail?.price !== undefined ? JPYs.format(selectedPurchaseDetail?.price) : ''
                    }
                    type="text"
                  />
                </td>
              </tr>
              {/* 取付時間*/}

              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">取付時間</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={'input-non-editable'}
                    name="processTime"
                    id="processTime"
                    value={
                      selectedPurchaseDetail?.info?.processTime !== undefined
                        ? secondsToHms(selectedPurchaseDetail?.info?.processTime)
                        : ''
                    }
                    type="text"
                  />
                </td>
              </tr>

              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">作成日</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                    {selectedRowData?.created ? formatDate(selectedRowData?.created) : ''}
                  </label>
                </td>
              </tr>

              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">更新日</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                    {selectedRowData?.modified ? formatDate(selectedRowData?.modified) : ''}
                  </label>
                </td>
              </tr>

              {/* 更新者 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">更新者</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label className="param_detail-title-label">{selectedRowData?.modifier}</label>
                </td>
              </tr>

              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">備考</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <TextArea
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="remarks"
                    id="remarks"
                    value={selectedRowData?.info?.remarks}
                    style={{ margin: 5, padding: 5, width: '98.3%' }}
                    type="text"
                    maxLength={2048}
                    onChange={(e) => editDetailInput('remarks', e.target.value)}
                  />
                </td>
              </tr>
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">表示</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <RadioGroup
                      name="isUsed"
                      defaultValue={selectedRowData?.info?.isUsed}
                      className="radioCustomerGpEdit"
                      onChange={(e) => editDetailInput('isUsed', e.target.value)}
                      value={selectedRowData?.info?.isUsed}
                    >
                      <Radio value={true}>オン</Radio>
                      <Radio value={false}>オフ</Radio>
                    </RadioGroup>
                  ) : (
                    selectedRowData?.info?.isUsed && (
                      <Image preview={false} width={13} src={checkedImg} id="imgOutsourcingFg" />
                    )
                  )}
                </td>
              </tr>
            </thead>
          </Table>

          <div>
            {/* 更新確認_Modal */}
            {updateConfirm
              ? commonModal(
                  updateConfirm,
                  confirmModalTitle,
                  commonFooter(updConfirmOk, updConfirmCancel),
                  null,
                  400,
                  updConfirmOk,
                  updConfirmCancel,
                  confirmUpdateContent,
                  null,
                  null,
                  false
                )
              : ''}

            {/* 破棄確認_Modal */}
            {cancelConfirm
              ? commonModal(
                  cancelConfirm,
                  confirmModalTitle,
                  commonFooter(discardConfirmOk, discardConfirmCancel),
                  null,
                  400,
                  discardConfirmOk,
                  discardConfirmCancel,
                  confirmDiscardContent,
                  null,
                  null,
                  false
                )
              : ''}

            {/* 購入品検索 */}
            {visiblePurchaseTbl &&
              commonModal(
                visiblePurchaseTbl,
                modalTitle('購入品検索', 1000, -24),
                commonFooter(purchaseOK, purchaseCancel),
                null,
                1000,
                purchaseOK,
                purchaseCancel,
                purchaseTblContent(activeTabKey),
                'addNewAutoReserveModal',
                null,
                false
              )}
          </div>
        </div>
      </div>
    </>
  );
});

export default AutoReserveDetails;
