/**
 * クラス名：クラウド用PMXファイル読み込むの板金情報タブ
 * 説明：クラウドにあるPMXファイルを読み込んで板金情報やファイル情報を表示・複数ファイル選択操作を行うJSである。
 * 作成者：ナンス
 * 作成日：2023/07/24
 * バージョン：1.0
 */
import { Button, Row, Input, Col, Image, Table, DatePicker, Tree } from 'antd';
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import '../../assets/styles/common.css';
import filter_clear_icon from '../../assets/images/filter_clear_icon.png';
import iq3Img_pmx1_1 from '../../assets/images/iq3Img_pmx1_1.png';
import iq3Img_pmx1_2 from '../../assets/images/iq3Img_pmx1_2.png';
import iq3Img_pmx1_3 from '../../assets/images/iq3Img_pmx1_3.png';
import no_image from '../../assets/images/no_image.jpg';

const PMX_CloudFileDialog_Tab_IQ3 = React.forwardRef((props, ref) => {
  const [selectedCloudPMXIQ3FileLst, setSelectedCloudPMXIQ3FileLst] = useState([]);
  const [selectedCloudPMXIQ3FileLstBK, setSelectedCloudPMXIQ3FileLstBK] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedDatas, setSelectedDatas] = useState([]);
  const [searchFileNm, setSearchFileNm] = useState('');
  const [searchUpdatedStart, setSearchUpdatedStart] = useState('');
  const [searchUpdatedEnd, setSearchUpdatedEnd] = useState('');
  const [searchMaterial, setSearchMaterial] = useState('');
  const [searchIQ3PartNm, setSearchIQ3PartNm] = useState('');
  const [searchThicknessStart, setSearchThicknessStart] = useState('');
  const [searchThicknessEnd, setSearchThicknessEnd] = useState('');

  useEffect(() => {
    //親部品
    const pmxFileList = [
      {
        key: 0,
        parents: [
          {
            title: 'MRO-M08A-4110' + '(4)',
            key: '0-0',
            children: [
              {
                title: 'MRO-M08A-4110-01',
                key: '0-0-0',
                type: '板金',
                img: (
                  <Image
                    preview={true}
                    width={120}
                    height={77}
                    src={iq3Img_pmx1_1}
                    style={{ marginLeft: '0px' }}
                  ></Image>
                ),
                material: 'SPCC',
                thickness: '2.1',
                partCnt: 1,
              },
              {
                title: 'MRO-M08A-4110-02',
                key: '0-0-1',
                type: '板金',
                img: (
                  <Image
                    preview={true}
                    width={120}
                    height={77}
                    src={iq3Img_pmx1_2}
                    style={{ marginLeft: '0px' }}
                  ></Image>
                ),
                material: 'SPCH',
                thickness: '2.2',
                partCnt: 2,
              },
              {
                title: 'MRO-M08A-4110-03',
                key: '0-0-2',
                type: '板金',
                img: (
                  <Image
                    preview={true}
                    width={120}
                    height={77}
                    src={iq3Img_pmx1_3}
                    style={{ marginLeft: '0px' }}
                  ></Image>
                ),
                material: 'SPCC',
                thickness: '2.0',
                partCnt: 2,
              },
              {
                title: 'MRO-M08A-4110-04',
                key: '0-0-3',
                type: 'その他',
                img: (
                  <Image preview={true} width={120} height={77} src={no_image} style={{ marginLeft: '0px' }}></Image>
                ),
                material: 'SPCC',
                thickness: '2.5',
                partCnt: 10,
              },
            ],
          },
        ],
        updated: '2023/07/21 18:00',
      },
      {
        key: 1,
        parents: [
          {
            title: 'MRO-M08A-4111' + '(1)',
            key: '0-1',
            children: [
              {
                title: 'MRO-M08A-4111-01',
                key: '0-1-0',
                type: '板金',
                img: '',
                material: 'SPCC',
                thickness: '2.3',
                partCnt: 1,
              },
            ],
          },
        ],
        updated: '2023/07/20 18:00',
      },
      {
        key: 2,
        parents: [
          {
            title: 'MRO-M08A-4112' + '(1)',
            key: '0-2',
            children: [
              {
                title: 'MRO-M08A-4112-01',
                key: '0-2-0',
              },
            ],
          },
        ],
        updated: '2023/07/23 18:00',
      },
      {
        key: 3,
        parents: [
          {
            title: 'MRO-M08A-4113' + '(1)',
            key: '0-3',
            children: [
              {
                title: 'MRO-M08A-4113-01',
                key: '0-3-0',
              },
            ],
          },
        ],
        updated: '2023/07/22 18:00',
      },
      {
        key: 4,
        parents: [
          {
            title: 'MRO-M08A-4114' + '(1)',
            key: '0-4',
            children: [
              {
                title: 'MRO-M08A-4114-01',
                key: '0-4-0',
              },
            ],
          },
        ],
        updated: '2023/07/24 18:00',
      },
    ];
    //板金
    let key = 0;
    const iq3Data = [];
    for (let i = 0; i < pmxFileList?.length; i++) {
      for (let j = 0; j < pmxFileList[i].parents[0].children?.length; j++) {
        iq3Data.push({
          key: key,
          parentTitle: pmxFileList[i].parents[0].title,
          img: pmxFileList[i].parents[0].children[j].img,
          type: pmxFileList[i].parents[0].children[j].type,
          partTitle: pmxFileList[i].parents[0].children[j].title,
          updated: '2023-07-21 18:00',
          thickness: pmxFileList[i].parents[0].children[j].thickness,
          material: pmxFileList[i].parents[0].children[j].material,
          partCnt: pmxFileList[i].parents[0].children[j].partCnt,
        });
        key++;
      }
    }
    setSelectedCloudPMXIQ3FileLst(iq3Data);
    setSelectedCloudPMXIQ3FileLstBK(iq3Data);
    setSelectedData(iq3Data[0]);
    setSelectedRowKeys([0]);
  }, []);

  let checkedRow = [];
  const onRowCheckboxSelect = (checked, record) => {
    checkedRow = [...checked];
    setSelectedRowKeys(checkedRow);
    const selectedRow = record.slice(-1);
    setSelectedData(selectedRow[0]);
    const data = [...selectedDatas];
    data.push(record);
    setSelectedDatas(data);
    props.selectedIQ3Rows(data);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onRowCheckboxSelect,
  };

  const getIQ3FileNmByFilter = (value) => {
    let searchBarText = value;
    let lstData = selectedCloudPMXIQ3FileLst?.length > 0 ? selectedCloudPMXIQ3FileLst : selectedCloudPMXIQ3FileLstBK;
    const filterData = lstData?.filter((item) => item.parentTitle?.indexOf(searchBarText) >= 0);

    if (searchBarText != '') {
      setSelectedCloudPMXIQ3FileLst(filterData);
    } else {
      setSelectedCloudPMXIQ3FileLst(selectedCloudPMXIQ3FileLstBK);
    }
  };

  const getIQ3PartFileNmByFilter = (value) => {
    let searchBarText = value;
    let lstData = selectedCloudPMXIQ3FileLst?.length > 0 ? selectedCloudPMXIQ3FileLst : selectedCloudPMXIQ3FileLstBK;
    const filterData = lstData?.filter((item) => item.partTitle?.indexOf(searchBarText) >= 0);

    if (searchBarText != '') {
      setSelectedCloudPMXIQ3FileLst(filterData);
    } else {
      setSelectedCloudPMXIQ3FileLst(selectedCloudPMXIQ3FileLstBK);
    }
  };

  const getIQ3MaterialNmByFilter = (value) => {
    let searchBarText = value;
    let lstData = selectedCloudPMXIQ3FileLst?.length > 0 ? selectedCloudPMXIQ3FileLst : selectedCloudPMXIQ3FileLstBK;
    const filterData = lstData?.filter((item) => item.material?.indexOf(searchBarText) >= 0);

    if (searchBarText != '') {
      setSelectedCloudPMXIQ3FileLst(filterData);
    } else {
      setSelectedCloudPMXIQ3FileLst(selectedCloudPMXIQ3FileLstBK);
    }
  };

  const getIQ3DateByFilter = (name, dateString) => {
    let lstData = [];

    lstData = selectedCloudPMXIQ3FileLst?.length > 0 ? selectedCloudPMXIQ3FileLst : selectedCloudPMXIQ3FileLstBK;

    if (name == 0) {
      setSearchUpdatedStart(dateString);
      let filterData = [];
      if (searchUpdatedEnd == '') {
        filterData = lstData?.filter((item) => dateString <= dayjs(item.updated).format('YYYY-MM-DD'));
      } else {
        filterData = lstData?.filter(
          (item) =>
            dateString <= dayjs(item.updated).format('YYYY-MM-DD') &&
            dayjs(item.updated).format('YYYY-MM-DD') <= searchUpdatedEnd
        );
      }

      setSelectedCloudPMXIQ3FileLst(filterData);
    } else {
      setSearchUpdatedEnd(dateString);
      let filterData = [];
      if (searchUpdatedStart == '') {
        filterData = lstData?.filter((item) => dateString >= dayjs(item.updated).format('YYYY-MM-DD'));
      } else {
        filterData = lstData?.filter(
          (item) =>
            searchUpdatedStart <= dayjs(item.updated).format('YYYY-MM-DD') &&
            dayjs(item.updated).format('YYYY-MM-DD') <= dateString
        );
      }

      setSelectedCloudPMXIQ3FileLst(filterData);
    }
  };

  const getIQ3ThicknessByFilter = (name, value) => {
    let lstData = [];
    lstData = selectedCloudPMXIQ3FileLst?.length > 0 ? selectedCloudPMXIQ3FileLst : selectedCloudPMXIQ3FileLstBK;
    if (name == 0) {
      setSearchThicknessStart(value);
      let filterData = [];
      if (searchThicknessEnd == '') {
        filterData = lstData?.filter((item) => value <= item.thickness);
      } else if (value == '') {
        filterData = lstData?.filter((item) => item.thickness <= searchThicknessEnd);
      } else {
        filterData = lstData?.filter((item) => value <= item.thickness && item.thickness <= searchThicknessEnd);
      }
      setSelectedCloudPMXIQ3FileLst(filterData);
    } else {
      setSearchThicknessEnd(value);
      let filterData = [];
      if (searchThicknessStart == '') {
        filterData = lstData?.filter((item) => value <= item.thickness);
      } else if (value == '') {
        filterData = lstData?.filter((item) => searchThicknessStart <= item.thickness);
      } else {
        filterData = lstData?.filter((item) => searchThicknessStart <= item.thickness && item.thickness <= value);
      }
      setSelectedCloudPMXIQ3FileLst(filterData);
    }
  };

  const clearAllFilter = (e) => {
    setSearchFileNm('');
    setSearchUpdatedStart('');
    setSearchUpdatedEnd('');

    setSelectedCloudPMXIQ3FileLst(selectedCloudPMXIQ3FileLstBK);

    setSearchMaterial('');
    setSearchThicknessStart('');
    setSearchThicknessEnd('');
    setSearchIQ3PartNm('');
  };

  const cloudFileIQ3DetailColumns = [
    {
      id: '1',
      title: 'ファイル名',
      dataIndex: 'parentTitle',
      width: 200 + 'px',
    },
    {
      id: '2',
      title: 'サムネイル',
      dataIndex: 'img',
      width: 200 + 'px',
    },
    {
      id: '3',
      title: '種別',
      dataIndex: 'type',
      width: 80 + 'px',
    },
    {
      id: '4',
      title: '子部品名称',
      dataIndex: 'partTitle',
      width: 200 + 'px',
    },
    {
      id: '5',
      title: '更新日時',
      dataIndex: 'updated',
      width: 200 + 'px',
    },
    {
      id: '6',
      title: '材質',
      dataIndex: 'material',
      width: 100 + 'px',
    },
    {
      id: '7',
      title: '板厚',
      dataIndex: 'thickness',
      width: 80 + 'px',
    },
    {
      id: '8',
      title: '員数',
      dataIndex: 'partCnt',
      width: 80 + 'px',
    },
  ];

  return (
    <>
      <Row>
        <Col span={5}>
          <Input
            id="fileNm"
            style={{ marginLeft: 5 }}
            placeholder="ファイル名"
            className={'input-editable'}
            value={searchFileNm}
            allowClear
            onChange={(e) => setSearchFileNm(e.target.value)}
            onPressEnter={(e) => getIQ3FileNmByFilter(e.target.value)}
          />
        </Col>
        <Col span={5} style={{ marginLeft: 15 }}>
          <Input
            id="searchIQ3PartNm"
            style={{ marginLeft: 5 }}
            placeholder="子部品名称"
            className={'input-editable'}
            value={searchIQ3PartNm}
            allowClear
            onChange={(e) => setSearchIQ3PartNm(e.target.value)}
            onPressEnter={(e) => getIQ3PartFileNmByFilter(e.target.value)}
          />
        </Col>
        <Col span={5} style={{ textAlign: 'center', marginLeft: 20 }}>
          <div style={{ display: 'flex' }} id="pmxCloudFileFilter">
            <DatePicker
              style={{ padding: 5, marginLeft: 5, width: '75.7%' }}
              className={'input-editable'}
              onChange={(name, dateString) => getIQ3DateByFilter(0, dateString)}
              value={searchUpdatedStart != '' ? dayjs(searchUpdatedStart, 'YYYY-MM-DD') : ''}
            />
            <label style={{ paddingTop: 3 }}>~</label>
            <DatePicker
              style={{ padding: 5, marginLeft: 5, width: '75.7%' }}
              className={'input-editable'}
              onChange={(name, dateString) => getIQ3DateByFilter(1, dateString)}
              value={searchUpdatedEnd != '' ? dayjs(searchUpdatedEnd, 'YYYY-MM-DD') : ''}
            />
          </div>
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          <Button style={{ marginLeft: 0 }} className="filterClearButton" onClick={(e) => clearAllFilter()}>
            <div style={{ display: 'flex' }}>
              <Image preview={false} width={18} height={18} src={filter_clear_icon} style={{ marginBottom: '5px' }} />
              <label style={{ marginLeft: '5px', marginTop: 1, fontSize: '12px' }}>検索条件をクリア</label>
            </div>
          </Button>
        </Col>
      </Row>
      <Row style={{ marginTop: 5 }}>
        <Col span={5}></Col>
        <Col span={5} style={{ marginLeft: 15 }}>
          <Input
            id="searchMaterial"
            style={{ marginLeft: 5 }}
            placeholder="材質"
            className={'input-editable'}
            value={searchMaterial}
            allowClear
            onChange={(e) => setSearchMaterial(e.target.value)}
            onPressEnter={(e) => getIQ3MaterialNmByFilter(e.target.value)}
          />
        </Col>
        <Col span={5} style={{ textAlign: 'center', marginLeft: 20 }}>
          <div style={{ display: 'flex' }} id="pmxCloudFileFilter">
            <Input
              id="searchThicknessStart"
              // style={{ width: '75.7%' }}
              placeholder="板厚"
              className={'input-editable'}
              value={searchThicknessStart}
              allowClear
              onChange={(e) => setSearchThicknessStart(e.target.value)}
              onPressEnter={(e) => getIQ3ThicknessByFilter(0, e.target.value)}
            />
            <label style={{ paddingTop: 3 }}>~</label>
            <Input
              id="searchThicknessEnd"
              // style={{ marginLeft: 5 }}
              placeholder="板厚"
              className={'input-editable'}
              value={searchThicknessEnd}
              allowClear
              onChange={(e) => setSearchThicknessEnd(e.target.value)}
              onPressEnter={(e) => getIQ3ThicknessByFilter(1, e.target.value)}
            />
          </div>
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}></Col>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Col span={24}>
          <Table
            id="parentCloudFileTbl"
            showSorterTooltip={false}
            rowClassName={(record) => (record.key == selectedRowKeys ? 'active-row' : 'data-row')}
            columns={cloudFileIQ3DetailColumns}
            rowKey={(record) => record.key}
            dataSource={selectedCloudPMXIQ3FileLst}
            className="parent-cloud-file-iq3-tb"
            // style={{ marginLeft: 10 }}
            rowSelection={rowSelection}
            pagination={{
              position: ['bottomLeft'],
              total: (record) => record?.length,
              showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
              defaultPageSize: 25, // 本番値確認必要
              defaultCurrent: 1,
            }}
            scroll={{ y: 'calc(100vh-220px)', x: '950' }}
          />
        </Col>
      </Row>
    </>
  );
});

export default PMX_CloudFileDialog_Tab_IQ3;
