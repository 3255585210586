/**
 * クラス名：購入品パラメータ
 * 説明：購入品情報詳細画面
 * 作成者：ケイ
 * 作成日：2023/05/01
 * バージョン：1.0
 */
import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Radio, Image, Input, Select, Row, Col, Checkbox, Popover } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  CloseCircleFilled,
  PlusCircleFilled,
  CaretUpOutlined,
  CaretDownOutlined,
  StarOutlined,
  StarFilled,
  SearchOutlined,
} from '@ant-design/icons';
import { StatusCodes } from 'http-status-codes';
import CurrencyInput from 'react-currency-input-field';

import checked from '../../../../assets/images/checked.png';
import {
  confirmDiscardContent,
  confirmUpdateContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  error,
  authorizeError,
} from '../../../common/CommonModal';
import {
  today,
  formValidatorMode,
  JPYs,
  removeYen,
  TextArea,
  isDigit,
  isDecimal,
  formatDate,
  updateAccessToken,
  currencyFormatWithDecimal,
  getAccessToken,
  isDigitZenkaku,
  regexExp,
  handleKeyPress,
} from '../../../common/Common';
import { ErrorMessage } from '../../../common/Message';
import SearchAndGetCustomerData from '../../../common/SearchAndGetCustomerData';
import { ClientType } from '../../../common/enums';
import { Supplier, purchaseCategoryDefaultValue } from '../../../common/Constant';
import { isExistPurchaseInfo } from '../../../common/CommonAPI';

const RadioGroup = Radio.Group;
dayjs.extend(customParseFormat);
const dateFormat = 'YYYY-MM-DD';

const PurchaseDetails = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    discardConfirmOk: discardConfirmOk,
    discardConfirmCancel: discardConfirmCancel,
    displayDetailInfo: displayDetailInfo,
    formValidation: formValidation,
  }));

  const inputItemRef = {};

  let token = localStorage?.getItem('iQxWeb_AccessToken');

  const editMode = props.editMode;

  const [id, setID] = useState('');
  const [purchaseCategoryId, setPurchaseCategoryId] = useState('');
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [priceLots, setPriceLots] = useState([]);
  const [priceHistories, setPriceHistories] = useState([]);
  const [isUsed, setIsUsed] = useState();
  const [isAllocation, setIsAllocation] = useState();
  const [model, setModel] = useState('');
  const [makerName, setMakerName] = useState('');
  const [supplierCode, setSupplierCode] = useState('');
  const [supplierName, setSupplierName] = useState('');
  const [isKeepPrice, setIsKeepPrice] = useState();
  const [processTime, setProcessTime] = useState();
  const [remarks, setRemarks] = useState('');
  const [sortNum, setSortNum] = useState('');
  const [created, setCreated] = useState('');
  const [modified, setModified] = useState('');
  const [modifier, setModifier] = useState('');

  const [lotUpperTh, setLotUpperTh] = useState([]);
  const [lotPrice, setLotPrice] = useState([]);

  const [historyPrice, setHistoryPrice] = useState('');
  const [historyModified, setHistoryModified] = useState('');

  const [purchaseCategoryIsUsed, setPurchaseCategoryIsUsed] = useState([]);
  const [purchaseCategoryName, setPurchaseCategoryName] = useState([]);

  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isNoData, setIsNoData] = useState(false);
  const [isPurchaseCategory, setIsPurchaseCategory] = useState(false);

  // ヘッダーバーの押下で情報を表示／非表示
  const [isPriceLots, setIsPriceLots] = useState(true);
  const [isPriceHistories, setIsPriceHistories] = useState(true);

  const [purchaseCategoryInfo, setPurchaseCategoryInfo] = useState([]);
  const [bkPurchaseCategoryInfo, setBkPurchaseCategoryInfo] = useState([]);

  const [purchaseCategoryDropList, setPurchaseCategoryDropList] = useState([]);

  // 数量別単価の初期値
  const initInfoPriceLots = { upperTh: 1, price: 0 };

  // 仕入れ先情報一覧
  const [clientModal, setClientModal] = useState(false);

  const [inValidItem, setInValidItem] = useState([]); // 入力不正な項目

  // 購入品種別の情報
  useEffect(() => {
    let categoryInfo = displayPurchaseCategoryInfo(props?.purchaseCategoryInfo);
    setPurchaseCategoryInfo(categoryInfo);
    setPurchaseCategoryDropList(props?.purchaseCategoryOptions());
    setInValidItem([]);
  }, [props.purchaseCategoryInfo]);

  // 選択した行の情報を設定
  useEffect(() => {
    let selectedRowData = props.selectedData;

    if (props.selectedData == null) {
      document.getElementById('edit').style.display = 'none';
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
      setIsNoData(false);
    } else if (props.selectedData?.length === 0) {
      // 一覧の検索結果が無い場合、
      resetDetailInfo();
      setIsNoData(true);
      props.updateEditMode(false);
    } else if (id !== selectedRowData?.id) {
      // 一覧の検索結果がある場合、
      displayDetailInfo(selectedRowData);
      setIsNoData(false);
      props.updateEditMode(false);
    }
  }, [props.selectedData]);

  // 編集モードより、「更新、破壊、編集」ボタンの表示・非表示を判断する
  useEffect(() => {
    if (props.editMode) {
      document.getElementById('edit').style.display = 'none';
      document.getElementById('update').style.display = 'block';
      document.getElementById('discard').style.display = 'block';
    } else {
      document.getElementById('edit').style.display = 'block';
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
    }
    setInValidItem([]);
  }, [props.editMode]);

  // 詳細情報をリセットする。
  const resetDetailInfo = () => {
    setID('');
    setPurchaseCategoryId('');
    setCode('');
    setName('');
    setPrice('');
    setPriceLots([]);
    setPriceHistories([]);
    setIsUsed();
    setIsAllocation();
    setModel('');
    setMakerName('');
    setSupplierCode('');
    setSupplierName('');
    setIsKeepPrice();
    setProcessTime();
    setRemarks('');
    setSortNum('');
    setCreated();
    setModified();
    setModifier('');
  };

  // 詳細情報を表示する
  const displayDetailInfo = (data) => {
    let lotUpperTh = [];
    let lotPrice = [];

    setID(data.id);
    setPurchaseCategoryId(data.purchaseCategoryId);
    setCode(data.code);
    setName(data.name);
    setPrice(data.price);
    setPriceLots(data.priceLots?.length === 0 ? [initInfoPriceLots] : data.priceLots);
    if (data.priceLots?.length === 0) {
      [initInfoPriceLots]?.map((item) => {
        lotUpperTh.push(item.upperTh);
        lotPrice.push(item.price);
      });
    } else {
      data?.priceLots?.map((item) => {
        lotUpperTh.push(item.upperTh);
        lotPrice.push(item.price);
      });
    }
    setLotUpperTh(lotUpperTh);
    setLotPrice(lotPrice);
    setPriceHistories(data.priceHistories);
    setIsUsed(data.info.isUsed);
    setIsAllocation(data.info.isAllocation);
    setModel(data.info.model);
    setMakerName(data.info.makerName);
    setSupplierCode(data.info.supplierCode);
    setSupplierName(data.info.supplierName);
    setIsKeepPrice(data.info.isKeepPrice);
    setProcessTime(data.info.processTime);
    setRemarks(data.info.remarks);
    setSortNum(data.info.sortNum);
    setCreated(data.created);
    setModified(data.modified);
    setModifier(data.modifier);
  };

  // 編集ボタンの押下
  const editData = () => {
    props.updateEditMode(true);
  };

  // 更新ボタンの押下
  const updateData = () => {
    /* setUpdateConfirm(true);
    setIsUpdate(true); */
    setUpdateConfirm(props.showEdtConfirmOK);
    if (!props.showEdtConfirmOK) {
      updConfirmOk();
    }
    setIsUpdate(true);
  };

  // 画面の入力チェック
  const formValidation = (inputItems, procMode, isDetail = true) => {
    let ret = true;
    let lineNo = 0;
    let message = [];
    let lotUpperThList = [];
    let lotPriceList = [];
    // コード
    let input = inputItems['code']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('コード').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 名称
    input = inputItems['name']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('名称').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 種別Id
    if (isDetail) {
      input = document.getElementsByName('purchaseCategoryDetailId')?.[0];
    } else {
      input = document.getElementsByName('purchaseCategoryId')?.[0];
    }
    input?.childNodes?.[0]?.classList?.remove('input-error');
    if (input?.innerText === purchaseCategoryDefaultValue && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('種別').E017}</li>);
      input?.childNodes?.[0]?.classList.add('input-error');
      ret = false;
    }

    // 単価
    input = inputItems['price'];
    input?.classList?.remove('input-error');
    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('単価').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // if (input?.value?.length > 0 && !isDecimal(input?.value)) {
    //   message.push(<li>{ErrorMessage('単価').E014}</li>);
    //   input.classList.add('input-error');
    //   ret = false;
    // }

    // 取付時間（秒）
    input = inputItems['processTime']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length > 0 && !isDigit(input?.value)) {
      message.push(<li>{ErrorMessage('取付時間（秒）').E009}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // ソート番号
    input = inputItems['sortNum']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length > 0 && !isDigit(input?.value)) {
      message.push(<li>{ErrorMessage('ソート番号').E009}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 数量別単価の数量（以上）
    lotUpperThList = Object.keys(inputItems)?.filter((v) => v.startsWith('lotUpperTh'));
    lotUpperThList?.map((name) => {
      input = inputItems[name]?.input;
      input?.classList?.remove('input-error');
      ++lineNo;
      if (input?.value?.length <= 0) {
        // 必須
        message.push(<li>{ErrorMessage('数量（以上）の' + lineNo + '行目').E007}</li>);
        input.classList.add('input-error');
        ret = false;
        return false;
      }

      if (input?.value?.length > 0 && !isDigit(input?.value)) {
        message.push(<li>{ErrorMessage('数量（以上）の' + lineNo + '行目').E009}</li>);
        input.classList.add('input-error');
        ret = false;
        return false;
      }
      return true;
    });
    if (inValidItem?.length > 0) {
      message.push(<li>{'数量別単価の数量（以上）に同じ値が含まれています。'}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 数量別単価の単価
    lineNo = 0;
    lotPriceList = Object.keys(inputItems)?.filter((v) => v.startsWith('lotPrice'));
    lotPriceList?.map((name) => {
      input = inputItems[name];
      input?.classList?.remove('input-error');
      ++lineNo;
      if (input?.value?.length <= 0) {
        // 必須
        message.push(<li>{ErrorMessage('単価の' + lineNo + '行目').E007}</li>);
        input.classList.add('input-error');
        ret = false;
        return false;
      }

      // if (input?.value?.length > 0 && !isDecimal(input?.value)) {
      //   message.push(<li>{ErrorMessage('単価の' + lineNo + '行目').E014}</li>);
      //   input.classList.add('input-error');
      //   ret = false;
      //   return false;
      // }
      return true;
    });

    if (message?.length > 0) error(<ul>{message}</ul>);

    return ret;
  };

  // 購入品種別画面の入力チェック
  const categoryFormValidation = (inputItems) => {
    let ret = true;
    let lineNo = 0;
    let message = [];
    let nameList = [];

    // 数量別単価の数量（以上）
    nameList = Object.keys(inputItems)?.filter((v) => v.startsWith('purchaseCategoryName'));
    nameList?.map((name) => {
      let input = inputItems[name]?.input;
      input?.classList?.remove('input-error');
      ++lineNo;
      if (input?.value?.length <= 0) {
        // 必須
        message.push(<li>{ErrorMessage('購入品種別の' + lineNo + '行目').E007}</li>);
        input.classList.add('input-error');
        ret = false;
        return false;
      }
      return true;
    });

    if (message?.length > 0) error(<ul>{message}</ul>);

    return ret;
  };

  // 破壊ボタンの押下
  const discardData = () => {
    //setCancelConfirm(true);
    setCancelConfirm(props.showEdtConfirmCancle);
    if (!props.showEdtConfirmCancle) {
      discardConfirmOk();
    }
  };

  // 種別ボタンの押下
  const callPurchaseCategory = () => {
    let usedList = [];
    let nameList = [];
    let tempData = [];
    tempData = orderById([...purchaseCategoryInfo]);
    tempData?.map((item) => {
      usedList.push(item.isUsed);
      nameList.push(item.name);
    });
    setPurchaseCategoryInfo([...tempData]);
    setBkPurchaseCategoryInfo([...tempData]);
    setPurchaseCategoryIsUsed(usedList);
    setPurchaseCategoryName(nameList);
    setIsPurchaseCategory(true);
  };

  // 更新確認ポップアップのOKボタン押下
  const updConfirmOk = async (e) => {
    setUpdateConfirm(false);
    if (isUpdate) {
      // 画面の入力チェック
      if (!formValidation(inputItemRef, formValidatorMode.Edit)) {
        return;
      }
      props.updateEditMode(false);
      setIsUpdate(false);
      submitData();
    } else if (isPurchaseCategory) {
      // 画面の入力チェック
      if (!categoryFormValidation(inputItemRef)) {
        return;
      }
      let tempData = orderById([...purchaseCategoryInfo]);
      setIsPurchaseCategory(false);
      //　idはない場合、削除
      for (let i = 0; i < bkPurchaseCategoryInfo?.length; i++) {
        // await bkPurchaseCategoryInfo?.map(async (record) => {
        let record = bkPurchaseCategoryInfo[i];
        if (tempData?.findIndex((temp) => temp.id === record.id) === -1) {
          let deletedData;
          deletedData = await deletePurchaseCategoryData(record.id);
          // IQX_WEBEST-281 使用しているパラメータなのに削除出来てしまう
          if (deletedData !== undefined && deletedData?.id > 0) {
            tempData = tempData?.filter((record) => record.id !== deletedData.id);
          }
        }
      }

      for (let i = 0; i < tempData?.length; i++) {
        // await tempData?.map(async (item, index) => {
        let item = tempData[i];
        let index = i;
        //　idは空白の場合、新規登録
        if (item.id === undefined) {
          let prepareCreateData = {
            name: purchaseCategoryName[index],
            info: {
              isUsed: purchaseCategoryIsUsed[index],
            },
          };
          await createPurchaseCategoryData(prepareCreateData);
        } else if (bkPurchaseCategoryInfo?.findIndex((record) => record.id === item.id) !== -1) {
          //　idはある場合、更新
          let prepareUpdateData = {
            id: item.id,
            name: purchaseCategoryName[index],
            info: {
              isUsed: purchaseCategoryIsUsed[index],
            },
          };
          await updatePurchaseCategoryData(prepareUpdateData);
        }
      }
    }
  };

  // 購入品種別情報をDBに新規登録する
  async function createPurchaseCategoryData(createData) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'PurchaseCategory';
      const otherParam = {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          name: createData.name,
          info: createData.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then(async (data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
            await props?.getPurchaseCategoryData(token);
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 購入品種別情報をDBに更新する
  async function updatePurchaseCategoryData(updateData) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'PurchaseCategory';
      const otherParam = {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          id: updateData.id,
          name: updateData.name,
          info: updateData.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then(async (data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
            await props?.getPurchaseCategoryData(token);
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 購入品種別情報をDBに削除する
  async function deletePurchaseCategoryData(id) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'PurchaseCategory/' + id;
      const otherParam = {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then(async (data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
            await props?.getPurchaseCategoryData(token);
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 更新確認ポップアップのCancelボタン押下
  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  // 破壊確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    if (isPurchaseCategory) {
      setIsPurchaseCategory(false);
      let tempData = [];
      tempData = [...bkPurchaseCategoryInfo];
      setPurchaseCategoryInfo(tempData);
    } else {
      props.updateEditMode(false);
      displayDetailInfo(props.selectedData);
    }
    setCancelConfirm(false);
  };

  // 破壊確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setCancelConfirm(false);
  };

  // 購入品種別ポップアップのOKボタン押下
  const confirmOk = () => {
    setUpdateConfirm(true);
  };

  // 購入品種別ポップアップのCancelボタン押下
  const confirmCancel = () => {
    setCancelConfirm(true);
  };

  // 入力された情報を更新する
  const submitData = () => {
    if (props.selectedData && props.selectedData.id === id) {
      priceLots?.map((item, i) => {
        item.upperTh = lotUpperTh[i];
        item.price = lotPrice[i];
      });

      if (isUpdate) {
        setModified(dayjs(dayjs()).format('YYYY-MM-DD'));
      }

      props.selectedData.purchaseCategoryId = isExistPurchaseCategory(purchaseCategoryId);
      props.selectedData.code = code;
      props.selectedData.name = name;
      props.selectedData.price = price;
      props.selectedData.priceLots = priceLots;
      props.selectedData.info.isUsed = isUsed;
      props.selectedData.info.isAllocation = isAllocation;
      props.selectedData.info.model = model;
      props.selectedData.info.makerName = makerName;
      props.selectedData.info.supplierCode = supplierCode;
      props.selectedData.info.supplierName = supplierName;
      props.selectedData.info.isKeepPrice = isKeepPrice;
      props.selectedData.info.processTime = processTime;
      props.selectedData.info.remarks = remarks;
      props.selectedData.info.sortNum = sortNum;
      props.selectedData.modified = today;

      props.submitData(props.selectedData);
    }
  };

  // 数量別単価のヘッダーバーを押下
  const clickPriceLots = () => {
    setIsPriceLots(!isPriceLots);
  };

  // 更新履歴のヘッダーバーを押下
  const clickPriceHistories = () => {
    setIsPriceHistories(!isPriceHistories);
  };

  // 行を削除する
  const deleteSelectedRow = async (index, e, btnName, record) => {
    if (btnName === 'btnPriceLots') {
      // 数量別単価
      setPriceLots(removedData(priceLots, index));
      setLotUpperTh(removedData(lotUpperTh, index));
      setLotPrice(removedData(lotPrice, index));
    } else if (btnName === 'btnPurchaseCategory') {
      // 購入品種別より所属している購入品があるかどうかのチェック
      let ret = await isExistPurchaseInfo(record?.id);
      if (ret !== '') {
        // 購入品がある場合、
        error(ret);
        return;
      }
      // 購入品種別
      let tempPurchaseCategoryData = removedData([...purchaseCategoryInfo], index);
      // tempPurchaseCategoryData?.map((value, index) => (value.no = index + 1));
      setPurchaseCategoryInfo(tempPurchaseCategoryData);
      setPurchaseCategoryIsUsed(removedData(purchaseCategoryIsUsed, index));
      setPurchaseCategoryName(removedData(purchaseCategoryName, index));
    }
  };

  // 情報を削除する
  const removedData = (data, index) => {
    return data?.filter((v, i) => i !== index);
  };

  // 数量別単価に行を追加
  const addPriceLots = (e) => {
    let tempPriceLots = [...priceLots];
    tempPriceLots.push(initInfoPriceLots);
    setPriceLots(tempPriceLots);
    resetInputPriceLots();
  };

  // 購入品種別に行を追加
  const addPurchaseCategory = (e) => {
    let tempPurchaseCategoryList = [...purchaseCategoryInfo];
    let index = tempPurchaseCategoryList?.length - 1;
    // tempPurchaseCategoryList.push({ no: tempPurchaseCategoryList[index].no + 1, used: 1, name: '' });
    tempPurchaseCategoryList.push({ id: undefined, isUsed: true, name: '' });
    setPurchaseCategoryInfo(tempPurchaseCategoryList);
    resetInputPurchaseCategory();
  };

  // 入力項目の初期値を設定
  const resetInputPriceLots = () => {
    setLotUpperTh((prev) => {
      return [...prev, lotUpperTh?.length + 1];
    });
    setLotPrice((prev) => {
      return [...prev, initInfoPriceLots.price];
    });
  };

  // 購入品種別画面で入力項目の初期値を設定
  const resetInputPurchaseCategory = () => {
    setPurchaseCategoryIsUsed((prev) => {
      return [...prev, true];
    });
    setPurchaseCategoryName((prev) => {
      return [...prev, ''];
    });
  };

  // 入力項目のチェック
  const isValid = (inputVal, name, index, lotUpperThArr) => {
    let input = '';
    let item = [];
    let ret = true;
    input = document.getElementsByName(name + index)?.[0];
    input?.classList?.remove('input-error');
    var filterItems = lotUpperThArr?.filter(function (value) {
      return Number(value) === Number(inputVal);
    });
    if (filterItems?.length > 1) {
      item = inValidItem;
      item.push(name + index);
      input?.classList.add('input-error');
      ret = false;
    }
    setInValidItem(item);
    return ret;
  };

  // 「数量別単価、購入品種別」情報に入力した場合、
  const onChangeValue = (name, value, index, isCheckZenkaku = true) => {
    let tempData = [];
    switch (name) {
      case 'lotUpperTh': // 数量（以上）
        // IQX_WEBEST-370 親部品‐購入品にロット数価格を使用している購入品情報を追加してもロット数価格情報が表示されていない。
        if (regexExp.test(value) && !isDigitZenkaku(value)) break;
        if (value === '0') value = 1;
        tempData = [...lotUpperTh];
        value = props?.onChangeInputForm(value, isCheckZenkaku); // 全角⇒半角変換
        tempData[index] = value;
        setLotUpperTh(tempData);
        if (!isValid(value, 'lotUpperTh', index, tempData)) break;
        break;
      case 'lotPrice': // 単価
        tempData = [...lotPrice];
        tempData[index] = value;
        setLotPrice(tempData);
        break;
      case 'purchaseCategoryIsUsed': // 使用
        tempData = [...purchaseCategoryIsUsed];
        tempData[index] = value;
        setPurchaseCategoryIsUsed(tempData);
        break;
      case 'purchaseCategoryName': // 種別名称
        tempData = [...purchaseCategoryName];
        tempData[index] = value;
        setPurchaseCategoryName(tempData);
        break;
    }
  };

  // 購入品種別ポップアップのヘッダー
  const purchaseCategoryTitle = (
    <div
      style={{
        width: 500,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>購入品種別</p>
    </div>
  );

  // 購入品種別ポップアップの詳細情報
  const purchaseCategoryContent = (
    <div className="overflow-auto" style={{ height: 520 }}>
      <Table className="tblPurchaseCategory">
        <thead>
          <tr className="detail-tbl-row">
            <td style={{ width: 20 }} className="param-detail-tbl-val-title">
              No
            </td>
            <td style={{ width: 20 }} className="param-detail-tbl-val-title">
              表示
            </td>
            <td style={{ width: 140 }} className="param-detail-tbl-val-title">
              種別名称
            </td>
            <td style={{ width: 70, border: 'none', backgroundColor: 'white' }}></td>
          </tr>
          {[...purchaseCategoryInfo]?.map((listValue, index) => {
            return (
              <tr key={index} className="detail-tbl-row">
                <td style={{ verticalAlign: 'middle' }}>
                  <Input className={'input-non-editable'} name="no" id={'no' + index} value={index + 1} type="text" />
                </td>
                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                  <Checkbox
                    name="purchaseCategoryIsUsed"
                    id={'purchaseCategoryIsUsed' + index}
                    style={{ marginRight: 10 }}
                    type="checkbox"
                    checked={purchaseCategoryIsUsed[index]}
                    onChange={(e) => onChangeValue('purchaseCategoryIsUsed', e.target.checked, index)}
                  ></Checkbox>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={'input-editable'}
                    name="purchaseCategoryName"
                    id={'purchaseCategoryName' + index}
                    value={purchaseCategoryName[index]}
                    onChange={(e) => onChangeValue('purchaseCategoryName', e.target.value, index)}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['purchaseCategoryName' + index] = ref;
                    }}
                    maxLength={50}
                  />
                </td>
                <td style={{ border: '0px solid', textAlign: 'left' }}>
                  {purchaseCategoryInfo?.length - 1 === index ? (
                    <PlusCircleFilled
                      className="add-icon"
                      onClick={(e) => addPurchaseCategory(e, `btnPurchaseCategory`)}
                    />
                  ) : (
                    <></>
                  )}
                  {purchaseCategoryInfo?.length > 1 ? (
                    <CloseCircleFilled
                      className="add-icon"
                      onClick={(e) => deleteSelectedRow(index, e, 'btnPurchaseCategory', listValue)}
                    />
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            );
          })}
        </thead>
      </Table>
    </div>
  );

  // 種別の有無チェック
  const isExistPurchaseCategory = (id) => {
    let record = purchaseCategoryDropList?.find((item) => item.value === id);
    if (record) {
      return id;
    } else {
      return '';
    }
  };

  // 仕入れ先名称を検索
  const getSupplierName = (e) => {
    setClientModal(true);
  };

  // 仕入れ先情報一覧に選択した、データを表示項目にセットする
  const selectedSupplierData = (selectedData) => {
    setSupplierCode(selectedData.code);
    setSupplierName(selectedData.name);
    setClientModal(false);
  };

  // 表示用の購入品種別情報
  const displayPurchaseCategoryInfo = (data) => {
    let categoryInfo = [];
    data?.map((record) => categoryInfo.push({ id: record.id, isUsed: record.info.isUsed, name: record.name }));
    return categoryInfo;
  };

  // Idで昇順する
  const orderById = (data) => {
    let sortedList = data.sort((a, b) => a.id - b.id);
    return sortedList;
  };

  return (
    <>
      <div className="d-flex" style={{ marginTop: 9, marginRight: 10 }}>
        <div>
          <Button
            className="mainButton"
            style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
            id="update"
            onClick={updateData}
          >
            更新
          </Button>
        </div>
        <div>
          <Button
            className="cancelButton"
            style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
            id="discard"
            onClick={discardData}
          >
            破棄
          </Button>
        </div>
        <div className="ms-auto">
          <Button className={isNoData ? 'disabledButton' : 'mainButton'} id="edit" onClick={editData}>
            編集
          </Button>
        </div>
      </div>

      <div style={{ height: 760, marginTop: 10, overflowX: 'hidden', overflowY: 'scroll' }}>
        <div id="showData">
          <Table id="param_detail_tbl" className="propertiesTbl">
            <thead>
              {/* 引当用 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">引当用</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <RadioGroup
                      id="isAllocation"
                      name="isAllocation"
                      defaultValue={isAllocation}
                      value={isAllocation}
                      className="radioCustomerGpEdit"
                      onChange={(e) => setIsAllocation(e.target.value)}
                    >
                      <Radio value={true}>オン</Radio>
                      <Radio value={false}>オフ</Radio>
                    </RadioGroup>
                  ) : isAllocation === true ? (
                    <StarFilled id="isAllocation" />
                  ) : isAllocation === false ? (
                    <StarOutlined id="isAllocation" />
                  ) : (
                    <></>
                  )}
                </td>
              </tr>

              {/* コード */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">コード</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="code"
                    id="code"
                    value={code}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['code'] = ref;
                    }}
                    maxLength={50}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </td>
              </tr>
              {/* 種別 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  {editMode ? (
                    <Button
                      className={'purchaseCategoryButton'}
                      id="purchaseCategoryBtn"
                      onClick={callPurchaseCategory}
                    >
                      種別
                    </Button>
                  ) : (
                    <label className="param_detail-title-label">種別</label>
                  )}
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <Select
                      className={'input-editable'}
                      id="purchaseCategoryDetailId"
                      name="purchaseCategoryDetailId"
                      value={isExistPurchaseCategory(purchaseCategoryId)}
                      options={purchaseCategoryDropList}
                      onChange={(e) => {
                        setPurchaseCategoryId(isExistPurchaseCategory(e));
                      }}
                    >
                      {purchaseCategoryInfo?.map((item, index) => (
                        <Select.Option key={index} id={'purchaseCategoryDetailId' + item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <Input
                      className={'input-non-editable'}
                      value={props?.getPurchaseCategoryName(purchaseCategoryId)}
                      type="text"
                    />
                  )}
                </td>
              </tr>
              {/* 名称 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">名称</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="name"
                    id="name"
                    value={name}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['name'] = ref;
                    }}
                    maxLength={100}
                    onChange={(e) => setName(e.target.value)}
                  />
                </td>
              </tr>
              {/* 型番 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">型番</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="model"
                    id="model"
                    value={model}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['model'] = ref;
                    }}
                    maxLength={100}
                    onChange={(e) => setModel(e.target.value)}
                  />
                </td>
              </tr>
              {/* メーカー名 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">メーカー名</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="makerName"
                    id="makerName"
                    value={makerName}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['makerName'] = ref;
                    }}
                    maxLength={150}
                    onChange={(e) => setMakerName(e.target.value)}
                  />
                </td>
              </tr>
              {/* 仕入先 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">仕入先</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <>
                      <Button
                        icon={<SearchOutlined />}
                        size="small"
                        onClick={(e) => getSupplierName(e)}
                        type="link"
                        className="supplier-search-icon"
                      ></Button>
                      <Input
                        className={'supplier-input-label input-non-editable '}
                        name="supplierName"
                        id="supplierName"
                        value={supplierName}
                        type="text"
                        ref={(ref) => {
                          inputItemRef['supplierName'] = ref;
                        }}
                        maxLength={50}
                      />
                    </>
                  ) : (
                    <Input
                      className={'input-non-editable'}
                      name="supplierName"
                      id="supplierName"
                      value={supplierName}
                      type="text"
                      ref={(ref) => {
                        inputItemRef['supplierName'] = ref;
                      }}
                      maxLength={150}
                    />
                  )}
                </td>
              </tr>
              {/* 仕入先コード */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">仕入先コード</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={'input-non-editable'}
                    name="supplierCode"
                    id="supplierCode"
                    value={supplierCode}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['supplierCode'] = ref;
                    }}
                    maxLength={50}
                  />
                </td>
              </tr>
              {/* 単価 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">単価</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <CurrencyInput
                    name="price"
                    id="price"
                    value={price}
                    defaultValue={0}
                    onValueChange={(e) => {
                      setPrice(e);
                    }}
                    ref={(ref) => {
                      inputItemRef['price'] = ref;
                    }}
                    className={
                      editMode
                        ? props?.getAntInputBoxClassName('price', editMode) + ' param-currency'
                        : props?.getAntInputBoxClassName('price', editMode)
                    }
                    prefix="¥"
                    decimalsLimit={100}
                    maxLength={Number.MAX_SAFE_INTEGER}
                  />
                </td>
              </tr>
              {/* 単価保持 */}
              {/** IQX_WEBEST-166 単価優先を非表示する->IQX_WEBEST-229 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">単価保持</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <RadioGroup
                      id="isKeepPrice"
                      name="isKeepPrice"
                      defaultValue={isKeepPrice}
                      value={isKeepPrice}
                      className="radioCustomerGpEdit"
                      onChange={(e) => setIsKeepPrice(e.target.value)}
                    >
                      <Radio value={true}>オン</Radio>
                      <Radio value={false}>オフ</Radio>
                    </RadioGroup>
                  ) : isKeepPrice ? (
                    <Image preview={false} width={13} src={checked} id="isKeepPrice" />
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
              {/* ソート番号 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">ソート番号</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="sortNum"
                    id="sortNum"
                    value={sortNum}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['sortNum'] = ref;
                    }}
                    maxLength={Number.MAX_SAFE_INTEGER}
                    onBlur={(e) => setSortNum(props?.onChangeInputForm(e.target.value))}
                    onPressEnter={(e) => setSortNum(props?.onChangeInputForm(e.target.value))}
                    onChange={(e) => setSortNum(props?.onChangeInputForm(e.target.value, false))}
                  />
                </td>
              </tr>
              {/* 作成日 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">作成日</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label className="param_detail-title-label">{created ? formatDate(created) : ''}</label>
                </td>
              </tr>
              {/* 更新日 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">更新日</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label className="param_detail-title-label">{modified ? formatDate(modified) : ''}</label>
                </td>
              </tr>
              {/* 更新者 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">更新者</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label className="param_detail-title-label">{modifier}</label>
                </td>
              </tr>
              {/* 取付時間(秒) */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">取付時間(秒)</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="processTime"
                    id="processTime"
                    value={processTime}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['processTime'] = ref;
                    }}
                    maxLength={Number.MAX_SAFE_INTEGER}
                    onBlur={(e) => setProcessTime(props?.onChangeInputForm(e.target.value))}
                    onPressEnter={(e) => setProcessTime(props?.onChangeInputForm(e.target.value))}
                    onChange={(e) => setProcessTime(props?.onChangeInputForm(e.target.value, false))}
                  />
                </td>
              </tr>
              {/* 備考 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">備考</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <TextArea
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="remarks"
                    id="remarks"
                    value={remarks}
                    style={{ margin: 5, padding: 5, width: '98.3%' }}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['remarks'] = ref;
                    }}
                    maxLength={2048}
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                </td>
              </tr>
              {/* 表示 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">表示</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <RadioGroup
                      id="isUsed"
                      name="isUsed"
                      defaultValue={isUsed}
                      value={isUsed}
                      className="radioCustomerGpEdit"
                      onChange={(e) => setIsUsed(e.target.value)}
                    >
                      <Radio value={true}>オン</Radio>
                      <Radio value={false}>オフ</Radio>
                    </RadioGroup>
                  ) : isUsed ? (
                    <Image preview={false} width={13} src={checked} id="isUsed" />
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            </thead>
          </Table>
          <div>
            {/* 数量別単価 */}
            <Row style={{ color: 'black', marginTop: 0 }} className="oyabuhin oyabuhin-row" onClick={clickPriceLots}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  <label>数量別単価</label>
                  {isPriceLots ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  )}
                </div>
              </Col>
            </Row>
            <div style={{ display: isPriceLots ? 'block' : 'none' }}>
              <Table className="tblPriceLots">
                <thead>
                  <tr className="detail-tbl-row">
                    <td className="param-detail-tbl-val-title">数量（以上）</td>
                    <td className="param-detail-tbl-val-title">単価</td>
                    <td style={{ width: 125, border: 'none', backgroundColor: 'white' }}></td>
                  </tr>
                  {[...priceLots]?.map((listValue, index) => {
                    return (
                      <tr key={index} className="detail-tbl-row">
                        <td style={{ verticalAlign: 'middle' }}>
                          <Input
                            className={editMode ? 'input-editable' : 'input-non-editable'}
                            name={'lotUpperTh' + index}
                            id={'lotUpperTh' + index}
                            value={lotUpperTh[index]}
                            onBlur={(e) => onChangeValue('lotUpperTh', e.target.value, index)}
                            onPressEnter={(e) => onChangeValue('lotUpperTh', e.target.value, index)}
                            onChange={(e) => onChangeValue('lotUpperTh', e.target.value, index, false)}
                            onKeyPress={(e) => {
                              handleKeyPress(e, true);
                            }}
                            type="text"
                            ref={(ref) => {
                              inputItemRef['lotUpperTh' + index] = ref;
                            }}
                            maxLength={Number.MAX_SAFE_INTEGER}
                          />
                          {inValidItem?.includes('lotUpperTh' + index) ? (
                            <Popover
                              content={<div style={{ padding: 7 }}>{'本値は既に入力されています。'}</div>}
                              placement="right"
                              open={true}
                            ></Popover>
                          ) : (
                            ''
                          )}
                        </td>
                        <td style={{ verticalAlign: 'middle' }}>
                          <CurrencyInput
                            name="lotPrice"
                            id={'lotPrice' + index}
                            value={lotPrice[index]}
                            defaultValue={0}
                            onValueChange={(e) => {
                              onChangeValue('lotPrice', e, index);
                            }}
                            ref={(ref) => {
                              inputItemRef['lotPrice' + index] = ref;
                            }}
                            className={
                              editMode
                                ? props?.getAntInputBoxClassName('lotPrice' + index, editMode) + ' param-currency'
                                : props?.getAntInputBoxClassName('lotPrice' + index, editMode)
                            }
                            style={{ height: 30 }}
                            prefix="¥"
                            decimalsLimit={100}
                            maxLength={Number.MAX_SAFE_INTEGER}
                          />
                        </td>
                        <td style={{ border: '0px solid', textAlign: 'left' }}>
                          {editMode ? (
                            <>
                              {priceLots?.length - 1 === index ? (
                                <PlusCircleFilled
                                  className="add-icon"
                                  onClick={(e) => addPriceLots(e, `btnPriceLots`)}
                                />
                              ) : (
                                <></>
                              )}
                              {priceLots?.length > 1 ? (
                                <CloseCircleFilled
                                  className="add-icon"
                                  onClick={(e) => deleteSelectedRow(index, e, 'btnPriceLots', listValue)}
                                />
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </thead>
              </Table>
            </div>

            {/* 更新履歴 */}
            <Row
              style={{ color: 'black', marginTop: 0 }}
              className="oyabuhin oyabuhin-row"
              onClick={clickPriceHistories}
            >
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  <label>更新履歴</label>
                  {isPriceHistories ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  )}
                </div>
              </Col>
            </Row>
            <div style={{ display: isPriceHistories ? 'block' : 'none' }}>
              <Table className="tblPriceHistories">
                <thead>
                  <tr className="detail-tbl-row">
                    <td className="param-detail-tbl-val-title">更新日</td>
                    <td className="param-detail-tbl-val-title">単価</td>
                  </tr>
                  {[...priceHistories]?.map((listValue, index) => {
                    return (
                      <tr key={index} className="detail-tbl-row">
                        <td style={{ verticalAlign: 'middle' }}>
                          <Input
                            className={'input-non-editable'}
                            name="historyModified"
                            id="historyModified"
                            value={formatDate(listValue.modified)}
                            type="text"
                          />
                        </td>
                        <td style={{ verticalAlign: 'middle' }}>
                          <Input
                            className={'input-non-editable'}
                            name="historyPrice"
                            id="historyPrice"
                            value={JPYs.format(listValue.price)}
                            type="text"
                          />
                        </td>
                      </tr>
                    );
                  })}
                </thead>
              </Table>
            </div>

            {/* 更新確認_Modal */}
            {updateConfirm
              ? commonModal(
                  updateConfirm,
                  confirmModalTitle,
                  commonFooter(updConfirmOk, updConfirmCancel),
                  null,
                  400,
                  updConfirmOk,
                  updConfirmCancel,
                  confirmUpdateContent,
                  null,
                  null,
                  false
                )
              : ''}

            {/* 破棄確認_Modal */}
            {cancelConfirm
              ? commonModal(
                  cancelConfirm,
                  confirmModalTitle,
                  commonFooter(discardConfirmOk, discardConfirmCancel),
                  null,
                  400,
                  discardConfirmOk,
                  discardConfirmCancel,
                  confirmDiscardContent,
                  null,
                  null,
                  false
                )
              : ''}

            {/* 購入品種別 */}
            {isPurchaseCategory
              ? commonModal(
                  isPurchaseCategory,
                  purchaseCategoryTitle,
                  commonFooter(confirmOk, confirmCancel),
                  null,
                  500,
                  confirmOk,
                  confirmCancel,
                  purchaseCategoryContent,
                  null,
                  null,
                  false
                )
              : ''}

            {/* 仕入れ先情報一覧 */}
            {clientModal ? (
              <SearchAndGetCustomerData
                customerModal={clientModal}
                selectedCustomerData={selectedSupplierData}
                title={Supplier}
                clientType={ClientType.Supplier}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </>
  );
});

export default PurchaseDetails;
