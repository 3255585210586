/**
 * クラス名：装置設定のチャージ金額詳細画面
 * 作成者：チュー
 * 作成日：2023/07/07
 * バージョン：1.0
 */
import React, { useState, useEffect, forwardRef } from 'react';
import { Row, Col, Input, Button, Space } from 'antd';
import { Table } from 'react-bootstrap';

import { commonModal } from '../../../common/CommonModal';

const ChargeDetails = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setEditMode(props.editMode);
  }, [props.editMode]);
  const closeModal = () => {
    props.closeModal(false);
  };
  return (
    <>
      <Row align="middle" className="mt-4">
        <Col span={2} style={{ textAlign: 'end' }}>
          <label className="chargeDetails">人件費</label>
        </Col>
        <Col span={4} style={{ textAlign: 'end' }}>
          <label style={{ marginRight: 10 }}>平均賃金</label>
        </Col>
        <Col span={4}>
          <Input></Input>
        </Col>
        <Col span={3} style={{ textAlign: 'end' }}>
          <label style={{ marginRight: 10 }}>勤務日数</label>
        </Col>
        <Col span={4}>
          <Input></Input>
        </Col>

        <Col span={3} style={{ textAlign: 'end' }}>
          <label style={{ marginRight: 10 }}>チャージ①</label>
        </Col>
        <Col span={4}>
          <Input placeholder="円/H" style={{ pointerEvents: 'none', border: 'none', textAlign: 'end' }}></Input>
        </Col>
      </Row>
      <Row align="middle" className="mt-1">
        <Col span={3} offset={10} style={{ textAlign: 'end' }}>
          <label style={{ marginRight: 10 }}>労働時間</label>
        </Col>
        <Col span={4}>
          <Input></Input>
        </Col>
      </Row>
      <Row align="middle" className="mt-4">
        <Col span={2} style={{ textAlign: 'end' }}>
          <label className="chargeDetails">機械償却費</label>
        </Col>
        <Col span={4} style={{ textAlign: 'end' }}>
          <label style={{ marginRight: 10 }}>購入金額</label>
        </Col>
        <Col span={4}>
          <Input></Input>
        </Col>
        <Col span={3} style={{ textAlign: 'end' }}>
          <label style={{ marginRight: 10 }}>稼働日数</label>
        </Col>
        <Col span={4}>
          <Input></Input>
        </Col>

        <Col span={3} style={{ textAlign: 'end' }}>
          <label style={{ marginRight: 10 }}>チャージ②</label>
        </Col>
        <Col span={4}>
          <Input placeholder="円/H" style={{ pointerEvents: 'none', border: 'none', textAlign: 'end' }}></Input>
        </Col>
      </Row>
      <Row align="middle" className="mt-1">
        <Col span={4} offset={2} style={{ textAlign: 'end' }}>
          <label style={{ marginRight: 10 }}>償却年数</label>
        </Col>
        <Col span={4}>
          <Input></Input>
        </Col>
        <Col span={3} style={{ textAlign: 'end' }}>
          <label style={{ marginRight: 10 }}>稼働時間</label>
        </Col>
        <Col span={4}>
          <Input></Input>
        </Col>
      </Row>
      <Row align="middle" className="mt-4">
        <Col span={2} style={{ textAlign: 'end' }}>
          <label className="chargeDetails">その他</label>
        </Col>
        <Col span={4} style={{ textAlign: 'end' }}>
          <label style={{ marginRight: 10 }}>保守・メンテナンス</label>
        </Col>
        <Col span={4}>
          <Input></Input>
        </Col>
        <Col span={3} style={{ textAlign: 'end' }}>
          <label style={{ marginRight: 10 }}>稼働日数</label>
        </Col>
        <Col span={4}>
          <Input></Input>
        </Col>

        <Col span={3} style={{ textAlign: 'end' }}>
          <label style={{ marginRight: 10 }}>チャージ③</label>
        </Col>
        <Col span={4}>
          <Input placeholder="円/H" style={{ pointerEvents: 'none', border: 'none', textAlign: 'end' }}></Input>
        </Col>
      </Row>
      <Row align="middle" className="mt-1">
        <Col span={3} offset={3} style={{ textAlign: 'end' }}>
          <label style={{ marginRight: 10 }}>消耗品</label>
        </Col>
        <Col span={4}>
          <Input></Input>
        </Col>
        <Col span={3} style={{ textAlign: 'end' }}>
          <label style={{ marginRight: 10 }}>稼働時間</label>
        </Col>
        <Col span={4}>
          <Input></Input>
        </Col>
        <Col span={4} offset={3}>
          <Input placeholder="円/H" style={{ pointerEvents: 'none', border: 'none', textAlign: 'end' }}></Input>
        </Col>
      </Row>
      <Row align="middle" className="mt-1">
        <Col span={3} offset={3} style={{ textAlign: 'end' }}>
          <label style={{ marginRight: 10 }}>ガス</label>
        </Col>
        <Col span={4}>
          <Input></Input>
        </Col>

        <Col span={4} offset={10}>
          <Input placeholder="円/H" style={{ pointerEvents: 'none', border: 'none', textAlign: 'end' }}></Input>
        </Col>
      </Row>
      <Row align="middle" className="mt-1">
        <Col span={3} offset={3} style={{ textAlign: 'end' }}>
          <label style={{ marginRight: 10 }}>電気</label>
        </Col>
        <Col span={4}>
          <Input></Input>
        </Col>

        <Col span={4} offset={10}>
          <Input placeholder="円/H" style={{ pointerEvents: 'none', border: 'none', textAlign: 'end' }}></Input>
        </Col>
      </Row>
      <Row align="middle" className="mt-1">
        <Col span={3} offset={3} style={{ textAlign: 'end' }}>
          <label style={{ marginRight: 10 }}>その他</label>
        </Col>
        <Col span={4}>
          <Input></Input>
        </Col>

        <Col span={4} offset={10}>
          <Input placeholder="円/H" style={{ pointerEvents: 'none', border: 'none', textAlign: 'end' }}></Input>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col span={3} offset={3} style={{ textAlign: 'end' }}>
          <label style={{ marginRight: 10 }}>備考</label>
        </Col>
        <Col span={11}>
          <Input.TextArea style={{ height: '125px' }}></Input.TextArea>
        </Col>
        <Col span={7}>
          <Row>
            <Col span={11} style={{ textAlign: 'end' }}>
              合計チャージ
            </Col>
            <Col span={13}>
              <Input placeholder="円/H" style={{ pointerEvents: 'none', border: 'none', textAlign: 'end' }}></Input>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col span={24} style={{ textAlign: 'end' }}>
              (①+②+③)
            </Col>
          </Row>
          <Row className="mt-1">
            <Col span={11} style={{ textAlign: 'end' }}>
              調整費
            </Col>
            <Col span={13}>
              <Input placeholder="円/H" style={{ pointerEvents: 'none', border: 'none', textAlign: 'end' }}></Input>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col span={11} style={{ textAlign: 'end' }}>
              <label style={{ marginRight: 3 }}>採用チャージ</label>
            </Col>
            <Col span={13}>
              <Input placeholder="円/H" style={{ textAlign: 'end' }}></Input>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col span={24} style={{ textAlign: 'end' }}>
              <a style={{ textDecoration: 'underline', color: '#1063aa' }}>リセット</a>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="end" className="mt-2">
        <Button className="mainButton" id="update" onClick={closeModal}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} id="discard" onClick={closeModal}>
          キャンセル
        </Button>
      </Row>
    </>
  );
});

export default ChargeDetails;
