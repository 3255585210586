/**
 * クラス名：装置設定詳細画面
 * 作成者：チュー
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
import { Form, Col, Input, Row, Button, Checkbox, Space, Select, Radio, Image, Tooltip, Modal } from 'antd';
import { Table as RTable } from 'react-bootstrap';
import {
  CaretUpOutlined,
  CaretDownOutlined,
  SearchOutlined,
  DownSquareFilled,
  UpSquareFilled,
} from '@ant-design/icons';
import CurrencyInput from 'react-currency-input-field';
import { CSVLink } from 'react-csv';

import ChargeAmount from './ChargeAmount';
import SetupSetting from './SetupSetting';
import MachineSpeed from './MachineSpeed';
import KaritsukiTime from './KaritsukiTime';
import TapWelding from './TapWelding';
import ProductSize from './ProductSize';
import Quantity from './Quantity';
import YousetsuDifficulty from './YousetsuDifficulty';
import Pitch from './Pitch';
import ChildPart from './ChildPart';
import WorkAccuracy from './WorkAccuracy';
import ToritsukiJikan_Itaatsu from './ToritsukiJikan_Itaatsu';
import ToritsukiJikan_Size from './ToritsukiJikan_Size';
import Device_Sagyoukeisuu from '../../iq3/devices/Device_Sagyoukeisuu';
import Device_DantoriJikan from '../../iq3/devices/Device_DantoriJikan';
import export_csv_icon from '../../../../assets/images/export_csv_icon.png';
import import_csv_icon from '../../../../assets/images/import_csv_icon.png';

import {
  souchiHandleIncreaseTime,
  souchiHandleDecreaseTime,
  JPYs,
  WorkTypes,
  processTypes,
  ServiceClasses,
  secondsToHms,
  setTimetoSec,
  handleKeyPress,
  formatDateString,
  todayTime,
  getProcessDetailTypesInfo,
  getChargesDetailValues,
  getPrepSettingDetailValues,
  getProcessDetailTypeName,
  toDecimal,
  isDigit,
  isDecimal,
} from '../../../common/Common';
import { Customer } from '../../../common/Constant';
import {
  ClientType,
  MaterialSurfaceBillingUnit,
  ServiceClass,
  WorkType,
  WorkTypeGroup,
} from '../../../common/enums.js';
import SearchAndGetCustomerData from '../../../common/SearchAndGetCustomerData.js';
import {
  confirmModalTitle,
  confirmDiscardContent,
  confirmUpdateContent,
  commonModal,
  commonFooter,
} from '../../../common/CommonModal';
import Device_Bending from '../../iq3/devices/Device_Bending';
import Device_BendingTimeFactor from '../../iq3/devices/Device_BendingTimeFactor';
import Device_BendingCorrectionTime from '../../iq3/devices/Device_BendingCorrectionTime';
import Device_SetupSetting from '../../iq3/devices/Device_SetupSetting';
import Device_ProcessType from '../../iq3/devices/Device_ProcessType';
import Device_KakouSokudo from '../../iq3/devices/Device_KakouSokudo';
import Device_KakouJikan from '../../iq3/devices/Device_KakouJikan';
import Device_ManualBari from '../../iq3/devices/Device_ManualBari';
import Device_ProductSize from '../../iq3/devices/Device_ProductSize';
import Device_BendingCorrectionTimeFactor from '../../iq3/devices/Device_BendingCorrectionTimeFactor';
import Device_ProcessInspection from '../../iq3/devices/Device_ProcessInspection';
import Device_MaterialHandlingTime from '../../iq3/devices/Device_MaterialHandlingTime';
import Device_MaterialArrangement from '../../iq3/devices/Device_MaterialArrangement';
import Device_Blank_DrillingTime from '../../iq3/devices/Device_Blank_DrillingTime';
import Device_Blank_GasType from '../../iq3/devices/Device_Blank_GasType';
import Device_Blank_Joint from '../../iq3/devices/Device_Blank_Joint';
import Device_Blank_KakouSokudoKeisuu from '../../iq3/devices/Device_Blank_KakouSokudoKeisuu';
import Device_Blank_PiercingProcessTime from '../../iq3/devices/Device_Blank_PiercingProcessTime';
import Device_Blank_GasKeisuu from '../../iq3/devices/Device_Blank_GasKeisuu';
import Device_Blank_Kakousokudo from '../../iq3/devices/Device_Blank_Kakousokudo';
import Device_Blank_PunchCondition from '../../iq3/devices/Device_Blank_PunchCondition';
import Device_Blank_OkuriSokudo from '../../iq3/devices/Device_Blank_OkuriSokudo';
import Device_Blank_RollerSpeed from '../../iq3/devices/Device_Blank_RollerSpeed';
import Device_Blank_ExtractCondition from '../../iq3/devices/Device_Blank_ExtractCondition';
import Device_Blank_PunchProcessTime from '../../iq3/devices/Device_Blank_PunchProcessTime';
import ChargeDetails from './ChargeDetails';
import Device_YousetsuShiage_DifficultyFactor from '../../iq3/devices/Device_YousetsuShiage_DifficultyFactor';
import Device_ShiageFlag from '../../iq3/devices/Device_ShiageFlag';
import { getClientInfo } from '../../../common/CommonAPI';
import checked from '../../../../assets/images/checked.png';
import { ErrorMessage } from '../../../common/Message.js';
import { find, words } from 'lodash';

const RadioGroup = Radio.Group;
const iconWidth = 35;

const DevicesDetails = forwardRef((props, ref) => {
  const csvImportRef = useRef(null);
  const csvExportRef = useRef(null);
  const messageRef = useRef([]);

  const [isBaritoriShurui, setIsBaritoriShurui] = useState(false);
  const [isCharge, setIsCharge] = useState(false);
  const [isDantoriJikan, setIsDantoriJikan] = useState(false);
  const [isSetupSetting, setIsSetupSetting] = useState(false);
  const [isKouteiNaikensa, setIsKouteiNaikensa] = useState(false);
  const [isMagekakouJikan, setIsMagekakouJikan] = useState(false);
  const [isArrangeMaterial, setIsArrangeMaterial] = useState(false);
  const [isPunch, setIsPunch] = useState(false);
  const [isPiercingProcess, setIsPiercingProcess] = useState(false);
  const [isPunchProcess, setIsPunchProcess] = useState(false);
  const [isMageHoseiJikan, setIsMageHoseiJikan] = useState(false);
  const [isMageHoseiJikanFactor, setIsMageHoseiJikanFactor] = useState(false);
  const [isProcessSizeFactor, setIsProcessSizeFactor] = useState(false);
  const [isBendingTimeFactor, setIsBendingTimeFactor] = useState(false);
  const [isProcessSpeed, setIsProcessSpeed] = useState(false);
  const [isSagyouSokudo, setIsSagyouSokudo] = useState(false);
  const [isSagyouKeisuu, setIsSagyouKeisuu] = useState(false);
  const [isiq3DantoriJikan, setIsiq3DantoriJikan] = useState(false);
  const [isiq3KakouJikan, setIsiq3KakouJikan] = useState(false);
  const [isBlankKakouSokudo, setIsBlankKakouSokudo] = useState(false);
  const [isBlankKakouSokudoKeisuu, setIsBlankKakouSokudoKeisuu] = useState(false);
  const [isBlankGasuKeisuu, setIsBlankGasuKeisuu] = useState(false);
  const [isExtractCondition, setIsExtractCondition] = useState(false);
  const [isBlankGasuType, setIsBlankGasuType] = useState(false);
  const [isBlankDrillingTime, setIsBlankDrillingTime] = useState(false);
  const [isOkuriSokudo, setIsOkuriSokudo] = useState(false);
  const [isRollerKakouSokudo, setIsRollerKakouSokudo] = useState(false);
  const [isBlankJoint, setIsBlankJoint] = useState(false);
  const [isiq3KakouSokudo, setIsiq3KakouSokudo] = useState(false);
  const [isiq3ManualBari, setIsiq3ManualBari] = useState(false);
  const [isProcessTime, setIsProcessTime] = useState(false);
  const [isAttach, setIsAttach] = useState(false);
  const [isToritsukiJikanItaatsu, setIsToritsukiJikanItaatsu] = useState(false);
  const [isToritsukiJikanSize, setIsToritsukiJikanSize] = useState(false);
  const [isWelding, setIsWelding] = useState(false);
  const [isDeviceDetails, setIsDeviceDetails] = useState(false);
  const [isTapWelding, setIsTapWelding] = useState(false);
  const [isPitch, setIsPitch] = useState(false);
  const [isProductSize, setIsProductSize] = useState(false);
  const [isProductQuantity, setIsProductQuantity] = useState(false);
  const [isChildPart, setIsChildPart] = useState(false);
  const [isDifficultyFactor, setIsDifficultyFactor] = useState(false);
  const [isShiageFlag, setIsShiageFlag] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [deviceType, setDeviceType] = useState(processTypes.standard);
  const [dantoriCharge, setDantoriChange] = useState('');
  const [detailsProcess, setDetailProcess] = useState([]);

  const [detailsData, setDetailsData] = useState([]);
  const initialValues = [8, 11, 13, 16, 17];
  // データ表示
  const [code, setCode] = useState('');
  const [detailId, setDetailId] = useState(0);
  const [processName, setProcessName] = useState('');
  const [workType, setWorkType] = useState();
  const [deviceCode, setDeviceCode] = useState('');
  const [deviceName, setDeviceName] = useState('');
  const [remarks, setRemarks] = useState('');
  const [isUsed, setIsUsed] = useState(true);
  // 追加工程種類情報
  const [kouteiName, setKouteiName] = useState('');
  const [kouteiCode, setKouteiCode] = useState('');
  const [customerId, setCustomerId] = useState();
  const [customerName, setCustomerName] = useState('');
  const [customerCode, setCustomerCode] = useState('');
  const [iniAmount, setIniAmount] = useState('');
  const [koubuhinnKeisuu, setKobuhinKeisuu] = useState('');
  const [tsuikaKouteiTypes, setTsuikaKouteiTypes] = useState([]);

  // 追加工程の段取金額と作業金額
  const [setupAmount, setSetupAmount] = useState('');
  const [workAmount, setWorkAmount] = useState('');
  // 段取時間
  const [dantoriTime, setDantoriTime] = useState('00:00:00');
  const [workTime, setWorkTime] = useState(0);
  const [sagyouTime, setSagyouTime] = useState(0); // 作業時間（x要素数）

  const [area, setArea] = useState([]);
  const [weight, setWeight] = useState([]);
  const [volume, setVolume] = useState([]);
  const [sizesDetailsList, setSizeDetailsList] = useState([]);
  const [dantoriSizeDetailsList, setDantoriSizeDetailsList] = useState([]);

  const [kihonDantori, setKihonDantori] = useState('');
  // 段取を個別に持つ
  const [dantoriFlag, setDantoriFlag] = useState('');
  const [selectedDeviceData, setSelectedDeviceData] = useState([]);
  // 名称
  const [meishou, setMeishou] = useState([]);
  const [meishouList, setMeishouList] = useState([]);
  // 数量区分
  const [suuryouData, setSuuryouData] = useState([]);
  const [suuryouList, setSuuryouList] = useState([]);
  const [dantoriSetteiSuuryouList, setdantoriSetteiSuuryouList] = useState([]);

  // 要素数
  const [yousosuuData, setYousosuuData] = useState([]);
  const [sagyouKeisuuYousosuuList, setSagyouKeisuuYousosuuList] = useState([]);
  const [shokiDankaiJikanList, setShokiDankaiJikanList] = useState([]);
  const [kensaJikanKeisuuList, setKensaJikanKeisuuList] = useState([]);

  const [jikanCharge, setJikanChaji] = useState([]);
  const [customerModal, setCustomerModal] = useState(false);

  const [initialCustomerData, setInitialCustomerData] = useState([]); // 初期表示に表示する項目
  const [initialCustomerDataBk, setInitialCustomerDataBk] = useState([]);
  const [selectedCustomerData, setSelectedCustomerData] = useState([]);
  const [selectedCustomerRowKeys, setSelectedCustomerRowKeys] = useState([]);

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [selectDisabled, setSelectDisabled] = useState(true);

  const [flagChecked, setFlagChecked] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(true);

  const [pitchItaatsuList, setPitchItaatsuList] = useState([]);

  const [tapWeldItaatsuList, setTapWeldItaatsuList] = useState([]);

  const [yousetsuNanidoItaatsuMasterData, setYousetsuNanidoItaatsuMasterData] = useState([]);
  const [yousetsuNanidoItaatsuList, setYousetsuNanidoItaatsuList] = useState([]);

  const [karitsukiTimeItaatsuMasterData, setKaritsukiTimeItaatsuMasterData] = useState([]);
  const [karitsukiTimeItaatsuList, setKaritsukiTimeItaatsuList] = useState([]);

  // 取付時間（サイズ、板厚）
  const [toritsukiTimeItaatsuMasterData, setToritsukiTimeItaatsuMasterData] = useState([]);
  const [toritsukiTimeItaatsuList, setToritsukiTimeItaatsuList] = useState([]);
  const [toritsukiTimeSizeMasterData, setToritsukiTimeSizeMasterData] = useState([]);
  const [toritsukiTimeSizeList, setToritsukiTimeSizeList] = useState([]);

  // 溶接線長
  const [detailsWeldLine, setWeldLine] = useState([]);
  const [yousetsuWeldLineList, setYousetsuWeldLineList] = useState([]);

  // 加工速度（溶接仕上線長）
  const [kakouSokudoSenchouMasterData, setKakouSokudoSenchouMasterData] = useState([]);
  const [kakouSokudoSenchouList, setKakouSokudoSenchouList] = useState([]);

  // 個数
  const [kosuu, setKosuu] = useState([]);
  const [kosuuList, setKousuuList] = useState([]);
  const [iq3KosuuList, setIQ3KosuuList] = useState([]);

  // iq3
  const [iq3SetupAmount, setiq3SetupAmount] = useState('');
  const [iq3WorkAmount, setiq3WorkAmount] = useState('');
  const [iq3ProcessInspection, setIQ3ProcessInspection] = useState('');
  const [secondProcessAmount, setSecondProcessAmount] = useState('');
  const [jointBarashiAmount, setJointBarashiAmount] = useState('');
  const [iq3ItaatsuMaster, setIQ3ItaatsuMaster] = useState([]);
  const [jointSizeMaster, setJointSizeMaster] = useState([]);
  const [iq3BendingTimeListData, setIQ3BendingTimeListData] = useState([]);
  const [gasTypeMaster, setGasTypeMaster] = useState([]);
  // 保有金型
  const [retainedMoldMaster, setRetainedMoldMaster] = useState([]);
  // ジョイントサイズ
  const [jointSizeList, setJointSizeList] = useState([]);
  // ガス種類
  const [gasTypeListData, setGasTypeListData] = useState([]);
  // ジョイント
  const [jointItaatsuListData, setJointItaatsuListData] = useState([]);
  // 加工速度係数
  const [blankKakouSokudoItaatsuList, setBlankKakouSokudoItaatsuList] = useState([]);
  // ガス係数
  const [gasKeisuuList, setGasKeisuuList] = useState([]);
  const [extractConditionList, setExtractConditionList] = useState([]);
  // 1ピアス加工時間
  const [piercingProcessItaatsuListData, setPiercingProcessItaatsuListData] = useState([]);
  // 難易度係数
  const [nanidoKeisuuList, setNanidoKeisuuList] = useState([]);
  // 1パンチ加工時間
  const [punchProcessItaatsuListData, setPunchProcessItaatsuListData] = useState([]);

  // 曲げ長さ係数
  const [length, setLength] = useState([]);
  const [bendingTimeFactorListData, setBendingTimeFactorListData] = useState([]);
  const [bendingCorrectionTimeFactorListData, setBendingCorrectionTimeFactorListData] = useState([]);
  // 補正時間係数
  const [correctionTimeFactorListData, setCorrectionTimeFactorListData] = useState([]);
  const [iq3BendingHonsuuList, setiQ3BendingHonsuuList] = useState([]);
  const [iq3BendingKosuuList, setIQ3BendingKosuuList] = useState([]);
  // 曲げ数
  const [iq3MageSuuMaster, setIQ3MageSuuMaster] = useState([]);
  const [iq3MageSuuListData, setIQ3MageSuuListData] = useState([]);
  // 曲げ個数
  const [iq3MageKosuuMaster, setIQ3MageKosuuMaster] = useState([]);
  const [iq3MageKosuuListData, setIQ3MageKosuuListData] = useState([]);
  // 子部品サイズ係数リスト
  // const [dantoriJuuryouMagechouList, setDantoriJuuryouMagechouList] = useState([]);
  // 曲げ長さ別段取時間
  const [magechouDantoriJikanListData, setMagechouDantoriJikanListData] = useState([]);
  // 面積
  const [iq3AreaMaster, setIQ3AreaMaster] = useState([]);
  const [iq3KakouJikanListData, setIQ3KakouJikanListData] = useState([]);
  // ブランク加工速度
  const [blankKakousokudolistData, setBlankKakousokudolistData] = useState([]);

  const [types, setTypes] = useState('');
  // 加工方法
  const [processMethod, setProcessMethod] = useState('');
  const [processMethods, setProcessMethods] = useState([]);
  const [processCode, setProcessCode] = useState([]);
  // 生産個数
  const [seisanKosuu, setSeisanKosuu] = useState([]);
  const [seisanKosuuList, setSeisanKosuuList] = useState([]);
  // 工程内検査の生産個数
  const [processInspectionKosuuList, setProcessInspectionKosuuList] = useState([]);

  // 本数
  const [honSuu, setHonsuu] = useState([]);
  const [honSuuList, setHonSuuList] = useState([]);
  // サイズ
  const [iq3SizeMaster, setIQ3SizeMaster] = useState([]);
  const [mmMaster, setMmMaster] = useState([]);
  const [iq3KaniListData, setIQ3KaniListData] = useState([]);
  const [iq3KaniAnaListData, setIQ3KaniAnaListData] = useState([]);
  const [itaatsu1, setItaatsu1] = useState([]);
  const [zaishitsu, setZaishitsu] = useState([]);
  // 製品重量係数
  const [iq3ProductSizeListData, setiq3ProductSizeListData] = useState([]);

  // 一部品あたり作業時間
  const [processTimeByUnit, setProcessTimeByUnit] = useState('');

  // マテリアルハンドリング時間
  const [materialHandlingTimeList, setMaterialHandlingTimeList] = useState([]);
  const [isMaterialHandling, setIsMaterialHandling] = useState(false);

  // 同一サイズ、同一形状をパターン化する
  const [programSameSizePatternValue, setProgramSameSizePatternValue] = useState([]);

  // 工程内検査
  const [processInspectionTime, setProcessInspectionTime] = useState('');

  // パンチ条件
  const [punchConditionList, setPunchConditionList] = useState('');

  const [drillingTime, setDrillingTime] = useState('');
  // 送り速度
  // const [forwardSpeed, setForwardSpeed] = useState('');
  // ローラー/ホイール/ケガキ金型　加工速度
  const [rollerSpeed, setRollerSpeed] = useState('');

  // 送り速度
  const [okuriSokudoVal, setOkuriSokudoVal] = useState('');
  // ケガキ速度
  const [kegakiSokudoVal, setKegakiSokudoVal] = useState('');

  const [kakosokudo_bari, setkakosokudo_bari] = useState('');
  const [naikei_bari, setNaikeibari] = useState('');
  const [naikei_min, setNaikeiMin] = useState('');
  const [naikei_max, setNaikeiMax] = useState('');
  const [ana_min, setAnaMin] = useState('');
  const [ana_max, setAnaMax] = useState('');

  const [additionalProcessId, setAdditionalProcessId] = useState(1);

  // One by one
  const [shirringflag, setShirringFlag] = useState(false);

  const [cutTime, setCutTime] = useState('00:00:00');

  // const [deviceTypes, setDeviceTypes] = useState('');

  // 手動バリ取りの加工速度
  const [iq3kakouchou, setIQ3Kakouchou] = useState([]);
  const [shudobariKakousokudoList, setShudoubariKakousokudoList] = useState([]);
  // チャージ金額の工程内検査
  const [kensaAmount, setKensaAmount] = useState('');

  // チャージ金額詳細
  const [isDetails, setIsDetails] = useState(false);
  // 溶接種類
  const [yousetsuTypes, setYousetsuTypes] = useState(false);
  const [shiageFlagList, setShiageFlagList] = useState([]);

  const [processMstRecord, setProcessMstRecord] = useState();

  // 工程マスタの種類リスト
  const [processTypesList, setProcessTypesList] = useState([]);

  // 材質区分データ取得
  const [materialTypes, setMaterialTypes] = useState([]);

  // IQX_WEBEST-271 サブ溶接にも溶接仕上を出来るようにしてほしい
  const [isKakouJikan, setIsKakouJikan] = useState(false);
  const [itaatsuKouteiTypeList, setItaatsuKouteiTypeList] = useState([]);
  const [sizeKouteiTypeList, setSizeuKouteiTypeList] = useState([]);
  const [weldProcessMst, setWeldProcessMst] = useState([]);
  const [filterKeys, setFilterKeys] = useState([]);

  useEffect(() => {
    let selectedRowData = JSON.parse(JSON.stringify(props.selectedData));
    setSelectedDeviceData(selectedRowData);
    setDetailsData(props.selectedData);
    let yousetsuMstRecord = props?.getProcessMstRecordByWorkType();
    // setYousetsuTypes(
    //   yousetsuMstRecord?.details.processDetailTypes?.filter((item) => item.group === 110 && item.isUsed === true)
    // );
    setYousetsuTypes(yousetsuMstRecord);

    let processMstRecord = props?.getProcessMstRecordById(selectedRowData?.processId);

    if (processMstRecord) setProcessMstRecord(processMstRecord);
    if (selectedRowData?.length != 0) {
      setMaterialTypes(props?.getMaterialTypes);

      // setDeviceTypes(selectedRowData.deviceTypes);
      // setkakosokudo_bari(selectedRowData.kakosokudo_bari);
      // setNaikeibari(selectedRowData.naikei_bari);
      // setNaikeiMin(selectedRowData.naikei_min);
      // setNaikeiMax(selectedRowData.naikei_max);
      // setAnaMin(selectedRowData.ana_min);
      // setAnaMin(selectedRowData.ana_min);
      // setAnaMax(selectedRowData.ana_max);
      // setShirringFlag(selectedRowData.shirringflag);
      // setCutTime(selectedRowData.shirringTime);
      // iq3
      // setIQ3BendingTimeListData(selectedRowData.iq3BendingTimeLstData);
      // setGasTypeListData(selectedRowData.gasTypeLstData);
      // setJointSizeList(selectedRowData.jointSizeListData);
      // setJointItaatsuListData(selectedRowData.jointItaatsuLstData);
      // setBlankKakouSokudoItaatsuList(selectedRowData.blankKakousokudoItaatsuList);
      // setPiercingProcessItaatsuListData(selectedRowData.piercingProcessItaatsuLstData);
      // setNanidoKeisuuList(selectedRowData.nanidoKeisuuLstData);
      // setPunchProcessItaatsuListData(selectedRowData.punchProcessItaatsuLstData);
      // setGasKeisuuList(selectedRowData.gasKeissuuLstData);
      // setExtractConditionList(selectedRowData.extractConditionList);
      // setIQ3ItaatsuMaster(selectedRowData.iq3ItaatsuMasterData);
      // setGasTypeMaster(selectedRowData.gasTypeMasterData);
      // setRetainedMoldMaster(selectedRowData.retrainedMoldMasterData);
      // setJointSizeMaster(selectedRowData.jointSizeMasterData);

      // 委託装置詳細
      setFlagChecked(selectedRowData?.details?.isManualInput);
      setInputDisabled(!selectedRowData?.details?.isManualInput);
      setCheckboxChecked(selectedRowData?.details?.isCommission);
      // setCustomerName(selectedRowData?.details.clientName);
      // setCustomerCode(selectedRowData?.details.clientCode);
      if (selectedRowData?.details?.clientId) {
        // IQX_WEBEST-380 パラメーター親部品、板金装置設定に追加工程の場合、取引先情報で新規作成と編集できません。
        const customers = props?.getCustomers?.data?.filter((item) => item.id === selectedRowData?.details?.clientId);

        if (customers?.length > 0) {
          setCustomerCode(customers[0].code); // Access the code from the first item in the filtered array
          setCustomerName(customers[0].name);
        }
      } else {
        setCustomerCode('');
        setCustomerName('');
      }

      setIniAmount(selectedRowData?.details?.initPrice);
      setSelectDisabled(!selectedRowData?.details?.isCommission);

      // setKosuu(selectedRowData.kosuuMasterData);
      // setKousuuList(selectedRowData.kosuuLstData);

      // 装置の詳細情報
      setDetailProcess(selectedRowData?.details);

      // 作業時間（x要素数）
      setSagyouTime(selectedRowData?.details?.workBasicTime);

      // setDantoriChange(selectedRowData.processCharge);
      setDeviceType(processMstRecord?.isAdditional ? processTypes.additional : processTypes.standard);

      setCode(processMstRecord?.code);
      setProcessName(processMstRecord?.name);
      setWorkType(processMstRecord?.workType);
      setProcessTypesList(processMstRecord?.details?.processDetailTypes);

      setDetailId(selectedRowData?.id);
      setDeviceCode(selectedRowData?.code);
      setDeviceName(selectedRowData?.name);
      setRemarks(selectedRowData?.info?.remarks);
      setIsUsed(selectedRowData?.info?.isUsed);
      setTypes(processMstRecord?.class);

      // 追加工程種類情報
      setAdditionalProcessId(selectedRowData?.details?.addProcessTypeId);
      let kouteiInfo = getAdditionalProcessInfo(processMstRecord, selectedRowData?.details?.addProcessTypeId);
      setKouteiName(kouteiInfo?.name);
      setKouteiCode(kouteiInfo?.code);

      // let worktime = secondsToHms(selectedRowData?.details.workBasicTime);

      // 1部品あたりの作業時間
      setWorkTime(selectedRowData?.details?.workBasicTime);
      // 溶接線長
      setWeldLine(selectedRowData?.details?.workSpeedThMaster);
      // setYousetsuWeldLineList(selectedRowData?.details.workSpeedThItems);
      setKakouSokudoSenchouList(selectedRowData?.details?.workSpeedThItems);

      // 加工速度（板厚）
      // setKakouSokudoSenchouMasterData(selectedRowData.yousetsuShiageSenchouMasterData);

      // setArea(selectedRowData.areaMasterData);
      // setWeight(selectedRowData.weightMasterData);
      // setSizeDetailsList(selectedRowData.sizeListData);
      // setVolume(selectedRowData.volumeMasterData);
      // setDantoriSizeDetailsList(selectedRowData.dantoriSizeListData);

      // setKihonDantori(selectedRowData.kihonDantoriTime);
      // setDantoriFlag(selectedRowData.dantoriFlag);
      // setProcessInspectionTime(selectedRowData.processInspectionTime);

      // 1検査時間
      setProcessTimeByUnit(selectedRowData?.details?.inspectionWorkTime);

      // 名称
      // setMeishou(selectedRowData.meishouDetails);
      // setMeishouList(selectedRowData.meishouLstData);
      // 数量区分
      // setSuuryouData(selectedRowData.kobuhinKeisuuMasterData);
      // setSuuryouList(selectedRowData.kobuhinKeisuuLstData);
      // setdantoriSetteiSuuryouList(selectedRowData.dantoriSetteiSuuryouLstData);

      // 要素数
      // setYousosuuData(selectedRowData.yousosuuMasterData);
      // setSagyouKeisuuYousosuuList(selectedRowData.sagyouJikanKeisuuLstData);
      // setShokiDankaiJikanList(selectedRowData.shokiDankaiJikanLstData);
      // setKensaJikanKeisuuList(selectedRowData.kensaJikanKeisuuLstData);

      // setJikanChaji(selectedRowData.jikanChajji);

      // setInitialCustomerData([]);
      // setInitialCustomerDataBk([]);

      // setKobuhinKeisuu(selectedRowData.kobuhinSuu);
      // setPitchItaatsuList(selectedRowData.pitchItaatsuLstData);
      // setTapWeldItaatsuList(selectedRowData.tapWeldingItaatsuLstData);

      // setYousetsuNanidoItaatsuMasterData(selectedRowData.yousetsuNanidoItaatsuMasterData);
      // setYousetsuNanidoItaatsuList(selectedRowData.yousetsuNanidoItaatsuLstData);

      // setKaritsukiTimeItaatsuMasterData(selectedRowData.karitsukiJikanItaatsuMasterData);
      // setKaritsukiTimeItaatsuList(selectedRowData.karitsukiJikanItaatsuLstData);

      // setToritsukiTimeItaatsuList(selectedRowData.toritsukiJikanItaatsuLstData); //別々テーブル
      // setToritsukiTimeItaatsuMasterData(selectedRowData.karitsukiJikanItaatsuMasterData);

      // setToritsukiTimeSizeMasterData(selectedRowData.toritsukiJikanSizeMasterData);
      // setToritsukiTimeSizeList(selectedRowData.toritsukiJikanSizeLstData);

      // setSetupAmount(selectedRowData.setupCharge);
      // setWorkAmount(selectedRowData.processCharge);
      // setDantoriTime(selectedRowData.setupTime);
      // setWorkTime(selectedRowData.workTime);
      // setTsuikaKouteiTypes(selectedRowData.tsuikaKouteiTypes);

      // setiq3SetupAmount(selectedRowData.iq3SetupAmount);
      // setiq3WorkAmount(selectedRowData.iq3WorkAmount);
      // setIQ3ProcessInspection(selectedRowData.iq3ProcessInspection);
      // setSecondProcessAmount(selectedRowData.secondProcessAmount);
      // setJointBarashiAmount(selectedRowData.jointBarashiAmount);
      // setIQ3KosuuList(selectedRowData.iq3DantoriJikanKosuuLstData);
      // setBendingTimeFactorListData(selectedRowData.bendingTimeFactorLstData);
      // setBendingCorrectionTimeFactorListData(selectedRowData.bendingCorrectionTimeFactorLstData);
      // setCorrectionTimeFactorListData(selectedRowData.correctionTimeFactorLstData);
      // setiq3ProductSizeListData(selectedRowData.iq3ProductSizeLstData);
      // setLength(selectedRowData?.lengthMasterData);
      // setiQ3BendingHonsuuList(selectedRowData.iq3BendingHonsuuLstData);
      // setIQ3BendingKosuuList(selectedRowData.iq3BendingkosuuLstData);
      // setIQ3MageSuuMaster(selectedRowData.mageSuuMasterData);
      // setIQ3MageSuuListData(selectedRowData.mageSuuLstData);
      // setIQ3MageKosuuMaster(selectedRowData.mageKosuuMasterData);
      // setIQ3MageKosuuListData(selectedRowData.mageKosuuLstData);

      // setDantoriJuuryouMagechouList(selectedRowData.dantoriSizeLstData);
      // setMagechouDantoriJikanListData(selectedRowData.iq3MagechouDantoriJikanListData);
      // setIQ3AreaMaster(selectedRowData.iq3AreaMasterData);
      // setIQ3KakouJikanListData(selectedRowData.iq3KakouJikanListData);
      // setBlankKakousokudolistData(selectedRowData.blankKakousokudoListData);
      // setProcessMethod(selectedRowData.kakouType);
      // setProcessMethods(selectedRowData.kakouMethods);
      // setProcessCode(selectedRowData.kakouCode);
      // setSeisanKosuu(selectedRowData.seisanKosuuMasterData);
      // setSeisanKosuuList(selectedRowData.seisanKosuuLstData);
      // setProcessInspectionKosuuList(selectedRowData.processInspectionKosuuLstData);
      // setHonsuu(selectedRowData.honsuuMasterData);
      // setHonSuuList(selectedRowData.honsuuLstData);
      // setIQ3SizeMaster(selectedRowData.iq3SizeMasterData);
      // setMmMaster(selectedRowData.iq3MmMasterData);

      // setIQ3KaniListData(selectedRowData.iq3KaninyuryokuListData);
      // setIQ3KaniAnaListData(selectedRowData.iq3KaninyuryokuAnaListData);
      // setItaatsu1(selectedRowData.itaatsu1MasterData);

      // setZaishitsu(selectedRowData.zaishitsuKubunn);
      // setMaterialHandlingTimeList(selectedRowData.materialHandlingTime);
      // setProgramSameSizePatternValue(selectedRowData.programSameSizePatternValue);

      // setPunchConditionList(selectedRowData.punchConditionList);
      // setDrillingTime(selectedRowData.drillingTime);
      // setForwardSpeed(selectedRowData.forwardSpeed);
      // setRollerSpeed(selectedRowData.rollerSpeed);
      // setOkuriSokudoVal(selectedRowData.okuriSokudoVal);
      // setKegakiSokudoVal(selectedRowData.kegakiSokudoVal);
      // setIQ3Kakouchou(selectedRowData.iq3KakouchouMasterData);
      // setShudoubariKakousokudoList(selectedRowData.iq3ShudouBariKakouSokudoListData);
      // setKensaAmount(selectedRowData.kouteinaiKensaAmount);

      // setShiageFlagList(selectedRowData.shiageFlagListData);
      // IQX_WEBEST-271 サブ溶接にも溶接仕上を出来るようにしてほしい
      let weldProcessMstRecord = props?.getProcessMstRecordByWorkType(WorkType.SmWelding);
      setWeldProcessMst(weldProcessMstRecord);
      let itaatsuTypes = weldProcessMstRecord?.details?.processDetailTypes?.filter(
        (item) => item.group === WorkTypeGroup.SmInstThick
      );
      if (itaatsuTypes?.length > 0) setItaatsuKouteiTypeList(itaatsuTypes);
      let sizeTypes = weldProcessMstRecord?.details?.processDetailTypes?.filter(
        (item) => item.group === WorkTypeGroup.SmInstSize
      );
      if (sizeTypes?.length > 0) setSizeuKouteiTypeList(sizeTypes);
      const usedIds = processMstRecord?.details?.processDetailTypes
        ?.filter((item) => item.isUsed)
        ?.map((item) => item.id);
      setFilterKeys(usedIds);
    } else {
      setProcessName('');
      setCode('');
      setDeviceCode('');
      setDeviceName('');
      setRemarks('');
      setIsUsed(true);
    }
  }, [props.selectedData, props.getMaterialTypes]);

  useEffect(() => {
    setIsCharge(props.flag);
  }, [props.flag]);

  useEffect(() => {
    setEditMode(props.editMode != undefined ? props.editMode : false);
  }, [props.editMode]);

  // const getClientInfoAPI = async (clientId) => {
  //   let result = await getClientInfo();
  //   const filterdData = result?.filter((item) => item.id === clientId);
  //   return filterdData;
  // };
  useImperativeHandle(ref, () => ({}));

  // 工程種別名を得る
  const getWorkTypeName = (value) => {
    return WorkTypes?.filter((item) => item?.value === value)?.[0]?.label;
  };

  // 追加工程種類名を得る
  const getAdditionalProcessInfo = (masterInfoRecord, id) => {
    return masterInfoRecord?.details?.processDetailTypes?.filter((item) => item.id === id)?.[0];
  };

  const chargeAmount = () => {
    if (isCharge == true) {
      setIsCharge(false);
      props.updatedFlag(false);
    } else {
      setIsCharge(true);
      props.updatedFlag(true);
    }
  };
  const baritoriShurui = () => {
    if (isBaritoriShurui == true) {
      setIsBaritoriShurui(false);
    } else {
      setIsBaritoriShurui(true);
    }
  };
  const dantoriJikan = () => {
    if (isDantoriJikan === true) {
      setIsDantoriJikan(false);
    } else {
      setIsDantoriJikan(true);
    }
  };
  const setupSetting = () => {
    if (isSetupSetting == true) {
      setIsSetupSetting(false);
    } else {
      setIsSetupSetting(true);
    }
  };
  const kouteiNaikensa = () => {
    if (isKouteiNaikensa == true) {
      setIsKouteiNaikensa(false);
    } else {
      setIsKouteiNaikensa(true);
    }
  };
  const magekakouJikan = () => {
    if (isMagekakouJikan === true) {
      setIsMagekakouJikan(false);
    } else {
      setIsMagekakouJikan(true);
    }
  };
  const arrangeMaterial = () => {
    if (isArrangeMaterial === true) {
      setIsArrangeMaterial(false);
    } else {
      setIsArrangeMaterial(true);
    }
  };
  const punch = () => {
    if (isPunch === true) {
      setIsPunch(false);
    } else {
      setIsPunch(true);
    }
  };
  const piercingProcess = () => {
    if (isPiercingProcess === true) {
      setIsPiercingProcess(false);
    } else {
      setIsPiercingProcess(true);
    }
  };
  const punchProcess = () => {
    if (isPunchProcess === true) {
      setIsPunchProcess(false);
    } else {
      setIsPunchProcess(true);
    }
  };
  const mageHoseiJikan = () => {
    if (isMageHoseiJikan === true) {
      setIsMageHoseiJikan(false);
    } else {
      setIsMageHoseiJikan(true);
    }
  };
  const mageHoseiJikanFactor = () => {
    if (isMageHoseiJikanFactor === true) {
      setIsMageHoseiJikanFactor(false);
    } else {
      setIsMageHoseiJikanFactor(true);
    }
  };
  const processSizeFactor = () => {
    if (isProcessSizeFactor === true) {
      setIsProcessSizeFactor(false);
    } else {
      setIsProcessSizeFactor(true);
    }
  };
  const bendingTimeFactor = () => {
    if (isBendingTimeFactor === true) {
      setIsBendingTimeFactor(false);
    } else {
      setIsBendingTimeFactor(true);
    }
  };
  const processSpeed = () => {
    if (isProcessSpeed == true) {
      setIsProcessSpeed(false);
    } else {
      setIsProcessSpeed(true);
    }
  };
  const sagyouSokudo = () => {
    if (isSagyouSokudo == true) {
      setIsSagyouSokudo(false);
    } else {
      setIsSagyouSokudo(true);
    }
  };
  const sagyouKeisuu = () => {
    if (isSagyouKeisuu == true) {
      setIsSagyouKeisuu(false);
    } else {
      setIsSagyouKeisuu(true);
    }
  };
  const iq3DantoriJikan = () => {
    if (isiq3DantoriJikan === true) {
      setIsiq3DantoriJikan(false);
    } else {
      setIsiq3DantoriJikan(true);
    }
  };
  const iq3KakouJikan = () => {
    if (isiq3KakouJikan === true) {
      setIsiq3KakouJikan(false);
    } else {
      setIsiq3KakouJikan(true);
    }
  };
  const blankKakouSokudo = () => {
    if (isBlankKakouSokudo === true) {
      setIsBlankKakouSokudo(false);
    } else {
      setIsBlankKakouSokudo(true);
    }
  };
  const blankKakouSokudoKeisuu = () => {
    if (isBlankKakouSokudoKeisuu === true) {
      setIsBlankKakouSokudoKeisuu(false);
    } else {
      setIsBlankKakouSokudoKeisuu(true);
    }
  };
  const blankGasuKeisuu = () => {
    if (isBlankGasuKeisuu === true) {
      setIsBlankGasuKeisuu(false);
    } else {
      setIsBlankGasuKeisuu(true);
    }
  };
  const extractCondition = () => {
    if (isExtractCondition === true) {
      setIsExtractCondition(false);
    } else {
      setIsExtractCondition(true);
    }
  };
  const blankGasuType = () => {
    if (isBlankGasuType === true) {
      setIsBlankGasuType(false);
    } else {
      setIsBlankGasuType(true);
    }
  };
  const blankDrillingTime = () => {
    if (isBlankDrillingTime === true) {
      setIsBlankDrillingTime(false);
    } else {
      setIsBlankDrillingTime(true);
    }
  };
  const okuriSokudo = () => {
    if (isOkuriSokudo === true) {
      setIsOkuriSokudo(false);
    } else {
      setIsOkuriSokudo(true);
    }
  };
  const rollerKakouSokudo = () => {
    if (isRollerKakouSokudo === true) {
      setIsRollerKakouSokudo(false);
    } else {
      setIsRollerKakouSokudo(true);
    }
  };
  const blankJoint = () => {
    if (isBlankJoint === true) {
      setIsBlankJoint(false);
    } else {
      setIsBlankJoint(true);
    }
  };
  const iq3KakouSokudo = () => {
    if (isiq3KakouSokudo === true) {
      setIsiq3KakouSokudo(false);
    } else {
      setIsiq3KakouSokudo(true);
    }
  };
  const iq3ManualBari = () => {
    if (isiq3ManualBari === true) {
      setIsiq3ManualBari(false);
    } else {
      setIsiq3ManualBari(true);
    }
  };
  const processTime = () => {
    if (isProcessTime == true) {
      setIsProcessTime(false);
    } else {
      setIsProcessTime(true);
    }
  };
  const attach = () => {
    if (isAttach == true) {
      setIsAttach(false);
    } else {
      setIsAttach(true);
    }
  };
  // IQX_WEBEST-271 サブ溶接にも溶接仕上を出来るようにしてほしい
  const kakouJikan = () => {
    if (isKakouJikan === true) {
      setIsKakouJikan(false);
    } else {
      setIsKakouJikan(true);
    }
  };
  const toritsukiJikanItaatsu = () => {
    if (isToritsukiJikanItaatsu === true) {
      setIsToritsukiJikanItaatsu(false);
    } else {
      setIsToritsukiJikanItaatsu(true);
    }
  };
  const toritsukiJikanSize = () => {
    if (isToritsukiJikanSize === true) {
      setIsToritsukiJikanSize(false);
    } else {
      setIsToritsukiJikanSize(true);
    }
  };
  const welding = () => {
    if (isWelding == true) {
      setIsWelding(false);
    } else {
      setIsWelding(true);
    }
  };
  const deviceDetails = () => {
    if (isDeviceDetails === true) {
      setIsDeviceDetails(false);
    } else {
      setIsDeviceDetails(true);
    }
  };
  const tapWelding = () => {
    if (isTapWelding == true) {
      setIsTapWelding(false);
    } else {
      setIsTapWelding(true);
    }
  };
  const pitch = () => {
    if (isPitch == true) {
      setIsPitch(false);
    } else {
      setIsPitch(true);
    }
  };
  const productSize = () => {
    if (isProductSize == true) {
      setIsProductSize(false);
    } else {
      setIsProductSize(true);
    }
  };
  const productQuantity = () => {
    if (isProductQuantity == true) {
      setIsProductQuantity(false);
    } else {
      setIsProductQuantity(true);
    }
  };
  const childPart = () => {
    if (isChildPart == true) {
      setIsChildPart(false);
    } else {
      setIsChildPart(true);
    }
  };
  const difficultyFactor = () => {
    if (isDifficultyFactor == true) {
      setIsDifficultyFactor(false);
    } else {
      setIsDifficultyFactor(true);
    }
  };
  const shiageFlag = () => {
    if (isShiageFlag == true) {
      setIsShiageFlag(false);
    } else {
      setIsShiageFlag(true);
    }
  };
  const materialHandling = () => {
    if (isMaterialHandling == true) {
      setIsMaterialHandling(false);
    } else {
      setIsMaterialHandling(true);
    }
  };
  const editData = () => {
    setEditMode(true);
    props.updatedFlag(isCharge);
    props.updateEditMode(true);
  };
  const updateData = () => {
    //  setUpdateConfirm(true);
    setUpdateConfirm(props.showEdtConfirmOK);
    if (!props.showEdtConfirmOK) {
      updConfirmOk();
    }
  };

  const discardData = () => {
    // setCancelConfirm(true);

    setCancelConfirm(props.showEdtConfirmCancle);
    if (!props.showEdtConfirmCancle) {
      discardConfirmOk();
    }
  };

  const updConfirmOk = (e) => {
    setEditMode(false);
    props.updateEditMode(false);
    setUpdateConfirm(false);
    selectedDeviceData.name = deviceName;
    selectedDeviceData.code = deviceCode;
    selectedDeviceData.info.remarks = remarks;
    selectedDeviceData.info.isUsed = isUsed;
    selectedDeviceData.details = detailsProcess;
    selectedDeviceData.updateId = selectedDeviceData.id;
    // プログラムの場合、
    if (workType === WorkType.SmProgram) {
      selectedDeviceData.details.workBasicTime = sagyouTime; // 作業時間（x要素数）
    } else {
      selectedDeviceData.details.workBasicTime = workTime;
    }
    selectedDeviceData.details.isCommission = checkboxChecked;

    selectedDeviceData.details.clientId = customerId;
    selectedDeviceData.details.isManualInput = flagChecked;
    selectedDeviceData.details.initPrice = iniAmount;
    selectedDeviceData.details.inspectionWorkTime = processTimeByUnit; // 1検査時間
    selectedDeviceData.details.addProcessTypeId = additionalProcessId;

    props.updatedDetails(selectedDeviceData);
  };

  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  const discardConfirmOk = () => {
    props.updateEditMode(false);
    setCancelConfirm(false);
    setEditMode(false);
    setDeviceName(detailsData['name']);
    setDeviceCode(detailsData['code']);
    setRemarks(detailsData.info['remarks']);
    setIsUsed(detailsData.info['isUsed']);
    setDetailProcess(detailsData['details']);
  };

  const discardConfirmCancel = () => {
    setCancelConfirm(false);
    props.updateEditMode(true);
  };

  const updateShiageFlagList = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, difficultyCoeff: result }));
  };

  //　チャージ金額の値を入力した場合、
  const updatedChargeAmount = (updDetails) => {
    setDetailProcess((prevData) => ({ ...prevData, charges: updDetails }));
  };

  const updatedCharge = (result) => {
    setJikanChaji(result);
  };
  // 送り速度(mm/min)
  const updateForwardSpeed = (result) => {
    // setForwardSpeed(result);
    setDetailProcess((prevData) => ({ ...prevData, feedSpeed: result }));
  };
  // ローラー/ホイール/ケガキ金型　加工速度(mm/sec)加工速度(mm/分)
  const updateRollerSpeed = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, workSpeed: result }));
  };
  const updateDrillingTime = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, twoStepHoleworkTime: result }));
  };
  // パンチ条件データ更新
  const updatePunchList = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, punches: result }));
  };
  // 材料配置データ更新
  const updateMaterialArrangement = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, materialArrange: result }));
  };
  // 1ピアス加工時間の板厚マスタ値とリスト更新
  const updatePiercingTimeThickThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, piercingTimeThickThMaster: result }));
  };
  const updatePiercingTimeThItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, piercingTimeThItems: result }));
  };
  const updateProcessMethod = (result) => {
    setProcessMethod(result);
  };
  const updateSetupAmount = (result) => {
    setiq3SetupAmount(result);
  };
  const updateWorkAmount = (result) => {
    setiq3WorkAmount(result);
  };
  const updateiq3ProcessInspection = (result) => {
    setIQ3ProcessInspection(result);
  };
  const updatesecondProcessAmount = (result) => {
    setSecondProcessAmount(result);
  };
  const updatejointBarashiAmount = (result) => {
    setJointBarashiAmount(result);
  };
  const updateKensaAmount = (result) => {
    setKensaAmount(result);
  };

  // 段取設定の値を入力された場合、
  const updatedDantori = (updDetails) => {
    setDetailProcess((prevData) => ({ ...prevData, prepSecs: updDetails }));
  };

  // 数量のマスタ値を入力された場合、
  const updateSuuryouMstData = (updDetails) => {
    // setSuuryouData(result);
    setDetailProcess((prevData) => ({ ...prevData, prepThQtyMaster: updDetails }));
  };

  // 子部品点数の値を入力された場合、
  const updatedSuuryouChildList = (updDetails) => {
    setDetailProcess((prevData) => ({ ...prevData, prepCoeffThChildQtyItems: updDetails }));
  };

  // 子部品種類数の値を入力された場合、
  const updatedSuuryouChildTypeList = (updDetails) => {
    setDetailProcess((prevData) => ({ ...prevData, prepCoeffThChildTypeQtyItems: updDetails }));
  };

  // 子部品点数又子部品種類数のチェックボタンを変更された場合、
  const updatedPreparationCoeffType = (updDetails) => {
    setDetailProcess((prevData) => ({ ...prevData, prepCoeffType: updDetails }));
  };

  // 重量マスタ値を入力された場合、
  const updatedWeightMstData = (updDetails) => {
    setDetailProcess((prevData) => ({ ...prevData, prepCoeffThWeightMaster: updDetails }));
  };

  // 重量リスト値を入力された場合、
  const updatedWeightListData = (updDetails) => {
    setDetailProcess((prevData) => ({ ...prevData, prepCoeffThWeightItems: updDetails }));
  };

  const updatedantoriSetteiSuuryouList = (result) => {
    setdantoriSetteiSuuryouList(result);
  };

  // 基本段取時間を入力された場合、
  const updatedBasicTime = (updDetails) => {
    // setKihonDantori(result);
    setDetailProcess((prevData) => ({ ...prevData, prepBasicTime: updDetails }));
  };

  // 段取按分しないを変更された場合、
  const updateApportionSelect = (updDetails) => {
    // setDantoriFlag(result);
    setDetailProcess((prevData) => ({ ...prevData, apportionSelect: updDetails }));
  };

  const updateDantoriFlag = (result) => {
    setDantoriFlag(result);
  };
  // 加工速度の溶接線長マスタ値を入力された場合、
  const updatedYousetsuSenchouData = (result) => {
    // setWeldLine(result);
    setDetailProcess((prevData) => ({ ...prevData, workSpeedThMaster: result }));
  };

  const updateYousetsuSenchouList = (result) => {
    setYousetsuWeldLineList(result);
  };

  const updateKakousokudoSenchouList = (result) => {
    // setKakouSokudoSenchouList(result);
    setDetailProcess((prevData) => ({ ...prevData, workSpeedThItems: result }));
  };
  // 仮付き時間の板厚マスタ値を入力された場合、
  const upadateKaritsukiItaatsuData = (result) => {
    // setKaritsukiTimeItaatsuMasterData(result);
    setDetailProcess((prevData) => ({ ...prevData, tempInstThMaster: result }));
  };
  // 取付時間(板厚判断)の板厚マスタ値を入力された場合、
  const updateInstThickThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, instThickThMaster: result }));
  };
  // 仮付き時間の板厚マスタ値により、リスト更新
  const updateKaritsukiTimeList = (result) => {
    // setKaritsukiTimeItaatsuList(result);
    setDetailProcess((prevData) => ({ ...prevData, tempInstThItems: result }));
  };
  // 取付時間(板厚判断)の板厚マスタ値により、リスト更新
  const updateInstThickThItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, instThickThItems: result }));
  };
  // IQX_WEBEST-271 加工時間(板厚判断)の板厚マスタ値を入力された場合、
  const updateKakouTimeInstThickThMaster = (result, kouteiTypeId) => {
    const data = { ...detailsProcess };
    data?.kakouTimeItems
      ?.filter((item) => Number(item.kouteiTypeId) === kouteiTypeId)
      ?.map((key) => {
        key.instThickThMaster = result;
      });
    setDetailProcess(data);
  };
  // IQX_WEBEST-271 加工時間(板厚判断)の値を入力された場合、
  const updateKakouTimeInstThickThItems = (result, kouteiTypeId) => {
    const data = { ...detailsProcess };
    data?.kakouTimeItems
      ?.filter((item) => Number(item.kouteiTypeId) === kouteiTypeId)
      ?.map((key) => {
        key.instThickThItems = result;
      });
    setDetailProcess(data);
  };
  // IQX_WEBEST-271 加工時間(サイズ判断)の板厚マスタ値を入力された場合、
  const updateKakouTimeInstSizeThMaster = (result, kouteiTypeId) => {
    const data = { ...detailsProcess };
    data?.kakouTimeItems
      ?.filter((item) => Number(item.kouteiTypeId) === kouteiTypeId)
      ?.map((key) => {
        key.instSizeThMaster = result;
      });
    setDetailProcess(data);
  };
  // IQX_WEBEST-271 加工時間(サイズ判断)の値を入力された場合、
  const updateKakouTimeInstSizeThItems = (result, kouteiTypeId) => {
    const data = { ...detailsProcess };
    data?.kakouTimeItems
      ?.filter((item) => Number(item.kouteiTypeId) === kouteiTypeId)
      ?.map((key) => {
        key.instSizeThItems = result;
      });
    setDetailProcess(data);
  };
  const updateSpotPitchThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, spotPitchThMaster: result }));
  };
  const updateSpotPitchThItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, spotPitchThItems: result }));
  };
  // 溶接仕上の難易度係数更新
  const updateDiffFactorCoeff = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, diffFactorCoeff: result }));
  };

  // 曲げ補正時間の本数マスタ値更新
  const updateModifyTimeQtyThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, modifyTimeQtyThMaster: result }));
  };
  const updateModifyTimeItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, modifyTimeItems: result }));
  };
  // 曲げ補正時間のkgとmmマスタ値更新
  const updateModifyCoeffLenThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, modifyCoeffLenThMaster: result }));
  };
  const updateModifyCoeffWeightThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, modifyCoeffWeightThMaster: result }));
  };
  const updateMtWeightThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, mtWeightThMaster: result }));
  };
  const updateModifyCoeffItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, modifyCoeffItems: result }));
  };
  // 加工補正時間係数
  const updatedworkCorrectionTimeListData = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, mtTimeCoeffItems: result }));
  };
  const updateMtAreaThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, mtAreaThMaster: result }));
  };
  // 手動バリ取対象サイズの内径最小丸穴径(mm)の値更新
  const updateRoundHoleDia = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, roundHoleDia: result }));
  };
  // 手動バリ取対象サイズの内径最小矩形周長(mm)の値更新
  const updateRectangleDia = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, rectangleDia: result }));
  };
  const updatedLists = (result) => {
    setYousetsuNanidoItaatsuList(result);
  };

  const updateProductSizeArea = (result) => {
    setArea(result);
  };

  //
  const updateKosuu = (result) => {
    setKosuu(result);
  };

  // 生産個数マスタの値を変更した場合、
  const updateSeisanKosuu = (result) => {
    // setSeisanKosuu(result);
    setDetailProcess((prevData) => ({ ...prevData, prepQtyCoeffThMaster: result }));
  };

  // 本数マスタ値を更新
  const updateHonsuu = (result) => {
    // setHonsuu(result);
    if (workType === WorkType.SmBending) {
      setDetailProcess((prevData) => ({ ...prevData, programCreateTimeCountfThMaster: result }));
    } else {
      setDetailProcess((prevData) => ({ ...prevData, prepMoldQtyCoeffThMaster: result }));
    }
  };

  // 生産個数リストの値を変更した場合、
  const updateiq3SeisanKosuuList = (result) => {
    // setSeisanKosuuList(result);
    setDetailProcess((prevData) => ({ ...prevData, prepQtyCoeffItems: result }));
  };

  const updateProcessInspectionKosuuList = (result) => {
    setProcessInspectionKosuuList(result);
  };

  // 本数リスト値を更新
  const updateHonsuuList = (result) => {
    // setHonSuuList(result);
    if (workType === WorkType.SmBending) {
      setDetailProcess((prevData) => ({ ...prevData, programCreateTimeItems: result }));
    } else {
      setDetailProcess((prevData) => ({ ...prevData, prepMoldQtyCoeffItems: result }));
    }
  };

  // 基本段取時間
  const updateDantoriTime = (result) => {
    // setKihonDantori(result);
    setDetailProcess((prevData) => ({ ...prevData, prepBasicTime: result }));
  };

  const updateProcessInspectionTime = (result) => {
    setProcessInspectionTime(result);
  };
  const updateMageKosuu = (result) => {
    setIQ3MageKosuuMaster(result);
  };
  const updateMageKosuList = (result) => {
    setIQ3MageKosuuListData(result);
  };
  const updateiq3BendingHonsuuList = (result) => {
    setiQ3BendingHonsuuList(result);
  };
  const updateIQ3BendingKosuuList = (result) => {
    setIQ3BendingKosuuList(result);
  };
  const updateiq3KosuuList = (result) => {
    setSeisanKosuuList(result);
  };

  // 生産個数係数の個数マスタ値を入力された場合、
  const updateProductionQtyCoeffMst = (updDetails) => {
    // setKousuuList(result);
    setDetailProcess((prevData) => ({ ...prevData, productionQtyCoeffThMaster: updDetails }));
  };

  // 生産個数係数を入力された場合、
  const updateProductionQtyCoeffList = (updDetails) => {
    // setKousuuList(result);
    setDetailProcess((prevData) => ({ ...prevData, productionQtyCoeffThItems: updDetails }));
  };

  const updatename = (result) => {
    setMeishou(result);
  };
  const updateMeishouList = (result) => {
    setMeishouList(result);
  };

  // 重量マスタの値を変更した場合、
  const updateProductSizeWeight = (result) => {
    // setWeight(result);
    setDetailProcess((prevData) => ({ ...prevData, childPartsCoeffWeightThMaster: result }));
  };

  // 曲げ長さ係数のkgとmmマスタ値更新
  const updateBendTimeCoeffLenThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, bendTimeCoeffLenThMaster: result }));
  };

  const updateBendTimeCoeffWeightThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, bendTimeCoeffWeightThMaster: result }));
  };

  const updateBendingCorrectionTimeFactorListData = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, bendTimeCoeffItems: result }));
  };

  // マテリアルハンドリング時間の重量と面積マスタ値更新
  const updateMhWeightThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, mhWeightThMaster: result }));
  };
  const updateMhAreaThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, mhAreaThMaster: result }));
  };

  // マテリアルハンドリング時間リスト更新
  const updateMhTimeItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, mhTimeItems: result }));
  };

  // 面積マスタの値を変更した場合、
  const updateBendingTimeArea = (result) => {
    // setIQ3AreaMaster(result);
    setDetailProcess((prevData) => ({ ...prevData, childPartsCoeffAreaThMaster: result }));
  };

  // const updateDantoriJuuryoMagechouListData = (result) => {
  //   setDantoriJuuryouMagechouList(result);
  // };

  // 曲長リスト値を変更
  const updateMagechouDantoriJikanList = (result) => {
    // setMagechouDantoriJikanListData(result);
    setDetailProcess((prevData) => ({ ...prevData, prepBendTimeItems: result }));
  };

  const updateBendingTimeFactorListData = (result) => {
    setBendingTimeFactorListData(result);
  };

  // サイズ係数のリスト値を変更した場合、
  const updatecorrectionTimeFactorListData = (result) => {
    // setCorrectionTimeFactorListData(result);
    setDetailProcess((prevData) => ({ ...prevData, childPartsCoeff: result }));
  };

  // パンチ条件板厚マスタ更新
  const updatePunchConditionThickThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, punchConditionThickThMaster: result }));
  };
  const updatePunchConditionItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, punchConditionItems: result }));
  };

  // 1パンチ加工時間マスタ更新
  const updatePunchWorkTimeThickThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, punchWorkTimeThickThMaster: result }));
  };
  const updatePunchWorkTimeItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, punchWorkTimeItems: result }));
  };
  // サイズ係数の面積マスタ更新
  const updateProdSizeAreaThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, prodSizeAreaThMaster: result }));
  };

  // サイズ係数の重量マスタ更新
  const updateProdSizeWeightThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, prodSizeWeightThMaster: result }));
  };

  // サイズ係数の重量面積リスト情報を更新
  const updateProdSizeItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, prodSizeItems: result }));
  };

  const updateIQ3ProductSizeListData = (result) => {
    setiq3ProductSizeListData(result);
  };

  // 曲長マスタの値を更新
  const updateBendingTimeLength = (result) => {
    // setLength(result);
    setDetailProcess((prevData) => ({ ...prevData, prepBendTimeLengthMaster: result }));
  };

  const updateIQ3AreaMaster = (result) => {
    setIQ3AreaMaster(result);
  };
  // 自動バリ又は表面処理の加工時間のcm２マスタ値更新
  const updateAreaThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, areaThMaster: result }));
  };
  const updateWorkTimeThItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, workTimeThItems: result }));
  };

  const updateIQ3KakouJikanList = (result) => {
    setIQ3KakouJikanListData(result);
  };

  // サイズマスタの更新
  const updatediq3SizeMaster = (result) => {
    // setIQ3SizeMaster(result);
    setDetailProcess((prevData) => ({ ...prevData, sizeThMaster: result }));
  };

  // mmマスタの更新
  const updateSizeMmMaster = (result) => {
    // setMmMaster(result);
    setDetailProcess((prevData) => ({ ...prevData, mmThMaster: result }));
  };

  // サイズ簡易入力リストの更新
  const updatediq3KaniListData = (result) => {
    // setIQ3KaniListData(result);
    setDetailProcess((prevData) => ({ ...prevData, workTimeSizeThItems: result }));
  };

  // mm簡易入力リストの更新
  const updateiq3KaniAnaListData = (result) => {
    // [setIQ3KaniAnaListData(result)];
    setDetailProcess((prevData) => ({ ...prevData, workTimeSizeHoleThItems: result }));
  };

  // サイズ板厚マスタの更新
  const updateSizeItaatsu = (result) => {
    // setItaatsu1(result);
    setDetailProcess((prevData) => ({ ...prevData, sizeThicknessThMaster: result }));
  };

  // mm板厚マスタの更新
  const updateMmItaatsu = (result) => {
    // setItaatsu1(result);
    setDetailProcess((prevData) => ({ ...prevData, mmThicknessThMaster: result }));
  };

  // 1カット時間
  const updateCutTime = (result) => {
    // setCutTime(result);
    setDetailProcess((prevData) => ({ ...prevData, cutTime: result }));
  };

  // one by one
  const updateShirringFlag = (result) => {
    // setShirringFlag(result);
    setDetailProcess((prevData) => ({ ...prevData, isOneCut: result }));
  };

  const updateVolume = (result) => {
    setVolume(result);
  };

  const updateDantoriSizeList = (result) => {
    setDantoriSizeDetailsList(result);
  };

  // 重量係数の個数マスタ値を入力された場合、
  const updateProductWeightCoeffMst = (updDetails) => {
    setDetailProcess((prevData) => ({ ...prevData, prodWeightCoeffThMaster: updDetails }));
  };

  // 重量係数の値を入力された場合、
  const updateProductWeightCoeffList = (updDetails) => {
    // setSizeDetailsList(result);
    setDetailProcess((prevData) => ({ ...prevData, prodWeightCoeffThItems: updDetails }));
  };

  const updateSuuryouDetails = (result) => {
    setSuuryouData(result);
  };

  const updateMagesuuData = (result) => {
    setIQ3MageSuuMaster(result);
  };

  // 曲げ種類リストの値を更新、
  const updateMagesuuList = (result) => {
    // setIQ3MageSuuListData(result);
    setDetailProcess((prevData) => ({ ...prevData, basicTimeThickItems: result }));
  };

  // 板厚マスタの値を更新
  const updateItaatsuMstData = (result) => {
    // setIQ3ItaatsuMaster(result);
    setDetailProcess((prevData) => ({ ...prevData, basicTimeThickThMaster: result }));
  };

  // 曲げ種類の板厚マスタを更新
  const updateIQ3Itaatsu = (result) => {
    // setIQ3ItaatsuMaster(result);
    setDetailProcess((prevData) => ({ ...prevData, workTimeThickThMaster: result }));
  };

  const updatedBlankKakousokudolistData = (result) => {
    setBlankKakousokudolistData(result);
  };
  const updateNaikeibari = (result) => {
    setNaikeibari(result);
  };
  const updateKakousokudobari = (result) => {
    setkakosokudo_bari(result);
  };
  // 手動バリの加工速度(mm/min)のマスタ値とリスト更新
  const updateWorkSpeedThickThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, workSpeedThickThMaster: result }));
  };
  const updateWorkSpeedLenThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, workSpeedLenThMaster: result }));
  };
  const updateWorkSpeedItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, workSpeedItems: result }));
  };
  const updateShudobariKakousokudoList = (result) => {
    setShudoubariKakousokudoList(result);
  };
  const updatedWorkSpeedItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, workSpeedItems: result }));
  };
  // const updateNaikeiMin = (result) => {
  //   setNaikeiMin(result);
  // };
  // const updateNaikeiMax = (result) => {
  //   setNaikeiMax(result);
  // };
  // const updateAnaMax = (result) => {
  //   setAnaMax(result);
  // };
  // const updateAnaMin = (result) => {
  //   setAnaMin(result);
  // };
  // ガス係数のガス種類と板厚マスタ値更新
  const updateGasCoeffThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, gasCoeffThMaster: result }));
  };
  const updateGasCoeffListItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, gasCoeffListItems: result }));
  };
  const updateGasCoeffThickThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, gasCoeffThickThMaster: result }));
  };
  const updateGasCoeffThItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, gasCoeffThItems: result }));
  };
  // 外形追抜条件の保有金型マスタ値更新
  const updatedRetainedMold = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, odOvertakeMoldMaster: result }));
  };
  const updateExtractConditionList = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, odOvertakeItems: result }));
  };

  // 曲げ種類の板厚リスト値を更新
  const updateBendingTimeList = (result) => {
    // setIQ3BendingTimeListData(result);
    setDetailProcess((prevData) => ({ ...prevData, workTimeThickItems: result }));
  };

  const updateGasTypeList = (result) => {
    setGasTypeListData(result);
  };

  const updatePiercingProcessList = (result) => {
    setPiercingProcessItaatsuListData(result);
  };

  const updatePunchProcessList = (result) => {
    setPunchProcessItaatsuListData(result);
  };
  // ジョイントバラシのcm2マスタ値更新
  const updateJointQtySizeThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, jointQtySizeThMaster: result }));
  };
  const updateJointQtyItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, jointQtyItems: result }));
  };
  // ジョイントバラシの板厚マスタ値更新
  const updateJointSeparateThickThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, jointSeparateThickThMaster: result }));
  };
  const updateJointSeparateItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, jointSeparateItems: result }));
  };

  // 加工速度係数のマスタ値とリスト更新
  const updateWorkSpeedCoeffThickThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, workSpeedCoeffLengthThMaster: result }));
  };
  const updateWorkSpeedCoeffInternalWorkItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, workSpeedCoeffInternalWorkItems: result }));
  };
  const updateWorkSpeedCoeffExternalWorkItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, workSpeedCoeffExternalWorkItems: result }));
  };
  const updateJointItaatsuList = (result) => {
    setJointItaatsuListData(result);
  };
  const updateblankKakouSokudoItaatsuList = (result) => {
    setBlankKakouSokudoItaatsuList(result);
  };
  const updateOkuriSokudoVal = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, feedSpeed: result }));
  };
  const updateKegakiSokudoVal = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, scribingSpeed: result }));
  };
  const updateIsUsedEasyWorkSpeedVal = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, isUsedEasyWorkSpeed: result }));
  };
  // const updateYousosuu = (result) => {
  //   setYousosuuData(result);
  // };

  // 要素数マスタを変更した場合、
  const updateYousosuuMaster = (result) => {
    // setYousosuuData(result);
    setDetailProcess((prevData) => ({ ...prevData, prepElementThMaster: result }));
  };

  // 初期段取時間を変更した場合、
  const updateshokiDankaiJikanListData = (result) => {
    // setShokiDankaiJikanList(result);
    setDetailProcess((prevData) => ({ ...prevData, prepElementThItems: result }));
  };

  // 同一形状・サイズの上限を変更した場合、
  const updateProgramSameSizePatternValue = (result) => {
    // setProgramSameSizePatternValue(result);
    setDetailProcess((prevData) => ({ ...prevData, shapeGrouping: result }));
  };

  // const updateSuuryouListData = (result) => {
  //   setSuuryouList(result);
  // };

  const updateDantoriProductSizeWeight = (result) => {
    setWeight(result);
  };
  const updatKobuhinnKeisuu = (result) => {
    setKobuhinKeisuu(result);
  };

  const updateTapWeldItaatsuLst = (result) => {
    setTapWeldItaatsuList(result);
  };
  const updateInstSizeThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, instSizeThMaster: result }));
  };
  const updateInstSizeThItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, instSizeThItems: result }));
  };

  // 溶接難易度の板厚マスタデータ更新
  const updateWeldDifficultyCoeffThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, weldDifficultyCoeffThMaster: result }));
  };
  const updatedWeldDifficultyCoeffThItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, weldDifficultyCoeffThItems: result }));
  };
  // タップ溶接の板厚マスタデータ更新
  const updateTapRateThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, tapRateThMaster: result }));
  };
  const updateTapRateThItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, tapRateThItems: result }));
  };
  const updateToritsukiItaatsuList = (result) => {
    setToritsukiTimeItaatsuList(result);
  };

  // 1検査時間を変更した場合、
  const changeTime = (e) => {
    setProcessTimeByUnit(setTimetoSec(e));
  };

  // マテリアルハンドリング時間更新
  const updateMaterialHandlingList = (result) => {
    setMaterialHandlingTimeList(result);
  };

  // 作業時間係数の要素数リストを変更した場合、
  const updateYousosuuListData = (result) => {
    // setSagyouKeisuuYousosuuList(result);
    setDetailProcess((prevData) => ({ ...prevData, workTimeElementCoeffThItems: result }));
  };

  // 作業時間係数の要素数マスタを変更した場合、
  const updateYousosuu = (result) => {
    // setYousosuuData(result);
    setDetailProcess((prevData) => ({ ...prevData, otherElementCoeffThMaster: result }));
  };

  const updateKensaJikanKeisuuList = (result) => {
    setKensaJikanKeisuuList(result);
  };

  // チャージ金額詳細
  const chargeDetailsModal = (event) => {
    event.stopPropagation();
    setIsDetails(true);
  };

  // バリ取種類
  const baritoriTypesContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={baritoriShurui}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>{processName === 'バリ取' ? 'バリ取' : 'ブランク'}種類</label>
            {isBaritoriShurui ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isBaritoriShurui ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_ProcessType
              processMethods={processMethods}
              processMethod={processMethod}
              editMode={editMode}
              updatedProcessMethod={updateProcessMethod}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // チャージ金額
  const chargeAmountContent = (
    <>
      {/* <Row align="middle" className="oyabuhin oyabuhin-row" onClick={chargeAmount}> */}
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={chargeAmount}>
        <Col span={20} className="deli-col">
          <div style={{ verticalAlign: 'middle' }}>
            <label style={{ marginRight: 5 }}>チャージ金額(/h)</label>
            {isCharge ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}

            {/* <Button className="detailsButton" onClick={chargeDetailsModal} style={{ marginTop: -2 }}>
              詳細
            </Button> */}
          </div>
        </Col>
      </Row>
      <div style={{ display: isCharge ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            {/* チャージ金額 */}
            <ChargeAmount
              deviceType={deviceType}
              types={types}
              // setupAmount={setupAmount}
              // updateSetupAmount={updateSetupAmount}
              // workAmount ={workAmount}
              // updateWorkAmount={updateWorkAmount}
              detailsData={detailsProcess}
              processMstRecord={processMstRecord}
              // chajiDetails={jikanCharge}
              processType={workType}
              processMethod={processMethod}
              editMode={editMode}
              updatedAmount={updatedChargeAmount}
              // updatedCharge={updatedCharge}
              // iq3SetupAmount={iq3SetupAmount}
              // updatediq3SetupAmount={updateSetupAmount}
              // iq3WorkAmount={iq3WorkAmount}
              // updatediq3WorkAmount={updateWorkAmount}
              // iq3ProcessInspection={iq3ProcessInspection} //工程内検査
              // updatediq3ProcessInspection={updateiq3ProcessInspection}
              // secondProcessAmount={secondProcessAmount} //２次加工
              // updatedsecondProcessAmount={updatesecondProcessAmount}
              // jointBarashiAmount={jointBarashiAmount}
              // updatedjointBarashiAmount={updatejointBarashiAmount}
              // チャージ金額の工程内検査
              // kensaAmount={kensaAmount}
              // updateKensaAmount={updateKensaAmount}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );

  // 段取設定
  const dantoriSettingContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={setupSetting}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>段取設定</label>
            {isSetupSetting ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isSetupSetting ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            {
              // プログラム
              workType === WorkType.SmProgram ||
              // ブランク
              workType === WorkType.SmBlankLaser ||
              workType === WorkType.SmBlankPunch ||
              workType === WorkType.SmBlankCombi ||
              // ベンディング
              workType === WorkType.SmBending ||
              // バリ取（自動・手動）
              workType === WorkType.SmDeburringAuto ||
              workType === WorkType.SmDeburringManual ||
              // 2次加工
              workType === WorkType.SmSecondaryWork ||
              // シャーリング
              workType === WorkType.SmShearing ||
              // 表面処理とiQ3
              (workType === WorkType.SmSurface && types === ServiceClass.SheetMetal) ||
              // 溶接とiQ3
              (workType === WorkType.SmWelding && types === ServiceClass.SheetMetal) ||
              // 溶接仕上とiQ3
              (workType === WorkType.SmWeldFinish && types === ServiceClass.SheetMetal) ||
              // 検査とiQ3
              (workType === WorkType.SmInspection && types === ServiceClass.SheetMetal) ||
              // カスタムとiQ3
              (workType === WorkType.SmCustom && types === ServiceClass.SheetMetal) ? (
                <>
                  <Device_SetupSetting
                    processType={workType}
                    detailsData={detailsProcess}
                    processMstRecord={processMstRecord}
                    processMethod={processMethod}
                    editMode={editMode}
                    // mageSuuData={iq3MageSuuMaster}
                    // updatedMagesuuData={updateMagesuuData}
                    itaatsuData={iq3ItaatsuMaster}
                    updatedItaatsuData={updateItaatsuMstData}
                    mageSuuLst={iq3MageSuuListData}
                    updatedMagesuuList={updateMagesuuList}
                    // 要素数
                    yousosuuMasterData={yousosuuData}
                    updatedYousosuuMasterData={updateYousosuuMaster}
                    // shokidankaiYousosuuList={shokiDankaiJikanList}
                    updatedYousosuuList={updateshokiDankaiJikanListData}
                    // 要素数により、作業係数のリスト更新
                    sagyouKeisuuYousosuuList={sagyouKeisuuYousosuuList}
                    updatedSagyouYousosuuList={updateYousosuuListData}
                    kensaJikanKeisuuList={kensaJikanKeisuuList}
                    updateKensaJikanKeisuuList={updateKensaJikanKeisuuList}
                    // 曲げ個数
                    mageKosuu={iq3MageKosuuMaster}
                    updatedMageKosuu={updateMageKosuu}
                    mageKosuuList={iq3MageKosuuListData}
                    updatedMageKosuuList={updateMageKosuList}
                    // 重量と曲げ長
                    length={length}
                    updatedLength={updateBendingTimeLength}
                    weight={weight}
                    updatedWeight={updateProductSizeWeight}
                    // dantoriJuuryouMagechouList={dantoriJuuryouMagechouList}
                    // updatedDantoriJuuryouMagechouList={updateDantoriJuuryoMagechouListData}
                    magechouDantoriJikanList={magechouDantoriJikanListData}
                    updatedmagechouDantoriJikanListData={updateMagechouDantoriJikanList}
                    // 二次加工

                    updatedDantoriSetting={updatedDantori} //段取 秒更新データ
                    // 生産個数
                    kosuu={seisanKosuu}
                    updatedKosuu={updateSeisanKosuu}
                    updatedKosuuList={updateiq3SeisanKosuuList}
                    kosuuList={seisanKosuuList}
                    processInspectionKosuuListosuuList={processInspectionKosuuList}
                    updatedProcessInspectionKosuuList={updateProcessInspectionKosuuList}
                    // 本数
                    honSuu={honSuu}
                    updateHonsuu={updateHonsuu}
                    honSuuList={honSuuList}
                    updateHonsuuList={updateHonsuuList}
                    // 基本段取時間
                    dantoriTime={kihonDantori}
                    updatedDantoriTime={updateDantoriTime}
                    // 段取を個別に持つ
                    dantoriFlag={dantoriFlag}
                    updateDantoriFlag={updateDantoriFlag}
                    area={iq3AreaMaster}
                    updatedArea={updateBendingTimeArea}
                    // 重量と面積
                    sizeKeisuuListData={correctionTimeFactorListData}
                    updatedsizeKeisuuListData={updatecorrectionTimeFactorListData}
                    // マテリアルハンドリング時間
                    materialHandlingTimeList={materialHandlingTimeList}
                    updateMaterialHandlingList={updateMaterialHandlingList}
                    // 製品重量係数
                    iq3SeihinSizeLst={iq3ProductSizeListData}
                    updatediq3ProductSizesList={updateIQ3ProductSizeListData}
                  />
                </>
              ) : (
                <>
                  {/* 段取設定 */}
                  <SetupSetting
                    editMode={editMode}
                    processType={workType}
                    detailsData={detailsProcess}
                    processMstRecord={processMstRecord}
                    updatedDantoriSetting={updatedDantori} //段取　秒更新データ
                    // kihonDantoriTime={kihonDantori} //基本段取時間
                    // updatedKihonDantoriTime={updatedTime}
                    // suuryouData={suuryouData} //数量Master
                    updatedSuuryouMstData={updateSuuryouMstData} // 個数マスタ値
                    updatedSuuryouChildList={updatedSuuryouChildList} // 子部品点数リスト値
                    updatedSuuryouChildTypeList={updatedSuuryouChildTypeList} // 子部品種類数リスト値
                    updatedPrepCoeffType={updatedPreparationCoeffType} // 子部品点数又子部品種類数のチェックボタン値
                    // dantoriSetteiSuuryouList={dantoriSetteiSuuryouList}
                    // updateddantoriSetteiSuuryouList={updatedantoriSetteiSuuryouList}
                    // 子部品数量
                    // kobuhinsuuryouList={suuryouList}
                    // updatedSuuryouList={updateSuuryouListData}
                    // 重量と体積
                    updatedWeightMstData={updatedWeightMstData} // 重量マスタ値
                    updatedWeightListData={updatedWeightListData} // 重量リスト値
                    updatedBasicTime={updatedBasicTime} //基本段取時間
                    // weight={weight}
                    // updatedWeight={updateDantoriProductSizeWeight}
                    // dantoriSizeList={dantoriSizeDetailsList}
                    // updatedDantoriSizeList={updateDantoriSizeList}
                    // sizeList={sizesDetailsList}
                    // updatedSizesList={updateProductSizeList}
                    // 段取を個別に持つ
                    // dantoriFlag={dantoriFlag}
                    updateDantoriFlag={updateApportionSelect} // 段取按分しない
                  />
                </>
              )
            }
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 工程内検査
  const kouteiNaiKensaContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={kouteiNaikensa}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>工程内検査</label>
            {isKouteiNaikensa ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isKouteiNaikensa ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_ProcessInspection
              processName={getWorkTypeName(workType)}
              editMode={editMode}
              // 生産個数
              kosuu={seisanKosuu}
              updatedKosuu={updateSeisanKosuu}
              kosuuList={processInspectionKosuuList}
              updatedKosuuList={updateProcessInspectionKosuuList}
              // 段取設定の生産個数
              dantorikosuuList={seisanKosuuList}
              updatedDantorisKosuuList={updateiq3SeisanKosuuList}
              // 基本段取時間
              inspectionTime={processInspectionTime}
              updatedDantoriTime={updateProcessInspectionTime}
              // 要素数
              yousosuuMasterData={yousosuuData}
              updatedYousosuuMasterData={updateYousosuu}
              kensaJikanKeisuuList={kensaJikanKeisuuList}
              updateKensaJikanKeisuuList={updateKensaJikanKeisuuList}
              // 要素数により、リスト更新
              sagyouKeisuuYousosuuList={sagyouKeisuuYousosuuList}
              updatedYousosuuList={updateYousosuuListData}
              // 要素数により、リスト更新
              shokidankaiYousosuuList={shokiDankaiJikanList}
              updatedShokidankaiYousosuuList={updateshokiDankaiJikanListData}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // マテリアルハンドリング時間
  const materialHandlingContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={materialHandling}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>マテリアルハンドリング時間</label>
            {isMaterialHandling ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isMaterialHandling ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_MaterialHandlingTime
              detailsData={detailsProcess}
              processMstRecord={processMstRecord}
              processName={getWorkTypeName(workType)}
              editMode={editMode}
              // mageSuuData={iq3MageSuuMaster}
              // updatedMagesuuData={updateMagesuuData}
              // mageSuuLst={iq3MageSuuListData}
              // updatedMagesuuList={updateMagesuuList}
              // 重量と面積
              // length={length}
              // updatedLength={updateBendingTimeLength}
              // weight={weight}
              updatedWeight={updateMhWeightThMaster}
              // area={iq3AreaMaster}
              updatedArea={updateMhAreaThMaster}
              // dantoriJuuryouMagechouList={dantoriJuuryouMagechouList}
              // materialHandlingTimeList={materialHandlingTimeList}
              updateMaterialHandlingList={updateMhTimeItems}
              // 段取設定の曲げ係数
              // correctionTimeFactorListData={correctionTimeFactorListData}
              // updatedcorrectionTimeFactorListData={updatecorrectionTimeFactorListData}
              // 製品重量係数
              // iq3SeihinSizeLst={iq3ProductSizeListData}
              // updatediq3ProductSizesList={updateIQ3ProductSizeListData}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );

  // 作業時間
  const sagyouJikanContent = (
    <>
      {/* 作業時間 */}
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={processTime}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>作業時間</label>
            {isProcessTime ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isProcessTime ? 'block' : 'none' }}>
        <Row style={{ marginLeft: 10 }} className="chargeAmount">
          <Col style={{ width: '20%' }}>
            <RTable>
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th className="tbl-header">
                    {deviceType === processTypes.additional ? '1箇所あたりの作業時間' : '1部品あたりの作業時間'}{' '}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ textAlign: 'center' }}>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Row style={{ marginLeft: 5 }} align="middle">
                      {!editMode ? (
                        <>
                          <Col span={24}>
                            <label>{secondsToHms(workTime)}</label>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col span={10}>
                            <Input
                              value={secondsToHms(workTime)}
                              onChange={(e) => {
                                setWorkTime(setTimetoSec(e.target.value));
                              }}
                            ></Input>
                          </Col>
                          <Col span={14}>
                            <CaretUpOutlined
                              style={{
                                fontSize: 16 + 'px',
                                marginLeft: 3,
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleIncreaseTime(secondsToHms(workTime), '10min');
                                setWorkTime(setTimetoSec(editedTime));
                              }}
                            />
                            <CaretDownOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleDecreaseTime(secondsToHms(workTime), '10min');
                                setWorkTime(setTimetoSec(editedTime));
                              }}
                            />
                            <CaretUpOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleIncreaseTime(secondsToHms(workTime), 'min');
                                setWorkTime(setTimetoSec(editedTime));
                              }}
                            />
                            <CaretDownOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleDecreaseTime(secondsToHms(workTime), 'min');
                                setWorkTime(setTimetoSec(editedTime));
                              }}
                            />
                            <CaretUpOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleIncreaseTime(secondsToHms(workTime), 'sec');
                                setWorkTime(setTimetoSec(editedTime));
                              }}
                            />
                            <CaretDownOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleDecreaseTime(secondsToHms(workTime), 'sec');
                                setWorkTime(setTimetoSec(editedTime));
                              }}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </td>
                </tr>
              </tbody>
            </RTable>
          </Col>
        </Row>
      </div>
    </>
  );
  // 曲げ加工時間
  const bendingTimeContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={magekakouJikan}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>作業・加工時間</label>
            {isMagekakouJikan ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isMagekakouJikan ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_Bending
              editMode={editMode}
              detailsData={detailsProcess}
              processMstRecord={processMstRecord}
              itaatsuData={iq3ItaatsuMaster}
              updatedItaatsuData={updateIQ3Itaatsu}
              itaatsuLst={iq3BendingTimeListData}
              updatedItaatsuList={updateBendingTimeList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 材料配置
  const materialArrangementContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={arrangeMaterial}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>材料配置</label>
            {isArrangeMaterial ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isArrangeMaterial ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_MaterialArrangement
              editMode={editMode}
              detailsData={detailsProcess}
              updateMaterialArrangement={updateMaterialArrangement}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // パンチ条件
  const punchConditionContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={punch}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>
              {workType === WorkType.SmBlankCombi ? 'パンチ条件丸穴径(mm)' : 'パンチ条件'}{' '}
              {/** IQX_WEBEST-294 パラメータ画面 > 板金 > 装置設定 > 複合機、”パンチ条件” の表記を "パンチ条件丸穴径(mm)" に変更 */}
            </label>
            {isPunch ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isPunch ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            {workType === WorkType.SmBlankCombi ? (
              <Device_Blank_PunchProcessTime
                isPunchCondition={true}
                editMode={editMode}
                detailsData={detailsProcess}
                materialTypes={materialTypes}
                updatedItaatsu={updatePunchConditionThickThMaster}
                updatePunchProcessList={updatePunchConditionItems}
              />
            ) : (
              <Device_Blank_PunchCondition
                editMode={editMode}
                detailsData={detailsProcess}
                updateList={updatePunchList}
                processType={workType}
              />
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 1ピアス加工時間
  const piercingProcessTimeContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={piercingProcess}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>1ピアス加工時間(秒)</label> {/** IQX_WEBEST-264 */}
            {isPiercingProcess ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isPiercingProcess ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_Blank_PiercingProcessTime
              editMode={editMode}
              detailsData={detailsProcess}
              materialTypes={materialTypes}
              // itaatsuData={iq3ItaatsuMaster}
              updatePiercingTimeThickThMaster={updatePiercingTimeThickThMaster}
              // piercingProcessList={piercingProcessItaatsuListData}
              updatePiercingTimeThItems={updatePiercingTimeThItems}
              // // ガス種類
              // gasTypeList={gasTypeListData}
              // updatedGasTypeList={updateGasTypeList}
              // // ジョイント
              // jointItaatsuList={jointItaatsuListData}
              // updatedJointItaatsuList={updateJointItaatsuList}
              // // 加工速度係数
              // kakouSokudoItaatsuList={blankKakouSokudoItaatsuList}
              // updateblankKakouSokudoItaatsuList={updateblankKakouSokudoItaatsuList}
              // // 1パンチ加工時間
              // punchProcessItaatsuListData={punchProcessItaatsuListData}
              // updatePunchProcessList={updatePunchProcessList}
              // // 加工速度
              // blankKakousokudolistData={blankKakousokudolistData}
              // updatedBlankKakousokudolistData={updatedBlankKakousokudolistData}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 1パンチ加工時間
  const punchProcessTimeContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={punchProcess}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>1パンチ加工時間(秒)</label> {/** IQX_WEBEST-264 */}
            {isPunchProcess ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isPunchProcess ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_Blank_PunchProcessTime
              isPunchCondition={false}
              editMode={editMode}
              // itaatsuData={iq3ItaatsuMaster}
              // updatedItaatsuData={updateIQ3Itaatsu}
              // // 1パンチ加工時間
              // punchProcessItaatsuListData={punchProcessItaatsuListData}
              // updatePunchProcessList={updatePunchProcessList}

              detailsData={detailsProcess}
              materialTypes={materialTypes}
              updatedItaatsu={updatePunchWorkTimeThickThMaster}
              updatePunchProcessList={updatePunchWorkTimeItems}
              // // 1ピアス加工時間
              // piercingProcessList={piercingProcessItaatsuListData}
              // updatePiercingProcessList={updatePiercingProcessList}
              // // ガス種類
              // gasTypeList={gasTypeListData}
              // updatedGasTypeList={updateGasTypeList}
              // // ジョイント
              // jointItaatsuList={jointItaatsuListData}
              // updatedJointItaatsuList={updateJointItaatsuList}
              // // 加工速度係数
              // kakouSokudoItaatsuList={blankKakouSokudoItaatsuList}
              // updateblankKakouSokudoItaatsuList={updateblankKakouSokudoItaatsuList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 曲げ長さ係数
  const bendingTimeFactorContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={bendingTimeFactor}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>曲げ長さ係数</label> {/** IQX_WEBEST-268 */}
            {isBendingTimeFactor ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isBendingTimeFactor ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_BendingTimeFactor
              detailsData={detailsProcess}
              processName={workType}
              editMode={editMode}
              // length={length}
              updatedLength={updateBendTimeCoeffLenThMaster}
              // weight={weight}
              updatedWeight={updateBendTimeCoeffWeightThMaster}
              // 曲げ長さ係数リスト
              // bendingTimeFactorLst={bendingCorrectionTimeFactorListData}
              updatedSizesList={updateBendingCorrectionTimeFactorListData}
              // 曲げ補正時間係数
              // bendingCorrectionTimeFactorLst={bendingTimeFactorListData}
              // updatedbendingCorrectionTimeFactorLst={updateBendingTimeFactorListData}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 曲げ補正時間
  const bendingCorrectionTimeContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={mageHoseiJikan}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>
              {/* {workType === WorkType.SmBending ? '曲げ補正時間' : '加工補正時間'} */}加工補正時間
            </label>
            {isMageHoseiJikan ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isMageHoseiJikan ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_BendingCorrectionTime
              editMode={editMode}
              detailsData={detailsProcess}
              processName={workType}
              // honSuu={honSuu}
              updateHonsuu={updateModifyTimeQtyThMaster}
              // honsuuList={iq3BendingHonsuuList}
              updateHonsuuList={updateModifyTimeItems}
              // updatedSeisanKosuuList={updateKosuuList}
              //追加
              // processName={getWorkTypeName(workType)}
              // length={length}
              updatedLength={updateModifyCoeffLenThMaster}
              // weight={weight}
              updatedWeight={updateModifyCoeffWeightThMaster}
              updatedWorkWeight={updateMtWeightThMaster}
              // 曲げ補正リスト
              // bendingTimeFactorLst={bendingTimeFactorListData}
              updatedSizesList={updateModifyCoeffItems}
              // 補正リスト
              // area={iq3AreaMaster}
              updatedArea={updateMtAreaThMaster}
              // correctionTimeFactorListData={correctionTimeFactorListData}
              updatedworkCorrectionTimeListData={updatedworkCorrectionTimeListData}
              // 製品重量係数
              iq3SeihinSizeLst={iq3ProductSizeListData}
              updatediq3ProductSizesList={updateIQ3ProductSizeListData}
              // マテリアルハンドリング時間
              materialHandlingTimeList={materialHandlingTimeList}
              updateMaterialHandlingList={updateMaterialHandlingList}
              listData={iq3KakouJikanListData}
              updatedIQ3KakouJikanList={updateIQ3KakouJikanList}
              kosuu={kosuu}
              updatedKosuu={updateKosuu}
              iq3BendingKosuuList={iq3BendingKosuuList}
              updateIQ3BendingKosuuList={updateIQ3BendingKosuuList}
              seisanKosuuList={kosuuList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 曲げ補正時間係数
  const bendingCorrectionTimeFactorContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={mageHoseiJikanFactor}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>
              {/* 2次加工、バリ取 */}
              {workType === WorkType.SmSecondaryWork ||
              workType === WorkType.SmDeburringAuto ||
              workType === WorkType.SmDeburringManual
                ? '補正時間係数'
                : '曲げ補正時間係数'}
            </label>
            {isMageHoseiJikanFactor ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isMageHoseiJikanFactor ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_BendingCorrectionTimeFactor
              processName={getWorkTypeName(workType)}
              editMode={editMode}
              area={iq3AreaMaster}
              updatedArea={updateBendingTimeArea}
              length={length}
              updatedLength={updateBendingTimeLength}
              weight={weight}
              updatedWeight={updateProductSizeWeight}
              // 曲げ補正リスト
              bendingTimeFactorLst={bendingTimeFactorListData}
              updatedSizesList={updateBendingTimeFactorListData}
              // 補正リスト
              correctionTimeFactorListData={correctionTimeFactorListData}
              updatedcorrectionTimeFactorListData={updatecorrectionTimeFactorListData}
              // 製品重量係数
              iq3SeihinSizeLst={iq3ProductSizeListData}
              updatediq3ProductSizesList={updateIQ3ProductSizeListData}
              // マテリアルハンドリング時間
              materialHandlingTimeList={materialHandlingTimeList}
              updateMaterialHandlingList={updateMaterialHandlingList}
              listData={iq3KakouJikanListData}
              updatedIQ3KakouJikanList={updateIQ3KakouJikanList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 製品重量係数
  const productSizeFactorContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={processSizeFactor}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>サイズ係数</label>
            {isProcessSizeFactor ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isProcessSizeFactor ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_ProductSize
              detailsData={detailsProcess}
              editMode={editMode}
              // area={iq3AreaMaster}
              updatedArea={updateProdSizeAreaThMaster}
              // weight={weight}
              updatedWeight={updateProdSizeWeightThMaster}
              // iq3SeihinSizeLst={iq3ProductSizeListData}
              // updatediq3ProductSizesList={updateIQ3ProductSizeListData}
              updatediq3ProductSizesList={updateProdSizeItems}
              // 補正時間係数
              bendingTimeFactorLst={bendingTimeFactorListData}
              updatedSizesList={updateBendingTimeFactorListData}
              // bendingTimeFactorLst={correctionTimeFactorListData}
              // updatedSizesList={updatecorrectionTimeFactorListData}
              // 段取設定の曲げ係数
              correctionTimeFactorListData={correctionTimeFactorListData}
              updatedcorrectionTimeFactorListData={updatecorrectionTimeFactorListData}
              // マテリアルハンドリング時間
              materialHandlingTimeList={materialHandlingTimeList}
              updateMaterialHandlingList={updateMaterialHandlingList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 加工速度
  const machineSpeedContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={processSpeed}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>加工速度(mm/min)</label>
            {isProcessSpeed ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isProcessSpeed ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <MachineSpeed
              detailsData={detailsProcess}
              processMstRecord={processMstRecord}
              // 工程タイプ
              processName={workType}
              // 工程マスタ種類
              processTypes={processTypesList}
              editMode={editMode}
              //　線長マスター更新
              detailsWeldLine={detailsWeldLine}
              updatedYousetsuSenchou={updatedYousetsuSenchouData}
              //溶接
              weldLineLst={yousetsuWeldLineList}
              updatedYousetsuSenchouList={updateYousetsuSenchouList}
              // 溶接仕上
              // senchouList={kakouSokudoSenchouList}
              updatedSenchouList={updateKakousokudoSenchouList}
              // detailsSenchou={detailsWeldLine}
              // updatedItaatsu={upadateKaritsukiItaatsuData}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 作業速度
  const sagyouSokudoContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={sagyouSokudo}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>加工設定</label>
            {isSagyouSokudo ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isSagyouSokudo ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Row style={{ marginLeft: 10 }} className="chargeAmount">
              <Col style={{ width: '20%' }}>
                <RTable>
                  <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                    <tr style={{ textAlign: 'center' }}>
                      <th className="tbl-header">作業時間（x要素数）</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>
                        <Row align="middle">
                          {!editMode ? (
                            <>
                              <Col span={24}>
                                <label>{secondsToHms(sagyouTime)}</label>
                              </Col>
                            </>
                          ) : (
                            <>
                              <Col span={10}>
                                <Input
                                  value={secondsToHms(sagyouTime)}
                                  onChange={(e) => {
                                    setSagyouTime(setTimetoSec(e.target.value));
                                  }}
                                  onKeyPress={(e) => {
                                    handleKeyPress(e, false);
                                  }}
                                ></Input>
                              </Col>
                              <Col span={14}>
                                <CaretUpOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    marginLeft: 3,
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleIncreaseTime(secondsToHms(sagyouTime), '10min');
                                    setSagyouTime(setTimetoSec(editedTime));
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    color: '#000',
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleDecreaseTime(secondsToHms(sagyouTime), '10min');
                                    setSagyouTime(setTimetoSec(editedTime));
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    color: '#000',
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleIncreaseTime(secondsToHms(sagyouTime), 'min');
                                    setSagyouTime(setTimetoSec(editedTime));
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    color: '#000',
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleDecreaseTime(secondsToHms(sagyouTime), 'min');
                                    setSagyouTime(setTimetoSec(editedTime));
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    color: '#000',
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleIncreaseTime(secondsToHms(sagyouTime), 'sec');
                                    setSagyouTime(setTimetoSec(editedTime));
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    color: '#000',
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleDecreaseTime(secondsToHms(sagyouTime), 'sec');
                                    setSagyouTime(setTimetoSec(editedTime));
                                  }}
                                />
                              </Col>
                            </>
                          )}
                        </Row>
                      </td>
                    </tr>
                  </tbody>
                </RTable>
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 作業係数など
  const sagyouKeisuuContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={sagyouKeisuu}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>係数・その他 </label>
            {isSagyouKeisuu ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isSagyouKeisuu ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_Sagyoukeisuu
              editMode={editMode}
              processType={workType}
              detailsData={detailsProcess}
              yousosuuMasterData={yousosuuData}
              updatedYousosuuMasterData={updateYousosuu}
              sagyouKeisuuYousosuuList={sagyouKeisuuYousosuuList}
              updatedYousosuuList={updateYousosuuListData}
              // 要素数により、リスト更新
              shokidankaiYousosuuList={shokiDankaiJikanList}
              updatedShokidankaiYousosuuList={updateshokiDankaiJikanListData}
              kensaJikanKeisuuList={kensaJikanKeisuuList}
              updateKensaJikanKeisuuList={updateKensaJikanKeisuuList}
              // 同一サイズ、同一形状をパターン化する
              programSameSizePatternValue={programSameSizePatternValue}
              updatedProgramSameSizePatternValue={updateProgramSameSizePatternValue}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // iq3段取時間
  const iq3DantoriJikanContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={iq3DantoriJikan}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>段取時間</label>
            {isiq3DantoriJikan ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 10 }}>(分)</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isiq3DantoriJikan ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_DantoriJikan
              editMode={editMode}
              kosuu={seisanKosuu}
              updatedKosuu={updateSeisanKosuu}
              kosuuList={seisanKosuuList}
              updatedKosuuList={updateiq3KosuuList}
              // 基本段取時間
              dantoriTime={kihonDantori}
              updatedDantoriTime={updateDantoriTime}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // iq3作業・加工時間
  const iq3KakouJikanContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={iq3KakouJikan}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>{workType === WorkType.SmShearing ? '作業・加工時間' : '加工時間'}</label>
            {isiq3KakouJikan ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isiq3KakouJikan ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_KakouJikan
              detailsData={detailsProcess}
              editMode={editMode}
              processType={workType}
              processMstRecord={processMstRecord}
              materialTypes={materialTypes}
              // area={iq3AreaMaster}
              updatedArea={updateAreaThMaster}
              // listData={iq3KakouJikanListData}
              updatedIQ3KakouJikanList={updateWorkTimeThItems}
              // processName={getWorkTypeName(workType)}
              area={iq3AreaMaster}
              // updatedArea={updateIQ3AreaMaster}
              listData={iq3KakouJikanListData}
              // updatedIQ3KakouJikanList={updateIQ3KakouJikanList}
              // 補正リスト
              // correctionTimeFactorListData={correctionTimeFactorListData}
              // updatedcorrectionTimeFactorListData={updatecorrectionTimeFactorListData}
              // 二次加工
              iq3SizeMaster={iq3SizeMaster}
              updatediq3SizeMaster={updatediq3SizeMaster}
              iq3KaniListData={iq3KaniListData}
              updatediq3KaniListData={updatediq3KaniListData}
              iq3KaniAnaListData={iq3KaniAnaListData}
              mmMaster={mmMaster}
              updateSizeMmMaster={updateSizeMmMaster}
              updateiq3KaniAnaListData={updateiq3KaniAnaListData}
              itaatsu1={itaatsu1}
              updatedSizeItaatsu={updateSizeItaatsu}
              updatedMmItaatsu={updateMmItaatsu}
              // zaishitsu={zaishitsu}
              shirringflag={shirringflag}
              updateShirringFlag={updateShirringFlag}
              updateCutTime={updateCutTime}
              cutTime={cutTime}
              selectedRowData={props.selectedData}
              updateIsUsedEasyWorkSpeedVal={updateIsUsedEasyWorkSpeedVal}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // ブランク加工速度
  const BlankKakouSokudoContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={blankKakouSokudo}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>加工速度(mm/min)</label>
            {isBlankKakouSokudo ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isBlankKakouSokudo ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_Blank_Kakousokudo
              editMode={editMode}
              processName={workType}
              detailsData={detailsProcess}
              materialTypes={materialTypes}
              // itaatsu={iq3ItaatsuMaster}
              updatedItaatsu={updateWorkSpeedThickThMaster}
              // blankKakousokudolistData={blankKakousokudolistData}
              updatedBlankKakousokudolistData={updatedWorkSpeedItems}
              // // 1パンチ加工時間
              // punchProcessItaatsuListData={punchProcessItaatsuListData}
              // updatePunchProcessList={updatePunchProcessList}
              // // 1ピアス加工時間
              // piercingProcessList={piercingProcessItaatsuListData}
              // updatePiercingProcessList={updatePiercingProcessList}
              // // ガス種類
              // gasTypeList={gasTypeListData}
              // updatedGasTypeList={updateGasTypeList}
              // // ジョイント
              // jointItaatsuList={jointItaatsuListData}
              // updatedJointItaatsuList={updateJointItaatsuList}
              // // 加工速度係数
              // kakouSokudoItaatsuList={blankKakouSokudoItaatsuList}
              // updateblankKakouSokudoItaatsuList={updateblankKakouSokudoItaatsuList}
              okuriSokudoVal={okuriSokudoVal}
              updateOkuriSokudoVal={updateOkuriSokudoVal}
              kegakiSokudoVal={kegakiSokudoVal}
              updateKegakiSokudoVal={updateKegakiSokudoVal}
              selectedRowData={props.selectedData}
              updateIsUsedEasyWorkSpeedVal={updateIsUsedEasyWorkSpeedVal}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // ブランク加工速度係数
  const BlankKakouSokudoKeisuuContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={blankKakouSokudoKeisuu}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>加工速度係数</label>
            {isBlankKakouSokudoKeisuu ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isBlankKakouSokudoKeisuu ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_Blank_KakouSokudoKeisuu
              editMode={editMode}
              detailsData={detailsProcess}
              // itaatsuData={iq3ItaatsuMaster}
              updatedItaatsuData={updateWorkSpeedCoeffThickThMaster}
              // kakouSokudoItaatsuList={blankKakouSokudoItaatsuList}
              updateWorkSpeedCoeffInternalWorkItems={updateWorkSpeedCoeffInternalWorkItems}
              updateWorkSpeedCoeffExternalWorkItems={updateWorkSpeedCoeffExternalWorkItems}
              // // ジョイント
              // jointItaatsuList={jointItaatsuListData}
              // updatedJointItaatsuList={updateJointItaatsuList}
              // // ガス種類
              // gasTypeList={gasTypeListData}
              // updatedGasTypeList={updateGasTypeList}
              // // 1ピアス加工時間
              // piercingProcessList={piercingProcessItaatsuListData}
              // updatePiercingProcessList={updatePiercingProcessList}
              // // 1パンチ加工時間
              // punchProcessItaatsuListData={punchProcessItaatsuListData}
              // updatePunchProcessList={updatePunchProcessList}
              // // 加工速度
              // blankKakousokudolistData={blankKakousokudolistData}
              // updatedBlankKakousokudolistData={updatedBlankKakousokudolistData}
              // // 手動バリの加工速度
              // shudobariKakousokudoList={shudobariKakousokudoList}
              // updateShudobariKakousokudoList={updateShudobariKakousokudoList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // ブランクガス係数
  const BlankGasuKeisuuContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={blankGasuKeisuu}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>ガス係数</label>
            {isBlankGasuKeisuu ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isBlankGasuKeisuu ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_Blank_GasKeisuu
              editMode={editMode}
              detailsData={detailsProcess}
              materialTypes={materialTypes}
              // gasTypeData={gasTypeMaster}
              updatedGasType={updateGasCoeffThMaster}
              // gasKeisuuList={gasKeisuuList}
              updateGasKeisuuList={updateGasCoeffListItems}
              // ガス種類
              // itaatsuData={iq3ItaatsuMaster}
              updateGasCoeffThickThMaster={updateGasCoeffThickThMaster}
              // gasTypeList={gasTypeListData}
              updateGasCoeffThItems={updateGasCoeffThItems}
              // // ジョイント
              // jointItaatsuList={jointItaatsuListData}
              // updatedJointItaatsuList={updateJointItaatsuList}
              // // 加工速度係数
              // kakouSokudoItaatsuList={blankKakouSokudoItaatsuList}
              // updateblankKakouSokudoItaatsuList={updateblankKakouSokudoItaatsuList}
              // // 1ピアス加工時間
              // piercingProcessList={piercingProcessItaatsuListData}
              // updatePiercingProcessList={updatePiercingProcessList}
              // // 1パンチ加工時間
              // punchProcessItaatsuListData={punchProcessItaatsuListData}
              // updatePunchProcessList={updatePunchProcessList}
              // // 加工速度
              // blankKakousokudolistData={blankKakousokudolistData}
              // updatedBlankKakousokudolistData={updatedBlankKakousokudolistData}
            />
            {/* <Device_Blank_GasType
              editMode={editMode}
              itaatsuData={iq3ItaatsuMaster}
              updatedItaatsuData={updateIQ3Itaatsu}
              gasTypeList={gasTypeListData}
              updatedGasTypeList={updateGasTypeList}
              // ジョイント
              jointItaatsuList={jointItaatsuListData}
              updatedJointItaatsuList={updateJointItaatsuList}
              // 加工速度係数
              kakouSokudoItaatsuList={blankKakouSokudoItaatsuList}
              updateblankKakouSokudoItaatsuList={updateblankKakouSokudoItaatsuList}
              // 1ピアス加工時間
              piercingProcessList={piercingProcessItaatsuListData}
              updatePiercingProcessList={updatePiercingProcessList}
              // 1パンチ加工時間
              punchProcessItaatsuListData={punchProcessItaatsuListData}
              updatePunchProcessList={updatePunchProcessList}
              // 加工速度
              blankKakousokudolistData={blankKakousokudolistData}
              updatedBlankKakousokudolistData={updatedBlankKakousokudolistData}
            /> */}
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // ブランク外形追抜条件
  const extractConditionContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={extractCondition}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>追抜き条件</label>
            {isExtractCondition ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isExtractCondition ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_Blank_ExtractCondition
              editMode={editMode}
              detailsData={detailsProcess}
              // retainedMold={retainedMoldMaster}
              updatedRetainedMold={updatedRetainedMold}
              // extractConditionList={extractConditionList}
              updateExtractConditionList={updateExtractConditionList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // ブランクガス種類
  const BlankGasuTypeContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={blankGasuType}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>ガス種類</label>
            {isBlankGasuType ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isBlankGasuType ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_Blank_GasType
              editMode={editMode}
              itaatsuData={iq3ItaatsuMaster}
              updatedItaatsuData={updateIQ3Itaatsu}
              gasTypeList={gasTypeListData}
              updatedGasTypeList={updateGasTypeList}
              // ジョイント
              jointItaatsuList={jointItaatsuListData}
              updatedJointItaatsuList={updateJointItaatsuList}
              // 加工速度係数
              kakouSokudoItaatsuList={blankKakouSokudoItaatsuList}
              updateblankKakouSokudoItaatsuList={updateblankKakouSokudoItaatsuList}
              // 1ピアス加工時間
              piercingProcessList={piercingProcessItaatsuListData}
              updatePiercingProcessList={updatePiercingProcessList}
              // 1パンチ加工時間
              punchProcessItaatsuListData={punchProcessItaatsuListData}
              updatePunchProcessList={updatePunchProcessList}
              // 加工速度
              blankKakousokudolistData={blankKakousokudolistData}
              updatedBlankKakousokudolistData={updatedBlankKakousokudolistData}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // ブランク2工程穴加工時間
  const BlankDrillingTimeContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={blankDrillingTime}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>2工程穴加工時間</label>
            {isBlankDrillingTime ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isBlankDrillingTime ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_Blank_DrillingTime
              editMode={editMode}
              // drillingTime={drillingTime}
              detailsData={detailsProcess}
              updateDrillingTime={updateDrillingTime}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // ブランク送り速度
  const okuriSokudoContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={okuriSokudo}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>送り速度(mm/min)</label>
            {isOkuriSokudo ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isOkuriSokudo ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_Blank_OkuriSokudo
              editMode={editMode}
              // forwardSpeed={forwardSpeed}
              detailsData={detailsProcess}
              updateForwardSpeed={updateForwardSpeed}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // ローラー/ホイール/ケガキ金型　加工速度
  const rollerKakouSokudoContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={rollerKakouSokudo}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>ローラー/ホイール/ケガキ金型　加工速度(mm/sec)</label>
            {isRollerKakouSokudo ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isRollerKakouSokudo ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_Blank_RollerSpeed
              editMode={editMode}
              // rollerSpeed={rollerSpeed}
              detailsData={detailsProcess}
              updateRollerSpeed={updateRollerSpeed}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // ブランクジョイント
  const BlankJointContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={blankJoint}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>ジョイントバラシ(秒)</label> {/** IQX_WEBEST-264 */}
            {isBlankJoint ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isBlankJoint ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_Blank_Joint
              editMode={editMode}
              materialTypes={materialTypes}
              detailsData={detailsProcess}
              // itaatsuData={iq3ItaatsuMaster}
              updatedItaatsuData={updateJointSeparateThickThMaster}
              // jointItaatsuList={jointItaatsuListData}
              updatedJointItaatsuList={updateJointSeparateItems}
              // サイズc
              // jointSizeMaster={jointSizeMaster}
              updateJointQtySizeThMaster={updateJointQtySizeThMaster}
              // jointSizeList={jointSizeList}
              updateJointQtyItems={updateJointQtyItems}
              // // ガス種類
              // gasTypeList={gasTypeListData}
              // updatedGasTypeList={updateGasTypeList}
              // // 加工速度係数
              // kakouSokudoItaatsuList={blankKakouSokudoItaatsuList}
              // updateblankKakouSokudoItaatsuList={updateblankKakouSokudoItaatsuList}
              // // 1ピアス加工時間
              // piercingProcessList={piercingProcessItaatsuListData}
              // updatePiercingProcessList={updatePiercingProcessList}

              // // 加工速度
              // blankKakousokudolistData={blankKakousokudolistData}
              // updatedBlankKakousokudolistData={updatedBlankKakousokudolistData}
              // // 1パンチ加工時間
              // punchProcessItaatsuListData={punchProcessItaatsuListData}
              // updatePunchProcessList={updatePunchProcessList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // iq3加工速度
  const iq3KakousokudoContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={iq3KakouSokudo}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>加工速度(mm/min)</label>
            {isiq3KakouSokudo ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isiq3KakouSokudo ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_KakouSokudo
              editMode={editMode}
              detailsData={detailsProcess}
              // 手動バリの加工速度
              // itaatsuData={iq3ItaatsuMaster}
              updatedItaatsuData={updateWorkSpeedThickThMaster}
              // iq3kakouchou={iq3kakouchou}
              updateWorkSpeedLenThMaster={updateWorkSpeedLenThMaster}
              // shudobariKakousokudoList={shudobariKakousokudoList}
              updateShudobariKakousokudoList={updateWorkSpeedItems}
              // 加工速度係数
              kakouSokudoItaatsuList={blankKakouSokudoItaatsuList}
              updateblankKakouSokudoItaatsuList={updateblankKakouSokudoItaatsuList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // iq3手動バリ取対象サイズ
  const iq3ManualBariContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={iq3ManualBari}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>手動バリ取対象サイズ</label>
            {isiq3ManualBari ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isiq3ManualBari ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_ManualBari
              editMode={editMode}
              detailsData={detailsProcess}
              updateRoundHoleDia={updateRoundHoleDia}
              updateRectangleDia={updateRectangleDia}
              // naikei_min={naikei_min}
              // updateNaikeiMin={updateNaikeiMin}
              // naikei_max={naikei_max}
              // updateNaikeiMax={updateNaikeiMax}
              // ana_max={ana_max}
              // updateAnaMax={updateAnaMax}
              // ana_min={ana_min}
              // updateAnaMin={updateAnaMin}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 仮付時間
  const karitsukiTimeContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={attach}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>仮付時間</label>
            {isAttach ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isAttach ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <KaritsukiTime
              editMode={editMode}
              // 工程マスタ種類
              processTypes={processTypesList}
              detailsData={detailsProcess}
              processMstRecord={processMstRecord}
              // detailsItaatsu={karitsukiTimeItaatsuMasterData}
              updatedItaatsu={upadateKaritsukiItaatsuData}
              // listData={karitsukiTimeItaatsuList}
              updatedListData={updateKaritsukiTimeList}
              // 板厚データにより、取付き時間の板厚リスト更新
              // itaatsuList={toritsukiTimeItaatsuList}
              // updatedItaatsuList={updateToritsukiItaatsuList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 溶接仕上（加工時間）IQX_WEBEST-271 サブ溶接にも溶接仕上を出来るようにしてほしい
  const yosetsuFinishKakoujikanContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={kakouJikan}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>加工時間</label>
            {isKakouJikan ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isKakouJikan ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <div className="table-container" style={{ marginLeft: 10, marginRight: 10 }}>
              <RTable
                style={{
                  width: '196px',
                }}
              >
                {detailsProcess?.kakouTimeItems
                  ?.filter((item) => filterKeys?.includes(parseInt(item.kouteiTypeId)))
                  ?.map((key) => (
                    <tr key={key.kouteiTypeId} style={{ textAlign: 'center' }}>
                      <td style={{ color: 'white', width: '196px' }} className="tbl-header fixedCol">
                        {processTypesList?.find((item) => item.id === key.kouteiTypeId)?.name}
                      </td>
                      <td>
                        <ToritsukiJikan_Itaatsu
                          editMode={editMode}
                          processTypes={itaatsuKouteiTypeList}
                          detailsData={key}
                          processMstRecord={weldProcessMst}
                          updatedItaatsu={updateKakouTimeInstThickThMaster}
                          updatedListData={updateKakouTimeInstThickThItems}
                          isYosetsuFinish={true}
                        />
                        <ToritsukiJikan_Size
                          editMode={editMode}
                          processTypes={sizeKouteiTypeList}
                          detailsData={key}
                          processMstRecord={weldProcessMst}
                          updatedSize={updateKakouTimeInstSizeThMaster}
                          updatedSizeList={updateKakouTimeInstSizeThItems}
                          isYosetsuFinish={true}
                        />
                      </td>
                    </tr>
                  ))}
              </RTable>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 取付＿板厚
  const toritsukiTimeItaatsuContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={toritsukiJikanItaatsu}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>取付時間(板厚判断)</label>
            {isToritsukiJikanItaatsu ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isToritsukiJikanItaatsu ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <div className="table-container" style={{ marginLeft: 10, marginRight: 10 }}>
              <Row>
                <ToritsukiJikan_Itaatsu
                  editMode={editMode}
                  // 工程マスタ種類
                  processTypes={processTypesList}
                  detailsData={detailsProcess}
                  processMstRecord={processMstRecord}
                  updatedItaatsu={updateInstThickThMaster}
                  updatedListData={updateInstThickThItems}
                />
              </Row>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 取付＿サイズ
  const toritsukiTimeSizeContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={toritsukiJikanSize}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>取付時間(サイズ判断)</label>
            {isToritsukiJikanSize ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isToritsukiJikanSize ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <div className="table-container" style={{ marginLeft: 10, marginRight: 10 }}>
              <Row>
                <ToritsukiJikan_Size
                  editMode={editMode}
                  processTypes={processTypesList}
                  detailsData={detailsProcess}
                  processMstRecord={processMstRecord}
                  updatedSize={updateInstSizeThMaster}
                  updatedSizeList={updateInstSizeThItems}
                />
              </Row>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 溶接難易度
  const yousetsuDifficultyContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={welding}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>溶接難易度</label>
            {isWelding ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isWelding ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <YousetsuDifficulty
              editMode={editMode}
              updatedItaatsu={updateWeldDifficultyCoeffThMaster}
              updatedListData={updatedWeldDifficultyCoeffThItems}
              detailsData={detailsProcess}
              materialTypes={materialTypes}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // タップ溶接
  const tappuYousetsuContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={tapWelding}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>タップ溶接(%/辺)</label>
            {isTapWelding ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isTapWelding ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <TapWelding
              editMode={editMode}
              detailsData={detailsProcess}
              materialTypes={materialTypes}
              updatedItaatsu={updateTapRateThMaster}
              updatedItaatsuList={updateTapRateThItems}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 点付、仮付ピッチ
  const pitchContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={pitch}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>点付、仮付ピッチ(mm/辺)</label>
            {isPitch ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isPitch ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Pitch
              editMode={editMode}
              updatedItaatsu={updateSpotPitchThMaster}
              updatedItaatsuList={updateSpotPitchThItems}
              detailsData={detailsProcess}
              // 材質区分データ
              materialTypes={materialTypes}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );

  // 製品重量係数
  const productSizeContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={productSize}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>重量係数</label>
            {isProductSize ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isProductSize ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            {/* 重量係数 */}
            <ProductSize
              detailsData={detailsProcess}
              editMode={editMode}
              // kosuu={kosuu}
              updatedMstJuuryouData={updateProductWeightCoeffMst}
              updatedJuuryouList={updateProductWeightCoeffList}
              // weight={weight}
              // updatedWeight={updateProductSizeWeight}
              // sizeList={sizesDetailsList}
              // updatedSizesList={updateKosuuList}
              // dantoriSizeList={dantoriSizeDetailsList}
              // updatedDantoriSizeList={updateDantoriSizeList}
              // 生産個数係数リスト更新
              // kosuuList={kosuuList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 生産個数係数
  const quantityContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={productQuantity}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>生産個数係数</label>
            {isProductQuantity ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isProductQuantity ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Quantity
              detailsData={detailsProcess}
              editMode={editMode}
              // kosuu={kosuu}
              updatedKosuu={updateProductionQtyCoeffMst}
              // kosuuList={kosuuList}
              updatedKosuuList={updateProductionQtyCoeffList}
              // ２次加工の補正時間
              // jikankosuuList={iq3BendingHonsuuList}
              // updatedJikanKosuuList={updateiq3BendingHonsuuList}
              // 重量係数
              // sizeList={sizesDetailsList}
              // updatedSizesList={updateProductSizeList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );

  // 作業・加工時間
  const childPartContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={childPart}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>作業・加工時間</label>
            {isChildPart ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isChildPart ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Row className="chargeAmount" style={{ marginLeft: 10, marginRight: 10 }}>
              <RTable style={{ width: '20%' }}>
                <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                  <tr style={{ textAlign: 'center' }}>
                    <th className="tbl-header">1検査時間 </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ textAlign: 'center', height: 35 }}>
                    <td>
                      <Row style={{ textAlign: 'center' }} align="middle">
                        {!editMode ? (
                          <>
                            <Col span={24}>
                              <label>{secondsToHms(processTimeByUnit)}</label>
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col span={10}>
                              <Input
                                value={secondsToHms(processTimeByUnit)}
                                onChange={(event) => changeTime(event.target.value)}
                              ></Input>
                            </Col>
                            <Col span={14}>
                              <CaretUpOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  marginLeft: 3,
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleIncreaseTime(secondsToHms(processTimeByUnit), '10min');
                                  changeTime(editedTime);
                                }}
                              />
                              <CaretDownOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleDecreaseTime(secondsToHms(processTimeByUnit), '10min');
                                  changeTime(editedTime);
                                }}
                              />
                              <CaretUpOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleIncreaseTime(secondsToHms(processTimeByUnit), 'min');
                                  changeTime(editedTime);
                                }}
                              />
                              <CaretDownOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleDecreaseTime(secondsToHms(processTimeByUnit), 'min');
                                  changeTime(editedTime);
                                }}
                              />
                              <CaretUpOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleIncreaseTime(secondsToHms(processTimeByUnit), 'sec');
                                  changeTime(editedTime);
                                }}
                              />
                              <CaretDownOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleDecreaseTime(secondsToHms(processTimeByUnit), 'sec');
                                  changeTime(editedTime);
                                }}
                              />
                            </Col>
                          </>
                        )}
                      </Row>
                    </td>
                  </tr>
                </tbody>
              </RTable>
            </Row>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 難易度係数
  const difficultyFactorContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={difficultyFactor}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>難易度係数</label>
            {isDifficultyFactor ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isDifficultyFactor ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_YousetsuShiage_DifficultyFactor
              detailsData={detailsProcess}
              materialTypes={materialTypes}
              editMode={editMode}
              updateDiffFactorCoeff={updateDiffFactorCoeff}
            />
          </>
        ) : (
          <></>
        )}{' '}
      </div>
    </>
  );
  // 仕上有無　IQX_WEBEST-271 パラメータに現状持っている「仕上げ有無」（溶接種類ごとのチェックボックス）を取り除く。
  const shiageFlagContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={shiageFlag}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>仕上有無</label>
            {isShiageFlag ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isShiageFlag ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_ShiageFlag
              detailsData={detailsProcess}
              processMstRecord={processMstRecord}
              yousetsuTypes={yousetsuTypes}
              editMode={editMode}
              // shiageFlagList={shiageFlagList}
              updateShiageFlagList={updateShiageFlagList}
            />
          </>
        ) : (
          <></>
        )}{' '}
      </div>
    </>
  );

  // 工程種類により、Propertiesの情報を判断する
  const content =
    // 溶接の場合、
    workType === WorkType.PaWelding || workType === WorkType.SmWelding ? (
      <>
        <div>
          {chargeAmountContent}
          {dantoriSettingContent}
          {/* {types === '親部品' ? '' : materialHandlingContent} */}
          {machineSpeedContent}
          {karitsukiTimeContent}
          {toritsukiTimeItaatsuContent}
          {toritsukiTimeSizeContent}
          {/* {tappuYousetsuContent}
          {pitchContent} */}
          {yousetsuDifficultyContent}
          {/* iQ3 */}
          {types === ServiceClass.SheetMetal ? (
            <>
              {/* {materialHandlingContent} */}
              {productSizeFactorContent}
              {quantityContent}
              {/* {kouteiNaiKensaContent} */}
            </>
          ) : (
            <>
              {productSizeContent}
              {quantityContent}
            </>
          )}
        </div>
      </>
    ) : workType === WorkType.PaWeldFinish || workType === WorkType.SmWeldFinish ? (
      // 溶接仕上の場合、
      <div>
        {chargeAmountContent}
        {dantoriSettingContent}
        {/* iQ3 */}
        {types === ServiceClass.SheetMetal ? (
          <>
            {machineSpeedContent}
            {yosetsuFinishKakoujikanContent}
            {/*IQX_WEBEST-271 サブ溶接にも溶接仕上を出来るようにしてほしい*/}
            {difficultyFactorContent}
            {/** IQX_WEBEST-271 パラメータに現状持っている「仕上げ有無」（溶接種類ごとのチェックボックス）を取り除く。 */}
            {/* {shiageFlagContent} */}
            {/*  {materialHandlingContent} */}
            {productSizeFactorContent}
            {quantityContent}
            {/* {kouteiNaiKensaContent} */}
          </>
        ) : (
          <>
            {machineSpeedContent}
            {productSizeContent}
            {quantityContent}
          </>
        )}
      </div>
    ) : workType === WorkType.PaSurface || workType === WorkType.SmSurface ? (
      // 表面処理の場合、
      <>
        <div>
          {/* チャージ金額 */}
          {chargeAmountContent}
          {/* 段取設定 */}
          {dantoriSettingContent}
          {/*  {types === ServiceClass.SheetMetal ? materialHandlingContent : ''} */}
          {/* 加工時間 */}
          {iq3KakouJikanContent}
          {/* 製品重量係数 */}
          {productSizeContent}
          {/* 生産個数係数 */}
          {quantityContent}
          {/* iQ3 */}
          {/* {types === ServiceClass.SheetMetal ? kouteiNaiKensaContent : ''} */}
        </div>
      </>
    ) : workType === WorkType.PaAssenble ||
      workType === WorkType.PaInspection ||
      workType === WorkType.PaPacking ||
      workType === WorkType.SmInspection ? (
      // 組立、検査、梱包の場合、
      <div>
        {chargeAmountContent}
        {dantoriSettingContent}
        {/* 親部品 */}
        {types === ServiceClass.Parent &&
        // 組立、検査、梱包の場合、
        (workType === WorkType.PaAssenble || workType === WorkType.PaPacking || workType === WorkType.PaInspection)
          ? sagyouJikanContent
          : childPartContent}
        {/* {childPartContent} */}
        {/* 検査とiQ3 */}
        {/*  {types === ServiceClass.SheetMetal && workType === WorkType.SmInspection ? materialHandlingContent : ''} */}
        {/* 検査とiQ3 */}
        {types === ServiceClass.SheetMetal && workType === WorkType.SmInspection
          ? productSizeFactorContent
          : productSizeContent}
        {/* {difficultyFactorContent} */}
        {quantityContent}
      </div>
    ) : workType === WorkType.SmProgram ? (
      // プログラム
      <>
        <div>
          {chargeAmountContent}
          {dantoriSettingContent}
          {sagyouSokudoContent}
          {sagyouKeisuuContent}
          {/* {kouteiNaiKensaContent} */}
        </div>
      </>
    ) : workType === WorkType.SmShearing ? (
      // シャーリング
      <>
        <div>
          {chargeAmountContent}
          {dantoriSettingContent}
          {iq3KakouJikanContent}
          {/*  {materialHandlingContent} */}
          {productSizeFactorContent}
          {quantityContent}
          {/* {kouteiNaiKensaContent} */}
        </div>
      </>
    ) : workType === WorkType.SmBending ? (
      // ベンディング
      <div>
        {chargeAmountContent}
        {dantoriSettingContent}
        {bendingTimeContent}
        {bendingTimeFactorContent}
        {bendingCorrectionTimeContent}
        {/* {bendingCorrectionTimeFactorContent} */}
        {/* {materialHandlingContent} */}
        {productSizeFactorContent}
        {quantityContent}
        {/* {kouteiNaiKensaContent} */}
      </div>
    ) : workType === WorkType.SmSecondaryWork ? (
      // 2次加工
      <>
        <div>
          {chargeAmountContent}
          {dantoriSettingContent}
          {iq3KakouJikanContent}
          {bendingCorrectionTimeContent}
          {/* {bendingCorrectionTimeFactorContent} */}
          {/*  {materialHandlingContent} */}
          {productSizeFactorContent}
          {quantityContent}
          {/* {kouteiNaiKensaContent} */}
        </div>
      </>
    ) : workType === WorkType.SmDeburringAuto ? (
      // 自動バリ
      <div>
        {chargeAmountContent}
        {dantoriSettingContent}
        {iq3KakouJikanContent}
        {/* {bendingCorrectionTimeContent} */} {/** IQX_WEBEST-270 */}
        {/*  {materialHandlingContent} */}
        {productSizeFactorContent}
        {quantityContent}
        {/* {kouteiNaiKensaContent} */}
      </div>
    ) : workType === WorkType.SmDeburringManual ? (
      <div>
        {chargeAmountContent}
        {dantoriSettingContent}
        {iq3KakousokudoContent}
        {BlankKakouSokudoKeisuuContent}
        {bendingCorrectionTimeContent}
        {iq3ManualBariContent}
        {/*  {materialHandlingContent} */}
        {productSizeFactorContent}
        {quantityContent}
        {/* {kouteiNaiKensaContent} */}
      </div>
    ) : workType === WorkType.SmBlankPunch ? (
      // ブランク（パンチ）
      <>
        {/* {baritoriTypesContent} */}
        {chargeAmountContent}
        {dantoriSettingContent}
        {materialArrangementContent}
        {punchConditionContent}
        {punchProcessTimeContent}
        {okuriSokudoContent}
        {rollerKakouSokudoContent}
        {extractConditionContent}
        {productSizeFactorContent}
        {quantityContent}
        {BlankDrillingTimeContent}
        {BlankJointContent}
        {/* {kouteiNaiKensaContent} */}
      </>
    ) : workType === WorkType.SmBlankCombi ? (
      // ブランク（複合機）
      <>
        {/* {baritoriTypesContent} */}
        {chargeAmountContent}
        {dantoriSettingContent}
        {materialArrangementContent}
        {punchConditionContent}
        {punchProcessTimeContent}
        {piercingProcessTimeContent}
        {BlankKakouSokudoContent}
        {BlankKakouSokudoKeisuuContent}
        {BlankGasuKeisuuContent}
        {productSizeFactorContent}
        {quantityContent}
        {BlankDrillingTimeContent}
        {BlankJointContent}
        {/* {kouteiNaiKensaContent} */}
      </>
    ) : workType === WorkType.SmBlankLaser ? (
      // ブランク（レーザー）
      <>
        {/* {baritoriTypesContent} */}
        {chargeAmountContent}
        {dantoriSettingContent}
        {materialArrangementContent}
        {piercingProcessTimeContent}
        {BlankKakouSokudoContent}
        {BlankKakouSokudoKeisuuContent}
        {BlankGasuKeisuuContent}
        {productSizeFactorContent}
        {quantityContent}
        {BlankDrillingTimeContent}
        {BlankJointContent}
        {/* {kouteiNaiKensaContent} */}
      </>
    ) : (
      <div>
        {chargeAmountContent}
        {dantoriSettingContent}
        {/* {machineSpeedContent}
        {karitsukiTimeContent}

        {toritsukiTimeItaatsuContent}
        {toritsukiTimeSizeContent}
        {yousetsuDifficultyContent}
        {tappuYousetsuContent}
        {pitchContent} */}
        {productSizeContent}
        {quantityContent}
      </div>
    );

  const searchCustomer = () => {
    setCustomerModal(true);
  };
  const getCustomerData = (selectedCustomerData) => {
    setCustomerId(selectedCustomerData.id);
    setCustomerCode(selectedCustomerData.code);
    setCustomerName(selectedCustomerData.name);
    setCustomerModal(false);
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setCheckboxChecked(isChecked);
    setSelectDisabled(!isChecked);
  };
  const flagChange = (e) => {
    const isChecked = e.target.checked;
    setFlagChecked(isChecked);
    setInputDisabled(!isChecked);
  };
  const changeAmount = (name, e) => {
    // const regex = /^[0-9,¥]+$/;
    // if (regex.test(e)) {
    //   if (e.startsWith('¥', 0)) {
    //     e = e.replace('¥', '');
    //   }
    //   if (e.indexOf(',') > 0) {
    //     e = e.replace(/,/g, '');
    //   }
    // } else {
    //   e = 0;
    // }
    // if (name === 'dantori') {
    //   setSetupAmount(e);
    // } else if (name === 'kakou') {
    //   setWorkAmount(e);
    // } else {
    //   setIniAmount(e);
    // }
    setIniAmount(e);
  };
  const closeAll = () => {
    props.updatedFlag(false);
    setIsCharge(false);
    setIsSetupSetting(false);
    setIsProcessSpeed(false);
    setIsAttach(false);
    setIsToritsukiJikanItaatsu(false);
    setIsToritsukiJikanSize(false);
    setIsDifficultyFactor(false);
    setIsTapWelding(false);
    setIsPitch(false);
    setIsProductSize(false);
    setIsProductQuantity(false);
    setIsChildPart(false);
    setIsDifficultyFactor(false);
    setIsDantoriJikan(false);
    setIsDeviceDetails(false);
    setIsProcessTime(false);
    setIsWelding(false);
    setIsSagyouSokudo(false);
    setIsSagyouKeisuu(false);
    setIsiq3DantoriJikan(false);
    setIsiq3KakouJikan(false);
    setIsBendingTimeFactor(false);
    setIsMagekakouJikan(false);
    setIsMageHoseiJikanFactor(false);
    setIsMageHoseiJikan(false);
    setIsBaritoriShurui(false);
    setIsiq3KakouSokudo(false);
    setIsiq3ManualBari(false);
    setIsProcessSizeFactor(false);
    setIsKouteiNaikensa(false);
    setIsMaterialHandling(false);
    setIsArrangeMaterial(false);
    setIsPiercingProcess(false);
    setIsBlankKakouSokudo(false);
    setIsBlankKakouSokudoKeisuu(false);
    setIsBlankGasuKeisuu(false);
    setIsBlankGasuType(false);
    setIsBlankJoint(false);
    setIsBlankDrillingTime(false);
    setIsPunch(false);
    setIsOkuriSokudo(false);
    setIsRollerKakouSokudo(false);
    setIsExtractCondition(false);
    setIsPunchProcess(false);
    setIsShiageFlag(false);
  };
  const openAll = () => {
    props.updatedFlag(true);
    setIsCharge(true);
    setIsSetupSetting(true);
    setIsProcessSpeed(true);
    setIsAttach(true);
    setIsToritsukiJikanItaatsu(true);
    setIsToritsukiJikanSize(true);
    setIsDifficultyFactor(true);
    setIsTapWelding(true);
    setIsPitch(true);
    setIsProductSize(true);
    setIsProductQuantity(true);
    setIsChildPart(true);
    setIsDifficultyFactor(true);
    setIsDantoriJikan(true);
    setIsDeviceDetails(true);
    setIsProcessTime(true);
    setIsWelding(true);
    setIsSagyouSokudo(true);
    setIsSagyouKeisuu(true);
    setIsiq3DantoriJikan(true);
    setIsiq3KakouJikan(true);
    setIsBendingTimeFactor(true);
    setIsMagekakouJikan(true);
    setIsMageHoseiJikanFactor(true);
    setIsMageHoseiJikan(true);
    setIsBaritoriShurui(true);
    setIsiq3KakouSokudo(true);
    setIsiq3ManualBari(true);
    setIsProcessSizeFactor(true);
    setIsKouteiNaikensa(true);
    setIsMaterialHandling(true);
    setIsArrangeMaterial(true);
    setIsPiercingProcess(true);
    setIsBlankKakouSokudo(true);
    setIsBlankKakouSokudoKeisuu(true);
    setIsBlankGasuKeisuu(true);
    setIsBlankGasuType(true);
    setIsBlankJoint(true);
    setIsBlankDrillingTime(true);
    setIsPunch(true);
    setIsOkuriSokudo(true);
    setIsRollerKakouSokudo(true);
    setIsExtractCondition(true);
    setIsPunchProcess(true);
    setIsShiageFlag(true);
  };

  const addDetailsModalTitle = (
    <div
      style={{
        width: 1030,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>チャージ金額詳細</p>
    </div>
  );
  const closeModal = (result) => {
    setIsDetails(result);
  };
  const closeAddModal = () => {
    setIsDetails(false);
  };
  const addData = () => {};
  const addContent = (
    <>
      <ChargeDetails editMode={editMode} closeModal={closeModal} />
    </>
  );
  // 追加工程の種類更新
  const changeAdditionalKouteiType = (e) => {
    const option = processTypesList?.find((option) => option.id === e);
    setAdditionalProcessId(option.id);
    setKouteiCode(option.code);
    setKouteiName(option.name);
  };

  // CSVファイルのヘッダー情報
  const getCSVHeaderInfo = () => {
    return (
      'ID,コード,工程名,' +
      (workType === WorkType.PaCustom || workType === WorkType.SmCustom ? 'コード,工程名,' : '') +
      '装置コード,装置名,備考,表示'
    );
  };

  // CSV入力ボタンを押下した場合、
  const onClickCSVImport = () => {
    csvImportRef.current.click();
  };

  // CSV出力ボタンを押下した場合、
  const onClickCSVExport = () => {
    let name = '';
    let data = '';
    name = getCsvExportFileName();
    data = getCsvExportData();
    let link = csvExportRef.current.link;
    const BOM = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([BOM, data], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', name);
    link.click();
  };

  // CSV入力ファイルを選択した場合、
  const handleCSVFileChange = (event) => {
    //FileReaderの定義
    const reader = new FileReader();
    let file = event.target.files[0];
    reader.onload = async () => {
      let result = await reader?.result?.split('\n');
      // IQX_WEBEST-301 パラメータ入出力機能において、エクセルで保存したCSVファイルも入力ファイルとして使用できるようにして欲しい。
      if (reader?.result?.includes('\r\n')) {
        // エクセル入力の場合
        result = await reader?.result?.split('\r\n');
      }
      submitCSVImportData(result);
    };
    reader.readAsText(file);
  };

  // 装置設定のプロパティビューのパンネル情報
  const getDetailMenuTab = () => {
    let detailMenuTab = [];
    // 表面処理の場合
    if (workType === WorkType.PaSurface || workType === WorkType.SmSurface) {
      if (types === ServiceClass.SheetMetal) {
        detailMenuTab = [
          'チャージ金額(/h)',
          '段取設定',
          '子部品サイズ係数',
          '段取個数係数',
          '加工時間',
          '重量係数',
          '生産個数係数',
        ];
      } else {
        detailMenuTab = [
          'チャージ金額(/h)',
          '段取設定',
          '段取係数1',
          '段取係数2',
          '加工時間',
          '重量係数',
          '生産個数係数',
        ];
      }
    } else if (workType === WorkType.PaInspection || workType === WorkType.SmInspection) {
      // 検査の場合、
      if (types === ServiceClass.SheetMetal) {
        detailMenuTab = [
          'チャージ金額(/h)',
          '段取設定',
          '子部品サイズ係数',
          '段取個数係数',
          '作業・加工時間',
          'サイズ係数',
          '生産個数係数',
        ];
      } else {
        detailMenuTab = [
          'チャージ金額(/h)',
          '段取設定',
          '段取係数1',
          '段取係数2',
          '作業時間',
          '重量係数',
          '生産個数係数',
        ];
      }
    } else if (workType === WorkType.PaCustom || workType === WorkType.SmCustom) {
      // 追加工程の場合、
      if (types === ServiceClass.SheetMetal) {
        detailMenuTab = [
          'チャージ金額(/h)',
          '段取設定',
          '子部品サイズ係数',
          '段取個数係数',
          '作業・加工時間',
          'サイズ係数',
          '生産個数係数',
          '委託装置詳細',
        ];
      } else {
        detailMenuTab = [
          'チャージ金額(/h)',
          '段取設定',
          '段取係数1',
          '段取係数2',
          '作業時間',
          '重量係数',
          '生産個数係数',
          '委託装置詳細',
        ];
      }
    } else if (workType === WorkType.PaAssenble || workType === WorkType.PaPacking) {
      // 組立の場合、// 梱包の場合、
      detailMenuTab = [
        'チャージ金額(/h)',
        '段取設定',
        '段取係数1',
        '段取係数2',
        '作業時間',
        '重量係数',
        '生産個数係数',
      ];
    } else if (workType === WorkType.SmProgram) {
      // プログラムの場合、
      detailMenuTab = ['チャージ金額(/h)', '段取設定', '加工設定', '係数・その他', '初期段取時間', '作業時間係数'];
    } else if (workType === WorkType.SmShearing) {
      // シャーリングの場合、
      detailMenuTab = [
        'チャージ金額(/h)',
        '段取設定',
        '子部品サイズ係数',
        '段取個数係数',
        '作業・加工時間',
        'サイズ係数',
        '生産個数係数',
      ];
    } else if (workType === WorkType.SmBending) {
      // ベンディングの場合、
      detailMenuTab = [
        'チャージ金額(/h)',
        '段取設定',
        'プログラム作成時間',
        '曲長段取時間',
        '子部品サイズ係数',
        '段取個数係数',
        '作業・加工時間',
        '曲げ長さ係数',
        '加工補正時間',
        '加工補正時間係数',
        'サイズ係数',
        '生産個数係数',
      ];
    } else if (workType === WorkType.SmDeburringAuto) {
      // 自動バリの場合、
      detailMenuTab = [
        'チャージ金額(/h)',
        '段取設定',
        '子部品サイズ係数',
        '段取個数係数',
        '加工時間',
        'サイズ係数',
        '生産個数係数',
      ];
    } else if (workType === WorkType.SmDeburringManual) {
      // 手動バリの場合、
      detailMenuTab = [
        'チャージ金額(/h)',
        '段取設定',
        '子部品サイズ係数',
        '段取個数係数',
        '加工速度(mm/min)',
        '加工速度係数',
        '加工補正時間',
        '加工補正時間係数',
        '手動バリ取対象サイズ',
        'サイズ係数',
        '生産個数係数',
      ];
    } else if (workType === WorkType.SmWelding) {
      // 溶接の場合、
      detailMenuTab = [
        'チャージ金額(/h)',
        '段取設定',
        '子部品サイズ係数',
        '段取個数係数',
        '加工速度(mm/min)',
        '仮付時間',
        '取付時間(板厚判断)',
        '取付時間(サイズ判断)',
        '溶接難易度',
        'サイズ係数',
        '生産個数係数',
      ];
    } else if (workType === WorkType.SmWeldFinish) {
      // 溶接仕上の場合、
      detailMenuTab = [
        'チャージ金額(/h)',
        '段取設定',
        '子部品サイズ係数',
        '段取個数係数',
        '加工速度(mm/min)',
        '難易度係数',
        'サイズ係数',
        '生産個数係数',
      ];
    } else if (workType === WorkType.SmSecondaryWork) {
      // ２次加工の場合、
      detailMenuTab = [
        'チャージ金額(/h)',
        '段取設定',
        '子部品サイズ係数',
        '段取個数係数',
        '加工時間',
        '加工種類/mm',
        '加工補正時間',
        '加工補正時間係数',
        'サイズ係数',
        '生産個数係数',
      ];
    } else if (workType === WorkType.SmBlankPunch) {
      // パンチの場合、
      detailMenuTab = [
        'チャージ金額(/h)',
        '段取設定',
        '段取個数係数',
        '金型段取時間',
        '材料配置',
        'パンチ条件',
        '1パンチ加工時間',
        '送り速度(mm/min)',
        'ローラー/ホイール/ケガキ金型　加工速度(mm/sec)',
        '追抜き条件',
        'サイズ係数',
        '生産個数係数',
        '2工程穴加工時間',
        'ジョイントバラシ',
      ];
    } else if (workType === WorkType.SmBlankLaser) {
      // レーザーの場合、
      detailMenuTab = [
        'チャージ金額(/h)',
        '段取設定',
        '段取個数係数',
        '材料配置',
        '1ピアス加工時間',
        '加工速度(mm/min)',
        'ケガキ速度,送り速度',
        '加工速度係数',
        'ガス係数',
        'サイズ係数',
        '生産個数係数',
        '2工程穴加工時間',
        'ジョイントバラシ',
      ];
    } else if (workType === WorkType.SmBlankCombi) {
      // 複合機の場合、
      detailMenuTab = [
        'チャージ金額(/h)',
        '段取設定',
        '段取個数係数',
        '金型段取時間',
        '材料配置',
        'パンチ条件',
        '1パンチ加工時間',
        '1ピアス加工時間',
        '加工速度(mm/min)',
        'ケガキ速度,送り速度',
        '加工速度係数',
        'ガス係数',
        'サイズ係数',
        '生産個数係数',
        '2工程穴加工時間',
        'ジョイントバラシ',
      ];
    }
    return detailMenuTab;
  };

  // CSV出力情報
  const getCsvExportData = () => {
    let detailMenuTab = getDetailMenuTab();
    let str = getCSVHeaderInfo();
    // CSV内容
    str +=
      '\n' +
      detailId +
      ',' +
      code +
      ',' +
      processName +
      (workType === WorkType.PaCustom || workType === WorkType.SmCustom ? ',' + kouteiCode + ',' + kouteiName : '') +
      ',' +
      deviceCode +
      ',' +
      deviceName +
      ',' +
      remarks +
      ',' +
      isUsed;

    // 表面処理の場合
    if (workType === WorkType.PaSurface || workType === WorkType.SmSurface) {
      str = getSurfaceCSVExportData(str, detailMenuTab);
    } else if (workType === WorkType.PaInspection || workType === WorkType.SmInspection) {
      // 検査
      str = getKensaCSVExportData(str, detailMenuTab);
    } else if (workType === WorkType.PaCustom || workType === WorkType.SmCustom) {
      // 追加工程
      str = getCustomCSVExportData(str, detailMenuTab);
    } else if (workType === WorkType.PaAssenble || workType === WorkType.PaPacking) {
      // 組立 // 梱包
      str = getAssenblePackingCSVExportData(str, detailMenuTab);
    } else if (workType === WorkType.SmProgram) {
      // プログラム
      str = getProgramCSVExportData(str, detailMenuTab);
    } else if (workType === WorkType.SmShearing) {
      // シャーリング
      str = getShearingCSVExportData(str, detailMenuTab);
    } else if (workType === WorkType.SmBending) {
      //　ベンディング
      str = getBendingCSVExportData(str, detailMenuTab);
    } else if (workType === WorkType.SmDeburringAuto) {
      // 自動バリ
      str = getAutoDeburCSVExportData(str, detailMenuTab);
    } else if (workType === WorkType.SmDeburringManual) {
      // 手動バリ
      str = getManualDeburCSVExportData(str, detailMenuTab);
    } else if (workType === WorkType.SmWelding) {
      // 溶接
      str = getWeldCSVExportData(str, detailMenuTab);
    } else if (workType === WorkType.SmWeldFinish) {
      // 溶接仕上
      str = getWeldFinishCSVExportData(str, detailMenuTab);
    } else if (workType === WorkType.SmSecondaryWork) {
      // ２次加工
      str = getSecondaryWorkCSVExportData(str, detailMenuTab);
    } else if (workType === WorkType.SmBlankPunch) {
      // パンチ
      str = getPunchCSVExportData(str, detailMenuTab);
    } else if (workType === WorkType.SmBlankLaser) {
      // レーザー
      str = getLaserCSVExportData(str, detailMenuTab);
    } else if (workType === WorkType.SmBlankCombi) {
      // 複合機
      str = getCombiCSVExportData(str, detailMenuTab);
    }
    return str;
  };

  // 表面処理の情報をCSVファイル内容に変換する
  const getSurfaceCSVExportData = (str, detailMenuTab) => {
    // 工程種別の詳細情報を得る
    const groupDisplayList = getProcessDetailTypesInfo(detailsProcess?.charges, processMstRecord);
    // チャージ金額(/h)
    str += '\n' + detailMenuTab[0];
    Object.entries(groupDisplayList)?.map(([_, groupItem]) => {
      groupItem?.map((subItem) => {
        str += '\n';
        str += subItem?.name;
        getChargesDetailValues(subItem?.id, detailsProcess?.charges)?.map((item, i) => {
          if (parseInt(item) === subItem?.id && i === 0) return;
          str += '\n';
          str += '段取(￥/h)';
          str += ',' + (item?.unit === MaterialSurfaceBillingUnit.Work) + ',時間(￥/h)';
          str += ',' + (item?.unit === MaterialSurfaceBillingUnit.Weight) + ',重量(￥/kg)';
          str += ',' + (item?.unit === MaterialSurfaceBillingUnit.SquareMeter) + ',面積(￥/m2)';
          str += '\n';
          str += item.prep + ',,' + item.work + ',,' + item.weight + ',,' + item.squareMeter;
        });
      });
    });
    // 段取設定
    str += '\n' + detailMenuTab[1];
    Object.entries(groupDisplayList)?.map(([_, groupItem]) => {
      groupItem?.map((subItem, index) => {
        str += '\n';
        str += subItem?.name;
        getPrepSettingDetailValues(subItem?.id, detailsProcess?.prepSecs)?.map((item, i) => {
          if (parseInt(item) === subItem?.id && i === 0) return;
          str += '\n';
          str += secondsToHms(item);
        });
      });
    });
    if (workType === WorkType.SmSurface && types === ServiceClass.SheetMetal) {
      // 段取設定の子部品サイズ係数
      str += '\n' + detailMenuTab[2];
      str += '\n' + 'kg/cm2';
      //面積マスタ
      detailsProcess?.childPartsCoeffAreaThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item) => {
          str += ',';
          str += '～' + item.value + 'cm2';
        });

      //重量マスタ
      detailsProcess?.childPartsCoeffWeightThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item) => {
          str += '\n';
          str += item.value + 'kg';
          //係数値
          let areaCoeffLists = detailsProcess?.childPartsCoeff?.filter(
            (itemCoef) => !itemCoef.isOutOfRange && itemCoef.weightId === item.no
          )?.[0]?.areaCoeffList;
          areaCoeffLists?.map((row) => {
            str += ',';
            str += toDecimal(row.value, 2);
          });
        });

      // 段取設定の段取個数係数
      str += '\n' + detailMenuTab[3];
      str += '\n' + '個数';
      detailsProcess?.prepQtyCoeffThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item) => {
          str += ',';
          str += '～' + item.value + '個';
        });
      str += '\n' + detailMenuTab[3];
      detailsProcess?.prepQtyCoeffItems
        ?.filter((item) => !item.isOutOfRange)
        ?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });
    } else if (workType === WorkType.PaSurface && types === ServiceClass.Parent) {
      // 段取係数1
      str += '\n' + detailMenuTab[2];
      str += '\n' + '種類/個数';
      detailsProcess?.prepThQtyMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item) => {
          str += ',';
          str += '～' + item.value + '個';
        });
      str += '\n' + '子部品点数';
      detailsProcess?.prepCoeffThChildQtyItems
        ?.filter((item) => !item.isOutOfRange)
        ?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });

      // 段取係数2
      str += '\n' + detailMenuTab[3];
      str += '\n' + '重量';
      detailsProcess?.prepCoeffThWeightMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item) => {
          str += ',';
          str += '～' + item.value + 'kg';
        });
      str += '\n' + '重量係数';
      detailsProcess?.prepCoeffThWeightItems
        ?.filter((item) => !item.isOutOfRange)
        ?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });
    }

    // 加工時間
    str += '\n' + detailMenuTab[4];
    str += '\n' + '表面処理/cm2';
    //加工時間-面積マスタ
    detailsProcess?.areaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'cm2';
      });
    //加工時間-値
    if (detailsProcess?.workTimeThItems) {
      Object.entries(detailsProcess?.workTimeThItems)?.map(([key, data]) => {
        let groupNm = processMstRecord?.details?.processDetailTypes?.filter(
          (subitem) => subitem.id === parseInt(key) && subitem.isUsed === true
        )?.[0]?.name;
        if (groupNm && groupNm !== '') {
          str += '\n';
          str += groupNm ? groupNm : '';
          str += ',';
          data?.map((row, index) => {
            if (index > 0) str += ',';
            str += secondsToHms(row?.value);
          });
        }
      });
    }
    // 重量係数
    str += '\n' + detailMenuTab[5];
    str += '\n' + '重量';
    detailsProcess?.prodWeightCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'kg';
      });
    str += '\n' + detailMenuTab[5];
    detailsProcess?.prodWeightCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += toDecimal(row.value, 2);
      });
    // 生産個数係数
    str += '\n' + detailMenuTab[6];
    str += '\n' + '個数';
    detailsProcess?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + '個';
      });
    str += '\n' + detailMenuTab[6];
    detailsProcess?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += toDecimal(row.value, 2);
      });
    return str;
  };

  // 検査処理の情報をCSVファイル内容に変換する
  const getKensaCSVExportData = (str, detailMenuTab) => {
    // チャージ金額(/h)
    str += '\n' + detailMenuTab[0];
    str += '\n' + '段取,加工';
    str += '\n' + detailsProcess?.charges?.prep + ',' + detailsProcess?.charges?.work;

    // 基本段取時間
    str += '\n' + detailMenuTab[1];
    str += '\n' + '基本段取時間';
    str += '\n' + secondsToHms(detailsProcess?.prepBasicTime);
    if (workType === WorkType.SmInspection && types === ServiceClass.SheetMetal) {
      // 段取設定の子部品サイズ係数
      str += '\n' + detailMenuTab[2];
      str += '\n' + 'kg/cm2';
      //面積マスタ
      detailsProcess?.childPartsCoeffAreaThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item) => {
          str += ',';
          str += '～' + item.value + 'cm2';
        });

      //重量マスタ
      detailsProcess?.childPartsCoeffWeightThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item) => {
          str += '\n';
          str += item.value + 'kg';
          //係数値
          let areaCoeffLists = detailsProcess?.childPartsCoeff?.filter(
            (itemCoef) => !itemCoef.isOutOfRange && itemCoef.weightId === item.no
          )?.[0]?.areaCoeffList;
          areaCoeffLists?.map((row) => {
            str += ',';
            str += toDecimal(row.value, 2);
          });
        });

      // 段取設定の段取個数係数
      str += '\n' + detailMenuTab[3];
      str += '\n' + '個数';
      detailsProcess?.prepQtyCoeffThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item) => {
          str += ',';
          str += '～' + item.value + '個';
        });
      str += '\n' + detailMenuTab[3];
      detailsProcess?.prepQtyCoeffItems
        ?.filter((item) => !item.isOutOfRange)
        ?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });
    } else if (workType === WorkType.PaInspection && types === ServiceClass.Parent) {
      // 段取係数1
      str += '\n' + detailMenuTab[2];
      str += '\n' + '種類/個数';
      detailsProcess?.prepThQtyMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item) => {
          str += ',';
          str += '～' + item.value + '個';
        });
      str += '\n' + '子部品点数';
      detailsProcess?.prepCoeffThChildQtyItems
        ?.filter((item) => !item.isOutOfRange)
        ?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });

      // 段取係数2
      str += '\n' + detailMenuTab[3];
      str += '\n' + '重量';
      detailsProcess?.prepCoeffThWeightMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item) => {
          str += ',';
          str += '～' + item.value + 'kg';
        });
      str += '\n' + '重量係数';
      detailsProcess?.prepCoeffThWeightItems
        ?.filter((item) => !item.isOutOfRange)
        ?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });
    }

    // 加工時間
    str += '\n' + detailMenuTab[4];
    if (workType === WorkType.SmInspection && types === ServiceClass.SheetMetal) {
      str += '\n' + '1検査時間';
      str += '\n' + secondsToHms(detailsProcess?.inspectionWorkTime);
    } else if (workType === WorkType.PaInspection && types === ServiceClass.Parent) {
      str += '\n' + '1部品あたりの作業時間';
      str += '\n' + secondsToHms(detailsProcess?.workBasicTime);
    }

    // 作業時間設定の子部品サイズ係数
    if (workType === WorkType.SmInspection && types === ServiceClass.SheetMetal) {
      str += '\n' + detailMenuTab[5];
      str += '\n' + 'kg/cm2';
      //面積マスタ
      detailsProcess?.prodSizeAreaThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item) => {
          str += ',';
          str += '～' + item.value + 'cm2';
        });

      //重量マスタ
      detailsProcess?.prodSizeWeightThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item) => {
          str += '\n';
          str += item.value + 'kg';
          //係数値
          let areaCoeffLists = detailsProcess?.prodSizeItems?.filter(
            (itemCoef) => !itemCoef.isOutOfRange && itemCoef.weightId === item.no
          )?.[0]?.areaCoeffList;
          areaCoeffLists?.map((row) => {
            str += ',';
            str += toDecimal(row.value, 2);
          });
        });
    } else if (workType === WorkType.PaInspection && types === ServiceClass.Parent) {
      // 重量係数
      str += '\n' + detailMenuTab[5];
      str += '\n' + '重量';
      detailsProcess?.prodWeightCoeffThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item) => {
          str += ',';
          str += '～' + item.value + 'kg';
        });
      str += '\n' + detailMenuTab[5];
      detailsProcess?.prodWeightCoeffThItems
        ?.filter((item) => !item.isOutOfRange)
        ?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });
    }

    // 生産個数係数
    str += '\n' + detailMenuTab[6];
    str += '\n' + '個数';
    detailsProcess?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + '個';
      });
    str += '\n' + detailMenuTab[6];
    detailsProcess?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += toDecimal(row.value, 2);
      });
    return str;
  };

  // 追加工程処理の情報をCSVファイル内容に変換する
  const getCustomCSVExportData = (str, detailMenuTab) => {
    // チャージ金額(/h)
    str += '\n' + detailMenuTab[0];
    str += '\n' + '段取,加工';
    str += '\n' + detailsProcess?.charges?.prep + ',' + detailsProcess?.charges?.work;

    // 基本段取時間
    str += '\n' + detailMenuTab[1];
    str += '\n' + '基本段取時間';
    str += '\n' + secondsToHms(detailsProcess?.prepBasicTime);

    if (workType === WorkType.SmCustom && types === ServiceClass.SheetMetal) {
      // 段取設定の子部品サイズ係数
      str += '\n' + detailMenuTab[2];
      str += '\n' + 'kg/cm2';
      //面積マスタ
      detailsProcess?.childPartsCoeffAreaThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item) => {
          str += ',';
          str += '～' + item.value + 'cm2';
        });

      //重量マスタ
      detailsProcess?.childPartsCoeffWeightThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item) => {
          str += '\n';
          str += item.value + 'kg';
          //係数値
          let areaCoeffLists = detailsProcess?.childPartsCoeff?.filter(
            (itemCoef) => !itemCoef.isOutOfRange && itemCoef.weightId === item.no
          )?.[0]?.areaCoeffList;
          areaCoeffLists?.map((row) => {
            str += ',';
            str += toDecimal(row.value, 2);
          });
        });

      // 段取設定の段取個数係数
      str += '\n' + detailMenuTab[3];
      str += '\n' + '個数';
      detailsProcess?.prepQtyCoeffThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item) => {
          str += ',';
          str += '～' + item.value + '個';
        });
      str += '\n' + detailMenuTab[3];
      detailsProcess?.prepQtyCoeffItems
        ?.filter((item) => !item.isOutOfRange)
        ?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });
    } else if (workType === WorkType.PaCustom && types === ServiceClass.Parent) {
      // 段取係数1
      str += '\n' + detailMenuTab[2];
      str += '\n' + '種類/個数';
      detailsProcess?.prepThQtyMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item) => {
          str += ',';
          str += '～' + item.value + '個';
        });
      str += '\n' + '子部品点数';
      detailsProcess?.prepCoeffThChildQtyItems
        ?.filter((item) => !item.isOutOfRange)
        ?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });

      // 段取係数2
      str += '\n' + detailMenuTab[3];
      str += '\n' + '重量';
      detailsProcess?.prepCoeffThWeightMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item) => {
          str += ',';
          str += '～' + item.value + 'kg';
        });
      str += '\n' + '重量係数';
      detailsProcess?.prepCoeffThWeightItems
        ?.filter((item) => !item.isOutOfRange)
        ?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });
    }

    // 加工時間
    str += '\n' + detailMenuTab[4];
    str += '\n' + '1箇所あたりの作業時間';
    str += '\n' + secondsToHms(detailsProcess?.workBasicTime);

    if (workType === WorkType.SmCustom && types === ServiceClass.SheetMetal) {
      // 作業時間設定の子部品サイズ係数
      str += '\n' + detailMenuTab[5];
      str += '\n' + 'kg/cm2';
      //面積マスタ
      detailsProcess?.prodSizeAreaThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item) => {
          str += ',';
          str += '～' + item.value + 'cm2';
        });

      //重量マスタ
      detailsProcess?.prodSizeWeightThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item) => {
          str += '\n';
          str += item.value + 'kg';
          //係数値
          let areaCoeffLists = detailsProcess?.prodSizeItems?.filter(
            (itemCoef) => !itemCoef.isOutOfRange && itemCoef.weightId === item.no
          )?.[0]?.areaCoeffList;
          areaCoeffLists?.map((row) => {
            str += ',';
            str += toDecimal(row.value, 2);
          });
        });
    } else if (workType === WorkType.PaCustom && types === ServiceClass.Parent) {
      // 重量係数
      str += '\n' + detailMenuTab[5];
      str += '\n' + '重量';
      detailsProcess?.prodWeightCoeffThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item) => {
          str += ',';
          str += '～' + item.value + 'kg';
        });
      str += '\n' + detailMenuTab[5];
      detailsProcess?.prodWeightCoeffThItems
        ?.filter((item) => !item.isOutOfRange)
        ?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });
    }

    // 生産個数係数
    str += '\n' + detailMenuTab[6];
    str += '\n' + '個数';
    detailsProcess?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + '個';
      });
    str += '\n' + detailMenuTab[6];
    detailsProcess?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += toDecimal(row.value, 2);
      });
    // 委託装置詳細
    const customers = props?.getCustomers?.data?.filter((item) => item.id === detailsProcess?.clientId);
    let code = customers?.[0]?.code ? customers?.[0]?.code : '';
    let name = customers?.[0]?.name ? customers?.[0]?.name : '';
    str += '\n' + detailMenuTab[7];
    str += '\n' + '委託工程,' + detailsProcess?.isCommission;
    str += '\n' + '取引先(委託先),' + name;
    str += '\n' + '取引先コード,' + code;
    str += '\n' + '手入力フラグ,' + detailsProcess?.isManualInput;
    str += '\n' + '初期金額(手入力時),' + detailsProcess?.initPrice;
    return str;
  };

  // 組立処理の情報をCSVファイル内容に変換する
  const getAssenblePackingCSVExportData = (str, detailMenuTab) => {
    // チャージ金額(/h)
    str += '\n' + detailMenuTab[0];
    str += '\n' + '段取,加工';
    str += '\n' + detailsProcess?.charges?.prep + ',' + detailsProcess?.charges?.work;

    // 基本段取時間
    str += '\n' + detailMenuTab[1];
    str += '\n' + '基本段取時間';
    str += '\n' + secondsToHms(detailsProcess?.prepBasicTime);

    // 段取係数1
    str += '\n' + detailMenuTab[2];
    str += '\n' + '種類/個数';
    detailsProcess?.prepThQtyMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + '個';
      });
    str += '\n' + '子部品点数';
    detailsProcess?.prepCoeffThChildQtyItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += toDecimal(row.value, 2);
      });

    // 段取係数2
    str += '\n' + detailMenuTab[3];
    str += '\n' + '重量';
    detailsProcess?.prepCoeffThWeightMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'kg';
      });
    str += '\n' + '重量係数';
    detailsProcess?.prepCoeffThWeightItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += toDecimal(row.value, 2);
      });

    // 加工時間
    str += '\n' + detailMenuTab[4];

    str += '\n' + '1部品あたりの作業時間';
    str += '\n' + secondsToHms(detailsProcess?.workBasicTime);

    // 作業時間設定の重量係数
    str += '\n' + detailMenuTab[5];
    str += '\n' + '重量';
    detailsProcess?.prodWeightCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'kg';
      });
    str += '\n' + detailMenuTab[5];
    detailsProcess?.prodWeightCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += toDecimal(row.value, 2);
      });

    // 生産個数係数
    str += '\n' + detailMenuTab[6];
    str += '\n' + '個数';
    detailsProcess?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + '個';
      });
    str += '\n' + detailMenuTab[6];
    detailsProcess?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += toDecimal(row.value, 2);
      });
    return str;
  };

  // プログラムの情報をCSVファイル内容に変換する
  const getProgramCSVExportData = (str, detailMenuTab) => {
    // チャージ金額(/h)
    str += '\n' + detailMenuTab[0];
    str += '\n' + '段取,作業';
    str += '\n' + detailsProcess?.charges?.prep + ',' + detailsProcess?.charges?.work;
    // 段取設定
    str += '\n' + detailMenuTab[1];
    str += '\n' + '要素数';
    detailsProcess?.prepElementThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value;
      });
    // 初期段取時間
    str += '\n' + detailMenuTab[4];
    detailsProcess?.prepElementThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',' + secondsToHms(item.value);
      });
    // 加工設定
    str += '\n' + detailMenuTab[2];
    str += '\n' + '作業時間（x要素数）';
    str += '\n' + secondsToHms(detailsProcess?.workBasicTime);
    // 係数・その他
    str += '\n' + detailMenuTab[3];
    str += '\n' + '要素数';
    detailsProcess?.otherElementCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value;
      });
    // 作業時間係数
    str += '\n' + detailMenuTab[5];
    detailsProcess?.workTimeElementCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',' + item.value;
      });
    str += '\n' + '同一形状・サイズの上限';
    str += '\n' + detailsProcess?.shapeGrouping;
    return str;
  };

  // シャーリングの情報をCSVファイル内容に変換する
  const getShearingCSVExportData = (str, detailMenuTab) => {
    let weightMst = detailsProcess?.childPartsCoeffWeightThMaster?.filter((item) => !item.isOutOfRange);
    // チャージ金額(/h)
    str += '\n' + detailMenuTab[0];
    str += '\n' + '段取,加工';
    str += '\n' + detailsProcess?.charges?.prep + ',' + detailsProcess?.charges?.work;
    // 段取設定
    str += '\n' + detailMenuTab[1];
    str += '\n' + '基本段取時間';
    str += '\n' + secondsToHms(detailsProcess?.prepBasicTime);
    // 子部品サイズ係数
    str += '\n' + detailMenuTab[2];
    str += '\n' + 'kg / cm2';
    detailsProcess?.childPartsCoeffAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'cm2';
      });
    detailsProcess?.childPartsCoeff
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += '\n';
        str += weightMst?.find((weight) => weight.no === item.weightId)?.value + 'kg';
        item?.areaCoeffList
          ?.filter((item) => !item.isOutOfRange)
          ?.map((area) => {
            str += ',' + area.value;
          });
      });
    // 段取個数係数
    str += '\n' + detailMenuTab[3];
    str += '\n' + '個数';
    detailsProcess?.prepQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',' + '～' + item.value + '個';
      });
    str += '\n' + detailMenuTab[3];
    detailsProcess?.prepQtyCoeffItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',' + item.value;
      });
    // 作業・加工時間
    str += '\n' + detailMenuTab[4];
    str += '\n' + '1カット時間,One by one';
    str += '\n' + secondsToHms(detailsProcess?.cutTime) + ',' + detailsProcess?.isOneCut;
    // サイズ係数
    str += '\n' + detailMenuTab[5];
    str += '\n' + 'kg / cm2';
    detailsProcess?.prodSizeAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'cm2';
      });
    detailsProcess?.prodSizeItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += '\n';
        str += weightMst?.find((weight) => weight.no === item.weightId)?.value + 'kg';
        item?.areaCoeffList
          ?.filter((item) => !item.isOutOfRange)
          ?.map((area) => {
            str += ',' + area.value;
          });
      });
    // 生産個数係数
    str += '\n' + detailMenuTab[6];
    str += '\n' + '個数';
    detailsProcess?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',' + '～' + item.value + '個';
      });
    str += '\n' + detailMenuTab[6];
    detailsProcess?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',' + item.value;
      });
    return str;
  };

  // ベンディン処理の情報をCSVファイル内容に変換する
  const getBendingCSVExportData = (str, detailMenuTab) => {
    // チャージ金額(/h)
    str += '\n' + detailMenuTab[0];
    str += '\n' + '段取,加工';
    str += '\n' + detailsProcess?.charges?.prep + ',' + detailsProcess?.charges?.work;

    // 段取設定
    str += '\n' + detailMenuTab[1];
    str += '\n' + '基本段取時間';
    str += '\n' + '曲げ種類/板厚';
    //加工時間-板厚マスタ
    detailsProcess?.basicTimeThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 't';
      });
    Object.entries(detailsProcess?.basicTimeThickItems)
      ?.filter(([itemKey]) => getProcessDetailTypeName(parseInt(itemKey), processMstRecord) !== undefined)
      ?.map(([key, groupItem]) => {
        str += '\n';
        str += getProcessDetailTypeName(parseInt(key), processMstRecord);
        groupItem
          ?.filter((subitem) => !subitem.isOutOfRange)
          ?.map((col, index) => {
            str += ',';
            str += secondsToHms(col.value);
          });
      });

    // プログラム作成時間
    str += '\n' + detailMenuTab[2];
    str += '\n' + '本数';
    detailsProcess?.programCreateTimeCountfThMaster
      ?.filter((subitem) => !subitem.isOutOfRange)
      ?.map((item, index) => {
        str += ',';
        str += item.value + '本';
      });
    str += '\n' + 'プログラム作成時間';
    detailsProcess?.programCreateTimeItems
      ?.filter((subitem) => !subitem.isOutOfRange)
      ?.map((item, index) => {
        str += ',';
        str += item.value ? secondsToHms(item.value) : secondsToHms(0);
      });

    // 曲長段取時間
    str += '\n' + detailMenuTab[3];
    str += '\n' + 'mm(曲長)';
    detailsProcess?.prepBendTimeLengthMaster
      ?.filter((subitem) => !subitem.isOutOfRange)
      ?.map((item, index) => {
        str += ',';
        str += '～' + item.value + 'mm';
      });
    str += '\n' + '曲長段取時間';
    detailsProcess?.prepBendTimeItems
      ?.filter((subitem) => !subitem.isOutOfRange)
      ?.map((item, index) => {
        str += ',';
        str += item.value ? secondsToHms(item.value) : secondsToHms(0);
      });

    // 段取設定の子部品サイズ係数
    str += '\n' + detailMenuTab[4];
    str += '\n' + 'kg/cm2';
    //面積マスタ
    detailsProcess?.childPartsCoeffAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'cm2';
      });

    //重量マスタ
    detailsProcess?.childPartsCoeffWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += '\n';
        str += item.value + 'kg';
        //係数値
        let areaCoeffLists = detailsProcess?.childPartsCoeff?.filter(
          (itemCoef) => !itemCoef.isOutOfRange && itemCoef.weightId === item.no
        )?.[0]?.areaCoeffList;
        areaCoeffLists?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });
      });

    // 段取設定の段取個数係数
    str += '\n' + detailMenuTab[5];
    str += '\n' + '個数';
    detailsProcess?.prepQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + '個';
      });
    str += '\n' + detailMenuTab[5];
    detailsProcess?.prepQtyCoeffItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += toDecimal(row.value, 2);
      });

    // 加工時間
    str += '\n' + detailMenuTab[6];
    str += '\n' + '曲げ種類/板厚';
    //加工時間-板厚マスタ
    detailsProcess?.workTimeThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 't';
      });
    //加工時間-値
    if (detailsProcess?.workTimeThickItems) {
      Object.entries(detailsProcess?.workTimeThickItems)
        ?.filter(([itemKey]) => getProcessDetailTypeName(parseInt(itemKey), processMstRecord) !== undefined)
        ?.map(([key, groupItem]) => {
          str += '\n';
          str += getProcessDetailTypeName(parseInt(key), processMstRecord);
          groupItem
            ?.filter((subitem) => !subitem.isOutOfRange)
            ?.map((col, index) => {
              str += ',';
              str += secondsToHms(col.value);
            });
        });
    }

    // 曲げ長さ係数
    str += '\n' + detailMenuTab[7];
    str += '\n' + 'kg/mm';
    //面積マスタ
    detailsProcess?.bendTimeCoeffLenThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'mm';
      });

    //重量マスタ
    detailsProcess?.bendTimeCoeffWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += '\n';
        str += item.value + 'kg';
        //係数値
        if (detailsProcess?.bendTimeCoeffItems) {
          let bendTimeCoeffItems = Object.entries(detailsProcess?.bendTimeCoeffItems)?.filter(
            ([key, data]) => parseInt(key) === item?.no && !data.isOutOfRange
          )?.[0];
          let bendTimeCoeffs = [];
          bendTimeCoeffItems?.map((item, index) => {
            if (index > 0) {
              bendTimeCoeffs = item;
            }
          });
          bendTimeCoeffs?.map((coef) => {
            if (!coef?.isOutOfRange) {
              str += ',';
              str += toDecimal(coef?.value, 2);
            }
          });
        }
      });

    // 加工補正時間
    str += '\n' + detailMenuTab[8];
    str += '\n' + '加工箇所数';
    detailsProcess?.modifyTimeQtyThMaster
      ?.filter((subitem) => !subitem.isOutOfRange)
      ?.map((item, index) => {
        str += ',';
        str += item.value + '本';
      });
    str += '\n' + '補正時間';
    detailsProcess?.modifyTimeItems
      ?.filter((subitem) => !subitem.isOutOfRange)
      ?.map((item, index) => {
        str += ',';
        str += item.value ? secondsToHms(item.value) : secondsToHms(0);
      });

    // 加工補正時間係数
    str += '\n' + detailMenuTab[9];
    str += '\n' + 'kg/mm';
    //面積マスタ
    detailsProcess?.modifyCoeffLenThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'mm';
      });

    //重量マスタ
    detailsProcess?.modifyCoeffWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += '\n';
        str += item.value + 'kg';
        //係数値
        let areaCoeffLists = detailsProcess?.modifyCoeffItems?.filter(
          (itemCoef) => !itemCoef.isOutOfRange && itemCoef.weightId === item.no
        )?.[0]?.lengthCoeffList;
        areaCoeffLists?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });
      });

    // サイズ係数
    str += '\n' + detailMenuTab[10];
    str += '\n' + 'kg/cm2';
    //面積マスタ
    detailsProcess?.prodSizeAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'cm2';
      });

    //重量マスタ
    detailsProcess?.prodSizeWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += '\n';
        str += item.value + 'kg';
        //係数値
        let areaCoeffLists = detailsProcess?.prodSizeItems?.filter(
          (itemCoef) => !itemCoef.isOutOfRange && itemCoef.weightId === item.no
        )?.[0]?.areaCoeffList;
        areaCoeffLists?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });
      });
    // 生産個数係数
    str += '\n' + detailMenuTab[11];
    str += '\n' + '個数';
    detailsProcess?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + '個';
      });
    str += '\n' + detailMenuTab[6];
    detailsProcess?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += toDecimal(row.value, 2);
      });
    return str;
  };

  // 自動バリ処理の情報をCSVファイル内容に変換する
  const getAutoDeburCSVExportData = (str, detailMenuTab) => {
    // チャージ金額(/h)
    str += '\n' + detailMenuTab[0];
    str += '\n' + '段取,加工';
    str += '\n' + detailsProcess?.charges?.prep + ',' + detailsProcess?.charges?.work;

    // 段取設定
    str += '\n' + detailMenuTab[1];
    str += '\n' + '基本段取時間';
    str += '\n' + secondsToHms(detailsProcess?.prepBasicTime);

    // 段取設定の子部品サイズ係数
    str += '\n' + detailMenuTab[2];
    str += '\n' + 'kg/cm2';
    //面積マスタ
    detailsProcess?.childPartsCoeffAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'cm2';
      });

    //重量マスタ
    detailsProcess?.childPartsCoeffWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += '\n';
        str += item.value + 'kg';
        //係数値
        let areaCoeffLists = detailsProcess?.childPartsCoeff?.filter(
          (itemCoef) => !itemCoef.isOutOfRange && itemCoef.weightId === item.no
        )?.[0]?.areaCoeffList;
        areaCoeffLists?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });
      });

    // 段取設定の段取個数係数
    str += '\n' + detailMenuTab[3];
    str += '\n' + '個数';
    detailsProcess?.prepQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + '個';
      });
    str += '\n' + detailMenuTab[3];
    detailsProcess?.prepQtyCoeffItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += toDecimal(row.value, 2);
      });

    // 加工時間
    str += '\n' + detailMenuTab[4];
    str += '\n' + '加工時間/cm2';
    //加工時間-板厚マスタ
    detailsProcess?.areaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'cm2';
      });
    //加工時間-値
    str += '\n' + '加工時間';
    detailsProcess?.workTimeThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += secondsToHms(item.value);
      });

    // サイズ係数
    str += '\n' + detailMenuTab[5];
    str += '\n' + 'kg/cm2';
    //面積マスタ
    detailsProcess?.prodSizeAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'cm2';
      });

    //重量マスタ
    detailsProcess?.prodSizeWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += '\n';
        str += item.value + 'kg';
        //係数値
        let areaCoeffLists = detailsProcess?.prodSizeItems?.filter(
          (itemCoef) => !itemCoef.isOutOfRange && itemCoef.weightId === item.no
        )?.[0]?.areaCoeffList;
        areaCoeffLists?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });
      });
    // 生産個数係数
    str += '\n' + detailMenuTab[6];
    str += '\n' + '個数';
    detailsProcess?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + '個';
      });
    str += '\n' + detailMenuTab[6];
    detailsProcess?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += toDecimal(row.value, 2);
      });
    return str;
  };

  // 手動バリ処理の情報をCSVファイル内容に変換する
  const getManualDeburCSVExportData = (str, detailMenuTab) => {
    // チャージ金額(/h)
    str += '\n' + detailMenuTab[0];
    str += '\n' + '段取,加工';
    str += '\n' + detailsProcess?.charges?.prep + ',' + detailsProcess?.charges?.work;

    // 段取設定
    str += '\n' + detailMenuTab[1];
    str += '\n' + '基本段取時間';
    str += '\n' + secondsToHms(detailsProcess?.prepBasicTime);

    // 段取設定の子部品サイズ係数
    str += '\n' + detailMenuTab[2];
    str += '\n' + 'kg/cm2';
    //面積マスタ
    detailsProcess?.childPartsCoeffAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'cm2';
      });

    //重量マスタ
    detailsProcess?.childPartsCoeffWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += '\n';
        str += item.value + 'kg';
        //係数値
        let areaCoeffLists = detailsProcess?.childPartsCoeff?.filter(
          (itemCoef) => !itemCoef.isOutOfRange && itemCoef.weightId === item.no
        )?.[0]?.areaCoeffList;
        areaCoeffLists?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });
      });

    // 段取設定の段取個数係数
    str += '\n' + detailMenuTab[3];
    str += '\n' + '個数';
    detailsProcess?.prepQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + '個';
      });
    str += '\n' + detailMenuTab[3];
    detailsProcess?.prepQtyCoeffItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += toDecimal(row.value, 2);
      });

    // 加工速度(mm/min)
    str += '\n' + detailMenuTab[4];
    str += '\n' + 'mm/板厚';
    //板厚マスタ
    detailsProcess?.workSpeedThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 't';
      });

    //重量マスタ
    detailsProcess?.workSpeedLenThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += '\n';
        str += item.value + 'mm';
        //係数値
        let areaCoeffLists = detailsProcess?.workSpeedItems?.filter(
          (itemCoef) => !itemCoef.isOutOfRange && itemCoef.lengthId === item.no
        )?.[0]?.itaatsuCoeffList;
        areaCoeffLists?.map((row) => {
          str += ',';
          str += Number(row.value);
        });
      });

    // 加工速度係数
    str += '\n' + detailMenuTab[5];
    str += '\n' + '加工種別/加工長';
    //加工長マスタ
    detailsProcess?.workSpeedCoeffLengthThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'mm';
      });
    //内加工
    str += '\n' + '内加工';
    detailsProcess?.workSpeedCoeffInternalWorkItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += item.value;
      });
    //外加工
    str += '\n' + '外加工';
    detailsProcess?.workSpeedCoeffExternalWorkItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += item.value;
      });

    // 加工補正時間
    str += '\n' + detailMenuTab[6];
    str += '\n' + '加工箇所数';
    //加工補正時間
    detailsProcess?.modifyTimeQtyThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value;
      });
    //加工時間-値
    str += '\n' + '補正時間';
    detailsProcess?.modifyTimeItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += secondsToHms(item.value);
      });

    // 加工補正時間係数
    str += '\n' + detailMenuTab[7];
    str += '\n' + 'kg/cm2';
    //面積マスタ
    detailsProcess?.mtAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'cm2';
      });

    //重量マスタ
    detailsProcess?.mtWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += '\n';
        str += item.value + 'kg';
        //係数値
        let areaCoeffLists = detailsProcess?.mtTimeCoeffItems?.filter(
          (itemCoef) => !itemCoef.isOutOfRange && itemCoef.weightId === item.no
        )?.[0]?.areaCoeffList;
        areaCoeffLists?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });
      });

    // 手動バリ取対象サイズ
    str += '\n' + detailMenuTab[8];
    str += '\n' + '内径最小丸穴径(mm)' + ',' + '内径最小矩形周長(mm)';
    str += '\n' + detailsProcess?.roundHoleDia + ',' + detailsProcess?.rectangleDia;

    // サイズ係数
    str += '\n' + detailMenuTab[9];
    str += '\n' + 'kg/cm2';
    //面積マスタ
    detailsProcess?.prodSizeAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'cm2';
      });

    //重量マスタ
    detailsProcess?.prodSizeWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += '\n';
        str += item.value + 'kg';
        //係数値
        let areaCoeffLists = detailsProcess?.prodSizeItems?.filter(
          (itemCoef) => !itemCoef.isOutOfRange && itemCoef.weightId === item.no
        )?.[0]?.areaCoeffList;
        areaCoeffLists?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });
      });
    // 生産個数係数
    str += '\n' + detailMenuTab[10];
    str += '\n' + '個数';
    detailsProcess?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + '個';
      });
    str += '\n' + detailMenuTab[10];
    detailsProcess?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += toDecimal(row.value, 2);
      });
    return str;
  };

  // 溶接処理の情報をCSVファイル内容に変換する
  const getWeldCSVExportData = (str, detailMenuTab) => {
    // 工程種別の詳細情報を得る
    const groupDisplayList = getProcessDetailTypesInfo(detailsProcess?.charges, processMstRecord);
    // チャージ金額(/h)
    str += '\n' + detailMenuTab[0];
    Object.entries(groupDisplayList)?.map(([_, groupItem]) => {
      groupItem?.map((subItem) => {
        str += '\n';
        str += subItem?.name;
        getChargesDetailValues(subItem?.id, detailsProcess?.charges)?.map((item) => {
          if (parseInt(item) === subItem?.id) return;
          str += '\n';
          str += '段取,加工';
          str += '\n';
          str += item.prep + ',' + item.work;
        });
      });
    });
    // 段取設定
    str += '\n' + detailMenuTab[1];
    Object.entries(groupDisplayList)?.map(([_, groupItem]) => {
      groupItem?.map((subItem, index) => {
        str += '\n';
        str += subItem?.name;
        getPrepSettingDetailValues(subItem?.id, detailsProcess?.prepSecs)?.map((item, i) => {
          if (parseInt(item) === subItem?.id && i === 0) return;
          str += '\n';
          str += secondsToHms(item);
        });
      });
    });

    // 段取設定の子部品サイズ係数
    str += '\n' + detailMenuTab[2];
    str += '\n' + 'kg/cm2';
    //面積マスタ
    detailsProcess?.childPartsCoeffAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'cm2';
      });

    //重量マスタ
    detailsProcess?.childPartsCoeffWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += '\n';
        str += item.value + 'kg';
        //係数値
        let areaCoeffLists = detailsProcess?.childPartsCoeff?.filter(
          (itemCoef) => !itemCoef.isOutOfRange && itemCoef.weightId === item.no
        )?.[0]?.areaCoeffList;
        areaCoeffLists?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });
      });

    // 段取設定の段取個数係数
    str += '\n' + detailMenuTab[3];
    str += '\n' + '個数';
    detailsProcess?.prepQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + '個';
      });
    str += '\n' + detailMenuTab[3];
    detailsProcess?.prepQtyCoeffItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += toDecimal(row.value, 2);
      });

    // 加工速度(mm/min)
    str += '\n' + detailMenuTab[4];
    str += '\n' + '仕上種類/溶接線長';
    //溶接線長マスタ
    detailsProcess?.workSpeedThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'mm';
      });

    //加工速度
    if (detailsProcess?.workSpeedThItems) {
      const usedIds = processMstRecord?.details?.processDetailTypes
        ?.filter((item) => item.isUsed)
        ?.map((item) => item.id);
      Object.keys(detailsProcess?.workSpeedThItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          let typeNm = processTypesList?.find((item) => item.id.toString() === key)?.name;
          str += '\n';
          str += typeNm ? typeNm : '';
          detailsProcess?.workSpeedThItems[key]?.map((item, index) => {
            str += ',';
            str += Number(item.value);
          });
        });
    }

    // 仮付時間
    str += '\n' + detailMenuTab[5];
    str += '\n' + '溶接種類/板厚';
    //板厚マスタ
    detailsProcess?.tempInstThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 't';
      });
    //仮付時間
    if (detailsProcess?.tempInstThItems) {
      const usedIds = processMstRecord?.details?.processDetailTypes
        ?.filter((item) => item.isUsed)
        ?.map((item) => item.id);
      Object.keys(detailsProcess?.tempInstThItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          let typeNm = processTypesList?.find((item) => item.id.toString() === key)?.name;
          str += '\n';
          str += typeNm ? typeNm : '';
          detailsProcess?.tempInstThItems[key]?.map((item, index) => {
            str += ',';
            str += secondsToHms(item.value);
          });
        });
    }

    // 取付時間(板厚判断)
    str += '\n' + detailMenuTab[6];
    str += '\n' + '取付種類/板厚';
    //板厚マスタ
    detailsProcess?.instThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 't';
      });
    //取付時間
    if (detailsProcess?.instThickThItems) {
      const usedIds = processMstRecord?.details?.processDetailTypes
        ?.filter((item) => item.isUsed)
        ?.map((item) => item.id);
      Object.keys(detailsProcess?.instThickThItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          let typeNm = processTypesList?.find((item) => item.id.toString() === key)?.name;
          str += '\n';
          str += typeNm ? typeNm : '';
          detailsProcess?.instThickThItems[key]?.map((item, index) => {
            str += ',';
            str += secondsToHms(item.value);
          });
        });
    }

    // 取付時間(サイズ判断)
    str += '\n' + detailMenuTab[7];
    str += '\n' + '取付種類/サイズ';
    //サイズマスタ
    detailsProcess?.instSizeThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～M' + item.value;
      });
    //取付時間
    if (detailsProcess?.instSizeThItems) {
      const usedIds = processMstRecord?.details?.processDetailTypes
        ?.filter((item) => item.isUsed)
        ?.map((item) => item.id);
      Object.keys(detailsProcess?.instSizeThItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          let typeNm = processTypesList?.find((item) => item.id.toString() === key)?.name;
          str += '\n';
          str += typeNm ? typeNm : '';
          detailsProcess?.instSizeThItems[key]?.map((item, index) => {
            str += ',';
            str += secondsToHms(item.value);
          });
        });
    }

    // 溶接難易度
    str += '\n' + detailMenuTab[8];
    str += '\n' + '材質区分/板厚';
    //板厚マスタ
    detailsProcess?.weldDifficultyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 't';
      });
    //溶接難易度
    if (detailsProcess?.weldDifficultyCoeffThItems) {
      const usedIds = processMstRecord?.details?.processDetailTypes
        ?.filter((item) => item.isUsed)
        ?.map((item) => item.id);
      Object.keys(detailsProcess?.weldDifficultyCoeffThItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          str += '\n';
          str += typeNm ? typeNm : '';
          detailsProcess?.weldDifficultyCoeffThItems[key]?.map((item, index) => {
            str += ',';
            str += Number(item.value);
          });
        });
    }

    // サイズ係数
    str += '\n' + detailMenuTab[9];
    str += '\n' + 'kg/cm2';
    //面積マスタ
    detailsProcess?.prodSizeAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'cm2';
      });

    //重量マスタ
    detailsProcess?.prodSizeWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += '\n';
        str += item.value + 'kg';
        //係数値
        let areaCoeffLists = detailsProcess?.prodSizeItems?.filter(
          (itemCoef) => !itemCoef.isOutOfRange && itemCoef.weightId === item.no
        )?.[0]?.areaCoeffList;
        areaCoeffLists?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });
      });
    // 生産個数係数
    str += '\n' + detailMenuTab[10];
    str += '\n' + '個数';
    detailsProcess?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + '個';
      });
    str += '\n' + detailMenuTab[10];
    detailsProcess?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += toDecimal(row.value, 2);
      });
    return str;
  };

  // 溶接仕上処理の情報をCSVファイル内容に変換する
  const getWeldFinishCSVExportData = (str, detailMenuTab) => {
    // 工程種別の詳細情報を得る
    const groupDisplayList = getProcessDetailTypesInfo(detailsProcess?.charges, processMstRecord);
    // チャージ金額(/h)
    str += '\n' + detailMenuTab[0];
    Object.entries(groupDisplayList)?.map(([_, groupItem]) => {
      groupItem?.map((subItem) => {
        str += '\n';
        str += subItem?.name;
        getChargesDetailValues(subItem?.id, detailsProcess?.charges)?.map((item) => {
          if (parseInt(item) === subItem?.id) return;
          str += '\n';
          str += '段取,加工';
          str += '\n';
          str += item.prep + ',' + item.work;
        });
      });
    });
    // 段取設定
    str += '\n' + detailMenuTab[1];
    Object.entries(groupDisplayList)?.map(([_, groupItem]) => {
      groupItem?.map((subItem, index) => {
        str += '\n';
        str += subItem?.name;
        getPrepSettingDetailValues(subItem?.id, detailsProcess?.prepSecs)?.map((item, i) => {
          if (parseInt(item) === subItem?.id && i === 0) return;
          str += '\n';
          str += secondsToHms(item);
        });
      });
    });

    // 段取設定の子部品サイズ係数
    str += '\n' + detailMenuTab[2];
    str += '\n' + 'kg/cm2';
    //面積マスタ
    detailsProcess?.childPartsCoeffAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'cm2';
      });

    //重量マスタ
    detailsProcess?.childPartsCoeffWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += '\n';
        str += item.value + 'kg';
        //係数値
        let areaCoeffLists = detailsProcess?.childPartsCoeff?.filter(
          (itemCoef) => !itemCoef.isOutOfRange && itemCoef.weightId === item.no
        )?.[0]?.areaCoeffList;
        areaCoeffLists?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });
      });

    // 段取設定の段取個数係数
    str += '\n' + detailMenuTab[3];
    str += '\n' + '個数';
    detailsProcess?.prepQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + '個';
      });
    str += '\n' + detailMenuTab[3];
    detailsProcess?.prepQtyCoeffItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += toDecimal(row.value, 2);
      });

    // 加工速度(mm/min)
    str += '\n' + detailMenuTab[4];
    str += '\n' + '仕上種類/溶接線長';
    //溶接線長マスタ
    detailsProcess?.workSpeedThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'mm';
      });

    //加工速度
    if (detailsProcess?.workSpeedThItems) {
      const usedIds = processMstRecord?.details?.processDetailTypes
        ?.filter((item) => item.isUsed)
        ?.map((item) => item.id);
      Object.keys(detailsProcess?.workSpeedThItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          let typeNm = processTypesList?.find((item) => item.id.toString() === key)?.name;
          str += '\n';
          str += typeNm ? typeNm : '';
          detailsProcess?.workSpeedThItems[key]?.map((item, index) => {
            str += ',';
            str += Number(item.value);
          });
        });
    }

    // 溶接難易度
    str += '\n' + detailMenuTab[5];
    str += '\n' + '材質区分';
    materialTypes?.map((item, index) => {
      str += ',';
      str += item.name;
    });
    //溶接難易度
    str += '\n' + '難易度係数';
    if (detailsProcess?.diffFactorCoeff) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(detailsProcess?.diffFactorCoeff)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          str += ',';
          str += Number(detailsProcess?.diffFactorCoeff[key]);
        });
    }

    // // 仕上有無 IQX_WEBEST-271 パラメータに現状持っている「仕上げ有無」（溶接種類ごとのチェックボックス）を取り除く。
    // str += '\n' + detailMenuTab[6];
    // str += '\n' + '溶接種類';
    // yousetsuTypes?.details.processDetailTypes
    //   ?.filter((item) => item.group === 10710 && item.isUsed === true)
    //   ?.map((item, index) => {
    //     str += ',';
    //     str += item?.name;
    //   });
    // str += '\n' + '難易度係数';
    // if (detailsProcess?.difficultyCoeff) {
    //   const usedIds = yousetsuTypes?.details?.processDetailTypes?.filter((item) => item.isUsed)?.map((item) => item.id);
    //   Object.keys(detailsProcess?.difficultyCoeff)
    //     ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
    //     ?.map((key) => {
    //       str += ',';
    //       str += detailsProcess?.difficultyCoeff[key];
    //     });
    // }

    // サイズ係数
    str += '\n' + detailMenuTab[6];
    str += '\n' + 'kg/cm2';
    //面積マスタ
    detailsProcess?.prodSizeAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'cm2';
      });

    //重量マスタ
    detailsProcess?.prodSizeWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += '\n';
        str += item.value + 'kg';
        //係数値
        let areaCoeffLists = detailsProcess?.prodSizeItems?.filter(
          (itemCoef) => !itemCoef.isOutOfRange && itemCoef.weightId === item.no
        )?.[0]?.areaCoeffList;
        areaCoeffLists?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });
      });
    // 生産個数係数
    str += '\n' + detailMenuTab[7];
    str += '\n' + '個数';
    detailsProcess?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + '個';
      });
    str += '\n' + detailMenuTab[7];
    detailsProcess?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += toDecimal(row.value, 2);
      });
    return str;
  };

  // ２次加工処理の情報をCSVファイル内容に変換する
  const getSecondaryWorkCSVExportData = (str, detailMenuTab) => {
    // 工程種別の詳細情報を得る
    const groupDisplayList = getProcessDetailTypesInfo(detailsProcess?.charges, processMstRecord);
    // チャージ金額(/h)
    str += '\n' + detailMenuTab[0];
    Object.entries(groupDisplayList)?.map(([_, groupItem]) => {
      groupItem?.map((subItem) => {
        str += '\n';
        str += subItem?.name;
        getChargesDetailValues(subItem?.id, detailsProcess?.charges)?.map((item) => {
          if (parseInt(item) === subItem?.id) return;
          str += '\n';
          str += '段取,加工';
          str += '\n';
          str += item.prep + ',' + item.work;
        });
      });
    });
    // 段取設定
    str += '\n' + detailMenuTab[1];
    Object.entries(groupDisplayList)?.map(([_, groupItem]) => {
      groupItem?.map((subItem, index) => {
        str += '\n';
        str += subItem?.name;
        getPrepSettingDetailValues(subItem?.id, detailsProcess?.prepSecs)?.map((item, i) => {
          if (parseInt(item) === subItem?.id && i === 0) return;
          str += '\n';
          str += secondsToHms(item);
        });
      });
    });

    // 段取設定の子部品サイズ係数
    str += '\n' + detailMenuTab[2];
    str += '\n' + 'kg/cm2';
    //面積マスタ
    detailsProcess?.childPartsCoeffAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'cm2';
      });

    //重量マスタ
    detailsProcess?.childPartsCoeffWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += '\n';
        str += item.value + 'kg';
        //係数値
        let areaCoeffLists = detailsProcess?.childPartsCoeff?.filter(
          (itemCoef) => !itemCoef.isOutOfRange && itemCoef.weightId === item.no
        )?.[0]?.areaCoeffList;
        areaCoeffLists?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });
      });

    // 段取設定の段取個数係数
    str += '\n' + detailMenuTab[3];
    str += '\n' + '個数';
    detailsProcess?.prepQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + '個';
      });
    str += '\n' + detailMenuTab[3];
    detailsProcess?.prepQtyCoeffItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += toDecimal(row.value, 2);
      });

    // 加工時間(mm/min)
    str += '\n' + detailMenuTab[4];
    str += '\n' + '簡易入力';
    str += '\n' + '加工種類/サイズ';
    //サイズ
    detailsProcess?.sizeThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～M' + item.value;
      });
    if (detailsProcess?.workTimeSizeThItems) {
      Object.keys(detailsProcess?.workTimeSizeThItems)
        .filter(([sizeDataKey]) => getProcessDetailTypeName(parseInt(sizeDataKey), processMstRecord) !== undefined)
        ?.map(([key, item], rowIndex) => {
          let typeNm = getProcessDetailTypeName(parseInt(key), processMstRecord);
          str += '\n';
          str += typeNm ? typeNm : '';
          detailsProcess?.workTimeSizeThItems?.[parseInt(key)]?.map((time, index) => {
            str += ',';
            str += secondsToHms(time.value);
          });
        });
    }

    str += '\n' + '詳細入力';
    str += '\n' + '加工種類/サイズ';
    detailsProcess?.sizeThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～M' + item.value;
      });
    if (detailsProcess?.workTimeSizeThItems) {
      Object.keys(detailsProcess?.workTimeSizeThItems)
        .filter(([sizeDataKey]) => getProcessDetailTypeName(parseInt(sizeDataKey), processMstRecord) !== undefined)
        ?.map(([key, item], rowIndex) => {
          let typeNm = getProcessDetailTypeName(parseInt(key), processMstRecord);
          if (rowIndex === 0) {
            str += '\n';
            str += typeNm ? typeNm : '';
            str += ',' + '材質区分/板厚';
            detailsProcess?.sizeThicknessThMaster
              ?.filter((thickTh) => !thickTh.isOutOfRange)
              ?.map((thickThItem) => {
                str += ',';
                str += '～' + thickThItem.value + 't';
              });
          }
          const usedItems = materialTypes?.filter((item) => item.info.isUsed);
          usedItems?.map((materialTypeItem) => {
            detailsProcess?.workTimeSizeThItems[key]?.map((item, workTimeSizeIndex) => {
              if (item?.workTimeThickThItems) {
                Object.keys(item?.workTimeThickThItems)
                  ?.filter((listkey) => parseInt(listkey) === materialTypeItem.id)
                  ?.map((key) => {
                    if (workTimeSizeIndex === 0) {
                      let materialTypeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
                      str += '\n';
                      str += typeNm ? typeNm : '';
                      str += ',';
                      str += materialTypeNm ? materialTypeNm : '';
                    }
                    item?.workTimeThickThItems[key]?.map((item, index) => {
                      str += ',';
                      str += secondsToHms(item.value);
                    });
                  });
              }
            });
          });
        });
    }

    str += '\n' + detailMenuTab[5];
    str += '\n' + '簡易入力';
    str += '\n' + '加工種類/mm';
    //mm
    detailsProcess?.mmThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'mm';
      });
    if (detailsProcess?.workTimeSizeHoleThItems) {
      Object.keys(detailsProcess?.workTimeSizeHoleThItems)
        .filter(([sizeDataKey]) => getProcessDetailTypeName(parseInt(sizeDataKey), processMstRecord) !== undefined)
        ?.map(([key, item], rowIndex) => {
          let typeNm = getProcessDetailTypeName(parseInt(key), processMstRecord);
          str += '\n';
          str += typeNm ? typeNm : '';
          detailsProcess?.workTimeSizeHoleThItems?.[parseInt(key)]?.map((time, index) => {
            str += ',';
            str += secondsToHms(time.value);
          });
        });
    }

    str += '\n' + '詳細入力';
    str += '\n' + '加工種類/mm';
    detailsProcess?.mmThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'mm';
      });
    if (detailsProcess?.workTimeSizeHoleThItems) {
      Object.keys(detailsProcess?.workTimeSizeHoleThItems)
        .filter(([sizeDataKey]) => getProcessDetailTypeName(parseInt(sizeDataKey), processMstRecord) !== undefined)
        ?.map(([key, item], rowIndex) => {
          let typeNm = getProcessDetailTypeName(parseInt(key), processMstRecord);
          if (rowIndex === 0) {
            str += '\n';
            str += typeNm ? typeNm : '';
            str += ',' + '材質区分/板厚';
            detailsProcess?.mmThicknessThMaster
              ?.filter((thickTh) => !thickTh.isOutOfRange)
              ?.map((thickThItem) => {
                str += ',';
                str += '～' + thickThItem.value + 't';
              });
          }
          const usedItems = materialTypes?.filter((item) => item.info.isUsed);
          usedItems?.map((materialTypeItem) => {
            detailsProcess?.workTimeSizeHoleThItems[key]?.map((item, workTimeSizeIndex) => {
              if (item?.workTimeThickThItems) {
                Object.keys(item?.workTimeThickThItems)
                  ?.filter((listkey) => parseInt(listkey) === materialTypeItem.id)
                  ?.map((key) => {
                    if (workTimeSizeIndex === 0) {
                      let materialTypeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
                      str += '\n';
                      str += typeNm ? typeNm : '';
                      str += ',';
                      str += materialTypeNm ? materialTypeNm : '';
                    }
                    item?.workTimeThickThItems[key]?.map((item, index) => {
                      str += ',';
                      str += secondsToHms(item.value);
                    });
                  });
              }
            });
          });
        });
    }

    // 加工補正時間
    str += '\n' + detailMenuTab[6];
    str += '\n' + '加工箇所数';
    //加工補正時間
    detailsProcess?.modifyTimeQtyThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value;
      });
    //加工時間-値
    str += '\n' + '補正時間';
    detailsProcess?.modifyTimeItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += secondsToHms(item.value);
      });

    // 加工補正時間係数
    str += '\n' + detailMenuTab[7];
    str += '\n' + 'kg/cm2';
    //面積マスタ
    detailsProcess?.mtAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'cm2';
      });

    //重量マスタ
    detailsProcess?.mtWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += '\n';
        str += item.value + 'kg';
        //係数値
        let areaCoeffLists = detailsProcess?.mtTimeCoeffItems?.filter(
          (itemCoef) => !itemCoef.isOutOfRange && itemCoef.weightId === item.no
        )?.[0]?.areaCoeffList;
        areaCoeffLists?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });
      });

    // サイズ係数
    str += '\n' + detailMenuTab[8];
    str += '\n' + 'kg/cm2';
    //面積マスタ
    detailsProcess?.prodSizeAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'cm2';
      });

    //重量マスタ
    detailsProcess?.prodSizeWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += '\n';
        str += item.value + 'kg';
        //係数値
        let areaCoeffLists = detailsProcess?.prodSizeItems?.filter(
          (itemCoef) => !itemCoef.isOutOfRange && itemCoef.weightId === item.no
        )?.[0]?.areaCoeffList;
        areaCoeffLists?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });
      });
    // 生産個数係数
    str += '\n' + detailMenuTab[9];
    str += '\n' + '個数';
    detailsProcess?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + '個';
      });
    str += '\n' + detailMenuTab[9];
    detailsProcess?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += toDecimal(row.value, 2);
      });
    return str;
  };

  // パンチ処理の情報をCSVファイル内容に変換する
  const getPunchCSVExportData = (str, detailMenuTab) => {
    // チャージ金額(/h)
    str += '\n' + detailMenuTab[0];
    str += '\n' + '段取,加工,2工程穴,ジョイントバラシ';
    str +=
      '\n' +
      detailsProcess?.charges?.prep +
      ',' +
      detailsProcess?.charges?.work +
      ',' +
      detailsProcess?.charges?.twoStepHolework +
      ',' +
      detailsProcess?.charges?.jointSeparate;

    // 段取設定
    str += '\n' + detailMenuTab[1];
    str += '\n' + '基本段取時間';
    str += '\n' + secondsToHms(detailsProcess?.prepBasicTime);

    // 段取設定の段取個数係数
    str += '\n' + detailMenuTab[2];
    str += '\n' + '個数';
    detailsProcess?.prepQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + '個';
      });
    str += '\n' + detailMenuTab[2];
    detailsProcess?.prepQtyCoeffItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += toDecimal(row.value, 2);
      });

    // 段取設定の金型段取時間
    str += '\n' + detailMenuTab[3];
    str += '\n' + '本数';
    detailsProcess?.prepMoldQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += item.value + '本';
      });
    str += '\n' + detailMenuTab[3];
    detailsProcess?.prepMoldQtyCoeffItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += secondsToHms(row.value);
      });

    // 材料配置
    str += '\n' + detailMenuTab[4];
    str += '\n' + '掴み代X,Y(mm)' + ',' + '桟幅X,Y(mm)' + ',' + '部品加工範囲X,Y(mm)' + '範囲超加算時間(sec)';
    str +=
      '\n' +
      detailsProcess?.materialArrange?.graspAllowanceX +
      ',' +
      detailsProcess?.materialArrange?.graspAllowanceY +
      ',' +
      detailsProcess?.materialArrange?.xWidth +
      ',' +
      detailsProcess?.materialArrange?.yWidth +
      ',' +
      detailsProcess?.materialArrange?.workRangeX +
      ',' +
      detailsProcess?.materialArrange?.workRangeY +
      ',' +
      detailsProcess?.materialArrange?.rangeAdditionalTime;

    // パンチ条件
    str += '\n' + detailMenuTab[5];
    str += '\n' + '1パンチ丸穴径' + ',' + '1パンチ矩形周長' + ',' + '二ブリングピッチ';
    str +=
      '\n' +
      detailsProcess?.punches?.punchHoleDia +
      ',' +
      detailsProcess?.punches?.punchRectanglePer +
      ',' +
      detailsProcess?.punches?.twoPitch;

    // 1パンチ加工時間
    str += '\n' + detailMenuTab[6];
    str += '\n' + '材質区分/板厚';
    //板厚マスタ
    detailsProcess?.punchWorkTimeThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 't';
      });

    // 1パンチ加工時間
    if (detailsProcess?.punchWorkTimeItems) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(detailsProcess?.punchWorkTimeItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          str += '\n';
          str += typeNm ? typeNm : '';
          detailsProcess?.punchWorkTimeItems[key]?.map((item, index) => {
            str += ',';
            str += Number(item.value);
          });
        });
    }

    // 送り速度(mm/min)
    str += '\n' + detailMenuTab[7];
    str += '\n' + '送り速度';
    str += '\n' + detailsProcess?.feedSpeed;

    //ローラー/ホイール/ケガキ金型　加工速度(mm/sec)
    str += '\n' + detailMenuTab[8];
    str += '\n' + '加工速度(mm/分)';
    str += '\n' + detailsProcess?.workSpeed;

    // 追抜き条件
    str += '\n' + detailMenuTab[9];
    str += '\n' + '辺長/保有金型';
    detailsProcess?.odOvertakeMoldMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',' + item.name;
      });
    str += '\n' + '加工辺長';
    detailsProcess?.odOvertakeItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',' + item.value;
      });

    // サイズ係数
    str += '\n' + detailMenuTab[10];
    str += '\n' + 'kg/cm2';
    //面積マスタ
    detailsProcess?.prodSizeAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'cm2';
      });

    //重量マスタ
    detailsProcess?.prodSizeWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += '\n';
        str += item.value + 'kg';
        //係数値
        let areaCoeffLists = detailsProcess?.prodSizeItems?.filter(
          (itemCoef) => !itemCoef.isOutOfRange && itemCoef.weightId === item.no
        )?.[0]?.areaCoeffList;
        areaCoeffLists?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });
      });
    // 生産個数係数
    str += '\n' + detailMenuTab[11];
    str += '\n' + '個数';
    detailsProcess?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + '個';
      });
    str += '\n' + detailMenuTab[11];
    detailsProcess?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += toDecimal(row.value, 2);
      });

    // 2工程穴加工時間
    str += '\n' + detailMenuTab[12];
    str += '\n' + detailMenuTab[12];
    str += '\n' + secondsToHms(detailsProcess?.twoStepHoleworkTime);

    // ジョイントバラシ
    str += '\n' + detailMenuTab[13];
    str += '\n' + '個数/cm2';
    detailsProcess?.jointQtySizeThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'cm2';
      });
    str += '\n' + 'ジョイント個数';
    detailsProcess?.jointQtyItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += Number(row.value);
      });
    str += '\n' + '材質区分/板厚';
    //板厚マスタ
    detailsProcess?.jointSeparateThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 't';
      });
    if (detailsProcess?.jointSeparateItems) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(detailsProcess?.jointSeparateItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          str += '\n';
          str += typeNm ? typeNm : '';
          detailsProcess?.jointSeparateItems[key]?.map((item, index) => {
            str += ',';
            str += Number(item.value);
          });
        });
    }

    return str;
  };

  // レーザー処理の情報をCSVファイル内容に変換する
  const getLaserCSVExportData = (str, detailMenuTab) => {
    // チャージ金額(/h)
    str += '\n' + detailMenuTab[0];
    str += '\n' + '段取,加工,2工程穴,ジョイントバラシ';
    str +=
      '\n' +
      detailsProcess?.charges?.prep +
      ',' +
      detailsProcess?.charges?.work +
      ',' +
      detailsProcess?.charges?.twoStepHolework +
      ',' +
      detailsProcess?.charges?.jointSeparate;

    // 段取設定
    str += '\n' + detailMenuTab[1];
    str += '\n' + '基本段取時間';
    str += '\n' + secondsToHms(detailsProcess?.prepBasicTime);

    // 段取設定の段取個数係数
    str += '\n' + detailMenuTab[2];
    str += '\n' + '個数';
    detailsProcess?.prepQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + '個';
      });
    str += '\n' + detailMenuTab[2];
    detailsProcess?.prepQtyCoeffItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += toDecimal(row.value, 2);
      });

    // 材料配置
    str += '\n' + detailMenuTab[3];
    str += '\n' + '掴み代X,Y(mm)' + ',' + '桟幅X,Y(mm)' + ',' + '部品加工範囲X,Y(mm)' + '範囲超加算時間(sec)';
    str +=
      '\n' +
      detailsProcess?.materialArrange?.graspAllowanceX + //IQX_WEBEST-298 パラメータのCSV出力時、レーザー工程と複合機の掴み代、桟幅の出力の修正
      ',' +
      detailsProcess?.materialArrange?.graspAllowanceY + //IQX_WEBEST-298 パラメータのCSV出力時、レーザー工程と複合機の掴み代、桟幅の出力の修正
      ',' +
      detailsProcess?.materialArrange?.xWidth + //IQX_WEBEST-298 パラメータのCSV出力時、レーザー工程と複合機の掴み代、桟幅の出力の修正
      ',' +
      detailsProcess?.materialArrange?.yWidth + //IQX_WEBEST-298 パラメータのCSV出力時、レーザー工程と複合機の掴み代、桟幅の出力の修正
      ',' +
      detailsProcess?.materialArrange?.workRangeX +
      ',' +
      detailsProcess?.materialArrange?.workRangeY +
      ',' +
      detailsProcess?.materialArrange?.rangeAdditionalTime;

    // 1ピアス加工時間
    str += '\n' + detailMenuTab[4];
    str += '\n' + '材質区分/板厚';
    //板厚マスタ
    detailsProcess?.piercingTimeThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 't';
      });

    // 1パンチ加工時間
    if (detailsProcess?.piercingTimeThItems) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(detailsProcess?.piercingTimeThItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          str += '\n';
          str += typeNm ? typeNm : '';
          detailsProcess?.piercingTimeThItems[key]?.map((item, index) => {
            str += ',';
            str += Number(item.value);
          });
        });
    }

    // 加工速度(mm/min)
    str += '\n' + detailMenuTab[5];
    str += '\n' + '簡易入力';
    str += '\n' + '材質区分/板厚';
    //板厚マスタ
    detailsProcess?.workSpeedThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 't';
      });
    if (detailsProcess?.workSpeedItems) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(detailsProcess?.workSpeedItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          str += '\n';
          str += typeNm ? typeNm : '';
          detailsProcess?.workSpeedItems[key]?.map((item, index) => {
            str += ',';
            str += Number(item.value);
          });
        });
    }

    str += '\n' + '詳細入力';
    str += '\n' + '材質区分/板厚';
    //板厚マスタ
    detailsProcess?.workSpeedThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 't';
      });
    if (detailsProcess?.workSpeedItems) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(detailsProcess?.workSpeedItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          str += '\n';
          str += typeNm ? typeNm : '';
          str += ',' + '丸穴径(Φ) (≦)';
          detailsProcess?.workSpeedItems[key]?.map((item, index) => {
            item?.roundHoleDiaThItems?.map((roundItem, index) => {
              str += ',' + roundItem.value;
            });
          });
          str += '\n';
          str += typeNm ? typeNm : '';
          str += ',' + '加工周長(mm)(≦)';
          detailsProcess?.workSpeedItems[key]?.map((item, index) => {
            item?.workLenThItems?.map((workLenItem, index) => {
              str += ',' + workLenItem.value;
            });
          });
          str += '\n';
          str += typeNm ? typeNm : '';
          str += ',' + '速度(mm/分)';
          detailsProcess?.workSpeedItems[key]?.map((item, index) => {
            item?.lenThItems?.map((lenItem, index) => {
              str += ',' + lenItem.value;
            });
          });
        });
    }

    str += '\n' + detailMenuTab[6];
    str += '\n' + detailsProcess?.scribingSpeed;
    str += ',' + detailsProcess?.feedSpeed;

    // 加工速度係数
    str += '\n' + detailMenuTab[7];
    str += '\n' + '加工種別/加工長';
    //加工長マスタ
    detailsProcess?.workSpeedCoeffLengthThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'mm';
      });
    //内加工
    str += '\n' + '内加工';
    detailsProcess?.workSpeedCoeffInternalWorkItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += item.value;
      });
    //外加工
    str += '\n' + '外加工';
    detailsProcess?.workSpeedCoeffExternalWorkItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += item.value;
      });
    // ガス係数
    str += '\n' + detailMenuTab[8];
    str += '\n' + '係数/種類';
    detailsProcess?.gasCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += item.name;
      });
    str += '\n' + detailMenuTab[8];
    detailsProcess?.gasCoeffListItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += toDecimal(item.value, 2);
      });

    str += '\n' + '材質区分/板厚';
    //板厚マスタ
    detailsProcess?.gasCoeffThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 't';
      });
    if (detailsProcess?.gasCoeffThItems) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(detailsProcess?.gasCoeffThItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          str += '\n';
          str += typeNm ? typeNm : '';
          detailsProcess?.gasCoeffThItems[key]?.map((item, index) => {
            str += ',';
            let gasTypeNm = detailsProcess?.gasCoeffThMaster?.filter(
              (itemCoef) => !itemCoef.isOutOfRange && itemCoef.no === parseInt(item.value)
            );
            str += gasTypeNm?.length > 0 ? gasTypeNm[0].name : '';
          });
        });
    }

    // サイズ係数
    str += '\n' + detailMenuTab[9];
    str += '\n' + 'kg/cm2';
    //面積マスタ
    detailsProcess?.prodSizeAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'cm2';
      });

    //重量マスタ
    detailsProcess?.prodSizeWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += '\n';
        str += item.value + 'kg';
        //係数値
        let areaCoeffLists = detailsProcess?.prodSizeItems?.filter(
          (itemCoef) => !itemCoef.isOutOfRange && itemCoef.weightId === item.no
        )?.[0]?.areaCoeffList;
        areaCoeffLists?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });
      });
    // 生産個数係数
    str += '\n' + detailMenuTab[10];
    str += '\n' + '個数';
    detailsProcess?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + '個';
      });
    str += '\n' + detailMenuTab[10];
    detailsProcess?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += toDecimal(row.value, 2);
      });

    // 2工程穴加工時間
    str += '\n' + detailMenuTab[11];
    str += '\n' + detailMenuTab[11];
    str += '\n' + secondsToHms(detailsProcess?.twoStepHoleworkTime);

    // ジョイントバラシ
    str += '\n' + detailMenuTab[12];
    str += '\n' + '個数/cm2';
    detailsProcess?.jointQtySizeThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'cm2';
      });
    str += '\n' + 'ジョイント個数';
    detailsProcess?.jointQtyItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += Number(row.value);
      });
    str += '\n' + '材質区分/板厚';
    //板厚マスタ
    detailsProcess?.jointSeparateThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 't';
      });
    if (detailsProcess?.jointSeparateItems) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(detailsProcess?.jointSeparateItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          str += '\n';
          str += typeNm ? typeNm : '';
          detailsProcess?.jointSeparateItems[key]?.map((item, index) => {
            str += ',';
            str += Number(item.value);
          });
        });
    }

    return str;
  };

  // 複合機処理の情報をCSVファイル内容に変換する
  const getCombiCSVExportData = (str, detailMenuTab) => {
    // チャージ金額(/h)
    str += '\n' + detailMenuTab[0];
    str += '\n' + '段取,パンチ加工、レーザー加工,2工程穴,ジョイントバラシ';
    str +=
      '\n' +
      detailsProcess?.charges?.prep +
      ',' +
      detailsProcess?.charges?.punchWork +
      ',' +
      detailsProcess?.charges?.laserWork +
      ',' +
      detailsProcess?.charges?.twoStepHolework +
      ',' +
      detailsProcess?.charges?.jointSeparate;

    // 段取設定
    str += '\n' + detailMenuTab[1];
    str += '\n' + '基本段取時間';
    str += '\n' + secondsToHms(detailsProcess?.prepBasicTime);

    // 段取設定の段取個数係数
    str += '\n' + detailMenuTab[2];
    str += '\n' + '個数';
    detailsProcess?.prepQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + '個';
      });
    str += '\n' + detailMenuTab[2];
    detailsProcess?.prepQtyCoeffItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += toDecimal(row.value, 2);
      });

    // 段取設定の金型段取時間
    str += '\n' + detailMenuTab[3];
    str += '\n' + '本数';
    detailsProcess?.prepMoldQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += item.value + '本';
      });
    str += '\n' + detailMenuTab[3];
    detailsProcess?.prepMoldQtyCoeffItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += secondsToHms(row.value);
      });

    // 材料配置
    str += '\n' + detailMenuTab[4];
    str += '\n' + '掴み代X,Y(mm)' + ',' + '桟幅X,Y(mm)' + ',' + '部品加工範囲X,Y(mm)' + '範囲超加算時間(sec)';
    str +=
      '\n' +
      detailsProcess?.materialArrange?.graspAllowanceX + //IQX_WEBEST-298 パラメータのCSV出力時、レーザー工程と複合機の掴み代、桟幅の出力の修正
      ',' +
      detailsProcess?.materialArrange?.graspAllowanceY + //IQX_WEBEST-298 パラメータのCSV出力時、レーザー工程と複合機の掴み代、桟幅の出力の修正
      ',' +
      detailsProcess?.materialArrange?.xWidth + //IQX_WEBEST-298 パラメータのCSV出力時、レーザー工程と複合機の掴み代、桟幅の出力の修正
      ',' +
      detailsProcess?.materialArrange?.yWidth + //IQX_WEBEST-298 パラメータのCSV出力時、レーザー工程と複合機の掴み代、桟幅の出力の修正
      ',' +
      detailsProcess?.materialArrange?.workRangeX +
      ',' +
      detailsProcess?.materialArrange?.workRangeY +
      ',' +
      detailsProcess?.materialArrange?.rangeAdditionalTime;

    // パンチ条件
    str += '\n' + detailMenuTab[5];
    str += '\n' + '材質区分/板厚';
    //板厚マスタ
    detailsProcess?.punchConditionThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 't';
      });

    // パンチ
    if (detailsProcess?.punchConditionItems) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(detailsProcess?.punchConditionItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          str += '\n';
          str += typeNm ? typeNm : '';
          detailsProcess?.punchConditionItems[key]?.map((item, index) => {
            str += ',';
            str += Number(item.value);
          });
        });
    }

    // 1パンチ加工時間
    str += '\n' + detailMenuTab[6];
    str += '\n' + '材質区分/板厚';
    //板厚マスタ
    detailsProcess?.punchWorkTimeThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 't';
      });

    // 1パンチ加工時間
    if (detailsProcess?.punchWorkTimeItems) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(detailsProcess?.punchWorkTimeItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          str += '\n';
          str += typeNm ? typeNm : '';
          detailsProcess?.punchWorkTimeItems[key]?.map((item, index) => {
            str += ',';
            str += Number(item.value);
          });
        });
    }

    // 1ピアス加工時間
    str += '\n' + detailMenuTab[7];
    str += '\n' + '材質区分/板厚';
    //板厚マスタ
    detailsProcess?.piercingTimeThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 't';
      });

    // 1パンチ加工時間
    if (detailsProcess?.piercingTimeThItems) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(detailsProcess?.piercingTimeThItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          str += '\n';
          str += typeNm ? typeNm : '';
          detailsProcess?.piercingTimeThItems[key]?.map((item, index) => {
            str += ',';
            str += Number(item.value);
          });
        });
    }

    // 加工速度(mm/min)
    str += '\n' + detailMenuTab[8];
    str += '\n' + '簡易入力';
    str += '\n' + '材質区分/板厚';
    //板厚マスタ
    detailsProcess?.workSpeedThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 't';
      });
    if (detailsProcess?.workSpeedItems) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(detailsProcess?.workSpeedItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          str += '\n';
          str += typeNm ? typeNm : '';
          detailsProcess?.workSpeedItems[key]?.map((item, index) => {
            str += ',';
            str += Number(item.value);
          });
        });
    }

    str += '\n' + '詳細入力';
    str += '\n' + '材質区分/板厚';
    //板厚マスタ
    detailsProcess?.workSpeedThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 't';
      });
    if (detailsProcess?.workSpeedItems) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(detailsProcess?.workSpeedItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          str += '\n';
          str += typeNm ? typeNm : '';
          str += ',' + '丸穴径(Φ) (≦)';
          detailsProcess?.workSpeedItems[key]?.map((item, index) => {
            item?.roundHoleDiaThItems?.map((roundItem, index) => {
              str += ',' + roundItem.value;
            });
          });
          str += '\n';
          str += typeNm ? typeNm : '';
          str += ',' + '加工周長(mm)(≦)';
          detailsProcess?.workSpeedItems[key]?.map((item, index) => {
            item?.workLenThItems?.map((workLenItem, index) => {
              str += ',' + workLenItem.value;
            });
          });
          str += '\n';
          str += typeNm ? typeNm : '';
          str += ',' + '速度(mm/分)';
          detailsProcess?.workSpeedItems[key]?.map((item, index) => {
            item?.lenThItems?.map((lenItem, index) => {
              str += ',' + lenItem.value;
            });
          });
        });
    }

    str += '\n' + detailMenuTab[9];
    str += '\n' + detailsProcess?.scribingSpeed;
    str += ',' + detailsProcess?.feedSpeed;

    // 加工速度係数
    str += '\n' + detailMenuTab[10];
    str += '\n' + '加工種別/加工長';
    //加工長マスタ
    detailsProcess?.workSpeedCoeffLengthThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'mm';
      });
    //内加工
    str += '\n' + '内加工';
    detailsProcess?.workSpeedCoeffInternalWorkItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += item.value;
      });
    //外加工
    str += '\n' + '外加工';
    detailsProcess?.workSpeedCoeffExternalWorkItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += item.value;
      });
    // ガス係数
    str += '\n' + detailMenuTab[11];
    str += '\n' + '係数/種類';
    detailsProcess?.gasCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += item.name;
      });
    str += '\n' + detailMenuTab[11];
    detailsProcess?.gasCoeffListItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += toDecimal(item.value, 2);
      });

    str += '\n' + '材質区分/板厚';
    //板厚マスタ
    detailsProcess?.gasCoeffThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 't';
      });
    if (detailsProcess?.gasCoeffThItems) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(detailsProcess?.gasCoeffThItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          str += '\n';
          str += typeNm ? typeNm : '';
          detailsProcess?.gasCoeffThItems[key]?.map((item, index) => {
            str += ',';
            let gasTypeNm = detailsProcess?.gasCoeffThMaster?.filter(
              (itemCoef) => !itemCoef.isOutOfRange && itemCoef.no === parseInt(item.value)
            );
            str += gasTypeNm?.length > 0 ? gasTypeNm[0].name : '';
          });
        });
    }

    // サイズ係数
    str += '\n' + detailMenuTab[12];
    str += '\n' + 'kg/cm2';
    //面積マスタ
    detailsProcess?.prodSizeAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'cm2';
      });

    //重量マスタ
    detailsProcess?.prodSizeWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += '\n';
        str += item.value + 'kg';
        //係数値
        let areaCoeffLists = detailsProcess?.prodSizeItems?.filter(
          (itemCoef) => !itemCoef.isOutOfRange && itemCoef.weightId === item.no
        )?.[0]?.areaCoeffList;
        areaCoeffLists?.map((row) => {
          str += ',';
          str += toDecimal(row.value, 2);
        });
      });
    // 生産個数係数
    str += '\n' + detailMenuTab[13];
    str += '\n' + '個数';
    detailsProcess?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + '個';
      });
    str += '\n' + detailMenuTab[13];
    detailsProcess?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += toDecimal(row.value, 2);
      });

    // 2工程穴加工時間
    str += '\n' + detailMenuTab[14];
    str += '\n' + detailMenuTab[14];
    str += '\n' + secondsToHms(detailsProcess?.twoStepHoleworkTime);

    // ジョイントバラシ
    str += '\n' + detailMenuTab[15];
    str += '\n' + '個数/cm2';
    detailsProcess?.jointQtySizeThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 'cm2';
      });
    str += '\n' + 'ジョイント個数';
    detailsProcess?.jointQtyItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row) => {
        str += ',';
        str += Number(row.value);
      });
    str += '\n' + '材質区分/板厚';
    //板厚マスタ
    detailsProcess?.jointSeparateThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        str += ',';
        str += '～' + item.value + 't';
      });
    if (detailsProcess?.jointSeparateItems) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(detailsProcess?.jointSeparateItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          str += '\n';
          str += typeNm ? typeNm : '';
          detailsProcess?.jointSeparateItems[key]?.map((item, index) => {
            str += ',';
            str += Number(item.value);
          });
        });
    }

    return str;
  };

  // CSVファイル名称
  const getCsvExportFileName = () => {
    let fileName = '';
    let mstProcess = props?.getProcessMstRecordByWorkType(workType);
    let classType = ServiceClasses?.find((item) => item.value === mstProcess?.class)?.label;
    let workTypeName = mstProcess?.name;
    fileName = `装置設定_${classType}_${workTypeName}_${formatDateString(todayTime())}.csv`;
    return fileName;
  };

  // CSV入力データを登録
  const submitCSVImportData = (dataList) => {
    messageRef.current = [];
    let detailMenuTab = getDetailMenuTab();
    if (props.csvImportDataValidation(dataList, detailMenuTab, messageRef, workType)) {
      let deviceData = JSON.parse(JSON.stringify(selectedDeviceData));
      // タイトル部分
      let title = dataList[0];
      if (
        workType !== WorkType.PaCustom &&
        workType !== WorkType.SmCustom &&
        title.includes('ID,コード,工程名,装置コード,装置名,備考,表示')
      ) {
        title = dataList[1]?.split(',');
        deviceData.updateId = title[0] !== undefined ? Number(title[0]) : deviceData?.id;
        deviceData.code = title[3] !== undefined ? title[3] : deviceData?.code;
        deviceData.name = title[4] !== undefined ? title[4] : deviceData?.name;
        deviceData.info.remarks = title[5] !== undefined ? title[5] : deviceData?.info.remarks;
        deviceData.info.isUsed = title[6] !== undefined ? JSON.parse(title[6]?.toLowerCase()) : deviceData?.info.isUsed; // IQX_WEBEST-301
      } else if (
        (workType === WorkType.PaCustom || workType === WorkType.SmCustom) &&
        title.includes('ID,コード,工程名,コード,工程名,装置コード,装置名,備考,表示')
      ) {
        title = dataList[1]?.split(',');
        deviceData.updateId = title[0] !== undefined ? Number(title[0]) : deviceData?.id;
        deviceData.code = title[5] !== undefined ? title[5] : deviceData?.code;
        deviceData.name = title[6] !== undefined ? title[6] : deviceData?.name;
        deviceData.info.remarks = title[7] !== undefined ? title[7] : deviceData?.info.remarks;
        deviceData.info.isUsed = title[8] !== undefined ? JSON.parse(title[8]?.toLowerCase()) : deviceData?.info.isUsed; // IQX_WEBEST-301
      }
      // 表面処理の場合
      if (workType === WorkType.PaSurface || workType === WorkType.SmSurface) {
        deviceData = surfaceCSVImport(deviceData, dataList, detailMenuTab);
      } else if (workType === WorkType.PaInspection || workType === WorkType.SmInspection) {
        // 検査処理の場合
        deviceData = kensaCSVImport(deviceData, dataList, detailMenuTab);
      } else if (workType === WorkType.PaCustom || workType === WorkType.SmCustom) {
        // 追加工程処理の場合
        deviceData = customCSVImport(deviceData, dataList, detailMenuTab);
      } else if (workType === WorkType.PaAssenble || workType === WorkType.PaPacking) {
        // 組立 // 梱包
        deviceData = assenblePackingCSVImport(deviceData, dataList, detailMenuTab);
      } else if (workType === WorkType.SmProgram) {
        // プログラム
        deviceData = programCSVImport(deviceData, dataList, detailMenuTab);
      } else if (workType === WorkType.SmShearing) {
        // シャーリング
        deviceData = shearingCSVImport(deviceData, dataList, detailMenuTab);
      } else if (workType === WorkType.SmBending) {
        // ベンディング
        deviceData = bendingCSVImport(deviceData, dataList, detailMenuTab);
      } else if (workType === WorkType.SmDeburringAuto) {
        // 自動バリ
        deviceData = autoDeburCSVImport(deviceData, dataList, detailMenuTab);
      } else if (workType === WorkType.SmDeburringManual) {
        // 手動バリ
        deviceData = manualDeburCSVImport(deviceData, dataList, detailMenuTab);
      } else if (workType === WorkType.SmWelding) {
        // 溶接
        deviceData = weldCSVImport(deviceData, dataList, detailMenuTab);
      } else if (workType === WorkType.SmWeldFinish) {
        // 溶接仕上
        deviceData = weldFinishCSVImport(deviceData, dataList, detailMenuTab);
      } else if (workType === WorkType.SmSecondaryWork) {
        // ２次加工
        deviceData = secondaryWorkCSVImport(deviceData, dataList, detailMenuTab);
      } else if (workType === WorkType.SmBlankPunch) {
        // パンチ
        deviceData = punchCSVImport(deviceData, dataList, detailMenuTab);
      } else if (workType === WorkType.SmBlankLaser) {
        // レーザー
        deviceData = laserCSVImport(deviceData, dataList, detailMenuTab);
      } else if (workType === WorkType.SmBlankCombi) {
        // 複合機
        deviceData = combiCSVImport(deviceData, dataList, detailMenuTab);
      }
      props.updatedDetails(deviceData);
    }
    Modal.success({
      width: 500,
      className: 'modal-complete csv-modal-complete',
      title: '完了',
      content: (
        <p style={{ fontSize: '13.5px', whiteSpace: 'pre-line', lineHeight: 2 }}>
          {'CSV入力のデータ更新処理は完了しました。'}
          {messageRef.current?.length > 0 ? (
            <>
              <br></br>
              {'下記の内容をご確認ください。'}
              <br></br>
              <ul>{messageRef.current}</ul>
            </>
          ) : (
            <></>
          )}
        </p>
      ),
    });
  };

  // 表面処理のCSV入力処理
  const surfaceCSVImport = (deviceData, dataList, detailMenuTab) => {
    let inVal = 0;
    // チャージ金額(/h)
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let chargesItemIndex = chargesIndex + 1;
    let inputData = [];
    let index = 0;
    // 工程種別の詳細情報を得る
    const groupDisplayList = getProcessDetailTypesInfo(deviceData?.details?.charges, processMstRecord);
    Object.entries(groupDisplayList)?.map(([_, groupItem]) => {
      groupItem?.map((subItem) => {
        getChargesDetailValues(subItem?.id, deviceData?.details?.charges)?.map((item) => {
          if (parseInt(item) === subItem?.id) return;
          if (index > 0) {
            chargesItemIndex = chargesItemIndex + 3;
          }
          inputData = dataList?.[chargesItemIndex];
          if (inputData && (inputData == subItem?.name || inputData?.includes(subItem?.name))) {
            inputData = dataList?.[chargesItemIndex + 2]?.split(',');
            item.prep = inputData?.[0] ? parseInt(inputData?.[0]) : item.prep;
            item.work = inputData?.[2] ? parseInt(inputData?.[2]) : item.work;
            item.weight = inputData?.[4] ? parseInt(inputData?.[4]) : item.weight;
            item.squareMeter = inputData?.[6] ? parseInt(inputData?.[6]) : item.squareMeter;
          }
        });
        index++;
      });
    });

    // 段取設定
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    chargesItemIndex = chargesIndex + 1;
    inputData = [];
    index = 0;
    Object.entries(groupDisplayList)?.map(([_, groupItem]) => {
      groupItem?.map((subItem) => {
        let groupDetail = getChargesDetailValues(subItem?.id, deviceData?.details?.prepSecs);
        groupDetail?.map((item, i) => {
          if (parseInt(item) === subItem?.id && i === 0) return;
          if (index > 0) {
            chargesItemIndex = chargesItemIndex + 2;
          }
          inputData = dataList?.[chargesItemIndex];
          if (inputData && (inputData == subItem?.name || inputData?.includes(subItem?.name))) {
            inputData = dataList?.[chargesItemIndex + 1];
            // IQX_WEBEST-301
            if (inputData?.split(',') !== undefined) {
              inputData = inputData?.split(',')?.[0];
            }
            deviceData.details.prepSecs[groupDetail[0]] = inputData !== undefined ? setTimetoSec(inputData) : item;
          }
        });
        index++;
      });
    });

    inputData = [];
    if (workType === WorkType.SmSurface && types === ServiceClass.SheetMetal) {
      let childPartAreaIndex = dataList?.findIndex(
        (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
      );
      inputData = dataList?.[childPartAreaIndex + 1]?.split(',');
      //面積マスタ
      deviceData?.details?.childPartsCoeffAreaThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item, index) => {
          inVal = inputData[index + 1]?.replace('～', '');
          inVal = inVal?.replace('cm2', '');
          inVal = inVal !== undefined ? Number(inVal) : item.value;
          item.value = inVal !== undefined ? inVal : item.value;
        });

      //重量マスタ
      let childPartWeightIndex = dataList?.findIndex(
        (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
      );
      deviceData?.details?.childPartsCoeffWeightThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item, index) => {
          inputData = dataList?.[childPartWeightIndex + 2 + index]?.split(',');
          inVal = inputData[0]?.replace('kg', '');
          inVal = inVal !== undefined ? Number(inVal) : item.value;
          item.value = inVal !== undefined ? inVal : item.value;
          //係数値
          deviceData?.details?.childPartsCoeff
            ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
            ?.areaCoeffList?.map((area, i) => {
              inVal = inputData[i + 1] !== undefined ? toDecimal(inputData[i + 1], 2) : area.value;
              area.value = inVal !== undefined ? inVal : area.value;
            });
        });

      // 段取設定の段取個数係数
      inputData = [];
      let dandoriQtyIndex = dataList?.findIndex(
        (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
      );
      inputData = dataList?.[dandoriQtyIndex + 1]?.split(',');
      deviceData?.details?.prepQtyCoeffThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item, index) => {
          inVal = inputData[index + 1]?.replace('～', '');
          inVal = inVal?.replace('個', '');
          item.value = inVal !== undefined ? Number(inVal) : item.value;
        });
      inputData = dataList?.[dandoriQtyIndex + 2]?.split(',');
      deviceData?.details?.prepQtyCoeffItems
        ?.filter((item) => !item.isOutOfRange)
        ?.map((row, index) => {
          inVal = inputData[index + 1] !== undefined ? toDecimal(inputData[index + 1], 2) : row.value;
          row.value = inVal !== undefined ? inVal : row.value;
        });
    } else if (workType === WorkType.PaSurface && types === ServiceClass.Parent) {
      // 段取係数1
      let childPartAreaIndex = dataList?.findIndex(
        (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
      );
      inputData = dataList?.[childPartAreaIndex + 1]?.split(',');
      deviceData?.details?.prepThQtyMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item, index) => {
          inVal = inputData[index + 1]?.replace('～', '');
          inVal = inVal?.replace('個', '');
          item.value = inVal !== undefined ? Number(inVal) : item.value;
        });
      inputData = dataList?.[childPartAreaIndex + 2]?.split(',');
      deviceData?.details?.prepCoeffThChildQtyItems
        ?.filter((item) => !item.isOutOfRange)
        ?.map((row, index) => {
          inVal = inputData[index + 1] !== undefined ? toDecimal(inputData[index + 1], 2) : row.value;
          row.value = inVal !== undefined ? inVal : row.value;
        });

      // 段取係数2
      let childPartWeightIndex = dataList?.findIndex(
        (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
      );
      inputData = dataList?.[childPartWeightIndex + 1]?.split(',');
      deviceData?.details?.prepCoeffThWeightMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item, index) => {
          inVal = inputData[index + 1]?.replace('～', '');
          inVal = inVal?.replace('kg', '');
          item.value = inVal !== undefined ? Number(inVal) : item.value;
        });
      inputData = dataList?.[childPartWeightIndex + 2]?.split(',');
      deviceData?.details?.prepCoeffThWeightItems
        ?.filter((item) => !item.isOutOfRange)
        ?.map((row, index) => {
          inVal = inputData[index + 1] !== undefined ? toDecimal(inputData[index + 1], 2) : row.value;
          row.value = inVal !== undefined ? inVal : row.value;
        });
    }

    // 加工時間
    //加工時間-面積マスタ
    inputData = [];
    let workIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    inputData = dataList?.[workIndex + 1]?.split(',');
    deviceData?.details?.areaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('cm2', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    //加工時間-値
    inputData = [];
    let workTimeIndex = 0;
    if (deviceData?.details?.workTimeThItems) {
      Object.entries(deviceData?.details?.workTimeThItems)?.map(([key, data]) => {
        let groupNm = processMstRecord?.details?.processDetailTypes?.filter(
          (subitem) => subitem.id === parseInt(key) && subitem.isUsed === true
        )?.[0]?.name;
        if (groupNm && groupNm !== '') {
          inputData = dataList?.[workIndex + 2 + workTimeIndex]?.split(',');
          if (inputData && inputData[0] == groupNm) {
            data?.map((row, i) => {
              inVal = inputData[i + 1];
              inVal = inVal !== undefined ? setTimetoSec(inVal) : row.value;
              row.value = inVal;
            });
          }
          workTimeIndex++;
        }
      });
    }

    // 重量係数
    inputData = [];
    let weightCoeffIndex = 0;
    if (workType === WorkType.SmSurface && types === ServiceClass.SheetMetal) {
      weightCoeffIndex = dataList?.findIndex(
        (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
      );
    } else if (workType === WorkType.PaSurface && types === ServiceClass.Parent) {
      weightCoeffIndex = dataList?.findIndex(
        (item, index) =>
          item === detailMenuTab[5] ||
          (dataList?.[index]?.split(',')?.[0] === detailMenuTab[5] &&
            dataList?.[index + 3]?.split(',')?.[0] === detailMenuTab[6])
      );
    }
    inputData = dataList?.[weightCoeffIndex + 1]?.split(',');
    deviceData?.details?.prodWeightCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('kg', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    inputData = dataList?.[weightCoeffIndex + 2]?.split(',');
    deviceData?.details?.prodWeightCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        item.value = toDecimal(inputData[index + 1], 2);
      });

    // 生産個数係数
    inputData = [];
    let quantityCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    inputData = dataList?.[quantityCoeffIndex + 1]?.split(',');
    deviceData?.details?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('個', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    inputData = dataList?.[quantityCoeffIndex + 2]?.split(',');
    deviceData?.details?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        item.value = toDecimal(inputData[index + 1], 2);
      });

    return deviceData;
  };

  // 検査処理のCSV入力処理
  const kensaCSVImport = (deviceData, dataList, detailMenuTab) => {
    let inVal = 0;
    // チャージ金額(/h)
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let dataTitle = dataList[chargesIndex + 1];
    let amountData = dataList[chargesIndex + 2]?.split(',');
    deviceData.details.charges.prep = amountData?.[0] ? amountData?.[0] : deviceData?.details?.charges?.prep;
    deviceData.details.charges.work = amountData?.[1] ? amountData?.[1] : deviceData?.details?.charges?.work;

    // 段取設定
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    dataTitle = dataList[chargesIndex + 1];
    let timeData = dataList[chargesIndex + 2];
    // IQX_WEBEST-301
    if (timeData?.split(',') !== undefined) {
      timeData = timeData?.split(',')?.[0];
    }
    deviceData.details.prepBasicTime =
      timeData !== undefined ? setTimetoSec(timeData) : deviceData?.details?.prepBasicTime;

    let inputData = [];
    if (workType === WorkType.SmInspection && types === ServiceClass.SheetMetal) {
      // 段取設定の子部品サイズ係数
      let childPartAreaIndex = dataList?.findIndex(
        (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
      );
      inputData = dataList?.[childPartAreaIndex + 1]?.split(',');
      //面積マスタ
      deviceData?.details?.childPartsCoeffAreaThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item, index) => {
          inVal = inputData[index + 1]?.replace('～', '');
          inVal = inVal?.replace('cm2', '');
          inVal = inVal !== undefined ? Number(inVal) : item.value;
          item.value = inVal !== undefined ? inVal : item.value;
        });

      //重量マスタ
      let childPartWeightIndex = dataList?.findIndex(
        (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
      );
      deviceData?.details?.childPartsCoeffWeightThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item, index) => {
          inputData = dataList?.[childPartWeightIndex + 2 + index]?.split(',');
          inVal = inputData[0]?.replace('kg', '');
          inVal = inVal !== undefined ? Number(inVal) : item.value;
          item.value = inVal !== undefined ? inVal : item.value;
          //係数値
          deviceData?.details?.childPartsCoeff
            ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
            ?.areaCoeffList?.map((area, i) => {
              inVal = inputData[i + 1] !== undefined ? toDecimal(inputData[i + 1], 2) : area.value;
              area.value = inVal !== undefined ? inVal : area.value;
            });
        });

      // 段取設定の段取個数係数
      inputData = [];
      let dandoriQtyIndex = dataList?.findIndex(
        (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
      );
      inputData = dataList?.[dandoriQtyIndex + 1]?.split(',');
      deviceData?.details?.prepQtyCoeffThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item, index) => {
          inVal = inputData[index + 1]?.replace('～', '');
          inVal = inVal?.replace('個', '');
          item.value = inVal !== undefined ? Number(inVal) : item.value;
        });
      inputData = dataList?.[dandoriQtyIndex + 2]?.split(',');
      deviceData?.details?.prepQtyCoeffItems
        ?.filter((item) => !item.isOutOfRange)
        ?.map((row, index) => {
          inVal = inputData[index + 1] !== undefined ? toDecimal(inputData[index + 1], 2) : row.value;
          row.value = inVal !== undefined ? inVal : row.value;
        });
    } else if (workType === WorkType.PaInspection && types === ServiceClass.Parent) {
      // 段取係数1
      let childPartAreaIndex = dataList?.findIndex(
        (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
      );
      inputData = dataList?.[childPartAreaIndex + 1]?.split(',');
      deviceData?.details?.prepThQtyMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item, index) => {
          inVal = inputData[index + 1]?.replace('～', '');
          inVal = inVal?.replace('個', '');
          item.value = inVal !== undefined ? Number(inVal) : item.value;
        });
      inputData = dataList?.[childPartAreaIndex + 2]?.split(',');
      deviceData?.details?.prepCoeffThChildQtyItems
        ?.filter((item) => !item.isOutOfRange)
        ?.map((row, index) => {
          inVal = inputData[index + 1] !== undefined ? toDecimal(inputData[index + 1], 2) : row.value;
          row.value = inVal !== undefined ? inVal : row.value;
        });

      // 段取係数2
      let childPartWeightIndex = dataList?.findIndex(
        (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
      );
      inputData = dataList?.[childPartWeightIndex + 1]?.split(',');
      deviceData?.details?.prepCoeffThWeightMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item, index) => {
          inVal = inputData[index + 1]?.replace('～', '');
          inVal = inVal?.replace('kg', '');
          item.value = inVal !== undefined ? Number(inVal) : item.value;
        });
      inputData = dataList?.[childPartWeightIndex + 2]?.split(',');
      deviceData?.details?.prepCoeffThWeightItems
        ?.filter((item) => !item.isOutOfRange)
        ?.map((row, index) => {
          inVal = inputData[index + 1] !== undefined ? toDecimal(inputData[index + 1], 2) : row.value;
          row.value = inVal !== undefined ? inVal : row.value;
        });
    }

    // 加工時間
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    dataTitle = dataList[chargesIndex + 1];
    if (workType === WorkType.SmInspection && types === ServiceClass.SheetMetal) {
      let timeData = dataList[chargesIndex + 2];
      // IQX_WEBEST-301
      if (timeData?.split(',') !== undefined) {
        timeData = timeData?.split(',')?.[0];
      }
      deviceData.details.inspectionWorkTime =
        timeData !== undefined ? setTimetoSec(timeData) : deviceData?.details?.inspectionWorkTime;
    } else if (workType === WorkType.PaInspection && types === ServiceClass.Parent) {
      let timeData = dataList[chargesIndex + 2];
      // IQX_WEBEST-301
      if (timeData?.split(',') !== undefined) {
        timeData = timeData?.split(',')?.[0];
      }
      deviceData.details.workBasicTime =
        timeData !== undefined ? setTimetoSec(timeData) : deviceData?.details?.workBasicTime;
    }

    // 加工時間の子部品サイズ係数
    if (workType === WorkType.SmInspection && types === ServiceClass.SheetMetal) {
      inputData = [];
      let areaIndex = dataList?.findIndex(
        (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
      );
      inputData = dataList?.[areaIndex + 1]?.split(',');
      //面積マスタ
      deviceData?.details?.prodSizeAreaThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item, index) => {
          inVal = inputData[index + 1]?.replace('～', '');
          inVal = inVal?.replace('cm2', '');
          inVal = inVal !== undefined ? Number(inVal) : item.value;
          item.value = inVal !== undefined ? inVal : item.value;
        });

      //重量マスタ
      let weightIndex = dataList?.findIndex(
        (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
      );
      deviceData?.details?.prodSizeWeightThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item, index) => {
          inputData = dataList?.[weightIndex + 2 + index]?.split(',');
          inVal = inputData[0]?.replace('kg', '');
          inVal = inVal !== undefined ? Number(inVal) : item.value;
          item.value = inVal !== undefined ? inVal : item.value;
          //係数値
          deviceData?.details?.prodSizeItems
            ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
            ?.areaCoeffList?.map((area, i) => {
              inVal = inputData[i + 1] !== undefined ? toDecimal(inputData[i + 1], 2) : area.value;
              area.value = inVal !== undefined ? inVal : area.value;
            });
        });
    } else if (workType === WorkType.PaInspection && types === ServiceClass.Parent) {
      // 重量係数
      inputData = [];
      let weightCoeffIndex = dataList?.findIndex(
        (item, index) =>
          item === detailMenuTab[5] ||
          (dataList?.[index]?.split(',')?.[0] === detailMenuTab[5] &&
            dataList?.[index + 3]?.split(',')?.[0] === detailMenuTab[6])
      );
      inputData = dataList?.[weightCoeffIndex + 1]?.split(',');
      deviceData?.details?.prodWeightCoeffThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item, index) => {
          inVal = inputData[index + 1]?.replace('～', '');
          inVal = inVal?.replace('kg', '');
          inVal = inVal !== undefined ? Number(inVal) : item.value;
          item.value = inVal !== undefined ? inVal : item.value;
        });
      inputData = dataList?.[weightCoeffIndex + 2]?.split(',');
      deviceData?.details?.prodWeightCoeffThItems
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item, index) => {
          item.value = toDecimal(inputData[index + 1], 2);
        });
    }

    // 生産個数係数
    inputData = [];
    let quantityCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    inputData = dataList?.[quantityCoeffIndex + 1]?.split(',');
    deviceData?.details?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('個', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    inputData = dataList?.[quantityCoeffIndex + 2]?.split(',');
    deviceData?.details?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        item.value = toDecimal(inputData[index + 1], 2);
      });

    return deviceData;
  };

  // 追加工程処理のCSV入力処理
  const customCSVImport = (deviceData, dataList, detailMenuTab) => {
    let inVal = 0;
    // チャージ金額(/h)
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let dataTitle = dataList[chargesIndex + 1];
    let amountData = dataList[chargesIndex + 2]?.split(',');
    deviceData.details.charges.prep = amountData?.[0] ? amountData?.[0] : deviceData?.details?.charges?.prep;
    deviceData.details.charges.work = amountData?.[1] ? amountData?.[1] : deviceData?.details?.charges?.work;

    // 段取設定
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    dataTitle = dataList[chargesIndex + 1];

    let timeData = dataList[chargesIndex + 2];
    // IQX_WEBEST-301
    if (timeData?.split(',') !== undefined) {
      timeData = timeData?.split(',')?.[0];
    }
    deviceData.details.prepBasicTime =
      timeData !== undefined ? setTimetoSec(timeData) : deviceData?.details?.prepBasicTime;

    let inputData = [];
    if (workType === WorkType.SmCustom && types === ServiceClass.SheetMetal) {
      // 段取設定の子部品サイズ係数
      let childPartAreaIndex = dataList?.findIndex(
        (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
      );
      inputData = dataList?.[childPartAreaIndex + 1]?.split(',');
      //面積マスタ
      deviceData?.details?.childPartsCoeffAreaThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item, index) => {
          inVal = inputData[index + 1]?.replace('～', '');
          inVal = inVal?.replace('cm2', '');
          inVal = inVal !== undefined ? Number(inVal) : item.value;
          item.value = inVal !== undefined ? inVal : item.value;
        });

      //重量マスタ
      let childPartWeightIndex = dataList?.findIndex(
        (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
      );
      deviceData?.details?.childPartsCoeffWeightThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item, index) => {
          inputData = dataList?.[childPartWeightIndex + 2 + index]?.split(',');
          inVal = inputData[0]?.replace('kg', '');
          inVal = inVal !== undefined ? Number(inVal) : item.value;
          item.value = inVal !== undefined ? inVal : item.value;
          //係数値
          deviceData?.details?.childPartsCoeff
            ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
            ?.areaCoeffList?.map((area, i) => {
              inVal = inputData[i + 1] !== undefined ? toDecimal(inputData[i + 1], 2) : area.value;
              area.value = inVal !== undefined ? inVal : area.value;
            });
        });

      // 段取設定の段取個数係数
      inputData = [];
      let dandoriQtyIndex = dataList?.findIndex(
        (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
      );
      inputData = dataList?.[dandoriQtyIndex + 1]?.split(',');
      deviceData?.details?.prepQtyCoeffThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item, index) => {
          inVal = inputData[index + 1]?.replace('～', '');
          inVal = inVal?.replace('個', '');
          item.value = inVal !== undefined ? Number(inVal) : item.value;
        });
      inputData = dataList?.[dandoriQtyIndex + 2]?.split(',');
      deviceData?.details?.prepQtyCoeffItems
        ?.filter((item) => !item.isOutOfRange)
        ?.map((row, index) => {
          inVal = inputData[index + 1] !== undefined ? toDecimal(inputData[index + 1], 2) : row.value;
          row.value = inVal !== undefined ? inVal : row.value;
        });
    } else if (workType === WorkType.PaCustom && types === ServiceClass.Parent) {
      // 段取係数1
      let childPartAreaIndex = dataList?.findIndex(
        (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
      );
      inputData = dataList?.[childPartAreaIndex + 1]?.split(',');
      deviceData?.details?.prepThQtyMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item, index) => {
          inVal = inputData[index + 1]?.replace('～', '');
          inVal = inVal?.replace('個', '');
          item.value = inVal !== undefined ? Number(inVal) : item.value;
        });
      inputData = dataList?.[childPartAreaIndex + 2]?.split(',');
      deviceData?.details?.prepCoeffThChildQtyItems
        ?.filter((item) => !item.isOutOfRange)
        ?.map((row, index) => {
          inVal = inputData[index + 1] !== undefined ? toDecimal(inputData[index + 1], 2) : row.value;
          row.value = inVal !== undefined ? inVal : row.value;
        });

      // 段取係数2
      let childPartWeightIndex = dataList?.findIndex(
        (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
      );
      inputData = dataList?.[childPartWeightIndex + 1]?.split(',');
      deviceData?.details?.prepCoeffThWeightMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item, index) => {
          inVal = inputData[index + 1]?.replace('～', '');
          inVal = inVal?.replace('kg', '');
          item.value = inVal !== undefined ? Number(inVal) : item.value;
        });
      inputData = dataList?.[childPartWeightIndex + 2]?.split(',');
      deviceData?.details?.prepCoeffThWeightItems
        ?.filter((item) => !item.isOutOfRange)
        ?.map((row, index) => {
          inVal = inputData[index + 1] !== undefined ? toDecimal(inputData[index + 1], 2) : row.value;
          row.value = inVal !== undefined ? inVal : row.value;
        });
    }

    // 加工時間
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    dataTitle = dataList[chargesIndex + 1];
    timeData = dataList[chargesIndex + 2];
    // IQX_WEBEST-301
    if (timeData?.split(',') !== undefined) {
      timeData = timeData?.split(',')?.[0];
    }
    deviceData.details.workBasicTime =
      timeData !== undefined ? setTimetoSec(timeData) : deviceData?.details?.workBasicTime;

    if (workType === WorkType.SmCustom && types === ServiceClass.SheetMetal) {
      // 加工時間の子部品サイズ係数
      inputData = [];
      let areaIndex = dataList?.findIndex(
        (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
      );
      inputData = dataList?.[areaIndex + 1]?.split(',');
      //面積マスタ
      deviceData?.details?.prodSizeAreaThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item, index) => {
          inVal = inputData[index + 1]?.replace('～', '');
          inVal = inVal?.replace('cm2', '');
          inVal = inVal !== undefined ? Number(inVal) : item.value;
          item.value = inVal !== undefined ? inVal : item.value;
        });

      //重量マスタ
      let weightIndex = dataList?.findIndex(
        (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
      );
      deviceData?.details?.prodSizeWeightThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item, index) => {
          inputData = dataList?.[weightIndex + 2 + index]?.split(',');
          inVal = inputData[0]?.replace('kg', '');
          inVal = inVal !== undefined ? Number(inVal) : item.value;
          item.value = inVal !== undefined ? inVal : item.value;
          //係数値
          deviceData?.details?.prodSizeItems
            ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
            ?.areaCoeffList?.map((area, i) => {
              inVal = inputData[i + 1] !== undefined ? toDecimal(inputData[i + 1], 2) : area.value;
              area.value = inVal !== undefined ? inVal : area.value;
            });
        });
    } else if (workType === WorkType.PaCustom && types === ServiceClass.Parent) {
      // 重量係数
      inputData = [];
      let weightCoeffIndex = dataList?.findIndex(
        (item, index) =>
          item === detailMenuTab[5] ||
          (dataList?.[index]?.split(',')?.[0] === detailMenuTab[5] &&
            dataList?.[index + 3]?.split(',')?.[0] === detailMenuTab[6])
      );
      inputData = dataList?.[weightCoeffIndex + 1]?.split(',');
      deviceData?.details?.prodWeightCoeffThMaster
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item, index) => {
          inVal = inputData[index + 1]?.replace('～', '');
          inVal = inVal?.replace('kg', '');
          inVal = inVal !== undefined ? Number(inVal) : item.value;
          item.value = inVal !== undefined ? inVal : item.value;
        });
      inputData = dataList?.[weightCoeffIndex + 2]?.split(',');
      deviceData?.details?.prodWeightCoeffThItems
        ?.filter((item) => !item.isOutOfRange)
        ?.map((item, index) => {
          item.value = toDecimal(inputData[index + 1], 2);
        });
    }

    // 生産個数係数
    inputData = [];
    let quantityCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    inputData = dataList?.[quantityCoeffIndex + 1]?.split(',');
    deviceData?.details?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('個', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    inputData = dataList?.[quantityCoeffIndex + 2]?.split(',');
    deviceData?.details?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        item.value = toDecimal(inputData[index + 1], 2);
      });

    // 委託装置詳細
    inputData = [];
    let commissionIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[7] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[7]
    );
    inputData = dataList?.[commissionIndex + 1]?.split(',');
    if (inputData?.[0] === '委託工程' || inputData?.[0]?.includes('委託工程')) {
      deviceData.details.isCommission =
        inputData?.[1] !== undefined ? JSON.parse(inputData?.[1]?.toLowerCase()) : deviceData?.details?.isCommission; // IQX_WEBEST-301
    }
    if (deviceData?.details?.isCommission) {
      inputData = dataList?.[commissionIndex + 2]?.split(',');
      let customerNm = '';
      let customers = [];
      if (inputData?.[0] === '取引先(委託先)' || inputData?.[0]?.includes('取引先(委託先)')) {
        customerNm = inputData?.[1];
      }
      inputData = dataList?.[commissionIndex + 3]?.split(',');
      if (inputData?.[0] === '取引先コード' || inputData?.[0]?.includes('取引先コード')) {
        // IQX_WEBEST-381 パラメーター親部品装置設定と板金装置設定のCSV出力処理ができていない。
        if (customerNm !== '' && inputData?.[1] !== '') {
          customers = props?.getCustomers?.data?.filter(
            (item) => item.code === inputData?.[1] && item.name === customerNm
          );
        } else if (customerNm !== '' && inputData?.[1] === '') {
          customers = props?.getCustomers?.data?.filter((item) => item.name === customerNm);
        } else if (customerNm === '' && inputData?.[1] !== '') {
          customers = props?.getCustomers?.data?.filter((item) => item.code === inputData?.[1]);
        }
        if (customers?.length > 0) {
          deviceData.details.clientId = customers?.[0]?.id;
        }
      }
    } else {
      deviceData.details.clientId = 0;
    }
    inputData = dataList?.[commissionIndex + 4]?.split(',');
    if (inputData?.[0] === '手入力フラグ' || inputData?.[0]?.includes('手入力フラグ')) {
      deviceData.details.isManualInput =
        inputData?.[1] !== undefined ? JSON.parse(inputData?.[1]?.toLowerCase()) : deviceData?.details?.isManualInput; // IQX_WEBEST-301
    }
    inputData = dataList?.[commissionIndex + 5]?.split(',');
    if (inputData?.[0] === '初期金額(手入力時)' || inputData?.[0]?.includes('初期金額(手入力時)')) {
      deviceData.details.initPrice =
        inputData?.[1] !== undefined ? Number(inputData?.[1]) : deviceData?.details?.initPrice;
    }
    return deviceData;
  };

  // 組立・梱包処理のCSV入力処理
  const assenblePackingCSVImport = (deviceData, dataList, detailMenuTab) => {
    let inVal = 0;
    // チャージ金額(/h)
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let dataTitle = dataList[chargesIndex + 1];
    let amountData = dataList[chargesIndex + 2]?.split(',');
    deviceData.details.charges.prep = amountData?.[0] ? amountData?.[0] : deviceData?.details?.charges?.prep;
    deviceData.details.charges.work = amountData?.[1] ? amountData?.[1] : deviceData?.details?.charges?.work;

    // 段取設定
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    dataTitle = dataList[chargesIndex + 1];
    let timeData = dataList[chargesIndex + 2];
    // IQX_WEBEST-301
    if (timeData?.split(',') !== undefined) {
      timeData = timeData?.split(',')?.[0];
    }
    deviceData.details.prepBasicTime =
      timeData !== undefined ? setTimetoSec(timeData) : deviceData?.details?.prepBasicTime;

    let inputData = [];

    // 段取係数1
    let childPartAreaIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    inputData = dataList?.[childPartAreaIndex + 1]?.split(',');
    deviceData?.details?.prepThQtyMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('個', '');
        item.value = inVal !== undefined ? Number(inVal) : item.value;
      });
    inputData = dataList?.[childPartAreaIndex + 2]?.split(',');
    deviceData?.details?.prepCoeffThChildQtyItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row, index) => {
        inVal = inputData[index + 1] !== undefined ? toDecimal(inputData[index + 1], 2) : row.value;
        row.value = inVal !== undefined ? inVal : row.value;
      });

    // 段取係数2
    let childPartWeightIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
    );
    inputData = dataList?.[childPartWeightIndex + 1]?.split(',');
    deviceData?.details?.prepCoeffThWeightMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('kg', '');
        item.value = inVal !== undefined ? Number(inVal) : item.value;
      });
    inputData = dataList?.[childPartWeightIndex + 2]?.split(',');
    deviceData?.details?.prepCoeffThWeightItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row, index) => {
        inVal = inputData[index + 1] !== undefined ? toDecimal(inputData[index + 1], 2) : row.value;
        row.value = inVal !== undefined ? inVal : row.value;
      });

    // 加工時間
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    dataTitle = dataList[chargesIndex + 1];

    timeData = dataList[chargesIndex + 2];
    // IQX_WEBEST-301
    if (timeData?.split(',') !== undefined) {
      timeData = timeData?.split(',')?.[0];
    }
    deviceData.details.workBasicTime =
      timeData !== undefined ? setTimetoSec(timeData) : deviceData?.details?.workBasicTime;

    // 加工時間の 重量係数
    inputData = [];
    let weightCoeffIndex = dataList?.findIndex(
      (item, index) =>
        item === detailMenuTab[5] ||
        (dataList?.[index]?.split(',')?.[0] === detailMenuTab[5] &&
          dataList?.[index + 3]?.split(',')?.[0] === detailMenuTab[6])
    );
    inputData = dataList?.[weightCoeffIndex + 1]?.split(',');
    deviceData?.details?.prodWeightCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('kg', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    inputData = dataList?.[weightCoeffIndex + 2]?.split(',');
    deviceData?.details?.prodWeightCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        item.value = toDecimal(inputData[index + 1], 2);
      });

    // 生産個数係数
    inputData = [];
    let quantityCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    inputData = dataList?.[quantityCoeffIndex + 1]?.split(',');
    deviceData?.details?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('個', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    inputData = dataList?.[quantityCoeffIndex + 2]?.split(',');
    deviceData?.details?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        item.value = toDecimal(inputData[index + 1], 2);
      });

    return deviceData;
  };

  // プログラムのCSV入力処理
  const programCSVImport = (deviceData, dataList, detailMenuTab) => {
    // プログラムの場合
    let inputData = [];
    // チャージ金額(/h)
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    inputData = dataList?.[chargesIndex + 2]?.split(',');
    deviceData.details.charges.prep = parseInt(inputData?.[0]);
    deviceData.details.charges.work = parseInt(inputData?.[1]);

    // 段取設定
    let dantoriIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    inputData = [];
    inputData = dataList?.[dantoriIndex + 2]?.split(',');
    deviceData?.details?.prepElementThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        item.value = setTimetoSec(inputData[index + 1]);
      });

    // 加工設定
    inputData = [];
    let workCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    inputData = dataList?.[workCoeffIndex + 2]?.split(',');
    deviceData.details.workBasicTime = setTimetoSec(inputData[0]);

    // 係数・その他
    inputData = [];
    let coeffOtherIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
    );
    inputData = dataList?.[coeffOtherIndex + 2]?.split(',');
    deviceData?.details?.workTimeElementCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        item.value = toDecimal(inputData[index + 1], 2);
      });

    // 同一形状・サイズの上限
    inputData = [];
    inputData = dataList?.[coeffOtherIndex + 4]?.split(',');
    deviceData.details.shapeGrouping = parseInt(inputData[0]);

    return deviceData;
  };

  // シャーリングのCSV入力処理
  const shearingCSVImport = (deviceData, dataList, detailMenuTab) => {
    // シャーリングの場合
    let inVal = '';
    let inputData = [];
    // チャージ金額(/h)
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    inputData = dataList?.[chargesIndex + 2]?.split(',');
    deviceData.details.charges.prep = parseInt(inputData?.[0]);
    deviceData.details.charges.work = parseInt(inputData?.[1]);

    // 段取設定
    let dantoriIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    inputData = [];
    inputData = dataList?.[dantoriIndex + 2]?.split(',');
    deviceData.details.prepBasicTime = setTimetoSec(inputData[0]);

    // 子部品サイズ係数
    inputData = [];
    let childPartAreaIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    inputData = dataList?.[childPartAreaIndex + 1]?.split(',');
    //面積マスタ
    deviceData?.details?.childPartsCoeffAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('cm2', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    //重量マスタ
    let childPartWeightIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    deviceData?.details?.childPartsCoeffWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inputData = dataList?.[childPartWeightIndex + 2 + index]?.split(',');
        inVal = inputData[0]?.replace('kg', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
        //係数値
        deviceData?.details?.childPartsCoeff
          ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
          ?.areaCoeffList?.map((area, i) => {
            inVal = inputData[i + 1] !== undefined ? toDecimal(inputData[i + 1], 2) : area.value;
            area.value = inVal !== undefined ? inVal : area.value;
          });
      });

    // 段取設定の段取個数係数
    inputData = [];
    let dandoriQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
    );
    inputData = dataList?.[dandoriQtyIndex + 1]?.split(',');
    deviceData?.details?.prepQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('個', '');
        item.value = inVal !== undefined ? Number(inVal) : item.value;
      });
    inputData = dataList?.[dandoriQtyIndex + 2]?.split(',');
    deviceData?.details?.prepQtyCoeffItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row, index) => {
        inVal = inputData[index + 1] !== undefined ? toDecimal(inputData[index + 1], 2) : row.value;
        row.value = inVal !== undefined ? inVal : row.value;
      });

    // 作業・加工時間
    inputData = [];
    let workCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    inputData = dataList?.[workCoeffIndex + 2]?.split(',');
    deviceData.details.cutTime = inputData[0] !== undefined ? setTimetoSec(inputData[0]) : deviceData?.details?.cutTime;
    deviceData.details.isOneCut =
      inputData[1] !== undefined ? JSON.parse(inputData[1]?.toLowerCase()) : deviceData?.details?.isOneCut; // IQX_WEBEST-301

    // サイズ係数
    inputData = [];
    childPartAreaIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    inputData = dataList?.[childPartAreaIndex + 1]?.split(',');
    //面積マスタ
    deviceData?.details?.prodSizeAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('cm2', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    //重量マスタ
    childPartWeightIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    deviceData?.details?.prodSizeWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inputData = dataList?.[childPartWeightIndex + 2 + index]?.split(',');
        inVal = inputData[0]?.replace('kg', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
        //係数値
        deviceData?.details?.prodSizeItems
          ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
          ?.areaCoeffList?.map((area, i) => {
            inVal = inputData[i + 1] !== undefined ? toDecimal(inputData[i + 1], 2) : area.value;
            area.value = inVal !== undefined ? inVal : area.value;
          });
      });

    // 生産個数係数
    inputData = [];
    let productCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    inputData = dataList?.[productCoeffIndex + 1]?.split(',');
    deviceData?.details?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('個', '');
        inVal = inVal !== undefined ? Number(inVal, 2) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    inputData = dataList?.[productCoeffIndex + 2]?.split(',');
    deviceData?.details?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        item.value = toDecimal(inputData[index + 1], 2);
      });

    return deviceData;
  };

  // ベンディン処理のCSV入力処理
  const bendingCSVImport = (deviceData, dataList, detailMenuTab) => {
    let inVal = '';
    let inputData = [];
    // チャージ金額(/h)
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let dataTitle = dataList[chargesIndex + 1];
    let amountData = dataList[chargesIndex + 2]?.split(',');
    deviceData.details.charges.prep = amountData?.[0] ? amountData?.[0] : deviceData?.details?.charges?.prep;
    deviceData.details.charges.work = amountData?.[1] ? amountData?.[1] : deviceData?.details?.charges?.work;

    // 段取設定
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    let chargesItemIndex = chargesIndex + 2;
    inputData = dataList[chargesItemIndex]?.split(',');
    //板厚マスタ
    deviceData?.details?.basicTimeThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('t', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    chargesItemIndex = chargesItemIndex + 1;
    let index = 0;
    if (deviceData?.details?.basicTimeThickItems) {
      Object.entries(deviceData?.details?.basicTimeThickItems)
        ?.filter(([itemKey]) => getProcessDetailTypeName(parseInt(itemKey), processMstRecord) !== undefined)
        ?.map(([key, groupItem]) => {
          inputData = dataList[chargesItemIndex + index]?.split(',');
          let groupNm = getProcessDetailTypeName(parseInt(key), processMstRecord);
          if (groupNm === inputData?.[0]) {
            deviceData?.details?.basicTimeThickItems?.[parseInt(key)]
              ?.filter((subitem) => !subitem.isOutOfRange)
              ?.map((col, index) => {
                inVal = inputData[index + 1];
                col.value = inVal !== undefined ? setTimetoSec(inVal) : col.value;
              });
          }
          index++;
        });
    }
    // プログラム作成時間
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    inputData = dataList[chargesIndex + 1]?.split(',');
    deviceData?.details?.programCreateTimeCountfThMaster
      ?.filter((subitem) => !subitem.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('本', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    inputData = dataList[chargesIndex + 2]?.split(',');
    deviceData?.details?.programCreateTimeItems
      ?.filter((subitem) => !subitem.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1];
        item.value = inVal !== undefined ? setTimetoSec(inVal) : item.value;
      });
    // 曲長段取時間
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
    );
    inputData = dataList[chargesIndex + 1]?.split(',');
    deviceData?.details?.prepBendTimeLengthMaster
      ?.filter((subitem) => !subitem.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('mm', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    inputData = dataList[chargesIndex + 2]?.split(',');
    deviceData?.details?.prepBendTimeItems
      ?.filter((subitem) => !subitem.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1];
        item.value = inVal !== undefined ? setTimetoSec(inVal) : item.value;
      });

    // 子部品サイズ係数
    inputData = [];
    let childPartAreaIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    inputData = dataList?.[childPartAreaIndex + 1]?.split(',');
    //面積マスタ
    deviceData?.details?.childPartsCoeffAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('cm2', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });

    //重量マスタ
    let childPartWeightIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    deviceData?.details?.childPartsCoeffWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inputData = dataList?.[childPartWeightIndex + 2 + index]?.split(',');
        inVal = inputData[0]?.replace('kg', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
        //係数値
        deviceData?.details?.childPartsCoeff
          ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
          ?.areaCoeffList?.map((area, i) => {
            inVal = inputData[i + 1] !== undefined ? toDecimal(inputData[i + 1], 2) : area.value;
            area.value = inVal !== undefined ? inVal : area.value;
          });
      });

    // 段取設定の段取個数係数
    inputData = [];
    let dandoriQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    inputData = dataList?.[dandoriQtyIndex + 1]?.split(',');
    deviceData?.details?.prepQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('個', '');
        item.value = inVal !== undefined ? Number(inVal) : item.value;
      });
    inputData = dataList?.[dandoriQtyIndex + 2]?.split(',');
    deviceData?.details?.prepQtyCoeffItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row, index) => {
        inVal = inputData[index + 1] !== undefined ? toDecimal(inputData[index + 1], 2) : row.value;
        row.value = inVal !== undefined ? inVal : row.value;
      });

    // 加工時間
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    chargesItemIndex = chargesIndex + 1;
    inputData = dataList[chargesItemIndex]?.split(',');
    //板厚マスタ
    deviceData?.details?.workTimeThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('t', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    chargesItemIndex = chargesItemIndex + 1;
    index = 0;
    if (deviceData?.details?.workTimeThickItems) {
      Object.entries(deviceData?.details?.workTimeThickItems)
        ?.filter(([itemKey]) => getProcessDetailTypeName(parseInt(itemKey), processMstRecord) !== undefined)
        ?.map(([key, groupItem]) => {
          inputData = dataList[chargesItemIndex + index]?.split(',');
          let groupNm = getProcessDetailTypeName(parseInt(key), processMstRecord);
          if (groupNm === inputData?.[0] || inputData?.[0]?.includes(groupNm)) {
            deviceData?.details?.workTimeThickItems?.[parseInt(key)]
              ?.filter((subitem) => !subitem.isOutOfRange)
              ?.map((col, index) => {
                inVal = inputData[index + 1];
                col.value = inVal !== undefined ? setTimetoSec(inVal) : col.value;
              });
          }
          index++;
        });
    }

    // 曲げ長さ係数
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[7] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[7]
    );
    chargesItemIndex = chargesIndex + 1;
    inputData = dataList[chargesItemIndex]?.split(',');
    //面積マスタ
    deviceData?.details?.bendTimeCoeffLenThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('mm', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    //重量マスタ
    chargesItemIndex = chargesItemIndex + 1;
    deviceData?.details?.bendTimeCoeffWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inputData = dataList[chargesItemIndex + index]?.split(',');
        inVal = inputData[0]?.replace('kg', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
        //係数値
        if (deviceData?.details?.bendTimeCoeffItems) {
          let bendTimeCoeffItems = Object.entries(deviceData?.details?.bendTimeCoeffItems)?.filter(
            ([key, data]) => parseInt(key) === item?.no && !data.isOutOfRange
          )?.[0];
          let bendTimeCoeffs = 0;
          bendTimeCoeffItems?.map((item, index) => {
            if (index === 0) {
              bendTimeCoeffs = item;
            }
          });
          deviceData?.details?.bendTimeCoeffItems?.[bendTimeCoeffs].map((coef, i) => {
            if (!coef?.isOutOfRange) {
              inVal = inputData[i + 1];
              coef.value = inVal !== undefined ? toDecimal(inVal, 2) : coef.value;
            }
          });
        }
      });

    // 加工補正時間
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[8] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[8]
    );
    chargesItemIndex = chargesIndex + 1;
    inputData = dataList[chargesItemIndex]?.split(',');
    deviceData?.details?.modifyTimeQtyThMaster
      ?.filter((subitem) => !subitem.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('本', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });

    inputData = dataList[chargesItemIndex + 1]?.split(',');
    deviceData?.details?.modifyTimeItems
      ?.filter((subitem) => !subitem.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1];
        item.value = inVal !== undefined ? setTimetoSec(inVal) : item.value;
      });

    // 加工補正時間係数
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[9] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[9]
    );
    chargesItemIndex = chargesIndex + 1;
    inputData = dataList[chargesItemIndex]?.split(',');
    //面積マスタ
    deviceData?.details?.modifyCoeffLenThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('mm', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    //重量マスタ
    index = 0;
    chargesItemIndex = chargesItemIndex + 1;
    deviceData?.details?.modifyCoeffWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        inputData = dataList[chargesItemIndex + index]?.split(',');
        inVal = inputData[0]?.replace('kg', '');
        item.value = inVal !== undefined ? Number(inVal) : item.value;
        //係数値
        deviceData?.details?.modifyCoeffItems
          ?.filter((itemCoef) => !itemCoef.isOutOfRange && itemCoef.weightId === item.no)?.[0]
          ?.lengthCoeffList?.map((row, i) => {
            inVal = inputData[i + 1];
            row.value = inVal !== undefined ? toDecimal(inVal, 2) : row.value;
          });
        index++;
      });

    // サイズ係数
    inputData = [];
    childPartAreaIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[10] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[10]
    );
    inputData = dataList?.[childPartAreaIndex + 1]?.split(',');
    //面積マスタ
    deviceData?.details?.prodSizeAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('cm2', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    //重量マスタ
    childPartWeightIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[10] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[10]
    );
    deviceData?.details?.prodSizeWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inputData = dataList?.[childPartWeightIndex + 2 + index]?.split(',');
        inVal = inputData[0]?.replace('kg', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
        //係数値
        deviceData?.details?.prodSizeItems
          ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
          ?.areaCoeffList?.map((area, i) => {
            inVal = inputData[i + 1] !== undefined ? toDecimal(inputData[i + 1], 2) : area.value;
            area.value = inVal !== undefined ? inVal : area.value;
          });
      });

    // 生産個数係数
    inputData = [];
    let quantityCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[11] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[11]
    );
    inputData = dataList?.[quantityCoeffIndex + 1]?.split(',');
    deviceData?.details?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('個', '');
        inVal = inVal !== undefined ? Number(inVal, 2) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    inputData = dataList?.[quantityCoeffIndex + 2]?.split(',');
    deviceData?.details?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        item.value = toDecimal(inputData[index + 1], 2);
      });

    return deviceData;
  };

  // 自動バリ処理のCSV入力処理
  const autoDeburCSVImport = (deviceData, dataList, detailMenuTab) => {
    let inVal = '';
    let inputData = [];
    // チャージ金額(/h)
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let dataTitle = dataList[chargesIndex + 1];
    let amountData = dataList[chargesIndex + 2]?.split(',');
    deviceData.details.charges.prep = amountData?.[0] ? amountData?.[0] : deviceData?.details?.charges?.prep;
    deviceData.details.charges.work = amountData?.[1] ? amountData?.[1] : deviceData?.details?.charges?.work;

    // 段取設定
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    dataTitle = dataList[chargesIndex + 1];
    let dandoriTime = dataList[chargesIndex + 2];
    // IQX_WEBEST-301
    if (dandoriTime?.split(',') !== undefined) {
      dandoriTime = dandoriTime?.split(',')?.[0];
    }
    deviceData.details.prepBasicTime =
      dandoriTime !== undefined ? setTimetoSec(dandoriTime) : deviceData?.details?.prepBasicTime;

    // 子部品サイズ係数
    inputData = [];
    let childPartAreaIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    inputData = dataList?.[childPartAreaIndex + 1]?.split(',');
    //面積マスタ
    deviceData?.details?.childPartsCoeffAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('cm2', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });

    //重量マスタ
    let childPartWeightIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    deviceData?.details?.childPartsCoeffWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inputData = dataList?.[childPartWeightIndex + 2 + index]?.split(',');
        inVal = inputData[0]?.replace('kg', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
        //係数値
        deviceData?.details?.childPartsCoeff
          ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
          ?.areaCoeffList?.map((area, i) => {
            inVal = inputData[i + 1] !== undefined ? toDecimal(inputData[i + 1], 2) : area.value;
            area.value = inVal !== undefined ? inVal : area.value;
          });
      });

    // 段取設定の段取個数係数
    inputData = [];
    let dandoriQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
    );
    inputData = dataList?.[dandoriQtyIndex + 1]?.split(',');
    deviceData?.details?.prepQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('個', '');
        item.value = inVal !== undefined ? Number(inVal) : item.value;
      });
    inputData = dataList?.[dandoriQtyIndex + 2]?.split(',');
    deviceData?.details?.prepQtyCoeffItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row, index) => {
        inVal = inputData[index + 1] !== undefined ? toDecimal(inputData[index + 1], 2) : row.value;
        row.value = inVal !== undefined ? inVal : row.value;
      });

    // 加工時間
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    let chargesItemIndex = chargesIndex + 1;
    inputData = dataList[chargesItemIndex]?.split(',');
    //面積マスタ
    deviceData?.details?.areaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('cm2', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    chargesItemIndex = chargesItemIndex + 1;
    inputData = dataList[chargesItemIndex]?.split(',');
    deviceData?.details?.workTimeThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1];
        inVal = inVal !== undefined ? setTimetoSec(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });

    // サイズ係数
    inputData = [];
    childPartAreaIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    inputData = dataList?.[childPartAreaIndex + 1]?.split(',');
    //面積マスタ
    deviceData?.details?.prodSizeAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('cm2', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    //重量マスタ
    childPartWeightIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    deviceData?.details?.prodSizeWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inputData = dataList?.[childPartWeightIndex + 2 + index]?.split(',');
        inVal = inputData[0]?.replace('kg', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
        //係数値
        deviceData?.details?.prodSizeItems
          ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
          ?.areaCoeffList?.map((area, i) => {
            inVal = inputData[i + 1] !== undefined ? toDecimal(inputData[i + 1], 2) : area.value;
            area.value = inVal !== undefined ? inVal : area.value;
          });
      });

    // 生産個数係数
    inputData = [];
    let quantityCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    inputData = dataList?.[quantityCoeffIndex + 1]?.split(',');
    deviceData?.details?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('個', '');
        inVal = inVal !== undefined ? toDecimal(inVal, 2) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    inputData = dataList?.[quantityCoeffIndex + 2]?.split(',');
    deviceData?.details?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        item.value = toDecimal(inputData[index + 1], 2);
      });

    return deviceData;
  };

  // 手動バリ処理のCSV入力処理
  const manualDeburCSVImport = (deviceData, dataList, detailMenuTab) => {
    let inVal = '';
    let inputData = [];
    // チャージ金額(/h)
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let dataTitle = dataList[chargesIndex + 1];
    let amountData = dataList[chargesIndex + 2]?.split(',');
    deviceData.details.charges.prep = amountData?.[0] ? amountData?.[0] : deviceData?.details?.charges?.prep;
    deviceData.details.charges.work = amountData?.[1] ? amountData?.[1] : deviceData?.details?.charges?.work;

    // 段取設定
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    dataTitle = dataList[chargesIndex + 1];
    let dandoriTime = dataList[chargesIndex + 2];
    // IQX_WEBEST-301
    if (dandoriTime?.split(',') !== undefined) {
      dandoriTime = dandoriTime?.split(',')?.[0];
    }
    deviceData.details.prepBasicTime =
      dandoriTime !== undefined ? setTimetoSec(dandoriTime) : deviceData?.details?.prepBasicTime;

    // 子部品サイズ係数
    inputData = [];
    let childPartAreaIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    inputData = dataList?.[childPartAreaIndex + 1]?.split(',');
    //面積マスタ
    deviceData?.details?.childPartsCoeffAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('cm2', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });

    //重量マスタ
    let childPartWeightIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    deviceData?.details?.childPartsCoeffWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inputData = dataList?.[childPartWeightIndex + 2 + index]?.split(',');
        inVal = inputData[0]?.replace('kg', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
        //係数値
        deviceData?.details?.childPartsCoeff
          ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
          ?.areaCoeffList?.map((area, i) => {
            inVal = inputData[i + 1] !== undefined ? toDecimal(inputData[i + 1], 2) : area.value;
            area.value = inVal !== undefined ? inVal : area.value;
          });
      });

    // 段取設定の段取個数係数
    inputData = [];
    let dandoriQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
    );
    inputData = dataList?.[dandoriQtyIndex + 1]?.split(',');
    deviceData?.details?.prepQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('個', '');
        item.value = inVal !== undefined ? Number(inVal) : item.value;
      });
    inputData = dataList?.[dandoriQtyIndex + 2]?.split(',');
    deviceData?.details?.prepQtyCoeffItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row, index) => {
        inVal = inputData[index + 1] !== undefined ? toDecimal(inputData[index + 1], 2) : row.value;
        row.value = inVal !== undefined ? inVal : row.value;
      });

    // 加工速度(mm/min)
    inputData = [];
    let thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    inputData = dataList?.[thickIndex + 1]?.split(',');
    //板厚マスタ
    deviceData?.details?.workSpeedThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('t', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    //加工速度
    let speedLenIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    deviceData?.details?.workSpeedLenThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inputData = dataList?.[speedLenIndex + 2 + index]?.split(',');
        inVal = inputData[0]?.replace('mm', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;

        deviceData?.details?.workSpeedItems
          ?.filter((itemCoeff) => itemCoeff.lengthId === item.no)?.[0]
          ?.itaatsuCoeffList?.map((itaatsu, i) => {
            inVal = inputData[i + 1] !== undefined ? Number(inputData[i + 1]) : itaatsu.value;
            itaatsu.value = inVal !== undefined ? inVal : itaatsu.value;
          });
      });

    // 加工速度係数
    //加工長マスタ
    inputData = [];
    let speedCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    inputData = dataList?.[speedCoeffIndex + 1]?.split(',');
    deviceData?.details?.workSpeedCoeffLengthThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('mm', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    //内加工
    inputData = dataList?.[speedCoeffIndex + 2]?.split(',');
    deviceData?.details?.workSpeedCoeffInternalWorkItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1];
        inVal = inVal !== undefined ? toDecimal(inVal, 2) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    //外加工
    inputData = dataList?.[speedCoeffIndex + 3]?.split(',');
    deviceData?.details?.workSpeedCoeffExternalWorkItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1];
        inVal = inVal !== undefined ? toDecimal(inVal, 2) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });

    // 加工補正時間
    //加工長マスタ
    inputData = [];
    speedCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    inputData = dataList?.[speedCoeffIndex + 1]?.split(',');
    deviceData?.details?.modifyTimeQtyThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    //加工時間-値
    inputData = dataList?.[speedCoeffIndex + 2]?.split(',');
    deviceData?.details?.modifyTimeItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1];
        inVal = inVal !== undefined ? setTimetoSec(inVal, 2) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });

    // 加工補正時間係数
    inputData = [];
    speedCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[7] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[7]
    );
    inputData = dataList?.[speedCoeffIndex + 1]?.split(',');
    //面積マスタ
    deviceData?.details?.mtAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('cm2', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    //重量マスタ
    childPartWeightIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[7] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[7]
    );
    deviceData?.details?.mtWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inputData = dataList?.[childPartWeightIndex + 2 + index]?.split(',');
        inVal = inputData[0]?.replace('kg', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
        //係数値
        deviceData?.details?.mtTimeCoeffItems
          ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
          ?.areaCoeffList?.map((area, i) => {
            inVal = inputData[i + 1] !== undefined ? toDecimal(inputData[i + 1], 2) : area.value;
            area.value = inVal !== undefined ? inVal : area.value;
          });
      });

    // 手動バリ取対象サイズ
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[8] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[8]
    );
    dataTitle = dataList[chargesIndex + 1];
    let sizeData = dataList[chargesIndex + 2]?.split(',');
    deviceData.details.roundHoleDia = sizeData?.[0] ? sizeData?.[0] : deviceData?.details?.roundHoleDia;
    deviceData.details.rectangleDia = sizeData?.[1] ? sizeData?.[1] : deviceData?.details?.rectangleDia;

    // サイズ係数
    inputData = [];
    childPartAreaIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[9] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[9]
    );
    inputData = dataList?.[childPartAreaIndex + 1]?.split(',');
    //面積マスタ
    deviceData?.details?.prodSizeAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('cm2', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    //重量マスタ
    childPartWeightIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[9] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[9]
    );
    deviceData?.details?.prodSizeWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inputData = dataList?.[childPartWeightIndex + 2 + index]?.split(',');
        inVal = inputData[0]?.replace('kg', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
        //係数値
        deviceData?.details?.prodSizeItems
          ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
          ?.areaCoeffList?.map((area, i) => {
            inVal = inputData[i + 1] !== undefined ? toDecimal(inputData[i + 1], 2) : area.value;
            area.value = inVal !== undefined ? inVal : area.value;
          });
      });

    // 生産個数係数
    inputData = [];
    let quantityCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[10] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[10]
    );
    inputData = dataList?.[quantityCoeffIndex + 1]?.split(',');
    deviceData?.details?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('個', '');
        inVal = inVal !== undefined ? Number(inVal, 2) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    inputData = dataList?.[quantityCoeffIndex + 2]?.split(',');
    deviceData?.details?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        item.value = toDecimal(inputData[index + 1], 2);
      });

    return deviceData;
  };

  // 溶接処理のCSV入力処理
  const weldCSVImport = (deviceData, dataList, detailMenuTab) => {
    let inVal = '';
    let inputData = [];
    let index = 0;
    // チャージ金額(/h)
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let chargesItemIndex = chargesIndex + 1;
    const groupDisplayList = getProcessDetailTypesInfo(deviceData?.details?.charges, processMstRecord);
    Object.entries(groupDisplayList)?.map(([_, groupItem]) => {
      groupItem?.map((subItem) => {
        getChargesDetailValues(subItem?.id, deviceData?.details?.charges)?.map((item, i) => {
          if (parseInt(item) === subItem?.id && i === 0) return;
          if (index > 0) {
            chargesItemIndex = chargesItemIndex + 3;
          }
          inputData = dataList?.[chargesItemIndex];
          if (inputData && (inputData == subItem?.name || inputData?.includes(subItem?.name))) {
            inputData = dataList?.[chargesItemIndex + 2]?.split(',');
            item.prep = inputData?.[0] ? parseInt(inputData?.[0]) : item.prep;
            item.work = inputData?.[1] ? parseInt(inputData?.[1]) : item.work;
          }
        });
        index++;
      });
    });

    // 段取設定
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    chargesItemIndex = chargesIndex + 1;
    inputData = [];
    index = 0;
    Object.entries(groupDisplayList)?.map(([_, groupItem]) => {
      groupItem?.map((subItem) => {
        let groupDetail = getPrepSettingDetailValues(subItem?.id, deviceData?.details?.prepSecs);
        groupDetail?.map((item, i) => {
          if (parseInt(item) === subItem?.id && i === 0) return;
          if (index > 0) {
            chargesItemIndex = chargesItemIndex + 2;
          }
          inputData = dataList?.[chargesItemIndex];
          if (inputData && (inputData == subItem?.name || inputData?.includes(subItem?.name))) {
            inputData = dataList?.[chargesItemIndex + 1];
            // IQX_WEBEST-301
            if (inputData?.split(',') !== undefined) {
              inputData = inputData?.split(',')?.[0];
            }
            deviceData.details.prepSecs[groupDetail[0]] = inputData !== undefined ? setTimetoSec(inputData) : item;
          }
        });
        index++;
      });
    });

    // 子部品サイズ係数
    inputData = [];
    let childPartAreaIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    inputData = dataList?.[childPartAreaIndex + 1]?.split(',');
    //面積マスタ
    deviceData?.details?.childPartsCoeffAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('cm2', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });

    //重量マスタ
    let childPartWeightIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    deviceData?.details?.childPartsCoeffWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inputData = dataList?.[childPartWeightIndex + 2 + index]?.split(',');
        inVal = inputData[0]?.replace('kg', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
        //係数値
        deviceData?.details?.childPartsCoeff
          ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
          ?.areaCoeffList?.map((area, i) => {
            inVal = inputData[i + 1] !== undefined ? toDecimal(inputData[i + 1], 2) : area.value;
            area.value = inVal !== undefined ? inVal : area.value;
          });
      });

    // 段取設定の段取個数係数
    inputData = [];
    let dandoriQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
    );
    inputData = dataList?.[dandoriQtyIndex + 1]?.split(',');
    deviceData?.details?.prepQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('個', '');
        item.value = inVal !== undefined ? Number(inVal) : item.value;
      });
    inputData = dataList?.[dandoriQtyIndex + 2]?.split(',');
    deviceData?.details?.prepQtyCoeffItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row, index) => {
        inVal = inputData[index + 1] !== undefined ? toDecimal(inputData[index + 1], 2) : row.value;
        row.value = inVal !== undefined ? inVal : row.value;
      });

    // 加工速度(mm/min)
    inputData = [];
    let thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    inputData = dataList?.[thickIndex + 1]?.split(',');
    //マスタ
    deviceData?.details?.workSpeedThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('mm', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    //加工速度
    index = 0;
    let speedLenIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    if (deviceData?.details?.workSpeedThItems) {
      const usedIds = processMstRecord?.details?.processDetailTypes
        ?.filter((item) => item.isUsed)
        ?.map((item) => item.id);
      Object.keys(deviceData?.details?.workSpeedThItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key, index) => {
          inputData = dataList?.[speedLenIndex + 2 + index]?.split(',');
          let typeNm = processTypesList?.find((item) => item.id.toString() === key)?.name;
          if (typeNm === inputData[0]) {
            deviceData?.details?.workSpeedThItems[key]?.map((item, i) => {
              inVal = inputData[i + 1] !== undefined ? Number(inputData[i + 1]) : item.value;
              item.value = inVal !== undefined ? inVal : item.value;
            });
          }
          index++;
        });
    }

    // 仮付時間
    inputData = [];
    thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    inputData = dataList?.[thickIndex + 1]?.split(',');
    //マスタ
    deviceData?.details?.tempInstThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('t', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    // 仮付時間
    index = 0;
    speedLenIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    if (deviceData?.details?.tempInstThItems) {
      const usedIds = processMstRecord?.details?.processDetailTypes
        ?.filter((item) => item.isUsed)
        ?.map((item) => item.id);
      Object.keys(deviceData?.details?.tempInstThItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key, index) => {
          inputData = dataList?.[speedLenIndex + 2 + index]?.split(',');
          let typeNm = processTypesList?.find((item) => item.id.toString() === key)?.name;
          if (typeNm === inputData[0]) {
            deviceData?.details?.tempInstThItems[key]?.map((item, i) => {
              inVal = inputData[i + 1] !== undefined ? setTimetoSec(inputData[i + 1]) : item.value;
              item.value = inVal !== undefined ? inVal : item.value;
            });
          }
          index++;
        });
    }

    // 取付時間(板厚判断)
    inputData = [];
    thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    inputData = dataList?.[thickIndex + 1]?.split(',');
    //マスタ
    deviceData?.details?.instThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('t', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    // 取付時間
    index = 0;
    speedLenIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    if (deviceData?.details?.instThickThItems) {
      const usedIds = processMstRecord?.details?.processDetailTypes
        ?.filter((item) => item.isUsed)
        ?.map((item) => item.id);
      Object.keys(deviceData?.details?.instThickThItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key, index) => {
          inputData = dataList?.[speedLenIndex + 2 + index]?.split(',');
          let typeNm = processTypesList?.find((item) => item.id.toString() === key)?.name;
          if (typeNm === inputData[0]) {
            deviceData?.details?.instThickThItems[key]?.map((item, i) => {
              inVal = inputData[i + 1] !== undefined ? setTimetoSec(inputData[i + 1]) : item.value;
              item.value = inVal !== undefined ? inVal : item.value;
            });
          }
          index++;
        });
    }

    // 取付時間(サイズ判断)
    inputData = [];
    thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[7] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[7]
    );
    inputData = dataList?.[thickIndex + 1]?.split(',');
    //マスタ
    deviceData?.details?.instSizeThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('M', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    // 取付時間
    index = 0;
    speedLenIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[7] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[7]
    );
    if (deviceData?.details?.instSizeThItems) {
      const usedIds = processMstRecord?.details?.processDetailTypes
        ?.filter((item) => item.isUsed)
        ?.map((item) => item.id);
      Object.keys(deviceData?.details?.instSizeThItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key, index) => {
          inputData = dataList?.[speedLenIndex + 2 + index]?.split(',');
          let typeNm = processTypesList?.find((item) => item.id.toString() === key)?.name;
          if (typeNm === inputData[0]) {
            deviceData?.details?.instSizeThItems[key]?.map((item, i) => {
              inVal = inputData[i + 1] !== undefined ? setTimetoSec(inputData[i + 1]) : item.value;
              item.value = inVal !== undefined ? inVal : item.value;
            });
          }
          index++;
        });
    }

    // 溶接難易度
    inputData = [];
    thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[8] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[8]
    );
    inputData = dataList?.[thickIndex + 1]?.split(',');
    //マスタ
    deviceData?.details?.weldDifficultyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('t', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    // 溶接難易度
    index = 0;
    speedLenIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[8] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[8]
    );
    if (deviceData?.details?.weldDifficultyCoeffThItems) {
      const usedIds = processMstRecord?.details?.processDetailTypes
        ?.filter((item) => item.isUsed)
        ?.map((item) => item.id);
      Object.keys(deviceData?.details?.weldDifficultyCoeffThItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key, index) => {
          inputData = dataList?.[speedLenIndex + 2 + index]?.split(',');
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          if (typeNm === inputData[0]) {
            deviceData?.details?.weldDifficultyCoeffThItems[key]?.map((item, i) => {
              inVal = inputData[i + 1] !== undefined ? Number(inputData[i + 1]) : item.value;
              item.value = inVal !== undefined ? inVal : item.value;
            });
          }
          index++;
        });
    }

    // サイズ係数
    inputData = [];
    childPartAreaIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[9] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[9]
    );
    inputData = dataList?.[childPartAreaIndex + 1]?.split(',');
    //面積マスタ
    deviceData?.details?.prodSizeAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('cm2', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    //重量マスタ
    childPartWeightIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[9] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[9]
    );
    deviceData?.details?.prodSizeWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inputData = dataList?.[childPartWeightIndex + 2 + index]?.split(',');
        inVal = inputData[0]?.replace('kg', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
        //係数値
        deviceData?.details?.prodSizeItems
          ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
          ?.areaCoeffList?.map((area, i) => {
            inVal = inputData[i + 1] !== undefined ? toDecimal(inputData[i + 1], 2) : area.value;
            area.value = inVal !== undefined ? inVal : area.value;
          });
      });

    // 生産個数係数
    inputData = [];
    let quantityCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[10] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[10]
    );
    inputData = dataList?.[quantityCoeffIndex + 1]?.split(',');
    deviceData?.details?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('個', '');
        inVal = inVal !== undefined ? Number(inVal, 2) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    inputData = dataList?.[quantityCoeffIndex + 2]?.split(',');
    deviceData?.details?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        item.value = toDecimal(inputData[index + 1], 2);
      });

    return deviceData;
  };

  // 溶接仕上処理のCSV入力処理
  const weldFinishCSVImport = (deviceData, dataList, detailMenuTab) => {
    let inVal = '';
    let inputData = [];
    let index = 0;
    // チャージ金額(/h)
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let chargesItemIndex = chargesIndex + 1;
    const groupDisplayList = getProcessDetailTypesInfo(deviceData?.details?.charges, processMstRecord);
    Object.entries(groupDisplayList)?.map(([_, groupItem]) => {
      groupItem?.map((subItem) => {
        getChargesDetailValues(subItem?.id, deviceData?.details?.charges)?.map((item) => {
          if (parseInt(item) === subItem?.id) return;
          if (index > 0) {
            chargesItemIndex = chargesItemIndex + 3;
          }
          inputData = dataList?.[chargesItemIndex];
          if (inputData && (inputData == subItem?.name || inputData?.includes(subItem?.name))) {
            inputData = dataList?.[chargesItemIndex + 2]?.split(',');
            item.prep = inputData?.[0] ? parseInt(inputData?.[0]) : item.prep;
            item.work = inputData?.[1] ? parseInt(inputData?.[1]) : item.work;
          }
        });
        index++;
      });
    });

    // 段取設定
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    chargesItemIndex = chargesIndex + 1;
    inputData = [];
    index = 0;
    Object.entries(groupDisplayList)?.map(([_, groupItem]) => {
      groupItem?.map((subItem) => {
        let groupDetail = getPrepSettingDetailValues(subItem?.id, deviceData?.details?.prepSecs);
        groupDetail?.map((item, i) => {
          if (parseInt(item) === subItem?.id && i === 0) return;
          if (index > 0) {
            chargesItemIndex = chargesItemIndex + 2;
          }
          inputData = dataList?.[chargesItemIndex];
          if (inputData && (inputData == subItem?.name || inputData?.includes(subItem?.name))) {
            inputData = dataList?.[chargesItemIndex + 1];
            // IQX_WEBEST-301
            if (inputData?.split(',') !== undefined) {
              inputData = inputData?.split(',')?.[0];
            }
            deviceData.details.prepSecs[groupDetail[0]] = inputData !== undefined ? setTimetoSec(inputData) : item;
          }
        });
        index++;
      });
    });

    // 子部品サイズ係数
    inputData = [];
    let childPartAreaIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    inputData = dataList?.[childPartAreaIndex + 1]?.split(',');
    //面積マスタ
    deviceData?.details?.childPartsCoeffAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('cm2', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });

    //重量マスタ
    let childPartWeightIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    deviceData?.details?.childPartsCoeffWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inputData = dataList?.[childPartWeightIndex + 2 + index]?.split(',');
        inVal = inputData[0]?.replace('kg', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
        //係数値
        deviceData?.details?.childPartsCoeff
          ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
          ?.areaCoeffList?.map((area, i) => {
            inVal = inputData[i + 1] !== undefined ? toDecimal(inputData[i + 1], 2) : area.value;
            area.value = inVal !== undefined ? inVal : area.value;
          });
      });

    // 段取設定の段取個数係数
    inputData = [];
    let dandoriQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
    );
    inputData = dataList?.[dandoriQtyIndex + 1]?.split(',');
    deviceData?.details?.prepQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('個', '');
        item.value = inVal !== undefined ? Number(inVal) : item.value;
      });
    inputData = dataList?.[dandoriQtyIndex + 2]?.split(',');
    deviceData?.details?.prepQtyCoeffItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row, index) => {
        inVal = inputData[index + 1] !== undefined ? toDecimal(inputData[index + 1], 2) : row.value;
        row.value = inVal !== undefined ? inVal : row.value;
      });

    // 加工速度(mm/min)
    inputData = [];
    let thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    inputData = dataList?.[thickIndex + 1]?.split(',');
    //マスタ
    deviceData?.details?.workSpeedThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('mm', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    //加工速度
    index = 0;
    let speedLenIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    if (deviceData?.details?.workSpeedThItems) {
      const usedIds = processMstRecord?.details?.processDetailTypes
        ?.filter((item) => item.isUsed)
        ?.map((item) => item.id);
      Object.keys(deviceData?.details?.workSpeedThItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key, index) => {
          inputData = dataList?.[speedLenIndex + 2 + index]?.split(',');
          let typeNm = processTypesList?.find((item) => item.id.toString() === key)?.name;
          if (typeNm === inputData[0]) {
            deviceData?.details?.workSpeedThItems[key]?.map((item, i) => {
              inVal = inputData[i + 1] !== undefined ? Number(inputData[i + 1]) : item.value;
              item.value = inVal !== undefined ? inVal : item.value;
            });
          }
          index++;
        });
    }

    // 溶接難易度
    inputData = [];
    thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    inputData = dataList?.[thickIndex + 2]?.split(',');
    index = 0;
    // 溶接難易度
    if (deviceData?.details?.diffFactorCoeff) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(deviceData?.details?.diffFactorCoeff)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          inVal = inputData[index + 1];
          inVal = inVal !== undefined ? Number(inVal) : deviceData?.details?.diffFactorCoeff[parseInt(key)];
          deviceData.details.diffFactorCoeff[parseInt(key)] = inVal
            ? inVal
            : deviceData?.details?.diffFactorCoeff[parseInt(key)];
          index++;
        });
    }

    // 仕上有無　IQX_WEBEST-271 パラメータに現状持っている「仕上げ有無」（溶接種類ごとのチェックボックス）を取り除く。
    // inputData = [];
    // index = 0;
    // speedLenIndex = dataList?.findIndex(
    //   (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    // );
    // if (deviceData?.details?.difficultyCoeff) {
    //   const usedIds = yousetsuTypes?.details?.processDetailTypes?.filter((item) => item.isUsed)?.map((item) => item.id);
    //   inputData = dataList?.[speedLenIndex + 2]?.split(',');
    //   Object.keys(deviceData?.details?.difficultyCoeff)
    //     ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
    //     ?.map((key) => {
    //       inVal =
    //         inputData[index + 1] !== undefined
    //           ? JSON.parse(inputData[index + 1]?.toLowerCase()) // IQX_WEBEST-301
    //           : deviceData?.details?.difficultyCoeff[parseInt(key)];
    //       deviceData.details.difficultyCoeff[parseInt(key)] =
    //         inVal !== undefined ? inVal : deviceData?.details?.difficultyCoeff[parseInt(key)];
    //       index++;
    //     });
    // }

    // サイズ係数
    inputData = [];
    childPartAreaIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    inputData = dataList?.[childPartAreaIndex + 1]?.split(',');
    //面積マスタ
    deviceData?.details?.prodSizeAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('cm2', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    //重量マスタ
    childPartWeightIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    deviceData?.details?.prodSizeWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inputData = dataList?.[childPartWeightIndex + 2 + index]?.split(',');
        inVal = inputData[0]?.replace('kg', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
        //係数値
        deviceData?.details?.prodSizeItems
          ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
          ?.areaCoeffList?.map((area, i) => {
            inVal = inputData[i + 1] !== undefined ? toDecimal(inputData[i + 1], 2) : area.value;
            area.value = inVal !== undefined ? inVal : area.value;
          });
      });

    // 生産個数係数
    inputData = [];
    let quantityCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[7] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[7]
    );
    inputData = dataList?.[quantityCoeffIndex + 1]?.split(',');
    deviceData?.details?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('個', '');
        inVal = inVal !== undefined ? Number(inVal, 2) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    inputData = dataList?.[quantityCoeffIndex + 2]?.split(',');
    deviceData?.details?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        item.value = toDecimal(inputData[index + 1], 2);
      });

    return deviceData;
  };

  // ２次加工処理のCSV入力処理
  const secondaryWorkCSVImport = (deviceData, dataList, detailMenuTab) => {
    let inVal = '';
    let inputData = [];
    let index = 0;
    // チャージ金額(/h)
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let chargesItemIndex = chargesIndex + 1;
    const groupDisplayList = getProcessDetailTypesInfo(deviceData?.details?.charges, processMstRecord);
    Object.entries(groupDisplayList)?.map(([_, groupItem]) => {
      groupItem?.map((subItem) => {
        getChargesDetailValues(subItem?.id, deviceData?.details?.charges)?.map((item, i) => {
          if (parseInt(item) === subItem?.id && i === 0) return;
          if (index > 0) {
            chargesItemIndex = chargesItemIndex + 3;
          }
          inputData = dataList?.[chargesItemIndex];
          if (inputData && (inputData == subItem?.name || inputData?.includes(subItem?.name))) {
            inputData = dataList?.[chargesItemIndex + 2]?.split(',');
            item.prep = inputData?.[0] ? parseInt(inputData?.[0]) : item?.prep;
            item.work = inputData?.[1] ? parseInt(inputData?.[1]) : item?.work;
          }
        });
        index++;
      });
    });

    // 段取設定
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    chargesItemIndex = chargesIndex + 1;
    inputData = [];
    index = 0;
    Object.entries(groupDisplayList)?.map(([_, groupItem]) => {
      groupItem?.map((subItem) => {
        let groupDetail = getPrepSettingDetailValues(subItem?.id, deviceData?.details?.prepSecs);
        groupDetail?.map((item, i) => {
          if (parseInt(item) === subItem?.id && i === 0) return;
          if (index > 0) {
            chargesItemIndex = chargesItemIndex + 2;
          }
          inputData = dataList?.[chargesItemIndex];
          if (inputData && (inputData == subItem?.name || inputData?.includes(subItem?.name))) {
            inputData = dataList?.[chargesItemIndex + 1];
            // IQX_WEBEST-301
            if (inputData?.split(',') !== undefined) {
              inputData = inputData?.split(',')?.[0];
            }
            deviceData.details.prepSecs[groupDetail[0]] = inputData !== undefined ? setTimetoSec(inputData) : item;
          }
        });
        index++;
      });
    });

    // 子部品サイズ係数
    inputData = [];
    let childPartAreaIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    inputData = dataList?.[childPartAreaIndex + 1]?.split(',');
    //面積マスタ
    deviceData?.details?.childPartsCoeffAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('cm2', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });

    //重量マスタ
    let childPartWeightIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    deviceData?.details?.childPartsCoeffWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inputData = dataList?.[childPartWeightIndex + 2 + index]?.split(',');
        inVal = inputData[0]?.replace('kg', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
        //係数値
        deviceData?.details?.childPartsCoeff
          ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
          ?.areaCoeffList?.map((area, i) => {
            inVal = inputData[i + 1] !== undefined ? toDecimal(inputData[i + 1], 2) : area.value;
            area.value = inVal !== undefined ? inVal : area.value;
          });
      });

    // 段取設定の段取個数係数
    inputData = [];
    let dandoriQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
    );
    inputData = dataList?.[dandoriQtyIndex + 1]?.split(',');
    deviceData?.details?.prepQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('個', '');
        item.value = inVal !== undefined ? Number(inVal) : item.value;
      });
    inputData = dataList?.[dandoriQtyIndex + 2]?.split(',');
    deviceData?.details?.prepQtyCoeffItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row, index) => {
        inVal = inputData[index + 1] !== undefined ? toDecimal(inputData[index + 1], 2) : row.value;
        row.value = inVal !== undefined ? inVal : row.value;
      });

    // 加工時間(mm/min)
    // 加工種類/サイズ
    inputData = [];
    let thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    inputData = dataList?.[thickIndex + 2]?.split(',');
    // 簡易入力
    //板厚マスタ
    deviceData?.details?.sizeThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('M', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    index = 0;
    if (deviceData?.details?.workTimeSizeThItems) {
      Object.keys(deviceData?.details?.workTimeSizeThItems)
        .filter(([sizeDataKey]) => getProcessDetailTypeName(parseInt(sizeDataKey), processMstRecord) !== undefined)
        ?.map(([key, item], rowIndex) => {
          let typeNm = getProcessDetailTypeName(parseInt(key), processMstRecord);
          inputData = dataList?.[thickIndex + 3 + index]?.split(',');
          if (typeNm === inputData[0]) {
            deviceData?.details?.workTimeSizeThItems[key]?.map((item, i) => {
              inVal = inputData[i + 1] !== undefined ? setTimetoSec(inputData[i + 1]) : item.value;
              item.value = inVal !== undefined ? inVal : item.value;
            });
          }
          index++;
        });
    }

    // 詳細入力
    //板厚マスタ
    thickIndex = thickIndex + index + 3;
    inputData = dataList?.[thickIndex];
    let detailIndex = 0;
    let materialTypeIndex = 0;
    if (inputData === '詳細入力' || inputData?.includes('詳細入力')) {
      thickIndex = thickIndex + 3;
      if (deviceData?.details?.workTimeSizeThItems) {
        Object.keys(deviceData?.details?.workTimeSizeThItems)
          .filter(([sizeDataKey]) => getProcessDetailTypeName(parseInt(sizeDataKey), processMstRecord) !== undefined)
          ?.map(([key, item], rowIndex) => {
            detailIndex = 2;
            const usedItems = materialTypes?.filter((item) => item.info.isUsed);
            let typeNm = getProcessDetailTypeName(parseInt(key), processMstRecord);
            inputData = dataList?.[thickIndex + materialTypeIndex]?.split(',');
            const detailRowIndex = thickIndex + materialTypeIndex;
            if (typeNm === inputData[0]) {
              let usedItemIndex = 0;
              usedItems?.map((materialTypeItem) => {
                inputData = dataList?.[detailRowIndex + usedItemIndex]?.split(',');
                deviceData?.details?.workTimeSizeThItems[key]?.map((item, workTimeSizeIndex) => {
                  if (workTimeSizeIndex > 0)
                    detailIndex = item?.workTimeThickThItems[materialTypeItem.id]?.length + workTimeSizeIndex;
                  if (item?.workTimeThickThItems) {
                    item?.workTimeThickThItems[materialTypeItem.id]?.map((item, index) => {
                      item.value =
                        inputData[detailIndex + index] !== undefined
                          ? setTimetoSec(inputData[detailIndex + index])
                          : item.value;
                    });
                  }
                });
                usedItemIndex++;
                materialTypeIndex++;
              });
            }
          });
      }
    }

    // 加工時間(mm/min)
    // 加工種類/mm
    inputData = [];
    thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    inputData = dataList?.[thickIndex + 1]?.split(',');
    // 簡易入力
    //板厚マスタ
    deviceData?.details?.mmThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('mm', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    index = 0;
    Object.keys(deviceData?.details?.workTimeSizeHoleThItems)
      .filter(([sizeDataKey]) => getProcessDetailTypeName(parseInt(sizeDataKey), processMstRecord) !== undefined)
      ?.map(([key, item], rowIndex) => {
        let typeNm = getProcessDetailTypeName(parseInt(key), processMstRecord);
        inputData = dataList?.[thickIndex + 3 + index]?.split(',');
        if (typeNm === inputData[0]) {
          deviceData?.details?.workTimeSizeHoleThItems[key]?.map((item, i) => {
            inVal = inputData[i + 1] !== undefined ? setTimetoSec(inputData[i + 1]) : item.value;
            item.value = inVal !== undefined ? inVal : item.value;
          });
        }
        index++;
      });

    // 詳細入力
    //板厚マスタ
    thickIndex = thickIndex + index + 3;
    inputData = dataList?.[thickIndex];
    detailIndex = 0;
    materialTypeIndex = 0;
    if (inputData === '詳細入力' || inputData?.includes('詳細入力')) {
      thickIndex = thickIndex + 3;
      if (deviceData?.details?.workTimeSizeHoleThItems) {
        Object.keys(deviceData?.details?.workTimeSizeHoleThItems)
          .filter(([sizeDataKey]) => getProcessDetailTypeName(parseInt(sizeDataKey), processMstRecord) !== undefined)
          ?.map(([key, item], rowIndex) => {
            detailIndex = 2;
            let typeNm = getProcessDetailTypeName(parseInt(key), processMstRecord);
            inputData = dataList?.[thickIndex + rowIndex]?.split(',');
            if (typeNm === inputData[0]) {
              const usedItems = materialTypes?.filter((item) => item.info.isUsed);
              usedItems?.map((materialTypeItem) => {
                inputData = dataList?.[thickIndex + materialTypeIndex + rowIndex]?.split(',');
                deviceData?.details?.workTimeSizeHoleThItems[key]?.map((item, workTimeSizeIndex) => {
                  if (item?.workTimeThickThItems) {
                    Object.keys(item?.workTimeThickThItems)
                      ?.filter((listkey) => parseInt(listkey) === materialTypeItem.id)
                      ?.map((key) => {
                        item?.workTimeThickThItems[key]?.map((item, index) => {
                          item.value =
                            inputData[detailIndex] !== undefined ? setTimetoSec(inputData[detailIndex]) : item.value;
                        });
                      });
                  }
                  detailIndex++;
                });
                materialTypeIndex++;
              });
            }
          });
      }
    }

    // 加工補正時間
    inputData = [];
    thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    inputData = dataList?.[thickIndex + 1]?.split(',');
    //マスタ
    deviceData?.details?.modifyTimeQtyThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    // 加工補正時間-値
    inputData = dataList?.[thickIndex + 2]?.split(',');
    deviceData?.details?.modifyTimeItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1];
        inVal = inVal !== undefined ? setTimetoSec(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });

    // 加工補正時間係数
    childPartAreaIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[7] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[7]
    );
    inputData = dataList?.[childPartAreaIndex + 1]?.split(',');
    //面積マスタ
    deviceData?.details?.mtAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('cm2', '');
        inVal = inVal !== undefined ? Number(inVal, 2) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });

    //重量マスタ
    childPartWeightIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[7] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[7]
    );
    deviceData?.details?.mtWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inputData = dataList?.[childPartWeightIndex + 2 + index]?.split(',');
        inVal = inputData[index]?.replace('kg', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
        //係数値
        deviceData?.details?.mtTimeCoeffItems
          ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
          ?.areaCoeffList?.map((area, i) => {
            inVal = inputData[i + 1] !== undefined ? toDecimal(inputData[i + 1], 2) : area.value;
            area.value = inVal !== undefined ? inVal : area.value;
          });
      });

    // サイズ係数
    inputData = [];
    childPartAreaIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[8] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[8]
    );
    inputData = dataList?.[childPartAreaIndex + 1]?.split(',');
    //面積マスタ
    deviceData?.details?.prodSizeAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('cm2', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    //重量マスタ
    childPartWeightIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[8] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[8]
    );
    deviceData?.details?.prodSizeWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inputData = dataList?.[childPartWeightIndex + 2 + index]?.split(',');
        inVal = inputData[0]?.replace('kg', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
        //係数値
        deviceData?.details?.prodSizeItems
          ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
          ?.areaCoeffList?.map((area, i) => {
            inVal = inputData[i + 1] !== undefined ? toDecimal(inputData[i + 1], 2) : area.value;
            area.value = inVal !== undefined ? inVal : area.value;
          });
      });

    // 生産個数係数
    inputData = [];
    let quantityCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[9] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[9]
    );
    inputData = dataList?.[quantityCoeffIndex + 1]?.split(',');
    deviceData?.details?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('個', '');
        inVal = inVal !== undefined ? Number(inVal, 2) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    inputData = dataList?.[quantityCoeffIndex + 2]?.split(',');
    deviceData?.details?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        item.value = toDecimal(inputData[index + 1], 2);
      });

    return deviceData;
  };

  // パンチ処理のCSV入力処理
  const punchCSVImport = (deviceData, dataList, detailMenuTab) => {
    let inVal = '';
    let inputData = [];
    // チャージ金額(/h)
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let dataTitle = dataList[chargesIndex + 1];
    let amountData = dataList[chargesIndex + 2]?.split(',');
    deviceData.details.charges.prep = amountData?.[0] ? amountData?.[0] : deviceData?.details?.charges?.prep;
    deviceData.details.charges.work = amountData?.[1] ? amountData?.[1] : deviceData?.details?.charges?.work;
    deviceData.details.charges.twoStepHolework = amountData?.[2]
      ? amountData?.[2]
      : deviceData?.details?.charges?.twoStepHolework;
    deviceData.details.charges.jointSeparate = amountData?.[3]
      ? amountData?.[3]
      : deviceData?.details?.charges?.jointSeparate;

    // 段取設定
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    dataTitle = dataList[chargesIndex + 1];
    let dandoriTime = dataList[chargesIndex + 2];
    // IQX_WEBEST-301
    if (dandoriTime?.split(',') !== undefined) {
      dandoriTime = dandoriTime?.split(',')?.[0];
    }
    deviceData.details.prepBasicTime =
      dandoriTime !== undefined ? setTimetoSec(dandoriTime) : deviceData?.details?.prepBasicTime;

    // 段取設定の段取個数係数
    inputData = [];
    let dandoriQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    inputData = dataList?.[dandoriQtyIndex + 1]?.split(',');
    deviceData?.details?.prepQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('個', '');
        item.value = inVal !== undefined ? Number(inVal) : item.value;
      });
    inputData = dataList?.[dandoriQtyIndex + 2]?.split(',');
    deviceData?.details?.prepQtyCoeffItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row, index) => {
        inVal = inputData[index + 1] !== undefined ? toDecimal(inputData[index + 1], 2) : row.value;
        row.value = inVal !== undefined ? inVal : row.value;
      });

    // 段取設定の金型段取時間
    inputData = [];
    let thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
    );
    inputData = dataList?.[thickIndex + 1]?.split(',');
    //マスタ
    deviceData?.details?.prepMoldQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('本', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    inputData = dataList?.[thickIndex + 2]?.split(',');
    deviceData?.details?.prepMoldQtyCoeffItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1];
        inVal = inVal !== undefined ? setTimetoSec(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });

    // 材料配置
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    dataTitle = dataList[chargesIndex + 1];
    amountData = dataList[chargesIndex + 2]?.split(',');
    deviceData.details.materialArrange.graspAllowanceX = amountData?.[0]
      ? amountData?.[0]
      : deviceData?.details?.materialArrange?.graspAllowanceX;
    deviceData.details.materialArrange.graspAllowanceY = amountData?.[1]
      ? amountData?.[1]
      : deviceData?.details?.materialArrange?.graspAllowanceY;
    deviceData.details.materialArrange.xWidth = amountData?.[2]
      ? amountData?.[2]
      : deviceData?.details?.materialArrange?.xWidth;
    deviceData.details.materialArrange.yWidth = amountData?.[3]
      ? amountData?.[3]
      : deviceData?.details?.materialArrange?.yWidth;
    deviceData.details.materialArrange.workRangeX = amountData?.[4]
      ? amountData?.[4]
      : deviceData?.details?.materialArrange?.workRangeX;
    deviceData.details.materialArrange.workRangeY = amountData?.[5]
      ? amountData?.[5]
      : deviceData?.details?.materialArrange?.workRangeY;
    deviceData.details.materialArrange.rangeAdditionalTime = amountData?.[6]
      ? amountData?.[6]
      : deviceData?.details?.materialArrange?.rangeAdditionalTime;

    // パンチ条件
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    dataTitle = dataList[chargesIndex + 1];
    amountData = dataList[chargesIndex + 2]?.split(',');
    deviceData.details.punches.punchHoleDia = amountData?.[0]
      ? amountData?.[0]
      : deviceData?.details?.punches?.punchHoleDia;
    deviceData.details.punches.punchRectanglePer = amountData?.[1]
      ? amountData?.[1]
      : deviceData?.details?.punches?.punchRectanglePer;
    deviceData.details.punches.twoPitch = amountData?.[2] ? amountData?.[2] : deviceData?.details?.punches?.twoPitch;

    // 1パンチ加工時間
    inputData = [];
    thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    inputData = dataList?.[thickIndex + 1]?.split(',');
    //マスタ
    deviceData?.details?.punchWorkTimeThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('t', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    //1パンチ加工時間
    let index = 0;
    let speedLenIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    if (deviceData?.details?.punchWorkTimeItems) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(deviceData?.details?.punchWorkTimeItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          inputData = dataList?.[speedLenIndex + 2 + index]?.split(',');
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          if (typeNm === inputData[0]) {
            deviceData?.details?.punchWorkTimeItems[key]?.map((item, i) => {
              inVal = inputData[i + 1] !== undefined ? Number(inputData[i + 1]) : item.value;
              item.value = inVal !== undefined ? inVal : item.value;
            });
          }
          index++;
        });
    }

    // 送り速度(mm/min)
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[7] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[7]
    );
    dataTitle = dataList[chargesIndex + 1];
    amountData = dataList[chargesIndex + 2];
    // IQX_WEBEST-301
    if (amountData?.split(',') !== undefined) {
      amountData = amountData?.split(',')?.[0];
    }
    deviceData.details.feedSpeed = amountData !== undefined ? amountData : deviceData?.details?.feedSpeed;

    // ローラー/ホイール/ケガキ金型　加工速度(mm/sec)
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[8] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[8]
    );
    dataTitle = dataList[chargesIndex + 1];
    amountData = dataList[chargesIndex + 2];
    // IQX_WEBEST-301
    if (amountData?.split(',') !== undefined) {
      amountData = amountData?.split(',')?.[0];
    }
    deviceData.details.workSpeed = amountData !== undefined ? amountData : deviceData?.details?.workSpeed;

    // 追抜き条件
    inputData = [];
    thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[9] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[9]
    );
    inputData = dataList?.[thickIndex + 2]?.split(',');
    //マスタ
    deviceData?.details?.odOvertakeItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1];
        item.value = inVal !== undefined ? Number(inVal) : item.value;
      });

    // サイズ係数
    inputData = [];
    let childPartAreaIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[10] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[10]
    );
    inputData = dataList?.[childPartAreaIndex + 1]?.split(',');
    //面積マスタ
    deviceData?.details?.prodSizeAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('cm2', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    //重量マスタ
    let childPartWeightIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[10] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[10]
    );
    deviceData?.details?.prodSizeWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inputData = dataList?.[childPartWeightIndex + 2 + index]?.split(',');
        inVal = inputData[0]?.replace('kg', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
        //係数値
        deviceData?.details?.prodSizeItems
          ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
          ?.areaCoeffList?.map((area, i) => {
            inVal = inputData[i + 1] !== undefined ? toDecimal(inputData[i + 1], 2) : area.value;
            area.value = inVal !== undefined ? inVal : area.value;
          });
      });

    // 生産個数係数
    inputData = [];
    let quantityCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[11] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[11]
    );
    inputData = dataList?.[quantityCoeffIndex + 1]?.split(',');
    deviceData?.details?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('個', '');
        inVal = inVal !== undefined ? Number(inVal, 2) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    inputData = dataList?.[quantityCoeffIndex + 2]?.split(',');
    deviceData?.details?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        item.value = toDecimal(inputData[index + 1], 2);
      });

    // 2工程穴加工時間
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[12] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[12]
    );
    dataTitle = dataList[chargesIndex + 1];
    dandoriTime = dataList[chargesIndex + 2];
    // IQX_WEBEST-301
    if (dandoriTime?.split(',') !== undefined) {
      dandoriTime = dandoriTime?.split(',')?.[0];
    }
    deviceData.details.twoStepHoleworkTime =
      dandoriTime !== undefined ? setTimetoSec(dandoriTime) : deviceData?.details?.twoStepHoleworkTime;

    // ジョイントバラシ
    inputData = [];
    thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[13] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[13]
    );
    inputData = dataList?.[thickIndex + 1]?.split(',');
    //マスタ
    deviceData?.details?.jointQtySizeThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('cm2', '');
        item.value = inVal !== undefined ? inVal : item.value;
      });
    inputData = dataList?.[thickIndex + 2]?.split(',');
    //マスタ
    deviceData?.details?.jointQtyItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1];
        item.value = inVal !== undefined ? Number(inVal) : item.value;
      });

    // 材質区分/板厚
    inputData = [];
    inputData = dataList?.[thickIndex + 3]?.split(',');
    //マスタ
    deviceData?.details?.jointSeparateThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('t', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    index = 0;
    if (deviceData?.details?.jointSeparateItems) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(deviceData?.details?.jointSeparateItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          inputData = dataList?.[thickIndex + 4 + index]?.split(',');
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          if (typeNm === inputData[0]) {
            deviceData?.details?.jointSeparateItems[key]?.map((item, i) => {
              inVal = inputData[i + 1] !== undefined ? Number(inputData[i + 1]) : item.value;
              item.value = inVal !== undefined ? inVal : item.value;
            });
          }
          index++;
        });
    }

    return deviceData;
  };

  // レーザ処理のCSV入力処理
  const laserCSVImport = (deviceData, dataList, detailMenuTab) => {
    let inVal = '';
    let inputData = [];
    // チャージ金額(/h)
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let dataTitle = dataList[chargesIndex + 1];
    let amountData = dataList[chargesIndex + 2]?.split(',');
    deviceData.details.charges.prep = amountData?.[0] ? amountData?.[0] : deviceData?.details?.charges?.prep;
    deviceData.details.charges.work = amountData?.[1] ? amountData?.[1] : deviceData?.details?.charges?.work;
    deviceData.details.charges.twoStepHolework = amountData?.[2]
      ? amountData?.[2]
      : deviceData?.details?.charges?.twoStepHolework;
    deviceData.details.charges.jointSeparate = amountData?.[3]
      ? amountData?.[3]
      : deviceData?.details?.charges?.jointSeparate;

    // 段取設定
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    dataTitle = dataList[chargesIndex + 1];
    let dandoriTime = dataList[chargesIndex + 2];
    // IQX_WEBEST-301
    if (dandoriTime?.split(',') !== undefined) {
      dandoriTime = dandoriTime?.split(',')?.[0];
    }
    deviceData.details.prepBasicTime =
      dandoriTime !== undefined ? setTimetoSec(dandoriTime) : deviceData?.details?.prepBasicTime;

    // 段取設定の段取個数係数
    inputData = [];
    let dandoriQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    inputData = dataList?.[dandoriQtyIndex + 1]?.split(',');
    deviceData?.details?.prepQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('個', '');
        item.value = inVal !== undefined ? Number(inVal) : item.value;
      });
    inputData = dataList?.[dandoriQtyIndex + 2]?.split(',');
    deviceData?.details?.prepQtyCoeffItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row, index) => {
        inVal = inputData[index + 1] !== undefined ? toDecimal(inputData[index + 1], 2) : row.value;
        row.value = inVal !== undefined ? inVal : row.value;
      });

    // 材料配置
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
    );
    dataTitle = dataList[chargesIndex + 1];
    amountData = dataList[chargesIndex + 2]?.split(',');
    deviceData.details.materialArrange.graspAllowanceX = amountData?.[0]
      ? amountData?.[0]
      : deviceData?.details?.materialArrange?.graspAllowanceX;
    deviceData.details.materialArrange.graspAllowanceY = amountData?.[1]
      ? amountData?.[1]
      : deviceData?.details?.materialArrange?.graspAllowanceY;
    deviceData.details.materialArrange.xWidth = amountData?.[2]
      ? amountData?.[2]
      : deviceData?.details?.materialArrange?.xWidth;
    deviceData.details.materialArrange.yWidth = amountData?.[3]
      ? amountData?.[3]
      : deviceData?.details?.materialArrange?.yWidth;
    deviceData.details.materialArrange.workRangeX = amountData?.[4]
      ? amountData?.[4]
      : deviceData?.details?.materialArrange?.workRangeX;
    deviceData.details.materialArrange.workRangeY = amountData?.[5]
      ? amountData?.[5]
      : deviceData?.details?.materialArrange?.workRangeY;
    deviceData.details.materialArrange.rangeAdditionalTime = amountData?.[6]
      ? amountData?.[6]
      : deviceData?.details?.materialArrange?.rangeAdditionalTime;

    // 1ピアス加工時間
    inputData = [];
    let thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    inputData = dataList?.[thickIndex + 1]?.split(',');
    //マスタ
    deviceData?.details?.piercingTimeThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('t', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    //1ピアス加工時間
    let index = 0;
    let speedLenIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    if (deviceData?.details?.piercingTimeThItems) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(deviceData?.details?.piercingTimeThItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          inputData = dataList?.[speedLenIndex + 2 + index]?.split(',');
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          if (typeNm === inputData[0]) {
            deviceData?.details?.piercingTimeThItems[key]?.map((item, i) => {
              inVal = inputData[i + 1] !== undefined ? Number(inputData[i + 1]) : item.value;
              item.value = inVal !== undefined ? inVal : item.value;
            });
          }
          index++;
        });
    }

    // 加工速度(mm/min)
    inputData = [];
    thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    inputData = dataList?.[thickIndex + 2]?.split(',');
    // 簡易入力
    //板厚マスタ
    deviceData?.details?.workSpeedThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('t', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    index = 0;
    if (deviceData?.details?.workSpeedItems) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(deviceData?.details?.workSpeedItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          inputData = dataList?.[thickIndex + 3 + index]?.split(',');
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          if (typeNm === inputData[0]) {
            deviceData?.details?.workSpeedItems[key]?.map((item, i) => {
              inVal = inputData[i + 1] !== undefined ? Number(inputData[i + 1]) : item.value;
              item.value = inVal !== undefined ? inVal : item.value;
            });
          }
          index++;
        });
    }
    // 詳細入力
    //板厚マスタ
    thickIndex = thickIndex + index + 3;
    inputData = dataList?.[thickIndex];
    let detailIndex = 0;
    let materialTypeIndex = 0;
    if (inputData === '詳細入力' || inputData?.includes('詳細入力')) {
      thickIndex = thickIndex + 2;
      if (deviceData?.details?.workSpeedItems) {
        const usedItems = materialTypes?.filter((item) => item.info.isUsed);
        const usedIds = usedItems?.map((item) => item.id);
        Object.keys(deviceData.details?.workSpeedItems)
          ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
          ?.map((key) => {
            let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
            for (let i = 0; i < 3; i++) {
              inputData = dataList?.[thickIndex + materialTypeIndex + i]?.split(',');
              if (typeNm === inputData[0]) {
                if (inputData[1] === '丸穴径(Φ) (≦)') {
                  detailIndex = 0;
                  deviceData.details?.workSpeedItems[key]?.map((item, index) => {
                    item?.roundHoleDiaThItems?.map((roundItem, i) => {
                      roundItem.value =
                        inputData[detailIndex + 2] !== undefined ? Number(inputData[detailIndex + 2]) : roundItem.value;
                      detailIndex++;
                    });
                  });
                } else if (inputData[1] === '加工周長(mm)(≦)') {
                  detailIndex = 0;
                  deviceData.details?.workSpeedItems[key]?.map((item, index) => {
                    item?.workLenThItems?.map((roundItem, i) => {
                      roundItem.value =
                        inputData[detailIndex + 2] !== undefined ? Number(inputData[detailIndex + 2]) : roundItem.value;
                      detailIndex++;
                    });
                  });
                } else if (inputData[1] === '速度(mm/分)') {
                  detailIndex = 0;
                  deviceData.details?.workSpeedItems[key]?.map((item, index) => {
                    item?.lenThItems?.map((roundItem, i) => {
                      roundItem.value =
                        inputData[detailIndex + 2] !== undefined ? Number(inputData[detailIndex + 2]) : roundItem.value;
                      detailIndex++;
                    });
                  });
                }
              }
              materialTypeIndex++;
            }
          });
      }
    }

    // ケガキ速度,送り速度
    inputData = [];
    thickIndex = dataList?.findIndex(
      (item, index) =>
        item === detailMenuTab[6] ||
        (dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]?.split(',')?.[0] &&
          dataList?.[index]?.split(',')?.[1] === detailMenuTab[6]?.split(',')?.[1])
    );
    inputData = dataList?.[thickIndex + 1]?.split(',');
    deviceData.details.scribingSpeed =
      inputData[0] !== undefined ? Number(inputData[0]) : deviceData?.details?.scribingSpeed;
    deviceData.details.feedSpeed = inputData[1] !== undefined ? Number(inputData[1]) : deviceData?.details?.feedSpeed;

    // 加工速度係数
    inputData = [];
    thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[7] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[7]
    );
    inputData = dataList?.[thickIndex + 1]?.split(',');
    //マスタ
    deviceData?.details?.workSpeedCoeffLengthThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('mm', '');
        item.value = inVal !== undefined ? Number(inVal) : item.value;
      });
    //内加工
    inputData = dataList?.[thickIndex + 2]?.split(',');
    deviceData?.details?.workSpeedCoeffInternalWorkItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1];
        item.value = inVal !== undefined ? Number(inVal) : item.value;
      });
    //外加工
    inputData = dataList?.[thickIndex + 3]?.split(',');
    deviceData?.details?.workSpeedCoeffExternalWorkItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1];
        item.value = inVal !== undefined ? Number(inVal) : item.value;
      });

    // ガス係数
    inputData = [];
    thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[8] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[8]
    );
    inputData = dataList?.[thickIndex + 2]?.split(',');
    deviceData?.details?.gasCoeffListItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1];
        item.value = inVal !== undefined ? toDecimal(inVal, 2) : item.value;
      });

    // 材質区分/板厚
    inputData = [];
    inputData = dataList?.[thickIndex + 3]?.split(',');
    //マスタ
    deviceData?.details?.gasCoeffThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('t', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    index = 0;
    if (deviceData?.details?.gasCoeffThItems) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(deviceData?.details?.gasCoeffThItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          inputData = dataList?.[thickIndex + 4 + index]?.split(',');
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          if (typeNm === inputData[0]) {
            deviceData?.details?.gasCoeffThItems[key]?.map((item, i) => {
              inVal = inputData[i + 1] !== undefined ? inputData[i + 1] : '';
              let gasType = deviceData?.details?.gasCoeffThMaster?.filter(
                (item) => !item.isOutOfRange && item.name === inVal
              );
              if (gasType?.length > 0) {
                item.value = gasType[0].no;
              }
            });
          }
          index++;
        });
    }

    // サイズ係数
    inputData = [];
    let childPartAreaIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[9] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[9]
    );
    inputData = dataList?.[childPartAreaIndex + 1]?.split(',');
    //面積マスタ
    deviceData?.details?.prodSizeAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('cm2', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    //重量マスタ
    let childPartWeightIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[9] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[9]
    );
    deviceData?.details?.prodSizeWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inputData = dataList?.[childPartWeightIndex + 2 + index]?.split(',');
        inVal = inputData[0]?.replace('kg', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
        //係数値
        deviceData?.details?.prodSizeItems
          ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
          ?.areaCoeffList?.map((area, i) => {
            inVal = inputData[i + 1] !== undefined ? toDecimal(inputData[i + 1], 2) : area.value;
            area.value = inVal !== undefined ? inVal : area.value;
          });
      });

    // 生産個数係数
    inputData = [];
    let quantityCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[10] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[10]
    );
    inputData = dataList?.[quantityCoeffIndex + 1]?.split(',');
    deviceData?.details?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('個', '');
        inVal = inVal !== undefined ? toDecimal(inVal, 2) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    inputData = dataList?.[quantityCoeffIndex + 2]?.split(',');
    deviceData?.details?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        item.value = toDecimal(inputData[index + 1], 2);
      });

    // 2工程穴加工時間
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[11] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[11]
    );
    dataTitle = dataList[chargesIndex + 1];
    dandoriTime = dataList[chargesIndex + 2];
    // IQX_WEBEST-301
    if (dandoriTime?.split(',') !== undefined) {
      dandoriTime = dandoriTime?.split(',')?.[0];
    }
    deviceData.details.twoStepHoleworkTime =
      dandoriTime !== undefined ? setTimetoSec(dandoriTime) : deviceData?.details?.twoStepHoleworkTime;

    // ジョイントバラシ
    inputData = [];
    thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[12] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[12]
    );
    inputData = dataList?.[thickIndex + 1]?.split(',');
    //マスタ
    deviceData?.details?.jointQtySizeThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('cm2', '');
        item.value = inVal !== undefined ? inVal : item.value;
      });
    inputData = dataList?.[thickIndex + 2]?.split(',');
    //マスタ
    deviceData?.details?.jointQtyItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1];
        item.value = inVal !== undefined ? Number(inVal) : item.value;
      });

    // 材質区分/板厚
    inputData = [];
    inputData = dataList?.[thickIndex + 3]?.split(',');
    //マスタ
    deviceData?.details?.jointSeparateThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('t', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    index = 0;
    if (deviceData?.details?.jointSeparateItems) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(deviceData?.details?.jointSeparateItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          inputData = dataList?.[thickIndex + 4 + index]?.split(',');
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          if (typeNm === inputData[0]) {
            deviceData?.details?.jointSeparateItems[key]?.map((item, i) => {
              inVal = inputData[i + 1] !== undefined ? Number(inputData[i + 1]) : item.value;
              item.value = inVal !== undefined ? inVal : item.value;
            });
          }
          index++;
        });
    }

    return deviceData;
  };

  // 複合機処理のCSV入力処理
  const combiCSVImport = (deviceData, dataList, detailMenuTab) => {
    let inVal = '';
    let inputData = [];
    // チャージ金額(/h)
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let dataTitle = dataList[chargesIndex + 1];
    let amountData = dataList[chargesIndex + 2]?.split(',');
    deviceData.details.charges.prep = amountData?.[0] ? amountData?.[0] : deviceData?.details?.charges?.prep;
    deviceData.details.charges.punchWork = amountData?.[1] ? amountData?.[1] : deviceData?.details?.charges?.punchWork;
    deviceData.details.charges.laserWork = amountData?.[2] ? amountData?.[2] : deviceData?.details?.charges?.laserWork;
    deviceData.details.charges.twoStepHolework = amountData?.[3]
      ? amountData?.[3]
      : deviceData?.details?.charges?.twoStepHolework;
    deviceData.details.charges.jointSeparate = amountData?.[4]
      ? amountData?.[4]
      : deviceData?.details?.charges?.jointSeparate;

    // 段取設定
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    dataTitle = dataList[chargesIndex + 1];
    let dandoriTime = dataList[chargesIndex + 2];
    // IQX_WEBEST-301
    if (dandoriTime?.split(',') !== undefined) {
      dandoriTime = dandoriTime?.split(',')?.[0];
    }
    deviceData.details.prepBasicTime =
      dandoriTime !== undefined ? setTimetoSec(dandoriTime) : deviceData?.details?.prepBasicTime;

    // 段取設定の段取個数係数
    inputData = [];
    let dandoriQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    inputData = dataList?.[dandoriQtyIndex + 1]?.split(',');
    deviceData?.details?.prepQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('個', '');
        item.value = inVal !== undefined ? Number(inVal) : item.value;
      });
    inputData = dataList?.[dandoriQtyIndex + 2]?.split(',');
    deviceData?.details?.prepQtyCoeffItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((row, index) => {
        inVal = inputData[index + 1] !== undefined ? toDecimal(inputData[index + 1], 2) : row.value;
        row.value = inVal !== undefined ? inVal : row.value;
      });

    // 段取設定の金型段取時間
    inputData = [];
    let thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
    );
    inputData = dataList?.[thickIndex + 1]?.split(',');
    //マスタ
    deviceData?.details?.prepMoldQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('本', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    inputData = dataList?.[thickIndex + 2]?.split(',');
    deviceData?.details?.prepMoldQtyCoeffItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1];
        inVal = inVal !== undefined ? setTimetoSec(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });

    // 材料配置
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    dataTitle = dataList[chargesIndex + 1];
    amountData = dataList[chargesIndex + 2]?.split(',');
    deviceData.details.materialArrange.graspAllowanceX = amountData?.[0]
      ? amountData?.[0]
      : deviceData?.details?.materialArrange?.graspAllowanceX;
    deviceData.details.materialArrange.graspAllowanceY = amountData?.[1]
      ? amountData?.[1]
      : deviceData?.details?.materialArrange?.graspAllowanceY;
    deviceData.details.materialArrange.xWidth = amountData?.[2]
      ? amountData?.[2]
      : deviceData?.details?.materialArrange?.xWidth;
    deviceData.details.materialArrange.yWidth = amountData?.[3]
      ? amountData?.[3]
      : deviceData?.details?.materialArrange?.yWidth;
    deviceData.details.materialArrange.workRangeX = amountData?.[4]
      ? amountData?.[4]
      : deviceData?.details?.materialArrange?.workRangeX;
    deviceData.details.materialArrange.workRangeY = amountData?.[5]
      ? amountData?.[5]
      : deviceData?.details?.materialArrange?.workRangeY;
    deviceData.details.materialArrange.rangeAdditionalTime = amountData?.[6]
      ? amountData?.[6]
      : deviceData?.details?.materialArrange?.rangeAdditionalTime;

    // パンチ条件マスター
    inputData = [];
    thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    inputData = dataList?.[thickIndex + 1]?.split(',');
    //マスタ
    deviceData?.details?.punchConditionThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('t', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    // パンチ条件
    let index = 0;
    let speedLenIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    if (deviceData?.details?.punchConditionItems) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(deviceData?.details?.punchConditionItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          inputData = dataList?.[speedLenIndex + 2 + index]?.split(',');
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          if (typeNm === inputData[0]) {
            deviceData?.details?.punchConditionItems[key]?.map((item, i) => {
              inVal = inputData[i + 1] !== undefined ? Number(inputData[i + 1]) : item.value;
              item.value = inVal !== undefined ? inVal : item.value;
            });
          }
          index++;
        });
    }

    // 1パンチ加工時間
    inputData = [];
    thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    inputData = dataList?.[thickIndex + 1]?.split(',');
    //マスタ
    deviceData?.details?.punchWorkTimeThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('t', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    //1パンチ加工時間
    index = 0;
    speedLenIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    if (deviceData?.details?.punchWorkTimeItems) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(deviceData?.details?.punchWorkTimeItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          inputData = dataList?.[speedLenIndex + 2 + index]?.split(',');
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          if (typeNm === inputData[0]) {
            deviceData?.details?.punchWorkTimeItems[key]?.map((item, i) => {
              inVal = inputData[i + 1] !== undefined ? Number(inputData[i + 1]) : item.value;
              item.value = inVal !== undefined ? inVal : item.value;
            });
          }
          index++;
        });
    }

    // 1ピアス加工時間
    inputData = [];
    thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[7] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[7]
    );
    inputData = dataList?.[thickIndex + 1]?.split(',');
    //マスタ
    deviceData?.details?.piercingTimeThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('t', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    //1ピアス加工時間
    index = 0;
    speedLenIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[7] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[7]
    );
    if (deviceData?.details?.piercingTimeThItems) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(deviceData?.details?.piercingTimeThItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          inputData = dataList?.[speedLenIndex + 2 + index]?.split(',');
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          if (typeNm === inputData[0]) {
            deviceData?.details?.piercingTimeThItems[key]?.map((item, i) => {
              inVal = inputData[i + 1] !== undefined ? Number(inputData[i + 1]) : item.value;
              item.value = inVal !== undefined ? inVal : item.value;
            });
          }
          index++;
        });
    }

    // 加工速度(mm/min)
    inputData = [];
    thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[8] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[8]
    );
    inputData = dataList?.[thickIndex + 2]?.split(',');
    // 簡易入力
    //板厚マスタ
    deviceData?.details?.workSpeedThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('t', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    index = 0;
    if (deviceData?.details?.workSpeedItems) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(deviceData?.details?.workSpeedItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          inputData = dataList?.[thickIndex + 3 + index]?.split(',');
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          if (typeNm === inputData[0]) {
            deviceData?.details?.workSpeedItems[key]?.map((item, i) => {
              inVal = inputData[i + 1] !== undefined ? Number(inputData[i + 1]) : item.value;
              item.value = inVal !== undefined ? inVal : item.value;
            });
          }
          index++;
        });
    }
    // 詳細入力
    //板厚マスタ
    thickIndex = thickIndex + index + 3;
    inputData = dataList?.[thickIndex];
    let detailIndex = 0;
    let materialTypeIndex = 0;
    if (inputData === '詳細入力' || inputData?.includes('詳細入力')) {
      thickIndex = thickIndex + 2;
      if (deviceData?.details?.workSpeedItems) {
        const usedItems = materialTypes?.filter((item) => item.info.isUsed);
        const usedIds = usedItems?.map((item) => item.id);
        Object.keys(deviceData.details?.workSpeedItems)
          ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
          ?.map((key) => {
            let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
            for (let i = 0; i < 3; i++) {
              inputData = dataList?.[thickIndex + materialTypeIndex + i]?.split(',');
              if (typeNm === inputData[0]) {
                if (inputData[1] === '丸穴径(Φ) (≦)') {
                  detailIndex = 0;
                  deviceData.details?.workSpeedItems[key]?.map((item, index) => {
                    item?.roundHoleDiaThItems?.map((roundItem, i) => {
                      roundItem.value =
                        inputData[detailIndex + 2] !== undefined ? Number(inputData[detailIndex + 2]) : roundItem.value;
                      detailIndex++;
                    });
                  });
                } else if (inputData[1] === '加工周長(mm)(≦)') {
                  detailIndex = 0;
                  deviceData.details?.workSpeedItems[key]?.map((item, index) => {
                    item?.workLenThItems?.map((roundItem, i) => {
                      roundItem.value =
                        inputData[detailIndex + 2] !== undefined ? Number(inputData[detailIndex + 2]) : roundItem.value;
                      detailIndex++;
                    });
                  });
                } else if (inputData[1] === '速度(mm/分)') {
                  detailIndex = 0;
                  deviceData.details?.workSpeedItems[key]?.map((item, index) => {
                    item?.lenThItems?.map((roundItem, i) => {
                      roundItem.value =
                        inputData[detailIndex + 2] !== undefined ? Number(inputData[detailIndex + 2]) : roundItem.value;
                      detailIndex++;
                    });
                  });
                }
              }
              materialTypeIndex++;
            }
          });
      }
    }

    // ケガキ速度,送り速度
    inputData = [];
    thickIndex = dataList?.findIndex(
      (item, index) =>
        item === detailMenuTab[9] ||
        (dataList?.[index]?.split(',')?.[0] === detailMenuTab[9]?.split(',')?.[0] &&
          dataList?.[index]?.split(',')?.[1] === detailMenuTab[9]?.split(',')?.[1])
    );
    inputData = dataList?.[thickIndex + 1]?.split(',');
    deviceData.details.scribingSpeed =
      inputData[0] !== undefined ? Number(inputData[0]) : deviceData?.details?.scribingSpeed;
    deviceData.details.feedSpeed = inputData[1] !== undefined ? Number(inputData[1]) : deviceData?.details?.feedSpeed;

    // 加工速度係数
    inputData = [];
    thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[10] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[10]
    );
    inputData = dataList?.[thickIndex + 1]?.split(',');
    //マスタ
    deviceData?.details?.workSpeedCoeffLengthThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('mm', '');
        item.value = inVal !== undefined ? Number(inVal) : item.value;
      });
    //内加工
    inputData = dataList?.[thickIndex + 2]?.split(',');
    deviceData?.details?.workSpeedCoeffInternalWorkItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1];
        item.value = inVal !== undefined ? Number(inVal) : item.value;
      });
    //外加工
    inputData = dataList?.[thickIndex + 3]?.split(',');
    deviceData?.details?.workSpeedCoeffExternalWorkItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1];
        item.value = inVal !== undefined ? Number(inVal) : item.value;
      });

    // ガス係数
    inputData = [];
    thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[11] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[11]
    );
    inputData = dataList?.[thickIndex + 2]?.split(',');
    deviceData?.details?.gasCoeffListItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1];
        item.value = inVal !== undefined ? Number(inVal) : item.value;
      });

    // 材質区分/板厚
    inputData = [];
    inputData = dataList?.[thickIndex + 3]?.split(',');
    //マスタ
    deviceData?.details?.gasCoeffThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('t', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    index = 0;
    if (deviceData?.details?.gasCoeffThItems) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(deviceData?.details?.gasCoeffThItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          inputData = dataList?.[thickIndex + 4 + index]?.split(',');
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          if (typeNm === inputData[0]) {
            deviceData?.details?.gasCoeffThItems[key]?.map((item, i) => {
              inVal = inputData[i + 1] !== undefined ? inputData[i + 1] : '';
              let gasType = deviceData?.details?.gasCoeffThMaster?.filter(
                (item) => !item.isOutOfRange && item.name === inVal
              );
              if (gasType?.length > 0) {
                item.value = gasType[0].no;
              }
            });
          }
          index++;
        });
    }

    // サイズ係数
    inputData = [];
    let childPartAreaIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[12] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[12]
    );
    inputData = dataList?.[childPartAreaIndex + 1]?.split(',');
    //面積マスタ
    deviceData?.details?.prodSizeAreaThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('cm2', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    //重量マスタ
    let childPartWeightIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[12] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[12]
    );
    deviceData?.details?.prodSizeWeightThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inputData = dataList?.[childPartWeightIndex + 2 + index]?.split(',');
        inVal = inputData[0]?.replace('kg', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
        //係数値
        deviceData?.details?.prodSizeItems
          ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
          ?.areaCoeffList?.map((area, i) => {
            inVal = inputData[i + 1] !== undefined ? toDecimal(inputData[i + 1], 2) : area.value;
            area.value = inVal !== undefined ? inVal : area.value;
          });
      });

    // 生産個数係数
    inputData = [];
    let quantityCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[13] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[13]
    );
    inputData = dataList?.[quantityCoeffIndex + 1]?.split(',');
    deviceData?.details?.productionQtyCoeffThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('個', '');
        inVal = inVal !== undefined ? Number(inVal, 2) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    inputData = dataList?.[quantityCoeffIndex + 2]?.split(',');
    deviceData?.details?.productionQtyCoeffThItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        item.value = toDecimal(inputData[index + 1], 2);
      });

    // 2工程穴加工時間
    chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[14] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[14]
    );
    dataTitle = dataList[chargesIndex + 1];
    dandoriTime = dataList[chargesIndex + 2];
    // IQX_WEBEST-301
    if (dandoriTime?.split(',') !== undefined) {
      dandoriTime = dandoriTime?.split(',')?.[0];
    }
    deviceData.details.twoStepHoleworkTime =
      dandoriTime !== undefined ? setTimetoSec(dandoriTime) : deviceData?.details?.twoStepHoleworkTime;

    // ジョイントバラシ
    inputData = [];
    thickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[15] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[15]
    );
    inputData = dataList?.[thickIndex + 1]?.split(',');
    //マスタ
    deviceData?.details?.jointQtySizeThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('cm2', '');
        item.value = inVal !== undefined ? inVal : item.value;
      });
    inputData = dataList?.[thickIndex + 2]?.split(',');
    //マスタ
    deviceData?.details?.jointQtyItems
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1];
        item.value = inVal !== undefined ? Number(inVal) : item.value;
      });

    // 材質区分/板厚
    inputData = [];
    inputData = dataList?.[thickIndex + 3]?.split(',');
    //マスタ
    deviceData?.details?.jointSeparateThickThMaster
      ?.filter((item) => !item.isOutOfRange)
      ?.map((item, index) => {
        inVal = inputData[index + 1]?.replace('～', '');
        inVal = inVal?.replace('t', '');
        inVal = inVal !== undefined ? Number(inVal) : item.value;
        item.value = inVal !== undefined ? inVal : item.value;
      });
    index = 0;
    if (deviceData?.details?.jointSeparateItems) {
      const usedItems = materialTypes?.filter((item) => item.info.isUsed);
      const usedIds = usedItems?.map((item) => item.id);
      Object.keys(deviceData?.details?.jointSeparateItems)
        ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
        ?.map((key) => {
          inputData = dataList?.[thickIndex + 4 + index]?.split(',');
          let typeNm = materialTypes?.find((item) => item.id.toString() === key)?.name;
          if (typeNm === inputData[0]) {
            deviceData?.details?.jointSeparateItems[key]?.map((item, i) => {
              inVal = inputData[i + 1] !== undefined ? Number(inputData[i + 1]) : item.value;
              item.value = inVal !== undefined ? inVal : item.value;
            });
          }
          index++;
        });
    }

    return deviceData;
  };

  return (
    <>
      <Form>
        <div className="d-flex" style={{ marginTop: 7 }}>
          <div style={{ marginLeft: 10, display: editMode ? 'none' : 'block' }}>
            <Tooltip title="CSV入力" placement="bottom" overlayClassName="tooltip-text">
              <input
                type="file"
                accept=".csv"
                ref={csvImportRef}
                style={{ display: 'none' }}
                onChange={handleCSVFileChange}
                onClick={(e) => (e.target.value = null)}
              />
              <Image
                preview={false}
                width={iconWidth}
                src={import_csv_icon}
                style={{ marginLeft: '0px' }}
                onClick={onClickCSVImport}
              />
            </Tooltip>
          </div>
          <div style={{ marginLeft: 20, display: editMode ? 'none' : 'block' }}>
            <Tooltip title="CSV出力" placement="bottom" overlayClassName="tooltip-text">
              <CSVLink ref={csvExportRef} data={''} preview={true} style={{ display: 'none' }}></CSVLink>
              <Image
                preview={false}
                width={iconWidth}
                src={export_csv_icon}
                style={{ marginLeft: '0px' }}
                onClick={onClickCSVExport}
              />
            </Tooltip>
          </div>
          <div>
            <Button
              style={{
                marginLeft: 10,
                display: editMode ? 'block' : 'none',
              }}
              className="mainButton"
              id="update"
              onClick={updateData}
            >
              更新
            </Button>
          </div>
          <div>
            <Button
              style={{
                marginLeft: 10,
                display: editMode ? 'block' : 'none',
              }}
              className="cancelButton"
              id="discard"
              onClick={discardData}
            >
              破棄
            </Button>
          </div>
          <div className="ms-auto">
            <Button
              style={{
                marginRight: 10,
                display: 'none',
                display: editMode ? 'none' : 'block',
                pointerEvents: selectedDeviceData?.length != 0 ? 'auto' : 'none',
              }}
              className="mainButton"
              id="edit"
              onClick={editData}
            >
              編集
            </Button>
          </div>
        </div>
        <div className="overflow-auto kouteical timecharge registerModal" style={{ height: '77.1vh', marginTop: 22 }}>
          <div className="upperDeviceTbl">
            <Row className=" chargeAmount ">
              {/* workType：カスタム、ブランクの場合、 */}
              <Col
                style={{
                  width: workType === WorkType.PaCustom || workType === WorkType.SmCustom ? '100%' : '75%',
                }}
              >
                <RTable>
                  <thead className="table-light">
                    <tr style={{ textAlign: 'center' }}>
                      <th style={{ width: '10%' }}>コード</th>
                      <th style={{ width: '15%' }}>工程名</th>
                      {/* workType：カスタム、ブランクの場合、 */}
                      {workType === WorkType.PaCustom || workType === WorkType.SmCustom ? (
                        <>
                          <th style={{ width: '10%' }}>コード</th>
                          <th style={{ width: '15%' }}>工程名</th>
                        </>
                      ) : (
                        <></>
                      )}
                      <th style={{ width: '10%' }}>装置コード</th>
                      <th style={{ width: '15%' }}>装置名</th>
                      <th style={{ width: '12.5%' }}>備考</th>
                      <th style={{ width: '12.5%' }}>表示</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ textAlign: 'center' }}>
                      <td style={{ verticalAlign: 'middle' }}>{code}</td>
                      <td style={{ verticalAlign: 'middle' }}>{processName}</td>
                      {/* workType：ブランクの場合、 */}
                      {workType === WorkType.PaCustom || workType === WorkType.SmCustom ? (
                        // workType：カスタムの場合、
                        <>
                          <td style={{ verticalAlign: 'middle' }}>
                            {/* {kouteiCode} */}
                            {editMode ? (
                              <>
                                <Select
                                  id="koutei"
                                  name="koutei"
                                  placeholder="選択"
                                  value={additionalProcessId}
                                  style={{
                                    fontSize: 11,
                                    width: '97%',
                                  }}
                                  onChange={changeAdditionalKouteiType}
                                >
                                  {processTypesList
                                    ?.filter((i) => i.isUsed === true)
                                    ?.map((item, index) => (
                                      <Select.Option key={index} value={item.id}>
                                        {item.code}
                                      </Select.Option>
                                    ))}
                                </Select>
                              </>
                            ) : (
                              <>{kouteiCode}</>
                            )}
                          </td>
                          <td style={{ verticalAlign: 'middle' }}>
                            {/* {kouteiName} */}
                            {editMode ? (
                              <>
                                <Select
                                  id="koutei"
                                  name="koutei"
                                  placeholder="選択"
                                  value={additionalProcessId}
                                  style={{
                                    fontSize: 11,
                                    width: '97%',
                                  }}
                                  onChange={changeAdditionalKouteiType}
                                >
                                  {processTypesList
                                    ?.filter((i) => i.isUsed === true)
                                    ?.map((item, index) => (
                                      <Select.Option key={index} value={item.id}>
                                        {item.name}
                                      </Select.Option>
                                    ))}
                                </Select>
                              </>
                            ) : (
                              <>{kouteiName}</>
                            )}
                          </td>
                        </>
                      ) : (
                        <></>
                      )}
                      <td style={{ verticalAlign: 'middle' }}>
                        <Input
                          style={{ padding: 3, width: '98.2%', textAlign: 'center', height: 26 }}
                          className={editMode ? 'input-editable ' : 'input-non-editable '}
                          name="deviceCode"
                          id="deviceCode"
                          value={deviceCode}
                          type="text"
                          onChange={(e) => {
                            setDeviceCode(e.target.value);
                          }}
                        />
                      </td>
                      <td style={{ verticalAlign: 'middle' }}>
                        <Input
                          style={{ padding: 3, width: '98.2%', textAlign: 'center', height: 26 }}
                          className={editMode ? 'input-editable ' : 'input-non-editable '}
                          name="deviceName"
                          id="deviceName"
                          value={deviceName}
                          type="text"
                          onChange={(e) => {
                            setDeviceName(e.target.value);
                          }}
                        />
                      </td>
                      <td style={{ verticalAlign: 'middle' }}>
                        <Input
                          style={{ padding: 3, width: '98.2%', textAlign: 'center', height: 26 }}
                          className={editMode ? 'input-editable ' : 'input-non-editable '}
                          value={remarks}
                          type="text"
                          onChange={(e) => {
                            setRemarks(e.target.value);
                          }}
                        />
                      </td>
                      <td style={{ verticalAlign: 'middle' }}>
                        {editMode ? (
                          <RadioGroup
                            name="isUsed"
                            defaultValue={isUsed}
                            className="radioCustomerGpEdit"
                            onChange={(e) => setIsUsed(e.target.value)}
                            value={isUsed}
                          >
                            <Radio value={true}>オン</Radio>
                            <Radio value={false}>オフ</Radio>
                          </RadioGroup>
                        ) : isUsed ? (
                          <Image preview={false} width={13} src={checked} id="imgOutsourcingFg" />
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </RTable>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <Space>
                  <DownSquareFilled style={{ fontSize: 25, color: '#1063aa' }} onClick={openAll} />
                  <UpSquareFilled style={{ fontSize: 25, color: '#1063aa' }} onClick={closeAll} />
                </Space>
              </Col>
            </Row>
          </div>
          <Row>
            <Col span={24}>
              {/* 標準の場合、 */}
              {selectedDeviceData?.length != 0 ? (
                <>
                  {deviceType === processTypes.standard ? (
                    <>{content}</>
                  ) : (
                    // 追加の場合、
                    <>
                      {/* チャージ金額 */}
                      {chargeAmountContent}
                      {/* 段取時間 */}
                      {dantoriSettingContent}
                      {sagyouJikanContent}
                      {/* 親部品 */}
                      {types === ServiceClass.Parent ? (
                        productSizeContent
                      ) : (
                        <>
                          {/*  {materialHandlingContent} */}
                          {productSizeFactorContent}
                        </>
                      )}
                      {quantityContent}
                      {/* 委託装置詳細 */}
                      <Row
                        align="middle"
                        style={{ color: 'black' }}
                        className="oyabuhin oyabuhin-row"
                        onClick={deviceDetails}
                      >
                        <Col span={20} className="deli-col">
                          <div style={{ display: 'flex' }}>
                            <label style={{ marginRight: 5 }}>委託装置詳細</label>
                            {isDeviceDetails ? (
                              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                            ) : (
                              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                            )}
                          </div>
                        </Col>
                      </Row>
                      <div style={{ display: isDeviceDetails ? 'block' : 'none' }}>
                        <Row style={{ marginLeft: 10 }} className="chargeAmount">
                          <Col style={{ width: '70%' }}>
                            <RTable className="tsuikakoutei">
                              <thead>
                                <tr>
                                  <td className="device-detail-tbl-tsuika">
                                    <label className="detail-tbl-val-title-label">委託工程</label>
                                  </td>
                                  <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                                    <Checkbox
                                      style={{ marginLeft: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                                      onChange={handleCheckboxChange}
                                      checked={checkboxChecked}
                                    ></Checkbox>
                                  </td>
                                </tr>
                                <tr className={selectDisabled ? 'souchi' : ''}>
                                  <td className="device-detail-tbl-tsuika">
                                    <label className="detail-tbl-val-title-label">取引先(委託先)</label>
                                  </td>
                                  <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                                    <Row style={{ marginLeft: 10 }}>
                                      <Col span={16}>{customerName}</Col>
                                      <Col span={7} style={{ textAlign: 'end' }}>
                                        <SearchOutlined
                                          style={{
                                            fontSize: '18px',
                                            pointerEvents: selectDisabled || !editMode ? 'none' : 'auto',
                                          }}
                                          onClick={searchCustomer}
                                        />
                                      </Col>
                                    </Row>
                                  </td>
                                </tr>
                                <tr className={selectDisabled ? 'souchi' : ''}>
                                  <td className="device-detail-tbl-tsuika">
                                    <label className="detail-tbl-val-title-label">取引先コード</label>
                                  </td>
                                  <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                                    <label style={{ marginLeft: 10 }}>{customerCode}</label>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="device-detail-tbl-tsuika">
                                    <label className="detail-tbl-val-title-label">手入力フラグ</label>
                                  </td>
                                  <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                                    <Checkbox
                                      style={{ marginLeft: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                                      onChange={flagChange}
                                      checked={flagChecked}
                                    ></Checkbox>
                                  </td>
                                </tr>
                                <tr className={inputDisabled ? 'souchi' : ''}>
                                  <td className="device-detail-tbl-tsuika">
                                    <label className="detail-tbl-val-title-label">初期金額(手入力時)</label>
                                  </td>
                                  <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                                    <CurrencyInput
                                      name="iniAmount"
                                      id="iniAmount"
                                      value={iniAmount}
                                      defaultValue={0}
                                      style={{
                                        padding: 3,
                                        width: '97%',
                                        pointerEvents: inputDisabled || !editMode ? 'none' : 'auto',
                                      }}
                                      className={
                                        editMode
                                          ? 'currencyInputCost input-editable'
                                          : 'currencyInputCost input-non-editable'
                                      }
                                      prefix="¥"
                                      decimalsLimit={100}
                                      onValueChange={(e) => changeAmount('iniAmount', e)}
                                    />
                                    {/* <Input
                                  value={JPYs.format(iniAmount)}
                                  onChange={(e) => changeAmount('iniAmount', e.target.value)}
                                  type="text"
                                  style={{
                                    padding: 5,
                                    marginLeft: 5,
                                    width: '97%',
                                    pointerEvents: inputDisabled || !editMode ? 'none' : 'auto',
                                  }}
                                  className="input-editable"
                                ></Input> */}
                                  </td>
                                </tr>
                              </thead>
                            </RTable>
                          </Col>
                        </Row>
                      </div>
                      {/* 親部品 */}
                      {/* {types === ServiceClass.Parent ? '' : kouteiNaiKensaContent} */}
                    </>
                  )}
                </>
              ) : (
                <>
                  {chargeAmountContent}
                  {dantoriSettingContent}

                  {productSizeContent}
                  {quantityContent}
                </>
              )}
            </Col>
          </Row>
        </div>
      </Form>
      {/** 更新の確認メッセージ */}
      {updateConfirm
        ? commonModal(
            updateConfirm,
            confirmModalTitle,
            commonFooter(updConfirmOk, updConfirmCancel),
            null,
            400,
            updConfirmOk,
            updConfirmCancel,
            confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}
      {/** 破棄の確認メッセージ */}
      {cancelConfirm
        ? commonModal(
            cancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}

      {/* チャージ金額詳細 */}
      {isDetails
        ? commonModal(
            isDetails,
            addDetailsModalTitle,
            null,
            null,
            1030,
            addData,
            closeAddModal,
            addContent,
            null,
            null,
            true
          )
        : ''}
      {customerModal ? (
        <SearchAndGetCustomerData
          customerModal={customerModal}
          selectedCustomerData={getCustomerData}
          title={Customer}
          clientType={ClientType.Contractor}
        />
      ) : (
        ''
      )}
    </>
  );
});

export default DevicesDetails;
