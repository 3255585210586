import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { arrayMoveImmutable } from 'array-move';
import { Table, Button, Row } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

const columns = [
  {
    id: '1',
    title: '',
    dataIndex: 'id',
    width: 0 + 'px',
  },
  {
    id: '2',
    title: '',
    dataIndex: 'title',
    width: 20 + 'px',
    render: (value, record, index) => (
      <div id={'sortId' + index} className="selected-element">
        {value}
      </div>
    ),
  },
];

const Sorting = forwardRef((props, ref) => {
  const [items, setItems] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [curIndex, setCurIndex] = useState(0); // 現在Index

  useImperativeHandle(ref, () => ({
    setItems: setItems,
    setCurIndex: setCurIndex,
  }));
  // mode「1：最初,2：一箇所前,3：一箇所後,4：最後」
  const onSortEnd = (e, mode) => {
    var maxIndex = items?.length - 1; // リストの最大Index
    var oldIndex = curIndex; // 元Index
    var newIndex = curIndex; // 新Index

    //　現在、最初にある場合「最初、一箇所前へ」に移動不可。
    if (oldIndex === 0 && (mode === 1 || mode === 2)) return;
    //　現在、最後にある場合「一箇所後、最後へ」に移動不可。
    if (oldIndex === maxIndex && (mode === 3 || mode === 4)) return;

    // 最初へのボタンを押下
    if (mode === 1) newIndex = 0;
    // 一箇所前へのボタンを押下
    if (mode === 2) newIndex = newIndex - 1;
    // 一箇所後へのボタンを押下
    if (mode === 3) newIndex = newIndex + 1;
    //　最後へのボタンを押下
    if (mode === 4) newIndex = maxIndex;

    var newItems = arrayMoveImmutable(items, oldIndex, newIndex);
    // Indexの切り替え
    setItems(newItems);

    if (props && props.update) {
      props.update(newItems);
    }

    // 行の選択
    changeRowSelect(newIndex);
  };

  // 行の選択
  const onRowSelect = (record, index) => {
    setSelectedData(record);
    setCurIndex(index);
  };

  // Scrollの移動
  const scrollTo = (index) => {
    // Scrollの移動
    document.getElementById('sortId' + index)?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };

  // 行の選択
  const changeRowSelect = (index) => {
    setCurIndex(index);
    scrollTo(index);
  };

  // 画面の初期設定
  useEffect(() => {
    setItems(props.items);
    if (props && props.update) {
      props.update(props.items);
    }
    changeRowSelect(0);
  }, [props.items]);

  return (
    <>
      <div
        className="mt-1"
        style={{
          width: '100%',
          // height: 600,
          display: 'flex',
          border: '1px solid rgb(181, 178, 174)',
          borderRadius: 10,
        }}
      >
        {/* 一覧 */}
        <div style={{ width: '80%' }}>
          <Table
            id="sort-tbl"
            className="sorting"
            rowClassName={(record, index) => (index === curIndex ? 'active-row' : 'data-row')}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={items}
            scroll={{ y: 502, x: '10vw' }}
            pagination={false}
            onRow={(record, index) => {
              return {
                onClick: (event) => {
                  onRowSelect(record, index);
                },
              };
            }}
          />
        </div>
        {/* ボタン */}
        <div
          style={{
            width: '20%',
            float: 'right',
            display: 'inline-flex',
            alignItems: 'center',
            flexDirection: 'column',
            paddingTop: 120,
          }}
        >
          <Button
            id="toTop"
            onClick={(e) => {
              onSortEnd(e, 1);
            }}
            className="sortButton"
          >
            <div className="multiArrow">
              <ArrowUpOutlined />
              <ArrowUpOutlined />
            </div>
          </Button>
          <Button
            id="toOneUp"
            onClick={(e) => {
              onSortEnd(e, 2);
            }}
            className="sortButton"
          >
            <ArrowUpOutlined />
          </Button>
          <Button
            id="toOneDown"
            onClick={(e) => {
              onSortEnd(e, 3);
            }}
            className="sortButton"
          >
            <ArrowDownOutlined />
          </Button>
          <Button
            id="toBottom"
            onClick={(e) => {
              onSortEnd(e, 4);
            }}
            className="sortButton"
          >
            <div className="multiArrow">
              <ArrowDownOutlined />
              <ArrowDownOutlined />
            </div>
          </Button>
        </div>
      </div>
    </>
  );
});

export default Sorting;
