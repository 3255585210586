/**
 * クラス名：自動引当一覧画面
 * 説明：iQ3板金に使用の自動引当一覧ファイルです。
 * 作成者：テッ
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import { Table, Button, Row, Input, Col, Modal, Select, Space, Image, Radio, Tabs } from 'antd';
import { CSVLink, CSVDownload } from 'react-csv';
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Routes, Route } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CloseOutlined, CaretDownOutlined, CaretUpOutlined, StarFilled } from '@ant-design/icons';
import AutoReserveDetails from './AutoReserveDetails';
import 'split-pane-react/esm/themes/default.css';
import SplitPane from 'split-pane-react';
import { Table as RTable } from 'react-bootstrap';
import { StatusCodes } from 'http-status-codes';

import '../../../../assets/styles/common.css';
import checked from '../../../../assets/images/checked.png';
import {
  confirmHakiContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  confirmDiscardContent,
  modalTitle,
  confirmCreateContent,
  confirmDeleteContent,
  commonActionFooter,
  confirmUpdateContent,
  authorizeError,
  error,
  commonDeleteFooter,
} from '../../../common/CommonModal';
import {
  actionArr,
  dataStateList,
  secondsToHms,
  ServiceClasses,
  defaultValue,
  WorkTypes,
  getMaterialTypeList,
  getAccessToken,
  updateAccessToken,
  formatDate,
  JPYs,
  formValidatorMode,
  regexExp,
  toHalfWidth,
  formatDateString,
  todayTime,
  isDigit,
} from '../../../common/Common';
import CommonPurchaseList from '../../../common/CommonPurchaseList';
import {
  createPurchaseAllocationCSV,
  getProcessInfo,
  getPurchaseAllocationInfo,
  getPurchaseInfo,
} from '../../../common/CommonAPI';
import { WorkType, ServiceClass, WorkTypeGroup } from '../../../common/enums';
import { ErrorMessage } from '../../../common/Message';
import sort_up from '../../../../assets/images/sort_up.png';
import sort_down from '../../../../assets/images/sort_down.png';

dayjs.extend(customParseFormat);

const RadioGroup = Radio.Group;
const { TextArea } = Input;

const AutoReserveList = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    setSplitPaneSize: setSplitPaneSize,
    showAddNewModal: showAddNewModal,
    showXMLInputModal: showXMLInputModal,
    showGroupEditModal: showGroupEditModal,
    showDeleteModal: showDeleteModal,
    showCSVInputModal: showCSVInputModal,
    getEditMode: editMode,
    updateEditMode: setEditMode,
    discardChange: actionArr,
    setDiscardChangeMode: setDiscardOKAction,
    setEditModeCancelConfirm: setEditModeCancelConfirm,
    discardConfirmOk: discardConfirmOk,
    getCsvExportFileName: csvExportFileName,
    getCsvExportData: getCsvExportData,
    submitCsvImportData: submitCsvImportData,
  }));

  const detailRef = useRef();
  const messageRef = useRef([]);

  const [paramType, setParamType] = useState('iq3');
  const [autoReserve, setAutoReserve] = useState([]);
  const [tmpAutoReserve, setTmpAutoReserve] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const [editMode, setEditMode] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState();
  const [changeRecordId, setChangeRecordId] = useState();

  const [hasCheckData, setHasCheckData] = useState(true);
  const [showFlag, setShowFlag] = useState(1);
  const [filterKey, setFilterKey] = useState();
  const [isFilter, setIsFilter] = useState(true);

  const [listShowHide, setListShowHide] = useState(true);
  const [chkData, setChkData] = useState(0);

  const [visibleGroupEditModal, setVisibleGroupEditModal] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [visibleCSVInput, setVisibleCSVInput] = useState(false);
  const [visibleCSVOutput, setVisibleCSVOutput] = useState(false);
  const [visibleXMLInput, setVisibleXMLInput] = useState(false);
  const [visibleAddNewModal, setVisibleAddNewModal] = useState(false);
  const [childTitle, setChildTitle] = useState('');

  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [discardOKAction, setDiscardOKAction] = useState(actionArr[0]);
  const [updateCancelConfirm, setUpdateCancelConfirm] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [latestData, setLatestData] = useState(false);
  const [tmpFilteredData, setTmpFilteredData] = useState();

  const [activeTabKey, setActiveTabKey] = useState('1');
  const [activeProcessTbl, setActiveProcessTbl] = useState('');

  const [is2jikakou, setIs2jikakou] = useState(false);
  const [isParentYousetsu, setIsParentYousetsu] = useState(false);
  const [isIQ3Yousetsu, setIsIQ3Yousetsu] = useState(false);

  const [parentYousetsuArr, setParentYousetsuArr] = useState([]);
  const [iQ3YousetsuArr, setIQ3YousetsuArr] = useState([]);
  const [iQ3NijikakouArr, setIQ3NijikakouArr] = useState([]);

  const [selectedPurchasesData, setSelectedPurchasesData] = useState();

  const [keyword, setKeyWord] = useState('');
  const [isResize, setIsResize] = useState(false);

  // Content Resize
  const [sizes, setSizes] = useState(['60%']);

  // 一括編集選択行
  const [chkrowscount, setChkRowsCount] = useState(0);

  const [showEdtConfirmOK, setShowEdtConfirmOK] = useState();
  const [showEdtConfirmCancle, setShowEdtConfirmCancle] = useState();

  const inputItemRef = {};

  const [processMstInfo, setProcessMstInfo] = useState([]);
  const [purchaseMstInfo, setPurchaseMstInfo] = useState([]);
  const [purchaseMstInfoByAllocation, setPurchaseMstInfoByAllocation] = useState([]);
  const [materialTypeList, setMaterialTypeList] = useState([]);
  const [isFilterFlg, setIsFilterFlg] = useState(false);

  const csvExportFileName = `自動引当_${formatDateString(todayTime())}.csv`;

  // 工程種別より、グループ化に分ける
  const createGpWithProcessType = (tblData) => {
    const separatedData = tblData?.reduce((result, item) => {
      // グループID：工程マスタレコードID＋工程種別No
      const groupId = item.processId.toString() + item.processTypeNo.toString();
      if (!result[groupId]) {
        result[groupId] = [];
      }
      result[groupId].push(item);
      return result;
    }, {});

    return Object.entries(separatedData)?.map(([groupId, data]) => {
      // 工程マスタから加工種類情報を得る
      // let mstRecord = [...processMstInfo]?.filter((record) => record?.id === data?.[0]?.processId)?.[0];
      let mstRecord = getProcessMstRecordById(data?.[0]?.processId);
      // 加工種類情報
      let detailInfo = mstRecord?.details?.processDetailTypes?.filter(
        (detail) => detail?.id === data?.[0]?.processTypeNo
      )?.[0];
      return {
        ['processTypeName']: detailInfo?.name, // 加工種類名
        ['classId']: mstRecord?.class, // 種別ID
        ['workTypeId']: mstRecord?.workType, // 工程種別ID
        ['groupId']: groupId, // グループID
        ['data']: data, // 一覧情報
      };
    });
  };

  // グループ化する
  const categorizedAutoReserve = (dataArr, filterFlg) => {
    let parentYousetsu = [];
    let iQ3Yousetsu = [];
    let iQ3Nijikakou = [];

    let catAutoReserveArr = [];

    filterFlg ? (catAutoReserveArr = [...dataArr]) : (catAutoReserveArr = [...autoReserve]);

    catAutoReserveArr?.forEach((item) => {
      // let mstRecord = [...processMstInfo]?.filter((record) => record?.id === item?.processId)?.[0];
      let mstRecord = getProcessMstRecordById(item?.processId);
      switch (mstRecord?.class) {
        // 親部品
        case ServiceClass.Parent: {
          parentYousetsu.push(item);
          break;
        }
        // 板金子部品
        case ServiceClass.SheetMetal:
          switch (mstRecord?.workType) {
            // 溶接
            case WorkType.SmWelding:
              iQ3Yousetsu.push(item);
              break;
            // 2次加工
            case WorkType.SmSecondaryWork:
              iQ3Nijikakou.push(item);
              break;
          }
          break;
      }
    });
    // 親部品->溶接
    setParentYousetsuArr(createGpWithProcessType(parentYousetsu));
    // 板金子部品->溶接
    setIQ3YousetsuArr(createGpWithProcessType(iQ3Yousetsu));
    // 板金子部品->２次加工
    setIQ3NijikakouArr(createGpWithProcessType(iQ3Nijikakou));
    setIsFilter(false);
  };

  // 購入品引当マスタを得る
  useEffect(() => {
    getPurchaseAllocationMstData();
  }, []);

  // 購入品引当マスタをグループ化に分ける
  useEffect(() => {
    categorizedAutoReserve(autoReserve, false);
  }, [autoReserve]);

  useEffect(() => {
    if (props.showConfirmMsgs !== undefined) {
      setShowEdtConfirmOK(props.showConfirmMsgs?.editOk);
      setShowEdtConfirmCancle(props.showConfirmMsgs?.editCancel);
    } else {
      setShowEdtConfirmOK(true);
      setShowEdtConfirmCancle(true);
    }
  }, [props.showConfirmMsgs]);

  // 選択中の行が変わった場合、ヘッダーバーの開閉をコントロール
  useEffect(() => {
    let processMstRecord = getProcessMstRecordById(selectedData?.processId);
    if (processMstRecord) {
      if (processMstRecord?.workType === WorkType.PaWelding) {
        setIsParentYousetsu(true);
      } else if (processMstRecord?.workType === WorkType.SmWelding) {
        setIsIQ3Yousetsu(true);
      } else if (processMstRecord?.workType === WorkType.SmSecondaryWork) {
        setIs2jikakou(true);
      }
      let detailInfo = processMstRecord?.details?.processDetailTypes?.filter(
        (detail) => detail?.id === selectedData?.processTypeNo
      )?.[0];
      setActiveProcessTbl(
        processMstRecord?.class.toString() + processMstRecord?.workType.toString() + detailInfo?.name.toString()
      );
    }
  }, [selectedData]);

  // 購入品引当マスタ情報を取得する
  const getPurchaseAllocationMstData = async () => {
    props?.loading(true); // Waitingダイアログを表示にする
    await getPurchaseMstData(); // 購入品マスタ情報を取得する
    let materialTypeList = await getMaterialTypeList(); // 材質区分マスタ情報を取得する
    setMaterialTypeList(materialTypeList);
    await getProcessMstData(); // 工程マスタ情報を取得する
    let purAllocationData = await getPurchaseAllocationInfo(); // 購入品引当マスタ情報を取得する
    setShowFlag(1);
    setAutoReserve(purAllocationData);
    setFilteredData(purAllocationData);
    setTmpAutoReserve(purAllocationData);
    // 前回選択された行がある場合
    if (props.initialParam === 'autoReserve' && props.selectedRowId !== undefined && props.selectedRowId !== 0) {
      let selectedData = purAllocationData?.filter((item) => item.id === props?.selectedRowId);
      setSelectedRowId(selectedData?.[0]?.id);
      setSelectedData(selectedData !== undefined && selectedData?.length > 0 ? selectedData[0] : []);
      let propFilterDatas =
        props.initialParam === 'autoReserve' && props?.filterDatas !== undefined
          ? props?.filterDatas
          : {
              keyword: '',
              showFlag: 1,
            };
      setKeyWord(propFilterDatas?.keyword);
      setShowFlag(propFilterDatas?.showFlag);
    } else {
      setSelectedRowId(purAllocationData?.[0]?.id);
      setSelectedData(purAllocationData !== undefined && purAllocationData?.length > 0 ? purAllocationData[0] : []);
    }
    setIsFilterFlg(true);
    setIsFilter(false);
    setLatestData(false);
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // 工程マスタ情報を取得する
  const getProcessMstData = async () => {
    let processMstData = await getProcessInfo();
    setProcessMstInfo(processMstData);
  };

  // 購入品マスタ情報を取得する
  const getPurchaseMstData = async () => {
    let purchaseMstData = [];
    let purchaseMstInfo = await getPurchaseInfo(0, 0, 'ASC', '', {});
    if (purchaseMstInfo) {
      purchaseMstData = purchaseMstInfo ? purchaseMstInfo?.data : [];
      setPurchaseMstInfo(purchaseMstData);
      setPurchaseMstInfoByAllocation(purchaseMstData?.filter((item) => item.info.isAllocation));
    }
  };

  const getPurchaseData = (data) => {
    setSelectedPurchasesData(data[0]);
  };

  const getPurchaseDetailInfo = (id) => {
    return purchaseMstInfo?.filter((record) => record.id === id)?.[0];
  };

  const columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 50,
      render: (text, record, index) => <span>{index + 1}</span>,
      className: 'cm-a-right',
    },

    {
      key: 'materialTypeId',
      title: '材質区分名',
      dataIndex: 'materialTypeId',
      width: 150,
      render: (value) => <span>{materialTypeList?.filter((item) => item.value === value)?.[0]?.label}</span>,
      sorter: (a, b) =>
        materialTypeList
          ?.filter((item) => item.value === a.materialTypeId)?.[0]
          ?.label?.toUpperCase()
          ?.localeCompare(
            materialTypeList?.filter((item) => item.value === b.materialTypeId)?.[0]?.label?.toUpperCase()
          ),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'size',
      title: 'サイズ',
      dataIndex: 'size',
      width: 80,
      className: 'cm-a-right',
      sorter: (a, b) => a.size - b.size,
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'purchaseName',
      title: '購入品名称',
      dataIndex: 'purchaseId',
      render: (value) => <span>{getPurchaseDetailInfo(value)?.name}</span>,
      width: 150,
      sorter: (a, b) =>
        getPurchaseDetailInfo(a.purchaseId)
          ?.name?.toUpperCase()
          ?.localeCompare(getPurchaseDetailInfo(b.purchaseId)?.name?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'suppiler',
      title: '仕入先',
      dataIndex: 'purchaseId',
      render: (value) => <span>{getPurchaseDetailInfo(value)?.info?.supplierName}</span>,
      width: 150,
      sorter: (a, b) =>
        getPurchaseDetailInfo(a.purchaseId)
          ?.info?.supplierName?.toUpperCase()
          ?.localeCompare(getPurchaseDetailInfo(b.purchaseId)?.info?.supplierName?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'unitPrice',
      title: '単価',
      dataIndex: 'purchaseId',
      render: (value) => <span>{JPYs.format(getPurchaseDetailInfo(value)?.price)}</span>,
      width: 80,
      className: 'cm-a-right',
      sorter: (a, b) => getPurchaseDetailInfo(a.purchaseId)?.price - getPurchaseDetailInfo(b.purchaseId)?.price,
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'processTime',
      title: '取付時間',
      dataIndex: 'purchaseId',
      render: (value) => <span>{secondsToHms(getPurchaseDetailInfo(value)?.info?.processTime)}</span>,
      width: 100,
      className: 'cm-a-right',
      sorter: (a, b) =>
        getPurchaseDetailInfo(a.purchaseId)?.info?.processTime - getPurchaseDetailInfo(b.purchaseId)?.info?.processTime,
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'created',
      title: '作成日',
      dataIndex: 'created',
      width: 100,
      render: (created) => formatDate(created),
      sorter: (a, b) => a.created?.toUpperCase()?.localeCompare(b.created?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'modified',
      title: '更新日',
      dataIndex: 'modified',
      width: 100,
      render: (modified) => formatDate(modified),
      sorter: (a, b) => a.modified?.toUpperCase()?.localeCompare(b.modified?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'modifier',
      title: '更新者',
      dataIndex: 'modifier',
      width: 150,
      sorter: (a, b) => a.modifier?.toUpperCase()?.localeCompare(b.modifier?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'remarks',
      title: '備考',
      dataIndex: 'info',
      render: (text, record, index) => <span>{record?.info?.remarks}</span>,
      width: 200,
      sorter: (a, b) => a.info.remarks?.toUpperCase()?.localeCompare(b.info.remarks?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'isUsed',
      title: '表示',
      dataIndex: 'info',
      width: 95,
      render: (index, item) => {
        return item?.info?.isUsed ? (
          <Image preview={false} width={13} src={checked} style={{ marginLeft: '3px' }} id={item?.id} />
        ) : (
          <></>
        );
      },
      className: 'cm-a-center',
      sorter: (a, b) => a.info.isUsed - b.info.isUsed,
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
  ];

  const tabItems = [
    {
      key: '1',
      label: `検索`,
      children: <CommonPurchaseList data={purchaseMstInfo} getPurchaseData={getPurchaseData} />,
    },
    {
      key: '2',
      label: <StarFilled />,
      children: <CommonPurchaseList data={purchaseMstInfoByAllocation} getPurchaseData={getPurchaseData} />,
    },
  ];

  const onChange = (key) => {
    setActiveTabKey(key);
  };

  // 工程マスタから加工情報を取得する
  const getProcessTypeNoInfo = (classId, processTypeId) => {
    let prcessTypeNoInfo = [];
    if (classId === undefined || processTypeId === undefined || processMstInfo?.length <= 0) return prcessTypeNoInfo;
    // 選択された、種別と工程より工程マスタ情報を扱う
    let mstInfo = [...processMstInfo]?.filter((item) => item?.class === classId && item?.workType === processTypeId);
    mstInfo?.map((record) => {
      let groupType = WorkTypeGroup.None;
      let sGroupType = WorkTypeGroup.None;
      if (record?.workType === WorkType.PaWelding) {
        // 親部品->溶接の場合、取付種類（サイズ判断）の情報を扱う
        groupType = WorkTypeGroup.PaInstSize;
      } else if (record?.workType === WorkType.SmWelding) {
        // 板金子部品->溶接の場合、取付種類（サイズ判断）の情報を扱う
        groupType = WorkTypeGroup.SmInstSize;
      } else if (record?.workType === WorkType.SmSecondaryWork) {
        // 板金子部品->2次加工の場合、サイズ判断とミリメートル判断の情報を扱う
        groupType = WorkTypeGroup.SmSecondaryWorkSize;
        sGroupType = WorkTypeGroup.SmSecondaryWorkMillimeter;
      }
      prcessTypeNoInfo = record?.details?.processDetailTypes
        ?.filter((detail) => (detail.group === groupType || detail.group === sGroupType) && detail.isUsed)
        ?.map((item) => ({ value: item.id, label: item.name }));
    });
    return prcessTypeNoInfo;
  };

  // 工程マスタのレコードIDを取得する
  const getProcessRecordId = (classId, processTypeId) => {
    // 選択された、種別と工程より工程マスタレコードIDを得る
    let mstInfo = [...processMstInfo]?.filter((item) => item?.class === classId && item?.workType === processTypeId);
    return mstInfo?.length > 0 ? mstInfo[0].id : 0;
  };

  // 工程マスタレコード情報をIDで取得する
  const getProcessMstRecordById = (id) => {
    return [...processMstInfo]?.filter((record) => record?.id === id)?.[0];
  };

  // 新規作成の項目値を変更した場合、
  const onChangeInputForm = (value, isCheckZenkaku = true) => {
    if (isCheckZenkaku && regexExp.test(value)) {
      // 全角の場合、
      value = toHalfWidth(value); // 半角へ変換
    }
    setTmpAutoReserve({ ...tmpAutoReserve, size: value });
  };

  const addModalContent = (
    <div id="addNewAutoReserve" style={{ marginTop: 8 }}>
      <RTable id="param_detail_tbl" className="propertiesTbl">
        <thead>
          {/* 種別 */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">種別</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Select
                style={{ width: '98.2%' }}
                onChange={(e) => {
                  setTmpAutoReserve({ ...tmpAutoReserve, class: e, processType: '', processTypeNo: '' });
                }}
                id="service"
                name="service"
                defaultValue=""
              >
                <Select.Option id="defaultValue" value="">
                  {defaultValue.service}
                </Select.Option>
                {ServiceClasses?.filter(
                  // 板金子部品
                  (item) => item.value === ServiceClass.SheetMetal
                )?.map((record, index) => (
                  <Select.Option key={index} value={record?.value} id={record?.value}>
                    {record?.label}
                  </Select.Option>
                ))}
              </Select>
            </td>
          </tr>
          {/* 工程 */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">工程</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Select
                style={{ width: '98.2%' }}
                onChange={(e) => {
                  setTmpAutoReserve({ ...tmpAutoReserve, processType: e, processTypeNo: '' });
                }}
                id="processType"
                name="processType"
                defaultValue=""
                value={tmpAutoReserve?.processType}
              >
                <Select.Option id="defaultValue" value="">
                  {defaultValue.process}
                </Select.Option>
                {tmpAutoReserve?.class === ServiceClass.Parent ? (
                  // 親部品の場合、溶接を扱う
                  WorkTypes?.filter((item) => item.value === WorkType.PaWelding)?.map((record, index) => (
                    <Select.Option key={index} id={'processType' + record?.value} value={record?.value}>
                      {record?.label}
                    </Select.Option>
                  ))
                ) : tmpAutoReserve?.class === ServiceClass.SheetMetal ? (
                  // 板金子部品の場合、溶接・2次加工を扱う
                  WorkTypes?.filter(
                    (item) => item.value === WorkType.SmWelding || item.value === WorkType.SmSecondaryWork
                  )?.map((record, index) => (
                    <Select.Option key={index} id={'processType' + record?.value} value={record?.value}>
                      {record?.label}
                    </Select.Option>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            </td>
          </tr>
          {/* 加工 */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">加工</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Select
                style={{ width: '98.2%' }}
                onChange={(e) => {
                  setTmpAutoReserve({ ...tmpAutoReserve, processTypeNo: e });
                }}
                id="processTypeNo"
                name="processTypeNo"
                defaultValue=""
                value={tmpAutoReserve?.processTypeNo}
              >
                <Select.Option id="defaultValue" value="">
                  {defaultValue.processType}
                </Select.Option>
                {/* 種別・工程より、加工情報を扱う */}
                {getProcessTypeNoInfo(tmpAutoReserve?.class, tmpAutoReserve?.processType)?.map((item, index) => (
                  <Select.Option key={index} value={item?.value} id={item?.value}>
                    {item?.label}
                  </Select.Option>
                ))}
              </Select>
            </td>
          </tr>
          {/* 材質区分名 */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">材質区分名</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Select
                style={{ width: '98.2%' }}
                onChange={(e) => {
                  setTmpAutoReserve({ ...tmpAutoReserve, materialTypeId: e });
                }}
                id="materialType"
                name="materialType"
                defaultValue=""
              >
                <Select.Option id="defaultValue" value="">
                  {defaultValue.materialType}
                </Select.Option>
                {materialTypeList?.map((item, index) => (
                  <Select.Option key={index} id={'materialTypeId' + item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </td>
          </tr>
          {/* サイズ */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">サイズ</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                type="text"
                data-name="name"
                className={'input-editable'}
                value={tmpAutoReserve?.size}
                onBlur={(e) => onChangeInputForm(e.target.value)}
                onPressEnter={(e) => onChangeInputForm(e.target.value)}
                onChange={(e) => onChangeInputForm(e.target.value, false)}
                ref={(ref) => {
                  inputItemRef['size'] = ref;
                }}
                maxLength={Number.MAX_SAFE_INTEGER}
              />
            </td>
          </tr>
          {/* 表示 */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">表示</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <RadioGroup
                name="isUsed"
                defaultValue={true}
                className="radioCustomerGpEdit"
                onChange={(e) => {
                  setTmpAutoReserve((prevData) => ({
                    ...prevData,
                    info: { ...prevData.info, isUsed: e.target.value },
                  }));
                }}
                value={tmpAutoReserve?.info?.isUsed}
              >
                <Radio value={true}>オン</Radio>
                <Radio value={false}>オフ</Radio>
              </RadioGroup>
            </td>
          </tr>
        </thead>
      </RTable>
      {/** 一覧 */}
      <div>
        <Tabs
          className="estimate-detail-tab"
          type="card"
          defaultActiveKey="1"
          activeKey={activeTabKey}
          items={tabItems}
          onChange={onChange}
        />
      </div>
    </div>
  );

  const changeParentYousetsu = () => {
    if (isParentYousetsu == true) {
      setIsParentYousetsu(false);
    } else {
      setIsParentYousetsu(true);
    }
    setLatestData(true);
  };

  const changeIQ3Yousetsu = () => {
    if (isIQ3Yousetsu == true) {
      setIsIQ3Yousetsu(false);
    } else {
      setIsIQ3Yousetsu(true);
    }
    setLatestData(true);
  };

  const change2jikakou = () => {
    if (is2jikakou == true) {
      setIs2jikakou(false);
    } else {
      setIs2jikakou(true);
    }
    setLatestData(true);
  };

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

  const onRowSelect = (record) => {
    setChangeRecordId(record?.id);
    if (editMode) {
      setDiscardOKAction(actionArr[0]);
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        document.getElementById('update').style.display = 'none';
        document.getElementById('discard').style.display = 'none';
        document.getElementById('edit').style.display = 'block';
        setSelectedRowId(record.id);
        setSelectedData(record);
        setItemNo((currentPage - 1) * 25 + (index + 1));
        navigate(':iq3.materialSurface');
      }
    } else {
      setSelectedRowId(record?.id);
      setSelectedData(record);
    }
    props?.updateSelectedRowId(record?.id, 0, 0, 'ASC', 'id', {
      keyword: keyword,
      showFlag: showFlag,
    });
    props?.resetInitialParam();
  };

  const handleOk = (e) => {
    setVisibleGroupEditModal(false);
    setVisibleDeleteModal(false);
    setVisibleCSVOutput(false);
    setVisibleAddNewModal(false);
    setUpdateCancelConfirm(false);
  };

  const handleCancel = (e) => {
    setVisibleGroupEditModal(false);
    setVisibleDeleteModal(false);
    setVisibleAddNewModal(false);
  };

  // Idで降順する
  // const descOrderById = (data) => {
  //   if (data?.length > 0) {
  //     let sortedList = data?.sort((a, b) => b.id - a.id);
  //     return sortedList;
  //   } else {
  //     return data;
  //   }
  // };

  const updConfirmOk = async (e) => {
    let stateData = [];
    stateData = JSON.parse(JSON.stringify(autoReserve));
    if (visibleAddNewModal) {
      setUpdateCancelConfirm(false);
      // 画面の入力チェック
      if (!detailRef.current.formValidation(inputItemRef, formValidatorMode.Create)) {
        return;
      }
      props?.loading(true); // Waitingダイアログを表示にする
      tmpAutoReserve.processId = getProcessRecordId(tmpAutoReserve?.class, tmpAutoReserve?.processType);
      tmpAutoReserve.purchaseId = selectedPurchasesData?.id;
      let createdData = await createPurchaseAllocationData(tmpAutoReserve);
      stateData.push(createdData);
      setTmpFilteredData(stateData);
      setAutoReserve(stateData);
      resetAddModal();
      // onRowSelect(tmpAutoReserve);
      setSelectedRowId(createdData?.id);
      setSelectedData(createdData);
      setLatestData(false);
      setVisibleAddNewModal(false);
      props?.loading(false); // Waitingダイアログを非表示にする
    }
  };

  // 購入品引当マスタ情報をDBに新規登録する
  async function createPurchaseAllocationData(createData) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'PurchaseAllocation';
      const otherParam = {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          processId: createData.processId,
          processTypeNo: createData.processTypeNo,
          materialTypeId: createData.materialTypeId,
          size: createData.size,
          purchaseId: createData.purchaseId,
          info: createData.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  const callMethod = (methodName, param) => {
    const method = eval(methodName);
    if (typeof method === 'function') {
      method(param);
    }
  };

  // 編集中破壊確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmOk();
  };

  // 編集中破壊確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmCancel();
    /* setEditModeCancelConfirm(showEdtConfirmCancle);
    if (!showEdtConfirmCancle) {
      detailRef.current.discardConfirmCancel();
    } */
  };

  const updCancelOK = () => {
    setUpdateCancelConfirm(false);
    setVisibleAddNewModal(false);
    setVisibleGroupEditModal(false);
  };

  const updCancelCancel = () => {
    setUpdateCancelConfirm(false);
    if (visibleAddNewModal) {
      setVisibleAddNewModal(true);
    } else if (visibleGroupEditModal) {
      setVisibleGroupEditModal(true);
      setChkRowsCount(0);
    }
  };

  const updConfirmCancel = () => {
    setLatestData(false);
    setUpdateCancelConfirm(false);
  };

  const handleOkXMLInput = (e) => {
    setVisibleXMLInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.xml';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const showGroupEditModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[1]);
      setEditModeCancelConfirm(true);
    } else {
      handleGpEdit();
    }
  };

  const handleGpEdit = () => {
    setTmpAutoReserve({
      display: true,
      AutoReserve: '',
      lossFactor: '',
      remark: '',
    });
    setVisibleGroupEditModal(true);
    setChkRowsCount(0);
  };

  const showDeleteModal = () => {
    if (editMode) {
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        document.getElementById('update').style.display = 'none';
        document.getElementById('discard').style.display = 'none';
        document.getElementById('edit').style.display = 'block';
      }
      setVisibleDeleteModal(true);
    } else {
      setVisibleDeleteModal(true);
    }
  };

  const showCSVInputModal = () => {
    setVisibleCSVInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const showXMLInputModal = () => {
    setVisibleXMLInput(true);
  };

  const showAddNewModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[3]);
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        document.getElementById('update').style.display = 'none';
        document.getElementById('discard').style.display = 'none';
        document.getElementById('edit').style.display = 'block';
        handleAddFormSubmit();
      }
    } else {
      handleAddFormSubmit();
    }
  };

  // 新規作成ボタンの押下
  const handleAddFormSubmit = () => {
    setTmpAutoReserve({
      class: '',
      processId: '',
      processType: '',
      processTypeNo: '',
      materialTypeId: '',
      size: 0,
      purchaseId: '',
      info: { isUsed: true, remarks: '' },
      created: dayjs().format('YYYY-MM-DD'),
      modified: dayjs().format('YYYY-MM-DD'),
    });
    setVisibleAddNewModal(true);
  };

  const addOK = () => {
    setIsAdd(true);
    setUpdateCancelConfirm(true);
  };

  const addCancel = () => {
    setIsAdd(false);
    setUpdateCancelConfirm(showEdtConfirmCancle);
    if (!showEdtConfirmCancle) {
      updCancelOK();
    }
  };

  const deleteOk = async () => {
    setVisibleDeleteModal(false);
    if (!selectedData?.id) return;
    props?.loading(true); // Waitingダイアログを表示にする
    let deletedData = await deletePurchaseAllocationData(selectedData?.id);
    // IQX_WEBEST-281 使用しているパラメータなのに削除出来てしまう
    if (deletedData !== undefined && deletedData?.id > 0) {
      let data = [...autoReserve];
      data = data?.filter((item) => item?.id != deletedData?.id);
      setAutoReserve(data);
      setTmpFilteredData(data);
      setFilteredData(data);
      setSelectedRowId(data?.length > 0 ? data[0]?.id : -1);
      setSelectedData(data?.length > 0 ? data[0] : []);
      props?.updateSelectedRowId(data?.[0]?.id, 0, 0, 'ASC', 'id', {
        keyword: keyword,
        showFlag: showFlag,
      });
    }
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // 購入品引当マスタ情報をDBに削除する
  async function deletePurchaseAllocationData(id) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'PurchaseAllocation/' + id;
      const otherParam = {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  const deleteCancel = () => {
    setVisibleDeleteModal(false);
  };

  const resetAddModal = () => {
    setTmpAutoReserve({
      class: '',
      processId: '',
      processType: '',
      processTypeNo: '',
      materialTypeId: '',
      size: 0,
      purchaseId: '',
      info: { isUsed: true, remarks: '' },
      created: dayjs().format('YYYY-MM-DD'),
      modified: dayjs().format('YYYY-MM-DD'),
    });
  };

  // フラグ、キーワードに変更された場合、
  useEffect(() => {
    if (isFilterFlg) {
      // 絞り込み、
      getDataByFilter();
    }
  }, [keyword, showFlag, tmpFilteredData, isFilterFlg]);

  // 編集中破棄確認ポップアップに「OK」又は「キャンセル」を押下
  useEffect(() => {
    if (!editMode && !editModeCancelConfirm) {
      if (discardOKAction.key === 0 && changeRecordId !== undefined) {
        setSelectedRowId(changeRecordId);
        setSelectedData(filteredData?.find((item) => item?.id === changeRecordId));
      } else if (discardOKAction.key === 0 && changeRecordId === undefined) {
        return;
      } else if (discardOKAction.key === actionArr[6]?.key) {
        // CSV入力
        onClickCSVImport();
      } else if (discardOKAction.key === actionArr[7]?.key) {
        // CSV出力
        onClickCSVExport();
      } else {
        const action = actionArr?.find((item) => item.key === discardOKAction.key);
        if ([1, 4, 5]?.includes(discardOKAction.key)) {
          callMethod(action.methodName, filterKey);
        } else {
          callMethod(action.methodName);
        }
      }
    }
  }, [editModeCancelConfirm]);

  const beforeSearch = (e) => {
    {
      let action;
      switch (typeof e) {
        case 'object':
          action = actionArr[1];
          break;
        case 'string':
          action = actionArr[4];
          break;
        case 'number':
          action = actionArr[5];
          break;
      }
      setFilterKey(e);
      setDiscardOKAction(action);
      callMethod(action.methodName, e);
      setIsFilterFlg(true);
    }
  };

  // 無効フラグの押下
  /*  const searchByDataState = (value) => {
    dataState === value ? getDataByFilter() : setDataState(value);
  }; */

  const searchByShowFlg = (value) => {
    showFlag === value ? getDataByFilter() : setShowFlag(value);
  };

  // キーワードで検索した場合、
  /*  const searchByKeyword = (e) => {
    switch (e.target.name) {
      case 'name':
        nameKeyword === e.target.value ? getDataByFilter() : setNameKeyWord(e.target.value);
        break;
      case 'model':
        modelKeyword === e.target.value ? getDataByFilter() : setModelKeyWord(e.target.value);
        break;
    }
  }; */

  // キーワードで検索した場合、
  const searchByKeyword = (e) => {
    keyword === e.target.value ? getDataByFilter() : setKeyWord(e.target.value);
  };

  // 絞り込み、
  const getDataByFilter = () => {
    if (autoReserve?.length <= 0) {
      return;
    }
    let searchBarText = keyword;
    let currentData = [...autoReserve];
    let flag;
    let currentFilterData = currentData;

    // キーワードで絞り込み
    if (searchBarText !== '') {
      currentFilterData = currentData?.filter((item) => {
        return Object.keys(item).some((key) => {
          // IQX_WEBEST-378 パラメーター自動引当の絞込にキーワードで検索できていない。
          if (key === 'materialTypeId') {
            let materialLabel = materialTypeList?.find(
              (materialItem) => materialItem.value === item.materialTypeId
            )?.label;
            return materialLabel?.toLowerCase()?.includes(searchBarText.toLowerCase());
          } else if (key === 'purchaseId') {
            let supplierName = getPurchaseDetailInfo(item.purchaseId)?.info?.supplierName; // 仕入先
            let price = getPurchaseDetailInfo(item.purchaseId)?.price; // 単価
            let processTime = getPurchaseDetailInfo(item.purchaseId)?.info?.processTime; // 取付時間
            processTime = secondsToHms(processTime);
            let purchaseNm = getPurchaseDetailInfo(item.purchaseId)?.name;
            return (
              purchaseNm?.toLowerCase()?.includes(searchBarText.toLowerCase()) ||
              supplierName?.toLowerCase()?.includes(searchBarText.toLowerCase()) ||
              price == searchBarText ||
              processTime?.toLowerCase()?.includes(searchBarText.toLowerCase())
            );
          } else if (key === 'size') {
            return item.size == searchBarText;
          } else if (key === 'created' || key === 'modified') {
            let date = key === 'created' ? formatDate(item.created) : formatDate(item.modified);
            return date?.toLowerCase()?.includes(searchBarText.toLowerCase());
          } else {
            return String(item[key]).toLowerCase()?.includes(searchBarText.toLowerCase());
          }
        });
      });
    }

    //  無効フラグで絞り込み
    if (showFlag === 1 || showFlag === 2) {
      flag = showFlag === 1 ? true : false;
      currentFilterData = currentFilterData?.filter((item) => item?.info?.isUsed === flag);
    }
    setIsFilter(true);
    setFilteredData(currentFilterData);
    categorizedAutoReserve(currentFilterData, true);
    props?.updateSelectedRowId(selectedRowId, 0, 0, 'ASC', 'id', {
      keyword: keyword,
      showFlag: showFlag,
    });
    setIsFilterFlg(false);
  };

  document.body.style.overflowY = 'hidden';

  const reportModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const setSplitPaneSize = (splitSize) => {
    setSizes(splitSize);
  };

  // 更新確認のOKを押下で、一覧画面のデータを反映する
  const submitData = async (updateData) => {
    var tmpData = [...autoReserve];
    var updatedInfo = [];
    props?.loading(true); // Waitingダイアログを表示にする
    updatedInfo = await updatePurchaseAllocationData(updateData);

    var index = tmpData?.findIndex((item) => item?.id === updatedInfo?.id);
    tmpData[index] = updatedInfo;
    setAutoReserve(tmpData);
    setTmpAutoReserve(tmpData);
    setTmpFilteredData(tmpData);
    setSelectedRowId(updatedInfo?.id);
    setSelectedData(updatedInfo);
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // 購入品引当マスタ情報をDBに更新する
  async function updatePurchaseAllocationData(updateData) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'PurchaseAllocation';
      const otherParam = {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          id: updateData.id,
          processId: updateData.processId,
          processTypeNo: updateData.processTypeNo,
          materialTypeId: updateData.materialTypeId,
          size: updateData.size,
          purchaseId: updateData.purchaseId,
          info: updateData.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 編集モードを変更
  const updateEditMode = (mode) => {
    setEditMode(mode);
    props.updateMainParameterEditMode(mode);
  };

  // 加工種別バーを押下
  const changeProcessType = (process) => {
    if (document.getElementById('tbl' + process) !== null) {
      let showHide = document.getElementById('tbl' + process)?.style.display;
      if (showHide == 'block') {
        document.getElementById('tbl' + process).style.display = 'none';
        document.getElementById('arrowUp' + process).style.display = 'none';
        document.getElementById('arrowDown' + process).style.display = 'block';
      } else {
        document.getElementById('tbl' + process).style.display = 'block';
        document.getElementById('arrowUp' + process).style.display = 'block';
        document.getElementById('arrowDown' + process).style.display = 'none';
      }
      isResize ? setIsResize(false) : !isResize && setIsResize(true);
    }
  };

  // 種別より、表示一覧のコンテンツ
  const itemCollapse = (processTypeName, purchaseAllocationData, classId, workTypeId) => {
    const process = classId.toString() + workTypeId.toString() + processTypeName.toString();
    return (
      <div>
        <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
          <Col span={24} className="deli-col" onClick={(e) => changeProcessType(process)}>
            <div style={{ display: 'flex' }} className="ddl_2_noImg">
              <CaretUpOutlined
                id={'arrowUp' + process}
                style={{ fontSize: 17, marginTop: 2, display: activeProcessTbl === process ? 'block' : 'none' }}
              />
              <CaretDownOutlined
                id={'arrowDown' + process}
                style={{ fontSize: 17, marginTop: 2, display: activeProcessTbl === process ? 'none' : 'block' }}
              />
              <label style={{ marginRight: 5 }}>{processTypeName}</label>
            </div>
          </Col>
        </Row>
        <div id={'tbl' + process} style={{ display: activeProcessTbl === process ? 'block' : 'none' }}>
          <Row style={{ color: 'black', display: 'inherit' }} className="oyabuhin-detail oyabuhin-row ">
            <Table
              showSorterTooltip={false}
              columns={hasCheckData ? tableColumns : chkData}
              dataSource={purchaseAllocationData}
              scroll={{ y: 250, x: '10vw' }}
              rowClassName={(record) => (record?.id == selectedRowId ? 'active-row' : 'data-row')}
              rowKey={(record) => record?.id}
              pagination={false}
              className="drop-down-tbl"
              onRow={(record, index) => {
                return {
                  onClick: (event) => {
                    onRowSelect(record);
                  },
                };
              }}
            />
          </Row>
        </div>
      </div>
    );
  };

  // CSVヘッダの情報
  const getCSVHeaderInfo = () => {
    let str = '';
    columns?.map((col, index) => {
      if (index === 1) {
        str += ',種別,工程,加工';
      }
      if (str.length === 0) {
        str += col.title;
      } else {
        str += ',' + col.title;
      }
    }); // ヘッダの情報
    str = 'ID,' + str;
    return str;
  };

  // CSV出力情報
  const getCsvExportData = () => {
    let str = '';
    str = getCSVHeaderInfo();
    filteredData.map((data, index) => {
      let mstProcess = getProcessMstRecordById(data.processId);
      // CSV内容
      str +=
        '\n' +
        data.id +
        ',' +
        (index + 1) +
        ',' +
        ServiceClasses?.find((item) => item.value === mstProcess?.class)?.label + // 種別
        ',' +
        mstProcess?.name + // 工程
        ',' +
        getProcessTypeNoInfo(mstProcess?.class, mstProcess?.workType)?.find((item) => item.value === data.processTypeNo) // 加工
          ?.label +
        ',' +
        materialTypeList?.find((item) => item.value === data.materialTypeId)?.label + // 材質区分名
        ',' +
        data.size + // サイズ
        ',' +
        getPurchaseDetailInfo(data.purchaseId)?.name + // 購入品名称
        ',' +
        getPurchaseDetailInfo(data.purchaseId)?.info?.supplierName + // 仕入先
        ',' +
        getPurchaseDetailInfo(data.purchaseId)?.price + // 単価
        ',' +
        getPurchaseDetailInfo(data.purchaseId)?.info?.processTime + // 取付時間
        ',' +
        formatDate(data.created) + // 作成日
        ',' +
        formatDate(data.modified) + // 更新日
        ',' +
        data.modifier + // 更新者
        ',' +
        data.info.remarks + // 備考
        ',' +
        data.info.isUsed; // 表示
    });
    return str;
  };

  // CSV入力情報
  const submitCsvImportData = async (dataList) => {
    let createdDatas = [];
    let failedDatas = [];
    messageRef.current = [];
    let tempDatas = [];
    props?.loading(true); // Waitingダイアログを表示にする
    // ヘッダの情報
    if (JSON.stringify(dataList[0]) !== JSON.stringify(getCSVHeaderInfo())) {
      // 不正なヘッダの情報
      messageRef?.current?.push(<li>{ErrorMessage('1行目').E016}</li>);
    } else {
      for (let i = 1; i < dataList.length; i++) {
        let tempData = {
          class: '',
          processId: '',
          processTypeNo: '',
          materialTypeId: '',
          size: 0,
          purchaseId: '',
          info: { isUsed: true, remarks: '' },
        };
        let data = dataList[i].split(',');
        if (data && data?.[0].length === 0) continue;
        if (!csvImportDataValidation(i + 1, data)) {
          failedDatas.push(dataList[i]);
          continue;
        }
        let classId = ServiceClasses?.find((item) => item.label === data?.[2])?.value;
        tempData.id = data?.[0];
        tempData.class = classId; // 種別
        let workTypeNo = WorkTypes?.find(
          (item) =>
            (item.value === WorkType.SmWelding || item.value === WorkType.SmSecondaryWork) && item.label === data?.[3]
        )?.value;
        let processId = getProcessRecordId(classId, workTypeNo);
        tempData.processId = processId; // 工程
        let processTypeNo = getProcessTypeNoInfo(classId, workTypeNo)?.find((item) => item.label === data?.[4]);
        tempData.processTypeNo = processTypeNo?.value; // 加工
        tempData.materialTypeId = materialTypeList?.find((item) => item.label === data?.[5])?.value; // 材質区分名
        tempData.size = data?.[6]; // サイズ
        tempData.purchaseId = purchaseMstInfo?.find((item) => item.name === data?.[7])?.id; // 購入品名称
        tempData.info.remarks = data?.[14]; // 備考
        tempData.info.isUsed = data?.[15] ? JSON.parse(data?.[15]?.toLowerCase()) : true; // 表示 // IQX_WEBEST-301
        tempDatas.push(tempData);
      }
      createdDatas = await createPurchaseAllocationCSV(tempDatas);
      if (createdDatas) {
        if (createdDatas !== undefined && createdDatas?.length > 0) {
          setAutoReserve(createdDatas);
          setFilteredData(createdDatas?.filter((item) => item.info.isUsed === true));
          setSelectedData(createdDatas?.[0]);
          setSelectedRowId(createdDatas?.[0]?.id);
          props?.updateSelectedRowId(createdDatas?.[0]?.id, 0, 0, 'ASC', 'id', {
            keyword: keyword,
            showFlag: showFlag,
          });
        }
      }
    }
    Modal.success({
      width: 500,
      className: 'modal-complete csv-modal-complete',
      title: '完了',
      content: (
        <p style={{ fontSize: '13.5px', whiteSpace: 'pre-line', lineHeight: 2 }}>
          {'CSV入力のデータ保存は完了しました。正常：' + tempDatas?.length + '件、異常：' + failedDatas?.length + '件'}
          {messageRef.current?.length > 0 ? (
            <>
              <br></br>
              {'下記の内容をご確認ください。'}
              <br></br>
              <ul>{messageRef.current}</ul>
            </>
          ) : (
            <></>
          )}
        </p>
      ),
    });
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // CSV入力情報チェック
  const csvImportDataValidation = (lineNo, data) => {
    // CSV入力情報チェック
    let no = lineNo + '行目の';

    // 種別
    let input = data[2];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('種別').E007}</li>);
      return false;
    }

    if (input?.length > 0 && input !== ServiceClasses?.find((item) => item.value === ServiceClass.SheetMetal)?.label) {
      // 不正な種別
      messageRef?.current?.push(<li>{no + ErrorMessage('種別').E016}</li>);
      return false;
    }

    // 工程
    input = data[3];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('工程').E007}</li>);
      return false;
    }

    let workType = WorkTypes?.filter(
      (item) => (item.value === WorkType.SmWelding || item.value === WorkType.SmSecondaryWork) && item.label === input
    );
    if (input?.length > 0 && workType?.length === 0) {
      // 不正な工程
      messageRef?.current?.push(<li>{no + ErrorMessage('工程').E016}</li>);
      return false;
    }

    // 加工
    input = data[4];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('加工').E007}</li>);
      return false;
    }

    let processTypeNo = getProcessTypeNoInfo(ServiceClass.SheetMetal, workType?.[0]?.value);
    if (input?.length > 0 && processTypeNo?.findIndex((item) => item.label === input) === -1) {
      // 不正な加工
      messageRef?.current?.push(<li>{no + ErrorMessage('加工').E016}</li>);
      return false;
    }

    // 材質区分名
    input = data[5];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('材質区分名').E007}</li>);
      return false;
    }

    if (input?.length > 0 && materialTypeList?.findIndex((item) => item.label === input) === -1) {
      // 不正な材質区分名
      messageRef?.current?.push(<li>{no + ErrorMessage('材質区分名').E016}</li>);
      return false;
    }

    // サイズ
    input = data[6];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('サイズ').E007}</li>);
      return false;
    }

    if (input?.length > 0 && !isDigit(input)) {
      messageRef?.current?.push(<li>{no + ErrorMessage('サイズ').E009}</li>);
      return false;
    }

    // 購入品名称
    input = data[7];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('購入品名称').E007}</li>);
      return false;
    }

    if (input?.length > 0 && purchaseMstInfo?.findIndex((item) => item.name === input) === -1) {
      // 不正な購入品名称
      messageRef?.current?.push(<li>{no + ErrorMessage('購入品名称').E016}</li>);
      return false;
    }

    return true;
  };

  // CSV入力ボタンを押下した場合、
  const onClickCSVImport = () => {
    props?.onClickCSVImport();
  };

  // CSV出力ボタンを押下した場合、
  const onClickCSVExport = () => {
    props?.onClickCSVExport();
  };

  return (
    <>
      {/* 一覧とプロパティ表示 */}
      <div
        style={{
          height: '90.8vh',
          marginLeft: 10,
        }}
      >
        <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1}>
          <div style={{ marginTop: 7 }}>
            {/*  クイック検索 */}
            {listShowHide ? (
              <>
                <Space size="middle">
                  <Input
                    className="input-editable search-select-input"
                    placeholder="キーワードを入力"
                    allowClear
                    onPressEnter={beforeSearch}
                    onBlur={beforeSearch}
                    // onChange={(e) => {
                    //   if (e.target.value === '') {
                    //     beforeSearch(e);
                    //   }
                    // }}
                    name="model"
                    id="model"
                    onChange={beforeSearch}
                    value={keyword}
                  />
                  <Select
                    style={{ width: 90 }}
                    onChange={beforeSearch}
                    placeholder="表示・非表示"
                    id="showFlgDrop"
                    name="showFlgDrop"
                    value={showFlag}
                  >
                    {dataStateList?.map((item, index) => (
                      <Select.Option key={index} id={'showFlg' + item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Space>
                {/* 一覧アリア */}
                <div className="overflow-auto autoReservedContainer" style={{ maxHeight: 806 }}>
                  {/* 親部品 */}
                  {/* <Row style={{ color: 'black', marginTop: 10 }} className="oyabuhin oyabuhin-row">
                    <Col span={20} className="deli-col">
                      <div style={{ display: 'flex' }}>
                        <label style={{ marginRight: 5 }}>親部品</label>
                      </div>
                    </Col>
                  </Row> */}
                  {/*　溶接 */}
                  {/* <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={changeParentYousetsu}>
                    <Col span={20} className="deli-col">
                      <div style={{ display: 'flex' }}>
                        {isParentYousetsu ? (
                          <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                        ) : (
                          <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                        )}
                        <label style={{ marginRight: 5 }}>溶接</label>
                      </div>
                    </Col>
                  </Row>
                  <div style={{ display: isParentYousetsu ? 'block' : 'none' }}>
                    {(() => {
                      const subArr = [];
                      if (parentYousetsuArr !== undefined) {
                        for (let i = 0; i < parentYousetsuArr?.length; i++) {
                          subArr.push(
                            itemCollapse(
                              parentYousetsuArr[i].processTypeName,
                              parentYousetsuArr[i].data,
                              parentYousetsuArr[i].classId,
                              parentYousetsuArr[i].workTypeId
                            )
                          );
                        }
                      }
                      return subArr;
                    })()}
                  </div> */}

                  {/* iQ3板金子部品 */}
                  <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
                    <Col span={20} className="deli-col">
                      <div style={{ display: 'flex' }}>
                        <label style={{ marginRight: 5 }}>iQ3板金子部品</label>
                      </div>
                    </Col>
                  </Row>
                  {/*　溶接 */}
                  <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={changeIQ3Yousetsu}>
                    <Col span={20} className="deli-col">
                      <div style={{ display: 'flex' }}>
                        {isIQ3Yousetsu ? (
                          <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                        ) : (
                          <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                        )}
                        <label style={{ marginRight: 5 }}>溶接</label>
                      </div>
                    </Col>
                  </Row>
                  <div style={{ display: isIQ3Yousetsu ? 'block' : 'none' }}>
                    {(() => {
                      const subArr = [];
                      if (iQ3YousetsuArr !== undefined) {
                        for (let i = 0; i < iQ3YousetsuArr?.length; i++) {
                          subArr.push(
                            itemCollapse(
                              iQ3YousetsuArr[i].processTypeName,
                              iQ3YousetsuArr[i].data,
                              iQ3YousetsuArr[i].classId,
                              iQ3YousetsuArr[i].workTypeId
                            )
                          );
                        }
                      }
                      return subArr;
                    })()}
                  </div>
                  {/* 2次加工 */}
                  <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={change2jikakou}>
                    <Col span={20} className="deli-col">
                      <div style={{ display: 'flex' }}>
                        {is2jikakou ? (
                          <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                        ) : (
                          <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                        )}
                        <label style={{ marginRight: 5 }}>2次加工</label>
                      </div>
                    </Col>
                  </Row>
                  <div style={{ display: is2jikakou ? 'block' : 'none' }}>
                    {(() => {
                      const subArr = [];
                      if (iQ3NijikakouArr !== undefined) {
                        for (let i = 0; i < iQ3NijikakouArr?.length; i++) {
                          subArr.push(
                            itemCollapse(
                              iQ3NijikakouArr[i].processTypeName,
                              iQ3NijikakouArr[i].data,
                              iQ3NijikakouArr[i].classId,
                              iQ3NijikakouArr[i].workTypeId
                            )
                          );
                        }
                      }
                      return subArr;
                    })()}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div>
            {/* <Outlet /> */}
            <Routes>
              <Route
                exact
                path=":common.autoReserve"
                element={
                  <AutoReserveDetails
                    ref={detailRef}
                    editMode={editMode}
                    selectedData={selectedData}
                    paramType={paramType}
                    submitData={submitData}
                    updateEditMode={updateEditMode}
                    processMstInfo={processMstInfo}
                    purchaseMstInfo={purchaseMstInfo}
                    purchaseMstInfoByAllocation={purchaseMstInfoByAllocation}
                    getProcessTypeNoInfo={getProcessTypeNoInfo}
                    materialTypeList={materialTypeList}
                    getPurchaseDetailInfo={getPurchaseDetailInfo}
                    getProcessRecordId={getProcessRecordId}
                    showEdtConfirmOK={showEdtConfirmOK}
                    showEdtConfirmCancle={showEdtConfirmCancle}
                  />
                }
              />
            </Routes>
          </div>
        </SplitPane>
      </div>

      {/* 削除_Modal */}
      {visibleDeleteModal &&
        commonModal(
          visibleDeleteModal,
          confirmModalTitle,
          commonDeleteFooter(deleteOk, deleteCancel),
          null,
          400,
          handleOk,
          handleCancel,
          confirmDeleteContent,
          null,
          null,
          false
        )}

      {/* XML呼出 */}
      <Modal
        //title="確認"
        title={reportModalTitle}
        open={visibleXMLInput}
        onOk={handleOkXMLInput}
        onCancel={handleCancel}
        footer={[
          <Button key="file" type="primary" onClick={handleOkXMLInput} className="btn-ok-cancel">
            OK
          </Button>,
          <Button key="back" onClick={handleCancel} className="btn-ok-cancel">
            Cancel
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p>
          装置情報（時間チャージ、段取設定、加工設定）を初期パラメータ作成情報から取り込みます。
          <br></br>装置情報は、呼出情報で更新されます。
        </p>
      </Modal>

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}

      {/* 新規追加Modal */}
      {visibleAddNewModal &&
        commonModal(
          visibleAddNewModal,
          modalTitle('自動引当（新規追加）', 1000, -24),
          commonActionFooter(addOK, addCancel, '登録', '破棄'),
          null,
          1000,
          handleOk,
          handleCancel,
          addModalContent,
          'addNewAutoReserveModal',
          null,
          false
        )}

      {/* 一括編集　Modal */}
      {/* {visibleGroupEditModal &&
        commonModal(
          visibleGroupEditModal,
          modalTitle('自動引当（選択したデータをまとめて編集）', 1790, -24),
         
          null,
          null,
          1790,
          handleOk,
          handleCancel,
          gpEditModalContent,
          'updateModalStyle',
          null,
          true
        )} */}

      {/** 新規追加破棄確認ポップアップ */}
      {updateCancelConfirm &&
        commonModal(
          updateCancelConfirm,
          confirmModalTitle,
          commonFooter(isAdd ? updConfirmOk : updCancelOK, isAdd ? updConfirmCancel : updCancelCancel),
          null,
          400,
          updCancelOK,
          updCancelCancel,
          isAdd ? (visibleGroupEditModal ? confirmUpdateContent : confirmCreateContent) : confirmDiscardContent,
          null,
          null,
          false
        )}
    </>
  );
});

export default AutoReserveList;
