import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Row, Col, Input, Select, Popover, Checkbox, Space, InputNumber, notification, Button } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';

import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  checkInput,
  secondsToHms,
  setTimetoSec,
  checkInputWithColor,
  handleCollpse,
  handleShowHide,
  checkShowHideIcon,
  handleKeyPress,
  kouteiCalculateSelectedArr,
  handleChangeCheckData,
  toDecimal,
} from '../common/Common.js';
import { DataType, MaterialSurfaceBillingUnit, ServiceClass, WorkType, WorkTypeGroup } from '../common/enums';
import { amtEmptyStr, timeHmsStr, DataButton, helpCmt } from '../common/Constant';

const IQ3KouteiCalculate_YosetsuFinish = forwardRef((props, ref) => {
  const [isYosetsuFinishdetail, setIYosetsuFinishdetail] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [totalAmt, setTotalAmt] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [totalSagyoAmt, setTotalSagyoAmt] = useState(0);
  const [totalSagyoTime, setTotalSagyoTime] = useState(0);
  const [totalDandoriAmt, setTotalDandoriAmt] = useState(0);
  const [totalDandoriTime, setTotalDandoriTime] = useState(0);

  const [totalSum, setTotalSum] = useState(0);
  const [totalTimeSum, setTotalTimeSum] = useState(0);
  const [sagyoAmtSum, setSagyoAmtSum] = useState(0);
  const [sagyoTimeSum, setSagyoTimeSum] = useState(0);
  const [dandoriAmtSum, setDandoriAmtSum] = useState(0);
  const [dandoriTimeSum, setDandoriTimeSum] = useState(0);
  const [processArr, setProcessArr] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [oldEditedData, setOldEditedData] = useState([]);
  const [patterns, setPatterns] = useState([]);

  const [editedHandelingAmt, setEditedHandelingAmt] = useState();
  const [editedHandelingTime, setEditedHandelingTime] = useState();
  const [editedKensaAmt, setEditedKensaAmt] = useState();
  const [editedKensaTime, setEditedKensaTime] = useState();

  const [subYousetsusArr, setSubYousetsuArr] = useState([]);
  const [yousetsusArr, setYousetsuArr] = useState([]);
  const [processCount, setProcessCount] = useState();

  const [subSagyoTotal, setSubSagyoTotal] = useState([]);
  const [selectedDataFlg, setSelectedDataFlg] = useState(0);
  const [partThickness, setPartThickness] = useState(0);
  const [updateEditedData, setUpdateEditedData] = useState([]);
  const [weldingFinishAmt, setWeldingFinishAmt] = useState(0);
  const [deviceMst, setDeviceMst] = useState([]);
  const [processMst, setProcessMst] = useState([]);
  const [patternInfo, setPatternInfo] = useState([]);

  const [sizeCoeff, setSizeCoeff] = useState();
  const [diffCoeff, setDiffCoeff] = useState();

  const JPYs = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'JPY',
  });

  const handleChangeCheckData = (e, i) => {
    const data = { ...updateEditedData };
    data.dataType = e;
    setUpdateEditedData(data);
    setWeldingFinishAmt(e == DataType.Data ? data.totalDataPrice : data.totalEditPrice);
    props?.updateIQ3KouteiCal(data, WorkType.SmWeldFinish);
  };

  const calculateTotals = (editDataArr) => {
    let sagyoAmtSum = 0;
    let dandoriAmtSum = 0;

    let sagyoTimeSec = 0;
    let dandoriTimeSec = 0;

    editDataArr?.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt ? Math.round(element.sagyoAmt) : 0;
      dandoriAmtSum += element.dandoriAmt ? Math.round(element.dandoriAmt) : 0;
      sagyoTimeSec += element.sagyoTime;
      dandoriTimeSec += element.dandoriTime;
    });

    // sagyoAmtSum += parseInt(editDataArr[0].handelingAmt);
    // sagyoTimeSec += setTimetoSec(editDataArr[0].handelingTime);

    // let totalSum = Math.round(sagyoAmtSum + dandoriAmtSum + editDataArr[0].kensaAmt);
    let totalSum = (sagyoAmtSum ? Math.round(sagyoAmtSum) : 0) + (dandoriAmtSum ? Math.round(dandoriAmtSum) : 0); // 画面表示用（四捨五入した金額を足す）
    let sagyoTimeSum = sagyoTimeSec;
    let dandoriTimeSum = dandoriTimeSec;
    // let totalTimeSum = secondsToHms(sagyoTimeSec + dandoriTimeSec + setTimetoSec(editedKensaTime));
    let totalTimeSum = sagyoTimeSec + dandoriTimeSec;

    let totals = [];
    let i = 0;
    totals.push({
      id: i++,
      sagyoAmtSum: sagyoAmtSum,
      dandoriAmtSum: dandoriAmtSum,
      sagyoTimeSec: sagyoTimeSec,
      dandoriTimeSec: dandoriTimeSec,
      totalSum: totalSum,
      sagyoTimeSum: sagyoTimeSum,
      dandoriTimeSum: dandoriTimeSum,
      totalTimeSum: totalTimeSum,
    });
    return totals;
  };

  useEffect(() => {
    let editData = [];
    let totals = [];
    let toBEdit = [];
    let processMst = [];
    let weldProcessMst = [];
    let deviceMst = [];
    setTotalAmt(0);
    setTotalTime(0);
    setTotalSum(0);
    setTotalTimeSum(0);
    setTotalSagyoAmt(0);
    setSagyoAmtSum(0);
    setTotalSagyoTime(0);
    setSagyoTimeSum(0);
    setTotalDandoriAmt(0);
    setDandoriAmtSum(0);
    setTotalDandoriTime(0);
    setDandoriTimeSum(0);
    setWeldingFinishAmt(0);

    let parameters = props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
    if (parameters?.process?.length > 0) {
      processMst = parameters?.process
        ? parameters?.process?.filter(
            (item) => item?.class === ServiceClass.SheetMetal && item.workType === WorkType.SmWeldFinish
          )[0]
        : [];
      setProcessMst(processMst ? processMst : []);
      if (parameters?.device?.length > 0) {
        deviceMst = parameters?.device?.filter((item) => processMst?.id == item?.processId);
      }
    }

    let patternInfo = props?.selectedIQ3DataDetail?.processSelect
      ? props?.selectedIQ3DataDetail?.processSelect?.details?.filter((item) => item.processId == processMst?.id)
      : [];
    setPatternInfo([]);
    if (patternInfo == undefined) return;
    if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;

    deviceMst =
      deviceMst && patternInfo
        ? deviceMst?.filter(
            (item) => item.processId == patternInfo[0]?.processId && item.id == patternInfo[0]?.deviceId
          )[0]
        : []; //テスト用ID;
    setDeviceMst(deviceMst ? deviceMst : []);
    if (deviceMst == undefined) return;
    setPatternInfo(patternInfo);
    // 選択された行の工程積算情報を正しく取得するための対応
    let curSelectedIQ3DataDetail = props?.selectedEstimateData?.esIq3Info?.filter(
      (item) => item.no === props?.selectedIQ3DataDetail?.no
    );
    let selectedIQ3DataDetail = curSelectedIQ3DataDetail?.length > 0 ? curSelectedIQ3DataDetail?.[0] : [];
    if (selectedIQ3DataDetail?.length === 0) return;
    let weldingFinishAdditions = selectedIQ3DataDetail?.additions?.filter(
      (item) => item.processId == processMst?.id
    )[0];
    // IQX_WEBEST-16 工程積算タブで編集データのチェックがOFFだと、手入力で入力した内容がリセットされてしまう
    let weldingFinishAdditionsEditData = weldingFinishAdditions?.editItems;
    let editDataFlg = weldingFinishAdditions?.editDataFlg;
    // 溶接工程入力情報取得
    weldProcessMst = parameters?.process
      ? parameters?.process?.filter(
          (item) => item?.class === ServiceClass.SheetMetal && item.workType === WorkType.SmWelding
        )[0]
      : [];
    let weldingProcessInput = props?.selectedIQ3DataDetail?.processInput?.filter(
      (item) => item.processId == weldProcessMst?.id
    )[0];

    let detailItems = [];
    let editDetailItems = [];
    // 重量
    let weight = props?.selectedIQ3DataDetail?.weight;
    // 面積
    let area = Number(props?.selectedIQ3DataDetail?.totalSurfaceArea);
    let weldIndex = 0;
    //溶接情報設定
    for (let j = 0; j < weldingProcessInput?.details?.[0]?.weldingList?.length; j++) {
      let weldingList = weldingProcessInput?.details?.[0]?.weldingList[j];
      //溶接仕上が適用の場合のみ
      if (weldingList?.senchouYousetsushiage == 0) {
        //重量と面積が０の場合、計算しない。
        if (weight === 0 && area === 0) {
        } else {
          // 線長の場合、長さ/箇所が０の場合、計算しない。（IQX_WEBEST-54）
          // 仮付けの場合、長さ/箇所が０の場合、計算しない。（IQX_WEBEST-54）
          if (Number(weldingList?.karitsukeCnt) !== 0 || Number(weldingList?.senchou) !== 0) {
            let weldProcess = weldProcessMst?.details?.processDetailTypes?.filter(
              (item) => item.id == weldingList.yousetsuType
            )?.[0];
            detailItems.push({
              id: weldIndex + 1,
              processName: weldProcess?.name,
              processTypeId: weldProcess?.id,
              dandoriAmt: 0,
              dandoriTime: 0,
              dandoriDetails: [],
              sagyoAmt: 0,
              sagyoTime: 0,
              sagyoDetails: [],
              senchou: Number(weldingList.senchou), //溶接加工長
              senchouKaisu: Number(weldingList.senchouKaisu), //溶接加工長回数
              formula: '',
              yousetsuType: '',
            });
            //工程選択から処理情報取得
            let multiprocesses = patternInfo[0].multiprocesses;
            for (let i = 0; i < multiprocesses?.length; i++) {
              //溶接仕上工程マスター情報取得
              let processTypeNm = processMst?.details?.processDetailTypes?.filter(
                (item) => item.id == multiprocesses[i].processDetailTypeId
              )?.[0];
              if (processTypeNm == undefined || processTypeNm?.length == 0) continue;
              let dandoriDetails = {
                id: i + 1,
                processDetailTypeId: processTypeNm?.id,
                processDetailName: processTypeNm?.name,
                dandoriAmt: 0,
                dandoriTime: 0,
              };
              let sagyoDetail = {
                id: i + 1,
                processDetailTypeId: processTypeNm?.id,
                processDetailName: processTypeNm?.name,
                sagyoAmt: 0,
                sagyoTime: 0,
              };
              let detailItemsIndex = detailItems?.length === 0 ? 0 : detailItems?.length - 1;
              if (
                detailItems?.[detailItemsIndex]?.dandoriDetails?.filter(
                  (item) => item?.processDetailTypeId === dandoriDetails?.processDetailTypeId
                )?.length === 0
              ) {
                detailItems[detailItemsIndex]?.dandoriDetails?.push(dandoriDetails); // IQX_WEBEST-304 PMXからの見積りができない（「予期せぬエラーが発生しました」と表示される）
              }
              detailItems[detailItemsIndex]?.sagyoDetails?.push(sagyoDetail); // IQX_WEBEST-304 PMXからの見積りができない（「予期せぬエラーが発生しました」と表示される）
            }
            weldIndex++;
          }
        }
      }
    }
    let weldLen = weldingProcessInput?.details?.[0]?.weldingList?.reduce((total, item) => {
      let weldInfoLen = item.senchou > 0 && item.senchouYousetsushiage == 0 ? 1 : 0;
      return total + weldInfoLen;
    }, 0);
    let subWeldIndex = 0;
    // サブ溶接情報設定 IQX_WEBEST-271 サブ溶接にも溶接仕上を出来るようにしてほしい
    for (let j = 0; j < weldingProcessInput?.details?.[0]?.subWeldingList?.length; j++) {
      let subWeldingList = weldingProcessInput?.details?.[0]?.subWeldingList[j];
      //溶接仕上が適用の場合のみ
      if (subWeldingList?.yousetsushiage == 0) {
        //重量と面積が０の場合、計算しない。
        if (weight === 0 && area === 0) {
        } else {
          // 径又はサイズが０の場合、計算しない。（IQX_WEBEST-54）
          if (Number(subWeldingList?.total) !== 0) {
            let subWeldIndexId = weldLen + subWeldIndex + 1;
            let weldProcess = weldProcessMst?.details?.processDetailTypes?.filter(
              (item) => item.id == subWeldingList.subWeldingType
            )?.[0];
            detailItems.push({
              id: subWeldIndexId,
              processName: weldProcess?.name,
              processTypeId: weldProcess?.id,
              dandoriAmt: 0,
              dandoriTime: 0,
              dandoriDetails: [],
              sagyoAmt: 0,
              sagyoTime: 0,
              sagyoDetails: [],
              senchou: Number(subWeldingList.daimeter), //　径・サイズ
              senchouKaisu: Number(subWeldingList.total), //回数
              formula: '',
              yousetsuType: subWeldingList?.subWeldingGroupType,
            });
            //工程選択から処理情報取得
            let multiprocesses = patternInfo[0].multiprocesses;
            for (let i = 0; i < multiprocesses?.length; i++) {
              //溶接仕上工程マスター情報取得
              let processTypeNm = processMst?.details?.processDetailTypes?.filter(
                (item) => item.id == multiprocesses[i].processDetailTypeId
              )?.[0];
              if (processTypeNm == undefined || processTypeNm?.length == 0) continue;
              let dandoriDetails = {
                id: i + 1,
                processDetailTypeId: processTypeNm?.id,
                processDetailName: processTypeNm?.name,
                dandoriAmt: 0,
                dandoriTime: 0,
              };
              let sagyoDetail = {
                id: i + 1,
                processDetailTypeId: processTypeNm?.id,
                processDetailName: processTypeNm?.name,
                sagyoAmt: 0,
                sagyoTime: 0,
              };
              let detailItemsIndex = detailItems?.length === 0 ? 0 : detailItems?.length - 1;
              if (
                detailItems?.[detailItemsIndex]?.dandoriDetails?.filter(
                  (item) => item?.processDetailTypeId === dandoriDetails?.processDetailTypeId
                )?.length === 0
              ) {
                detailItems[detailItemsIndex]?.dandoriDetails?.push(dandoriDetails); // IQX_WEBEST-304 PMXからの見積りができない（「予期せぬエラーが発生しました」と表示される）
              }
              detailItems[detailItemsIndex]?.sagyoDetails?.push(sagyoDetail); // IQX_WEBEST-304 PMXからの見積りができない（「予期せぬエラーが発生しました」と表示される）
            }
          }
        }
      }
    }

    editDetailItems = [];
    let weldingFinishAddition = {
      no: processMst?.id,
      processId: processMst?.id,
      dataType: weldingFinishAdditions?.dataType ? weldingFinishAdditions?.dataType : DataType.Data,
      dataItems: detailItems,
      editItems: weldingFinishAdditions?.editItems ? weldingFinishAdditions?.editItems : editDetailItems,
      totalDandori: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalSagyo: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalDataPrice: 0,
      totalDataTime: 0,
      totalEditPrice: 0,
      totalEditTime: 0,
      editDataFlg: editDataFlg,
    };
    weldingFinishAdditions = weldingFinishAddition;

    if (weldingFinishAdditions?.dataItems) {
      let orgData = JSON.parse(JSON.stringify(weldingFinishAdditions?.dataItems));
      if (deviceMst != undefined) {
        let totalDandori = 0;
        let totalSagyo = 0;
        let totalDandoriTm = 0;
        let dataItemsByProcessInput = [];
        let totalSagyoTm = 0;

        // 数量
        let partCnt = props?.selectedEstimateData?.estimateProducts?.quantity;
        // 板厚
        let thickness = parameters?.materialIq3?.filter(
          (item) => item.id == props?.selectedIQ3DataDetail?.thickness
        )?.[0]?.info?.thick;
        setPartThickness(thickness);

        //材質
        let materialTypeIq3Id = props?.selectedIQ3DataDetail?.materialTypeIq3Id;

        //材質区分
        let materialTypeId = parameters?.materialTypeIq3?.filter((item) => item.id == materialTypeIq3Id)?.[0]
          ?.materialTypeId;

        // 選択された部品の材質により難易度係数を取得
        let diffCoef = getDiffCoef(deviceMst, materialTypeId);
        setDiffCoeff(diffCoef);
        // サイズ係数
        let sizeCoef = getSizeCoeff(deviceMst, weight, area);
        setSizeCoeff(sizeCoef);

        // IQX_WEBEST-278 工程積算タブの表示に生産個数係数が含まれていなかったので、含むようにしてほしい
        let productionQuantityCoeff = getProductionQuantityCoeff(deviceMst);
        let weldIndex = 0;

        //　段取はメイン溶接とサブ溶接の両方が存在している場合でも、いずれか一方のみであっても、段取時間は同じのため、溶接仕上処理により計算
        /** 段取金額/時間 */
        if (orgData?.length > 0) {
          for (let d = 0; d < orgData?.[0]?.dandoriDetails?.length; d++) {
            let dandoriDetailsData = orgData?.[0]?.dandoriDetails[d];
            // 基本段取時間＊サイズ係数
            let dandoriTimeSec = calDandoriTime(deviceMst, weight, area, dandoriDetailsData?.processDetailTypeId);
            let dandoriTime = dandoriTimeSec;
            orgData[0].dandoriDetails[d].dandoriTime = dandoriTime ? dandoriTime : 0;
            // 段取時間の合計
            orgData[0].dandoriTime += orgData[0].dandoriDetails[d].dandoriTime;

            // 段取金額設定
            //段階時間
            let mSetTimeMin = dandoriTimeSec / 60;
            //装置の段階時間チャージ
            let deviceCharges = Object.entries(deviceMst.details?.charges)?.filter(
              ([key, data]) => parseInt(key) === dandoriDetailsData?.processDetailTypeId
            )?.[0];
            let mSetHRate = 0;
            deviceCharges?.map((item, index) => {
              if (index > 0) {
                mSetHRate = item?.prep;
              }
            });
            let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0; //段階時間＊時間チャージ
            orgData[0].dandoriDetails[d].dandoriAmt = dandoriAmt ? dandoriAmt : 0;
            // 段取金額の合計
            orgData[0].dandoriAmt += orgData[0].dandoriDetails[d].dandoriAmt;
          }
          totalDandori = orgData[0].dandoriAmt ? Math.round(orgData[0].dandoriAmt) : 0;
          totalDandoriTm = orgData[0].dandoriTime;
        }

        //溶接情報設定
        for (let i = 0; i < weldingProcessInput?.details?.[0]?.weldingList?.length; i++) {
          //工程入力情報
          let weldingProcessInputInfo = weldingProcessInput?.details?.[0]?.weldingList[i];
          // 線長の場合、長さ/箇所が０の場合、計算しない。（IQX_WEBEST-54）
          // 仮付けの場合、長さ/箇所が０の場合、計算しない。（IQX_WEBEST-54）
          if (Number(weldingProcessInputInfo?.karitsukeCnt) !== 0 || Number(weldingProcessInputInfo?.senchou) !== 0) {
            let weldProcess = weldProcessMst?.details?.processDetailTypes?.filter(
              (item) => item.id == weldingProcessInputInfo.yousetsuType
            )?.[0];
            let dataItemByProcessInput = orgData?.filter((item) => item.processName === weldProcess?.name);
            if (dataItemByProcessInput?.length > 0) {
              dataItemByProcessInput = dataItemByProcessInput?.[0];
              // 作業時間（バフ加工時間	+	酸洗加工時間	+	サンダー加工時間）
              for (let s = 0; s < dataItemByProcessInput?.sagyoDetails?.length; s++) {
                let sagyoDetailData = dataItemByProcessInput?.sagyoDetails[s];
                // 加工長
                let sagyoLen = Number(weldingProcessInputInfo?.senchou);
                // 溶接線長により該当種類の加工速度を取得
                let sagyoSpeed = getSagyoSpeed(deviceMst, sagyoLen, sagyoDetailData?.processDetailTypeId);
                // 溶接時間（加工長*回数/加工速度＊難易度係数＊サイズ係数）
                let totalSagyoLen = sagyoLen * Number(weldingProcessInputInfo?.senchouKaisu);
                let sagyoTimeSec = Number.isFinite(totalSagyoLen / sagyoSpeed)
                  ? (totalSagyoLen / sagyoSpeed) * diffCoef * sizeCoef * 60 // IQX_WEBEST-331 加工速度の単位漏れ対応
                  : 0;
                sagyoTimeSec = sagyoTimeSec * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
                dataItemByProcessInput.sagyoDetails[s].sagyoTime = sagyoTimeSec ? sagyoTimeSec : 0;
                dataItemByProcessInput.sagyoTime += sagyoTimeSec ? sagyoTimeSec : 0;

                // 作業金額
                let mSagyoTimeMin = sagyoTimeSec / 60; // IQX_WEBEST-331 加工速度の単位漏れ対応
                //装置の作業時間チャージ
                let mSagyoHRate = 0;
                //装置の段階時間チャージ
                let sagyoDeviceCharges = Object.entries(deviceMst.details?.charges)?.filter(
                  ([key, data]) => parseInt(key) === sagyoDetailData?.processDetailTypeId
                )?.[0];
                sagyoDeviceCharges?.map((item, index) => {
                  if (index > 0) {
                    mSagyoHRate = item?.work;
                  }
                });
                let sagyoAmt = (mSagyoTimeMin * mSagyoHRate) / 60.0; //作業時間＊時間チャージ
                dataItemByProcessInput.sagyoDetails[s].sagyoAmt = sagyoAmt ? sagyoAmt : 0;
                dataItemByProcessInput.sagyoAmt += sagyoAmt ? sagyoAmt : 0;
              }
              // 作業金額の合計
              totalSagyo += dataItemByProcessInput.sagyoAmt ? Math.round(dataItemByProcessInput.sagyoAmt) : 0;
              totalSagyoTm += dataItemByProcessInput.sagyoTime;
              dataItemsByProcessInput.push(dataItemByProcessInput);
            }
            weldIndex++;
          }
        }

        //サプ溶接情報設定 IQX_WEBEST-271 サブ溶接にも溶接仕上を出来るようにしてほしい
        for (let i = 0; i < weldingProcessInput?.details?.[0]?.subWeldingList?.length; i++) {
          //工程入力情報
          let weldingProcessInputInfo = weldingProcessInput?.details?.[0]?.subWeldingList[i];
          // 径又はサイズが０の場合、計算しない。（IQX_WEBEST-54）
          if (Number(weldingProcessInputInfo?.total) !== 0) {
            let weldProcess = weldProcessMst?.details?.processDetailTypes?.filter(
              (item) => item.id == weldingProcessInputInfo.subWeldingType
            )?.[0];
            let dataItemByProcessInput = orgData?.filter((item) => item.processName === weldProcess?.name);
            if (dataItemByProcessInput?.length > 0) {
              dataItemByProcessInput = dataItemByProcessInput?.[0];
              // 作業時間（バフ加工時間	+	酸洗加工時間	+	サンダー加工時間）
              for (let s = 0; s < dataItemByProcessInput?.sagyoDetails?.length; s++) {
                let sagyoDetailData = dataItemByProcessInput?.sagyoDetails[s];
                let daimeter = Number(weldingProcessInputInfo?.daimeter);
                // 作業時間（加工時間*回数）
                let sagyoKaisu = Number(weldingProcessInputInfo?.total);
                let sagyoTimeSec = calSagyoTime(
                  deviceMst,
                  thickness,
                  daimeter,
                  sagyoDetailData?.processDetailTypeId,
                  weldingProcessInputInfo?.subWeldingType,
                  weldingProcessInputInfo?.subWeldingGroupType
                );
                sagyoTimeSec = sagyoTimeSec * sagyoKaisu * diffCoef * sizeCoef; // IQX_WEBEST-271 加工時間＊回数＊難易度係数＊サイズ係数
                dataItemByProcessInput.sagyoDetails[s].sagyoTime = sagyoTimeSec ? sagyoTimeSec : 0;
                dataItemByProcessInput.sagyoTime += sagyoTimeSec ? sagyoTimeSec : 0;

                // 作業金額
                let mSagyoTimeMin = sagyoTimeSec / 60;
                //装置の作業時間チャージ
                let mSagyoHRate = 0;
                //装置の段階時間チャージ
                let sagyoDeviceCharges = Object.entries(deviceMst.details?.charges)?.filter(
                  ([key, data]) => parseInt(key) === sagyoDetailData?.processDetailTypeId
                )?.[0];
                sagyoDeviceCharges?.map((item, index) => {
                  if (index > 0) {
                    mSagyoHRate = item?.work;
                  }
                });
                let sagyoAmt = (mSagyoTimeMin * mSagyoHRate) / 60.0; //作業時間＊時間チャージ
                dataItemByProcessInput.sagyoDetails[s].sagyoAmt = sagyoAmt ? sagyoAmt : 0;
                dataItemByProcessInput.sagyoAmt += sagyoAmt ? sagyoAmt : 0;
              }
              // 作業金額の合計
              totalSagyo += dataItemByProcessInput.sagyoAmt ? Math.round(dataItemByProcessInput.sagyoAmt) : 0;
              totalSagyoTm += dataItemByProcessInput.sagyoTime;
              dataItemsByProcessInput.push(dataItemByProcessInput);
            }
          }
        }

        // 段取金額の合計
        setDandoriAmtSum(totalDandori);
        // 段取時間の合計
        setDandoriTimeSum(totalDandoriTm);
        // 作業金額の合計
        setSagyoAmtSum(totalSagyo);
        setSagyoTimeSum(totalSagyoTm);

        /** 　合計時間　*/
        let totalTimeSum = totalDandoriTm + totalSagyoTm;
        setTotalTimeSum(totalTimeSum);
        weldingFinishAdditions.totalDataTime = totalTimeSum;
        weldingFinishAdditions.totalDandori.dataItemsTime = totalDandoriTm;
        weldingFinishAdditions.totalSagyo.dataItemsTime = totalSagyoTm;

        /** 　合計金額　*/
        let totalSum = (totalDandori ? Math.round(totalDandori) : 0) + (totalSagyo ? Math.round(totalSagyo) : 0); // 画面表示用（四捨五入した金額を足す）
        setTotalSum(totalSum);
        weldingFinishAdditions.totalDataPrice = totalSum;
        weldingFinishAdditions.totalDandori.dataItemsAmt = totalDandori;
        weldingFinishAdditions.totalSagyo.dataItemsAmt = totalSagyo;

        orgData = dataItemsByProcessInput;
        setProcessArr(dataItemsByProcessInput);
        weldingFinishAdditions.dataItems = orgData;

        // IQX_WEBEST-16 工程積算タブで編集データのチェックがOFFだと、手入力で入力した内容がリセットされてしまう
        if (weldingFinishAdditionsEditData?.length > 0 && editDataFlg) {
          toBEdit = JSON.parse(JSON.stringify(weldingFinishAdditionsEditData));
        } else {
          toBEdit = JSON.parse(JSON.stringify(orgData));
        }
      }
    }
    if (toBEdit?.length > 0) {
      editData = toBEdit;
      weldingFinishAdditions.editItems = editData;
      if (editData?.length > 0) {
        setEditedData(editData);
        //setOldEditedData(editData);
        totals = calculateTotals(editData);

        setTotalAmt(totals[0]?.totalSum);
        weldingFinishAdditions.totalEditPrice = totals[0]?.totalSum;
        weldingFinishAdditions.totalDandori.editItemsAmt = totals[0]?.dandoriAmtSum;
        weldingFinishAdditions.totalSagyo.editItemsAmt = totals[0]?.sagyoAmtSum;

        setTotalTime(totals[0]?.totalTimeSum);
        weldingFinishAdditions.totalEditTime = totals[0]?.totalTimeSum;
        weldingFinishAdditions.totalDandori.editItemsTime = totals[0]?.dandoriTimeSum;
        weldingFinishAdditions.totalSagyo.editItemsTime = totals[0]?.sagyoTimeSum;

        setTotalSagyoAmt(totals[0]?.sagyoAmtSum);

        setTotalSagyoTime(totals[0]?.sagyoTimeSum);

        setTotalDandoriAmt(totals[0]?.dandoriAmtSum);

        setTotalDandoriTime(totals[0]?.dandoriTimeSum);
      }
      setUpdateEditedData(weldingFinishAdditions);
    }
    if (weldingFinishAdditions?.dataType == DataType.Data) {
      setWeldingFinishAmt(weldingFinishAdditions.totalDataPrice);
    } else {
      setWeldingFinishAmt(weldingFinishAdditions.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(weldingFinishAdditions, WorkType.SmWeldFinish);
  }, [
    props?.activeTabKey,
    props?.isReCalIQ3,
    props?.activePartMenu,
    props?.iq3ActionForCal,
    props?.selectedProcessSelect,
    props?.selectedIQ3DataDetail,
  ]);

  useEffect(() => {
    setEditMode(props?.editMode != undefined ? props?.editMode : false);
  }, [props?.editMode]);

  const getDiffCoef = (deviceMst, materialTypeId) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;

    let weldDifficultyCoeffThItems = Object.entries(deviceMst.details?.diffFactorCoeff)?.filter(
      ([key, data]) => parseInt(key) === materialTypeId
    )?.[0];

    // 溶接難易度
    let childPartsCoeff = 0;
    weldDifficultyCoeffThItems?.map((item, index) => {
      if (index > 0) {
        childPartsCoeff = item;
      }
    });
    childPartsCoeff = childPartsCoeff ? childPartsCoeff : 0;
    return childPartsCoeff;
  };

  const getSizeCoeff = (deviceMst, weight, area) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let prodSizeWeightThMaster = deviceMst?.details?.prodSizeWeightThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
    );
    // 面積
    let prodSizeAreaThMaster = deviceMst?.details?.prodSizeAreaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item.isOutOfRange
    );
    let childPartsCoeffs =
      prodSizeWeightThMaster?.length > 0 && prodSizeAreaThMaster?.length > 0
        ? deviceMst?.details?.prodSizeItems?.filter((item) => item.weightId == prodSizeWeightThMaster[0]?.no)
        : deviceMst?.details?.prodSizeItems[0];

    // サイズ係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter(
            (item) => item.no == prodSizeAreaThMaster?.[0]?.no && !item.isOutOfRange
          )?.[0]?.value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;
    partsCoeff = partsCoeff ? partsCoeff : 0;
    return partsCoeff;
  };

  const calDandoriTime = (deviceMst, weight, area, processDetailTypeId) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定
    // 重量
    let childPartsCoeffWeightThMaster = deviceMst?.details?.childPartsCoeffWeightThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
    );
    // 面積
    let childPartsCoeffAreaThMaster = deviceMst?.details?.childPartsCoeffAreaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item.isOutOfRange
    );
    let childPartsCoeffs =
      childPartsCoeffWeightThMaster?.length > 0 && childPartsCoeffAreaThMaster?.length > 0
        ? deviceMst?.details?.childPartsCoeff?.filter((item) => item.weightId == childPartsCoeffWeightThMaster[0]?.no)
        : deviceMst?.details?.childPartsCoeff[0];

    // サイズ係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter(
            (item) => item.no == childPartsCoeffAreaThMaster?.[0]?.no && !item.isOutOfRange
          )?.[0]?.value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    //　基本段取時間
    let deviceDandori = Object.entries(deviceMst.details?.prepSecs)?.filter(
      ([key, data]) => parseInt(key) === processDetailTypeId
    )?.[0];
    let deviceDandoriTm = 0;
    deviceDandori?.map((item, index) => {
      if (index > 0) {
        deviceDandoriTm = item;
      }
    });

    //　基本段取時間＊サイズ係数
    let dandoriTimeSec = deviceDandoriTm * partsCoeff;

    dandoriTimeSec = dandoriTimeSec ? dandoriTimeSec : 0;
    dandoriTimeSec = dandoriTimeSec * getSetupCoeff(deviceMst); // IQX_WEBEST-278 基本段取時間＊段取個数係数
    return dandoriTimeSec;
  };

  const calDandoriAmt = (dandoriTimeSec, deviceMst, processDetailTypeId) => {
    //段階時間
    let mSetTimeMin = dandoriTimeSec / 60;
    //装置の段階時間チャージ
    let mSetHRate = 0;
    //装置の段階時間チャージ
    let deviceCharges = Object.entries(deviceMst.details?.charges)?.filter(
      ([key, data]) => parseInt(key) === processDetailTypeId
    )?.[0];
    deviceCharges?.map((item, index) => {
      if (index > 0) {
        mSetHRate = item?.prep;
      }
    });
    //段階時間＊時間チャージ
    let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0;
    dandoriAmt = Number.isFinite(dandoriAmt) ? dandoriAmt : 0;
    return dandoriAmt;
  };

  const getSagyoSpeed = (deviceMst, sencho, processDetailTypeId) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 加工速度
    // 溶接線長
    let workSpeedThMaster = deviceMst?.details?.workSpeedThMaster?.filter(
      (item) => (item.value == sencho || item.value > sencho) && !item.isOutOfRange
    )?.[0];
    workSpeedThMaster = workSpeedThMaster ? workSpeedThMaster : deviceMst?.details?.workSpeedThMaster[0];
    let workSpeedThItems = Object.entries(deviceMst.details?.workSpeedThItems)?.filter(
      ([key, data]) => parseInt(key) === processDetailTypeId
    )?.[0];

    /* let workSpeedThItems = Object.entries(deviceMst.details?.workSpeedThItems)?.filter(
      ([key, data]) => parseInt(key) === workSpeedThMaster?.no
    )?.[0]; */

    // 加工速度
    let childPartsCoeff = 0;
    workSpeedThItems?.map((item, index) => {
      if (index > 0) {
        childPartsCoeff = item?.filter(
          (item1) => parseInt(item1.no) == workSpeedThMaster?.no && !item1.isOutOfRange
        )?.[0]?.value;
      }
    });
    childPartsCoeff = childPartsCoeff ? childPartsCoeff : 0;
    return childPartsCoeff;
  };

  const calSagyoAmt = (cSagyoTimeKonpoSec, deviceMst, processDetailTypeId) => {
    // 作業金額
    // 作業時間
    let mWorkHTime = cSagyoTimeKonpoSec;
    let mWorkHTimeMin = mWorkHTime / 60;
    //装置の作業時間チャージ
    let mWorkHRate = 0;
    let deviceCharges = Object.entries(deviceMst.details?.charges)?.filter(
      ([key, data]) => parseInt(key) === processDetailTypeId
    )?.[0];
    deviceCharges?.map((item, index) => {
      if (index > 0) {
        mWorkHRate = item?.work;
      }
    });
    //　加工時間＊時間チャージ
    let cSagyoAmtKonpo = (mWorkHTimeMin * mWorkHRate) / 60.0;
    cSagyoAmtKonpo = Number.isFinite(cSagyoAmtKonpo) ? cSagyoAmtKonpo : 0;
    return cSagyoAmtKonpo;
  };

  // IQX_WEBEST-271 サブ溶接にも溶接仕上を出来るようにしてほしい
  const calSagyoTime = (deviceMst, thickness, size, processDetailTypeId, weldTypeId, weldTypeGroupId) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 加工時間
    let kakouTimeItem = deviceMst?.details?.kakouTimeItems?.filter(
      (item) => item.kouteiTypeId === processDetailTypeId
    )?.[0];
    let instThMaster = [];
    let instThItems = [];
    if (weldTypeGroupId == WorkTypeGroup.SmInstThick) {
      instThMaster = kakouTimeItem?.instThickThMaster?.filter(
        (item) => (item.value == thickness || item.value > thickness) && !item.isOutOfRange
      )?.[0];
      if (kakouTimeItem?.instThickThItems) {
        instThItems = Object.entries(kakouTimeItem?.instThickThItems)?.filter(
          ([key, data]) => parseInt(key) === weldTypeId
        )?.[0];
      }
    } else {
      instThMaster = kakouTimeItem?.instSizeThMaster?.filter(
        (item) => (item.value == size || item.value > size) && !item.isOutOfRange
      )?.[0];
      if (kakouTimeItem?.instSizeThItems) {
        instThItems = Object.entries(kakouTimeItem?.instSizeThItems)?.filter(
          ([key, data]) => parseInt(key) === weldTypeId
        )?.[0];
      }
    }

    // 加工時間
    let sagyoTime = 0;
    instThItems?.map((item, index) => {
      if (index > 0) {
        sagyoTime = item?.filter((item) => parseInt(item.no) == instThMaster?.no && !item.isOutOfRange)?.[0]?.value;
      }
    });
    sagyoTime = sagyoTime ? sagyoTime : 0;
    return sagyoTime;
  };

  const getYousetsuTypes = () => {
    let subYousetsus = [];
    let yousetsus = [];
    const kouteiMasterData = JSON.parse(localStorage.getItem('kouteiMasterData'))?.forEach((e) => {
      if (e.processType === '溶接' && e.type === 'iQ3') {
        e.processDetail?.forEach((processDetail) => {
          if (
            processDetail.processDetailTitle === '取付種類（板厚判断）' ||
            processDetail.processDetailTitle === '取付種類（サイズ判断）'
          ) {
            processDetail.processDetailValue?.forEach((detailVal) => {
              subYousetsus.push(detailVal.detailType);
            });
          } else if (processDetail.processDetailTitle === '溶接種類') {
            processDetail.processDetailValue?.forEach((detailVal) => {
              yousetsus.push(detailVal.detailType);
            });
          }
        });
      }
    });
    setSubYousetsuArr(subYousetsus);
    setYousetsuArr(yousetsus);
  };

  const patternNo = 1;
  const getProcessFromPattern = (patternNo) => {
    let patterns = JSON.parse(localStorage.getItem('iq3_processPattern'));
    let processCodes = [];
    patterns?.forEach((obj) => {
      if (obj.key === patternNo) {
        obj.patternProcess?.forEach((pattenProcess) => {
          if (pattenProcess.processName === '溶接仕上') {
            let processDetailLst = pattenProcess.processDetailLst;
            processDetailLst?.forEach((processDetail) => {
              processCodes.push(processDetail.processDetailCode);
            });
          }
        });
      }
    });
    let processNames = [];
    JSON.parse(localStorage.getItem('kouteiMasterData'))?.forEach((e) => {
      if (e.processType === '溶接仕上' && e.type === 'iQ3') {
        let processDetailValue = e.processDetail[0]?.processDetailValue;
        for (const code of processCodes) {
          processNames.push(processDetailValue?.find((obj) => obj.detailCode === code)?.detailType);
        }
      }
    });
    return processNames;
  };

  const yosetsuFinish = () => {
    if (isYosetsuFinishdetail == true) {
      setIYosetsuFinishdetail(false);
    } else {
      setIYosetsuFinishdetail(true);
    }
  };

  const setYosetsuFinishDisplay = (display) => {
    setIYosetsuFinishdetail(display);
  };

  const subpartDetail = (i) => {
    let showHide = document.getElementById('yosetsuFinishSagyo' + i).style.display;
    if (showHide == 'block') {
      document.getElementById('yosetsuFinishSagyo' + i).style.display = 'none';
      document.getElementById('arrowDownYosetsuFinish' + i).style.display = 'block';
      document.getElementById('arrowUpYosetsuFinish' + i).style.display = 'none';
    } else {
      document.getElementById('yosetsuFinishSagyo' + i).style.display = 'block';
      document.getElementById('arrowDownYosetsuFinish' + i).style.display = 'none';
      document.getElementById('arrowUpYosetsuFinish' + i).style.display = 'block';
    }
  };

  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };
  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
    setYosetsuFinishDisplay: setYosetsuFinishDisplay,
  }));

  const toTwoDecimal = (j, param) => {
    let newState = JSON.parse(JSON.stringify(editedData));
    newState[j][param] = toDecimal(editedData[j]?.[param], 2);
    setEditedData(newState);
  };

  // IQX_WEBEST-16 データ表示に戻す
  const settingDataItems = () => {
    updateEditedData.editItems = JSON.parse(JSON.stringify(updateEditedData?.dataItems));
    updateEditedData.editDataFlg = false;
    setEditedData(JSON.parse(JSON.stringify(processArr)));
    setTotalSagyoAmt(sagyoAmtSum ? sagyoAmtSum : 0);
    setTotalSagyoTime(sagyoTimeSum ? sagyoTimeSum : 0);
    setTotalDandoriAmt(dandoriAmtSum ? dandoriAmtSum : 0);
    setTotalDandoriTime(dandoriTimeSum ? dandoriTimeSum : 0);
    setTotalAmt(totalSum ? totalSum : 0);
    setTotalTime(totalTimeSum ? totalTimeSum : 0);
    updateEditedData.totalEditPrice = totalSum ? totalSum : 0;
    updateEditedData.totalEditTime = totalTimeSum ? totalTimeSum : 0;
    const cuDivs = document.querySelectorAll('div[id*="Cu"]');

    cuDivs.forEach((div) => {
      div.style.display = 'none';
    });

    if (updateEditedData.dataType == DataType.Data) {
      setWeldingFinishAmt(updateEditedData.totalDataPrice);
    } else {
      setWeldingFinishAmt(updateEditedData.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmWeldFinish);
  };

  const settingNewDandoriAmtInArr = (value, index, index1, objId, timeType, timeAct) => {
    updateEditedData.editDataFlg = true; // IQX_WEBEST-16 編集データを保持フラグOn
    let ij = String(index1) + String(index);
    if (index === '') {
      index = 0;
    }
    if (index1 === '') {
      index1 = 0;
    }
    let newState = JSON.parse(JSON.stringify(editedData));
    let oldValue = 0;

    if (objId !== 'dandoriAmt' && objId !== 'dandoriTime' && objId !== 'sagyoAmt' && objId !== 'sagyoTime') {
      setOldEditedData(JSON.parse(JSON.stringify(editedData)));
    }

    if (value === undefined) {
      value = 0;
    } else {
      value = value ? Number(value) : 0;
    }

    if (objId === 'dandoriTime' || objId === 'dandoriAmt') {
      if (objId === 'dandoriTime') {
        if (timeAct === 'input') {
          let timeType = value - oldValue;
          newState[0].dandoriDetails[index].dandoriAmt = changeAmtwithTime(
            newState?.[0]?.dandoriDetails?.[index]?.dandoriAmt,
            timeType,
            timeAct,
            value,
            'dandoriTime',
            newState?.[0]?.dandoriDetails?.[index]?.processDetailTypeId
          );
        }
        if (document.getElementById(index + 'YFinishDandoriAmtCu').style.display !== undefined) {
          let showHideCu = document.getElementById(index + 'YFinishDandoriAmtCu').style.display;
          if (showHideCu === 'none') {
            if (value === 0) {
              newState[0].dandoriDetails[index].dandoriAmt = 0;
            } else if (timeAct === 'input') {
              let timeType = value - oldValue;
              newState[0].dandoriDetails[index].dandoriAmt = changeAmtwithTime(
                newState?.[0]?.dandoriDetails?.[index]?.dandoriAmt,
                timeType,
                timeAct,
                value,
                'dandoriTime',
                newState?.[0]?.dandoriDetails?.[index]?.processDetailTypeId
              );
            } else {
              newState[0].dandoriDetails[index].dandoriAmt = changeAmtwithTime(
                newState?.[0]?.dandoriDetails?.[index]?.dandoriAmt,
                timeType,
                timeAct,
                value,
                objId,
                newState?.[0]?.dandoriDetails?.[index]?.processDetailTypeId
              );
            }
          }
          newState[0].dandoriDetails[index].dandoriTime = value;
          updateEditedData.editItems[0].dandoriDetails[index].dandoriAmt =
            newState?.[0]?.dandoriDetails?.[index]?.dandoriAmt;
          updateEditedData.editItems[0].dandoriDetails[index].dandoriTime = value;
        }
      } else if (objId === 'dandoriAmt') {
        newState[0].dandoriDetails[index].dandoriAmt = Number(value);
        updateEditedData.editItems[0].dandoriDetails[index].dandoriAmt =
          newState?.[0]?.dandoriDetails?.[index]?.dandoriAmt;
      }
    } else if (objId === 'sagyoTime' || objId === 'sagyoAmt') {
      if (objId === 'sagyoTime') {
        if (document.getElementById(ij + 'YFinishSagyoAmtCu').style.display !== undefined) {
          let showHideCu = document.getElementById(ij + 'YFinishSagyoAmtCu').style.display;
          if (showHideCu === 'none') {
            if (value === 0) {
              newState[0].dandoriDetails[index].sagyoAmt = 0;
            } else if (timeAct === 'input') {
              let timeType = value - oldValue;
              let timeHms = timeType;
              newState[index1].sagyoDetails[index].sagyoAmt = changeAmtwithTime(
                newState?.[index1]?.sagyoDetails?.[index]?.sagyoAmt,
                timeType,
                timeAct,
                timeHms,
                objId,
                newState?.[index1]?.sagyoDetails?.[index]?.processDetailTypeId
              );
            } else {
              newState[index1].sagyoDetails[index].sagyoAmt = changeAmtwithTime(
                newState?.[index1]?.sagyoDetails?.[index]?.sagyoAmt,
                timeType,
                timeAct,
                value,
                objId,
                newState?.[index1]?.sagyoDetails?.[index]?.processDetailTypeId
              );
            }
          }
        }
        newState[index1].sagyoDetails[index].sagyoTime = value;
        updateEditedData.editItems[index1].sagyoDetails[index].sagyoAmt =
          newState?.[index1]?.sagyoDetails?.[index]?.sagyoAmt;
        updateEditedData.editItems[index1].sagyoDetails[index].sagyoTime =
          newState?.[index1]?.sagyoDetails?.[index]?.sagyoTime;
        checkInputWithColor(false, '', ij + 'YFinishSagyoTime', secondsToHms(value), 'red');
      } else if (objId === 'sagyoAmt') {
        newState[index1].sagyoDetails[index].sagyoAmt = Number(value);
        updateEditedData.editItems[index1].sagyoDetails[index].sagyoAmt =
          newState?.[index1]?.sagyoDetails?.[index]?.sagyoAmt;
      }
    } else if (objId === 'senchou') {
      /* else if (objId === 'handelingTime') {
      if (document.getElementById('YousetsuHandlingAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById('YousetsuHandlingAmtCu').style.display;
        if (showHideCu === 'none') {
          if (timeAct === 'input') {
            let timeType = value - oldValue;
            newState[0].handelingAmt = changeAmtwithTime(newState[0].handelingAmt, timeType, timeAct, value, objId);
          } else {
            newState[0].handelingAmt = changeAmtwithTime(newState[0].handelingAmt, timeType, timeAct, value, objId);
          }
        }
      }
    } */
      //作業detail取得
      //例：id = 'index1+index+YFinishSagyoAmtCu'
      var selector = '[id^="' + index1 + '"][id$="YFinishSagyoAmtCu"]';
      var elements = document.querySelectorAll(selector);
      elements.forEach((e, j) => {
        // 作業時間
        // 加工長
        let sagyoLen = Number(value);
        // 溶接線長により該当種類の加工速度を取得
        let sagyoSpeed = getSagyoSpeed(deviceMst, sagyoLen, newState?.[index1]?.sagyoDetails?.[j]?.processDetailTypeId);
        // 選択された部品の板厚により難易度係数を取得
        let diffCoef = diffCoeff;
        // サイズ係数
        let sizeCoef = sizeCoeff;
        // 加工長/加工速度＊難易度係数＊サイズ係数
        let totalSagyoLen = sagyoLen * Number(newState?.[index1]?.senchouKaisu);
        let lenBySpeed = Number.isFinite(totalSagyoLen / sagyoSpeed) ? totalSagyoLen / sagyoSpeed : 0;
        let sagyoTimeSec = lenBySpeed * diffCoef * sizeCoef * 60; // IQX_WEBEST-331 加工速度の単位漏れ対応
        sagyoTimeSec = sagyoTimeSec * getProductionQuantityCoeff(deviceMst); // IQX_WEBEST-278 作業時間＊生産個数係数
        newState[index1].sagyoDetails[j].sagyoTime = sagyoTimeSec;

        if (e.style.display !== undefined) {
          let showHideCu = e.style.display;
          if (showHideCu === 'none') {
            // 作業金額
            let mSagyoTimeMin = sagyoTimeSec / 60; // IQX_WEBEST-331 加工速度の単位漏れ対応
            //装置の作業時間チャージ
            // let mSetHRate = deviceMst.details?.charges[processType].prep;
            //装置の作業時間チャージ
            let mSagyoHRate = 0;
            //装置の段階時間チャージ
            let sagyoDeviceCharges = Object.entries(deviceMst.details?.charges)?.filter(
              ([key, data]) => parseInt(key) === newState?.[index1]?.sagyoDetails?.[j]?.processDetailTypeId
            )?.[0];
            sagyoDeviceCharges?.map((item, index) => {
              if (index > 0) {
                mSagyoHRate = item?.work;
              }
            });
            let sagyoAmt = (mSagyoTimeMin * mSagyoHRate) / 60.0; //作業時間＊時間チャージ
            sagyoAmt = Number.isFinite(sagyoAmt) ? sagyoAmt : 0;
            newState[index1].sagyoDetails[j].sagyoAmt = sagyoAmt;

            updateEditedData.editItems[index1].sagyoDetails[j].sagyoAmt = sagyoAmt;
          }
        }
        updateEditedData.editItems[index1].sagyoDetails[j].sagyoTime = sagyoTimeSec;
      });
      newState[index1].senchou = value;
      updateEditedData.editItems[index1].senchou = value;
      var elements = document.querySelectorAll('[id^="' + index1 + '"][id$="YFinishSagyoTimeIn"]');

      elements.forEach(function (element) {
        element.style.color = '#4cc12f';
      });
    } else if (objId === 'senchouKaisu') {
      // IQX_WEBEST-271 サブ溶接にも溶接仕上を出来るようにしてほしい
      // 作業時間（加工時間*回数）
      let sagyoKaisu = value;
      let sagyoTimeSec = calSagyoTime(
        deviceMst,
        partThickness,
        newState[index1].senchou,
        newState?.[index1]?.sagyoDetails?.[index]?.processDetailTypeId,
        newState?.[index1]?.processTypeId,
        newState?.[index1]?.yousetsuType
      );
      sagyoTimeSec = sagyoTimeSec * sagyoKaisu; // 加工時間＊回数
      newState[index1].sagyoDetails[index].sagyoAmt = calSagyoAmt(
        sagyoTimeSec,
        deviceMst,
        newState?.[index1]?.sagyoDetails?.[index]?.processDetailTypeId
      );
      newState[index1].senchouKaisu = value;
      updateEditedData.editItems[index1].senchouKaisu = value;
      newState[index1].sagyoDetails[index].sagyoTime = sagyoTimeSec;
      updateEditedData.editItems[index1].sagyoDetails[index].sagyoAmt =
        newState?.[index1]?.sagyoDetails?.[index]?.sagyoAmt;
      updateEditedData.editItems[index1].sagyoDetails[index].sagyoTime =
        newState?.[index1]?.sagyoDetails?.[index]?.sagyoTime;
    }

    let newSagyoSum = 0;
    let newDandoriSum = 0;
    let newSum = 0;
    let newSubSum = 0;
    let newSagyoTimeSec = 0;
    let newDandoriTimeSec = 0;

    for (let i = 0; i < newState[0].dandoriDetails?.length; i++) {
      newDandoriSum += Math.round(newState?.[0]?.dandoriDetails?.[i]?.dandoriAmt);
      newDandoriTimeSec += newState?.[0]?.dandoriDetails?.[i]?.dandoriTime;
    }
    // updateEditedData.editItems[index1].dandoriAmt = newDandoriSum;
    // updateEditedData.editItems[index1].dandoriTime = newDandoriTimeSec;

    let sagyoSubTotalAmt = 0;
    let sagyoSubTotalTime = 0;
    for (let j = 0; j < newState[index1]?.sagyoDetails.length; j++) {
      sagyoSubTotalAmt += Number(newState[index1]?.sagyoDetails[j]?.sagyoAmt);
      sagyoSubTotalTime += newState[index1]?.sagyoDetails[j]?.sagyoTime;
    }
    newState[index1].sagyoAmt = sagyoSubTotalAmt;
    newState[index1].sagyoTime = sagyoSubTotalTime;

    updateEditedData.editItems[index1].sagyoAmt = newState?.[index1]?.sagyoAmt;
    updateEditedData.editItems[index1].sagyoTime = newState?.[index1]?.sagyoTime;

    for (let i = 0; i < newState.length; i++) {
      newSagyoSum += newState?.[i]?.sagyoAmt ? Math.round(newState?.[i]?.sagyoAmt) : 0;
      newSagyoTimeSec += newState?.[i]?.sagyoTime;
    }

    newSum = (newSagyoSum ? Math.round(newSagyoSum) : 0) + (newDandoriSum ? Math.round(newDandoriSum) : 0); // 画面表示用（四捨五入した金額を足す）
    let newTotalTimeSum = newSagyoTimeSec + newDandoriTimeSec;

    setTotalSagyoAmt(newSagyoSum);
    setTotalDandoriAmt(newDandoriSum);
    setTotalAmt(newSum);
    setTotalSagyoTime(newSagyoTimeSec);
    setTotalDandoriTime(newDandoriTimeSec);
    setTotalTime(newTotalTimeSum);
    setEditedData(newState);

    updateEditedData.totalEditPrice = newSum;
    updateEditedData.totalEditTime = newTotalTimeSum;
    updateEditedData.totalDandori.editItemsAmt = newDandoriSum;
    updateEditedData.totalDandori.editItemsTime = newDandoriTimeSec;
    updateEditedData.totalSagyo.editItemsAmt = newSagyoSum;
    updateEditedData.totalSagyo.editItemsTime = newSagyoTimeSec;
    if (updateEditedData.dataType == DataType.Data) {
      setWeldingFinishAmt(updateEditedData?.totalDataPrice);
    } else {
      setWeldingFinishAmt(updateEditedData?.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmWeldFinish);
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = Number(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal, processType, processDetailType) => {
    let editedAmt;
    let totalSec = Number(timeVal);
    let intAmt = checkStr(amt);
    // let deviceMst = [];

    if (processType === 'dandoriTime') {
      // 段取金額
      //　段取時間＊時間チャージ
      editedAmt = calDandoriAmt(totalSec, deviceMst, processDetailType);
    } else if (processType === 'sagyoTime') {
      // 作業金額
      //　加工時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, deviceMst, processDetailType);
    }
    // else if (processType === 'handelingTime') {
    //   // マテハン金額
    //   //　マテリアルハンドリング時間＊時間チャージ
    //   editedAmt = calSagyoAmt(totalSec, deviceMst, processDetailType);
    // }

    return editedAmt;
  };

  const getSetupTmCmt = (type) => {
    return (
      <div style={{ height: 40 }}>
        <p style={{ fontSize: 12, padding: 10 }}>{type}段取時間＝基本段取時間ｘ子部品サイズ係数ｘ段取個数係数</p>
      </div>
    );
  };

  const getSetupTotalTm = () => {
    let cmt = '段取時間＝';
    for (let j = 0; j < processArr[0]?.dandoriDetails?.length; j++) {
      let processDetailOr = processArr[0]?.dandoriDetails[j];
      cmt += processDetailOr?.processDetailName + '段取時間+';
    }
    cmt = cmt?.slice(0, -1);
    return (
      <div style={{ height: 40 }}>
        <p style={{ fontSize: 12, padding: 10 }}>{cmt}</p>
      </div>
    );
  };

  const getWorkTotalTm = (processArr) => {
    let cmt = '仕上時間＝';
    for (let j = 0; j < processArr?.sagyoDetails?.length; j++) {
      let sagyoProcessDetailOr = processArr?.sagyoDetails[j];
      cmt += sagyoProcessDetailOr?.processDetailName + '仕上時間+';
    }
    cmt = cmt?.slice(0, -1);
    return (
      <div style={{ height: 40 }}>
        <p style={{ fontSize: 12, padding: 10 }}>{cmt}</p>
      </div>
    );
  };

  // IQX_WEBEST-278 工程積算タブの表示に生産個数係数が含まれていなかったので、含むようにしてほしい
  const getProductionQuantityCoeff = (deviceMst) => {
    let productionQuantityCoeff = 1;
    let quantity = Number(props?.selectedEstimateData?.estimateProducts?.quantity);
    let partCnt = Number(props?.selectedIQ3DataDetail?.partCount); // 員数
    let manufacturingCnt = quantity * partCnt; // 製造数 * 員数
    // 生産個数係数
    let productionCoeffTh = deviceMst?.details?.productionQtyCoeffThMaster?.filter(
      (item) => (item.value == manufacturingCnt || item.value > manufacturingCnt) && !item.isOutOfRange
    );
    if (productionCoeffTh) {
      let productionCoeffThNo =
        productionCoeffTh?.length > 0
          ? productionCoeffTh[0]?.no
          : deviceMst?.details?.productionQtyCoeffThMaster?.[0]?.no;
      let productionCoeff =
        productionCoeffThNo == 0
          ? 0
          : deviceMst?.details?.productionQtyCoeffThItems?.filter(
              (item) => item.no == productionCoeffThNo && !item.isOutOfRange
            )[0]?.value;

      productionQuantityCoeff = productionCoeff ? productionCoeff : 1;
    }
    return productionQuantityCoeff;
  };

  // IQX_WEBEST-278 工程積算タブの表示に段取個数係数が含まれていなかったので、含むようにしてほしい
  const getSetupCoeff = (deviceMstFilter) => {
    let iq3SetupCoeff = 1;
    let quantity = Number(props?.selectedEstimateData?.estimateProducts?.quantity);
    let partCnt = Number(props?.selectedIQ3DataDetail?.partCount); // 員数
    let manufacturingCnt = quantity * partCnt; // 製造数 * 員数
    // 段取個数係数
    let prepQtyCoeffTh = deviceMstFilter?.details?.prepQtyCoeffThMaster?.filter(
      (item) => (item.value == manufacturingCnt || item.value > manufacturingCnt) && !item.isOutOfRange
    );
    if (prepQtyCoeffTh) {
      let prepQtyCoeffThNo =
        prepQtyCoeffTh?.length > 0 ? prepQtyCoeffTh?.[0]?.no : deviceMstFilter?.details?.prepQtyCoeffThMaster?.[0]?.no;
      let prepQtyCoeff =
        prepQtyCoeffThNo == 0
          ? 0
          : deviceMstFilter?.details?.prepQtyCoeffItems?.filter(
              (item) => item.no == prepQtyCoeffThNo && !item.isOutOfRange
            )[0]?.value;
      iq3SetupCoeff = prepQtyCoeff;
      iq3SetupCoeff = iq3SetupCoeff ? iq3SetupCoeff : 1;
    }
    return iq3SetupCoeff;
  };

  return (
    <div className="kouteiCalculateDiv">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={yosetsuFinish}>
        <Col span={4} className="oyabuhin-col">
          <div style={{ display: 'flex' }}>
            {isYosetsuFinishdetail ? (
              <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 5 }}>{processMst?.name}</label>
          </div>
        </Col>
        <Col span={6} className="oyabuhin-col">
          <div>{weldingFinishAmt ? JPYs.format(Math.round(weldingFinishAmt)) : amtEmptyStr}</div>
        </Col>
        <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
          <div>
            <label style={{ marginLeft: 5 }}>{deviceMst?.name}</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isYosetsuFinishdetail ? 'block' : 'none' }}>
        {/* 工程詳細 */}
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            工程詳細
          </Col>
          <Col span={6} className="oyabuhin-detail-col">
            <Checkbox
              key={kouteiCalculateSelectedArr[0].key}
              value={kouteiCalculateSelectedArr[0].value}
              checked={kouteiCalculateSelectedArr[0].key == updateEditedData.dataType}
              disabled={!props?.editMode}
              style={{ marginRight: 6 }}
              onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[0].key)}
            ></Checkbox>
            <label>{kouteiCalculateSelectedArr[0].value}</label>
          </Col>
          <Col span={8} className="oyabuhin-detail-col">
            <div style={{ display: 'flex', justifyContent: 'left', width: '60%' }}>
              <Checkbox
                key={kouteiCalculateSelectedArr[1].key}
                value={kouteiCalculateSelectedArr[1].value}
                disabled={!props?.editMode}
                onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[1].key)}
                checked={kouteiCalculateSelectedArr[1].key == updateEditedData.dataType}
                style={{ marginRight: 6 }}
              ></Checkbox>
              <label>{kouteiCalculateSelectedArr[1].value}</label>
            </div>
            <div style={{ display: 'flex', justifyContent: 'right', width: '40%' }}>
              <Button onClick={settingDataItems} className="dataButton" id="dataBtn1">
                {DataButton}
              </Button>
            </div>
          </Col>
          <Col span={1} className="oyabuhin-detail-col">
            単位
          </Col>
          <Col span={5} className="formula-column">
            計算式
          </Col>
        </Row>
        {/* 工程詳細 */}
        {/*  溶接仕上 */}
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            {processMst?.name}
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalAmtYFinish">{totalSum ? JPYs.format(Math.round(totalSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalTimeYFinish">{totalTimeSum ? secondsToHms(totalTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="totalAmtYFinishIn"
              style={{
                color: Math.round(totalAmt) === Math.round(totalSum) ? 'black' : '#4cc12f',
              }}
            >
              {totalAmt ? JPYs.format(Math.round(totalAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="totalTimeYFinishIn"
              style={{
                color: Math.round(totalTime) === Math.round(totalTimeSum) ? 'black' : '#4cc12f',
              }}
            >
              {totalTime ? secondsToHms(totalTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={2} className="formula-column formula-column-right-border">
            <Popover content={helpCmt.procTotalAmt_shiage}>金額</Popover>
          </Col>
          <Col span={2} className="formula-column">
            <Popover content={helpCmt.procTotalTm_shiage}>時間</Popover>
          </Col>
        </Row>
        {/* 溶接仕上 */}
        {/* 段取金額/時間 */}
        <Row
          style={{ color: 'black' }}
          className="oyabuhin-detail oyabuhin-row"
          onClick={(e) => handleCollpse('YFinishDandori')}
        >
          <Col span={4} className="oyabuhin-detail-col">
            <div style={{ display: 'flex' }}>
              <CaretUpOutlined id={'arrowUpYFinishDandori'} style={{ fontSize: 17, marginTop: 2, display: 'none' }} />

              <CaretDownOutlined id={'arrowDownYFinishDandori'} style={{ fontSize: 17, marginTop: 2 }} />
              <label style={{ marginLeft: 5 }}> 段取金額/時間</label>
            </div>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriAmtYFinish">
              {dandoriAmtSum ? JPYs.format(Math.round(dandoriAmtSum)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriTimeYFinish">{dandoriTimeSum ? secondsToHms(dandoriTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="totalDandoriAmtYFinishIn"
              style={{
                color: Math.round(dandoriAmtSum) === Math.round(totalDandoriAmt) ? 'black' : '#4cc12f',
              }}
            >
              {totalDandoriAmt ? JPYs.format(Math.round(totalDandoriAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="totalDandoriTimeYFinishIn"
              style={{
                color: Math.round(dandoriTimeSum) === Math.round(totalDandoriTime) ? 'black' : '#4cc12f',
              }}
            >
              {totalDandoriTime ? secondsToHms(totalDandoriTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={2} className="formula-column formula-column-right-border">
            <Popover content={helpCmt.setupAmt_p1}>金額</Popover>
          </Col>
          <Col span={2} className="formula-column">
            <Popover content={getSetupTotalTm()}>時間</Popover>
          </Col>
        </Row>
        <div id={'divYFinishDandori'} style={{ display: 'none' }}>
          {(() => {
            const arr = [];
            for (let j = 0; j < processArr[0]?.dandoriDetails?.length; j++) {
              let processDetailOr = processArr[0]?.dandoriDetails[j];
              let processDetailOrEdit = editedData[0]?.dandoriDetails[j];
              let setupTmCmt = getSetupTmCmt(processDetailOr?.processDetailName);
              arr.push(
                <div>
                  {/* ○○_標準 */}
                  <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                    <Col span={4} className="oyabuhin-detail-col">
                      <div style={{ display: 'flex' }}>
                        <span style={{ marginLeft: '20px' }}>{processDetailOr?.processDetailName}</span>
                      </div>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      <label id={j + 'YFinishDandoriAmt'}>
                        {processDetailOr?.dandoriAmt
                          ? JPYs.format(Math.round(processDetailOr?.dandoriAmt))
                          : amtEmptyStr}
                      </label>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      {
                        <label id={j + 'YFinishDandoriTime'}>
                          {processDetailOr?.dandoriTime ? secondsToHms(processDetailOr?.dandoriTime) : timeHmsStr}
                        </label>
                      }
                    </Col>

                    <Col span={4} className="input-col">
                      <CurrencyInput
                        id={j + 'YFinishDandoriAmtIn'}
                        value={processDetailOrEdit?.dandoriAmt ? Math.round(processDetailOrEdit?.dandoriAmt) : 0}
                        defaultValue={0}
                        style={{
                          border: 'none',
                          color:
                            Number(processDetailOr?.dandoriAmt) === Number(processDetailOrEdit?.dandoriAmt)
                              ? 'black'
                              : checkShowHideIcon(j + 'YFinishDandoriAmtCu') === 'none'
                              ? '#4cc12f'
                              : 'red',
                        }}
                        onValueChange={(e) => {
                          //checkRegex(e.target.value);
                          if (editedData[0]?.dandoriAmt != e) {
                            settingNewDandoriAmtInArr(e, j, 0, 'dandoriAmt', '', '');
                            checkInputWithColor(false, '', j + 'YFinishDandoriAmt', e, 'amt');
                          }
                        }}
                        className={
                          editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                        }
                        prefix="¥"
                        decimalsLimit={100}
                      />
                      <div id={j + 'YFinishDandoriAmtCu'} style={{ display: 'none' }}>
                        <div className="currencyIcon">
                          <BsUsbC style={{ fontSize: '25' }} />
                          <BsX style={{ margin: '-8px 0 0 16px' }} />
                          <span class="tooltiptext">
                            金額に変更がありましたので、以降時間と連携して自動計算されない。
                          </span>
                        </div>
                      </div>
                    </Col>

                    <Col span={4} className="input-col">
                      <Row style={{ width: '100%' }}>
                        <Col className="timer-input-col">
                          <Input
                            value={
                              processDetailOrEdit?.dandoriTime
                                ? secondsToHms(processDetailOrEdit?.dandoriTime)
                                : timeHmsStr
                            }
                            id={j + 'YFinishDandoriTimeIn'}
                            style={{
                              width: '100%',
                              color:
                                processDetailOr?.dandoriTime === processDetailOrEdit?.dandoriTime
                                  ? handleShowHide(
                                      Number(processDetailOrEdit?.dandoriAmt),
                                      processDetailOr?.dandoriAmt,
                                      j + 'YFinishDandoriAmtCu'
                                    )
                                  : 'red',
                            }}
                            className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                            onChange={(e) => {
                              settingNewDandoriAmtInArr(e.target.value, j, 0, 'YFinishDandoriAmt', 'input', 'input');
                            }}
                          ></Input>
                        </Col>
                        <Col className="timer-button-col">
                          {editMode ? (
                            <>
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    processDetailOrEdit?.dandoriTime,
                                    '10min',
                                    j + 'YFinishDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 0, 'dandoriTime', '10min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    processDetailOrEdit?.dandoriTime,
                                    '10min',
                                    j + 'YFinishDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 0, 'dandoriTime', '10min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    processDetailOrEdit?.dandoriTime,
                                    'min',
                                    j + 'YFinishDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 0, 'dandoriTime', 'min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    processDetailOrEdit?.dandoriTime,
                                    'min',
                                    j + 'YFinishDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 0, 'dandoriTime', 'min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    processDetailOrEdit?.dandoriTime,
                                    'sec',
                                    j + 'YFinishDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 0, 'dandoriTime', 'sec', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    processDetailOrEdit?.dandoriTime,
                                    'sec',
                                    j + 'YFinishDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 0, 'dandoriTime', 'sec', 'dec');
                                }}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={1} className="oyabuhin-detail-col"></Col>
                    <Col span={2} className="formula-column formula-column-right-border">
                      <Popover content={helpCmt.setupAmt_p1}>金額</Popover>
                    </Col>
                    <Col span={2} className="formula-column">
                      <Popover content={setupTmCmt}>時間</Popover>
                    </Col>
                  </Row>
                </div>
              );
            }
            return arr;
          })()}
        </div>
        {/* 段取金額/時間 */}

        {/* 作業金額/時間 */}
        {/* 作業合計 */}
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('YFinishSagyoTotal')}>
            <div style={{ display: 'flex' }}>
              <CaretUpOutlined
                id={'arrowUpYFinishSagyoTotal'}
                style={{ fontSize: 17, marginTop: 2, display: 'none' }}
              />
              <CaretDownOutlined id={'arrowDownYFinishSagyoTotal'} style={{ fontSize: 17, marginTop: 2 }} />
              <label style={{ marginLeft: 5 }}> 作業金額/時間</label>
            </div>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalSagyoAmtYFinish">{sagyoAmtSum ? JPYs.format(Math.round(sagyoAmtSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalSagyoTimeYFinish">{sagyoTimeSum ? secondsToHms(sagyoTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="totalSagyoAmtYFinishIn"
              style={{
                color: Math.round(sagyoAmtSum) === Math.round(totalSagyoAmt) ? 'black' : '#4cc12f',
              }}
            >
              {totalSagyoAmt ? JPYs.format(Math.round(totalSagyoAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="totalSagyoTimeYFinishIn"
              style={{
                color: Math.round(sagyoTimeSum) === Math.round(totalSagyoTime) ? 'black' : '#4cc12f',
              }}
            >
              {totalSagyoTime ? secondsToHms(totalSagyoTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column"></Col>
        </Row>
        <div id={'divYFinishSagyoTotal'} style={{ display: 'none' }}>
          {(() => {
            const arr = [];
            for (let i = 0; i < processArr?.length; i++) {
              arr.push(
                <div>
                  <Row
                    style={{ color: 'black' }}
                    className="oyabuhin-detail oyabuhin-row"
                    onClick={(e) => subpartDetail(i)}
                  >
                    <Col span={4} className="oyabuhin-detail-col">
                      <div style={{ display: 'flex', marginLeft: '20px' }}>
                        <CaretUpOutlined id={'arrowUpYosetsuFinish' + i} style={{ fontSize: 17, marginTop: 2 }} />
                        <CaretDownOutlined
                          id={'arrowDownYosetsuFinish' + i}
                          style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                        />
                        <span style={{ marginLeft: '5px' }}>{processArr[i]?.processName}</span>
                      </div>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      <label id={i + 'yosetsuSagyoAmt'}>
                        {processArr[i]?.sagyoAmt ? JPYs.format(Math.round(processArr[i]?.sagyoAmt)) : amtEmptyStr}
                      </label>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      <label id={i + 'yosetsuSagyoTime'}>
                        {processArr[i]?.sagyoTime ? secondsToHms(processArr[i]?.sagyoTime) : timeHmsStr}
                      </label>
                    </Col>
                    <Col span={4} className="oyabuhin-detail-col">
                      <label
                        id={i + 'yosetsuSagyoAmtIn'}
                        style={{
                          color: processArr[i]?.sagyoAmt === editedData[i]?.sagyoAmt ? 'black' : '#4cc12f',
                        }}
                      >
                        {editedData[i]?.sagyoAmt ? JPYs.format(Math.round(editedData[i]?.sagyoAmt)) : amtEmptyStr}
                      </label>
                    </Col>
                    <Col span={4} className="oyabuhin-detail-col">
                      <label
                        id={i + 'yosetsuSagyoTimeIn'}
                        style={{
                          color: processArr[i]?.sagyoTime === editedData[i]?.sagyoTime ? 'black' : '#4cc12f',
                        }}
                      >
                        {editedData[i]?.sagyoTime ? secondsToHms(editedData[i]?.sagyoTime) : timeHmsStr}
                      </label>
                    </Col>
                    <Col span={1} className="oyabuhin-detail-col"></Col>
                    <Col span={2} className="formula-column formula-column-right-border">
                      <Popover content={helpCmt.workAmt_p2}>金額</Popover>
                    </Col>
                    <Col span={2} className="formula-column">
                      <Popover content={getWorkTotalTm(processArr[i])}>時間</Popover>
                    </Col>
                  </Row>
                  <div id={'yosetsuFinishSagyo' + i} style={{ display: 'none' }}>
                    {(() => {
                      const arr = [];
                      for (let j = 0; j < processArr[i]?.sagyoDetails?.length; j++) {
                        let ij = String(i) + String(j);
                        let sagyoProcessDetailOr = processArr[i]?.sagyoDetails[j];
                        let sagyoProcessDetailOrEdit = editedData[i]?.sagyoDetails[j];
                        let updEdtData = updateEditedData.editItems[i]?.sagyoDetails[j];
                        arr.push(
                          <div>
                            {/* ○○_標準 */}
                            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                              <Col span={4} className="oyabuhin-detail-col">
                                <div style={{ display: 'flex' }}>
                                  <span style={{ marginLeft: '45px' }}>{sagyoProcessDetailOr.processDetailName}</span>
                                </div>
                              </Col>
                              <Col span={3} className="oyabuhin-detail-col">
                                <label id={ij + 'YFinishSagyoAmt'}>
                                  {sagyoProcessDetailOr?.sagyoAmt
                                    ? JPYs.format(Math.round(sagyoProcessDetailOr?.sagyoAmt))
                                    : amtEmptyStr}
                                </label>
                              </Col>
                              <Col span={3} className="oyabuhin-detail-col">
                                {
                                  <label id={ij + 'YFinishSagyoTime'}>
                                    {sagyoProcessDetailOr?.sagyoTime
                                      ? secondsToHms(sagyoProcessDetailOr?.sagyoTime)
                                      : timeHmsStr}
                                  </label>
                                }
                              </Col>

                              <Col span={4} className="input-col">
                                <CurrencyInput
                                  id={ij + 'YFinishSagyoAmtIn'}
                                  value={
                                    sagyoProcessDetailOrEdit?.sagyoAmt
                                      ? Math.round(sagyoProcessDetailOrEdit?.sagyoAmt)
                                      : 0
                                  }
                                  defaultValue={0}
                                  style={{
                                    border: 'none',
                                    color:
                                      sagyoProcessDetailOr?.sagyoAmt === sagyoProcessDetailOrEdit?.sagyoAmt
                                        ? 'black'
                                        : checkShowHideIcon(ij + 'YFinishSagyoAmtCu') === 'none'
                                        ? '#4cc12f'
                                        : 'red',
                                  }}
                                  onValueChange={(e) => {
                                    //checkRegex(e.target.value);
                                    if (sagyoProcessDetailOrEdit?.sagyoAmt != e) {
                                      settingNewDandoriAmtInArr(e, j, i, 'sagyoAmt', '', '');
                                      checkInputWithColor(false, '', ij + 'YFinishSagyoAmt', e, 'amt');
                                    }
                                  }}
                                  className={
                                    editMode
                                      ? 'input-editable currency resetColor'
                                      : 'input-non-editable currency resetColor'
                                  }
                                  prefix="¥"
                                  decimalsLimit={100}
                                />
                                <div id={ij + 'YFinishSagyoAmtCu'} style={{ display: 'none' }}>
                                  <div className="currencyIcon">
                                    <BsUsbC style={{ fontSize: '25' }} />
                                    <BsX style={{ margin: '-8px 0 0 16px' }} />
                                    <span class="tooltiptext">
                                      金額に変更がありましたので、以降時間と連携して自動計算されない。
                                    </span>
                                  </div>
                                </div>
                              </Col>

                              <Col span={4} className="input-col">
                                <Row style={{ width: '100%' }}>
                                  <Col className="timer-input-col">
                                    <Input
                                      value={
                                        sagyoProcessDetailOrEdit?.sagyoTime
                                          ? secondsToHms(sagyoProcessDetailOrEdit?.sagyoTime)
                                          : timeHmsStr
                                      }
                                      id={ij + 'YFinishSagyoTimeIn'}
                                      style={{
                                        width: '100%',
                                        color:
                                          sagyoProcessDetailOr?.sagyoTime === sagyoProcessDetailOrEdit?.sagyoTime
                                            ? 'black'
                                            : Number(processArr[i]?.senchou) !== Number(editedData[i]?.senchou)
                                            ? '#4cc12f'
                                            : 'red',
                                      }}
                                      className={
                                        editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'
                                      }
                                      onChange={(e) => {
                                        settingNewDandoriAmtInArr(e.target.value, j, i, 'sagyoTime', 'input', 'input');
                                      }}
                                    ></Input>
                                  </Col>
                                  <Col className="timer-button-col">
                                    {editMode ? (
                                      <>
                                        <CaretUpOutlined
                                          style={{ fontSize: 17 + 'px' }}
                                          onClick={() => {
                                            let editedTime = handelIncreaseTime(
                                              sagyoProcessDetailOrEdit?.sagyoTime,
                                              '10min',
                                              ij + 'YFinishSagyoTime'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, j, i, 'sagyoTime', '10min', 'inc');
                                          }}
                                        />
                                        <CaretDownOutlined
                                          style={{ fontSize: 17 + 'px', color: '#000' }}
                                          onClick={() => {
                                            let editedTime = handelDecreaseTime(
                                              sagyoProcessDetailOrEdit?.sagyoTime,
                                              '10min',
                                              ij + 'YFinishSagyoTime'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, j, i, 'sagyoTime', '10min', 'dec');
                                          }}
                                        />
                                        <CaretUpOutlined
                                          style={{ fontSize: 17 + 'px', color: '#000' }}
                                          onClick={() => {
                                            let editedTime = handelIncreaseTime(
                                              sagyoProcessDetailOrEdit?.sagyoTime,
                                              'min',
                                              ij + 'YFinishSagyoTime'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, j, i, 'sagyoTime', 'min', 'inc');
                                          }}
                                        />
                                        <CaretDownOutlined
                                          style={{ fontSize: 17 + 'px', color: '#000' }}
                                          onClick={() => {
                                            let editedTime = handelDecreaseTime(
                                              sagyoProcessDetailOrEdit?.sagyoTime,
                                              'min',
                                              ij + 'YFinishSagyoTime'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, j, i, 'sagyoTime', 'min', 'dec');
                                          }}
                                        />
                                        <CaretUpOutlined
                                          style={{ fontSize: 17 + 'px', color: '#000' }}
                                          onClick={() => {
                                            let editedTime = handelIncreaseTime(
                                              sagyoProcessDetailOrEdit?.sagyoTime,
                                              'sec',
                                              ij + 'YFinishSagyoTime'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, j, i, 'sagyoTime', 'sec', 'inc');
                                          }}
                                        />
                                        <CaretDownOutlined
                                          style={{ fontSize: 17 + 'px', color: '#000' }}
                                          onClick={() => {
                                            let editedTime = handelDecreaseTime(
                                              sagyoProcessDetailOrEdit?.sagyoTime,
                                              'sec',
                                              ij + 'YFinishSagyoTime'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, j, i, 'sagyoTime', 'sec', 'dec');
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={1} className="oyabuhin-detail-col"></Col>
                              <Col span={2} className="formula-column formula-column-right-border">
                                <Popover content={helpCmt.workAmt_p2}>金額</Popover>
                              </Col>
                              <Col span={2} className="formula-column">
                                <Popover content={helpCmt.workTm_shiage}>時間</Popover>
                              </Col>
                            </Row>
                          </div>
                        );
                      }
                      return arr;
                    })()}
                    {/** IQX_WEBEST-271 サブ溶接にも溶接仕上を出来るようにしてほしい */}
                    {processArr[i]?.yousetsuType === WorkTypeGroup.SmInstSize ||
                    processArr[i]?.yousetsuType === WorkTypeGroup.SmInstThick ? (
                      <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                        <Col span={4} className="oyabuhin-detail-col">
                          <span className="ddl_2_noImg" style={{ marginLeft: '45px' }}>
                            合計回数({processArr[i]?.processName})
                          </span>
                        </Col>
                        <Col span={3} className="oyabuhin-detail-col">
                          <label id={i + 'senchouKaisu'}>{processArr[i]?.senchouKaisu}</label>
                        </Col>
                        <Col span={3} className="oyabuhin-detail-col"></Col>
                        <Col span={4} className="input-col">
                          <Input
                            value={editedData[i]?.senchouKaisu}
                            id={i + 'senchouKaisuIn'}
                            min={0}
                            className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                            controls={false}
                            style={{
                              width: '45%',
                              border: 'none',
                              color:
                                Number(editedData[i]?.senchouKaisu) === Number(processArr[i]?.senchouKaisu)
                                  ? 'black'
                                  : 'red',
                            }}
                            onKeyPress={(e) => {
                              handleKeyPress(e, false);
                            }}
                            onChange={(e) => settingNewDandoriAmtInArr(e.target.value, '', i, 'senchouKaisu', '', '')}
                          />
                        </Col>

                        <Col span={4} className="oyabuhin-detail-col"></Col>

                        <Col span={1} className="oyabuhin-detail-col"></Col>
                        <Col span={5} className="formula-column"></Col>
                      </Row>
                    ) : (
                      <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                        <Col span={4} className="oyabuhin-detail-col">
                          <span className="ddl_2_noImg" style={{ marginLeft: '45px' }}>
                            加工長({processArr[i]?.processName})
                          </span>
                        </Col>
                        <Col span={3} className="oyabuhin-detail-col">
                          <label id={i + 'senchou'}>{processArr[i]?.senchou}</label>
                        </Col>
                        <Col span={3} className="oyabuhin-detail-col"></Col>
                        <Col span={4} className="input-col">
                          <Input
                            value={editedData[i]?.senchou}
                            id={i + 'senchouIn'}
                            min={0}
                            className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                            controls={false}
                            style={{
                              width: '45%',
                              border: 'none',
                              color:
                                Number(editedData[i]?.senchou) === Number(processArr[i]?.senchou) ? 'black' : 'red',
                            }}
                            onKeyPress={(e) => {
                              handleKeyPress(e, false);
                            }}
                            onChange={(e) => settingNewDandoriAmtInArr(e.target.value, '', i, 'senchou', '', '')}
                            onBlur={(e) => {
                              toTwoDecimal(i, 'senchou');
                            }}
                          />
                        </Col>

                        <Col span={4} className="oyabuhin-detail-col"></Col>

                        <Col span={1} className="oyabuhin-detail-col"></Col>
                        <Col span={5} className="formula-column"></Col>
                      </Row>
                    )}
                  </div>
                </div>
              );
            }
            return arr;
          })()}
        </div>
      </div>
    </div>
  );
});

export default IQ3KouteiCalculate_YosetsuFinish;
