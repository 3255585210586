import React, { Component, useEffect, useRef, useState } from 'react';
import { Button, Input, Form, Modal, Select, DatePicker } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { WithRouter } from '../../components/WithRouter';
import { CloseOutlined } from '@ant-design/icons';
import {
  JPYs,
  formatDate,
  getEstimateStatusFontColor,
  getNameByRecordId,
  getNameByValue,
  initAdditionalItemCost,
  initAdditionalItemDate,
  initAdditionalItemLabel,
  initAdditionalItemMemo,
  today,
} from '../common/Common.js';
import dayjs from 'dayjs';
import {
  commonTaiochuModal,
  confirmHakiContent,
  confirmUpdateContent,
  confirmDiscardContent,
  confirmScreenChangeContent,
  confirmModalTitle,
  confirmScreenChangeTitle,
  commonModal,
  commonFooter,
  commonDeleteFooter,
  error,
  sameDrawingNoContent,
} from '../common/CommonModal';
import { ErrorMessage } from '../common/Message';
import { amtEmptyStr, saveSameTypes } from '../common/Constant.js';
import { getIsExistSameEstimate } from '../common/CommonAPI.js';

const ParentListDetail = (props) => {
  const location = useLocation();
  const [drawingNo, setDrawingNo] = useState('');
  const [name, setName] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [isOrderId, setIsOrderId] = useState(0);
  const [isOrder, setIsOrder] = useState('');
  const [customer, setCustomer] = useState('');
  const [estimateNum, setEstimateNum] = useState('');

  // new
  const [grossProfit, setGrossProfit] = useState(0);
  const [rate, setRate] = useState(0);
  const [estimatedAmount, setEstimatedAmount] = useState(0);
  const [sizeX, setSizeX] = useState('');
  const [sizeY, setSizeY] = useState('');
  const [sizeZ, setSizeZ] = useState('');
  const [hiyou1, setHiyou1] = useState('');
  const [hiyou2, setHiyou2] = useState('');
  const [hiyou3, setHiyou3] = useState('');

  const [oldDrawingNo, setOldDrawingNo] = useState('');
  const [oldName, setOldName] = useState('');
  const [oldLotNum, setOldLotNum] = useState('');
  const [oldOrder, setOldOrder] = useState('');
  const [oldOrderId, setOldOrderId] = useState('');
  const [oldGrossProfit, setOldGrossProfit] = useState('');
  const [oldEstimateAmount, setOldEstimateAmount] = useState('');
  const [oldSizeX, setoldSizeX] = useState('');
  const [oldSizeY, setoldSizeY] = useState('');
  const [oldSizeZ, setoldSizeZ] = useState('');
  const [oldRate, setOldRate] = useState('');
  const [oldHiyou1, setoldHiyou1] = useState('');
  const [oldHiyou2, setoldHiyou2] = useState('');
  const [oldHiyou3, setoldHiyou3] = useState('');
  const [oldEstimateNum, setOldEstimateNum] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [detailsData, setDetailsData] = useState([]);
  const ref = useRef(null);
  const [hiyous, setHiyous] = useState([]);
  const [memos, setMemos] = useState([]);
  const [labels, setLabels] = useState([]);
  const [dates, setDates] = useState([]);
  const [orderOptions, setOrderOptions] = useState([]);
  let token = localStorage?.getItem('iQxWeb_AccessToken');

  const [paramCostLst, setParamCostLst] = useState([]); // 追加項目の費用マスタ情報
  const [paramLabelLst, setParamLabelLst] = useState([]); // 追加項目のラベルマスタ情報
  const [paramMemoLst, setParamMemoLst] = useState([]); // 追加項目のメモマスタ情報
  const [paramDateLst, setParamDateLst] = useState([]); // 追加項目の日付マスタ情報

  // 同一図番で保存
  const [isSaveSameDrawingNo, setIsSaveSameDrawingNo] = useState(false);
  const [saveSameDrawingNoFncName, setSaveSameDrawingNoFncName] = useState('');

  useEffect(() => {
    let parentDetails = props.selectedData;
    setEditMode(props.editMode != undefined ? props.editMode : false);

    let grossProfit =
      parentDetails?.estimateProducts?.grossTotalPrice == undefined
        ? 0
        : JPYs.format(Math.round(parentDetails?.estimateProducts?.grossTotalPrice));
    let estimateAmount =
      parentDetails?.estimateProducts?.estimateTotalPrice == undefined
        ? 0
        : JPYs.format(Math.round(parentDetails?.estimateProducts?.estimateTotalPrice));
    let rate =
      parentDetails?.estimateProducts?.grossRate == undefined
        ? '0%'
        : parentDetails?.estimateProducts?.grossRate.toLocaleString(navigator.language, {
            minimumFractionDigits: 0,
          }) + '%';
    setDetailsData({ ...parentDetails });
    setDrawingNo(parentDetails?.estimateProducts?.drawingNo);
    setName(parentDetails?.estimateProducts?.name);
    setQuantity(parentDetails?.estimateProducts?.quantity);
    setIsOrderId(parentDetails?.estimateProducts?.estimateStatusId);
    setIsOrder(parentDetails?.estimateProducts?.estimateStatus);
    setCustomer(parentDetails?.estimateProducts?.clientName);
    setEstimateNum(parentDetails?.estimateProducts?.estimateNo);
    setGrossProfit(grossProfit);
    // setEstimatedAmount(JPYs.format(parentDetails.estimatedAmount));
    setEstimatedAmount(estimateAmount);
    setSizeX(parentDetails?.estimateProducts?.sizeX);
    setSizeY(parentDetails?.estimateProducts?.sizeY);
    setSizeZ(parentDetails?.estimateProducts?.sizeZ);
    setRate(rate);

    if (props?.companyInfo?.length > 0 && parentDetails?.epCustomCosts) {
      let companyInfo = props?.companyInfo[0];
      let mstCost = companyInfo?.additionalItemCosts?.filter((item) => item.isUsed && !item.isDeleted);
      let mstLabel = companyInfo?.additionalItemLabels?.filter((item) => item.isUsed && !item.isDeleted);
      let mstMemo = companyInfo?.additionalItemMemos?.filter((item) => item.isUsed && !item.isDeleted);
      let mstDate = companyInfo?.additionalItemDates?.filter((item) => item.isUsed && !item.isDeleted);
      setParamCostLst(mstCost);
      setParamLabelLst(mstLabel);
      setParamMemoLst(mstMemo);
      setParamDateLst(mstDate);
      let customCost = parentDetails?.epCustomCosts;
      let displayCostLst = mstCost?.map((mCost) => {
        let cost = customCost?.find((cCost) => cCost.additionalItemId === mCost.id);
        if (cost) {
          return { ...cost, additionalItemCode: mCost.code, additionalItemName: mCost.name };
        } else {
          return initAdditionalItemCost(mCost.id, mCost.code, mCost.name);
        }
      });
      let customMemo = parentDetails?.epCustomMemos;
      let displayMemoLst = mstMemo?.map((mMemo) => {
        let memo = customMemo?.find((cMemo) => cMemo.additionalItemId === mMemo.id);
        if (memo) {
          return { ...memo, additionalItemCode: mMemo.code, additionalItemName: mMemo.name };
        } else {
          return initAdditionalItemMemo(mMemo.id, mMemo.code, mMemo.name);
        }
      });
      let customLabel = parentDetails?.epCustomLabels;
      let displayLabelLst = mstLabel?.map((mLabel) => {
        let label = customLabel?.find((cLabel) => cLabel.additionalItemId === mLabel.id);
        if (label) {
          return { ...label, additionalItemCode: mLabel.code, additionalItemName: mLabel.name };
        } else {
          return initAdditionalItemLabel(mLabel.id, mLabel.code, mLabel.name);
        }
      });
      let customDate = parentDetails?.epCustomDates;
      let displayDateLst = mstDate?.map((mDate) => {
        let date = customDate?.find((cDate) => cDate.additionalItemId === mDate.id);
        if (date) {
          return { ...date, additionalItemCode: mDate.code, additionalItemName: mDate.name };
        } else {
          return initAdditionalItemDate(mDate.id, mDate.code, mDate.name);
        }
      });
      setHiyous(displayCostLst);
      setMemos(displayMemoLst);
      setLabels(displayLabelLst);
      setDates(displayDateLst);
    } else {
      setHiyous(parentDetails?.epCustomCosts);
      setMemos(parentDetails?.epCustomMemos);
      setLabels(parentDetails?.epCustomLabels);
      setDates(parentDetails?.epCustomDates);
    }
    // getProjectStatusData();
  }, [props.selectedData, props.editMode, props.companyInfo]);

  useEffect(() => {
    getProjectStatusData(props.orderOptions);
  }, [props.orderOptions]);

  // 受注非受注リストのフォントカラーを更新する
  useEffect(() => {
    document.documentElement.style.setProperty(
      '--list-estStatus-color',
      getEstimateStatusFontColor(orderOptions, isOrderId)
    );
  }, [isOrderId]);

  const getProjectStatusData = async (projectStatusData) => {
    // 案件状態データ
    projectStatusData = projectStatusData?.filter((item) => item.info.isUsed == true);
    setOrderOptions(projectStatusData);
  };

  const editData = () => {
    setEditMode(true);
    setOldDrawingNo(document.getElementById('drawingNo').value);
    setOldName(document.getElementById('name').value);
    setOldLotNum(document.getElementById('quantity').value);
    setOldOrder(document.getElementById('estimateStatus').value);
    setOldOrderId(isOrderId);
    setOldGrossProfit(document.getElementById('grossProfit').value);
    setOldEstimateAmount(document.getElementById('estimatedAmount').value);
    setoldSizeX(document.getElementById('sizeX').value);
    setoldSizeY(document.getElementById('sizeY').value);
    setoldSizeZ(document.getElementById('sizeZ').value);
    setOldRate(document.getElementById('rate').value);
    props.updateEditMode(true);
  };
  const updateData = () => {
    // setUpdateConfirm(true);
    setUpdateConfirm(props.showEdtConfirmOK);
    if (!props.showEdtConfirmOK) {
      updConfirmOk();
    }
  };
  const discardData = () => {
    //setCancelConfirm(true);
    setCancelConfirm(props.showEdtConfirmCancle);
    if (!props.showEdtConfirmCancle) {
      discardConfirmOk();
    }
  };

  const updConfirmOk = async () => {
    setUpdateConfirm(false);
    // 画面の入力チェック
    if (!formValidation()) {
      return;
    }

    // 同一図番があるか存在チェックする
    let companySetting = props?.companySettingInfo?.[0];
    if (!isSaveSameDrawingNo && !companySetting?.environments?.isUseSameName) {
      let estProducts = detailsData.estimateProducts;
      let saveSameType = companySetting?.environments?.saveSameType;
      let isExist = await getIsExistSameEstimate(estProducts, saveSameType);
      if (isExist) {
        setIsSaveSameDrawingNo(true);
        setSaveSameDrawingNoFncName('updConfirmOk()');
        return;
      }
    } else {
      setIsSaveSameDrawingNo(false);
    }

    setEditMode(false);

    if (detailsData && detailsData.epCustomCosts) detailsData.epCustomCosts = hiyous;
    if (detailsData && detailsData.epCustomMemos) detailsData.epCustomMemos = memos;
    if (detailsData && detailsData.epCustomLabels) detailsData.epCustomLabels = labels;
    if (detailsData && detailsData.epCustomDates) detailsData.epCustomDates = dates;
    props.onUpdateData(detailsData);
    props.updateEditMode(false);
  };

  // 画面の入力チェック
  const formValidation = () => {
    let ret = true;
    let message = [];
    let input;
    // 日付
    dates?.map((date, index) => {
      input = document.getElementsByName('date' + date?.additionalItemId)?.[0];
      input?.classList?.remove('input-error');
      if (input?.value?.length === 0) {
        // 必須
        message.push(<li key={index}>{ErrorMessage(date?.additionalItemName).E017}</li>);
        input?.classList.add('input-error');
        ret = false;
      }
    });
    if (message?.length > 0) error(<ul>{message}</ul>);
    return ret;
  };

  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  const discardConfirmOk = () => {
    // 2023/08/08 記載　親部品リストの詳細更新は旧パラのまま更新
    setCancelConfirm(false);
    setEditMode(false);
    props.updateEditMode(false);
    setDrawingNo(oldDrawingNo);
    setName(oldName);
    // setLotNumber(oldLotNum);
    // setGrossProfit(oldGrossProfit);
    // setRate(oldRate);
    // setEstimatedAmount(oldEstimateAmount);
    // setSizeX(oldSizeX);
    // setSizeY(oldSizeY);
    // setSizeZ(oldSizeZ);
    setIsOrder(oldOrder);
    setIsOrderId(oldOrderId);
    if (detailsData.estimateProducts) {
      detailsData.estimateProducts['drawingNo'] = oldDrawingNo;
      detailsData.estimateProducts['name'] = oldName;
      // detailsData['lotNum'] = oldLotNum;
      // detailsData['grossProfit'] = oldGrossProfit;
      // detailsData['rate'] = oldRate;
      // detailsData['estimatedAmount'] = oldEstimateAmount;
      // detailsData['sizeX'] = oldSizeX;
      // detailsData['sizeY'] = oldSizeY;
      // detailsData['sizeZ'] = oldSizeZ;
      detailsData.estimateProducts['estimateStatusId'] = oldOrderId;
      detailsData.estimateProducts['estimateStatus'] = oldOrder;
    }
  };

  const discardConfirmCancel = () => {
    setCancelConfirm(false);
    props.updateEditMode(true);
  };

  // 同一図面で保存する確認メッセージのOkボタン押下
  const saveSameDrawingNoOk = async () => {
    await eval(saveSameDrawingNoFncName);
  };

  // 同一図面で保存する確認メッセージのCancelボタン押下
  const saveSameDrawingNoCancel = () => {
    setIsSaveSameDrawingNo(false);
  };

  const sameDrawingNoModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>確認</p>
    </div>
  );

  const changeValue = (i, event, name) => {
    if (name == 'cost') {
      const val = JSON.parse(JSON.stringify(hiyous));
      val[i]['unitPrice'] = event.target.value;

      setHiyous(val);
      detailsData['epCustomCosts'] = val;
    } else if (name == 'label') {
      const val = JSON.parse(JSON.stringify(labels));
      val[i]['label'] = event.target.value;

      setLabels(val);
      detailsData['epCustomLabels'] = val;
    } else if (name == 'memo') {
      const val = JSON.parse(JSON.stringify(memos));
      val[i]['memo'] = event.target.value;

      setMemos(val);
      detailsData['epCustomMemos'] = val;
    }
  };

  // 日付を変更した場合、
  const changeDate = (i, dateString) => {
    if (dateString !== '') {
      const val = JSON.parse(JSON.stringify(dates));
      val[i]['date'] = dateString;
      setDates(val);
      detailsData['epCustomDates'] = val;
    }
  };

  // お客様コードでお客様名称を取得
  function getNameById(id, Lists) {
    let retValue = '';
    if (id) {
      let info = Lists?.filter((info) => info.code === id);
      if (info?.length > 0) {
        retValue = info[0].name;
      }
    }
    return retValue;
  }

  return (
    <>
      <Form style={{ overflowY: 'hidden' }}>
        <div className="d-flex" style={{ marginTop: 7 }}>
          <div>
            <Button
              style={{
                marginLeft: 10,
                display: editMode ? 'block' : 'none',
              }}
              className="mainButton"
              id="update"
              onClick={updateData}
            >
              更新
            </Button>
          </div>
          <div>
            <Button
              style={{
                marginLeft: 10,
                display: editMode ? 'block' : 'none',
              }}
              className="cancelButton"
              id="discard"
              onClick={discardData}
            >
              破棄
            </Button>
          </div>
          <div className="ms-auto">
            <Button
              style={{
                marginRight: 10,
                display: 'none',
                display: editMode ? 'none' : 'block',
              }}
              // className={props.isFromiQ3 !== undefined && props.isFromiQ3 ? 'disabledButton' : 'mainButton'}
              className={
                props.selectedData === undefined || props.selectedData === 0 || props?.isFromParent || props.isFromiQ3
                  ? 'disabledButton'
                  : 'mainButton'
              }
              id="edit"
              onClick={editData}
            >
              編集
            </Button>
          </div>
        </div>
        <div
          className="overflow-auto"
          style={{ height: props.isFromiQ3 == undefined || props.isFromiQ3 == false ? 760 : 525, marginTop: 10 }}
        >
          <Table className="propertiesTbl">
            <thead>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">お客様</label>
                </td>
                <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                    className="input-non-editable "
                    ref={ref}
                    name="customerNm"
                    id="customerNm"
                    value={customer}
                    type="text"
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">図番</label>
                </td>
                <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    ref={ref}
                    name="drawingNo"
                    id="drawingNo"
                    value={drawingNo}
                    type="text"
                    onChange={(e) => {
                      setDrawingNo(e.target.value);
                      detailsData.estimateProducts[e.target.name] = e.target.value;
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">名称</label>
                </td>
                <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    name="name"
                    id="name"
                    value={name}
                    type="text"
                    onChange={(e) => {
                      setName(e.target.value);
                      detailsData.estimateProducts[e.target.name] = e.target.value;
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">数量</label>
                </td>

                <td style={{ verticalAlign: 'middle', width: '13.3%' }}>
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '90%' }}
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-non-editable "
                    ref={ref}
                    name="quantity"
                    id="quantity"
                    value={quantity}
                    type="text"
                    onChange={(e) => {
                      setQuantity(e.target.value);
                      detailsData.estimateProducts[e.target.name] = e.target.value;
                    }}
                  />
                </td>

                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">粗利益</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '90%' }}
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-non-editable "
                    ref={ref}
                    name="grossProfit"
                    id="grossProfit"
                    value={grossProfit}
                    type="text"
                    // onChange={(e) => {
                    //   setGrossProfit(e.target.value);
                    //   detailsData.estimateProducts[e.target.name] = e.target.value;
                    // }}
                    // prefix={<label style={{ fontSize: '13px' }}>¥</label>}
                  />
                </td>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">粗利率</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '90%', textAlign: 'left' }}
                    // className={editMode ? 'input-editable' : 'input-non-editable '}
                    className="input-non-editable "
                    ref={ref}
                    name="rate"
                    id="rate"
                    value={rate}
                    type="text"
                    onChange={(e) => {
                      setRate(e.target.value);
                      detailsData.estimateProducts[e.target.name] = e.target.value;
                    }}
                    // suffix={<label style={{ fontSize: '13px', textAlign: 'left' }}>%</label>}
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">受注非受注</label>
                </td>
                <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <Select
                      className="iq3-detail-select list-estimate-status"
                      value={isOrderId}
                      name="estimateStatus"
                      id="estimateStatus"
                      onChange={(e) => {
                        let orderName = getNameByRecordId(e, orderOptions);
                        setIsOrder(orderName);
                        setIsOrderId(e);
                        detailsData.estimateProducts['estimateStatusId'] = e;
                        detailsData.estimateProducts['estimateStatus'] = orderName;
                      }}
                      style={{ marginLeft: 5, width: '40.8%' }}
                    >
                      {orderOptions?.map((option, index) => (
                        <Select.Option key={index} value={option.id}>
                          {option.name}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <Input
                      style={{
                        padding: 5,
                        marginLeft: 5,
                        width: '98.2%',
                        color: getEstimateStatusFontColor(orderOptions, isOrderId),
                      }}
                      // className="input-non-editable "
                      className={'input-non-editable '}
                      ref={ref}
                      name="estimateStatus"
                      id="estimateStatus"
                      value={getNameByRecordId(isOrderId, orderOptions)}
                      // value={isOrder}
                      type="text"
                    />
                  )}
                </td>
              </tr>

              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">見積金額</label>
                </td>
                <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                  <Input
                    type="text"
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-non-editable "
                    style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                    name="estimatedAmount"
                    id="estimatedAmount"
                    value={estimatedAmount}
                    // onChange={(e) => {
                    //   setEstimatedAmount(e.target.value);
                    //   detailsData[e.target.name] = e.target.value;
                    // }}
                    // prefix={<label style={{ fontSize: '13px' }}>¥</label>}
                  ></Input>
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">サイズX</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    type="text"
                    className={'input-non-editable '}
                    style={{ padding: 5, marginLeft: 5, width: '90%' }}
                    name="sizeX"
                    id="sizeX"
                    value={sizeX}
                    onChange={(e) => {
                      setSizeX(e.target.value);
                      detailsData.estimateProducts[e.target.name] = e.target.value;
                    }}
                  ></Input>
                </td>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">サイズY</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    type="text"
                    className={'input-non-editable '}
                    style={{ padding: 5, marginLeft: 5, width: '90%' }}
                    name="sizeY"
                    id="sizeY"
                    value={sizeY}
                    onChange={(e) => {
                      setSizeY(e.target.value);
                      detailsData.estimateProducts[e.target.name] = e.target.value;
                    }}
                  ></Input>
                </td>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">サイズZ</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    type="text"
                    className={'input-non-editable '}
                    style={{ padding: 5, marginLeft: 5, width: '90%' }}
                    name="sizeZ"
                    id="sizeZ"
                    value={sizeZ}
                    onChange={(e) => {
                      setSizeZ(e.target.value);
                      detailsData.estimateProducts[e.target.name] = e.target.value;
                    }}
                  ></Input>
                </td>
              </tr>

              {/* 費用 */}
              {hiyous != undefined
                ? hiyous?.map((item, index) => {
                    let { additionalItemId, additionalItemName, unitPrice, isUseUnit, totalPrice } = item;
                    unitPrice = totalPrice;
                    unitPrice = unitPrice == undefined ? 0 : JPYs.format(Math.round(unitPrice));
                    return (
                      <>
                        <tr key={index}>
                          <td className="detail-tbl-val-title">
                            <label className="detail-tbl-val-title-label">{additionalItemName}</label>
                          </td>
                          <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                            <Input
                              style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                              className={'input-non-editable '}
                              name={'cost' + additionalItemId}
                              id={'cost' + additionalItemId}
                              value={unitPrice}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  })
                : ''}

              {/* 日付 */}
              {dates != undefined
                ? dates?.map((item, index) => {
                    let { additionalItemId, additionalItemName, date } = item;
                    return (
                      <>
                        <tr key={index}>
                          <td className="detail-tbl-val-title">
                            <label className="detail-tbl-val-title-label">{additionalItemName}</label>
                          </td>
                          <td colSpan={6} style={{ verticalAlign: 'middle', width: '33.3%' }}>
                            {editMode ? (
                              <DatePicker
                                name={'date' + additionalItemId}
                                id={'date' + additionalItemId}
                                style={{ padding: 5, marginLeft: 5, width: '40.8%' }}
                                className={editMode ? 'input-editable' : 'input-non-editable'}
                                value={date ? dayjs(date, 'YYYY-MM-DD') : ''}
                                onChange={(date, dateString) => changeDate(index, dateString)}
                              />
                            ) : (
                              <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                                {date ? formatDate(date) : ''}
                              </label>
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })
                : ''}

              {/* ラベル */}
              {labels != undefined
                ? labels?.map((item, index) => {
                    let { additionalItemId, additionalItemName, label } = item;
                    return (
                      <>
                        <tr key={index}>
                          <td className="detail-tbl-val-title">
                            <label className="detail-tbl-val-title-label">{additionalItemName}</label>
                          </td>
                          <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                            <Input
                              style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                              className={editMode ? 'input-editable ' : 'input-non-editable '}
                              name={'label' + additionalItemId}
                              id={'label' + additionalItemId}
                              value={label}
                              onChange={(event) => changeValue(index, event, 'label')}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  })
                : ''}

              {/* メモ */}
              {memos != undefined
                ? memos?.map((item, index) => {
                    let { additionalItemId, additionalItemName, memo } = item;
                    return (
                      <>
                        <tr key={index}>
                          <td className="detail-tbl-val-title">
                            <label className="detail-tbl-val-title-label">{additionalItemName}</label>
                          </td>
                          <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                            <Input
                              style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                              className={editMode ? 'input-editable ' : 'input-non-editable '}
                              name={'memo' + additionalItemId}
                              id={'memo' + additionalItemId}
                              value={memo}
                              onChange={(event) => changeValue(index, event, 'memo')}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  })
                : ''}
            </thead>
          </Table>

          {/** 更新の確認メッセージ */}
          {updateConfirm
            ? commonModal(
                updateConfirm,
                confirmModalTitle,
                commonFooter(updConfirmOk, updConfirmCancel),
                null,
                400,
                updConfirmOk,
                updConfirmCancel,
                confirmUpdateContent,
                null,
                null,
                false
              )
            : ''}
          {/** 破棄の確認メッセージ */}
          {cancelConfirm
            ? commonModal(
                cancelConfirm,
                confirmModalTitle,
                commonFooter(discardConfirmOk, discardConfirmCancel),
                null,
                400,
                discardConfirmOk,
                discardConfirmCancel,
                confirmDiscardContent,
                null,
                null,
                false
              )
            : ''}

          {/* 同一図番_Modal */}
          {isSaveSameDrawingNo
            ? commonModal(
                isSaveSameDrawingNo,
                sameDrawingNoModalTitle,
                commonFooter(saveSameDrawingNoOk, saveSameDrawingNoCancel),
                null,
                400,
                saveSameDrawingNoOk,
                saveSameDrawingNoCancel,
                sameDrawingNoContent(
                  getNameByValue(props?.companySettingInfo?.[0]?.environments?.saveSameType, saveSameTypes)
                ),
                null,
                null,
                false
              )
            : ''}
        </div>
      </Form>
    </>
  );
};

export default WithRouter(ParentListDetail);
