import React, { useState, useEffect, forwardRef } from 'react';
import { StarOutlined, StarFilled, PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Table, Row, Input, Col, Select, Space, Image, Button } from 'antd';
import { Table as RTable } from 'react-bootstrap';

import { JPYs, handleKeyPress, secondsToHms, setTimetoSec, formatDate } from '../common/Common';
import checked from '../../assets/images/checked.png';
import { commonModal } from '../common/CommonModal';
const kounyuuhinColumns = [
  {
    id: '1',
    title: 'No',
    dataIndex: 'no',
    width: 50 + 'px',
    sorter: (a, b) => a.id - b.id,
    sortOrder: 'ascend',
    render: (text, record, index) => <span>{index + 1}</span>,
  },

  {
    id: '2',
    title: '取付時間(秒)',
    dataIndex: 'info',
    width: 100 + 'px',
    className: 'cm-a-right',
    render: (info) => info.processTime,
  },

  {
    id: '3',
    title: 'コード',
    dataIndex: 'code',
    width: 100 + 'px',
  },

  {
    id: '4',
    title: '種別',
    dataIndex: 'purchaseCategoryId',
    width: 100 + 'px',
  },
  {
    id: '5',
    title: '名称',
    dataIndex: 'name',
    width: 150 + 'px',
  },
  {
    id: '6',
    title: '型番',
    dataIndex: 'info',
    width: 120 + 'px',
    render: (info) => info.model,
  },
  {
    id: '7',
    title: 'メーカー名',
    dataIndex: 'info',
    width: 120 + 'px',
    render: (info) => info.makerName,
  },
  {
    id: '8',
    title: '仕入先',
    dataIndex: 'info',
    width: 150 + 'px',
    render: (info) => info.supplierName,
  },
  {
    id: '9',
    title: '仕入先コード',
    dataIndex: 'info',
    width: 150 + 'px',
    render: (info) => info.supplierCode,
  },
  {
    id: '10',
    title: '単価',
    dataIndex: 'price',
    width: 70 + 'px',
    className: 'cm-a-right',
    render: (value) => JPYs.format(value),
  },
  {
    id: '11',
    title: '単価保持',
    dataIndex: 'info',
    width: 95 + 'px',
    render: (index, item) => {
      return item.info.isKeepPrice === 1 ? (
        <Image
          preview={false}
          width={13}
          src={checked}
          style={{ marginLeft: '3px' }}
          // key={item['key']}
          id={item.id}
        />
      ) : (
        <></>
      );
    },
    className: 'cm-a-center',
  },

  {
    id: '12',
    title: 'ソート番号',
    dataIndex: 'info',
    width: 100 + 'px',
    className: 'cm-a-right',
    render: (info) => info.sortNum,
  },
  {
    id: '13',
    title: '作成日',
    dataIndex: 'created',
    width: 100 + 'px',
    render: (created) => formatDate(created),
  },
  {
    id: '14',
    title: '更新日',
    dataIndex: 'modified',
    width: 100 + 'px',
    render: (modified) => formatDate(modified),
  },
  {
    id: '15',
    title: '引当用',
    dataIndex: 'info',
    width: 100 + 'px',
    render: (index, item) => {
      return item.info.isAllocation ? <StarFilled /> : <StarOutlined />;
    },
    className: 'cm-a-center',
  },
  {
    id: '16',
    title: '備考',
    dataIndex: 'info',
    render: (info) => info.remarks,
    width: 200 + 'px',
  },
  {
    id: '17',
    title: '表示',
    dataIndex: 'info',
    width: 100 + 'px',
    render: (index, item) => {
      return item.info.isUsed ? (
        <Image
          preview={false}
          width={13}
          src={checked}
          style={{ marginLeft: '3px' }}
          // key={item['key']}
          id={item.id}
        />
      ) : (
        <></>
      );
    },
    className: 'cm-a-center',
  },
  {
    id: '18',
    title: '',
    dataIndex: 'id',
    hidden: true,
  },
]?.filter((item) => !item.hidden);
const purchaseColumns = [
  // {
  //   id: '1',
  //   title: '購入品マスタId',
  //   dataIndex: 'purchaseId',
  //   width: 180 + 'px',
  //   hidden: true,
  // },
  {
    id: '1',
    title: '種別',
    dataIndex: 'purchaseCategoryId',
    width: 180 + 'px',
    // render: (_, record) =>
    //   purchaseLst?.length >= 1 ? (
    //     <label style={{ fontSize: '13px' }}>{getNameById(record.purchaseCategoryId)}</label>
    //   ) : null,
  },
  {
    id: '2',
    title: '品名',
    dataIndex: 'name',
    width: 200 + 'px',
  },
  {
    id: '3',
    title: '型番',
    dataIndex: 'model',
    width: 120 + 'px',
  },
  {
    id: '4',
    title: '単価',
    dataIndex: 'unitPrice',
    width: 100 + 'px',
    className: 'cm-a-right',
  },
  {
    id: '5',
    title: '数量別単価',
    dataIndex: 'unitPriceByQuantity',
    width: 100 + 'px',
    className: 'cm-a-right',
  },
  {
    id: '6',
    title: '必要数',
    dataIndex: 'required',
    width: 80 + 'px',
    className: 'cm-a-right',
  },
  {
    id: '7',
    title: '金額',
    dataIndex: 'price',
    width: 100 + 'px',
    className: 'cm-a-right',
  },
  {
    id: '8',
    title: '合計金額',
    dataIndex: 'totalPrice',
    width: 100 + 'px',
    className: 'cm-a-right',
  },
  {
    id: '9',
    title: '数量別金額',
    dataIndex: 'priceByQuantity',
    width: 100 + 'px',
    className: 'cm-a-right',
  },
  {
    id: '10',
    title: '合計必要数',
    dataIndex: 'totalRequired',
    width: 120 + 'px',
  },
  {
    id: '11',
    title: '単価保持',
    dataIndex: 'isKeepPrice',
    width: 120 + 'px',
  },
  {
    id: '12',
    title: '取付時間',
    dataIndex: 'processTime',
    width: 120 + 'px',
  },
  {
    id: '13',
    title: '合計取付時間',
    dataIndex: 'totalProcessTime',
    width: 120 + 'px',
  },
  {
    id: '14',
    title: '仕入先コード',
    dataIndex: 'supplierCode',
    width: 150 + 'px',
  },
  {
    id: '15',
    title: '仕入先名',
    dataIndex: 'supplierName',
    width: 150 + 'px',
  },
  {
    id: '16',
    title: 'メーカー',
    dataIndex: 'makerName',
    width: 150 + 'px',
  },

  {
    id: '17',
    title: '備考',
    dataIndex: 'purchaseRemark',
    width: 200 + 'px',
  },
];
const ParentKouteiInput_Kumitate_DetailsModal = forwardRef((props, ref) => {
  const [purchasesData, setPurchasesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [purchaseCategory, setPurchaseCategory] = useState([]);
  const [category, setCategory] = useState(0);
  const [kumitateJikanList, setKumitateJikanList] = useState([]);

  const [detailsModal, setDetailsModal] = useState(props.isDetailsModal);
  useEffect(() => {
    setPurchasesData(props.purchases);
    setFilteredData(props.purchases);
    setPurchaseCategory(props.purchaseCategory);
    setDetailsModal(props.isDetailsModal);
    setKumitateJikanList(props.kumitateJikanList);
  }, [props.purchases, props.purchaseCategory, props.isDetailsModal, props.kumitateJikanList]);

  const addData = () => {
    if (kumitateJikanList?.length > 0) {
      const data = {
        no: kumitateJikanList.slice(-1)[0].no + 1,
        name: '',
        workTime: 0,
        workCount: 0,
        workTotalTime: 0,
      };
      let addArr = [...kumitateJikanList, data];
      setKumitateJikanList(addArr);
    }
  };
  const deleteKumitate = (index, e) => {
    if (kumitateJikanList?.length > 1) {
      setKumitateJikanList(
        kumitateJikanList?.filter((j) => {
          return ![index]?.includes(j.no);
        })
      );
    }
  };
  const changeListData = (id, value, name) => {
    const temp = JSON.parse(JSON.stringify(kumitateJikanList));

    if (name === 'workCount' || name === 'workTime') {
      // Find the row with the given id
      const updatedData = temp?.map((row) => {
        if (row.no === id) {
          const updatedRow = { ...row, [name]: value };
          // Calculate and update totalCount
          updatedRow.workTotalTime = updatedRow.workTime * updatedRow.workCount;
          return updatedRow;
        }
        return row;
      });

      setKumitateJikanList(updatedData);
    } else {
      // Find the row with the given id
      const updatedData = temp?.map((row) => {
        if (row.no === id) {
          const updatedRow = { ...row, [name]: value };
          return updatedRow;
        }
        return row;
      });

      setKumitateJikanList(updatedData);
    }
  };
  const kumitateJikanListContent = (
    <>
      <Row className="registerModal mt-1">
        <RTable style={{ width: '100%' }}>
          <thead>
            <tr style={{ textAlign: 'center' }}>
              <td style={{ width: '20%' }} className="charge_title">
                名称
              </td>
              <td style={{ width: '27%' }} className="charge_title">
                組立時間
              </td>
              <td style={{ width: '16%' }} className="charge_title">
                作業回数
              </td>
              <td style={{ width: '25%' }} className="charge_title">
                合計組立時間
              </td>
            </tr>
          </thead>
          <tbody>
            {kumitateJikanList?.map((item, index) => (
              <tr key={index}>
                <td style={{ padding: 3 }}>
                  <Input value={item.name} onChange={(e) => changeListData(item.no, e.target.value, 'name')}></Input>
                </td>
                <td style={{ padding: 3 }}>
                  <Input
                    value={secondsToHms(item.workTime)}
                    onChange={(e) => changeListData(item.no, setTimetoSec(e.target.value), 'workTime')}
                  ></Input>
                </td>
                <td style={{ padding: 3 }}>
                  <Input
                    value={item.workCount}
                    onChange={(e) => changeListData(item.no, e.target.value, 'workCount')}
                    onKeyPress={(e) => {
                      handleKeyPress(e, false);
                    }}
                  ></Input>
                </td>
                <td style={{ padding: 3 }}>
                  <Input value={secondsToHms(item.workTotalTime)} style={{ pointerEvents: 'none' }}></Input>
                </td>
                <td style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '18%' }}>
                  <Row justify="center">
                    <PlusCircleFilled
                      className="add-remove-icon"
                      style={{ marginLeft: 10, marginRight: 10 }}
                      onClick={addData}
                    />
                    <CloseCircleFilled className="add-remove-icon" onClick={(e) => deleteKumitate(item.no, e)} />
                  </Row>
                </td>
              </tr>
            ))}
          </tbody>
        </RTable>
      </Row>
    </>
  );
  const filterByCategory = (e) => {
    setCategory(e);
    let currentData = [...purchasesData];
    let purchaseFilterData = currentData;
    if (e > 0) {
      purchaseFilterData = purchaseFilterData?.filter((item) => item.purchaseCategoryId === e);
    }

    setFilteredData(purchaseFilterData);
  };
  const updateDetails = () => {
    props.updateKumitateJikanList(kumitateJikanList);
    setDetailsModal(false);
    props.updateDetailsModal(false);
  };
  const cancelDetails = () => {
    setDetailsModal(false);
    props.updateDetailsModal(false);
  };
  const addModalTitle = (
    <div
      style={{
        width: 800,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>詳細</p>
    </div>
  );
  const closeModal = () => {
    setDetailsModal(false);
    props.updateDetailsModal(false);
  };
  const add = () => {};
  const addContent = (
    <>
      <div>
        <Row align="middle" className="tbl-header" style={{ padding: 5 }}>
          <Col span={6}>
            <label>購入品情報</label>
          </Col>
          <Col span={8}>
            <Select style={{ width: '100%' }} value={category} defaultValue={0} onChange={filterByCategory}>
              <Select.Option value={0}>{'全ての種別'}</Select.Option>
              {purchaseCategory?.map((item, index) => (
                <Select.Option key={index} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row>
          <Table
            columns={purchaseColumns}
            dataSource={filteredData}
            scroll={{ y: 590, x: '10vw' }}
            pagination={{
              position: ['bottomLeft'],
              total: (record) => record?.length,
              showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
              defaultCurrent: 1,
              showSizeChanger: false,
            }}
          />
        </Row>

        <Row align="middle" className="tbl-header mt-4" style={{ padding: 5 }}>
          <Col span={6}>
            <label>その他の組立時間</label>
          </Col>
        </Row>
        <div style={{ height: '250px', overflow: 'auto' }}>{kumitateJikanListContent}</div>
        <Row className="mt-4" justify="end">
          <Col>
            <Space>
              <Button className="mainButton" onClick={updateDetails}>
                更新
              </Button>
              <Button className="cancelButton" onClick={cancelDetails}>
                キャンセル
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
    </>
  );
  return (
    <>
      {detailsModal
        ? commonModal(detailsModal, addModalTitle, null, null, 800, add, closeModal, addContent, null, null, true)
        : ''}
    </>
  );
});

export default ParentKouteiInput_Kumitate_DetailsModal;
