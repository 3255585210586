import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';

import { Row, Col, Input, Select, Popover, Checkbox, Button } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  checkInput,
  secondsToHms,
  setTimetoSec,
  checkInputWithColor,
  isNumber,
  checkShowHideIcon,
  JPYs,
  handleShowHide,
  handleKeyPress,
  kouteiCalculateSelectedArr,
  handleCollpse,
} from '../common/Common.js';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';
import { WorkType, DataType } from '../common/enums';
import { amtEmptyStr, timeHmsStr, DataButton, helpCmt } from '../common/Constant';

//員数
// const partCnt = 4;

const ParentKouteiCalculate_Konpo = forwardRef((props, ref) => {
  const [isYousetsudetail, setIYousetsudetail] = useState(false);
  const [totalAmt, setTotalAmt] = useState('');
  const [totalTime, setTotalTime] = useState('');
  const [totalSagyoAmt, setTotalSagyoAmt] = useState('');
  const [totalSagyoTime, setTotalSagyoTime] = useState('');
  const [totalDandoriAmt, setTotalDandoriAmt] = useState('');
  const [totalDandoriTime, setTotalDandoriTime] = useState('');

  const [totalSum, setTotalSum] = useState('');
  const [totalTimeSum, setTotalTimeSum] = useState('');
  const [sagyoAmtSum, setSagyoAmtSum] = useState('');
  const [sagyoTimeSum, setSagyoTimeSum] = useState('');
  const [dandoriAmtSum, setDandoriAmtSum] = useState('');
  const [dandoriTimeSum, setDandoriTimeSum] = useState('');
  const [subTotalAmt, setSubTotalAmt] = useState([]);
  const [subTotalTime, setSubTotalTime] = useState([]);
  const [totalChild, setTotalChild] = useState([]);
  const [editData, setEditData] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [selectedDataFlg, setSelectedDataFlg] = useState(0);

  const [deviceMst, setDeviceMst] = useState([]);
  const [processMst, setProcessMst] = useState([]);
  const [patternInfo, setPatternInfo] = useState([]);
  const [oldEditedData, setOldEditedData] = useState([]);
  const [updateEditedData, setUpdateEditedData] = useState([]);
  const [konpoAmt, setKonpoAmt] = useState(0);

  const settingEditData = (arr) => {
    let sagyoAmt = 0;
    let sagyoTimeSec = 0;
    let childSumArr = [];
    let dandoriAmtSum = 0;
    let dandoriTimeSumSec = 0;

    if (arr[0] == undefined) return;

    dandoriAmtSum = arr[0].dandoriAmt;
    dandoriTimeSumSec = arr[0].dandoriTime;

    for (let i = 0; i < arr?.length; i++) {
      sagyoAmt += arr[i].sagyoAmt;
      sagyoTimeSec += arr[i].sagyoTime;

      childSumArr.push({
        id: i,
        processName: arr[i].processName,
        dandoriAmt: arr[i].dandoriAmt,
        dandoriTime: arr[i].dandoriTime,
        dandoriTimeSec: arr[i].dandoriTime,
        sagyoAmt: arr[i].sagyoAmt,
        sagyoTime: arr[i].sagyoTime,
        sagyoTimeSec: arr[i].sagyoTime,
        processCount: arr[i].processCount,
        processTime: arr[i].processTime,
      });
    }
    return childSumArr;
  };

  const calculateTotals = (editDataArr) => {
    let sagyoTimeSumSec = 0;
    let sagyoAmtSum = 0;
    editDataArr?.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt ? Math.round(element.sagyoAmt) : 0;
      sagyoTimeSumSec += element.sagyoTimeSec;
    });

    let totalSum =
      (sagyoAmtSum ? Math.round(sagyoAmtSum) : 0) +
      (editDataArr[0].dandoriAmt ? Math.round(editDataArr[0].dandoriAmt) : 0); // 画面表示用（四捨五入した金額を足す）
    let totalTimeSum = sagyoTimeSumSec + editDataArr[0].dandoriTimeSec;
    let sagyoTimeSum = sagyoTimeSumSec;

    let totals = [];
    let i = 0;
    totals.push({
      id: i++,
      sagyoAmtSum: sagyoAmtSum,
      sagyoTimeSum: sagyoTimeSum,
      sagyoTimeSumSec: sagyoTimeSumSec,
      totalSum: totalSum,
      totalTimeSum: totalTimeSum,
    });
    return totals;
  };

  const handleChangeCheckData = (e, i) => {
    const editedData = { ...updateEditedData };
    editedData.dataType = e;
    setUpdateEditedData(editedData);
    setKonpoAmt(e == DataType.Data ? editedData.totalDataPrice : editedData.totalEditPrice);
    props?.updateKouteiCal(editedData, WorkType.PaPacking);
  };

  // IQX_WEBEST-16 データ表示に戻す
  const settingDataItems = () => {
    updateEditedData.editItems = JSON.parse(JSON.stringify(updateEditedData?.dataItems));
    updateEditedData.editDataFlg = false;
    setEditData(JSON.parse(JSON.stringify(orgData)));
    setTotalSagyoAmt(sagyoAmtSum ? sagyoAmtSum : 0);
    setTotalSagyoTime(sagyoTimeSum ? sagyoTimeSum : 0);
    setTotalAmt(totalSum ? totalSum : 0);
    setTotalTime(totalTimeSum ? totalTimeSum : 0);
    updateEditedData.totalEditPrice = totalSum ? totalSum : 0;
    updateEditedData.totalEditTime = totalTimeSum ? totalTimeSum : 0;
    const cuDivs = document.querySelectorAll('div[id*="Cu"]');

    cuDivs.forEach((div) => {
      div.style.display = 'none';
    });

    if (updateEditedData.dataType == DataType.Data) {
      setKonpoAmt(updateEditedData.totalDataPrice);
    } else {
      setKonpoAmt(updateEditedData.totalEditPrice);
    }
    props?.updateKouteiCal(updateEditedData, WorkType.PaPacking);
  };

  useEffect(() => {
    let editData = [];
    let totals = [];
    setKonpoAmt(0);
    setDandoriTimeSum(0);
    setDandoriAmtSum(0);
    setSagyoTimeSum(0);
    setSagyoAmtSum(0);
    setTotalSum(0);
    setTotalTimeSum(0);

    setTotalAmt(0);
    setTotalTime(0);
    setTotalSagyoAmt(0);
    setTotalSagyoTime(0);
    let parameters = props?.selectedDataDetail?.estimateProducts?.calcParameters?.parameters;
    let processMst = parameters?.process
      ? parameters.process?.filter((item) => item.workType === WorkType.PaPacking)[0]
      : [];
    let konpoAdditions = props?.selectedDataDetail?.estimateProducts?.additions?.filter(
      (item) => item.processId == processMst?.id
    )[0];
    // IQX_WEBEST-16 工程積算タブで編集データのチェックがOFFだと、手入力で入力した内容がリセットされてしまう
    let konpoAdditionsEditData = konpoAdditions?.editItems;
    let editDataFlg = konpoAdditions?.editDataFlg;
    let patternInfo = props?.selectedDataDetail?.estimateProducts?.processSelect
      ? props?.selectedDataDetail?.estimateProducts?.processSelect?.details?.filter(
          (item) => item.processId == processMst?.id
        )
      : [];
    setPatternInfo([]);
    if (patternInfo == undefined) return;
    if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;
    let deviceMst =
      parameters?.device && patternInfo
        ? parameters?.device?.filter(
            (item) => item.processId == patternInfo[0]?.processId && item.id == patternInfo[0]?.deviceId
          )[0]
        : []; //テスト用ID;
    setProcessMst(processMst ? processMst : []);
    setDeviceMst(deviceMst ? deviceMst : []);
    setPatternInfo(patternInfo);
    // 新規の場合
    /* if (konpoAdditions == undefined) { */
    let konpoAddition = {};
    let detailItems = [];
    let editDetailItems = [];
    let processCount = 0;
    let processName = '基本合計梱包金額/時間';
    let processTime = 0;

    // 基本合計組立情報
    detailItems.push({
      id: 1,
      processName: processName,
      dandoriAmt: 0,
      dandoriTime: 0,
      sagyoAmt: 0,
      sagyoTime: 0,
      processCount: processCount,
      processTime: processTime,
      kihon: true,
      formula: '',
    });

    let id = 1;
    if (props?.selectedDataDetail?.estimateProducts?.additions?.length > 0) {
      let lastRecord = props?.selectedDataDetail?.estimateProducts?.additions?.sort((a, b) => b.no - a.no)?.[0];
      id = lastRecord?.no + 1;
    }
    konpoAddition = {
      no: processMst.id,
      processId: processMst.id,
      dataType: konpoAdditions?.dataType ? konpoAdditions.dataType : DataType.Data,
      dataItems: detailItems,
      editItems: editDetailItems,
      totalDandori: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalSagyo: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalDataPrice: 0,
      totalDataTime: 0,
      totalEditPrice: 0,
      totalEditTime: 0,
      editDataFlg: editDataFlg,
    };
    konpoAdditions = konpoAddition;
    /* } */

    if (konpoAdditions?.dataItems) {
      let orgData = JSON.parse(JSON.stringify(konpoAdditions?.dataItems));
      setOrgData(JSON.parse(JSON.stringify(konpoAdditions?.dataItems)));
      if (deviceMst != undefined && orgData?.length > 0) {
        let packingProcessInput = props?.selectedDataDetail?.estimateProducts?.processInput?.packingProcessInputInfo;
        //部品数
        let partCnt = Number(packingProcessInput?.smPartsCount);
        orgData[0].processCount = partCnt;
        konpoAdditions.dataItems[0].processCount = partCnt;
        // 基本情報の総重量
        let weight = props?.selectedDataDetail?.estimateProducts?.totalWeight;
        //総重量が0、子部品数が0の場合、段取と加工を計算しない。
        if (weight === 0 && partCnt === 0) {
          /** 段取金額/時間 */
          // 段取時間設定
          orgData[0].dandoriTime = 0;
          konpoAdditions.dataItems[0].dandoriTime = 0;
          // 段取時間の合計
          setDandoriTimeSum(0);

          // 段取金額設定
          orgData[0].dandoriAmt = 0;
          // 段取金額の合計
          setDandoriAmtSum(0);
          konpoAdditions.dataItems[0].dandoriAmt = 0;

          // 1部品当たりの作業時間
          orgData[0].processTime = 0;
          konpoAdditions.dataItems[0].processTime = 0;

          /** 作業金額/時間 */
          // 作業時間
          orgData[0].sagyoTime = 0;
          konpoAdditions.dataItems[0].sagyoTime = 0;
          setSagyoTimeSum(0);

          // 作業金額
          //　作業時間＊時間チャージ
          orgData[0].sagyoAmt = 0;
          konpoAdditions.dataItems[0].sagyoAmt = 0;
          setSagyoAmtSum(0);
          setTotalSum(0);
          konpoAdditions.totalDataPrice = 0;
          konpoAdditions.totalDandori.dataItemsAmt = 0;
          konpoAdditions.totalSagyo.dataItemsAmt = 0;

          setTotalTimeSum(0);
          konpoAdditions.totalDataTime = 0;
          konpoAdditions.totalDandori.dataItemsTime = 0;
          konpoAdditions.totalSagyo.dataItemsTime = 0;
        } else {
          /** 段取金額/時間 */
          // 段取時間設定
          //　基本段取時間＊重量係数＊部品点数
          let dandoriTimeSec = calDandoriTime(partCnt, deviceMst, weight);
          let dandoriTimeHms = dandoriTimeSec ? dandoriTimeSec : 0;
          orgData[0].dandoriTime = dandoriTimeHms ? dandoriTimeHms : 0;
          konpoAdditions.dataItems[0].dandoriTime = dandoriTimeSec ? dandoriTimeSec : 0;
          // 段取時間の合計
          setDandoriTimeSum(dandoriTimeHms ? dandoriTimeHms : 0);

          // 段取金額設定
          //段階時間＊時間チャージ
          let dandoriAmt = calDandoriAmt(dandoriTimeSec, deviceMst);
          orgData[0].dandoriAmt = dandoriAmt ? dandoriAmt : 0;
          // 段取金額の合計
          setDandoriAmtSum(dandoriAmt ? dandoriAmt : 0);
          konpoAdditions.dataItems[0].dandoriAmt = dandoriAmt ? dandoriAmt : 0;

          // 1部品当たりの作業時間
          let mWorkHTime = deviceMst?.details?.workBasicTime;
          orgData[0].processTime = mWorkHTime ? mWorkHTime : 0;
          konpoAdditions.dataItems[0].processTime = mWorkHTime ? mWorkHTime : 0;

          /** 作業金額/時間 */
          // 作業時間
          // 1部品当たりの作業時間＊部品数＊重量係数
          let cSagyoTimeKonpoSec = calSagyoTime(partCnt, mWorkHTime, deviceMst, weight);
          let cSagyoTimeKonpo = cSagyoTimeKonpoSec;
          cSagyoTimeKonpo = cSagyoTimeKonpo ? cSagyoTimeKonpo : 0;
          orgData[0].sagyoTime = cSagyoTimeKonpo;
          konpoAdditions.dataItems[0].sagyoTime = cSagyoTimeKonpo;
          setSagyoTimeSum(cSagyoTimeKonpo);

          // 作業金額
          //　作業時間＊時間チャージ
          let cSagyoAmtKonpo = calSagyoAmt(cSagyoTimeKonpo, deviceMst);
          orgData[0].sagyoAmt = cSagyoAmtKonpo ? cSagyoAmtKonpo : 0;
          konpoAdditions.dataItems[0].sagyoAmt = cSagyoAmtKonpo ? cSagyoAmtKonpo : 0;
          setSagyoAmtSum(cSagyoAmtKonpo ? cSagyoAmtKonpo : 0);

          let totalSum = (dandoriAmt ? Math.round(dandoriAmt) : 0) + (cSagyoAmtKonpo ? Math.round(cSagyoAmtKonpo) : 0); // 画面表示用（四捨五入した金額を足す）
          setTotalSum(totalSum ? totalSum : 0);
          konpoAdditions.totalDataPrice = totalSum ? totalSum : 0;
          konpoAdditions.totalDandori.dataItemsAmt = dandoriAmt ? Math.round(dandoriAmt) : 0;
          konpoAdditions.totalSagyo.dataItemsAmt = cSagyoAmtKonpo ? Math.round(cSagyoAmtKonpo) : 0;

          let totalTimeSec = dandoriTimeSec + cSagyoTimeKonpo;
          setTotalTimeSum(totalTimeSec ? totalTimeSec : 0);
          konpoAdditions.totalDataTime = totalTimeSec ? totalTimeSec : 0;
          konpoAdditions.totalDandori.dataItemsTime = dandoriTimeSec ? dandoriTimeSec : 0;
          konpoAdditions.totalSagyo.dataItemsTime = cSagyoTimeKonpo ? cSagyoTimeKonpo : 0;
        }
        setOrgData(orgData);
        if (konpoAdditionsEditData?.length > 0 && editDataFlg) {
          editData = settingEditData(JSON.parse(JSON.stringify(konpoAdditionsEditData)));
        } else {
          editData = settingEditData(JSON.parse(JSON.stringify(orgData)));
        }
        if (editData != undefined && editData?.length > 0) {
          konpoAdditions.editItems = editData;
          setEditData(editData);
          setOldEditedData(editData);
          totals = calculateTotals(editData);
          // 編集
          setTotalAmt(totals[0]?.totalSum ? totals[0]?.totalSum : 0);
          konpoAdditions.totalEditPrice = totals[0]?.totalSum ? totals[0]?.totalSum : 0;
          konpoAdditions.totalDandori.editItemsAmt = editData[0].dandoriAmt ? Math.round(editData[0].dandoriAmt) : 0;
          konpoAdditions.totalSagyo.editItemsAmt = totals[0]?.sagyoAmtSum ? totals[0]?.sagyoAmtSum : 0;

          setTotalTime(totals[0]?.totalTimeSum ? totals[0]?.totalTimeSum : 0);
          konpoAdditions.totalEditTime = totals[0]?.totalTimeSum ? totals[0]?.totalTimeSum : 0;
          konpoAdditions.totalDandori.editItemsTime = editData[0].dandoriTime ? editData[0].dandoriTime : 0;
          konpoAdditions.totalSagyo.editItemsTime = totals[0]?.sagyoTimeSum ? totals[0]?.sagyoTimeSum : 0;

          setTotalSagyoAmt(totals[0]?.sagyoAmtSum ? totals[0]?.sagyoAmtSum : 0);
          setTotalSagyoTime(totals[0]?.sagyoTimeSum ? totals[0]?.sagyoTimeSum : 0);
        }
        setKonpoAmt(
          konpoAdditions?.dataType == DataType.Data ? konpoAdditions.totalDataPrice : konpoAdditions.totalEditPrice
        );
        setUpdateEditedData(konpoAdditions);
        props?.updateKouteiCal(konpoAdditions, WorkType.PaPacking);
      }
    }
  }, [
    props?.activeTabKey,
    props?.selectedDataDetail,
    props?.activePartMenu,
    props.selectedDataDetail?.esIq3Info,
    props?.isReCal,
  ]);

  const Konpo = () => {
    if (isYousetsudetail == true) {
      setIYousetsudetail(false);
    } else {
      setIYousetsudetail(true);
    }
  };

  const subpartKonpoDetail = (i) => {
    let showHide = document.getElementById('KonpoProcess' + i).style.display;

    if (showHide == 'block') {
      document.getElementById('KonpoProcess' + i).style.display = 'none';
      document.getElementById('arrowDownKonpo' + i).style.display = 'block';
      document.getElementById('arrowUpKonpo' + i).style.display = 'none';
    } else {
      document.getElementById('KonpoProcess' + i).style.display = 'block';
      document.getElementById('arrowDownKonpo' + i).style.display = 'none';
      document.getElementById('arrowUpKonpo' + i).style.display = 'block';
    }
  };

  const childDetail = (i) => {
    let showHide = document.getElementById('childPartKonpo' + i).style.display;
    if (showHide == 'block') {
      document.getElementById('childPartKonpo' + i).style.display = 'none';
      document.getElementById('arrowUpChildKonpo' + i).style.display = 'none';
      document.getElementById('arrowDownChildKonpo' + i).style.display = 'block';
    } else {
      document.getElementById('childPartKonpo' + i).style.display = 'block';
      document.getElementById('arrowUpChildKonpo' + i).style.display = 'block';
      document.getElementById('arrowDownChildKonpo' + i).style.display = 'none';
    }
  };

  const setKonpoDisplay = (display) => {
    setIYousetsudetail(display);
  };

  useImperativeHandle(ref, () => ({
    getKouteiCalInfoPacking: () => {
      return updateEditedData;
    },
    setKonpoDisplay: setKonpoDisplay,
  }));

  const settingNewDandoriAmtInArr = (value, indexj, objId, timeType, timeAct) => {
    let newState = [...editData];
    updateEditedData.editDataFlg = true; // IQX_WEBEST-16 編集データを保持フラグOn
    setOldEditedData(JSON.parse(JSON.stringify(newState)));
    let oldValue;
    let oldChild = JSON.parse(JSON.stringify(newState[indexj]));
    let sagyoAmtSum = 0;
    let dandoriAmtSum = 0;
    let dandoriAmt = 0;
    let sagyoAmt = 0;
    let sagyoTimeSec = 0;
    let dandoriTimeSec = 0;
    let sagyoTimeSumSec = 0;
    let dandoriTimeSumSec = 0;
    let totalChild = 0;
    let childSumArrState = [];
    let childPartArr = newState;
    let cSagyoAmtKonpoSum = 0;
    let cDadoriAmtSum = 0;
    let csagyoTimeSec = 0;
    let cDandoriTimeSec = 0;
    if (value === undefined) {
      value = 0;
    } else {
      value = value ? Number(value) : 0;
    }

    let childPart = editData[indexj];
    if (indexj === '') {
      oldValue = JSON.parse(JSON.stringify(editData[indexj][objId]));
      newState[objId] = value;
    } else {
      oldValue = childPart[objId];
      if (timeType === '') {
        childPart[objId] = parseFloat(value);
      } else {
        childPart[objId] = value;
      }
      newState = childPart;
    }
    // 基本情報の総重量
    let weight = props?.selectedDataDetail?.estimateProducts?.totalWeight;
    if (objId === 'sagyoTime') {
      if (document.getElementById(indexj + 'cSagyoAmtKonpoCu').style.display !== undefined) {
        let showHideCu = document.getElementById(indexj + 'cSagyoAmtKonpoCu').style.display;
        if (showHideCu === 'block') {
          // checkInputWithColor(true, '', indexj + 'cSagyoAmtKonpo', childPart[indexj].sagyoAmt, 'red');
        } else {
          if (value === 0) {
            newState.sagyoAmt = 0;
          } else if (timeAct === 'input') {
            let oldValue = oldChild[indexj];
            let timeType = value - oldValue.sagyoTime;
            childPart.sagyoAmt = changeAmtwithTime(newState.sagyoAmt, timeType, timeAct, value);
            childPart.sagyoTime = value ? value : 0;
          } else {
            newState.sagyoAmt = changeAmtwithTime(newState.sagyoAmt, timeType, timeAct, value);
            newState.sagyoTime = value ? value : 0;
          }
          newState = childPart;
          updateEditedData.editItems[indexj].sagyoAmt = newState.sagyoAmt ? newState.sagyoAmt : 0;
          updateEditedData.editItems[indexj].sagyoTime = value ? value : 0;
          /* checkInputWithColor(false,'',indexi + 'cSagyoAmtKonpo', newState[indexi].sagyoAmt, 'green'); 
          checkInputWithColor(true, '', indexj + 'cSagyoAmtKonpo', childPart.sagyoAmt, 'green');*/
        }
      }
    } else if (objId === 'sagyoAmt') {
      newState.sagyoAmt = value ? value : 0;
      updateEditedData.editItems[indexj].sagyoAmt = value ? value : 0;
    } else if (objId === 'processCount') {
      childPartArr[0].processCount = value ? value : 0;
      updateEditedData.editItems[indexj].processCount = value ? value : 0;
      /** 段取金額/時間 */
      // 1部品当たりの作業時間＊部品数＊重量係数
      // 段取時間設定
      let dandoriTimeSec = calDandoriTime(value, deviceMst, weight);
      let dandoriTimeHms = dandoriTimeSec;
      childPartArr[0].dandoriTime = dandoriTimeHms;
      updateEditedData.editItems[indexj].dandoriTime = dandoriTimeSec;
      if (document.getElementById('konpoTotalDandoriAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById('konpoTotalDandoriAmtCu').style.display;
        if (showHideCu === 'none') {
          // 段取金額設定
          //段階時間＊時間チャージ
          let dandoriAmt = calDandoriAmt(dandoriTimeSec, deviceMst);
          childPartArr[0].dandoriAmt = dandoriAmt;
          updateEditedData.editItems[indexj].dandoriAmt = dandoriAmt;
        }
      }

      // 作業時間
      // 1部品当たりの作業時間＊部品数＊重量係数
      let workBasicTime = childPartArr[0].processTime;
      let cSagyoTimeKonpoSec = calSagyoTime(value, workBasicTime, deviceMst, weight);
      let cSagyoTimeKonpo = cSagyoTimeKonpoSec;
      childPartArr[0].sagyoTime = cSagyoTimeKonpo ? cSagyoTimeKonpo : 0;
      updateEditedData.editItems[indexj].sagyoTime = cSagyoTimeKonpo ? cSagyoTimeKonpo : 0;
      // 作業金額
      //　加工時間＊時間チャージ
      if (document.getElementById(indexj + 'cSagyoAmtKonpoCu').style.display !== undefined) {
        let showHideCu = document.getElementById(indexj + 'cSagyoAmtKonpoCu').style.display;
        if (showHideCu === 'none') {
          childPartArr[0].sagyoAmt = calSagyoAmt(cSagyoTimeKonpo, deviceMst);
          updateEditedData.editItems[indexj].sagyoAmt = childPartArr[0].sagyoAmt;
        }
      }
    } else if (objId === 'processTime') {
      // 作業時間
      let workBasicTimeIn = value;
      childPartArr[0].processTime = value ? value : 0;
      updateEditedData.editItems[indexj].processTime = value ? value : 0;
      // 1部品当たりの作業時間＊部品数＊重量係数
      let cSagyoTimeKonpoSec = calSagyoTime(childPartArr[0].processCount, workBasicTimeIn, deviceMst, weight);
      let cSagyoTimeKonpo = cSagyoTimeKonpoSec;
      childPartArr[0].sagyoTime = cSagyoTimeKonpo ? cSagyoTimeKonpo : 0;
      updateEditedData.editItems[indexj].sagyoTime = cSagyoTimeKonpo ? cSagyoTimeKonpo : 0;
      // 作業金額
      //　加工時間＊時間チャージ
      childPartArr[0].sagyoAmt = calSagyoAmt(cSagyoTimeKonpo, deviceMst);
      updateEditedData.editItems[indexj].sagyoAmt = childPartArr[0]?.sagyoAmt ? childPartArr[0]?.sagyoAmt : 0;
    } else if (objId === 'dandoriAmt') {
      newState.dandoriAmt = value ? value : 0;
      updateEditedData.editItems[indexj].dandoriAmt = value ? value : 0;
    } else if (objId === 'dandoriTime') {
      newState.dandoriTime = value ? value : 0;
      updateEditedData.editItems[indexj].dandoriTime = value ? value : 0;
      // 段取金額設定
      //段階時間＊時間チャージ
      if (document.getElementById('konpoTotalDandoriAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById('konpoTotalDandoriAmtCu').style.display;
        if (showHideCu === 'none') {
          let dandoriAmt = calDandoriAmt(value, deviceMst);
          newState.dandoriAmt = dandoriAmt ? dandoriAmt : 0;
          setTotalDandoriAmt(dandoriAmt ? dandoriAmt : 0);
          updateEditedData.editItems[indexj].dandoriAmt = value ? value : 0;
        }
      }
    }

    // データ更新
    dandoriAmt = newState.dandoriAmt;
    dandoriTimeSec = newState.dandoriTime;
    for (let j = 0; j < childPartArr?.length; j++) {
      childSumArrState.push({
        id: j,
        dandoriAmt: dandoriAmt ? Math.round(dandoriAmt) : 0,
        dandoriTimeSec: dandoriTimeSec,
        dandoriTime: dandoriTimeSec,
        sagyoAmt: +childPartArr[j].sagyoAmt,
        sagyoTime: +childPartArr[j].sagyoTime,
        sagyoTimeSec: +childPartArr[j].sagyoTime,
        processCount: childPartArr[j].processCount,
        processTime: childPartArr[j].processTime,
        kihon: childPartArr[j].kihon,
      });
    }

    childSumArrState?.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt ? Math.round(element.sagyoAmt) : 0;
      sagyoTimeSumSec += element.sagyoTimeSec;
    });

    let totalSum = (sagyoAmtSum ? Math.round(sagyoAmtSum) : 0) + (dandoriAmt ? Math.round(dandoriAmt) : 0); // 画面表示用（四捨五入した金額を足す）
    let totalTimeSumSec = sagyoTimeSumSec + dandoriTimeSec;
    let sagyoTimeSum = sagyoTimeSumSec;

    setTotalSagyoAmt(sagyoAmtSum ? sagyoAmtSum : 0);
    setTotalSagyoTime(sagyoTimeSum ? sagyoTimeSum : 0);
    setTotalAmt(totalSum ? totalSum : 0);
    setTotalTime(totalTimeSumSec ? totalTimeSumSec : 0);
    updateEditedData.totalEditPrice = totalSum ? totalSum : 0;
    updateEditedData.totalEditTime = totalTimeSumSec ? totalTimeSumSec : 0;
    updateEditedData.totalDandori.editItemsAmt = dandoriAmt ? dandoriAmt : 0;
    updateEditedData.totalDandori.editItemsTime = dandoriTimeSec ? dandoriTimeSec : 0;
    updateEditedData.totalSagyo.editItemsAmt = sagyoAmtSum ? sagyoAmtSum : 0;
    updateEditedData.totalSagyo.editItemsTime = sagyoTimeSumSec ? sagyoTimeSumSec : 0;

    setEditData(childSumArrState);
    if (updateEditedData.dataType == DataType.Data) {
      setKonpoAmt(updateEditedData.totalDataPrice);
    } else {
      setKonpoAmt(updateEditedData.totalEditPrice);
    }
    props?.updateKouteiCal(updateEditedData, WorkType.PaPacking);
  };

  const calSagyoTime = (partCntIn, workBasicTime, deviceMst, weight) => {
    // 作業時間
    // 重量係数
    let prepCoeffThWeights = deviceMst?.details?.prepCoeffThWeightMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
    );
    let prepCoeffThWeightNo =
      prepCoeffThWeights?.length > 0 ? prepCoeffThWeights[0]?.no : deviceMst?.details?.prepCoeffThWeightMaster[0].no;
    let prepCoeffThWeight =
      prepCoeffThWeightNo == 0
        ? 0
        : deviceMst?.details?.prepCoeffThWeightItems?.filter(
            (item) => item.no == prepCoeffThWeightNo && !item.isOutOfRange
          )[0]?.value;
    // 1部品当たりの作業時間＊部品数＊重量係数
    let cSagyoTimeKonpoSec = workBasicTime * partCntIn * prepCoeffThWeight;
    cSagyoTimeKonpoSec = cSagyoTimeKonpoSec ? cSagyoTimeKonpoSec : 0;
    cSagyoTimeKonpoSec = cSagyoTimeKonpoSec * getProductionQuantityCoeff(deviceMst); // IQX_WEBEST-278 作業時間＊生産個数係数
    return cSagyoTimeKonpoSec;
  };

  const calSagyoAmt = (cSagyoTimeKonpoSec, deviceMst) => {
    // 作業金額
    // 1部品当たりの作業時間
    let mWorkHTime = cSagyoTimeKonpoSec;
    let mWorkHTimeMin = mWorkHTime / 60;
    //装置の作業時間チャージ
    let mWorkHRate = deviceMst?.details?.charges.work;
    //　加工時間＊時間チャージ
    let cSagyoAmtKonpo = (mWorkHTimeMin * mWorkHRate) / 60.0;
    cSagyoAmtKonpo = cSagyoAmtKonpo ? cSagyoAmtKonpo : 0;
    return cSagyoAmtKonpo;
  };

  const calDandoriTime = (partCnt, deviceMst, weight) => {
    if (partCnt == 0) return 0;
    // 段取時間設定
    // 重量係数
    let mBendCoeffMsts = deviceMst?.details?.prepCoeffThWeightMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
    );
    let mBendCoeffMstNo =
      mBendCoeffMsts?.length > 0 ? mBendCoeffMsts[0]?.no : deviceMst?.details?.prepCoeffThWeightMaster[0]?.no;
    let mBendCoeff =
      mBendCoeffMstNo == 0
        ? 0
        : deviceMst?.details?.prepCoeffThWeightItems?.filter(
            (item) => item.no == mBendCoeffMstNo && !item.isOutOfRange
          )[0]?.value;
    // 部品点数
    let prepThQtyMasters = deviceMst?.details?.prepThQtyMaster?.filter(
      (item) => (item.value == partCnt || item.value > partCnt) && !item.isOutOfRange
    );
    let buhinCntNo = prepThQtyMasters?.length > 0 ? prepThQtyMasters[0]?.no : deviceMst?.details?.prepThQtyMaster[0].no;
    let buhinCnt =
      buhinCntNo == 0
        ? 0
        : deviceMst?.details?.prepCoeffThChildQtyItems?.filter((item) => item.no == buhinCntNo && !item.isOutOfRange)[0]
            ?.value;
    //　基本段取時間＊重量係数＊部品点数
    let dandoriTimeSec = deviceMst?.details?.prepBasicTime * mBendCoeff * buhinCnt;
    dandoriTimeSec = dandoriTimeSec ? dandoriTimeSec : 0;
    return dandoriTimeSec;
  };

  const calDandoriAmt = (dandoriTimeSec, deviceMst) => {
    //段階時間
    let mSetTimeMin = dandoriTimeSec / 60;
    //装置の段階時間チャージ
    let mSetHRate = deviceMst?.details?.charges.prep;
    //段階時間＊時間チャージ
    let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0;
    dandoriAmt = dandoriAmt ? dandoriAmt : 0;
    return dandoriAmt;
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = parseFloat(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    intAmt = intAmt ? intAmt : 0;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal) => {
    let editedAmt;
    let totalSec = Number(timeVal);
    let intAmt = checkStr(amt);

    if (action === 'input') {
      editedAmt = intAmt + typeTime;
    } else {
      // 作業金額
      //　加工時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, deviceMst);
    }
    editedAmt = editedAmt ? editedAmt : 0;
    return editedAmt;
  };

  // IQX_WEBEST-278 工程積算タブの表示に生産個数係数が含まれていなかったので、含むようにしてほしい
  const getProductionQuantityCoeff = (deviceMst) => {
    let productionQuantityCoeff = 1;
    let quantity = props?.selectedDataDetail?.estimateProducts?.quantity;
    // 生産個数係数
    let productionCoeffTh = deviceMst?.details?.productionQtyCoeffThMaster?.filter(
      (item) => (item.value == quantity || item.value > quantity) && !item.isOutOfRange
    );
    if (productionCoeffTh) {
      let productionCoeffThNo =
        productionCoeffTh?.length > 0
          ? productionCoeffTh[0]?.no
          : deviceMst?.details?.productionQtyCoeffThMaster?.[0]?.no;
      let productionCoeff =
        productionCoeffThNo == 0
          ? 0
          : deviceMst?.details?.productionQtyCoeffThItems?.filter(
              (item) => item.no == productionCoeffThNo && !item.isOutOfRange
            )[0]?.value;

      productionQuantityCoeff = productionCoeff ? productionCoeff : 1;
    }
    return productionQuantityCoeff;
  };

  return (
    <div className="kouteiCalculateDiv" style={{ display: patternInfo?.length > 0 ? 'block' : 'none' }}>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={Konpo}>
        <Col span={4} className="oyabuhin-col">
          <div style={{ display: 'flex' }}>
            {isYousetsudetail ? (
              <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 5 }}>梱包</label>
          </div>
        </Col>
        <Col span={6} className="oyabuhin-col">
          <div>{konpoAmt ? JPYs.format(Math.round(konpoAmt)) : amtEmptyStr}</div>
        </Col>
        <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
          <div>
            <label style={{ marginLeft: 5 }}>{deviceMst?.name}</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isYousetsudetail ? 'block' : 'none' }}>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            工程詳細
          </Col>
          <Col span={6} className="oyabuhin-detail-col">
            <Checkbox
              key={kouteiCalculateSelectedArr[0].key}
              value={kouteiCalculateSelectedArr[0].value}
              checked={kouteiCalculateSelectedArr[0].key == updateEditedData.dataType}
              disabled={!props?.editMode}
              style={{ marginRight: 6 }}
              onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[0].key)}
            ></Checkbox>
            <label>{kouteiCalculateSelectedArr[0].value}</label>
          </Col>
          <Col span={8} className="oyabuhin-detail-col">
            <div style={{ display: 'flex', justifyContent: 'left', width: '60%' }}>
              <Checkbox
                key={kouteiCalculateSelectedArr[1].key}
                value={kouteiCalculateSelectedArr[1].value}
                disabled={!props?.editMode}
                onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[1].key)}
                checked={kouteiCalculateSelectedArr[1].key == updateEditedData.dataType}
                style={{ marginRight: 6 }}
              ></Checkbox>
              <label>{kouteiCalculateSelectedArr[1].value}</label>
            </div>
            <div style={{ display: 'flex', justifyContent: 'right', width: '40%' }}>
              <Button onClick={settingDataItems} className="dataButton" id="dataBtn1">
                {DataButton}
              </Button>
            </div>
          </Col>
          <Col span={1} className="oyabuhin-detail-col">
            単位
          </Col>
          <Col span={5} className="formula-column">
            計算式
          </Col>
        </Row>

        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            {processMst?.name}
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="konpoTotalAmt">{totalSum ? JPYs.format(Math.round(totalSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="konpoTotalTime">{totalTimeSum ? secondsToHms(totalTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="konpoTotalAmtIn"
              className="resetColor"
              style={{ color: Math.round(totalSum) === Math.round(totalAmt) ? 'black' : '#4cc12f' }}
            >
              {totalAmt ? JPYs.format(Math.round(totalAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="konpoTotalTimeIn"
              className="resetColor"
              style={{ color: totalTimeSum === totalTime ? 'black' : '#4cc12f' }}
            >
              {totalTime ? secondsToHms(totalTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={2} className="formula-column formula-column-right-border">
            <Popover content={helpCmt.procTotalAmt}>金額</Popover>
          </Col>
          <Col span={2} className="formula-column">
            <Popover content={helpCmt.procTotalTm}>時間</Popover>
          </Col>
        </Row>

        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            段取金額/時間
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="konpoTotalDandoriAmt">
              {orgData[0]?.dandoriAmt ? JPYs.format(Math.round(orgData[0]?.dandoriAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="konpoTotalDandoriTime">
              {orgData[0]?.dandoriTime ? secondsToHms(orgData[0]?.dandoriTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={4} className="input-col">
            <CurrencyInput
              id={'konpoTotalDandoriAmtIn'}
              value={editData[0]?.dandoriAmt ? Math.round(editData[0]?.dandoriAmt) : 0}
              defaultValue={0}
              onValueChange={(e) => {
                if (totalDandoriAmt != e) {
                  settingNewDandoriAmtInArr(e, 0, 'dandoriAmt', '', '');
                  checkInputWithColor(false, '', 'konpoTotalDandoriAmt', e, 'amt');
                }
              }}
              style={{
                border: 'none',
                color:
                  Math.round(orgData[0]?.dandoriAmt) === Math.round(editData[0]?.dandoriAmt)
                    ? 'black'
                    : checkShowHideIcon('konpoTotalDandoriAmtCu') === 'none'
                    ? '#4cc12f'
                    : 'red',
              }}
              className={
                props?.editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
              }
              prefix="¥"
              decimalsLimit={100}
            />
            <div id={'konpoTotalDandoriAmtCu'} style={{ display: 'none' }} className="currencyReset">
              <div className="currencyIcon">
                <BsUsbC style={{ fontSize: '25' }} />
                <BsX style={{ margin: '-8px 0 0 16px' }} />
                <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
              </div>
            </div>
          </Col>
          <Col span={4} className="input-col">
            <Row style={{ width: '100%' }}>
              <Col className="timer-input-col">
                <Input
                  value={editData[0]?.dandoriTime ? secondsToHms(editData[0]?.dandoriTime) : timeHmsStr}
                  id={'KonpoTotalDandoriTimeIn'}
                  style={{
                    width: '100%',
                    color:
                      orgData[0]?.dandoriTime === editData[0]?.dandoriTime
                        ? 'black'
                        : oldEditedData[0]?.processCount === editData[0]?.processCount
                        ? 'red'
                        : '#4cc12f',
                  }}
                  className={props?.editMode ? 'input-editable' : 'input-non-editable'}
                  onChange={(e) => {
                    //disableTimer('test');
                    settingNewDandoriAmtInArr(e.target.value, 0, 'dandoriTime', 'input', 'input');
                  }}
                ></Input>
              </Col>
              <Col className="timer-button-col">
                {props?.editMode ? (
                  <>
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px' }}
                      onClick={() => {
                        let editedTime = handelIncreaseTime(editData[0]?.dandoriTime, '10min', 'cDandoroTimeKonpo');
                        settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTime', '10min', 'inc');
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelDecreaseTime(editData[0]?.dandoriTime, '10min', 'cDandoroTimeKonpo');
                        settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTime', '10min', 'dec');
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelIncreaseTime(editData[0]?.dandoriTime, 'min', 'cDandoroTimeKonpo');
                        settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTime', 'min', 'inc');
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelDecreaseTime(editData[0]?.dandoriTime, 'min', 'cDandoroTimeKonpo');
                        settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTime', 'min', 'dec');
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelIncreaseTime(editData[0]?.dandoriTime, 'sec', 'cDandoroTimeKonpo');
                        settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTime', 'sec', 'inc');
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelDecreaseTime(editData[0]?.dandoriTime, 'sec', 'cDandoroTimeKonpo');
                        settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTime', 'sec', 'dec');
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={2} className="formula-column formula-column-right-border">
            <Popover content={helpCmt.setupAmt_p1}>金額</Popover>
          </Col>
          <Col span={2} className="formula-column">
            <Popover content={helpCmt.setupTm_p2}>時間</Popover>
          </Col>
        </Row>

        <Row
          style={{ color: 'black' }}
          className="oyabuhin-detail oyabuhin-row"
          onClick={(e) => handleCollpse('KonpoSagyo')}
        >
          <Col span={4} className="oyabuhin-detail-col">
            <div style={{ display: 'flex', overflow: 'hidden' }}>
              <CaretUpOutlined id={'arrowUpKonpoSagyo'} style={{ fontSize: 17, marginTop: 2, display: 'none' }} />
              <CaretDownOutlined id={'arrowDownKonpoSagyo'} style={{ fontSize: 17, marginTop: 2 }} />
              <span className="kouteiCal-proc-title">作業金額/時間</span>
            </div>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="konpoTotalSagyoAmt">{sagyoAmtSum ? JPYs.format(Math.round(sagyoAmtSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="konpoTotalSagyoTime">{sagyoTimeSum ? secondsToHms(sagyoTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="konpoTotalSagyoAmtIn"
              className="resetColor"
              style={{ color: totalSagyoAmt === sagyoAmtSum ? 'black' : '#4cc12f' }}
            >
              {totalSagyoAmt ? JPYs.format(Math.round(totalSagyoAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="konpoTotalSagyoTimeIn"
              className="resetColor"
              style={{ color: totalSagyoTime === sagyoTimeSum ? 'black' : '#4cc12f' }}
            >
              {totalSagyoTime ? secondsToHms(totalSagyoTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={2} className="formula-column formula-column-right-border">
            <Popover content={helpCmt.workAmt_p2}>金額</Popover>
          </Col>
          <Col span={2} className="formula-column">
            <Popover content={helpCmt.workTm_p2}>時間</Popover>
          </Col>
        </Row>
        <div id={'divKonpoSagyo'} style={{ display: 'none' }}>
          {(() => {
            const subArr = [];
            for (let j = 0; j < editData?.length; j++) {
              <div>
                {(() => {
                  subArr.push(
                    <div>
                      <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                        <Col span={4} className="oyabuhin-detail-col" onClick={(e) => childDetail(j)}>
                          <div style={{ display: 'flex', overflow: 'hidden', marginLeft: '20px' }}>
                            <CaretUpOutlined
                              id={'arrowUpChildKonpo' + j}
                              style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                            />
                            <CaretDownOutlined id={'arrowDownChildKonpo' + j} style={{ fontSize: 17, marginTop: 2 }} />
                            <span className="kouteiCal-proc-title" title={orgData[j]?.processName}>
                              {orgData[j]?.processName}
                            </span>
                          </div>
                        </Col>
                        <Col span={3} className="oyabuhin-detail-col" onClick={(e) => childDetail(j)}>
                          <label id={j + 'cSagyoAmtKonpo'}>
                            {orgData[j]?.sagyoAmt ? JPYs.format(Math.round(orgData[j]?.sagyoAmt)) : amtEmptyStr}
                          </label>
                        </Col>
                        <Col span={3} className="oyabuhin-detail-col" onClick={(e) => childDetail(j)}>
                          <label id={'sagyoTime' + j}>
                            {orgData[j]?.sagyoTime ? secondsToHms(orgData[j]?.sagyoTime) : timeHmsStr}
                          </label>
                        </Col>
                        <Col span={4} className="input-col">
                          <CurrencyInput
                            id={j + 'cSagyoAmtKonpoIn'}
                            value={editData[j]?.sagyoAmt ? Math.round(editData[j]?.sagyoAmt) : 0}
                            defaultValue={0}
                            onValueChange={(e) => {
                              if (editData[j]?.sagyoAmt != e) {
                                settingNewDandoriAmtInArr(e, j, 'sagyoAmt', '', '');
                                checkInputWithColor(false, '', j + 'cSagyoAmtKonpo', e, 'amt');
                              }
                            }}
                            style={{
                              border: 'none',
                              color:
                                Math.round(orgData[j]?.sagyoAmt) === Math.round(editData[j]?.sagyoAmt)
                                  ? 'black'
                                  : checkShowHideIcon(j + 'cSagyoAmtKonpoCu') === 'none'
                                  ? '#4cc12f'
                                  : 'red',
                            }}
                            className={
                              props?.editMode
                                ? 'input-editable currency resetColor'
                                : 'input-non-editable currency resetColor'
                            }
                            prefix="¥"
                            decimalsLimit={100}
                          />
                          <div id={j + 'cSagyoAmtKonpoCu'} style={{ display: 'none' }} className="currencyReset">
                            <div className="currencyIcon">
                              <BsUsbC style={{ fontSize: '25' }} />
                              <BsX style={{ margin: '-8px 0 0 16px' }} />
                              <span class="tooltiptext">
                                金額に変更がありましたので、以降時間と連携して自動計算されない。
                              </span>
                            </div>
                          </div>
                        </Col>
                        <Col span={4} className="input-col">
                          <Row style={{ width: '100%' }}>
                            <Col className="timer-input-col">
                              <Input
                                value={editData[j]?.sagyoTime ? secondsToHms(editData[j]?.sagyoTime) : timeHmsStr}
                                id={'sagyoTime' + j + 'In'}
                                style={{
                                  width: '100%',
                                  color:
                                    orgData[j]?.sagyoTime === editData[j]?.sagyoTime
                                      ? handleShowHide(
                                          Math.round(orgData[j]?.sagyoAmt),
                                          Math.round(editData[j]?.sagyoAmt),
                                          j + 'cSagyoAmtKonpoCu'
                                        )
                                      : oldEditedData[j]?.processCount === editData[j]?.processCount &&
                                        oldEditedData[j]?.processTime === editData[j]?.processTime
                                      ? 'red'
                                      : '#4cc12f',
                                }}
                                className={
                                  props?.editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'
                                }
                                onChange={(e) => {
                                  //disableTimer('test');

                                  settingNewDandoriAmtInArr(e.target.value, j, 'sagyoTime', 'input', 'input');
                                }}
                              ></Input>
                            </Col>
                            <Col className="timer-button-col">
                              {props?.editMode ? (
                                <>
                                  <CaretUpOutlined
                                    style={{ fontSize: 17 + 'px' }}
                                    onClick={() => {
                                      let editedTime = handelIncreaseTime(
                                        editData[j]?.sagyoTime,
                                        '10min',
                                        'sagyoTime' + j
                                      );
                                      settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', '10min', 'inc');
                                    }}
                                  />
                                  <CaretDownOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelDecreaseTime(
                                        editData[j]?.sagyoTime,
                                        '10min',
                                        'sagyoTime' + j
                                      );
                                      settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', '10min', 'dec');
                                    }}
                                  />
                                  <CaretUpOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelIncreaseTime(
                                        editData[j]?.sagyoTime,
                                        'min',
                                        'sagyoTime' + j
                                      );
                                      settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'min', 'inc');
                                    }}
                                  />
                                  <CaretDownOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelDecreaseTime(
                                        editData[j]?.sagyoTime,
                                        'min',
                                        'sagyoTime' + j
                                      );
                                      settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'min', 'dec');
                                    }}
                                  />
                                  <CaretUpOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelIncreaseTime(
                                        editData[j]?.sagyoTime,
                                        'sec',
                                        'sagyoTime' + j
                                      );
                                      settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'sec', 'inc');
                                    }}
                                  />
                                  <CaretDownOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelDecreaseTime(
                                        editData[j]?.sagyoTime,
                                        'sec',
                                        'sagyoTime' + j
                                      );
                                      settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'sec', 'dec');
                                    }}
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                            </Col>
                          </Row>
                        </Col>
                        <Col span={1} className="oyabuhin-detail-col" onClick={(e) => childDetail(j)}></Col>
                        <Col span={5} className="formula-column" onClick={(e) => childDetail(j)}>
                          {/* <Popover content={contentSub}>{editData[j]?.formula}</Popover> */}
                        </Col>
                      </Row>
                      <div id={'childPartKonpo' + j} style={{ display: 'none' }}>
                        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                          <Col span={4} className="oyabuhin-detail-col">
                            <span className="ddl_2_noImg kouteiCal-proc-title" style={{ paddingLeft: '10px' }}>
                              {orgData[j]?.kihon ? '部品数' : '個数'}/1つ当たりの梱包時間
                            </span>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            <label id="processCount">{orgData[j]?.processCount}</label>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            <label id="processTime">
                              {orgData[j]?.processTime ? secondsToHms(orgData[j]?.processTime) : timeHmsStr}
                            </label>
                          </Col>
                          <Col span={4} className="input-col">
                            <Input
                              value={editData[j]?.processCount}
                              id="processCountIn"
                              min={0}
                              className={props?.editMode ? 'input-editable' : 'input-non-editable '}
                              controls={false}
                              style={{
                                width: '45%',
                                border: 'none',
                                color: orgData[j]?.processCount === editData[j]?.processCount ? 'black' : 'red',
                              }}
                              onKeyPress={(e) => {
                                handleKeyPress(e, true);
                              }}
                              onChange={(e) => {
                                settingNewDandoriAmtInArr(
                                  e.target.value ? parseInt(e.target.value) : 0,
                                  j,
                                  'processCount',
                                  '',
                                  ''
                                );
                              }}
                            />
                          </Col>
                          <Col span={4} className="input-col">
                            <Row style={{ width: '100%' }}>
                              <Col className="timer-input-col">
                                <Input
                                  value={editData[j]?.processTime ? secondsToHms(editData[j]?.processTime) : timeHmsStr}
                                  id={j + 'processTimeIn'}
                                  style={{
                                    width: '100%',
                                    color: orgData[j]?.processTime === editData[j]?.processTime ? 'black' : 'red',
                                  }}
                                  className={
                                    props?.editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'
                                  }
                                  onChange={(e) => {
                                    settingNewDandoriAmtInArr(e.target.value, j, 'processTime', 'input', 'input');
                                  }}
                                ></Input>
                              </Col>
                              <Col className="timer-button-col">
                                {props?.editMode ? (
                                  <>
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editData[j]?.processTime,
                                          '10min',
                                          j + 'processTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'processTime', '10min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editData[j]?.processTime,
                                          '10min',
                                          j + 'processTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'processTime', '10min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editData[j]?.processTime,
                                          'min',
                                          j + 'processTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'processTime', 'min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editData[j]?.processTime,
                                          'min',
                                          j + 'processTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'processTime', 'min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editData[j]?.processTime,
                                          'sec',
                                          j + 'processTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'processTime', 'sec', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editData[j]?.processTime,
                                          'sec',
                                          j + 'processTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'processTime', 'sec', 'dec');
                                      }}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Col>
                            </Row>
                          </Col>

                          <Col span={1} className="oyabuhin-detail-col"></Col>
                          <Col span={4} className="formula-column"></Col>
                        </Row>
                      </div>
                    </div>
                  );
                  return subArr;
                })()}
              </div>;
            }
            return subArr;
          })()}
        </div>
      </div>
    </div>
  );
});

export default ParentKouteiCalculate_Konpo;
