/**
 * クラス名：板金子部品見積詳細
 * 説明：板板金子部品見積詳細ファイルである。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import React, { useImperativeHandle, forwardRef, useEffect, useRef, useState } from 'react';
import { Input, Form, Row, Col, Select, Image, Space } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import {
  getMaterialNameById,
  getMaterialTypeById,
  getMaterialSurfaceById,
  getMaterialThickById,
  isDigit,
  handleKeyPress,
  JPYs,
  toDecimal,
  getDisplayMaterialSizeIq3,
  getMaterialAutoSize,
  getInitMaterialPatternsInfo,
  getInitMaterialSizeInfo,
  toCheckZenkaku,
  currentTime,
  currentDate,
  formatDateString,
  padWithLeadingZeros,
  isDigitZenkaku,
  regexExp,
} from '../common/Common.js';
import {
  getMaterialTypeIQ3Info,
  getCardDetailImg,
  getExistCompanySetting,
  updateUserSetting,
} from '../common/CommonAPI';
import { ErrorMessage } from '../common/Message.js';
import { error } from '../common/CommonModal.js';
import { amtEmptyStr } from '../common/Constant.js';
import edit_name from '../../assets/images/edit_name.png';

const IQ3EstimateDetail = forwardRef((props, ref) => {
  const location = useLocation();
  const [rowKey, setRowKey] = useState(0);
  const [partNo, setPartNo] = useState('');
  const [img, setImg] = useState('');
  const [drawingNo, setDrawingNo] = useState('');
  const [name, setName] = useState('');
  const [patternName, setPatternName] = useState('');
  const [partCount, setPartCount] = useState(1);
  const [materialTypeId, setMaterialTypeId] = useState(0);
  const [thickness, setThickness] = useState('');
  const [materialId, setMaterialId] = useState(0);
  const [materialSurfaceId, setMaterialSurfaceId] = useState([]);
  const [weight, setWeight] = useState('');
  const [surfaceArea, setSurfaceArea] = useState('');
  const [materialCost, setMaterialCost] = useState('');
  const [processCost, setProcessCost] = useState('');
  const [purchaseCost, setPurchaseCost] = useState('');
  const [purchaseTotalCost, setPurchaseTotalCost] = useState('');
  const [surfaceCost, setSurfaceCost] = useState('');
  const [surfaceTotalCost, setSurfaceTotalCost] = useState('');
  const [costPrice, setCostPrice] = useState(0);
  const [estimatedAmount, setEstimatedAmount] = useState('');
  const [totalCostPrice, setTotalCostPrice] = useState('');
  const [totalEstimatedAmount, setTotalEstimatedAmount] = useState('');
  const [costProfit, setCostProfit] = useState('');
  const [estimateProfit, setEstimateProfit] = useState('');
  const [profitRate, setProfitRate] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [detailData, setDetailData] = useState([]);
  const [totalMaterialCost, setTotalMaterialCost] = useState('');
  const [totalProcessCost, setTotalProcessCost] = useState('');

  const [currentRecordIndex, setCurrentRecordIndex] = useState(1);

  // 材料名称リスト
  let materialLst = props.materialLst?.filter((i) => i?.info?.isUsed);
  // 材質名称リスト
  let materialTypeLst = props.materialTypeLst?.filter((i) => i?.info?.isUsed);
  materialTypeLst = materialTypeLst?.sort((a, b) => a?.info?.sortNum - b?.info?.sortNum); // IQX_WEBEST-279　ソート番号の昇順ソート
  // 材料表面保護
  let materialSurfaceLst = props.materialSurfaceLst?.filter((i) => i?.info?.isUsed);

  const inputItemRef = {};

  useEffect(() => {
    if (props?.isReCal) {
      initEstimateDetailsInfo(props.selectedIQ3DataDetail);
    }
  }, [props.isReCal, props.activeTabKey, props.selectedIQ3DataDetail]);

  useEffect(() => {
    initEstimateDetailsInfo(props.selectedIQ3DataDetail);
    let curRowIndex = 1;
    let curRowNo = props?.currentRecordNo ? props?.currentRecordNo : 1;
    for (let i = 0; i < props.selectedEstimateData?.esIq3Info?.length; i++) {
      if (props.selectedEstimateData?.esIq3Info[i]?.no === curRowNo) {
        setCurrentRecordIndex(i);
        curRowIndex = i;
      }
    }
    // calculateChildPartCount();
  }, [
    props.selectedIQ3DataDetail,
    props.selectedEstimateData,
    props.currentRecordNo,
    props.activeTabKey,
    props.partMenuChange,
    props.selectedIQ3DataDetail?.additions,
    props.selectedIQ3DataDetail?.partCount,
  ]);

  const initEstimateDetailsInfo = (selectedIQ3DataDetail) => {
    const childPartDetail = selectedIQ3DataDetail;
    setDetailData({ ...childPartDetail });
    if (childPartDetail !== undefined) {
      // IQX_WEBEST-207
      if (childPartDetail.imageUrl === undefined) {
        setImg('');
      } else {
        setImg(childPartDetail.imageUrl);
      }
      setPartNo(childPartDetail.no);
      setDrawingNo(childPartDetail.drawingNo);
      setName(childPartDetail.name);
      setPatternName(childPartDetail.processSelect?.name);
      setPartCount(childPartDetail.partCount);
      setMaterialTypeId(childPartDetail.materialTypeIq3Id);
      setThickness(childPartDetail.thickness);
      setMaterialId(childPartDetail.materialIq3Id);
      setWeight(childPartDetail.weight);
      setSurfaceArea(childPartDetail.totalSurfaceArea);
      setMaterialCost(childPartDetail.materialCostUnitPrice);
      setTotalMaterialCost(childPartDetail.materialCostTotalPrice);
      setProcessCost(childPartDetail.workCostUnitPrice);
      setTotalProcessCost(childPartDetail.workCostTotalPrice);
      setPurchaseCost(childPartDetail.purchaseUnitPrice);
      setPurchaseTotalCost(childPartDetail.purchaseTotalPrice);
      setSurfaceCost(childPartDetail.surfaceUnitPrice);
      setSurfaceTotalCost(childPartDetail.surfaceTotalPrice);
      setCostPrice(childPartDetail.costUnitPrice);
      setEstimatedAmount(childPartDetail.costTotalPrice);
      setTotalCostPrice(childPartDetail.costTotalPrice);
      setTotalEstimatedAmount(childPartDetail.totalEstimatedAmount);
      setCostProfit(childPartDetail.costProfit);
      setEstimateProfit(childPartDetail.estimateProfit);
      setProfitRate(childPartDetail.profitRate);
      setMaterialSurfaceId(childPartDetail.materialSurfaceIq3Id);
    }
  };
  const calculateChildPartCount = () => {
    let childpart = 0;
    if (props?.selectedEstimateData?.esIq3Info?.length > 0) {
      childpart = props?.selectedEstimateData?.esIq3Info?.reduce((total, item) => {
        return total + parseInt(item.partCount);
      }, 0);
    }
    if (props?.selectedEstimateData?.estimateProducts?.processInput != undefined) {
      props.selectedEstimateData.estimateProducts.processInput.inspectionProcessInputInfo.smPartsCount = childpart;
      props.selectedEstimateData.estimateProducts.processInput.assenbleProcessInputInfo.smPartsCount = childpart;
      props.selectedEstimateData.estimateProducts.processInput.packingProcessInputInfo.smPartsCount = childpart;
      // IQX_WEBEST-179
      props.selectedEstimateData.estimateProducts.processInput.inspectionProcessInputInfo.autoExtract = childpart;
      props.selectedEstimateData.estimateProducts.processInput.assenbleProcessInputInfo.autoExtract = childpart;
      props.selectedEstimateData.estimateProducts.processInput.packingProcessInputInfo.autoExtract = childpart;
    }
  };
  // IQX_WEBEST-308 親工程 －表面処置、工程入力画面の「面積/重量」の値変更
  const calculateWeightAreaForPaSurface = () => {
    let totalWeight = 0;
    let totalArea = 0;
    totalWeight = props?.selectedEstimateData?.esIq3Info?.reduce((total, item) => {
      return total + parseFloat(item.weight * Number(item.partCount));
    }, 0);
    totalArea = props?.selectedEstimateData?.esIq3Info?.reduce((total, item) => {
      return total + parseFloat(item.totalSurfaceArea * Number(item.partCount));
    }, 0);
    if (totalWeight === 0 && totalArea === 0) {
    } else {
      if (props?.selectedEstimateData?.estimateProducts?.processInput?.surfaceProcessInputInfo?.length > 0) {
        let parentSurfaceData =
          props?.selectedEstimateData?.estimateProducts?.processInput?.surfaceProcessInputInfo?.map((paSurface) => {
            return {
              ...paSurface,
              areaUnitPrice: parseFloat(toDecimal(totalArea, 2)),
              weightUnitPrice: parseFloat(toDecimal(totalWeight, 2)),

              areaAutoExtract: parseFloat(toDecimal(totalArea, 2)),
              weightAutoExtract: parseFloat(toDecimal(totalWeight, 2)),
              totalArea: parseFloat(toDecimal(totalArea * paSurface?.areaCount, 2)),
              totalWeight: parseFloat(toDecimal(totalWeight * paSurface?.weightCount, 2)),
            };
          });
        props.selectedEstimateData.estimateProducts.processInput.surfaceProcessInputInfo = parentSurfaceData;
      }
    }
  };
  // 値を変更した場合、
  const onUpdateDetail = (name, value) => {
    detailData[name] = value;
    props.selectedEstimateData.esIq3Info[currentRecordIndex].partCount = detailData['partCount'];
    props.selectedEstimateData.esIq3Info[currentRecordIndex].name = detailData['name'];
    props.selectedEstimateData.esIq3Info[currentRecordIndex].drawingNo = detailData['drawingNo'];

    props.selectedEstimateData.esIq3Info[currentRecordIndex].materialTypeIq3Id = detailData['materialTypeIq3Id'];
    props.selectedEstimateData.esIq3Info[currentRecordIndex].materialTypeName = detailData['materialTypeName'];
    props.selectedEstimateData.esIq3Info[currentRecordIndex].thickness = detailData['thickness'];
    props.selectedEstimateData.esIq3Info[currentRecordIndex].materialIq3Id = detailData['materialIq3Id'];
    props.selectedEstimateData.esIq3Info[currentRecordIndex].materialName = detailData['materialName'];
    props.selectedEstimateData.esIq3Info[currentRecordIndex].materialSurfaceIq3Id = detailData['materialSurfaceIq3Id'];
    props.selectedEstimateData.esIq3Info[currentRecordIndex].materialSurfaceName = detailData['materialSurfaceName'];

    // IQX_WEBEST-315 板厚を変更しても重量が更新されない
    let gravityVal = 0;
    let thicknessVal = 0;
    let xsize = props.selectedEstimateData?.esIq3Info[currentRecordIndex]?.partSizeX
      ? toDecimal(props.selectedEstimateData?.esIq3Info[currentRecordIndex]?.partSizeX, 2)
      : 0;
    let ysize = props.selectedEstimateData?.esIq3Info[currentRecordIndex]?.partSizeY
      ? toDecimal(props.selectedEstimateData?.esIq3Info[currentRecordIndex]?.partSizeY, 2)
      : 0;
    const thickId = props.selectedEstimateData?.esIq3Info[currentRecordIndex]?.materialIq3Id;
    const thicknessInfo = props.materialLst?.find((item) => item.id === thickId);

    if (thicknessInfo != undefined) {
      thicknessVal = thicknessInfo?.info?.thick;
      gravityVal = thicknessInfo?.info?.gravity;
    }
    let weightVal = ((thicknessVal * parseInt(xsize) * parseInt(ysize)) / 1000000) * gravityVal;
    let areaVal = (toDecimal(xsize, 2) * toDecimal(ysize, 2)) / 100;
    if (props.selectedEstimateData?.esIq3Info[currentRecordIndex]) {
      props.selectedEstimateData.esIq3Info[currentRecordIndex].weight = weightVal;
      props.selectedEstimateData.esIq3Info[currentRecordIndex].totalSurfaceArea = areaVal;
    }

    calculateChildPartCount();
    calculateWeightAreaForPaSurface(); // IQX_WEBEST-308 親工程 －表面処置、工程入力画面の「面積/重量」の値変更
  };

  // 材質を変更した時、材料、板厚、表面保護の情報をフィルターする
  const filteredInfoByMaterialType = async (materialTypeId) => {
    // 材料情報
    let materialList = materialLst?.filter((item) => item.info.isUsed && item.materialTypeIq3Id === materialTypeId);
    let materialId = 0;
    let materialSurfaceId = 0;
    if (materialList?.length > 0) {
      materialId = materialList[0]?.id;
    }
    setThickness(materialId); // 板厚
    setMaterialId(materialId);
    detailData['thickness'] = materialId;
    detailData['materialIq3Id'] = materialId;
    detailData['materialName'] = getMaterialNameById(materialId, materialLst);
    // 材料表面情報
    let materialSurfaceList = materialSurfaceLst?.filter(
      (item) => item.info.isUsed && item.materialTypeIq3Id === materialTypeId
    );
    if (materialSurfaceList?.length > 0) {
      materialSurfaceId = materialSurfaceList[0]?.id;
    }
    setMaterialSurfaceId(materialSurfaceId);
    detailData['materialSurfaceIq3Id'] = materialSurfaceId;
    detailData['materialSurfaceName'] = getMaterialSurfaceById(materialSurfaceId, materialSurfaceLst);
    detailData['materialDetailSizes'] = getMSizeListByMTypeIq3Id(materialTypeId);
    detailData['materialDetailPatterns'] = getMPatternListByMTypeIq3Id(materialTypeId, detailData);
    // props.selectedEstimateData.esIq3Info[currentRecordIndex] = detailData;
    props.selectedEstimateData.esIq3Info[currentRecordIndex].thickness = detailData['thickness'];
    props.selectedEstimateData.esIq3Info[currentRecordIndex].materialIq3Id = detailData['materialIq3Id'];
    props.selectedEstimateData.esIq3Info[currentRecordIndex].materialName = detailData['materialName'];
    props.selectedEstimateData.esIq3Info[currentRecordIndex].materialSurfaceIq3Id = detailData['materialSurfaceIq3Id'];
    props.selectedEstimateData.esIq3Info[currentRecordIndex].materialSurfaceName = detailData['materialSurfaceName'];
    props.selectedEstimateData.esIq3Info[currentRecordIndex].materialDetailSizes = detailData['materialDetailSizes'];
    props.selectedEstimateData.esIq3Info[currentRecordIndex].materialDetailPatterns =
      detailData['materialDetailPatterns'];
    let userSetting = props.selectedEstimateData.estimateProducts.calcParameters.settings.userSettings;
    if (userSetting?.length > 0) userSetting = userSetting[0];
    if (userSetting !== null) {
      if (userSetting.users?.newSmInitialInfo?.materialIq3Id !== undefined) {
        userSetting.users.newSmInitialInfo.materialIq3Id = materialId;
        await updateUserSetting(userSetting, 0);
      }
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      getIQ3EstimateDetailInfo: () => {
        return detailData;
      },
      getIQ3EstimateInfo: () => {
        return parentEstimateInfo;
      },
      formValidation: formValidation,
    }),
    [detailData]
  );

  // 画面の入力チェック
  const formValidation = () => {
    let ret = true;
    let message = [];
    let input;
    // 員数
    input = inputItemRef['partCount']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length <= 0) {
      // 必須
      message.push(<li>{ErrorMessage('iQ3板金・基本情報の員数').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }
    if (input?.value?.length > 0 && !isDigit(input?.value)) {
      message.push(<li>{ErrorMessage('iQ3板金・基本情報の員数').E009}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 材質
    input = document.getElementsByName('materialTypeId')?.[0];
    input?.childNodes?.[0]?.classList?.remove('input-error');
    if (input?.innerText?.length <= 0) {
      // 必須
      message.push(<li>{ErrorMessage('iQ3板金・基本情報の材質').E017}</li>);
      input?.childNodes?.[0]?.classList.add('input-error');
      ret = false;
    }

    // 材料名称
    input = document.getElementsByName('materialId')?.[0];
    input?.childNodes?.[0]?.classList?.remove('input-error');
    if (input?.innerText?.length <= 0) {
      // 必須
      message.push(<li>{ErrorMessage('iQ3板金・基本情報の材料名称').E017}</li>);
      input?.childNodes?.[0]?.classList.add('input-error');
      ret = false;
    }

    // 板厚
    input = document.getElementsByName('thickness')?.[0];
    input?.childNodes?.[0]?.classList?.remove('input-error');
    if (input?.innerText?.length <= 0) {
      // 必須
      message.push(<li>{ErrorMessage('iQ3板金・基本情報の板厚').E017}</li>);
      input?.childNodes?.[0]?.classList.add('input-error');
      ret = false;
    }

    // 表面保護
    // input = document.getElementsByName('materialSurface')?.[0];
    // input?.childNodes?.[0]?.classList?.remove('input-error');
    // if (input?.innerText?.length <= 0) {
    //   // 必須
    //   message.push(<li>{ErrorMessage('iQ3板金・基本情報の表面保護').E017}</li>);
    //   input?.childNodes?.[0]?.classList.add('input-error');
    //   ret = false;
    // } // IQX_WEBEST-248 表面保護は空欄でも保存できるように変更してほしい

    if (message?.length > 0) error(<ul>{message}</ul>);
    return ret;
  };

  const filterOption = (input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;

  // 変更した材質名称によって表示する材料サイズリストを得る
  const getMSizeListByMTypeIq3Id = (materialTypeIq3Id) => {
    let materialDetailSizes = [];
    let filteredMaterialSizeIq3 = getFilteredMaterialSizeIq3(materialTypeIq3Id);
    materialDetailSizes = filteredMaterialSizeIq3?.map((item, index) => {
      if (item?.sizeName === 'Other') {
        item = { ...item, isUsed: true };
      }
      return getInitMaterialSizeInfo(index, item);
    });
    return materialDetailSizes;
  };

  // 変更した材質名称によって表示する材料パターンリストを得る
  const getMPatternListByMTypeIq3Id = (materialTypeIq3Id, detailData) => {
    let materialDetailPatterns = [];
    let filteredMaterialSizeIq3 = getFilteredMaterialSizeIq3(materialTypeIq3Id);
    filteredMaterialSizeIq3?.map((item, index) => {
      let copyItem = JSON.parse(JSON.stringify(item));
      if (item?.sizeName === 'Other') {
        let iq3Info = detailData;
        // 材料サイズX計算
        let sheetX = getMaterialAutoSize(iq3Info, false);
        // 材料サイズY計算
        let sheetY = getMaterialAutoSize(iq3Info, false, false);
        copyItem = { ...copyItem, info: { ...copyItem.info, sizeX: sheetX, sizeY: sheetY } };
      }
      materialDetailPatterns = getInitMaterialPatternsInfo(materialDetailPatterns, copyItem);
    });
    return materialDetailPatterns;
  };

  // 変更した材質名称によって表示する材料サイズマスタ情報を得る
  const getFilteredMaterialSizeIq3 = (materialTypeIq3Id) => {
    let parameters = props.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
    let materialSizeIq3Infos = parameters?.materialSizeIq3;
    let filteredMaterialSizeIq3 = getDisplayMaterialSizeIq3(materialTypeIq3Id, materialSizeIq3Infos);
    return filteredMaterialSizeIq3;
  };

  // IQX_WEBEST-241 会社設定において見積データ、子部品データ追加時の自動入力される名称の変更する機能
  const getSelectName = (defaultInfo) => {
    let defaultDrawingNo = '';
    if (defaultInfo === undefined || defaultInfo === '') {
      defaultDrawingNo += '';
    } else if (defaultInfo === 1) {
      // 年月日
      defaultDrawingNo += formatDateString(currentDate());
    } else if (defaultInfo === 2) {
      // 時分秒
      defaultDrawingNo += formatDateString(currentTime());
    } else if (defaultInfo === 3) {
      // 材料名
      let materialTypeIq3 = materialLst?.filter(
        (item) => item.info.isUsed && item.materialTypeIq3Id === materialTypeId
      );
      let materialType = materialTypeIq3?.filter((item) => item.info.isUsed && item.id === materialId);
      defaultDrawingNo += materialType?.length > 0 ? materialType[0]?.name : '';
    } else if (defaultInfo === 4) {
      // 材質名
      let materialIq3 = materialTypeLst?.filter(
        (item) => materialLst?.findIndex((mItem) => mItem.materialTypeIq3Id === item.id) > -1
      );
      let materialType = materialIq3?.filter((item) => item.info.isUsed && item.id === materialTypeId);
      defaultDrawingNo += materialType?.length > 0 ? materialType[0]?.name : '';
    } else if (defaultInfo === 5) {
      // 表面保護
      let materialSurfaceIq3 = materialSurfaceLst?.filter(
        (item) => item.info.isUsed && item.materialTypeIq3Id === materialTypeId
      );
      defaultDrawingNo += materialSurfaceIq3?.length > 0 ? materialSurfaceIq3[0]?.name : '';
    } else if (defaultInfo === 6) {
      // 板厚
      let materialIq3 = materialLst?.filter((item) => item.info.isUsed && item.materialTypeIq3Id === materialTypeId);
      materialIq3 = materialIq3?.filter((item) => item.id === thickness);
      defaultDrawingNo += materialIq3?.length > 0 ? materialIq3[0]?.info?.thick : '';
    } else if (defaultInfo === 7) {
      // 材料サイズX
      let materialSize = detailData?.materialDetailSizes?.filter((item) => item.isUsed);
      let materialPattern = detailData?.materialDetailPatterns?.filter(
        (item) => item.patternType === 1 && item.materialSizeIq3Id === materialSize?.[0]?.materialSizeIq3Id
      );
      defaultDrawingNo += materialPattern?.length > 0 ? materialPattern?.[0]?.sizeX : 0;
    } else if (defaultInfo === 8) {
      // 材料サイズY
      let materialSize = detailData?.materialDetailSizes?.filter((item) => item.isUsed);
      let materialPattern = detailData?.materialDetailPatterns?.filter(
        (item) => item.patternType === 1 && item.materialSizeIq3Id === materialSize?.[0]?.materialSizeIq3Id
      );
      defaultDrawingNo += materialPattern?.length > 0 ? materialPattern?.[0]?.sizeY : 0;
    } else if (defaultInfo === 9) {
      // 部品サイズX
      defaultDrawingNo += detailData?.partSizeX ? detailData?.partSizeX : 0;
    } else if (defaultInfo === 10) {
      // 部品サイズY
      defaultDrawingNo += detailData?.partSizeY ? detailData?.partSizeY : 0;
    } else if (defaultInfo === 11) {
      // 親部品図番
      defaultDrawingNo += props.selectedEstimateData?.estimateProducts
        ? props.selectedEstimateData?.estimateProducts?.drawingNo
        : '';
    } else if (defaultInfo === 12) {
      // 親部品名称
      defaultDrawingNo += props.selectedEstimateData?.estimateProducts
        ? props.selectedEstimateData?.estimateProducts?.name
        : '';
    } else if (defaultInfo === 13) {
      // 0000*
      let defaultSerialNo = padWithLeadingZeros(detailData?.no, 5);
      defaultDrawingNo += defaultSerialNo ? defaultSerialNo : '';
    }
    return defaultDrawingNo;
  };

  // IQX_WEBEST-241 会社設定において見積データ、子部品データ追加時の自動入力される名称の変更する機能
  const setToDefaultName = async (type) => {
    let defaultDrawingNoInfo = '';
    let companySetting = await getExistCompanySetting();
    if (companySetting !== undefined && companySetting?.length > 0) {
      defaultDrawingNoInfo = companySetting?.[0]?.initNames?.childInitNameInfo[0];
      if (type === 'name') {
        defaultDrawingNoInfo = companySetting?.[0]?.initNames?.childInitNameInfo[1];
      }
      // デフォルト値１
      let defaultName =
        defaultDrawingNoInfo?.input1 === undefined || defaultDrawingNoInfo?.input1 === ''
          ? ''
          : defaultDrawingNoInfo?.input1;
      // デフォルト値２
      defaultName +=
        defaultDrawingNoInfo?.input2 === undefined || defaultDrawingNoInfo?.input2 === ''
          ? ''
          : defaultDrawingNoInfo?.input2;
      // デフォルト値３
      defaultName += getSelectName(defaultDrawingNoInfo?.select1);
      // デフォルト値４
      defaultName +=
        defaultDrawingNoInfo?.input3 === undefined || defaultDrawingNoInfo?.input3 === ''
          ? ''
          : defaultDrawingNoInfo?.input3;
      // デフォルト値５
      defaultName += getSelectName(defaultDrawingNoInfo?.select2);
      // デフォルト値６
      defaultName +=
        defaultDrawingNoInfo?.input4 === undefined || defaultDrawingNoInfo?.input4 === ''
          ? ''
          : defaultDrawingNoInfo?.input4;
      // デフォルト値７
      defaultName += getSelectName(defaultDrawingNoInfo?.select3);
      defaultName = defaultName ? defaultName : '';
      if (type === 'drawingNo') {
        setDrawingNo(defaultName);
      } else if (type === 'name') {
        setName(defaultName);
      }
      onUpdateDetail(type, defaultName);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', float: 'left', width: '100%', paddingTop: 5 }}>
        {/* 部品プロパティアリア */}
        <div style={{ width: '50%' }}>
          {' '}
          <Table className="propertiesTbl">
            <thead>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">No.</label>
                </td>
                <td className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                    className="input-non-editable "
                    name="partNo"
                    id="partNo"
                    value={partNo}
                    type="text"
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <Space size="large">
                    <label className="detail-tbl-val-title-label">子部品図番</label>
                    <a
                      onClick={async () => {
                        setToDefaultName('drawingNo'); // IQX_WEBEST-241 会社設定において見積データ、子部品データ追加時の自動入力される名称の変更する機能
                      }}
                    >
                      <Image preview={false} width={20} height={20} src={edit_name} style={{ marginBottom: '5px' }} />
                    </a>
                  </Space>
                </td>
                <td className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                    // className={editMode ? 'input-editable' : 'input-non-editable '}
                    className="input-editable"
                    name="drawingNo"
                    value={drawingNo}
                    // onChange={(e) => setImgNo(e.target.value)}
                    onChange={(e) => {
                      setDrawingNo(e.target.value);
                      onUpdateDetail(e.target.name, e.target.value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <Space size="large">
                    <label className="detail-tbl-val-title-label">子部品名称</label>
                    <a
                      onClick={async () => {
                        setToDefaultName('name'); // IQX_WEBEST-241 会社設定において見積データ、子部品データ追加時の自動入力される名称の変更する機能
                      }}
                    >
                      <Image preview={false} width={20} height={20} src={edit_name} style={{ marginBottom: '5px' }} />
                    </a>
                  </Space>
                </td>
                <td className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-editable"
                    name="name"
                    value={name}
                    // onChange={(e) => setPartNm(e.target.value)}
                    onChange={(e) => {
                      setName(e.target.value);
                      onUpdateDetail(e.target.name, e.target.value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">工程パターン</label>
                </td>

                <td className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                    className={'input-non-editable '}
                    name="patternName"
                    id="patternName"
                    type="text"
                    value={patternName}
                    // onChange={(e) => {
                    //   setProcessNm(e.target.value);
                    //
                    //   onUpdateDetail(e.target.name, e.target.value);
                    // }}
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">員数</label>
                </td>
                <td className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '35.5%' }}
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-editable"
                    name="partCount"
                    value={partCount}
                    onKeyPress={(e) => {
                      handleKeyPress(e, true);
                    }}
                    ref={(ref) => {
                      if (ref) inputItemRef['partCount'] = ref;
                    }}
                    onBlur={(e) => {
                      if (regexExp.test(e.target.value) && !isDigitZenkaku(e.target.value)) return;
                      let value = toCheckZenkaku(e.target.value);
                      setPartCount(value);
                      onUpdateDetail(e.target.name, value);
                    }}
                    onPressEnter={(e) => {
                      if (regexExp.test(e.target.value) && !isDigitZenkaku(e.target.value)) return;
                      let value = toCheckZenkaku(e.target.value);
                      setPartCount(value);
                      onUpdateDetail(e.target.name, value);
                    }}
                    onChange={(e) => {
                      if (regexExp.test(e.target.value) && !isDigitZenkaku(e.target.value)) return;
                      setPartCount(e.target.value);
                      onUpdateDetail(e.target.name, e.target.value);
                      // props.selectedEstimateData.esIq3Info[currentRecordIndex].partCount = e.target.value;
                    }}
                    // disabled
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">材質</label>
                </td>
                <td className="detail-tbl-val">
                  <Select
                    showSearch
                    className="iq3-detail-select"
                    name="materialTypeId"
                    value={materialTypeId ? materialTypeId : ''}
                    onChange={async (e) => {
                      setMaterialTypeId(e);
                      detailData['materialTypeName'] = getMaterialTypeById(e, materialTypeLst);
                      onUpdateDetail('materialTypeIq3Id', e);
                      filteredInfoByMaterialType(e);
                    }}
                    filterOption={filterOption}
                    style={{ marginLeft: 5, width: '98.2%' }}
                  >
                    {materialTypeLst
                      ?.filter((item) => materialLst?.findIndex((mItem) => mItem.materialTypeIq3Id === item.id) > -1)
                      ?.map((option, index) => (
                        <Select.Option key={index} value={option.id}>
                          {option.name}
                        </Select.Option>
                      ))}
                  </Select>
                </td>
              </tr>
              {/** IQX_WEBEST-113 表示する順番を変更 */}
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">板厚</label>
                </td>
                <td className="detail-tbl-val">
                  {/* {editMode ? ( */}
                  <Select
                    name="thickness"
                    className="iq3-detail-select"
                    value={thickness ? thickness : ''}
                    onChange={async (e) => {
                      setThickness(e);
                      onUpdateDetail('thickness', e);
                      setMaterialId(e);
                      detailData['materialName'] = getMaterialNameById(e, materialLst);
                      onUpdateDetail('materialIq3Id', e);
                      let userSetting =
                        props.selectedEstimateData.estimateProducts.calcParameters.settings.userSettings;
                      if (userSetting?.length > 0) userSetting = userSetting[0];
                      if (userSetting !== null) {
                        if (userSetting.users?.newSmInitialInfo?.materialIq3Id !== undefined) {
                          userSetting.users.newSmInitialInfo.materialIq3Id = e;
                          await updateUserSetting(userSetting, 0);
                        }
                      }
                    }}
                    style={{ marginLeft: 5, width: '35.5%' }}
                  >
                    {materialLst
                      ?.filter((item) => item.info.isUsed && item.materialTypeIq3Id === materialTypeId)
                      ?.map((option, index) => (
                        <Select.Option key={index} value={option.id}>
                          {option.info.thick}
                        </Select.Option>
                      ))}
                  </Select>
                  {/* ) : (
                    <Input
                      style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                      className={'input-non-editable '}
                      type="text"
                      value={getMaterialThickById(thickness, materialNmLst)}
                    />
                  )} */}
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">材料名称</label>
                </td>
                <td className="detail-tbl-val">
                  {/* {editMode ? ( */}
                  <Select
                    className="iq3-detail-select"
                    name="materialId"
                    value={materialId ? materialId : ''}
                    onChange={async (e) => {
                      setMaterialId(e);
                      detailData['materialName'] = getMaterialNameById(e, materialLst);
                      onUpdateDetail('materialIq3Id', e);
                      setThickness(e);
                      onUpdateDetail('thickness', e);
                      let userSetting =
                        props.selectedEstimateData.estimateProducts.calcParameters.settings.userSettings;
                      if (userSetting?.length > 0) userSetting = userSetting[0];
                      if (userSetting !== null) {
                        if (userSetting.users?.newSmInitialInfo?.materialIq3Id !== undefined) {
                          userSetting.users.newSmInitialInfo.materialIq3Id = e;
                          await updateUserSetting(userSetting, 0);
                        }
                      }
                    }}
                    style={{ marginLeft: 5, width: '98.2%' }}
                  >
                    {materialLst
                      ?.filter((item) => item.info.isUsed && item.materialTypeIq3Id === materialTypeId)
                      ?.map((option, index) => (
                        <Select.Option key={index} value={option.id}>
                          {option.name}
                        </Select.Option>
                      ))}
                  </Select>
                  {/* ) : (
                    <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                      {getMaterialNameById(materialNm, materialNmLst)}
                    </label>
                  )} */}
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">表面保護</label>
                </td>
                <td className="detail-tbl-val">
                  {/* {editMode ? ( */}
                  <Select
                    name="materialSurface"
                    className="iq3-detail-select"
                    value={materialSurfaceId ? materialSurfaceId : ''}
                    onChange={async (e) => {
                      setMaterialSurfaceId(e);
                      detailData['materialSurfaceName'] = getMaterialSurfaceById(e, materialSurfaceLst);
                      onUpdateDetail('materialSurfaceIq3Id', e);
                      let userSetting =
                        props.selectedEstimateData.estimateProducts.calcParameters.settings.userSettings;
                      if (userSetting?.length > 0) userSetting = userSetting[0];
                      if (userSetting !== null) {
                        if (userSetting.users?.newSmInitialInfo?.materialSurfaceIq3Id !== undefined) {
                          userSetting.users.newSmInitialInfo.materialSurfaceIq3Id = e;
                          await updateUserSetting(userSetting, 0);
                        }
                      }
                    }}
                    style={{ marginLeft: 5, width: '35.5%' }}
                  >
                    {materialSurfaceLst
                      ?.filter((item) => item.info.isUsed && item.materialTypeIq3Id === materialTypeId)
                      ?.map((option, index) => (
                        <Select.Option key={index} value={option.id}>
                          {option.name}
                        </Select.Option>
                      ))}
                  </Select>
                  {/* ) : (
                    <Input
                      style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                      className={'input-non-editable '}
                      type="text"
                      value={getMaterialSurfaceById(materialSurface, materialSurfaceLst)}
                    />
                  )} */}
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">重量(kg)</label>
                </td>
                <td className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                    className={'input-non-editable '}
                    type="text"
                    // value={weight ? Math.round(weight).toFixed(2) : 0}
                    value={weight ? toDecimal(weight, 2) : toDecimal(0, 2)}
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">表面積(㎠)</label>
                </td>
                <td className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                    className={'input-non-editable '}
                    type="text"
                    // value={surfaceArea ? Math.round(surfaceArea).toFixed(2) : 0}
                    value={surfaceArea ? toDecimal(surfaceArea, 2) : toDecimal(0, 2)}
                  />
                </td>
              </tr>
            </thead>
          </Table>
        </div>
        {/* 原価 */}
        <div style={{ marginLeft: '10px', width: '30%' }}>
          {/** 原価*/}
          <Row style={{ height: '45px', paddingTop: '20px' }}>
            <Col span={24}>【原価】</Col>
          </Row>
          <Row
            style={{
              backgroundColor: '#1063aa',
              color: 'white',
              height: '45px',
              paddingTop: '3px',
              textAlign: 'center',
              paddingTop: '10px',
            }}
          >
            <Col span={8}>
              <p style={{ margin: 0, fontSize: '13px' }}></p>
            </Col>
            <Col span={8}>
              <p style={{ margin: 0, fontSize: '13px' }}>単品</p>
            </Col>
            <Col span={8}>
              <p style={{ margin: 0, fontSize: '13px' }}>合計</p>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '5px',
              height: '45px',
              paddingTop: '10px',
              border: 'solid',
              borderWidth: 1,
              borderColor: '#a5a5a5',
            }}
          >
            <Col span={6}>
              <p style={{ margin: 0, fontSize: '13px' }}>原価</p>
            </Col>
            <Col span={9}>
              <p style={{ margin: 0, fontSize: '13px', textAlign: 'right', paddingRight: '40px' }}>
                {costPrice ? JPYs.format(Math.round(costPrice)) : amtEmptyStr}
              </p>
            </Col>
            <Col span={9}>
              <p style={{ margin: 0, fontSize: '13px', textAlign: 'right', paddingRight: '10px' }}>
                {totalCostPrice ? JPYs.format(Math.round(totalCostPrice)) : amtEmptyStr}
              </p>
            </Col>
          </Row>

          {/** 原価詳細*/}
          <Row style={{ height: '45px', paddingTop: '20px' }}>
            <Col span={24}>【原価内訳】</Col>
          </Row>
          <Row
            style={{
              backgroundColor: '#1063aa',
              color: 'white',
              height: '45px',
              paddingTop: '3px',
              textAlign: 'center',
              paddingTop: '10px',
            }}
          >
            <Col span={8}>
              <p style={{ margin: 0, fontSize: '13px' }}></p>
            </Col>
            <Col span={8}>
              <p style={{ margin: 0, fontSize: '13px' }}>単品</p>
            </Col>
            <Col span={8}>
              <p style={{ margin: 0, fontSize: '13px' }}>合計</p>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '5px',
              height: '45px',
              /* paddingTop: '10px', */
              border: 'solid',
              borderWidth: 0.5,
              borderColor: '#a5a5a5',
              borderBottom: 0,
              borderBottomWidth: 0,
              borderBottomColor: 'none',
              verticalAlign: 'middle',
              display: 'flex',
              flexWrap: 'wrap',
              alignContent: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Col span={6}>
              <p style={{ margin: 0, fontSize: '13px' }}>材料原価</p>
            </Col>
            <Col span={9}>
              <p style={{ margin: 0, fontSize: '13px', textAlign: 'right', paddingRight: '40px' }}>
                {materialCost ? JPYs.format(Math.round(materialCost)) : amtEmptyStr}
              </p>
            </Col>
            <Col span={9}>
              <p style={{ margin: 0, fontSize: '13px', textAlign: 'right', paddingRight: '10px' }}>
                {totalMaterialCost ? JPYs.format(Math.round(totalMaterialCost)) : amtEmptyStr}
              </p>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '5px',
              height: '45px',
              /*  paddingTop: '10px', */
              border: 'solid',
              borderWidth: 0.5,
              borderColor: '#a5a5a5',
              borderBottom: 0,
              borderBottomWidth: 0,
              borderBottomColor: 'none',
            }}
          >
            <Col span={6}>
              <p style={{ margin: 0, fontSize: '13px', textAlign: 'center', width: 'fit-content' }}>
                製造原価
                <br />
                (表面処理費)
              </p>
            </Col>
            <Col span={9}>
              <p
                style={{
                  margin: 0,
                  fontSize: '13px',
                  textAlign: 'right',
                  paddingRight: '40px',
                  textAlign: 'center',
                  width: 'fit-content',
                  float: 'right',
                }}
              >
                {processCost ? JPYs.format(Math.round(processCost)) : amtEmptyStr}
                <br />
                {surfaceCost ? JPYs.format(Math.round(surfaceCost)) : amtEmptyStr}
              </p>
            </Col>
            <Col span={9}>
              <p
                style={{
                  margin: 0,
                  fontSize: '13px',
                  textAlign: 'right',
                  paddingRight: '10px',
                  textAlign: 'center',
                  width: 'fit-content',
                  float: 'right',
                }}
              >
                {totalProcessCost ? JPYs.format(Math.round(totalProcessCost)) : amtEmptyStr} <br />
                {surfaceTotalCost ? JPYs.format(Math.round(surfaceTotalCost)) : amtEmptyStr}
              </p>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '5px',
              height: '45px',
              paddingTop: '10px',
              border: 'solid',
              borderWidth: 0.5,
              borderColor: '#a5a5a5',
            }}
          >
            <Col span={6}>
              <p style={{ margin: 0, fontSize: '13px' }}>購入品費</p>
            </Col>
            <Col span={9}>
              <p
                style={{
                  margin: 0,
                  fontSize: '13px',
                  textAlign: 'right',
                  paddingRight: '40px',
                  textAlign: 'center',
                  width: 'fit-content',
                  float: 'right',
                }}
              >
                {purchaseCost ? JPYs.format(Math.round(purchaseCost)) : amtEmptyStr}
              </p>
            </Col>
            <Col span={9}>
              <p
                style={{
                  margin: 0,
                  fontSize: '13px',
                  textAlign: 'right',
                  paddingRight: '10px',
                  textAlign: 'center',
                  width: 'fit-content',
                  float: 'right',
                }}
              >
                {purchaseTotalCost ? JPYs.format(Math.round(purchaseTotalCost)) : amtEmptyStr}
              </p>
            </Col>
          </Row>
        </div>
        {/* イメージアリア */}
        <div
          style={{
            width: '20%',
            marginLeft: '10px',
            marginTop: '23px',
          }}
        >
          <Row>
            <Col span={24}>【イメージ】</Col>
          </Row>
          <div className="detailImage">{<img src={img} alt="" width={270} />}</div>
        </div>
      </div>
    </>
  );
});

export default IQ3EstimateDetail;
