/**
 * クラス名：ユーザー設定画面
 * 作成者：チュー
 * 作成日：2023/07/21
 * バージョン：1.0
 */
import { Input, Layout, Menu, Row, Col, Select, Button, Typography, Checkbox, Space, Modal } from 'antd';
import React, { Component, useState, useRef, forwardRef, useEffect, useImperativeHandle } from 'react';
import dayjs from 'dayjs';

// import moment from 'moment-timezone';
import CompanySetting from './CompanySetting';
import { getProcessPatternInfo, getStaffInfo } from '../common/CommonAPI';
import {
  compareCompanySettings,
  compareUserSettings,
  getAccessToken,
  getInitParamUpdateFlgInfos,
  updateAccessToken,
} from '../common/Common';
import { ErrorMessage } from '../common/Message';
import {
  authorizeError,
  error,
  confirmModalTitle,
  commonModal,
  commonFooter,
  confirmUpdateContent,
  complete,
  confirmScreenChangeWarning,
} from '../common/CommonModal';

import { StatusCodes } from 'http-status-codes';
import { initShowDisps, languages, ServiceClass, UserRoleToString } from '../common/enums';
const { Sider, Content } = Layout;
const contentStyle = {
  backgroundColor: 'white',
  fontSize: '13px',
};

const siderStyle = {
  color: '#fff',
  backgroundColor: '#e5e5e5',
  width: '350px',
};
const userSetting = ['ユーザー情報', '言語とタイムゾーン', 'メッセージ表示', 'CURT.パラ更新'];
const adminSetting = ['言語とタイムゾーン', '環境', '初期名称', '出力フォーマット', '帳票' /* , 'CSV出力' */];

const timeZone = [
  { id: 1, value: '会社の設定を使用：(GMT+0:900) Asia/Tokyo', label: '会社の設定を使用：(GMT+0:900) Asia/Tokyo' },
];
const displayFlag = [
  { id: 1, value: true, label: '表示する' },
  { id: 2, value: false, label: '表示しない' },
];

const UserSetting = forwardRef((props, ref) => {
  const [selectedItem, setSelectedItem] = useState('0');
  const [selectedAdminItem, setSelectedAdminItem] = useState();
  const [userRole, setUserRole] = useState('user');
  /*const [userTimeZone, setUserTimeZone] = useState('会社の設定を使用：(GMT+0:900) Asia/Tokyo'); */
  const [personInChargeData, setPersonInChargeData] = useState([]);
  const [userSettingObj, setUserSettingObj] = useState([]);
  const [userSettingBefore, setUserSettingBefore] = useState([]);
  const [isExist, setIsExist] = useState(false);
  const [saveConfirm, setSaveConfirm] = useState(false);
  const [manager, setManager] = useState('');
  const [screen, setScreen] = useState('');
  const [userLanguage, setUserLanguage] = useState();
  const [updateMessageShowHide, setUpdateMessageShowHide] = useState();
  const [discardMessageShowHide, setDiscardMessageShowHide] = useState();
  const [pageTransitionConfirm, setPageTransitionConfirm] = useState();
  const [childDeleteConfirm, setChildDeleteConfirm] = useState();
  const [mitsumoriDeleteConfirm, setMitsumoriDeleteConfirm] = useState();
  const [saveDiscardConfirmPopup, setSaveDiscardConfirmPopup] = useState(false);
  const [selectedItemTmp, setSelectedItemTmp] = useState('0');
  const [userRoleTmp, setUserRoleTmp] = useState('user');
  const [companySettingObj, setCompanySettingObj] = useState([]);
  const [updatedExcelList, setUpdatedExcelList] = useState([]);

  const [parentProcessPatterns, setParentProcessPatterns] = useState([]);
  const [iq3ProcessPatterns, setIq3ProcessPatterns] = useState([]);

  // const timeZones = moment.tz.names();
  const [userId, setUserId] = useState('');
  const [userType, setUserType] = useState('');

  const companySettingRef = useRef();

  async function getPersonInChargeData() {
    let personInChargeData = [];
    let personInChargeInfo = await getStaffInfo(0, 0, 'ASC', '', {});
    if (personInChargeInfo) {
      personInChargeData = personInChargeInfo ? personInChargeInfo?.data : [];
    }
    let personInChargeFilterData = personInChargeData?.filter((item) => item.info.isUsed === true);
    return personInChargeFilterData;
  }

  const currentDateTime = dayjs().format('YYYY-MM-DD HH:mm:ss.SSSSSZ');

  useImperativeHandle(ref, () => ({
    saveDiscardConfirmOKSetting: saveDiscardConfirmOK,
    isChangeSetting: isChangeSetting,
    updateSaveDiscardConfirmPopup: updateSaveDiscardConfirmPopup,
    getSettingData: () => {
      return {
        userSettingData: userSettingObj,
        companySettingData: companySettingObj,
        updatedExcelList: updatedExcelList,
        isExist: isExist,
      };
    },
  }));

  useEffect(() => {
    async function fetchData() {
      var userInfo =
        window.localStorage.getItem('iQxWeb_LoginUser') != undefined
          ? JSON.parse(decodeURI(window.localStorage.getItem('iQxWeb_LoginUser')))
          : [];

      setUserId(userInfo.mail);
      const data = await getPersonInChargeData();
      setPersonInChargeData(data);
      setManager(data[0]?.id);
      setScreen(initShowDisps[0]);
      setUserLanguage(1);
      setUpdateMessageShowHide(true);
      setDiscardMessageShowHide(true);
      setPageTransitionConfirm(true);
      setChildDeleteConfirm(true);
      setMitsumoriDeleteConfirm(true);
      setUserType(UserRoleToString[userInfo.role]);

      // 工程パターン情報を取得
      var patternLists = [];
      var patternListInfos = await getProcessPatternInfo(0, 0, ServiceClass.Parent, 'ASC', 'id');
      if (patternListInfos) {
        patternLists = patternListInfos ? patternListInfos?.data : [];
      }
      var parentPattern = patternLists?.filter((item) => item.class === ServiceClass.Parent);
      var iq3Pattern = patternLists?.filter((item) => item.class === ServiceClass.SheetMetal);
      setParentProcessPatterns(parentPattern);
      setIq3ProcessPatterns(iq3Pattern);

      var existedData = await getExistUserSetting();

      if (existedData?.length > 0 && existedData != []) {
        let data = existedData[0];
        data = {
          ...data,
          users: {
            ...data.users,
            parentProcessPatternId: data.users?.parentProcessPatternId
              ? data?.users?.parentProcessPatternId
              : parentPattern?.[0]?.id,
            iq3ProcessPatternId: data.users?.iq3ProcessPatternId
              ? data?.users?.iq3ProcessPatternId
              : iq3Pattern?.[0]?.id,
          },
        };
        setUserSettingObj(data);
        setUserSettingBefore(data);
        setIsExist(true);
      } else {
        setUserSettingObj({
          created: currentDateTime,
          modified: currentDateTime,
          users: {
            nickName: data[0]?.name,
            staffId: data[0]?.id,
            initShowDisp: 0,
            parentProcessPatternId: parentPattern?.[0]?.id,
            iq3ProcessPatternId: iq3Pattern?.[0]?.id,
          },
          languages: { language: 0 },
          massages: { editOk: true, editCancel: true, moveInEdit: true, deleteEst: true, deletePart: true },
          parametersUpdateFlgInfos: JSON.parse(JSON.stringify(getInitParamUpdateFlgInfos)),
          info: { isUsed: true, sortNum: 0, remarks: '' },
        });
        setUserSettingBefore({
          created: currentDateTime,
          modified: currentDateTime,
          users: {
            nickName: data[0]?.name,
            staffId: data[0]?.id,
            initShowDisp: 0,
            parentProcessPatternId: parentPattern?.[0]?.id,
            iq3ProcessPatternId: iq3Pattern?.[0]?.id,
          },
          languages: { language: 0 },
          massages: { editOk: true, editCancel: true, moveInEdit: true, deleteEst: true, deletePart: true },
          parametersUpdateFlgInfos: JSON.parse(JSON.stringify(getInitParamUpdateFlgInfos)),
          info: { isUsed: true, sortNum: 0, remarks: '' },
        });
        setIsExist(false);
      }
    }
    fetchData();
  }, [selectedItem]);

  const getExistUserSetting = async () => {
    let existUserSetting = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'UserSetting';
      const otherParam = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            existUserSetting = data.data;
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage().E006);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return existUserSetting;
  };

  async function addUserSetting(userSetting) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'UserSetting';
      const otherParam = {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          userSettingType: selectedItem,
          Info: userSetting.users,
          Langs: userSetting.languages,
          Msgs: userSetting.massages,
          SettingInfo: userSetting.info,
          ParamUpdateFlgInfos: userSetting.parametersUpdateFlgInfos,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
            if (ret) {
              showCompleteMsg();
              setUserSettingBefore(JSON.parse(JSON.stringify(userSettingObj)));
            }
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  async function updateUserSetting(userSetting) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'UserSetting';
      const otherParam = {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          userSettingType: selectedItem,
          Info: userSetting.users,
          Langs: userSetting.languages,
          Msgs: userSetting.massages,
          SettingInfo: userSetting.info,
          ParamUpdateFlgInfos: userSetting.parametersUpdateFlgInfos,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
            if (ret) {
              showCompleteMsg();
              setUserSettingBefore(JSON.parse(JSON.stringify(userSettingObj)));
            }
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 画面切り替えする
  const discardConfirmOk = () => {
    setSaveDiscardConfirmPopup(false);
  };

  const closeOk = () => {
    props?.loading(false); // Waitingダイアログを非表示にする(IQX_WEBEST-222)
    setSaveDiscardConfirmPopup(false);
  };

  const updConfirmOk = async (e) => {
    if (isExist) {
      await updateUserSetting(userSettingObj);
    } else {
      await addUserSetting(userSettingObj);
      setIsExist(true);
    }
    setSaveDiscardConfirmPopup(false);
    setSaveConfirm(false);
  };

  const updConfirmCancel = () => {
    setSaveConfirm(false);
    setSaveDiscardConfirmPopup(false);
  };

  const saveDiscardConfirmOK = async (e) => {
    if (userRole === 'user') {
      updConfirmOk();
    } else {
      companySettingRef.current.updConfirmOkCompany();
      setSaveDiscardConfirmPopup(false);
    }
    changeMenu(selectedItemTmp, userRoleTmp);
  };

  const saveDiscardConfirmCancel = () => {
    setSaveDiscardConfirmPopup(false);
    // マインの左メニュークリックエベントの場合
    if (props?.isMenuClickByUserSetting) {
      let page = props?.navigatePageName;
      if (page === 'parentList') {
        props?.navigatePage('', ':parentList', 'parentList');
      } else if (page === 'parent') {
        props?.navigatePage('', ':estimate.parent', 'parent');
      } else if (page === 'sheetMetal') {
        props?.navigatePage('', ':estimate.iq3', 'iq3');
      } else {
        props?.paramChangeEvent();
      }
    } else {
      changeMenu(selectedItemTmp, userRoleTmp);
    }
  };

  const userInformationContent = (
    <>
      <Row className="mt-4">
        <label style={{ fontWeight: 800, fontSize: '13.5px' }}>ユーザー情報</label>
      </Row>
      <div style={{ marginLeft: '1.5rem' }} className="settingContent">
        <Row className="mt-2">
          <label>ユーザーID</label>
        </Row>
        <Row>
          <Col span={8}>
            <Input value={userId} disabled></Input>
          </Col>
        </Row>
        <Row className="mt-4">
          <label>担当者</label>
        </Row>
        <Row>
          <Col span={8}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={/* manager */ userSettingObj?.users?.staffId !== 0 ? userSettingObj?.users?.staffId : manager}
              onChange={(e) => {
                setUserSettingObj((prevData) => ({
                  ...prevData,
                  users: {
                    ...prevData?.users,
                    staffId: e,
                    nickName: personInChargeData?.find((item) => item.id === e)?.name || '',
                  },
                }));
                setManager(e);
              }}
            >
              {personInChargeData !== undefined &&
                personInChargeData
                  ?.sort((a, b) => Number(a?.info?.sortNum) - Number(b?.info?.sortNum))
                  .map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
            </Select>
          </Col>
        </Row>
        <Row className="mt-4">
          <label>権限</label>
        </Row>
        <Row>
          <Col span={8}>
            <Input value={userType} disabled></Input>
          </Col>
        </Row>
        <Row className="mt-4">
          <label>ログイン後の表示画面</label>
        </Row>
        <Row>
          <Col span={8}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={initShowDisps[userSettingObj?.users?.initShowDisp]}
              onChange={(e) => {
                setUserSettingObj((prevData) => ({
                  ...prevData,
                  users: { ...prevData?.users, initShowDisp: e },
                }));
                setScreen(e);
              }}
            >
              {/* {screens?.map((item) => (
                <Select.Option value={item.id}>{item.label}</Select.Option>
              ))} */}
              {Object.entries(initShowDisps)?.map(([key, value]) => (
                <Select.Option key={key} value={key}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row className="mt-4">
          <label>親工程の工程パターン初期値</label>
        </Row>
        <Row>
          <Col span={8}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={userSettingObj?.users?.parentProcessPatternId}
              onChange={(e) => {
                setUserSettingObj((prevData) => ({
                  ...prevData,
                  users: { ...prevData?.users, parentProcessPatternId: e },
                }));
                setScreen(e);
              }}
            >
              {parentProcessPatterns?.map((item, index) => (
                <Select.Option key={index} value={item?.id}>
                  {item?.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row className="mt-4">
          <label>板金工程の工程パターン初期値</label>
        </Row>
        <Row>
          <Col span={8}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={userSettingObj?.users?.iq3ProcessPatternId}
              onChange={(e) => {
                setUserSettingObj((prevData) => ({
                  ...prevData,
                  users: { ...prevData?.users, iq3ProcessPatternId: e },
                }));
                setScreen(e);
              }}
            >
              {iq3ProcessPatterns?.map((item, index) => (
                <Select.Option key={index} value={item?.id}>
                  {item?.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row style={{ marginTop: '4rem' }}>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Button
              className="mainButton"
              onClick={() => {
                setSaveConfirm(userSettingObj?.massages?.editOk);
                if (!userSettingBefore.massages.editOk) {
                  updConfirmOk();
                }
              }}
            >
              保存
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );

  const userLanguageSetting = (
    <>
      <Row className="mt-4">
        <label style={{ fontWeight: 800, fontSize: '13.5px' }}>言語とタイムゾーンの設定</label>
      </Row>
      <div style={{ marginLeft: '1.5rem' }} className="settingContent">
        <Row className="mt-2">
          <label>言語</label>
        </Row>
        <Row>
          <Col span={8}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={userSettingObj?.languages?.language}
              onChange={(e) => {
                setUserSettingObj((prevData) => ({
                  ...prevData,
                  languages: {
                    ...prevData?.languages,
                    language: e,
                  },
                }));
                setUserLanguage(e);
              }}
            >
              {Object.entries(languages)?.map(([key, value]) => (
                <Select.Option key={value} value={value}>
                  {key}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        {/*  <Row className="mt-4">
          <label>タイムゾーン</label>
        </Row>
        <Row>
          <Col span={8}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={userTimeZone}
              onChange={(e) => setUserTimeZone(e)}
            >
              {timeZone?.map((item) => (
                <Select.Option value={item.value}>{item.label}</Select.Option>
              ))}
              {timeZones?.map((item, index) => (
              <Select.Option value={index}>{item}</Select.Option>
            ))}
            </Select>
          </Col>
        </Row> */}
        <Row style={{ marginTop: '4rem' }}>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Button
              className="mainButton"
              onClick={() => {
                setSaveConfirm(userSettingBefore.massages.editOk);
                if (!userSettingBefore.massages.editOk) {
                  updConfirmOk();
                }
              }}
            >
              保存
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );

  const displaySetting = (
    <>
      <Row className="mt-4">
        <label style={{ fontWeight: 800, fontSize: '13.5px' }}>メッセージ表示の設定</label>
      </Row>
      <div style={{ marginLeft: '1.5rem' }} className="settingContent">
        <Row className="mt-2">
          <label>編集更新確認メッセージ</label>
        </Row>
        <Row>
          <Col span={8}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={userSettingObj?.massages?.editOk}
              onChange={(e) => {
                setUserSettingObj((prevData) => ({
                  ...prevData,
                  massages: {
                    ...prevData?.massages,
                    editOk: e,
                  },
                }));
                setUpdateMessageShowHide(e);
              }}
            >
              {displayFlag?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
            <Typography.Text type="secondary" style={{ fontSize: '11px', marginLeft: '5px' }}>
              ※表示しないを選択した場合、更新ボタンを押すとぞのまま更新されます。
            </Typography.Text>
          </Col>
        </Row>
        <Row className="mt-4">
          <label>編集破棄確認メッセージ</label>
        </Row>
        <Row>
          <Col span={8}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={userSettingObj?.massages?.editCancel}
              onChange={(e) => {
                setUserSettingObj((prevData) => ({
                  ...prevData,
                  massages: {
                    ...prevData?.massages,
                    editCancel: e,
                  },
                }));
                setDiscardMessageShowHide(e);
              }}
            >
              {displayFlag?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
            <Typography.Text type="secondary" style={{ fontSize: '11px', marginLeft: '5px' }}>
              ※表示しないを選択した場合、破棄ボタンを押すとぞのまま破棄されます。
            </Typography.Text>
          </Col>
        </Row>
        <Row className="mt-4">
          <label>編集モード時の画面遷移確認メッセージ</label>
        </Row>
        <Row>
          <Col span={8}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={userSettingObj?.massages?.moveInEdit}
              onChange={(e) => {
                setUserSettingObj((prevData) => ({
                  ...prevData,
                  massages: {
                    ...prevData?.massages,
                    moveInEdit: e,
                  },
                }));
                setPageTransitionConfirm(e);
              }}
            >
              {displayFlag?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={24}>
            <Typography.Text type="secondary" style={{ fontSize: '11px', marginLeft: '5px' }}>
              ※表示しないを選択した場合、画面遷移の際に編集データは自動的に破棄されます。
            </Typography.Text>
          </Col>
        </Row>
        <Row className="mt-4">
          <label>見積データ削除確認メッセージ</label>
        </Row>
        <Row>
          <Col span={8}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={userSettingObj?.massages?.deleteEst}
              onChange={(e) => {
                setUserSettingObj((prevData) => ({
                  ...prevData,
                  massages: {
                    ...prevData?.massages,
                    deleteEst: e,
                  },
                }));
                setMitsumoriDeleteConfirm(e);
              }}
            >
              {displayFlag?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row className="mt-4">
          <label>子部品データ削除確認メッセージ</label>
        </Row>
        <Row>
          <Col span={8}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={userSettingObj?.massages?.deletePart}
              onChange={(e) => {
                setUserSettingObj((prevData) => ({
                  ...prevData,
                  massages: {
                    ...prevData?.massages,
                    deletePart: e,
                  },
                }));
                setChildDeleteConfirm(e);
              }}
            >
              {displayFlag?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Row style={{ marginTop: '4rem' }}>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Button
              className="mainButton"
              onClick={() => {
                setSaveConfirm(userSettingBefore.massages.editOk);
                if (!userSettingBefore.massages.editOk) {
                  updConfirmOk();
                }
                //saveInfo();
              }}
            >
              保存
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );

  const initParamUpdateFlgSetting = (
    <>
      <Row className="mt-4">
        <label style={{ fontWeight: 800, fontSize: '13.5px' }}>CURT.パラ更新の設定</label>
      </Row>
      <div style={{ marginLeft: '1.5rem' }} className="settingContent">
        <Row className="mt-2">
          <label>CURT.パラに更新する項目にチェックを入れてください。</label>
        </Row>
        <Row className="mt-2">
          <Checkbox
            style={{ alignSelf: 'center' }}
            checked={userSettingObj?.parametersUpdateFlgInfos?.isMaterialParam}
            onChange={(e) => {
              if (userSettingObj?.parametersUpdateFlgInfos) {
                setUserSettingObj((prevData) => ({
                  ...prevData,
                  parametersUpdateFlgInfos: {
                    ...prevData?.parametersUpdateFlgInfos,
                    isMaterialParam: e.target.checked,
                  },
                }));
              }
            }}
          >
            材料系（材質区分、材質名称、材料名称・重量単価）
          </Checkbox>
        </Row>
        <Row className="mt-2">
          <Checkbox
            style={{ alignSelf: 'center' }}
            checked={userSettingObj?.parametersUpdateFlgInfos?.isProcessParam}
            onChange={(e) => {
              if (userSettingObj?.parametersUpdateFlgInfos) {
                setUserSettingObj((prevData) => ({
                  ...prevData,
                  parametersUpdateFlgInfos: {
                    ...prevData?.parametersUpdateFlgInfos,
                    isProcessParam: e.target.checked,
                  },
                }));
              }
            }}
          >
            加工系（工程マスター、装置設定、工程パターン）
          </Checkbox>
        </Row>
        <Row className="mt-2">
          <Checkbox
            style={{ alignSelf: 'center' }}
            checked={userSettingObj?.parametersUpdateFlgInfos?.isPurchaseParam}
            onChange={(e) => {
              if (userSettingObj?.parametersUpdateFlgInfos) {
                setUserSettingObj((prevData) => ({
                  ...prevData,
                  parametersUpdateFlgInfos: {
                    ...prevData?.parametersUpdateFlgInfos,
                    isPurchaseParam: e.target.checked,
                  },
                }));
              }
            }}
          >
            購入品
          </Checkbox>
        </Row>
        <Row className="mt-2">
          <Checkbox
            style={{ alignSelf: 'center' }}
            checked={userSettingObj?.parametersUpdateFlgInfos?.isOtherParam}
            onChange={(e) => {
              if (userSettingObj?.parametersUpdateFlgInfos) {
                setUserSettingObj((prevData) => ({
                  ...prevData,
                  parametersUpdateFlgInfos: {
                    ...prevData?.parametersUpdateFlgInfos,
                    isOtherParam: e.target.checked,
                  },
                }));
              }
            }}
          >
            その他全て（係数、カスタマイズ・・・）
          </Checkbox>
        </Row>
        <Row style={{ marginTop: '4rem' }}>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Button
              className="mainButton"
              onClick={() => {
                setSaveConfirm(userSettingBefore.massages.editOk);
                if (!userSettingBefore.massages.editOk) {
                  updConfirmOk();
                }
                //saveInfo();
              }}
            >
              保存
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );

  const saveDiscardConfirmContent = (
    <>
      <Row>
        <p style={{ fontSize: '13.5px' }}>
          データが保存されていません。
          <br />
          そのまま画面を切り替えると編集データが削除されます。
          <br /> 保存しますか？
        </p>
      </Row>
      <Row align="middle">
        <Col span={8}>
          <>
            <Button className="mainButton" onClick={saveDiscardConfirmOK}>
              保存
            </Button>
          </>
        </Col>
        <Col span={8} offset={7}>
          <Space size={'middle'}>
            <Button className="discardButton" style={{ textAlign: 'center' }} onClick={saveDiscardConfirmCancel}>
              破棄
            </Button>
            <Button className="cancelButton" onClick={closeOk}>
              キャンセル
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );

  /* const compareUserSettings = (obj1, obj2) => {
    let differences = {};

    // 言語比較
    if (obj1.languages.language !== obj2.languages.language) {
      differences.languages = obj1.languages.language;
    }

    // メッセージ比較

    Object.keys(obj1.massages).forEach((key) => {
      if (obj1.massages[key] !== obj2.massages[key]) {
        if (!differences.messages) {
          differences.messages = {};
        }
        differences.messages[key] = obj1.massages[key];
      }
    });

    //ユーザー情報比較
    Object.keys(obj1.users).forEach((key) => {
      if (obj1.users[key] !== obj2.users[key]) {
        if (!differences.users) {
          differences.users = {};
        }
        differences.users[key] = obj1.users[key];
      }
    });

    return differences;
  };

  const compareCompanySettings = (companyData) => {
    let obj1 = companyData.companySettingData;
    let obj2 = companyData.companySettingBefore;
    let excelList = companyData.updatedExcelList;
    let differences = {};

    // 言語比較
    Object.keys(obj1.languages).forEach((key) => {
      if (obj1.languages[key] !== obj2.languages[key]) {
        differences['languages'] = false;
      }
    });

    let beforeExcelFormats = obj2.excelFormats || [];
    if (beforeExcelFormats.length !== excelList.length) {
      differences['excelFormats'] = false;
    } else {
      for (let i = 0; i < beforeExcelFormats.length; i++) {
        const beforeFormat = beforeExcelFormats[i];
        const dataFormat = excelList[i];

        loop1: for (let key in beforeFormat) {
          if (beforeFormat[key] !== dataFormat[key]) {
            differences['excelFormats'] = false;
            break loop1;
          }
        }
      }
    }

    for (let i = 0; i < obj1.printOutputs.length; i++) {
      for (let key in obj1) {
        if (obj1['printOutputs'][key] !== obj2['printOutputs'][key]) {
          differences['printOutputs'] = false;
        }
      }
    }

    return differences;
  }; */

  const handleMenuClick = (e, role) => {
    setSelectedItemTmp(e.key);
    setUserRoleTmp(role);
    let diff = isChangeSetting();
    if (diff) {
      if (Object.keys(diff).length > 0) {
        setSaveDiscardConfirmPopup(true);
      } else {
        setSaveDiscardConfirmPopup(false);
        changeMenu(e.key, role);
      }
    } else {
      setSaveDiscardConfirmPopup(false);
      changeMenu(e.key, role);
    }
  };

  const isChangeSetting = () => {
    let companyData = getCompanySettingData();
    if (companyData) {
      setCompanySettingObj(companyData.companySettingData);
      setUpdatedExcelList(companyData.updatedExcelList);
    }
    let diff;
    if (userRole === 'user') {
      diff = compareUserSettings(userSettingObj, userSettingBefore);
    } else if (userRole === 'admin') {
      if (companyData) {
        diff = compareCompanySettings(companyData);
      }
    }
    return diff;
  };

  const showCompleteMsg = () => {
    Modal.success({
      width: 465,
      className: 'modal-complete',
      title: '完了',
      content: (
        <p style={{ fontSize: '13.5px', whiteSpace: 'pre-line', lineHeight: 2 }}>
          {'データ保存は正常に完了しました。'}
        </p>
      ),
      onOk: () => {
        if (props?.isMenuClickByUserSetting) {
          let page = props?.navigatePageName;
          if (page === 'parentList') {
            props?.navigatePage('', ':parentList', 'parentList');
          } else if (page === 'parent') {
            props?.navigatePage('', ':estimate.parent', 'parent');
          } else if (page === 'sheetMetal') {
            props?.navigatePage('', ':estimate.iq3', 'iq3');
          } else {
            props?.paramChangeEvent();
          }
        }
      },
    });
  };

  const updateSaveDiscardConfirmPopup = () => {
    setSaveDiscardConfirmPopup(true);
  };

  const changeMenu = (selectedSetting, role) => {
    if (role === 'user') {
      setUserRole(role);
      setSelectedItem(selectedSetting);
      setSelectedAdminItem();
    } else if (role === 'admin') {
      setUserRole(role);
      setSelectedAdminItem(selectedSetting);
      setSelectedItem();
    }
  };

  const getCompanySettingData = () => {
    let companySetting;
    if (companySettingRef.current) {
      companySetting = companySettingRef.current.getCompanySettingData();
    }
    return companySetting;
  };

  return (
    <>
      <Layout className="setting">
        <Sider style={siderStyle}>
          <label style={{ marginLeft: 25, fontWeight: '400', color: 'black', marginTop: '1.5rem' }}>ユーザー設定</label>
          <Menu
            mode="vertical"
            selectedKeys={[selectedItem]}
            // defaultSelectedKeys={[selectedItem]}
            onClick={(e) => handleMenuClick(e, 'user')}
            style={{ backgroundColor: '#e5e5e5', marginLeft: 25, fontSize: '13px' }}
          >
            {userSetting?.map((item, index) => (
              <Menu.Item key={index} style={{ textAlign: 'left', width: '240px' }}>
                {item}
              </Menu.Item>
            ))}
          </Menu>

          <label style={{ marginLeft: 25, fontWeight: '400', color: 'black', marginTop: 20 }}>会社(管理者)設定</label>
          <Menu
            mode="vertical"
            selectedKeys={[selectedAdminItem]}
            style={{ backgroundColor: '#e5e5e5', marginLeft: 25, fontSize: '13px' }}
          >
            {adminSetting?.map((item, index) => (
              <Menu.Item
                key={index}
                style={{ textAlign: 'left', width: '240px' }}
                onClick={(e) => handleMenuClick(e, 'admin')}
              >
                {item}
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
        <Layout>
          <Content style={contentStyle}>
            <div style={{ marginLeft: 25 }}>
              {userRole === 'user' ? (
                <>
                  {selectedItem === '0'
                    ? userInformationContent
                    : selectedItem === '1'
                    ? userLanguageSetting
                    : selectedItem === '2'
                    ? displaySetting
                    : initParamUpdateFlgSetting}
                </>
              ) : (
                <>
                  <CompanySetting
                    selectedItem={selectedAdminItem}
                    ref={companySettingRef}
                    showCompleteMsg={showCompleteMsg}
                  />
                </>
              )}

              {/* {userInformationContent} */}
            </div>
          </Content>
        </Layout>
      </Layout>

      {/** 新規追加破棄確認ポップアップ */}
      {saveConfirm &&
        commonModal(
          saveConfirm,
          confirmModalTitle,
          commonFooter(updConfirmOk, updConfirmCancel),
          null,
          400,
          updConfirmOk,
          updConfirmCancel,
          confirmUpdateContent,
          null,
          null,
          false
        )}

      {saveDiscardConfirmPopup &&
        commonModal(
          saveDiscardConfirmPopup,
          confirmScreenChangeWarning,
          null,
          null,
          500,
          discardConfirmOk,
          closeOk,
          saveDiscardConfirmContent,
          null,
          null,
          false
        )}
    </>
  );
});

export default UserSetting;
