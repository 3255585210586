import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';

import { Row, Col, Input, Select, Popover, Checkbox, Button } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  checkInput,
  secondsToHms,
  setTimetoSec,
  checkInputWithColor,
  kouteiCalculateSelectedArr,
  checkShowHideIcon,
  JPYs,
  handleShowHide,
  handleCollpse,
  handleKeyPress,
  toDecimal,
} from '../common/Common.js';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';
import { DataType, WorkType } from '../common/enums';
import { amtEmptyStr, timeHmsStr, DataButton, helpCmt } from '../common/Constant';

const IQ3KouteiCalculate_AutoBari = forwardRef((props, ref) => {
  const [isAutoBaridetail, setIAutoBaridetail] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [totalAmt, setTotalAmt] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [totalSagyoAmt, setTotalSagyoAmt] = useState(0);
  const [totalSagyoTime, setTotalSagyoTime] = useState(0);
  const [totalDandoriAmt, setTotalDandoriAmt] = useState(0);
  const [totalDandoriTime, setTotalDandoriTime] = useState('');
  const [totalSum, setTotalSum] = useState(0);
  const [totalTimeSum, setTotalTimeSum] = useState(0);
  const [sagyoAmtSum, setSagyoAmtSum] = useState(0);
  const [sagyoTimeSum, setSagyoTimeSum] = useState(0);
  const [dandoriAmtSum, setDandoriAmtSum] = useState(0);
  const [dandoriTimeSum, setDandoriTimeSum] = useState(0);
  const [processArr, setProcessArr] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [handelingAmt, setHandelingAmt] = useState();
  const [handelingTime, setHandelingTime] = useState();
  const [autoBariAmt, setAutoBariAmt] = useState();
  const [kensaTime, setKensaTime] = useState();

  const [editedHandelingAmt, setEditedHandelingAmt] = useState();
  const [editedHandelingTime, setEditedHandelingTime] = useState();
  const [editedKensaAmt, setEditedKensaAmt] = useState();
  const [editedKensaTime, setEditedKensaTime] = useState();
  const [selectedDataFlg, setSelectedDataFlg] = useState(0);
  const [patternInfo, setPatternInfo] = useState([]);
  const [updateEditedData, setUpdateEditedData] = useState([]);
  const [deviceMst, setDeviceMst] = useState([]);
  const [processMst, setProcessMst] = useState([]);

  const settingEditData = (arr, propsOrgData) => {
    let editDataArr = [];

    for (let i = 0; i < arr?.length; i++) {
      let subArr = arr[j];
      editDataArr.push({
        id: i,
        dandoriAmt: subArr.dandoriAmt,
        dandoriTime: subArr.dandoriTime,
        sagyoAmt: subArr.sagyoAmt,
        sagyoTime: subArr.sagyoTime,
        processDetailTypeNo: propsOrgData[j]?.processDetailTypeNo,
        sumAmt: subArr.dandoriAmt + subArr.sagyoAmt,
        sumTime: subArr.dandoriTime + subArr.sagyoTime,
        calMethod: subArr.calMethod,
        calInput: subArr.calInput,
        processDetailWorkTypeGroup: propsOrgData[j]?.processDetailWorkTypeGroup,
      });
    }
    return editDataArr;
  };

  const calculateTotals = (editDataArr) => {
    let sagyoTimeSumSec = 0;
    let sagyoAmtSum = 0;
    editDataArr?.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt ? Math.round(element.sagyoAmt) : 0;
      sagyoTimeSumSec += element.sagyoTime;
    });

    let totalSum =
      (sagyoAmtSum ? Math.round(sagyoAmtSum) : 0) +
      (editDataArr[0].dandoriAmt ? Math.round(editDataArr[0].dandoriAmt) : 0); // 画面表示用（四捨五入した金額を足す）
    let totalTimeSum = sagyoTimeSumSec + editDataArr[0].dandoriTime;
    let sagyoTimeSum = sagyoTimeSumSec;

    let totals = [];
    let i = 0;
    totals.push({
      id: i++,
      sagyoAmtSum: sagyoAmtSum,
      sagyoTimeSum: sagyoTimeSum,
      sagyoTimeSumSec: sagyoTimeSumSec,
      totalSum: totalSum,
      totalTimeSum: totalTimeSum,
    });
    return totals;
  };

  const handleChangeCheckData = (e, i) => {
    /* setSelectedDataFlg(e);
    props?.sendSelectedDataFlg(e); */
    const data = { ...updateEditedData };
    data.dataType = e;
    setUpdateEditedData(data);
    setAutoBariAmt(e == DataType.Data ? data.totalDataPrice : data.totalEditPrice);
    props?.updateIQ3KouteiCal(data, WorkType.SmDeburringAuto);
  };

  // IQX_WEBEST-16 データ表示に戻す
  const settingDataItems = () => {
    updateEditedData.editItems = JSON.parse(JSON.stringify(updateEditedData?.dataItems));
    updateEditedData.editDataFlg = false;
    setEditedData(JSON.parse(JSON.stringify(processArr)));
    setTotalSagyoAmt(sagyoAmtSum ? sagyoAmtSum : 0);
    setTotalSagyoTime(sagyoTimeSum ? sagyoTimeSum : 0);
    setTotalAmt(totalSum ? totalSum : 0);
    setTotalTime(totalTimeSum ? totalTimeSum : 0);
    updateEditedData.totalEditPrice = totalSum ? totalSum : 0;
    updateEditedData.totalEditTime = totalTimeSum ? totalTimeSum : 0;
    const cuDivs = document.querySelectorAll('div[id*="Cu"]');

    cuDivs.forEach((div) => {
      div.style.display = 'none';
    });

    if (updateEditedData.dataType == DataType.Data) {
      setAutoBariAmt(updateEditedData.totalDataPrice);
    } else {
      setAutoBariAmt(updateEditedData.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmDeburringAuto);
  };

  useEffect(() => {
    let dataEdit = [];
    let totals = [];
    let editData = [];
    let processMst = [];
    let deviceMst = []; //テスト用ID;

    let parameters = props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
    processMst = parameters?.process
      ? parameters.process?.filter((item) => item.workType === WorkType.SmDeburringAuto)[0]
      : [];
    let patternInfo = props?.selectedIQ3DataDetail?.processSelect
      ? props?.selectedIQ3DataDetail?.processSelect?.details?.filter((item) => item.processId == processMst?.id)
      : [];
    setPatternInfo([]);
    if (patternInfo == undefined) return;
    if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;
    deviceMst =
      parameters?.device && patternInfo
        ? parameters?.device?.filter(
            (item) => item.processId == patternInfo[0]?.processId && item.id == patternInfo[0]?.deviceId
          )[0]
        : []; //テスト用ID;
    setProcessMst(processMst ? processMst : []);
    setDeviceMst(deviceMst ? deviceMst : []);
    setPatternInfo(patternInfo);
    // 選択された行の工程積算情報を正しく取得するための対応
    let curSelectedIQ3DataDetail = props?.selectedEstimateData?.esIq3Info?.filter(
      (item) => item.no === props?.selectedIQ3DataDetail?.no
    );
    let selectedIQ3DataDetail = curSelectedIQ3DataDetail?.length > 0 ? curSelectedIQ3DataDetail?.[0] : [];
    if (selectedIQ3DataDetail?.length === 0) return;
    let deburringAutoAdditions = selectedIQ3DataDetail?.additions?.filter(
      (item) => item.processId == processMst?.id
    )[0];

    // IQX_WEBEST-16 工程積算タブで編集データのチェックがOFFだと、手入力で入力した内容がリセットされてしまう
    let autoDeburAdditionEditData = deburringAutoAdditions?.editItems;
    let editDataFlg = deburringAutoAdditions?.editDataFlg;
    // 自動バリ工程入力情報取得
    let autoDeburProcessInput = props?.selectedIQ3DataDetail?.processInput?.filter(
      (item) => item.processId == processMst?.id
    )[0];
    // 新規の場合
    // if (deburringAutoAdditions?.dataItems?.length == 0 || deburringAutoAdditions == undefined) {
    let deburringAutoAddition = {};
    let detailItems = [];
    let editDetailItems = [];
    let processName = '自動バリ金額/時間';
    let processTime = 0;

    // 自動バリ詳細情報
    detailItems.push({
      id: 1,
      processName: processName,
      dandoriAmt: 0,
      dandoriTime: 0,
      sagyoAmt: 0,
      sagyoTime: 0,
      area: Number(autoDeburProcessInput?.details?.[0]?.area),
      weight: Number(autoDeburProcessInput?.details?.[0]?.weight),
      processCount: Number(autoDeburProcessInput?.details?.[0]?.count),
      kihon: true,
      formula: '',
    });

    deburringAutoAddition = {
      no: processMst?.id,
      processId: processMst?.id,
      dataType: deburringAutoAdditions?.dataType ? deburringAutoAdditions?.dataType : DataType.Data,
      dataItems: detailItems,
      editItems: deburringAutoAdditions?.editItems ? deburringAutoAdditions?.editItems : editDetailItems,
      totalDandori: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalSagyo: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalDataPrice: 0,
      totalDataTime: 0,
      totalEditPrice: 0,
      totalEditTime: 0,
      editDataFlg: editDataFlg,
    };
    deburringAutoAdditions = deburringAutoAddition;
    // }

    if (deburringAutoAdditions?.dataItems) {
      let orgData = JSON.parse(JSON.stringify(deburringAutoAdditions?.dataItems));
      if (deviceMst != undefined && orgData?.length > 0) {
        // 重量
        let weight = Number(autoDeburProcessInput?.details?.[0]?.weight);
        // 面積
        let area = Number(autoDeburProcessInput?.details?.[0]?.area);
        orgData[0].area = area === undefined ? 0 : area;
        if (weight === 0 && area === 0) {
          deburringAutoAdditions.dataItems[0].dandoriTime = 0;
          orgData[0].dandoriTime = 0;
          setDandoriTimeSum(0);

          let dandoriAmt = 0;
          deburringAutoAdditions.dataItems[0].dandoriAmt = dandoriAmt;
          orgData[0].dandoriAmt = dandoriAmt === dandoriAmt;
          setDandoriAmtSum(dandoriAmt);

          let processCount = 0;
          let cSagyoTimeSec = 0;
          let cSagyoTime = cSagyoTimeSec;
          deburringAutoAdditions.dataItems[0].sagyoTime = cSagyoTime;
          orgData[0].sagyoTime = cSagyoTime;
          setSagyoTimeSum(0);

          let cSagyoAmt = 0;
          deburringAutoAdditions.dataItems[0].sagyoAmt = cSagyoAmt;
          orgData[0].sagyoAmt = cSagyoAmt;
          setSagyoAmtSum(cSagyoAmt);

          let totalSum = 0;
          setTotalSum(totalSum);
          deburringAutoAdditions.totalDataPrice = totalSum;
          deburringAutoAdditions.totalDandori.dataItemsAmt = dandoriAmt;
          deburringAutoAdditions.totalSagyo.dataItemsAmt = cSagyoAmt;

          let totalTimeSec = 0;
          setTotalTimeSum(totalTimeSec);
          deburringAutoAdditions.totalDataTime = totalTimeSec;
          deburringAutoAdditions.totalDandori.dataItemsTime = 0;
          deburringAutoAdditions.totalSagyo.dataItemsTime = 0;
        } else {
          // サイズ係数
          let sizeCoef = getSizeCoeff(deviceMst, weight, area);

          /** 段取金額/時間 */
          // 段取時間設定
          //　基本段取時間＊サイズ係数
          let dandoriTimeSec = calDandoriTime(deviceMst, weight, area);
          dandoriTimeSec = dandoriTimeSec ? dandoriTimeSec : 0;
          let dandoriTimeHms = dandoriTimeSec;
          deburringAutoAdditions.dataItems[0].dandoriTime = dandoriTimeHms;
          orgData[0].dandoriTime = dandoriTimeHms === undefined ? 0 : dandoriTimeHms;
          setDandoriTimeSum(dandoriTimeHms);

          // 段取金額設定
          //段階時間＊時間チャージ
          let dandoriAmt = calDandoriAmt(dandoriTimeSec, deviceMst);
          dandoriAmt = dandoriAmt ? dandoriAmt : 0;
          deburringAutoAdditions.dataItems[0].dandoriAmt = dandoriAmt;
          orgData[0].dandoriAmt = dandoriAmt === undefined ? 0 : dandoriAmt;
          setDandoriAmtSum(dandoriAmt);

          /** 作業金額/時間 */
          // 作業時間｛加工時間	+	(加工補正時間	*	加工補正係数)｝	*	サイズ係数	*	回数
          let processCount = Number(autoDeburProcessInput?.details?.[0]?.count);
          // // 加工補正時間 // IQX_WEBEST-270
          // let modifyTimeQtyThMaster = deviceMst?.details?.modifyTimeQtyThMaster?.filter(
          //   (item) => (item.value == area || item.value > area) && !item.isOutOfRange
          // )?.[0];
          // let hoseTime = deviceMst?.details?.modifyTimeItems?.filter(
          //   (item) => item.no == modifyTimeQtyThMaster?.no && !item.isOutOfRange
          // )?.[0]?.value;
          // // 加工補正係数
          // let hoseCoeff = getHoseCoeff(deviceMst, weight, area);
          let workTime = calSagyoTime(deviceMst, area);
          let cSagyoTimeSec = workTime * sizeCoef * processCount; // IQX_WEBEST-270
          cSagyoTimeSec = cSagyoTimeSec ? cSagyoTimeSec : 0;
          let cSagyoTime = cSagyoTimeSec;
          deburringAutoAdditions.dataItems[0].sagyoTime = cSagyoTime;
          orgData[0].sagyoTime = cSagyoTime === undefined ? 0 : cSagyoTime;
          setSagyoTimeSum(cSagyoTime);

          // 作業金額
          //　作業時間＊時間チャージ
          let cSagyoAmt = calSagyoAmt(cSagyoTimeSec, deviceMst);
          deburringAutoAdditions.dataItems[0].sagyoAmt = cSagyoAmt === undefined ? 0 : cSagyoAmt;
          orgData[0].sagyoAmt = cSagyoAmt === undefined ? 0 : cSagyoAmt;
          setSagyoAmtSum(cSagyoAmt);

          let totalSum = (dandoriAmt ? Math.round(dandoriAmt) : 0) + (cSagyoAmt ? Math.round(cSagyoAmt) : 0); // 画面表示用（四捨五入した金額を足す）
          setTotalSum(totalSum);
          deburringAutoAdditions.totalDataPrice = totalSum;
          deburringAutoAdditions.totalDandori.dataItemsAmt = dandoriAmt ? Math.round(dandoriAmt) : 0;
          deburringAutoAdditions.totalSagyo.dataItemsAmt = cSagyoAmt ? Math.round(cSagyoAmt) : 0;

          let totalTimeSec = dandoriTimeSec + cSagyoTimeSec;
          setTotalTimeSum(totalTimeSec);
          deburringAutoAdditions.totalDataTime = totalTimeSec;
          deburringAutoAdditions.totalDandori.dataItemsTime = dandoriTimeSec;
          deburringAutoAdditions.totalSagyo.dataItemsTime = cSagyoTimeSec;
        }
        setProcessArr(orgData);

        // IQX_WEBEST-16 工程積算タブで編集データのチェックがOFFだと、手入力で入力した内容がリセットされてしまう
        if (autoDeburAdditionEditData?.length > 0 && editDataFlg) {
          editData = JSON.parse(JSON.stringify(autoDeburAdditionEditData));
        } else {
          editData = JSON.parse(JSON.stringify(orgData));
        }
        if (editData != undefined && editData?.length > 0) {
          deburringAutoAdditions.editItems = editData;
          setEditedData(editData);
          totals = calculateTotals(editData);

          setTotalAmt(totals[0]?.totalSum);

          deburringAutoAdditions.totalEditPrice = totals[0]?.totalSum;
          deburringAutoAdditions.totalDandori.editItemsAmt = editData[0].dandoriAmt
            ? Math.round(editData[0].dandoriAmt)
            : 0;
          deburringAutoAdditions.totalSagyo.editItemsAmt = totals[0]?.sagyoAmtSum;

          setTotalTime(totals[0]?.totalTimeSum);
          setTotalTimeSum(totals[0]?.totalTimeSum);
          deburringAutoAdditions.totalEditTime = totals[0]?.totalTimeSum;
          deburringAutoAdditions.totalDandori.editItemsTime = editData[0].dandoriTime;
          deburringAutoAdditions.totalSagyo.editItemsTime = totals[0]?.sagyoTimeSum;

          setTotalSagyoAmt(totals[0]?.sagyoAmtSum);
          setTotalSagyoTime(totals[0]?.sagyoTimeSum);

          setTotalDandoriAmt(totals[0]?.dandoriAmtSum);
          setTotalDandoriTime(totals[0]?.dandoriTimeSum);
        }
        setAutoBariAmt(
          deburringAutoAdditions?.dataType == DataType.Data
            ? deburringAutoAdditions.totalDataPrice
            : deburringAutoAdditions.totalEditPrice
        );
        setUpdateEditedData(deburringAutoAdditions);
        props?.updateIQ3KouteiCal(deburringAutoAdditions, WorkType.SmDeburringAuto);
      }
    }
  }, [
    props?.activeTabKey,
    props?.isReCalIQ3,
    props?.activePartMenu,
    props?.iq3ActionForCal,
    props?.selectedProcessSelect,
    props?.selectedIQ3DataDetail,
  ]);

  useEffect(() => {
    setEditMode(props?.editMode != undefined ? props?.editMode : false);
  }, [props?.editMode]);

  const getSizeCoeff = (deviceMst, weight, area) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let prodSizeWeightThMaster = deviceMst?.details?.prodSizeWeightThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
    );
    // 面積
    let prodSizeAreaThMaster = deviceMst?.details?.prodSizeAreaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item.isOutOfRange
    );
    let childPartsCoeffs =
      prodSizeWeightThMaster?.length > 0 && prodSizeAreaThMaster?.length > 0
        ? deviceMst?.details?.prodSizeItems?.filter((item) => item.weightId == prodSizeWeightThMaster?.[0]?.no)
        : deviceMst?.details?.prodSizeItems[0];

    // サイズ係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter(
            (item) => item.no == prodSizeAreaThMaster?.[0]?.no && !item.isOutOfRange
          )?.[0]?.value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    partsCoeff = partsCoeff ? partsCoeff : 0;

    return partsCoeff;
  };

  const getHoseCoeff = (deviceMst, weight, area) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let mtWeightThMaster = deviceMst?.details?.mtWeightThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
    );
    // 面積
    let mtAreaThMaster = deviceMst?.details?.mtAreaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item.isOutOfRange
    );
    let childPartsCoeffs =
      mtWeightThMaster?.length > 0 && mtAreaThMaster?.length > 0
        ? deviceMst?.details?.prodSizeItems?.filter((item) => item.weightId == mtWeightThMaster?.[0]?.no)
        : [deviceMst?.details?.prodSizeItems[0]];

    // 補正係数
    let partsCoeff =
      childPartsCoeffs?.length > 0 && mtAreaThMaster?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter(
            (item) => item.no == mtAreaThMaster?.[0]?.no && !item.isOutOfRange
          )?.[0]?.value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    partsCoeff = partsCoeff ? partsCoeff : 0;

    return partsCoeff;
  };

  const calDandoriTime = (deviceMst, weight, area) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let childPartsCoeffWeightThMaster = deviceMst?.details?.childPartsCoeffWeightThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
    );
    // 面積
    let childPartsCoeffAreaThMaster = deviceMst?.details?.childPartsCoeffAreaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item.isOutOfRange
    );
    let childPartsCoeffs =
      childPartsCoeffWeightThMaster?.length > 0 && childPartsCoeffAreaThMaster?.length > 0
        ? deviceMst?.details?.childPartsCoeff?.filter((item) => item.weightId == childPartsCoeffWeightThMaster[0]?.no)
        : deviceMst?.details?.childPartsCoeff[0];

    // サイズ係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter(
            (item) => item.no == childPartsCoeffAreaThMaster?.[0]?.no && !item.isOutOfRange
          )?.[0]?.value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    //　基本段取時間＊サイズ係数
    let dandoriTimeSec = deviceMst?.details?.prepBasicTime * partsCoeff;
    dandoriTimeSec = dandoriTimeSec ? dandoriTimeSec : 0;
    dandoriTimeSec = dandoriTimeSec * getSetupCoeff(deviceMst); // IQX_WEBEST-278 基本段取時間＊段取個数係数
    return dandoriTimeSec;
  };

  const calDandoriAmt = (dandoriTimeSec, deviceMst) => {
    //段階時間
    let mSetTimeMin = dandoriTimeSec / 60;
    //装置の段階時間チャージ
    let mSetHRate = deviceMst.details?.charges?.prep;
    //段階時間＊時間チャージ
    let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0;
    dandoriAmt = Number.isFinite(dandoriAmt) ? dandoriAmt : 0;
    return dandoriAmt;
  };

  const calSagyoTime = (deviceMst, area) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 作業時間
    // 面積
    let areaThMaster = deviceMst?.details?.areaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item.isOutOfRange
    );
    let workTime =
      areaThMaster?.length > 0
        ? deviceMst?.details?.workTimeThItems?.filter(
            (item) => item.no == areaThMaster[0]?.no && !item.isOutOfRange
          )?.[0]?.value
        : deviceMst?.details?.workTimeThItems[0]?.value;
    workTime = workTime ? workTime : 0;
    workTime = workTime * getProductionQuantityCoeff(deviceMst); // IQX_WEBEST-278 作業時間＊生産個数係数
    return workTime;
  };

  const calSagyoAmt = (cSagyoTimeKonpoSec, deviceMst) => {
    // 作業金額
    // 作業時間
    let mWorkHTime = cSagyoTimeKonpoSec;
    let mWorkHTimeMin = mWorkHTime / 60;
    //装置の作業時間チャージ
    let mWorkHRate = deviceMst.details?.charges?.work;
    //　加工時間＊時間チャージ
    let cSagyoAmtKonpo = (mWorkHTimeMin * mWorkHRate) / 60.0;
    cSagyoAmtKonpo = Number.isFinite(cSagyoAmtKonpo) ? cSagyoAmtKonpo : 0;
    return cSagyoAmtKonpo;
  };

  const autoBari = () => {
    if (isAutoBaridetail == true) {
      setIAutoBaridetail(false);
    } else {
      setIAutoBaridetail(true);
    }
  };

  const setAutoBariDisplay = (display) => {
    setIAutoBaridetail(display);
  };

  const subpartDetail = (i) => {
    let showHide = document.getElementById('autoBProcess' + i).style.display;
    if (showHide == 'block') {
      document.getElementById('autoBProcess' + i).style.display = 'none';
      document.getElementById('arrowDownAutoB' + i).style.display = 'block';
      document.getElementById('arrowUpAutoB' + i).style.display = 'none';
    } else {
      document.getElementById('autoBProcess' + i).style.display = 'block';
      document.getElementById('arrowDownAutoB' + i).style.display = 'none';
      document.getElementById('arrowUpAutoB' + i).style.display = 'block';
    }
  };

  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };
  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
    setAutoBariDisplay: setAutoBariDisplay,
  }));

  const toTwoDecimal = (j, param) => {
    let newState = JSON.parse(JSON.stringify(editedData));
    newState[j][param] = toDecimal(editedData[j]?.[param], 2);
    setEditedData(newState);
  };

  const settingNewDandoriAmtInArr = (value, index, objId, timeType, timeAct) => {
    let newState = [...editedData];
    updateEditedData.editDataFlg = true; // IQX_WEBEST-16 編集データを保持フラグOn
    let oldValue = editedData[index][objId];
    if (value === undefined) {
      value = 0;
    } else {
      value = value ? Number(value) : 0;
    }
    if (objId === 'area') {
      newState[index][objId] = value;
    } else {
      newState[index][objId] = Number(value);
    }
    updateEditedData.editItems[index][objId] = newState[index][objId];
    //let deviceMst = [];

    if (objId === 'dandoriTime') {
      if (document.getElementById(index + 'autoBDandoriAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'autoBDandoriAmtCu').style.display;
        if (showHideCu === 'none') {
          if (value === 0) {
            newState[index].dandoriAmt = 0;
          } else if (timeAct === 'input') {
            let timeType = value - oldValue;
            newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value, objId);
          } else {
            newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value, objId);
          }
          updateEditedData.editItems[index].dandoriAmt = newState[index].dandoriAmt;
        }
      }
    } else if (objId === 'sagyoTime') {
      if (document.getElementById(index + 'autoBWorkAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'autoBWorkAmtCu').style.display;
        if (showHideCu === 'none') {
          if (value === 0) {
            newState[index].sagyoAmt = 0;
          } else if (timeAct === 'input') {
            let timeType = value - oldValue;
            newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value, objId);
          } else {
            newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value, objId);
          }
          updateEditedData.editItems[index].sagyoAmt = newState[index].sagyoAmt;
        }
      }
    } else if (objId === 'materialHandlingTime') {
      if (document.getElementById(index + 'materialHandlingAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'materialHandlingAmtCu').style.display;
        if (showHideCu === 'block') {
          //checkInputWithColor(false,'',index + 'autoBWorkAmt', newState[index].sagyoAmt, 'red');
        } else {
          if (timeAct === 'input') {
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
            newState[index].materialHandlingAmt = changeAmtwithTime(
              newState[index].materialHandlingAmt,
              timeType,
              timeAct,
              value,
              objId
            );
          } else {
            newState[index].materialHandlingAmt = changeAmtwithTime(
              newState[index].materialHandlingAmt,
              timeType,
              timeAct,
              value,
              objId
            );
          }
          // checkInputWithColor(false,'',index + 'autoBWorkAmt', newState[index].sagyoAmt, 'green');
        }
      }
    } else if (objId === 'area') {
      value = Number(value);
      let weight = newState[index]['weight'];
      let processCount = newState[index]['processCount'];
      let sizeCoef = getSizeCoeff(deviceMst, weight, value);
      // 加工補正時間 // IQX_WEBEST-270
      // let modifyTimeQtyThMaster = deviceMst?.details?.modifyTimeQtyThMaster?.filter(
      //   (item) => (item.value == value || item.value > value) && !item.isOutOfRange
      // )?.[0];
      // modifyTimeQtyThMaster =
      //   modifyTimeQtyThMaster === undefined
      //     ? deviceMst?.details?.modifyTimeQtyThMaster[deviceMst?.details?.modifyTimeQtyThMaster.length - 1]
      //     : modifyTimeQtyThMaster;
      // let hoseTime = deviceMst?.details?.modifyTimeItems?.filter(
      //   (item) => item.no == modifyTimeQtyThMaster?.no && !item.isOutOfRange
      // )?.[0]?.value;
      // // 加工補正係数
      // let hoseCoeff = getHoseCoeff(deviceMst, weight, value);
      let workTime = calSagyoTime(deviceMst, value);
      let cSagyoTimeSec = workTime * sizeCoef * processCount; // IQX_WEBEST-270
      // 入力面積により装置の加工時間
      // let cSagyoTimePerSec = 300;
      // 加工時間＊サイズ係数
      // let cSagyoTimeSec = cSagyoTimePerSec * 1.0;
      let cSagyoTime = cSagyoTimeSec ? cSagyoTimeSec : 0;
      newState[index].sagyoTime = cSagyoTime;
      updateEditedData.editItems[index].sagyoTime = newState[index].sagyoTime;

      // 作業金額
      //　作業時間＊時間チャージ
      if (document.getElementById(index + 'autoBWorkAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'autoBWorkAmtCu').style.display;
        if (showHideCu === 'none') {
          let cSagyoAmt = calSagyoAmt(cSagyoTimeSec, deviceMst);
          newState[index].sagyoAmt = cSagyoAmt ? cSagyoAmt : 0;
          updateEditedData.editItems[index].sagyoAmt = newState[index].sagyoAmt;
        }
      }
    }
    let newSagyoSum = 0;
    let newDandoriSum = 0;
    let newSum = 0;
    let newSubSum = 0;
    let newSagyoTimeSec = 0;
    let newDandoriTimeSec = 0;

    newSubSum = newState[index].sagyoAmt + newState[index].dandoriAmt;
    newState[index].sumAmt = newSubSum;

    let newSubTimeSum = newState[index].sagyoTime + newState[index].dandoriTime;
    newState[index].sumTime = newSubTimeSum;
    newState?.forEach((element) => {
      newSagyoSum += element.sagyoAmt ? Math.round(element.sagyoAmt) : 0;
      newDandoriSum += element.dandoriAmt ? Math.round(element.dandoriAmt) : 0;
      newSagyoTimeSec += element.sagyoTime;
      newDandoriTimeSec += element.dandoriTime;
    });

    newSum = (newSagyoSum ? Math.round(newSagyoSum) : 0) + (newDandoriSum ? Math.round(newDandoriSum) : 0); // 画面表示用（四捨五入した金額を足す）

    let newSagyoTimeSum = newSagyoTimeSec;
    let newDandoriTimeSum = newDandoriTimeSec;
    let newTotalTimeSum = newSagyoTimeSec + newDandoriTimeSec;

    /* checkInputWithColor(false, '', 'totalAmtAuto', newSum, 'green');
    checkInputWithColor(false, '', 'totalTimeAuto', newTotalTimeSum, 'green'); */
    setTotalSagyoAmt(newSagyoSum);
    setTotalDandoriAmt(newDandoriSum);
    setTotalAmt(newSum);
    setTotalSagyoTime(newSagyoTimeSum);
    setTotalDandoriTime(newDandoriTimeSum);
    setTotalTime(newTotalTimeSum);
    setEditedKensaAmt(newState[0].kensaAmt);
    setEditedKensaTime(newState[0].kensaTime);
    setEditedData(newState);

    updateEditedData.totalEditPrice = newSum;
    updateEditedData.totalEditTime = newTotalTimeSum;
    updateEditedData.totalDandori.editItemsAmt = newDandoriSum;
    updateEditedData.totalDandori.editItemsTime = newDandoriTimeSum;
    updateEditedData.totalSagyo.editItemsAmt = newSagyoSum;
    updateEditedData.totalSagyo.editItemsTime = newSagyoTimeSum;
    if (updateEditedData.dataType == DataType.Data) {
      setAutoBariAmt(updateEditedData.totalDataPrice);
    } else {
      setAutoBariAmt(updateEditedData.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmDeburringAuto);
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = parseFloat(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal, processType) => {
    let editedAmt;
    let totalSec = Number(timeVal);
    let intAmt = checkStr(amt);
    //let deviceMst = [];

    if (processType === 'dandoriTime') {
      // 段取金額
      //　段取時間＊時間チャージ
      editedAmt = calDandoriAmt(totalSec, deviceMst);
    } else if (processType === 'sagyoTime') {
      // 作業金額
      //　加工時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, deviceMst);
    } else if (processType === 'materialHandlingTime') {
      // マテハン金額
      //　マテリアルハンドリング時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, deviceMst);
    }
    return editedAmt;
  };

  const disableTimer = (id) => {
    document.getElementById(id).style.color = 'grey';
    document.getElementById(id).style.pointerEvents = 'none';
  };

  // IQX_WEBEST-278 工程積算タブの表示に生産個数係数が含まれていなかったので、含むようにしてほしい
  const getProductionQuantityCoeff = (deviceMst) => {
    let productionQuantityCoeff = 1;
    let quantity = Number(props?.selectedEstimateData?.estimateProducts?.quantity);
    let partCnt = Number(props?.selectedIQ3DataDetail?.partCount); // 員数
    let manufacturingCnt = quantity * partCnt; // 製造数 * 員数
    // 生産個数係数
    let productionCoeffTh = deviceMst?.details?.productionQtyCoeffThMaster?.filter(
      (item) => (item.value == manufacturingCnt || item.value > manufacturingCnt) && !item.isOutOfRange
    );
    if (productionCoeffTh) {
      let productionCoeffThNo =
        productionCoeffTh?.length > 0
          ? productionCoeffTh[0]?.no
          : deviceMst?.details?.productionQtyCoeffThMaster?.[0]?.no;
      let productionCoeff =
        productionCoeffThNo == 0
          ? 0
          : deviceMst?.details?.productionQtyCoeffThItems?.filter(
              (item) => item.no == productionCoeffThNo && !item.isOutOfRange
            )[0]?.value;

      productionQuantityCoeff = productionCoeff ? productionCoeff : 1;
    }
    return productionQuantityCoeff;
  };

  // IQX_WEBEST-278 工程積算タブの表示に段取個数係数が含まれていなかったので、含むようにしてほしい
  const getSetupCoeff = (deviceMstFilter) => {
    let iq3SetupCoeff = 1;
    let quantity = Number(props?.selectedEstimateData?.estimateProducts?.quantity);
    let partCnt = Number(props?.selectedIQ3DataDetail?.partCount); // 員数
    let manufacturingCnt = quantity * partCnt; // 製造数 * 員数
    // 段取個数係数
    let prepQtyCoeffTh = deviceMstFilter?.details?.prepQtyCoeffThMaster?.filter(
      (item) => (item.value == manufacturingCnt || item.value > manufacturingCnt) && !item.isOutOfRange
    );
    if (prepQtyCoeffTh) {
      let prepQtyCoeffThNo =
        prepQtyCoeffTh?.length > 0 ? prepQtyCoeffTh?.[0]?.no : deviceMstFilter?.details?.prepQtyCoeffThMaster?.[0]?.no;
      let prepQtyCoeff =
        prepQtyCoeffThNo == 0
          ? 0
          : deviceMstFilter?.details?.prepQtyCoeffItems?.filter(
              (item) => item.no == prepQtyCoeffThNo && !item.isOutOfRange
            )[0]?.value;
      iq3SetupCoeff = prepQtyCoeff;
      iq3SetupCoeff = iq3SetupCoeff ? iq3SetupCoeff : 1;
    }
    return iq3SetupCoeff;
  };

  return (
    <div className="kouteiCalculateDiv">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={autoBari}>
        <Col span={4} className="oyabuhin-col">
          <div style={{ display: 'flex' }}>
            {isAutoBaridetail ? (
              <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 5 }}>{processMst?.name}</label>
          </div>
        </Col>
        <Col span={6} className="oyabuhin-col">
          <div>{autoBariAmt ? JPYs.format(Math.round(autoBariAmt)) : amtEmptyStr}</div>
        </Col>
        <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
          <div>
            <label style={{ marginLeft: 5 }}>{deviceMst?.name}</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isAutoBaridetail ? 'block' : 'none' }}>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            工程詳細
          </Col>
          <Col span={6} className="oyabuhin-detail-col">
            <Checkbox
              key={kouteiCalculateSelectedArr[0].key}
              value={kouteiCalculateSelectedArr[0].value}
              checked={kouteiCalculateSelectedArr[0].key == updateEditedData.dataType}
              disabled={!props?.editMode}
              style={{ marginRight: 6 }}
              onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[0].key)}
            ></Checkbox>
            <label>{kouteiCalculateSelectedArr[0].value}</label>
          </Col>
          <Col span={8} className="oyabuhin-detail-col">
            <div style={{ display: 'flex', justifyContent: 'left', width: '60%' }}>
              <Checkbox
                key={kouteiCalculateSelectedArr[1].key}
                value={kouteiCalculateSelectedArr[1].value}
                disabled={!props?.editMode}
                onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[1].key)}
                checked={kouteiCalculateSelectedArr[1].key == updateEditedData.dataType}
                style={{ marginRight: 6 }}
              ></Checkbox>
              <label>{kouteiCalculateSelectedArr[1].value}</label>
            </div>
            <div style={{ display: 'flex', justifyContent: 'right', width: '40%' }}>
              <Button onClick={settingDataItems} className="dataButton" id="dataBtn1">
                {DataButton}
              </Button>
            </div>
          </Col>
          <Col span={1} className="oyabuhin-detail-col">
            単位
          </Col>
          <Col span={5} className="formula-column">
            計算式
          </Col>
        </Row>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            {processMst?.name}
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalAmtAuto">{totalSum ? JPYs.format(Math.round(totalSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalTimeAuto">{totalTimeSum ? secondsToHms(totalTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="totalAmtAutoIn"
              style={{
                color: Math.round(totalAmt) === Math.round(totalSum) ? 'black' : '#4cc12f',
              }}
            >
              {totalAmt ? JPYs.format(Math.round(totalAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="totalTimeAutoIn"
              style={{
                color: Math.round(totalTime) === Math.round(totalTimeSum) ? 'black' : '#4cc12f',
              }}
            >
              {totalTime ? secondsToHms(totalTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={2} className="formula-column formula-column-right-border">
            <Popover content={helpCmt.procTotalAmt}>金額</Popover>
          </Col>
          <Col span={2} className="formula-column">
            <Popover content={helpCmt.procTotalTm}>時間</Popover>
          </Col>
        </Row>
        {(() => {
          const arr = [];
          for (let j = 0; j < processArr?.length; j++) {
            arr.push(
              <div>
                <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                  <Col span={4} className="oyabuhin-detail-col">
                    <span>段取金額/時間</span>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    <label id={j + 'autoBDandoriAmt'}>
                      {processArr[0]?.dandoriAmt ? JPYs.format(Math.round(processArr[0]?.dandoriAmt)) : amtEmptyStr}
                    </label>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    <label id={j + 'autoBDandoriTime'}>
                      {processArr[0]?.dandoriTime ? secondsToHms(processArr[0]?.dandoriTime) : timeHmsStr}
                    </label>
                  </Col>
                  <Col span={4} className="input-col">
                    <CurrencyInput
                      id={j + 'autoBDandoriAmtIn'}
                      value={editedData[j]?.dandoriAmt ? Math.round(editedData[j]?.dandoriAmt) : 0}
                      defaultValue={0}
                      onValueChange={(e) => {
                        //checkRegex(e.target.value);
                        if (editedData[j]?.dandoriAmt != e) {
                          let value = e ? parseInt(e) : 0;
                          settingNewDandoriAmtInArr(value, j, 'dandoriAmt', '', '');
                          checkInputWithColor(false, '', j + 'autoBDandoriAmt', value, 'amt');
                        }
                        // checkInput(j + 'autoBDandoriAmt', e);
                      }}
                      /* onKeyPress={(e) => {
                                  if (isNumber(e.key)) {
                                    checkInputWithColor(false,'',j + 'autoBDandoriAmt', e, 'amt');
                                  }
                                }} */
                      style={{
                        border: 'none',
                        color:
                          parseFloat(processArr[j]?.dandoriAmt) === parseFloat(editedData[j]?.dandoriAmt)
                            ? 'black'
                            : checkShowHideIcon(j + 'autoBDandoriAmtCu') === 'none'
                            ? '#4cc12f'
                            : 'red',
                      }}
                      className={
                        editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                      }
                      prefix="¥"
                      decimalsLimit={100}
                    />
                    <div id={j + 'autoBDandoriAmtCu'} style={{ display: 'none' }}>
                      <div className="currencyIcon">
                        <BsUsbC style={{ fontSize: '25' }} />
                        <BsX style={{ margin: '-8px 0 0 16px' }} />
                        <span class="tooltiptext">
                          金額に変更がありましたので、以降時間と連携して自動計算されない。
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col span={4} className="input-col">
                    <Row style={{ width: '100%' }}>
                      <Col className="timer-input-col">
                        <Input
                          value={editedData[j]?.dandoriTime ? secondsToHms(editedData[j]?.dandoriTime) : timeHmsStr}
                          id={j + 'autoBDandoriTimeIn'}
                          style={{
                            width: '100%',
                            color:
                              processArr[j]?.dandoriTime === editedData[j]?.dandoriTime
                                ? handleShowHide(
                                    parseFloat(editedData[j]?.dandoriAmt),
                                    processArr[j]?.dandoriAmt,
                                    j + 'autoBDandoriAmtCu'
                                  )
                                : 'red',
                          }}
                          className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                          onChange={(e) => {
                            //disableTimer('test');
                            settingNewDandoriAmtInArr(e.target.value, j, 'dandoriTime', 'input', 'input');
                          }}
                        ></Input>
                      </Col>
                      <Col className="timer-button-col">
                        {editMode ? (
                          <>
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[j]?.dandoriTime,
                                  '10min',
                                  j + 'autoBDandoriTime'
                                );
                                settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'inc');
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[j]?.dandoriTime,
                                  '10min',
                                  j + 'autoBDandoriTime'
                                );
                                settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'dec');
                              }}
                            />
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[j]?.dandoriTime,
                                  'min',
                                  j + 'autoBDandoriTime'
                                );
                                settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'inc');
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[j]?.dandoriTime,
                                  'min',
                                  j + 'autoBDandoriTime'
                                );
                                settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'dec');
                              }}
                            />
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[j]?.dandoriTime,
                                  'sec',
                                  j + 'autoBDandoriTime'
                                );
                                settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'inc');
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[j]?.dandoriTime,
                                  'sec',
                                  j + 'autoBDandoriTime'
                                );
                                settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'dec');
                              }}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={1} className="oyabuhin-detail-col"></Col>
                  <Col span={2} className="formula-column formula-column-right-border">
                    <Popover content={helpCmt.setupAmt_p1}>金額</Popover>
                  </Col>
                  <Col span={2} className="formula-column">
                    <Popover content={helpCmt.setupTm_p6}>時間</Popover>
                  </Col>
                </Row>
                <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                  <Col span={4} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('AutoB' + j)}>
                    <CaretUpOutlined id={'arrowUpAutoB' + j} style={{ fontSize: 17, marginTop: 2, display: 'none' }} />
                    <CaretDownOutlined id={'arrowDownAutoB' + j} style={{ fontSize: 17, marginTop: 2 }} />
                    <span style={{ marginLeft: '5px' }}>加工金額/時間</span>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    <label id={j + 'autoBWorkAmt'}>
                      {sagyoAmtSum ? JPYs.format(Math.round(sagyoAmtSum)) : amtEmptyStr}
                    </label>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    <label id={j + 'autoBWorkTime'}>{sagyoTimeSum ? secondsToHms(sagyoTimeSum) : timeHmsStr}</label>
                  </Col>
                  <Col span={4} className="oyabuhin-detail-col">
                    <label id="totalSagyoAmtIn" style={{ color: sagyoAmtSum == totalSagyoAmt ? 'black' : '#4cc12f' }}>
                      {totalSagyoAmt ? JPYs.format(Math.round(totalSagyoAmt)) : amtEmptyStr}
                    </label>
                  </Col>
                  <Col span={4} className="oyabuhin-detail-col">
                    <label
                      id="totalSagyoTimeIn"
                      style={{ color: sagyoTimeSum == totalSagyoTime ? 'black' : '#4cc12f' }}
                    >
                      {totalSagyoTime ? secondsToHms(totalSagyoTime) : timeHmsStr}
                    </label>
                  </Col>
                  <Col span={1} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}></Col>
                  <Col
                    span={2}
                    className="formula-column formula-column-right-border"
                    onClick={(e) => subpartDetail(i)}
                  >
                    <Popover content={helpCmt.workAmt_p2}>金額</Popover>
                  </Col>
                  <Col span={2} className="formula-column" onClick={(e) => subpartDetail(i)}>
                    <Popover content={helpCmt.workTm_bari_auto}>時間</Popover>
                  </Col>
                </Row>
                <div id={'divAutoB' + j} style={{ display: 'none' }}>
                  <Row
                    style={{
                      color: 'black' /* , display: processArr[j]?.materialHandlingAmt == 0 ? 'none' : ''  */,
                    }}
                    className="oyabuhin-detail oyabuhin-row"
                    onClick={(e) => handleCollpse('Kakou')}
                  >
                    <Col span={4} className="oyabuhin-detail-col">
                      <div style={{ display: 'flex' }} className="ddl_2_noImg">
                        <span>加工金額/時間</span>
                      </div>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      <label id={j + 'autoBWorkAmt'}>
                        {processArr[j]?.sagyoAmt ? JPYs.format(Math.round(processArr[j]?.sagyoAmt)) : amtEmptyStr}
                      </label>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      <label id={j + 'autoBWorkTime'}>
                        {processArr[j]?.sagyoTime ? secondsToHms(processArr[j]?.sagyoTime) : timeHmsStr}
                      </label>
                    </Col>
                    <Col span={4} className="input-col">
                      <CurrencyInput
                        id={j + 'autoBWorkAmtIn'}
                        value={editedData[j]?.sagyoAmt ? Math.round(editedData[j]?.sagyoAmt) : 0}
                        defaultValue={0}
                        onValueChange={(e) => {
                          if (editedData[j]?.sagyoAmt != e) {
                            let value = e ? parseInt(e) : 0;
                            settingNewDandoriAmtInArr(value, j, 'sagyoAmt', '', '');
                            checkInputWithColor(false, '', j + 'autoBWorkAmt', value, 'amt');
                          }
                        }}
                        style={{
                          border: 'none',
                          color:
                            processArr[j]?.sagyoAmt === editedData[j]?.sagyoAmt
                              ? 'black'
                              : checkShowHideIcon(j + 'autoBWorkAmtCu') === 'none'
                              ? '#4cc12f'
                              : 'red',
                        }}
                        className={
                          editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                        }
                        prefix="¥"
                        decimalsLimit={100}
                      />
                      <div id={j + 'autoBWorkAmtCu'} style={{ display: 'none' }}>
                        <div className="currencyIcon">
                          <BsUsbC style={{ fontSize: '25' }} />
                          <BsX style={{ margin: '-8px 0 0 16px' }} />
                          <span class="tooltiptext">
                            金額に変更がありましたので、以降時間と連携して自動計算されない。
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col span={4} className="input-col">
                      <Row style={{ width: '100%' }}>
                        <Col className="timer-input-col">
                          <Input
                            value={editedData[j]?.sagyoTime ? secondsToHms(editedData[j]?.sagyoTime) : timeHmsStr}
                            id={j + 'autoBWorkTimeIn'}
                            style={{
                              width: '100%',
                              color:
                                processArr[j]?.sagyoTime === editedData[j]?.sagyoTime
                                  ? handleShowHide(
                                      parseFloat(processArr[j]?.sagyoAmt),
                                      editedData[j]?.sagyoAmt,
                                      j + 'autoBWorkAmtCu'
                                    )
                                  : 'red',
                            }}
                            className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                            onChange={(e) => {
                              //disableTimer('test');
                              settingNewDandoriAmtInArr(e.target.value, j, 'sagyoTime', 'input', 'input');
                            }}
                          ></Input>
                        </Col>
                        <Col className="timer-button-col">
                          {editMode ? (
                            <>
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[j]?.sagyoTime,
                                    '10min',
                                    j + 'autoBWorkTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', '10min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[j]?.sagyoTime,
                                    '10min',
                                    j + 'autoBWorkTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', '10min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[j]?.sagyoTime,
                                    'min',
                                    j + 'autoBWorkTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[j]?.sagyoTime,
                                    'min',
                                    j + 'autoBWorkTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[j]?.sagyoTime,
                                    'sec',
                                    j + 'autoBWorkTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'sec', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[j]?.sagyoTime,
                                    'sec',
                                    j + 'autoBWorkTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'sec', 'dec');
                                }}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={1} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}></Col>
                    <Col span={5} className="formula-column" onClick={(e) => subpartDetail(i)}></Col>
                  </Row>
                  {/*  加工面積 */}
                  <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row ">
                    <Col span={4} className="oyabuhin-detail-col">
                      <span style={{ marginLeft: 24 }}>加工面積</span>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      <label id="proArea">{processArr[j]?.area}</label>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col"></Col>

                    <Col span={4} className="input-col">
                      <Input
                        value={editedData[j]?.area}
                        id="proAreaIn"
                        className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                        style={{
                          width: '45%',
                          border: 'none',
                          color: editedData[j]?.area === processArr[j]?.area ? 'black' : 'red',
                        }}
                        onKeyPress={(e) => {
                          handleKeyPress(e, false);
                        }}
                        onChange={(e) =>
                          settingNewDandoriAmtInArr(e.target.value ? e.target.value : 0, 0, 'area', '', '')
                        }
                        onBlur={(e) => {
                          toTwoDecimal(j, 'area');
                        }}
                      />
                    </Col>

                    <Col span={4} className="input-col"></Col>
                    <Col span={1} className="oyabuhin-detail-col"></Col>
                    <Col span={5} className="formula-column"></Col>
                  </Row>
                </div>
              </div>
            );
          }
          return arr;
        })()}
      </div>
    </div>
  );
});

export default IQ3KouteiCalculate_AutoBari;
