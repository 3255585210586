/**
 * クラス名：装置設定一覧画面
 * 作成者：チュー
 * 作成日：2023/06/01
 * バージョン：1.0
 */
import { Col, Input, Row, Select, Table, Button, Checkbox, Image } from 'antd';
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import SplitPane from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';
import { Table as RTable } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import { CaretDownOutlined, CaretUpOutlined, SearchOutlined } from '@ant-design/icons';
import { StatusCodes } from 'http-status-codes';

import {
  souchiHandleIncreaseTime,
  souchiHandleDecreaseTime,
  JPYs,
  getKubunNameDDL,
  actionArr,
  createShiageFlagData,
  createJikanListData,
  updateJikanList,
  createJikanSizeListData,
  updateJikanSizeListData,
  updateProductSizeJikanList,
  createKeisuuListData,
  createSizeListData,
  createGasListData,
  createKakouSokudoListData,
  processTypes,
  getAccessToken,
  updateAccessToken,
  secondsToHms,
  setTimetoSec,
  dataStateList,
  isDigit,
  isDecimal,
  getProcessDetailTypesInfo,
  getPrepSettingDetailValues,
  getProcessDetailTypeName,
  getChargesDetailValues,
} from '../../../common/Common.js';
import DevicesDetails from './DevicesDetails';
import {
  confirmCreateContent,
  confirmHakiContent,
  confirmDiscardContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  confirmDeleteContent,
  authorizeError,
  error,
  commonDeleteFooter,
} from '../../../common/CommonModal';
import { Customer, pageSizes } from '../../../common/Constant';
import { ClientType, ServiceClass, WorkType } from '../../../common/enums.js';
import SearchAndGetCustomerData from '../../../common/SearchAndGetCustomerData.js';
import { createData } from './deviceDetailsModel/Weld_Detail.js';
import { createListData } from '../../../common/Common.js';
import {
  getClientInfo,
  getDeviceInfo,
  getProcessInfo,
  getMaterialTypeInfo,
  getDeviceInfoById,
  GetDevicesByProcessId,
} from '../../../common/CommonAPI.js';
import checked from '../../../../assets/images/checked.png';
import { ErrorMessage } from '../../../common/Message.js';
import sort_up from '../../../../assets/images/sort_up.png';
import sort_down from '../../../../assets/images/sort_down.png';
import { isNumber } from 'lodash';

// 溶接線長
const weldLineMasterData = [
  { id: 1, max: '0.80', displayFlag: true },
  { id: 2, max: '1.00', displayFlag: true },
  { id: 3, max: '1.20', displayFlag: true },
  { id: 4, max: '1.60', displayFlag: true },
  { id: 5, max: '2.30', displayFlag: true },
  { id: 6, max: '3.20', displayFlag: true },
  { id: 7, max: '4.50', displayFlag: true },
  { id: 8, max: '6.00', displayFlag: true },
  { id: 8, max: '99999', displayFlag: true },
];
// 曲げ個数
const mageKosuuData = [
  { id: 1, val: 1, displayFlag: true },
  { id: 2, val: 2, displayFlag: true },
];
// iq3板厚
const iq3ItaatsuData = [
  { id: 1, max: '0.80', displayFlag: true },
  { id: 2, max: '1.00', displayFlag: true },
  { id: 3, max: '1.20', displayFlag: true },
  { id: 4, max: '1.60', displayFlag: true },
  { id: 5, max: '2.30', displayFlag: true },
  { id: 6, max: '3.20', displayFlag: true },
  { id: 7, max: '4.50', displayFlag: true },
  { id: 8, max: '6.00', displayFlag: true },
  { id: 8, max: '99999', displayFlag: true },
];
// iq3ガス種類
const gasTypeData = [
  { id: 1, val: '酸素', displayFlag: true },
  { id: 2, val: '窒素', displayFlag: true },
  { id: 3, val: 'Easy', displayFlag: true },
  { id: 4, val: 'Air', displayFlag: true },
  { id: 5, val: 'その他', displayFlag: true },
];
const retainedMoldData = [
  {
    id: 1,
    val: '5.0 x 5.0',
    displayFlag: true,
  },
  {
    id: 2,
    val: '10.0 x 5.0',
    displayFlag: true,
  },
  {
    id: 7,
    val: '20.0 x 5.0',
    displayFlag: true,
  },
  {
    id: 6,
    val: '30.0 x 5.0',
    displayFlag: true,
  },
  {
    id: 5,
    val: '40.0 x 5.0',
    displayFlag: true,
  },
  {
    id: 4,
    val: '50.0 x 5.0',
    displayFlag: true,
  },
  {
    id: 3,
    val: '80.0 x 5.0',
    displayFlag: true,
  },
  {
    id: 8,
    val: '110.0 x 5.0',
    displayFlag: true,
  },
];
// ジョイントサイズ
const jointSizeData = [
  {
    id: 1,
    max: '100',
    displayFlag: true,
  },
  {
    id: 6,
    val: '',
    displayFlag: true,
    max: '400',
  },
  {
    id: 5,
    val: '',
    displayFlag: true,
    max: '800',
  },
  {
    id: 4,
    val: '',
    displayFlag: true,
    max: '1600',
  },
  {
    id: 3,
    val: '',
    displayFlag: true,
    max: '10000',
  },
  {
    id: 2,
    max: 99999,
    displayFlag: true,
  },
];
// iq3曲げ数
const mageSuuData = [
  { id: 1, val: 1, displayFlag: true },
  { id: 2, val: 2, displayFlag: true },
];
// 板厚
const itaatsuMasterData = [
  { id: 1, max: '0.80', displayFlag: true },
  { id: 2, max: '1.00', displayFlag: true },
  { id: 3, max: '1.20', displayFlag: true },
  { id: 4, max: '1.60', displayFlag: true },
  { id: 5, max: '2.30', displayFlag: true },
  { id: 6, max: '3.20', displayFlag: true },
  { id: 7, max: '4.50', displayFlag: true },
  { id: 8, max: '6.00', displayFlag: true },
  { id: 8, max: '99999', displayFlag: true },
];
// パンチ条件
const punchData = [
  { key: 1, name: '1パンチ丸穴径', val: 0 },
  { key: 2, name: '1パンチ矩形周長', val: 0 },
  { key: 3, name: '二ブリングピッチ', val: 0 },
];
// 材料配置

// 板厚(溶接難易度)
const yousetsuNanidoItaatsuMasterData = [
  { id: 1, max: '0.80', displayFlag: true },
  { id: 2, max: '1.00', displayFlag: true },
  { id: 3, max: '1.20', displayFlag: true },
  { id: 4, max: '1.60', displayFlag: true },
  { id: 5, max: '2.30', displayFlag: true },
  { id: 6, max: '3.20', displayFlag: true },
  { id: 7, max: '4.50', displayFlag: true },
  { id: 8, max: '6.00', displayFlag: true },
  { id: 8, max: '99999', displayFlag: true },
];
// 板厚(仮付き時間)
const karitsukiJikanItaatsuMasterData = [
  { id: 1, max: '0.80', displayFlag: true },
  { id: 2, max: '1.00', displayFlag: true },
  { id: 3, max: '1.20', displayFlag: true },
  { id: 4, max: '1.60', displayFlag: true },
  { id: 5, max: '2.30', displayFlag: true },
  { id: 6, max: '3.20', displayFlag: true },
  { id: 7, max: '4.50', displayFlag: true },
  { id: 8, max: '6.00', displayFlag: true },
  { id: 8, max: '99999', displayFlag: true },
];
// 製品重量係数
const weightMasterData = [
  { id: 1, max: 5, displayFlag: true },
  { id: 2, max: 10, displayFlag: true },
  { id: 3, max: 20, displayFlag: true },
  { id: 4, max: 30, displayFlag: true },
  { id: 5, max: 50, displayFlag: true },
  { id: 6, max: 999, displayFlag: true },
];
const taisekiMasterData = [
  { id: 1, max: '100.00', displayFlag: true },
  { id: 2, max: '400.00', displayFlag: true },
  { id: 3, max: '800.00', displayFlag: true },
  { id: 4, max: '1200.00', displayFlag: true },
  { id: 5, max: '1600.00', displayFlag: true },
  { id: 6, max: '2400.00', displayFlag: true },
  { id: 7, max: '10000.00', displayFlag: true },
  { id: 8, max: '99999.99', displayFlag: true },
];
const areaMasterData = [
  { id: 1, max: '100.00', displayFlag: true },
  { id: 2, max: '400.00', displayFlag: true },
  { id: 3, max: '800.00', displayFlag: true },
  { id: 4, max: '1200.00', displayFlag: true },
  { id: 5, max: '1600.00', displayFlag: true },
  { id: 6, max: '2400.00', displayFlag: true },
  { id: 7, max: '10000.00', displayFlag: true },
  { id: 8, max: '99999.99', displayFlag: true },
];
const iq3areaMasterData = [
  { id: 1, max: '100.00', displayFlag: true },
  { id: 2, max: '400.00', displayFlag: true },
  { id: 3, max: '800.00', displayFlag: true },
  { id: 4, max: '1200.00', displayFlag: true },
  { id: 5, max: '1600.00', displayFlag: true },
  { id: 6, max: '2400.00', displayFlag: true },
  { id: 7, max: '10000.00', displayFlag: true },
  { id: 8, max: '99999.99', displayFlag: true },
];
// 加工長
const kakouchouMasterData = [
  { id: 1, max: 30, displayFlag: true },
  { id: 2, max: 99999, displayFlag: true },
];
const iq3SizeMasterData = [
  { id: 1, max: 4, displayFlag: true },
  { id: 2, max: 6, displayFlag: true },
  { id: 3, max: 10, displayFlag: true },
  { id: 4, max: 999, displayFlag: true },
];
const iq3mmMasterData = [
  { id: 1, max: 5, displayFlag: true },
  { id: 2, max: 10, displayFlag: true },
  { id: 3, max: 20, displayFlag: true },
  { id: 4, max: 30, displayFlag: true },
  { id: 5, max: 999, displayFlag: true },
];
const lengthMasterData = [
  { id: 1, max: '100.00', displayFlag: true },
  { id: 2, max: '400.00', displayFlag: true },
  { id: 3, max: '800.00', displayFlag: true },
  { id: 4, max: '1200.00', displayFlag: true },
  { id: 5, max: '1600.00', displayFlag: true },
  { id: 6, max: '2400.00', displayFlag: true },
  { id: 7, max: '10000.00', displayFlag: true },
  { id: 8, max: '99999.99', displayFlag: true },
];
// 取付時間のサイズ
const sizeMasterData = [
  { id: 1, max: 4, displayFlag: true },
  { id: 2, max: 6, displayFlag: true },
  { id: 3, max: 8, displayFlag: true },
  { id: 4, max: 10, displayFlag: true },
  { id: 5, max: 12, displayFlag: true },
  { id: 6, max: 999, displayFlag: true },
];
// 生産個数係数
const quantityMasterData = [
  { id: 1, val: 1, displayFlag: true },
  { id: 2, val: 2, displayFlag: true },
  { id: 3, val: 3, displayFlag: true },
];
// 数量区分
const suuryouKubunMasterData = [
  { id: 1, max: 10, displayFlag: true },
  { id: 2, max: 25, displayFlag: true },
  { id: 3, max: 50, displayFlag: true },
  { id: 4, max: 100, displayFlag: true },
  { id: 5, max: 150, displayFlag: true },
  { id: 6, max: 200, displayFlag: true },
  { id: 7, max: 250, displayFlag: true },
  { id: 8, max: 99999, displayFlag: true },
];
// 要素数
const yousosuuMasterData = [
  { id: 1, max: 10, displayFlag: true },
  { id: 2, max: 25, displayFlag: true },
  { id: 3, max: 50, displayFlag: true },
  { id: 4, max: 100, displayFlag: true },
  { id: 5, max: 150, displayFlag: true },
  { id: 6, max: 200, displayFlag: true },
  { id: 7, max: 250, displayFlag: true },
  { id: 8, max: 99999, displayFlag: true },
];
// 名称
const meishouMasterData = [
  { id: 1, name: '極粗級', displayFlag: true },
  { id: 2, name: '粗級', displayFlag: true },
  { id: 3, name: '中級', displayFlag: true },
  { id: 4, name: '精級', displayFlag: true },
];
// iq3生産個数
const iq3SeisanKosuuMasterData = [
  { id: 1, val: 1, displayFlag: true },
  { id: 2, val: 2, displayFlag: true },
  { id: 3, val: 5, displayFlag: true },
  { id: 4, val: 10, displayFlag: true },
  { id: 5, val: 50, displayFlag: true },
  { id: 6, val: 100, displayFlag: true },
  { id: 7, val: 250, displayFlag: true },
  { id: 8, val: 251, displayFlag: true },
];
// iq3本数
const iq3honsuuMasterData = [
  { id: 1, val: 3, displayFlag: true },
  { id: 2, val: 5, displayFlag: true },
  { id: 3, val: 8, displayFlag: true },
  { id: 4, val: 10, displayFlag: true },
  { id: 5, val: 11, displayFlag: true },
];

// 数量区分
const keisuu = ['係数'];
// 数量
const suuryouTypes = ['子部品点数', '子部品種類数'];
// デフォルト値
const defaultCharge = 4200;
// 時間チャージ
// const jikanchaji = [
//   {
//     id: 1,
//     val: defaultCharge,
//   },
//   {
//     id: 2,
//     val: defaultCharge,
//   },
//   {
//     id: 3,
//     val: defaultCharge,
//   },
// ];

// 子部品数係数（検査と梱包）
// const kobuhinData = [
//   {
//     key: 1,
//     name: '子部品点数',
//     lstData: [
//       {
//         key: 1,
//         name: 'iQ3',
//         val: '',
//       },
//       {
//         key: 2,
//         name: 'iQ5',
//         val: '',
//       },
//       {
//         key: 3,
//         name: 'iQ7',
//         val: '',
//       },
//     ],
//   },
//   {
//     key: 2,
//     name: '子部品種類数',
//     lstData: [
//       {
//         key: 1,
//         name: 'iQ3',
//         val: '',
//       },
//       {
//         key: 2,
//         name: 'iQ5',
//         val: '',
//       },
//       {
//         key: 3,
//         name: 'iQ7',
//         val: '',
//       },
//     ],
//   },
// ];

// デフォルトページの表示件数
const defaultPageSize = 25;

// ReactEnvの情報
const envProperties = process.env;

const DevicesList = forwardRef((props, ref) => {
  //   Content Resize
  const [sizes, setSizes] = useState(['45%']);

  // const [tblColumns, setTblColumns] = useState([]);
  const navigate = useNavigate();
  // 編集モード
  const [editMode, setEditMode] = useState(false);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [clickedAction, setClickedAction] = useState(0);

  const [currentRecordKey, setCurrentRecordKey] = useState(0);
  const [currentRecord, setCurrentRecord] = useState([]);

  // 新規modal
  const [isNew, setIsNew] = useState(false);
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [isDiscardData, setIsDiscardData] = useState(false);

  // 削除Modal
  const [isDelete, setIsDelete] = useState(false);

  // 新規作成
  const [category, setCategory] = useState(processTypes.standard);

  const [kouteiType, setKouteiType] = useState('');

  const [kouteiId, setKouteiId] = useState('');
  const [kouteiCode, setKouteiCode] = useState('');
  const [kouteiName, setKouteiName] = useState('');
  const [kakouHouhou, setKakouHouhou] = useState([]);
  const [kakouId, setKakouId] = useState();
  const [kakouName, setKakouName] = useState([]);
  const [kakouCode, setKakouCode] = useState([]);
  const [souchiCode, setSouchiCode] = useState('');
  const [souchiName, setSouchiName] = useState('');
  // 段取時間チャージ金額
  const [prep, setPrep] = useState(0);
  // 作業時間チャージ金額
  const [work, setWork] = useState(0);

  // 追加工程種類
  const [addProcessTypeId, setAddProcessTypeId] = useState('');
  // const [tsuikaKouteiCode, setTsuikaKouteiCode] = useState('');
  const [addProcessTypes, setAddProcessTypes] = useState([]);

  // 初期金額(手入力時)
  const [initPrice, setInitPrice] = useState(0);

  const [selectedData, setSelectedData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [initialTblItems, setInitialTableItems] = useState([]); // 初期表示に表示する項目
  const [initialTblItemsBk, setInitialTableItemsBk] = useState([]); // 初期表示に表示する項目
  const [tmpFilteredData, setTmpFilteredData] = useState();

  // 検索
  const [process, setProcess] = useState('');

  // 委託工程
  const [isCommission, setIsCommission] = useState(false);
  const [selectDisabled, setSelectDisabled] = useState(true);

  // 手入力フラグ
  const [isManualInput, setIsManualInput] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(true);

  const [customerModal, setCustomerModal] = useState(false);

  // 複写
  const [copyDeviceList, setCopyDeviceList] = useState([]);
  const [copyDeviceId, setCopyDeviceId] = useState('');

  // 取引先情報
  const [customerId, setCustomerId] = useState(0);
  const [customerName, setCustomerName] = useState('');
  const [customerCode, setCustomerCode] = useState('');

  // 備考
  const [remarks, setRemarks] = useState('');

  const [flag, setFlag] = useState(false);

  const [weldLineMaster, setWeldLineMaster] = useState([]);
  const [areaMaster, setAreaMaster] = useState([]);
  const [weightMaster, setWeightMaster] = useState([]);
  const [volumeMaster, setVolumeMaster] = useState([]);

  const [suuryouKubunMaster, setSuuryouKubunMaster] = useState([]);
  // 要素数
  const [yousosuuMaster, setYousosuuMaster] = useState([]);

  // 溶接難易度
  const [yousetsuNanidoItaatsuMaster, setYousetsuNanidoItaatsuMaster] = useState([]);
  // 仮付き時間
  const [karitsukiJikanItaatsuMaster, setKaritsukiJikanItaatsuMaster] = useState([]);

  // 取付き時間
  const [toritsukiJikanItaatsuMaster, setToritsukiJikanItaatsuMaster] = useState([]);
  const [toritsukiJikanSizeMaster, setToritsukiJikanSizeMaster] = useState([]);

  // 加工速度（板厚）
  const [kakouSokudoItaatsuMaster, setKakouSokudoItaatsuMaster] = useState([]);

  // 名称
  const [meishouMaster, setMeishouMasterData] = useState([]);

  // 個数
  const [kosuuMaster, setKosuuMaster] = useState([]);

  // 段取時間
  const [prepBasicTime, setPrepBasicTime] = useState(0);
  // 作業時間(1箇所当たり)
  const [workBasicTime, setWorkBasicTime] = useState(0);

  const [kouteiMaster, setKouteiMaster] = useState([]);

  const [yousetsuSenChouMasterData, setYousetsuSenChouMasterData] = useState([]);
  const [yousetsuToritsukiSizeMasterData, setYousetsuToritsukiSizeMasterData] = useState([]);
  const [yousetsuToritsukiItaatsuMasterData, setYousetsuToritsukiItaatsuMasterData] = useState([]);
  const [yousetsuTypeMasterData, setYousetsuTypeMasterData] = useState([]);

  // 材質区分
  const [zaishitsuKubunn, setZaishitsuKubunn] = useState([]);
  const [customers, setCustomers] = useState([]);

  // 絞込
  const [koteimei, setKoteimei] = useState();
  const [device, setDevice] = useState('');
  const [nameKeyword, setNameKeyWord] = useState('');
  const [dataState, setDataState] = useState(1);

  // iq3
  const [iq3Itaatsu, setIQ3Itaatsu] = useState([]);
  // ガス種類
  const [gasType, setGasType] = useState([]);

  const [retainedMold, setRetrainedMold] = useState([]);
  // ジョイントサイズ
  const [jointSize, setJointSize] = useState([]);
  // 曲げ長さ係数
  const [lengthMaster, setLengthMaster] = useState([]);
  // 曲げ数
  const [mageSuuMaster, setMageSuuMaster] = useState([]);
  // 曲げ個数
  const [mageKosuuMaster, setMageKosuuMaster] = useState([]);
  // 面積
  const [iq3AreaMaster, setIQ3AreaMaster] = useState([]);
  // 加工長
  const [iq3Kakouchou, setIQ3Kakouchou] = useState([]);
  // 生産個数
  const [seisanKosuuMaster, setSeisanKosuuMaster] = useState([]);
  // 本数
  const [honsuuMaster, setHonsuuMaster] = useState([]);
  // サイズ
  const [iq3SizeMaster, setIQ3SizeMaster] = useState([]);
  const [mmMaster, setMmMaster] = useState([]);
  const [itaatsu1Master, setItaatsu1Master] = useState([]);
  // ２次加工の種類
  const [secondProcessTypes, setSecondProcessTypes] = useState([]);

  // 取引先情報リスト
  const [clientInfos, setClientInfos] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [showEdtConfirmOK, setShowEdtConfirmOK] = useState();
  const [showEdtConfirmCancle, setShowEdtConfirmCancle] = useState();

  // pagination
  const [pageSize, setPageSize] = useState(100);
  const [totalCount, setTotalCount] = useState(0);
  const [ascSortFlg, setAscSortFlg] = useState('ASC');
  const [sortNm, setSortNm] = useState('id');
  const [totalCountBK, setTotalCountBK] = useState(0);
  const [filterData, setFilterData] = useState({
    processNm: '',
    deviceNm: '',
    isUsedFlg: 1,
  });
  const [tblLoading, setTblLoading] = useState(false);
  const [isFilterFlg, setIsFilterFlg] = useState(false);

  const handleDropdown = () => {
    setFlag(true);
  };

  const location = useLocation();
  const [path, setPath] = useState('');

  const currentRoute = location.pathname;

  const inputItemRef = {};

  useEffect(() => {
    getDeviceMstData();
  }, [currentRoute]);

  useEffect(() => {
    if (isFilterFlg) {
      // 絞り込み、
      getDataByFilter();
    }
  }, [nameKeyword, koteimei, dataState, tmpFilteredData]);

  useEffect(() => {
    if (props.showConfirmMsgs !== undefined) {
      setShowEdtConfirmOK(props.showConfirmMsgs?.editOk);
      setShowEdtConfirmCancle(props.showConfirmMsgs?.editCancel);
    } else {
      setShowEdtConfirmOK(true);
      setShowEdtConfirmCancle(true);
    }
  }, [props.showConfirmMsgs]);

  // 装置マスタ情報を取得する
  const getDeviceMstData = async () => {
    // let processMstData = await getProcessMstData(); // 工程マスタ情報を取得する
    // await getProcessMstData();
    props?.loading(true); // Waitingダイアログを表示にする
    setTblLoading(true); // Waitingダイアログを表示にする
    await getMaterialTypeData();
    await getClientInfoAPI();
    let currentClass = currentRoute?.includes('parent') ? ServiceClass.Parent : ServiceClass.SheetMetal;
    let pageBefore =
      (props.initialParam === 'oya_chargeProcess' || props.initialParam === 'iq3_chargeProcess') &&
      props?.rowSelectPage !== undefined &&
      props?.rowSelectPage !== 0
        ? props?.rowSelectPage - 1
        : 0;
    setCurrentPage(pageBefore === 0 ? 1 : props?.rowSelectPage);
    let propSortFlg =
      (props.initialParam === 'oya_chargeProcess' || props.initialParam === 'iq3_chargeProcess') &&
      props?.ascSortFlg !== undefined
        ? props?.ascSortFlg
        : ascSortFlg;
    let propSortNm =
      (props.initialParam === 'oya_chargeProcess' || props.initialParam === 'iq3_chargeProcess') &&
      props?.sortNm !== undefined
        ? props?.sortNm
        : sortNm;
    let propFilterDatas =
      (props.initialParam === 'oya_chargeProcess' || props.initialParam === 'iq3_chargeProcess') &&
      props?.filterDatas !== undefined
        ? props?.filterDatas
        : {
            processNm: '',
            deviceNm: '',
            isUsedFlg: 1,
          };
    let deviceMstData = await getDeviceInfo(
      pageSize,
      pageBefore,
      currentClass,
      propSortFlg,
      propSortNm,
      propFilterDatas
    ); // 装置マスタ情報を取得する
    let deviceLst = [];
    if (deviceMstData) {
      deviceLst = deviceMstData?.data ? deviceMstData?.data : [];
      setTotalCount(deviceMstData?.totalCount ? deviceMstData?.totalCount : 0);
    }
    setTblLoading(false); // Waitingダイアログを表示にする
    // let kosuu = [];
    // // 個数
    // kosuu =
    //   localStorage.getItem('kosuuMasterData') != undefined ? JSON.parse(localStorage.getItem('kosuuMasterData')) : [];
    // const kosuuMaster1 = kosuu?.filter((subitem) => subitem.displayFlag === true);

    // setKosuuMaster(kosuuMaster1);

    // setKouteiMaster(processMstData);

    if (currentRoute?.includes('parent')) {
      setPath(':parent.device');
      navigate(':parent.device');

      const parentKouteiMst = await getProcessMstData(ServiceClass.Parent);
      setKouteiMaster(parentKouteiMst);
      // deviceLst = deviceMstData?.filter(
      //   (item) => parentKouteiMst?.findIndex((process) => process?.id === item?.processId) > -1
      // );

      // deviceLst =
      //   localStorage.getItem('parent_devicesMaster') != undefined
      //     ? JSON.parse(localStorage.getItem('parent_devicesMaster'))
      //     : [];
      // deviceLst = deviceLst?.filter((item) => item.type === '親部品');
      // 個数
      // if (deviceLst?.length > 0) {
      //   const kosuuUpdList = updateList2(
      //     deviceLst[deviceLst?.length - 1].kosuuMasterData,
      //     kosuuMaster1,
      //     deviceLst[deviceLst?.length - 1].kosuuLstData
      //   );

      //   deviceLst[deviceLst?.length - 1]['kosuuLstData'] = kosuuUpdList;
      //   deviceLst[deviceLst?.length - 1]['kosuuMasterData'] = kosuuMaster1;
      // }

      // setSelectedData(deviceLst?.length > 0 ? deviceLst[0] : []);
      // setSelectedRowKeys(deviceLst?.length > 0 ? deviceLst[0]?.id : 0);
      // setInitialTableItems(deviceLst);
      // setInitialTableItemsBk(deviceLst);

      // 取付Masterデータ（板厚とサイズ）
      // const yousetsuTypeMasterData = result
      //   ?.filter((i) => i.processType === '溶接')[0]
      //   .processDetail[0].processDetailValue?.map((i) => {
      //     if (i.displayFlg === true) {
      //       return i.detailType;
      //     }
      //   });

      // setYousetsuTypeMasterData(yousetsuTypeMasterData);
      // 取付Masterデータ（板厚とサイズ）
      // const yousetsuToritsukiItaatsuMasterData = result
      //   ?.filter((i) => i.processType === '溶接')[0]
      //   .processDetail[1].processDetailValue?.map((i) => {
      //     if (i.displayFlg === true) {
      //       return i.detailType;
      //     }
      //   });
      // setYousetsuToritsukiItaatsuMasterData(yousetsuToritsukiItaatsuMasterData);
      // const yousetsuToritsukiSizeMasterData = result
      //   ?.filter((i) => i.processType === '溶接')[0]
      //   .processDetail[2].processDetailValue?.map((i) => {
      //     if (i.displayFlg === true) {
      //       return i.detailType;
      //     }
      //   });
      // setYousetsuToritsukiSizeMasterData(yousetsuToritsukiSizeMasterData);
      // 溶接線長
      // const yousetsuSenChouMasterData = result
      //   ?.filter((i) => i.processType === '溶接仕上')[0]
      //   .processDetail[0].processDetailValue?.map((i) => {
      //     if (i.displayFlg === true) {
      //       return i.detailType;
      //     }
      //   });
      // setYousetsuSenChouMasterData(yousetsuSenChouMasterData);

      // const data = getKubunNameDDL();

      // const zaishitsu = data?.map((i) => {
      //   return i.label;
      // });
      // setZaishitsuKubunn(zaishitsu);
    } else {
      navigate(':iq3.device');
      const iq3KouteiMst = await getProcessMstData(ServiceClass.SheetMetal);
      setKouteiMaster(iq3KouteiMst);
      // const result = deviceMstData?.filter((i) => i.type === 'iQ3');
      // setKouteiMaster(result);
      // deviceLst =
      //   localStorage.getItem('iq3_devicesMaster') != undefined
      //     ? JSON.parse(localStorage.getItem('iq3_devicesMaster'))
      //     : [];

      // deviceLst = deviceLst?.filter((item) => item.type === 'iQ3');

      // deviceLst = deviceMstData?.filter(
      //   (item) => iq3KouteiMst?.findIndex((process) => process?.id === item?.processId) > -1
      // );

      // setSelectedData(deviceLst?.length > 0 ? deviceLst[0] : []);
      // setSelectedRowKeys(deviceLst?.length > 0 ? deviceLst[0]?.id : 0);
      // setInitialTableItems(deviceLst);
      // setInitialTableItemsBk(deviceLst);

      // setMageKosuuMaster(mageKosuuData);
      // setIQ3AreaMaster(iq3areaMasterData);
      // setSeisanKosuuMaster(iq3SeisanKosuuMasterData);
      // setHonsuuMaster(iq3honsuuMasterData);
      // setIQ3SizeMaster(iq3SizeMasterData);
      // setMmMaster(iq3mmMasterData);
      // setItaatsu1Master(yousetsuNanidoItaatsuMasterData);
      // setIQ3Kakouchou(kakouchouMasterData);
      // const data = getKubunNameDDL();
      // const zaishitsu = data?.map((i) => {
      //   return i.label;
      // });
      // setZaishitsuKubunn(zaishitsu);
      // 取付Masterデータ（板厚とサイズ）
      // const yousetsuTypeMasterData = result
      //   ?.filter((i) => i.processType === '溶接')[0]
      //   .processDetail[0].processDetailValue?.map((i) => {
      //     if (i.displayFlg === true) {
      //       return i.detailType;
      //     }
      //   });
      // setYousetsuTypeMasterData(yousetsuTypeMasterData);
      // 取付Masterデータ（板厚とサイズ）
      // const yousetsuToritsukiItaatsuMasterData = result
      //   ?.filter((i) => i.processType === '溶接')[0]
      //   .processDetail[1].processDetailValue?.map((i) => {
      //     if (i.displayFlg === true) {
      //       return i.detailType;
      //     }
      //   });
      // setYousetsuToritsukiItaatsuMasterData(yousetsuToritsukiItaatsuMasterData);
      // const yousetsuToritsukiSizeMasterData = result
      //   ?.filter((i) => i.processType === '溶接')[0]
      //   .processDetail[2].processDetailValue?.map((i) => {
      //     if (i.displayFlg === true) {
      //       return i.detailType;
      //     }
      //   });
      // setYousetsuToritsukiSizeMasterData(yousetsuToritsukiSizeMasterData);
      // 溶接線長
      // const yousetsuSenChouMasterData = result
      //   ?.filter((i) => i.processType === '溶接仕上')[0]
      //   .processDetail[0].processDetailValue?.map((i) => {
      //     if (i.displayFlg === true) {
      //       return i.detailType;
      //     }
      //   });
      // setYousetsuSenChouMasterData(yousetsuSenChouMasterData);

      setPath(':iq3.device');
    }
    if (deviceLst?.length > 0) {
      let initialInfo = deviceLst?.[0];
      // 前回選択された行がある場合
      if (
        (props.initialParam === 'oya_chargeProcess' || props.initialParam === 'iq3_chargeProcess') &&
        props.selectedRowId !== undefined &&
        props.selectedRowId !== 0
      ) {
        let selectedRowData = deviceLst?.filter((item) => item.id === props?.selectedRowId);
        if (selectedRowData?.length > 0) {
          initialInfo = await getDeviceInfoById(selectedRowData?.[0]?.id);
        }
        setKoteimei(propFilterDatas?.processNm);
        setNameKeyWord(propFilterDatas?.deviceNm);
        setDataState(propFilterDatas?.isUsedFlg);
        setFilterData(propFilterDatas);
      } else {
        initialInfo = await getDeviceInfoById(deviceLst[0]?.id);
        setKoteimei('全て');
        setNameKeyWord('');
        setDataState(1);
        setFilterData({
          processNm: '',
          deviceNm: '',
          isUsedFlg: 1,
        });
      }
      if (initialInfo?.length > 0) {
        setSelectedData(initialInfo?.length > 0 ? initialInfo[0] : []);
        setSelectedRowKeys(initialInfo?.length > 0 ? initialInfo[0]?.id : 0);
      }
    }
    setInitialTableItems(deviceLst);
    setInitialTableItemsBk(deviceLst);

    // setTblColumns(devicesColumns);

    setWeldLineMaster(weldLineMasterData);

    // 製品重量係数
    setAreaMaster(areaMasterData);
    setWeightMaster(weightMasterData);
    setVolumeMaster(taisekiMasterData); //体積

    setMeishouMasterData(meishouMasterData);
    setSuuryouKubunMaster(suuryouKubunMasterData);
    setYousosuuMaster(yousosuuMasterData);
    setYousetsuNanidoItaatsuMaster(yousetsuNanidoItaatsuMasterData);
    setKaritsukiJikanItaatsuMaster(karitsukiJikanItaatsuMasterData);
    setToritsukiJikanItaatsuMaster(karitsukiJikanItaatsuMasterData);

    setToritsukiJikanSizeMaster(sizeMasterData);
    setKakouSokudoItaatsuMaster(itaatsuMasterData);

    // iq3
    setIQ3Itaatsu(iq3ItaatsuData);
    setGasType(gasTypeData);
    setRetrainedMold(retainedMoldData);
    setJointSize(jointSizeData);
    setLengthMaster(lengthMasterData);
    setMageSuuMaster(mageSuuData);
    // setCurrentPage(1);

    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // 工程マスタ情報を取得する
  const getProcessMstData = async (classId) => {
    let processMstData = await getProcessInfo();
    processMstData = processMstData?.filter((process) => process?.class === classId);
    return processMstData;
  };

  // 工程マスタレコード情報をIDで取得する
  const getProcessMstRecordById = (id) => {
    return [...kouteiMaster]?.filter((record) => record?.id === id)?.[0];
  };
  // 追加工程種類名を得る
  const getAdditionalProcessInfo = (processId, id) => {
    let processMst = getProcessMstRecordById(processId);
    let data = processMst?.details?.processDetailTypes?.filter((subitem) => subitem.id === id)?.[0];

    return data;
  };

  // 工程マスタレコード情報をWorkTypeで取得する
  const getProcessMstRecordByWorkType = (workType = WorkType.SmWelding) => {
    return [...kouteiMaster]?.filter((record) => record?.workType === workType)?.[0];
  };

  // 材質区分マスタ情報を取得する
  const getMaterialTypeData = async () => {
    const materialTypeData = await getMaterialTypeInfo();
    setZaishitsuKubunn(materialTypeData);
    // getMaterialTypeInfo
  };

  const getClientInfoAPI = async () => {
    const result = await getClientInfo(0, 0, 'ASC', '', {});
    setCustomers(result);
  };
  // テーブルColumns
  const columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'no',
      width: '55px',
      className: 'cm-a-right',
      render: (id, record, index) => {
        let pageBefore = currentPage - 1;
        let startPage = pageBefore < 0 ? 0 : pageSize * pageBefore;
        return startPage + (index + 1); // IQX_WEBEST-240
      },
    },
    {
      key: 'processType',
      title: '種別',
      dataIndex: 'processId',
      width: '65px',
      render: (processId) =>
        getProcessMstRecordById(processId)?.isAdditional ? processTypes.additional : processTypes.standard,
      sorter: (a, b) => {
        setSortNm('processType');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'processcode',
      title: 'コード',
      dataIndex: 'processId',
      width: '85px',
      render: (processId) => getProcessMstRecordById(processId)?.code,
      sorter: (a, b) => {
        setSortNm('processCode');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'processName',
      title: '工程名',
      dataIndex: 'processId',
      width: '160px',
      render: (processId) => getProcessMstRecordById(processId)?.name,
      sorter: (a, b) => {
        setSortNm('processName');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'deviceCode',
      title: '装置コード',
      dataIndex: 'code',
      width: '85px',
      sorter: (a, b) => {
        setSortNm('deviceCode');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'deviceName',
      title: '装置名',
      dataIndex: 'name',
      width: '225px',
      sorter: (a, b) => {
        setSortNm('deviceName');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'remarks',
      title: '備考',
      dataIndex: 'info',
      render: (text, record, index) => <span>{record?.info?.remarks}</span>,
      width: 250,
      sorter: (a, b) => {
        setSortNm('remarks');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'isUsed',
      title: '表示',
      dataIndex: 'info',
      width: 95,
      render: (index, item) => {
        return item.info.isUsed ? (
          <Image preview={false} width={13} src={checked} style={{ marginLeft: '3px' }} id={item.id} />
        ) : (
          <></>
        );
      },
      className: 'cm-a-center',
      sorter: (a, b) => {
        setSortNm('isUsed');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
  ];

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

  const setSplitPaneSize = (splitSize) => {
    setSizes(splitSize);
  };

  // 編集モードを変更
  const updateEditMode = (mode) => {
    setEditMode(mode);
  };

  const showAddNewModal = async () => {
    setClickedAction(2);
    if (editMode) {
      // setEditModeCancelConfirm(true);
      //setEditModeCancelConfirm(showEdtConfirmCancle);
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        setIsNew(true);
        setKouteiType('');
        setKouteiCode('');
        setSouchiCode('');
        setSouchiName('');
        setCopyDeviceList([]);
        setCopyDeviceId('');
        setCategory(processTypes.standard);
        setAddProcessTypeId('');
        setIsManualInput(false);
        setIsCommission(false);
        setSelectDisabled(true);
        setInputDisabled(true);
        setPrepBasicTime(0);
        setWorkBasicTime(0);
        setPrep(0);
        setWork(0);
      }
    } else {
      let result = await getClientInfo(0, 0, 'ASC', '', {}); // 取引先情報を得る
      if (result) {
        result = result?.data;
        setClientInfos(result);
      }
      setIsNew(true);
      setKakouCode('');
      setKakouHouhou([]);
      setKakouId();
      setKakouName('');
      setKouteiType('');
      setKouteiCode('');
      setSouchiCode('');
      setSouchiName('');
      setCopyDeviceList([]);
      setCopyDeviceId('');
      setCategory(processTypes.standard);
      setAddProcessTypeId('');
      setIsManualInput(false);
      setIsCommission(false);
      setSelectDisabled(true);
      setInputDisabled(true);
      setInitPrice(0);
      setPrepBasicTime(0);
      setWorkBasicTime(0);
      setPrep(0);
      setWork(0);
      setKouteiName('');
      setKouteiId('');
    }
  };

  const showDeleteModal = () => {
    setClickedAction(3);
    if (editMode) {
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        discardConfirmOk();
      }
    } else {
      if (selectedData?.length != 0) {
        setIsDelete(true);
      } else {
        alert('データを選択してください');
      }
    }
  };

  const deleteModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>確認</p>
    </div>
  );

  const deleteOk = async () => {
    setIsDelete(false);
    if (!selectedData?.id) return;
    props?.loading(true); // Waitingダイアログを表示にする
    let deletedData = await deleteDeviceData(selectedData?.id);

    let updatedData = [...initialTblItemsBk];
    updatedData = updatedData?.filter((item) => item.id !== deletedData?.id);

    // for (let i = 0; i < updatedData?.length; i++) {
    //   updatedData[i].deviceNo = i + 1;
    // }

    // setInitialTableItems(updatedData);
    setTmpFilteredData(updatedData?.length === 0 ? [] : updatedData);
    setInitialTableItemsBk(updatedData?.length === 0 ? [] : updatedData);
    if (updatedData?.length === 0) {
      setInitialTableItems([]);
    }
    // setSelectedRowKeys(updatedData?.length > 0 ? updatedData[updatedData?.length - 1].deviceNo : 0);
    // setSelectedData(updatedData?.length > 0 ? updatedData[updatedData?.length - 1] : []);
    setSelectedRowKeys(updatedData?.length > 0 ? updatedData[0]?.id : 0);
    setSelectedData(updatedData?.length > 0 ? updatedData[0] : []);
    // if (currentRoute?.includes('parent')) {
    //   localStorage.setItem('parent_devicesMaster', JSON.stringify(updatedData));
    // } else {
    //   localStorage.setItem('iq3_devicesMaster', JSON.stringify(updatedData));
    // }
    props?.updateSelectedRowId(
      updatedData?.length > 0 ? updatedData[0]?.id : 0,
      0,
      currentPage,
      ascSortFlg,
      sortNm,
      filterData
    );
    setCurrentPage(1);
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // 装置マスタ情報をDBに削除する
  async function deleteDeviceData(id) {
    let ret = [];
    try {
      const Url = envProperties.REACT_APP_BACKEND_API_URL + 'Device/' + id;
      const otherParam = {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': envProperties.REACT_APP_API_KEY_AUTH,
        },
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  const deleteCancel = () => {
    setIsDelete(false);
  };

  useImperativeHandle(ref, () => ({
    setSplitPaneSize: setSplitPaneSize,
    showAddNewModal: showAddNewModal,
    showDeleteModal: showDeleteModal,
    getEditMode: editMode,
  }));

  // 新規作成一覧のヘッダー
  const addNewModalTitle = (
    <div
      style={{
        width: 600,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>新規作成</p>
    </div>
  );

  // 新規作成のOKボタン押下
  const addData = () => {
    setIsUpdateData(true);

    /* setIsUpdateData(showEdtConfirmCancle);
    if (!showEdtConfirmCancle) {
      updateDataOk
    } */
  };

  // 新規作成のCancelボタン押下
  const cancelData = () => {
    // setIsDiscardData(true);

    setIsDiscardData(showEdtConfirmCancle);
    if (!showEdtConfirmCancle) {
      discardOk();
    }
  };

  const createDetailsListData = (type, masterData, kubunTypes, itaatsuMasterData) => {
    const data = createJikanListData(kubunTypes, itaatsuMasterData);

    const result = type?.map((materialType, key) => {
      const filteredData2 = masterData
        ?.filter((subitem) => subitem.displayFlag === true)
        ?.map((item) => {
          return {
            key: item.id,
            value: '00:00:00',
            list: data,
          };
        });

      return {
        key: key + 1,
        materialType,
        lstData: filteredData2,
        isUsed: false,
      };
    });
    return result;
  };

  const createList = (masterData) => {
    const filteredData2 = masterData
      ?.filter((subitem) => subitem.displayFlag === true)
      ?.map((item) => {
        return {
          key: item.id,
          val: '',
        };
      });

    return filteredData2;
  };
  const createKosuuList = (masterData) => {
    let temp = masterData?.filter((i) => i.displayFlag === true);
    const lastObject = temp[temp?.length - 1];
    let valOfLastObject = lastObject.val + 1;
    let addData = { id: temp?.length + 1, val: valOfLastObject, displayFlag: true };

    const data = [...masterData, addData];

    const filteredData2 = data
      ?.filter((subitem) => subitem.displayFlag === true)
      ?.map((item) => {
        return {
          key: item.id,
          val: '',
        };
      });

    return filteredData2;
  };

  // 登録確認メッセージのOKボタン押下
  const updateDataOk = async () => {
    let createdData = {};
    setIsUpdateData(false);
    if (isNew == true) {
      // 画面の入力チェック
      if (!formValidation(inputItemRef)) {
        return;
      }
      setIsNew(false);
      props?.loading(true); // Waitingダイアログを表示にする
      const kouteiData = kouteiMaster?.filter((i) => i.id === kouteiId)?.[0];
      let createData = {};
      if (copyDeviceId) {
        // IQX_WEBEST-379 パラメーター親部品装置設定に表面処理工程の場合にコピー元装置で新規作成できません。
        let copyDataDetail = await getDeviceInfoById(copyDeviceId);
        if (copyDataDetail?.length > 0) {
          let copyData = copyDataDetail[0];
          copyData.processId = kouteiId;
          copyData.name = souchiName;
          copyData.code = souchiCode;
          if (kouteiData.isAdditional) {
            createData = copyData;
            if (createData) {
              createData.details.charges.prep = prep ? prep : copyData.details?.charges?.prep;
              createData.details.charges.work = work ? work : copyData.details?.charges?.work;
              createData.details.charges.chargeDetails = copyData.details?.charges?.chargeDetails;
              createData.details.clientId = customerId && customerId > 0 ? customerId : copyData.details?.clientId;
              createData.details.initPrice = initPrice ? initPrice : copyData.details?.initPrice;
              createData.details.addProcessTypeId = addProcessTypeId
                ? addProcessTypeId
                : copyData.details?.addProcessTypeId;
              createData.details.prepBasicTime = prepBasicTime ? prepBasicTime : copyData.details?.prepBasicTime;
              createData.details.workBasicTime = workBasicTime ? workBasicTime : copyData.details?.workBasicTime;
              createData.details.isCommission = isCommission ? isCommission : copyData.details?.isCommission;
              createData.details.isManualInput = isManualInput ? isManualInput : copyData.details?.isManualInput;
              createData.info.remarks = remarks ? remarks : copyData?.info?.remarks;
            }
          } else {
            createData = {
              processId: copyData.processId,
              code: copyData.code,
              name: copyData.name,
              details: copyData.details,
            };
          }
          createdData = await createDeviceData(createData);
        }
      } else {
        if (kouteiData.isAdditional) {
          createData = {
            processId: kouteiId,
            code: souchiCode,
            name: souchiName,
            details: {
              charges: { prep: prep === 0 ? 4200 : prep, work: work === 0 ? 4200 : work },
              clientId: customerId,
              initPrice: initPrice,
              addProcessTypeId: addProcessTypeId,
              prepBasicTime: prepBasicTime,
              workBasicTime: workBasicTime,
              isCommission: isCommission,
              isManualInput: isManualInput,
            },
            info: { remarks: remarks },
          };
        } else {
          createData = {
            processId: kouteiId,
            code: souchiCode,
            name: souchiName,
            details: {},
          };
        }
        createdData = await createDeviceData(createData);
      }
      let pageBefore = currentPage - 1;
      let currentClass = currentRoute?.includes('parent') ? ServiceClass.Parent : ServiceClass.SheetMetal;
      let deviceMstData = await getDeviceInfo(pageSize, pageBefore, currentClass, ascSortFlg, sortNm, filterData); // 装置マスタ情報を取得する
      let deviceLst = [];
      if (deviceMstData) {
        deviceLst = deviceMstData?.data ? deviceMstData?.data : [];
        setTotalCount(deviceMstData?.totalCount ? deviceMstData?.totalCount : 0);
        setInitialTableItemsBk(deviceLst);
        setTmpFilteredData(deviceLst);
        setInitialTableItems(deviceLst);
      }
      setSelectedData(createdData);
      // setCurrentPage(1);
      setSelectedRowKeys(createdData?.id);
      props?.updateSelectedRowId(createdData?.id, 0, 1, ascSortFlg, sortNm, filterData);
      props?.loading(false); // Waitingダイアログを非表示にする
    }
  };

  // 装置マスタ情報をDBに新規登録する
  async function createDeviceData(createData) {
    let ret = [];
    try {
      const Url = envProperties.REACT_APP_BACKEND_API_URL + 'Device';
      const otherParam = {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': envProperties.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify(createData),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  const updateDataCancel = () => {
    if (isNew == true) {
      setIsNew(true);
    }
    setIsUpdateData(false);
  };
  const discardOk = () => {
    if (isNew == true) {
      setIsNew(false);
    }
    setIsDiscardData(false);
  };
  const discardCancel = () => {
    if (isNew == true) {
      setIsNew(true);
    }
    setIsDiscardData(false);
  };
  const closeAddModal = () => {
    setIsNew(false);
  };
  const closeCustomerModal = () => {
    setCustomerModal(false);
  };
  // masterデータにより、リスト更新
  const updateList = (masterData, updatedMasterData, listData) => {
    // 削除したid取得
    const deletedId = [];
    masterData?.forEach((item1, index) => {
      const found = updatedMasterData?.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(index + 1);
      }
    });
    // 新規追加したid取得
    const newId = updatedMasterData
      ?.filter((item) => item.displayFlag && !masterData.some((data2Item) => data2Item.id === item.id))
      ?.map((item) => item.id);
    // リスト更新
    const deleteLstData = listData?.map((item) => {
      return {
        ...item,
        lstData: item.lstData?.filter((it) => !deletedId?.includes(it.key)),
      };
    });
    const updatedData = deleteLstData?.map((item) => {
      newId?.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });
    return updatedData;
  };

  const updateList2 = (masterData, updatedMasterData, listData) => {
    // 削除したid取得
    const deletedId = [];
    masterData?.forEach((item1, index) => {
      const found = updatedMasterData?.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });
    // 新規追加したid取得
    const newId = updatedMasterData
      ?.filter((item) => item.displayFlag && !masterData.some((data2Item) => data2Item.id === item.id))
      ?.map((item) => item.id);
    // リスト更新
    const deleteLstData = listData?.map((item) => {
      return {
        ...item,
        lstData: item.lstData?.filter((it) => !deletedId?.includes(it.key)),
      };
    });
    const updatedData = deleteLstData?.map((item) => {
      newId?.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });
    const sortedList = updatedData?.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = updatedMasterData?.findIndex((obj) => obj.id === a.key);
        const bIndex = updatedMasterData?.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
        isUsed: item.isUsed,
      };
    });
    return sortedList;
  };

  // const updateList3 = (masterData, updatedMasterData, listData) => {
  //   const list = [
  //     { key: 1, name: '丸穴径', svalue: '', mvalue: '', lvalue: '' },
  //     { key: 2, name: '加工周長', svalue: '', mvalue: '', lvalue: '' },
  //     { key: 3, name: '速度', svalue: '', mvalue: '', lvalue: '' },
  //   ];
  //   // 削除したid取得
  //   const deletedId = [];
  //   masterData?.forEach((item1, index) => {
  //     const found = updatedMasterData?.find((item2) => item2.id === item1.id && item2.displayFlag === false);
  //     if (found) {
  //       deletedId.push(item1.id);
  //     }
  //   });
  //   // 新規追加したid取得
  //   const newId = updatedMasterData
  //     ?.filter((item) => item.displayFlag && !masterData.some((data2Item) => data2Item.id === item.id))
  //     ?.map((item) => item.id);
  //   // リスト更新
  //   const deleteLstData = listData?.map((item) => {
  //     return {
  //       ...item,
  //       lstData: item.lstData?.filter((it) => !deletedId?.includes(it.key)),
  //     };
  //   });
  //   const updatedData = deleteLstData?.map((item) => {
  //     newId?.forEach((key) => {
  //       // Add new objects to lstData
  //       item.lstData.push({
  //         key: key,
  //         value: '',
  //         list: list,
  //       });
  //     });

  //     return item;
  //   });
  //   const sortedList = updatedData?.map((item) => {
  //     const sortedLstData = [...item.lstData];
  //     sortedLstData.sort((a, b) => {
  //       const aIndex = updatedMasterData?.findIndex((obj) => obj.id === a.key);
  //       const bIndex = updatedMasterData?.findIndex((obj) => obj.id === b.key);
  //       return aIndex - bIndex;
  //     });

  //     return {
  //       key: item.key,
  //       materialType: item.materialType,
  //       lstData: sortedLstData,
  //     };
  //   });
  //   return sortedList;
  // };
  const deleteListData = (list, deletedId) => {
    const deletedData = list?.map((item) => {
      return {
        ...item,
        lstData: item.lstData?.filter((it) => !deletedId?.includes(it.key)),
      };
    });
    return deletedData;
  };
  const updateListData = (deletedData, newId, detailsData) => {
    const updatedData = deletedData?.map((item) => {
      newId?.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '00:00:00',
          list: detailsData,
        });
      });

      return item;
    });
    return updatedData;
  };
  const sortListData = (list, masterData) => {
    const sortedList = list?.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = masterData?.findIndex((obj) => obj.id === a.key);
        const bIndex = masterData?.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
        isUsed: item.isUsed,
      };
    });
    return sortedList;
  };
  const deleteListData2 = (data, deletedId) => {
    const updatedData = [...data];

    updatedData?.forEach((item) => {
      item.lstData?.forEach((subitem) => {
        subitem.list?.forEach((listItem) => {
          if (listItem.lstData) {
            listItem.lstData = listItem.lstData?.filter((lstItem) => !deletedId?.includes(lstItem.key));
          }
        });
      });
    });

    return updatedData;
  };
  const updateListData2 = (deletedData, newId) => {
    const updatedData = JSON.parse(JSON.stringify(deletedData)); // Create a deep copy of the data array

    updatedData?.forEach((item) => {
      item.lstData?.forEach((i) => {
        i.list?.forEach((j) => {
          if (j.lstData) {
            newId?.forEach((key) => {
              // Add new objects to lstData
              j.lstData.push({
                key: key,
                value: '00:00:00',
              });
            });
          }
        });
      });
    });

    return updatedData;
  };
  const sortListData2 = (list, masterData) => {
    const sortedList = JSON.parse(JSON.stringify(list));

    sortedList?.forEach((item) => {
      item.lstData?.forEach((i) => {
        i.list?.forEach((j) => {
          if (j.lstData) {
            j.lstData.sort((a, b) => {
              const aIndex = masterData?.findIndex((obj) => obj.id === a.key);
              const bIndex = masterData?.findIndex((obj) => obj.id === b.key);
              return aIndex - bIndex;
            });
          }
        });
      });
    });

    return sortedList;
  };
  // ２次加工
  const updateItaatsuInsideKaniList = (itaatsu, itaatsuTemp, list) => {
    const deletedId = [];

    itaatsu?.forEach((item1, index) => {
      const found = itaatsuTemp?.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = itaatsuTemp
      ?.filter((item) => item.displayFlag && !itaatsu.some((data2Item) => data2Item.id === item.id))
      ?.map((item) => item.id);

    const deletedData = deleteListData2(list, deletedId);

    const updatedData = updateListData2(deletedData, newId);

    const sortedList = sortListData2(updatedData, itaatsuTemp);
    return sortedList;
  };

  const updateKaniSizeList = (
    zaishitsu,
    masterData,
    updatedMasterData,
    itaatsuMasterData,
    updItaatsuMasterData,
    listData
  ) => {
    const deletedId = [];

    masterData?.forEach((item1, index) => {
      const found = updatedMasterData?.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = updatedMasterData
      ?.filter((item) => item.displayFlag && !masterData.some((data2Item) => data2Item.id === item.id))
      ?.map((item) => item.id);

    const deletedData = deleteListData(listData, deletedId);

    // let detailsData = createListData(zaishitsu, itaatsuMasterData);
    let detailsData = zaishitsu?.map((materialType, key) => {
      const filteredData2 = itaatsuMasterData
        ?.filter((subitem) => subitem.displayFlag === true)
        ?.map((item) => {
          return {
            key: item.id,
            value: '',
          };
        });

      return {
        key: key + 1,
        materialType,
        lstData: filteredData2,
        isUsed: false,
      };
    });
    const updatedData = updateListData(deletedData, newId, detailsData);

    const sortedList = sortListData(updatedData, updatedMasterData);
    const updatedListData = updateItaatsuInsideKaniList(itaatsuMasterData, updItaatsuMasterData, sortedList);
    return updatedListData;
  };
  // const updateKaniList = (sizeMasterData, updSizeMasterData, itaatsuMasterData, updItaatsuMasterData, listData) => {
  //   const sizeupdated = updateList2(sizeMasterData, updSizeMasterData, listData);
  //   return sizeupdated;
  // };
  const updateProductSizeList = (masterData, updatedMasterData, listData) => {
    const deletedId = [];

    masterData?.forEach((item1, index) => {
      const found = updatedMasterData?.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(index + 1);
      }
    });

    const newId = updatedMasterData
      ?.filter((item) => item.displayFlag && !masterData.some((data2Item) => data2Item.id === item.id))
      ?.map((item) => item.id);

    const deleteData = listData?.filter((it) => !deletedId?.includes(it.key));

    const result = deleteData?.map((item) => {
      const list = updatedMasterData?.find((listItem) => listItem.id === item.key);

      return {
        ...item,
        max: list ? list.max : item.max,
        lstData: item.lstData?.map((lstItem) => {
          const newListData = {
            key: lstItem.key,
            value: lstItem.value,
          };

          return newListData;
        }),
      };
    });

    newId?.forEach((id) => {
      const list = updatedMasterData?.find((item) => item.id === id);

      if (list) {
        const newObj = {
          key: id,
          max: list.max,
          displayFlag: true,
          lstData: result[0].lstData?.map((lstItem) => {
            return {
              key: lstItem.key,
              value: '',
            };
          }),
        };

        result.push(newObj);
      }
    });
    return result;
  };

  // iq3製品重量係数
  const updateProductSizeList2 = (masterData, updatedMasterData, listData) => {
    const deletedId = [];

    masterData?.forEach((item1, index) => {
      const found = updatedMasterData?.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = updatedMasterData
      ?.filter((item) => item.displayFlag && !masterData?.some((data2Item) => data2Item.id === item.id))
      ?.map((item) => item.id);

    const deleteData = listData?.filter((it) => !deletedId?.includes(it.key));

    const result = deleteData?.map((item) => {
      const list = updatedMasterData?.find((listItem) => listItem.id === item.key);

      return {
        ...item,
        max: list ? list.max : item.max,
        lstData: item.lstData?.map((lstItem) => {
          const newListData = {
            key: lstItem.key,
            value: lstItem.value,
          };

          return newListData;
        }),
      };
    });

    newId?.forEach((id) => {
      const list = updatedMasterData?.find((item) => item.id === id);

      if (list) {
        const newObj = {
          key: id,
          max: list.max,
          displayFlag: true,
          lstData: result[0]?.lstData?.map((lstItem) => {
            return {
              key: lstItem.key,
              value: '',
            };
          }),
        };

        result?.push(newObj);
      }
    });
    result?.sort((a, b) => {
      const aIndex = updatedMasterData?.findIndex((obj) => obj.id === a.key);
      const bIndex = updatedMasterData?.findIndex((obj) => obj.id === b.key);
      return aIndex - bIndex;
    });
    return result;
  };

  const updateMeishouList = (masterData, updatedMasterData, listData) => {
    const deletedId = [];

    masterData?.forEach((item1, index) => {
      const found = updatedMasterData?.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(index + 1);
      }
    });

    const newId = updatedMasterData
      ?.filter((item) => item.displayFlag && !masterData?.some((data2Item) => data2Item.id === item.id))
      ?.map((item) => item.id);

    const updatedData = listData?.filter((it) => !deletedId?.includes(it.key));

    const addNewData = newId?.map((item) => ({
      key: item,
      value: '',
    }));

    updatedData?.push(...addNewData);
    return updatedData;
  };

  const updateKosuuList = (masterData, updatedMasterData, listData) => {
    const lastArrObj = updatedMasterData[updatedMasterData?.length - 1];
    const lastArrObjId = lastArrObj.id;
    const lastArrObjList = listData[listData?.length - 1];
    const lastArrObjVal = lastArrObjList.val;
    // change last key value
    const updatedList = [
      ...listData.slice(0, listData?.length - 1),
      { ...listData[listData?.length - 1], key: lastArrObjId + 1, val: lastArrObjVal },
    ];

    const deletedId = [];

    masterData?.forEach((item1, index) => {
      const found = updatedMasterData?.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(index + 1);
      }
    });

    const newId = updatedMasterData
      ?.filter((item) => item.displayFlag && !masterData.some((data2Item) => data2Item.id === item.id))
      ?.map((item) => item.id);

    const updatedData = updatedList?.filter((it) => !deletedId?.includes(it.key));

    const addNewData = newId?.map((item) => ({
      key: item,
      val: '',
    }));

    updatedData.push(...addNewData);
    const sortedData = updatedData.sort((a, b) => a.key - b.key);
    return sortedData;
  };

  const onRowSelect = async (record) => {
    // const getData = kouteiMaster?.filter((i) => i.processType === record.processType);
    // let result = [];
    // if (getData[0].processDetail?.length > 0) {
    //   result = getData[0].processDetail[0].processDetailValue?.map((i) => {
    //     if (i.displayFlg === true) {
    //       return i.detailType;
    //     }
    //   });
    // }

    if (record) {
      let deviceInfo = await getDeviceInfoById(record?.id);
      if (deviceInfo?.length > 0) {
        record = deviceInfo[0];
      }
    }

    setCurrentRecordKey(record?.id);
    setCurrentRecord(record);
    setClickedAction(1);
    if (editMode) {
      //setEditModeCancelConfirm(true);
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setSelectedRowKeys(record?.id);
        setSelectedData(record);
      }
    } else {
      // const dantoriSizeUpdList = updateList2(record.weightMasterData, weightMaster, record.dantoriSizeListData);
      // record['dantoriSizeListData'] = dantoriSizeUpdList;
      // record['weightMasterData'] = weightMaster;

      // if (record.deviceTypes === '追加') {
      setSelectedRowKeys(record?.id);
      setSelectedData(record);
      // navigate(':parent.device');
      // navigate(path);
      // } else {
      // update 係数区分 master data

      // record['meishouDetails'] = meishouMaster;
      // record['suuryouDetails'] = suuryouKubunMaster;

      // 数量区分　子部品係数
      // const suuryouKubunUpdList = updateList2(
      //   record.kobuhinKeisuuMasterData,
      //   suuryouKubunMaster,
      //   record.kobuhinKeisuuLstData
      // );

      // 段取設定の数量
      // const danntorisetteisuuryouKubunUpdList = updateList2(
      //   record.kobuhinKeisuuMasterData,
      //   suuryouKubunMaster,
      //   record.dantoriSetteiSuuryouLstData
      // );

      // record['dantoriSetteiSuuryouLstData'] = danntorisetteisuuryouKubunUpdList;
      // record['kobuhinKeisuuMasterData'] = suuryouKubunMaster;
      // record['kobuhinKeisuuLstData'] = suuryouKubunUpdList;

      // if (record.processName === '組立' || record.processName === '検査' || record.processName === '梱包') {
      //   const meishouUpdList = updateMeishouList(record.meishouMasterData, meishouMaster, record.meishouLstData);
      //   record['meishouMasterData'] = meishouMaster;
      //   record['meishouLstData'] = meishouUpdList;
      // }
      // if (record.processName === '溶接') {
      //   // 溶接線長
      //   const yousetsuSenchouUpdList = updateList2(
      //     record.yousetsuSenchouMasterData,
      //     weldLineMaster,
      //     record.yousetsuSenchouLstData
      //   );
      //   record['yousetsuSenchouMasterData'] = weldLineMaster;
      //   record['yousetsuSenchouLstData'] = yousetsuSenchouUpdList;
      // }

      // if (record.processName === '溶接仕上') {
      //   // 溶接仕上線長
      //   const yousetsuShiageSenchouLstData = updateList2(
      //     record.yousetsuSenchouMasterData,
      //     weldLineMaster,
      //     record.yousetsuShiageSenchouLstData
      //   );
      //   record['yousetsuSenchouMasterData'] = weldLineMaster;
      //   record['yousetsuShiageSenchouLstData'] = yousetsuShiageSenchouLstData;
      // }

      // // Masterdataによって、リストデータ再更新
      // const pitchUpdList = updateList2(
      //   record.yousetsuNanidoItaatsuMasterData,
      //   yousetsuNanidoItaatsuMaster,
      //   record.pitchItaatsuLstData
      // );
      // const tapWeldingUpdList = updateList2(
      //   record.yousetsuNanidoItaatsuMasterData,
      //   yousetsuNanidoItaatsuMaster,
      //   record.tapWeldingItaatsuLstData
      // );

      // const yousetsuNanidoUpdList = updateList2(
      //   record.yousetsuNanidoItaatsuMasterData,
      //   yousetsuNanidoItaatsuMaster,
      //   record.yousetsuNanidoItaatsuLstData
      // );

      // const karitsukiJikanUpdList = updateJikanList(
      //   record.karitsukiJikanItaatsuMasterData,
      //   karitsukiJikanItaatsuMaster,
      //   record.karitsukiJikanItaatsuLstData
      // );

      // const toritsukiJikanItaatsuUpdList = updateJikanList(
      //   record.karitsukiJikanItaatsuMasterData,
      //   karitsukiJikanItaatsuMaster,
      //   record.toritsukiJikanItaatsuLstData
      // );
      // const toritsukiJikanSizeUpdList = updateJikanList(
      //   record.toritsukiJikanSizeMasterData,
      //   toritsukiJikanSizeMaster,
      //   record.toritsukiJikanSizeLstData
      // );

      // // 加工速度
      // const yousetsuShiageSenchouUpdList = updateList2(
      //   record.karitsukiJikanItaatsuMasterData,
      //   karitsukiJikanItaatsuMaster,
      //   record.yousetsuShiageSenchouLstData
      // );

      // // 個数
      // const kosuuUpdList = updateList2(record.kosuuMasterData, kosuuMaster, record.kosuuLstData);

      // // 製品重量係数
      // // const addColList = updateList2(record.areaMasterData, areaMaster, record.sizeListData);
      // // const productSizeUpdList = updateProductSizeList(record.weightMasterData, weightMaster, addColList);
      // const productSizeUpdList = updateList2(record.kosuuMasterData, kosuuMaster, record.sizeListData);
      // // 段取サイズ
      // // const dantoriTaisekiUpdList = updateList2(record.volumeMasterData, volumeMaster, record.dantoriSizeListData);
      // // const dantoriSizeUpdList = updateProductSizeList(record.weightMasterData, weightMaster, dantoriTaisekiUpdList);
      // const dantoriSizeUpdList = updateList2(record.weightMasterData, weightMaster, record.dantoriSizeListData);
      // // iq3製品重量係数
      // // 個数
      // const seihinkosuuUpdList = updateJikanList(record.kosuuMasterData, kosuuMaster, record.iq3BendingkosuuLstData);
      // const seihinHonsuuUpdList = updateJikanList(
      //   record.honsuuMasterData,
      //   honsuuMaster,
      //   record.iq3BendingHonsuuLstData
      // );
      // // iq3生産個数
      // const seisanKosuUpdList = updateList2(
      //   record.seisanKosuuMasterData,
      //   seisanKosuuMaster,
      //   record.seisanKosuuLstData
      // );
      // // 工程内検査の生産個数

      // const processInspectionKosuUpdList = updateList2(
      //   record.seisanKosuuMasterData,
      //   seisanKosuuMaster,
      //   record.processInspectionKosuuLstData
      // );
      // // 本数
      // const honnsuuUpdList = updateList2(record.honsuuMasterData, honsuuMaster, record.honsuuLstData);
      // // ２次加工
      // // iq3KaninyuryokuListData: createDetailsListData(result, iq3SizeMaster, zaishitsuKubunn, itaatsu1Master),
      // const iq3KaninyuryokuUpdList = updateKaniSizeList(
      //   zaishitsuKubunn,
      //   record.iq3SizeMasterData,
      //   iq3SizeMaster,
      //   record.itaatsu1MasterData,
      //   itaatsu1Master,
      //   record.iq3KaninyuryokuListData
      // );
      // const iq3KaninyuryokuAnaUpdList = updateKaniSizeList(
      //   zaishitsuKubunn,
      //   record.iq3MmMasterData,
      //   mmMaster,
      //   record.itaatsu1MasterData,
      //   itaatsu1Master,
      //   record.iq3KaninyuryokuAnaListData
      // );
      // // iq3製品重量係数
      // const iq3ProductSizeAreaUpdList = updateList2(
      //   record.iq3AreaMasterData,
      //   iq3AreaMaster,
      //   record.iq3ProductSizeLstData
      // );
      // const iq3ProductSizeUpdList = updateProductSizeList2(
      //   record.weightMasterData,
      //   weightMaster,
      //   iq3ProductSizeAreaUpdList
      // );
      // // 補正時間係数
      // const correctionTimeFactorLst = updateList2(
      //   record.iq3AreaMasterData,
      //   iq3AreaMaster,
      //   record.correctionTimeFactorLstData
      // );
      // const correctionTimeFactorUpdList = updateProductSizeList2(
      //   record.weightMasterData,
      //   weightMaster,
      //   correctionTimeFactorLst
      // );

      // // 曲げ補正時間係数
      // const bendingTimeFactorUpdlengthList = updateList2(
      //   record?.lengthMasterData,
      //   lengthMaster,
      //   record.bendingTimeFactorLstData
      // );
      // const bendingTimeFactorUpdList = updateProductSizeList2(
      //   record.weightMasterData,
      //   weightMaster,
      //   bendingTimeFactorUpdlengthList
      // );
      // // 曲げ長さ係数
      // const bendTimeFactorUpdlengthList = updateList2(
      //   record?.lengthMasterData,
      //   lengthMaster,
      //   record.bendingCorrectionTimeFactorLstData
      // );
      // const bendTimeFactorUpdList = updateProductSizeList2(
      //   record.weightMasterData,
      //   weightMaster,
      //   bendTimeFactorUpdlengthList
      // );
      // // マテリアルハンドリング時間
      // const materialHandlingLstData = updateJikanSizeListData(
      //   record.iq3AreaMasterData,
      //   iq3AreaMaster,
      //   record.materialHandlingTime
      // );
      // const materialHandlingUpdLst = updateProductSizeJikanList(
      //   record.weightMasterData,
      //   weightMaster,
      //   materialHandlingLstData
      // );
      // // ガス種類
      // const gasTypeUpdList = updateList2(record.iq3ItaatsuMasterData, iq3Itaatsu, record.gasTypeLstData);
      // // ブランク加工速度
      // const blankKakousokudoUpdList = updateList3(
      //   record.iq3ItaatsuMasterData,
      //   iq3Itaatsu,
      //   record.blankKakousokudoListData
      // );
      // // ジョイント
      // const jointItaatsuUpdList = updateList2(record.iq3ItaatsuMasterData, iq3Itaatsu, record.jointItaatsuLstData);
      // // 加工速度係数
      // const kakouSokudoItaatsuUpdList = updateList2(
      //   record.iq3ItaatsuMasterData,
      //   iq3Itaatsu,
      //   record.blankKakousokudoItaatsuList
      // );
      // // 1ピアス加工時間
      // const piercingProcessItaatsuUpdList = updateList2(
      //   record.iq3ItaatsuMasterData,
      //   iq3Itaatsu,
      //   record.piercingProcessItaatsuLstData
      // );

      // // 難易度係数
      // const nanidoKeisuuUpdList = updateList2(record.iq3ItaatsuMasterData, iq3Itaatsu, record.nanidoKeisuuLstData);

      // // 1パンチ加工時間
      // const punchProcessItaatsuUpdList = updateList2(
      //   record.iq3ItaatsuMasterData,
      //   iq3Itaatsu,
      //   record.punchProcessItaatsuLstData
      // );
      // // ガス係数
      // const gasKeisuuUpdList = updateList2(record.gasTypeMasterData, gasType, record.gasKeissuuLstData);
      // // ジョイントサイズ
      // const jointSizeUpdList = updateList2(record.jointSizeMasterData, jointSize, record.jointSizeListData);

      // const extractConditionUpdList = updateList2(
      //   record.retrainedMoldMasterData,
      //   retainedMold,
      //   record.extractConditionList
      // );

      // // 手動バリの加工速度
      // const shudouBariKakousokudoLengthUpdList = updateList2(
      //   record.iq3ItaatsuMasterData,
      //   iq3Itaatsu,
      //   record.iq3ShudouBariKakouSokudoListData
      // );
      // const shudouBariKakousokudoUpdList = updateProductSizeList2(
      //   record.iq3KakouchouMasterData,
      //   iq3Kakouchou,
      //   shudouBariKakousokudoLengthUpdList
      // );
      // // 要素数リスト更新
      // const sagyouJikanKeisuuUpdList = updateList2(
      //   record.yousosuuMasterData,
      //   yousosuuMaster,
      //   record.sagyouJikanKeisuuLstData
      // );
      // const shokiDankaiJikanUpdLst = updateJikanList(
      //   record.yousosuuMasterData,
      //   yousosuuMaster,
      //   record.shokiDankaiJikanLstData
      // );
      // const kensaJikanKeisuuUpdList = updateList2(
      //   record.yousosuuMasterData,
      //   yousosuuMaster,
      //   record.kensaJikanKeisuuLstData
      // );
      // const mageSuuUpdList = updateJikanList(record.iq3ItaatsuMasterData, iq3Itaatsu, record.mageSuuLstData);
      // const kakouJikanUpdList = updateJikanList(
      //   record.iq3AreaMasterData,
      //   iq3AreaMaster,
      //   record.iq3KakouJikanListData
      // );
      // // ベンディングの作業加工時間
      // const iq3BendingTimeUpdList = updateJikanList(
      //   record.iq3ItaatsuMasterData,
      //   iq3Itaatsu,
      //   record.iq3BendingTimeLstData
      // );
      // record['iq3KakouJikanListData'] = kakouJikanUpdList;
      // record['iq3BendingTimeLstData'] = iq3BendingTimeUpdList;
      // record['mageSuuLstData'] = mageSuuUpdList;
      // record['shokiDankaiJikanLstData'] = shokiDankaiJikanUpdLst;
      // record['sagyouJikanKeisuuLstData'] = sagyouJikanKeisuuUpdList;
      // record['kensaJikanKeisuuLstData'] = kensaJikanKeisuuUpdList;
      // record['iq3ShudouBariKakouSokudoListData'] = shudouBariKakousokudoUpdList;
      // record['iq3KakouchouMasterData'] = iq3Kakouchou;
      // record['processInspectionKosuuLstData'] = processInspectionKosuUpdList;
      // record['blankKakousokudoListData'] = blankKakousokudoUpdList;
      // record['jointSizeListData'] = jointSizeUpdList;
      // record['gasKeissuuLstData'] = gasKeisuuUpdList;
      // record['extractConditionList'] = extractConditionUpdList;
      // record['piercingProcessItaatsuLstData'] = piercingProcessItaatsuUpdList;
      // record['nanidoKeisuuLstData'] = nanidoKeisuuUpdList;
      // record['punchProcessItaatsuLstData'] = punchProcessItaatsuUpdList;
      // record['jointItaatsuLstData'] = jointItaatsuUpdList;
      // record['gasTypeLstData'] = gasTypeUpdList;
      // record['blankKakousokudoItaatsuList'] = kakouSokudoItaatsuUpdList;
      // record['correctionTimeFactorLstData'] = correctionTimeFactorUpdList;
      // record['bendingTimeFactorLstData'] = bendingTimeFactorUpdList;
      // record['bendingCorrectionTimeFactorLstData'] = bendTimeFactorUpdList;
      // record['iq3ProductSizeLstData'] = iq3ProductSizeUpdList;
      // record['seisanKosuuMasterData'] = seisanKosuuMaster;
      // record['honsuuMasterData'] = honsuuMaster;
      // record['iq3SizeMasterData'] = iq3SizeMaster;
      // record['itaatsu1MasterData'] = itaatsu1Master;
      // record['iq3KaninyuryokuListData'] = iq3KaninyuryokuUpdList;
      // record['iq3KaninyuryokuAnaListData'] = iq3KaninyuryokuAnaUpdList;
      // record['pitchItaatsuLstData'] = pitchUpdList;

      // record['tapWeldingItaatsuLstData'] = tapWeldingUpdList;

      // record['yousetsuNanidoItaatsuMasterData'] = yousetsuNanidoItaatsuMaster;
      // record['yousetsuNanidoItaatsuLstData'] = yousetsuNanidoUpdList;

      // record['karitsukiJikanItaatsuMasterData'] = karitsukiJikanItaatsuMaster;
      // record['karitsukiJikanItaatsuLstData'] = karitsukiJikanUpdList;

      // record['toritsukiJikanItaatsuMasterData'] = karitsukiJikanItaatsuMaster;
      // record['toritsukiJikanItaatsuLstData'] = toritsukiJikanItaatsuUpdList;

      // record['toritsukiJikanSizeMasterData'] = toritsukiJikanSizeMaster;
      // record['toritsukiJikanSizeLstData'] = toritsukiJikanSizeUpdList;

      // record['yousetsuShiageSenchouMasterData'] = kakouSokudoItaatsuMaster;
      // record['yousetsuShiageSenchouLstData'] = yousetsuShiageSenchouUpdList;

      // record['areaMasterData'] = areaMaster;
      // record['weightMasterData'] = weightMaster;
      // record['lengthMasterData'] = lengthMaster;
      // record['volumeMasterData'] = volumeMaster;
      // record['sizeListData'] = productSizeUpdList;
      // record['dantoriSizeListData'] = dantoriSizeUpdList;

      // record['kosuuMasterData'] = kosuuMaster;
      // record['kosuuLstData'] = kosuuUpdList;

      // record['iq3BendingkosuuLstData'] = seihinkosuuUpdList;
      // record['iq3BendingHonsuuLstData'] = seihinHonsuuUpdList;
      // record['seisanKosuuLstData'] = seisanKosuUpdList;
      // record['honsuuLstData'] = honnsuuUpdList;
      // record['iq3AreaMasterData'] = iq3AreaMaster;
      // record['materialHandlingTime'] = materialHandlingUpdLst;
      // record['iq3ItaatsuMasterData'] = iq3Itaatsu;
      // record['jointSizeMasterData'] = jointSize;
      // record['yousosuuMasterData'] = yousosuuMaster;
      // record['iq3MmMasterData'] = mmMaster;

      // setSelectedRowKeys(record?.id);
      // setSelectedData(record);
      // navigate(':parent.device');
      // navigate(path);
      // }
    }
    props?.updateSelectedRowId(record?.id, 0, currentPage, ascSortFlg, sortNm, filterData);
    props?.resetInitialParam();
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    setTblLoading(true); // Waitingダイアログを表示にする
    let pageBefore = pagination?.current - 1;
    let ascSortFlg = 'ASC';
    if (sorter?.order === undefined) {
      ascSortFlg = '';
    } else if (sorter?.order === 'ascend') {
      ascSortFlg = 'ASC';
    } else if (sorter?.order === 'descend') {
      ascSortFlg = 'DESC';
    }
    setAscSortFlg(ascSortFlg);
    let currentClass = currentRoute?.includes('parent') ? ServiceClass.Parent : ServiceClass.SheetMetal;
    let deviceData = await getDeviceInfo(pageSize, pageBefore, currentClass, ascSortFlg, sorter?.columnKey, filterData);
    if (deviceData) {
      const deviceDatas = deviceData ? deviceData?.data : [];
      setInitialTableItems(deviceDatas);
    }
    setTblLoading(false); // Waitingダイアログを表示にする
  };

  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    setEditMode(false);
    if (clickedAction == 1) {
      // selected row change
      currentRecord['meishouDetails'] = meishouMaster;
      setSelectedRowKeys(currentRecordKey);
      setSelectedData(currentRecord);
      // navigate(':iq3.device');
      // navigate(path);
    } else if (clickedAction === 2) {
      setIsNew(true);
      setKouteiType('');
      setKouteiCode('');
      setSouchiCode('');
      setSouchiName('');
      setCopyDeviceList([]);
      setCopyDeviceId('');
      setCategory(processTypes.standard);
      setAddProcessTypeId('');
      setIsManualInput(false);
      setIsCommission(false);
      setSelectDisabled(true);
      setInputDisabled(true);
      setPrepBasicTime(0);
      setWorkBasicTime(0);
      setPrep(0);
      setWork(0);
    } else if (clickedAction === 3) {
      if (selectedData?.length != 0) {
        setIsDelete(true);
      } else {
        alert('データを選択してください');
      }
    }
  };

  const discardConfirmCancel = () => {
    setEditMode(true);
    setEditModeCancelConfirm(false);
  };

  const handleSelectVal = (e) => {
    setProcess(e);
    if (e != 0) {
      let deviceData = initialTblItemsBk;
      const deviceFilter = deviceData?.filter((item) => {
        return item.processName === e && item.deviceName?.includes(device);
      });

      setInitialTableItems(deviceFilter);
    } else {
      setInitialTableItems(initialTblItemsBk);
    }
  };

  const handleInputVal = (e) => {
    setDevice(e.target.value);
  };

  const searchByDeviceName = (e) => {
    let deviceData = initialTblItemsBk;

    const deviceFilter = deviceData?.filter((item) => {
      return item.deviceName?.includes(e.target.value);
    });

    setInitialTableItems(deviceFilter);
  };

  // 取引先情報一覧のOKボタン押下
  const getCustomerData = (selectedCustomerData) => {
    setCustomerId(selectedCustomerData.id);
    setCustomerCode(selectedCustomerData.code);
    setCustomerName(selectedCustomerData.name);
    setCustomerModal(false);
  };

  // 工程コードを選択した場合、
  const changeKouteiType = async (e) => {
    setKakouId('');
    setKakouName('');
    setKakouHouhou([]);
    setKakouCode('');
    setAddProcessTypeId('');
    const koutei = kouteiMaster?.filter((i) => i.id === e);

    // カスタムの場合、
    if (koutei[0].workType === WorkType.PaCustom || koutei[0].workType === WorkType.SmCustom) {
      // const data = koutei
      //   ?.filter((item) => item.id === e)?.[0]
      //   ?.details?.processDetailTypes?.map((i) => {
      //     if (i.isUsed) {
      //       return i;
      //     }
      //   });
      const data = koutei?.[0]?.details?.processDetailTypes?.filter((i) => i.isUsed);
      // 追加工程種類リスト
      setAddProcessTypes(data);
    }

    // バリ取、ブランクの場合、
    if (
      koutei[0].workType === WorkType.SmDeburringAuto ||
      koutei[0].workType === WorkType.SmDeburringManual ||
      koutei[0].workType === WorkType.SmBlankLaser ||
      koutei[0].workType === WorkType.SmBlankPunch ||
      koutei[0].workType === WorkType.SmBlankCombi
    ) {
      // const data = koutei?.[0].details?.processDetailTypes?.map((i) => {
      //   if (i.isUsed) {
      //     return i;
      //   }
      // });
      const data = koutei?.[0].details?.processDetailTypes?.filter((i) => i.isUsed);
      setKakouHouhou(data);
    }

    setCopyDeviceList([]);
    setCopyDeviceId('');
    setCustomerId(0);
    setCustomerCode('');
    setCustomerName('');
    setPrepBasicTime(0);
    setWorkBasicTime(0);
    setPrep(0);
    setWork(0);

    const option = kouteiMaster?.find((option) => option.id === e);
    let copyDevices = await GetDevicesByProcessId(option.id);
    if (copyDevices?.length > 0) {
      setCopyDeviceList(copyDevices);
    }

    setKouteiType(option.workType);
    setCategory(option.isAdditional ? processTypes.additional : processTypes.standard);
    // 標準の場合、
    if (!option.isAdditional) {
      setSelectDisabled(true);
      setIsCommission(false);
    }
    setKouteiCode(option.code);
    setKouteiName(option.name);
    setKouteiId(option.id);
  };

  const changeKakouhouhou = (e) => {
    const option = kakouHouhou?.find((option) => option.id === e);
    setKakouId(option.id);
    setKakouName(option.name);
    setKakouCode(option.code);
  };

  // 委託工程を変更した場合、
  const changeIsCommission = (e) => {
    const isChecked = e.target.checked;
    setIsCommission(isChecked);
    setSelectDisabled(!isChecked);
  };

  // 手入力フラグを変更した場合、
  const changeIsManualInput = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setPrep(initPrice);
      setWork(initPrice);
      setWorkBasicTime(0);
      setPrepBasicTime(0);
    }
    setIsManualInput(isChecked);
    setInputDisabled(!isChecked);
  };

  // 取引先の検索ボタン押下
  const searchCustomer = () => {
    setCustomerModal(true);
    // setCustomerCol(result);
  };

  // コピー元装置を変更した場合、
  const changeCopyDeviceId = (e) => {
    if (category === processTypes.additional && e) {
      const copy = initialTblItemsBk?.filter((item) => item.id === e)[0];

      // 初期金額(手入力時)
      setInitPrice(copy?.details?.initPrice);

      // 取引先情報
      setCustomerId(0);
      setCustomerName('');
      setCustomerCode('');
      if (copy?.details?.clientId) {
        let info = clientInfos?.filter((item) => item.id === copy?.details?.clientId);
        setCustomerId(info?.[0]?.id);
        setCustomerName(info?.[0]?.name);
        setCustomerCode(info?.[0]?.code);
      }
      // 手入力フラグ
      setIsManualInput(copy?.details?.isManualInput);
      setInputDisabled(!copy?.details?.isManualInput);

      // 委託工程
      setIsCommission(copy?.details?.isCommission);
      setSelectDisabled(!copy?.details?.isCommission);

      // 追加工程種類
      setAddProcessTypeId(copy?.details?.addProcessTypeId);

      // 段取時間
      setPrepBasicTime(copy?.details?.prepBasicTime);
      // 作業時間(1箇所当たり)
      setWorkBasicTime(copy?.details?.workBasicTime);
      // 段取時間チャージ金額
      setPrep(copy?.details?.charges?.prep);
      // 作業時間チャージ金額
      setWork(copy?.details?.charges?.work);
      // 備考
      setRemarks(copy?.info?.remarks);
    }
    setCopyDeviceId(e);
  };

  // 追加工程種類を変更した場合、
  const changeAddProcessType = (e) => {
    const code = addProcessTypes?.filter((i) => i.id === e);
    setAddProcessTypeId(e);
    // setTsuikaKouteiCode(code?.[0]?.code);
  };

  // 金額の項目を入力した場合、
  const changeAmount = (name, e) => {
    // if (e.startsWith('¥', 0)) {
    //   e = e.replace('¥', '');
    // }
    // if (e.indexOf(',') > 0) {
    //   e = e.replace(/,/g, '');
    // }
    if (name === 'dantori') {
      setPrep(e);
    } else if (name === 'kakou') {
      setWork(e);
    } else {
      setInitPrice(e);
      // 手入力フラグはオンの場合、段取と加工を自動入力
      if (isManualInput) {
        setPrep(e);
        setWork(e);
      }
    }
  };

  // 新規作成一覧の入力項目
  const addNewModalContent = (
    <>
      <Row>
        <RTable className="propertiesTbl">
          <thead>
            {/* 工程名/コード */}
            <tr>
              <td className="device-detail-tbl-val-title">
                <label className="detail-tbl-val-title-label">工程名/コード</label>
              </td>
              <td style={{ verticalAlign: 'middle', width: '40%' }}>
                <Select
                  id="koutei"
                  name="koutei"
                  placeholder="選択"
                  value={kouteiId}
                  style={{ fontSize: 11, width: '97%', marginLeft: 5 }}
                  onChange={changeKouteiType}
                >
                  {kouteiMaster?.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <Input
                  type="text"
                  value={kouteiCode}
                  style={{ padding: 5, marginLeft: 5, width: '93%', pointerEvents: 'none' }}
                  className={'input-editable'}
                ></Input>
              </td>
            </tr>
            {/* 加工方法/コード */}
            {/* <tr>
              <td className="device-detail-tbl-val-title">
                <label className="detail-tbl-val-title-label">加工方法/コード</label>
              </td>
              <td style={{ verticalAlign: 'middle', width: '40%' }}>
                <Select
                  placeholder="選択"
                  // disabled
                  value={kakouId}
                  style={{
                    fontSize: 11,
                    width: '97%',
                    marginLeft: 5,
                    pointerEvents:
                      // バリ取 || ブランク
                      kouteiType === WorkType.SmDeburringAuto ||
                      kouteiType === WorkType.SmDeburringManual ||
                      kouteiType === WorkType.SmBlankLaser ||
                      kouteiType === WorkType.SmBlankPunch ||
                      kouteiType === WorkType.SmBlankCombi
                        ? 'auto'
                        : 'none',
                  }}
                  onChange={changeKakouhouhou}
                >
                  {kakouHouhou?.map((item) => (
                    <Select.Option value={item.id}>{item.name}</Select.Option>
                  ))}
                </Select>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <Input
                  value={kakouCode}
                  type="text"
                  style={{ padding: 5, marginLeft: 5, width: '93%', pointerEvents: 'none' }}
                  className={'input-editable'}
                ></Input>
              </td>
            </tr> */}
            {/* 装置名/コード */}
            <tr>
              <td className="device-detail-tbl-val-title">
                <label className="detail-tbl-val-title-label">装置名/コード</label>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <Input
                  type="text"
                  value={souchiName}
                  onChange={(e) => setSouchiName(e.target.value)}
                  style={{ padding: 5, marginLeft: 5, width: '97%' }}
                  className={'input-editable'}
                  ref={(ref) => {
                    inputItemRef['souchiName'] = ref;
                  }}
                  maxLength={50}
                ></Input>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <Input
                  type="text"
                  value={souchiCode}
                  onChange={(e) => setSouchiCode(e.target.value)}
                  style={{ padding: 5, marginLeft: 5, width: '93%' }}
                  className={'input-editable'}
                  ref={(ref) => {
                    inputItemRef['souchiCode'] = ref;
                  }}
                  maxLength={50}
                ></Input>
              </td>
            </tr>
          </thead>
        </RTable>
      </Row>
      <Row justify="start" align="middle">
        <Col span={7} style={{ textAlign: 'end' }}>
          コピー元装置：
        </Col>
        <Col span={12}>
          <Select
            placeholder="選択"
            value={copyDeviceId}
            style={{ fontSize: 11, width: '97%', marginLeft: 5 }}
            onChange={changeCopyDeviceId}
          >
            {copyDeviceList?.map((option, index) => (
              <Select.Option key={index} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row className="mt-3">
        <RTable className="propertiesTbl tsuikakoutei">
          <thead>
            {/* 追加工程種類 */}
            <tr className={category === processTypes.standard ? 'souchi' : ''}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">追加工程種類</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                <Select
                  placeholder="選択"
                  value={addProcessTypeId}
                  id="addProcessType"
                  name="addProcessType"
                  onChange={(e) => changeAddProcessType(e)}
                  style={{ fontSize: 11, width: '97%', marginLeft: 5 }}
                >
                  {addProcessTypes
                    ?.filter((i) => i.isUsed)
                    ?.map((item, index) => (
                      <Select.Option key={index} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </td>
            </tr>
            <tr className={`${category === processTypes.standard ? 'souchi' : ''} ${inputDisabled ? '' : 'souchi'}`}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">段取時間</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                <Row style={{ marginLeft: 5 }} align="middle">
                  <Col span={5}>
                    {category === processTypes.standard ? (
                      <>
                        <label style={{ marginLeft: 5 }}>{secondsToHms(prepBasicTime)}</label>
                      </>
                    ) : (
                      <>
                        <Input
                          value={secondsToHms(prepBasicTime)}
                          onChange={(e) => {
                            setPrepBasicTime(setTimetoSec(e.target.value));
                          }}
                        ></Input>
                      </>
                    )}
                  </Col>
                  <Col span={12}>
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', marginLeft: 5 }}
                      onClick={() => {
                        let editedTime = souchiHandleIncreaseTime(secondsToHms(prepBasicTime), '10min');
                        setPrepBasicTime(setTimetoSec(editedTime));
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleDecreaseTime(secondsToHms(prepBasicTime), '10min');
                        setPrepBasicTime(setTimetoSec(editedTime));
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleIncreaseTime(secondsToHms(prepBasicTime), 'min');
                        setPrepBasicTime(setTimetoSec(editedTime));
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleDecreaseTime(secondsToHms(prepBasicTime), 'min');
                        setPrepBasicTime(setTimetoSec(editedTime));
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleIncreaseTime(secondsToHms(prepBasicTime), 'sec');
                        setPrepBasicTime(setTimetoSec(editedTime));
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleDecreaseTime(secondsToHms(prepBasicTime), 'sec');
                        setPrepBasicTime(setTimetoSec(editedTime));
                      }}
                    />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr className={`${category === processTypes.standard ? 'souchi' : ''} ${inputDisabled ? '' : 'souchi'}`}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">段取時間チャージ金額</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                <Row align="middle" style={{ marginLeft: 5 }}>
                  {category === processTypes.standard ? (
                    <>
                      <label style={{ marginLeft: 5 }}>{JPYs.format(prep)}</label>
                      <label style={{ marginLeft: 5 }}>（/h）</label>
                    </>
                  ) : (
                    <>
                      <Col span={5}>
                        {/* <Input
                          value={JPYs.format(prep)}
                          defaultValue={0}
                          onChange={(e) => changeAmount('dantori', e.target.value)}
                          ref={(ref) => {
                            inputItemRef['prep'] = ref;
                          }}
                          maxLength={Number.MAX_SAFE_INTEGER}
                        /> */}
                        <CurrencyInput
                          value={prep}
                          style={{
                            padding: 5,
                            // marginLeft: 5,
                            width: '97%',
                            pointerEvents: inputDisabled ? 'auto' : 'none',
                          }}
                          className={'input-editable chargeAmount_currency'}
                          onValueChange={(event) => changeAmount('dantori', event)}
                          maxLength={Number.MAX_SAFE_INTEGER}
                          prefix="¥"
                          decimalsLimit={100}
                          defaultValue={0}
                          ref={(ref) => {
                            inputItemRef['prep'] = ref;
                          }}
                        />
                      </Col>
                      <Col span={12}>
                        <label style={{ marginLeft: 5 }}>（/h）</label>
                      </Col>
                    </>
                  )}
                </Row>
              </td>
            </tr>
            <tr className={`${category === processTypes.standard ? 'souchi' : ''} ${inputDisabled ? '' : 'souchi'}`}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">作業時間(1箇所当たり)</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                <Row style={{ marginLeft: 5 }} align="middle">
                  <Col span={5}>
                    {category === processTypes.standard ? (
                      <>
                        <label style={{ marginLeft: 5 }}>{secondsToHms(workBasicTime)}</label>
                      </>
                    ) : (
                      <>
                        <Input
                          value={secondsToHms(workBasicTime)}
                          onChange={(e) => {
                            setWorkBasicTime(setTimetoSec(e.target.value));
                          }}
                        ></Input>
                      </>
                    )}
                  </Col>
                  <Col span={12}>
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', marginLeft: 5 }}
                      onClick={() => {
                        let editedTime = souchiHandleIncreaseTime(secondsToHms(workBasicTime), '10min');
                        setWorkBasicTime(setTimetoSec(editedTime));
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleDecreaseTime(secondsToHms(workBasicTime), '10min');
                        setWorkBasicTime(setTimetoSec(editedTime));
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleIncreaseTime(secondsToHms(workBasicTime), 'min');
                        setWorkBasicTime(setTimetoSec(editedTime));
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleDecreaseTime(secondsToHms(workBasicTime), 'min');
                        setWorkBasicTime(setTimetoSec(editedTime));
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleIncreaseTime(secondsToHms(workBasicTime), 'sec');
                        setWorkBasicTime(setTimetoSec(editedTime));
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleDecreaseTime(secondsToHms(workBasicTime), 'sec');
                        setWorkBasicTime(setTimetoSec(editedTime));
                      }}
                    />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr className={`${category === processTypes.standard ? 'souchi' : ''} ${inputDisabled ? '' : 'souchi'}`}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">作業時間チャージ金額</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                <Row align="middle" style={{ marginLeft: 5 }}>
                  {category === processTypes.standard ? (
                    <>
                      <label style={{ marginLeft: 5 }}>{JPYs.format(work)}</label>
                      <label style={{ marginLeft: 5 }}>（/h）</label>
                    </>
                  ) : (
                    <>
                      <Col span={5}>
                        {/* <Input
                          value={JPYs.format(work)}
                          defaultValue={0}
                          onChange={(e) => changeAmount('kakou', e.target.value)}
                          ref={(ref) => {
                            inputItemRef['work'] = ref;
                          }}
                          maxLength={Number.MAX_SAFE_INTEGER}
                        /> */}
                        <CurrencyInput
                          value={work}
                          style={{
                            padding: 5,
                            // marginLeft: 5,
                            width: '97%',
                            pointerEvents: inputDisabled ? 'auto' : 'none',
                          }}
                          className={'input-editable chargeAmount_currency'}
                          onValueChange={(event) => changeAmount('kakou', event)}
                          maxLength={Number.MAX_SAFE_INTEGER}
                          prefix="¥"
                          decimalsLimit={100}
                          defaultValue={0}
                          ref={(ref) => {
                            inputItemRef['work'] = ref;
                          }}
                        />
                      </Col>
                      <Col span={12}>
                        <label style={{ marginLeft: 5 }}>（/h）</label>
                      </Col>
                    </>
                  )}
                </Row>
              </td>
            </tr>
            <tr className={category === processTypes.standard ? 'souchi' : ''}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">委託工程</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                <Checkbox style={{ marginLeft: 5 }} onChange={changeIsCommission} checked={isCommission}></Checkbox>
              </td>
            </tr>
            <tr className={`${category === processTypes.standard ? 'souchi' : ''} ${selectDisabled ? 'souchi' : ''}`}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">取引先</label>
              </td>
              <td
                style={{
                  verticalAlign: 'middle',
                }}
                colSpan={2}
              >
                <Row>
                  <Col span={22}>
                    {category == processTypes.standard ? (
                      ''
                    ) : (
                      <>
                        <label style={{ marginLeft: 5 }}> {customerName}</label>
                      </>
                    )}
                  </Col>
                  <Col span={2}>
                    <SearchOutlined
                      style={{ fontSize: '18px', pointerEvents: selectDisabled ? 'none' : 'auto' }}
                      onClick={searchCustomer}
                    />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr className={`${category === processTypes.standard ? 'souchi' : ''} ${selectDisabled ? 'souchi' : ''}`}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">取引先コード</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                {category == processTypes.standard ? (
                  ''
                ) : (
                  <>
                    <label style={{ marginLeft: 5 }}> {customerCode}</label>
                  </>
                )}
              </td>
            </tr>
            <tr className={category === processTypes.standard ? 'souchi' : ''}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">手入力フラグ</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                <Checkbox style={{ marginLeft: 5 }} onChange={changeIsManualInput} checked={isManualInput}></Checkbox>
              </td>
            </tr>
            <tr className={`${category === processTypes.standard ? 'souchi' : ''} ${inputDisabled ? 'souchi' : ''}`}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">初期金額(手入力時)</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                {category === processTypes.standard ? (
                  ''
                ) : (
                  <>
                    {/* <Input
                      type="text"
                      value={JPYs.format(initPrice)}
                      onChange={(e) => changeAmount('initPrice', e.target.value)}
                      style={{
                        padding: 5,
                        marginLeft: 5,
                        width: '97%',
                        pointerEvents: inputDisabled ? 'none' : 'auto',
                      }}
                      className="input-editable"
                      ref={(ref) => {
                        inputItemRef['initPrice'] = ref;
                      }}
                      maxLength={Number.MAX_SAFE_INTEGER}
                    ></Input> */}
                    <CurrencyInput
                      value={initPrice}
                      style={{
                        padding: 5,
                        marginLeft: 5,
                        width: '97%',
                        pointerEvents: inputDisabled ? 'none' : 'auto',
                      }}
                      className={'input-editable chargeAmount_currency'}
                      onValueChange={(event) => changeAmount('initPrice', event)}
                      maxLength={Number.MAX_SAFE_INTEGER}
                      prefix="¥"
                      decimalsLimit={100}
                      defaultValue={0}
                      ref={(ref) => {
                        inputItemRef['initPrice'] = ref;
                      }}
                    />
                  </>
                )}
              </td>
            </tr>
            <tr className={category === processTypes.standard ? 'souchi' : ''}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">備考</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                {category === processTypes.standard ? (
                  ''
                ) : (
                  <>
                    <Input
                      type="text"
                      value={remarks}
                      onChange={(e) => setRemarks(e.target.value)}
                      style={{ padding: 5, marginLeft: 5, width: '97%' }}
                      className={'input-editable'}
                      ref={(ref) => {
                        inputItemRef['remarks'] = ref;
                      }}
                      maxLength={2048}
                    ></Input>
                  </>
                )}
              </td>
            </tr>
          </thead>
        </RTable>
      </Row>
      <Row justify="end">
        <Button onClick={addData} className="mainButton" id="update">
          登録
        </Button>
        <Button onClick={cancelData} className="cancelButton" style={{ marginLeft: 10 }} id="discard">
          破棄
        </Button>
      </Row>
    </>
  );

  // 画面の入力チェック
  const formValidation = (inputItems) => {
    let ret = true;
    let message = [];
    let input;

    // 工程名
    input = document.getElementsByName('koutei')?.[0];
    input?.childNodes?.[0]?.classList?.remove('input-error');
    if (input?.innerText?.length === 0) {
      // 必須
      message.push(<li>{ErrorMessage('工程名').E017}</li>);
      input?.childNodes?.[0]?.classList.add('input-error');
      ret = false;
    }

    // 装置名
    input = inputItems['souchiName']?.input;
    input?.classList?.remove('input-error');

    if (input?.value?.length <= 0) {
      // 必須
      message.push(<li>{ErrorMessage('装置名').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 装置コード
    input = inputItems['souchiCode']?.input;
    input?.classList?.remove('input-error');

    if (input?.value?.length <= 0) {
      // 必須
      message.push(<li>{ErrorMessage('装置コード').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 種別は追加の場合、
    if (kouteiMaster?.filter((i) => i.id === kouteiId)?.[0]?.isAdditional) {
      // 追加工程種類
      input = document.getElementsByName('addProcessType')?.[0];
      input?.childNodes?.[0]?.classList?.remove('input-error');
      if (input?.innerText?.length <= 0) {
        // 必須
        message.push(<li>{ErrorMessage('追加工程種類').E017}</li>);
        input?.childNodes?.[0]?.classList.add('input-error');
        ret = false;
      }

      // 段取時間チャージ金額
      input = inputItems['prep'];
      input?.classList?.remove('input-error');
      if (input?.value?.length <= 0) {
        // 必須
        message.push(<li>{ErrorMessage('段取時間チャージ金額').E007}</li>);
        input.classList.add('input-error');
        ret = false;
      }

      // 作業時間チャージ金額
      input = inputItems['work'];
      input?.classList?.remove('input-error');
      if (input?.value?.length <= 0) {
        // 必須
        message.push(<li>{ErrorMessage('作業時間チャージ金額').E007}</li>);
        input.classList.add('input-error');
        ret = false;
      }

      // 初期金額(手入力時)
      input = inputItems['initPrice'];
      input?.classList?.remove('input-error');
      if (isManualInput && input?.value?.length <= 0) {
        // 必須
        message.push(<li>{ErrorMessage('初期金額(手入力時)').E007}</li>);
        input.classList.add('input-error');
        ret = false;
      } else {
        input.value = 0;
      }
    }

    if (message?.length > 0) error(<ul>{message}</ul>);

    return ret;
  };

  const updateFlag = (result) => {
    setFlag(result);
  };

  // 更新確認のOKを押下で、一覧画面のデータを反映する
  const updatedDetailsData = async (result) => {
    if (result != null) {
      const data = [...initialTblItemsBk];
      props?.loading(true); // Waitingダイアログを表示にする
      var updatedInfo = [];
      updatedInfo = await updateDeviceData(result);
      const index = data?.findIndex((item) => item.id === updatedInfo.id);
      if (index >= 0) {
        data[index] = result;
        setInitialTableItemsBk(data);
        setSelectedData(result);
        setTmpFilteredData(data);
        setSelectedRowKeys(result?.id);
      }
      props?.loading(false); // Waitingダイアログを非表示にする
      // if (result.deviceTypes != '追加') {
      //   setYousetsuNanidoItaatsuMaster(result.yousetsuNanidoItaatsuMasterData);
      //   setKaritsukiJikanItaatsuMaster(result.karitsukiJikanItaatsuMasterData);
      //   setToritsukiJikanItaatsuMaster(result.karitsukiJikanItaatsuMasterData);
      //   setToritsukiJikanSizeMaster(result.toritsukiJikanSizeMasterData);
      //   setWeldLineMaster(result.yousetsuSenchouMasterData);
      //   setKakouSokudoItaatsuMaster(result.yousetsuShiageSenchouMasterData);

      //   setVolumeMaster(result.volumeMasterData);
      //   setKosuuMaster(result.kosuuMasterData);
      //   localStorage.setItem('kosuuMasterData', JSON.stringify(result.kosuuMasterData));
      //   setMeishouMasterData(result.meishouDetails);
      //   setIQ3Itaatsu(result.iq3ItaatsuMasterData);
      //   setIQ3SizeMaster(result.iq3SizeMasterData);
      //   setItaatsu1Master(result.itaatsu1MasterData);

      //   setGasType(result.gasTypeMasterData);
      //   setJointSize(result.jointSizeMasterData);
      //   setHonsuuMaster(result.honsuuMasterData);
      //   setRetrainedMold(result.retrainedMoldMasterData);
      //   setIQ3Kakouchou(result.iq3KakouchouMasterData);
      //   setYousosuuMaster(result.yousosuuMasterData);
      // }
      // setAreaMaster(result.areaMasterData);
      // setWeightMaster(result.weightMasterData);
      // setIQ3AreaMaster(result.iq3AreaMasterData);
      // setLengthMaster(result?.lengthMasterData);
      // setSeisanKosuuMaster(result.seisanKosuuMasterData);
      // setSuuryouKubunMaster(result.kobuhinKeisuuMasterData);
      // if (currentRoute?.includes('parent')) {
      //   localStorage.setItem('parent_devicesMaster', JSON.stringify(data));
      // } else {
      //   localStorage.setItem('iq3_devicesMaster', JSON.stringify(data));
      // }
    }
  };

  // 装置マスタ情報をDBに更新する
  async function updateDeviceData(updateData) {
    let ret = [];
    try {
      const Url = envProperties.REACT_APP_BACKEND_API_URL + 'Device';
      const otherParam = {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': envProperties.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          id: updateData.id,
          processId: updateData.processId,
          code: updateData.code,
          name: updateData.name,
          details: updateData.details,
          info: updateData.info,
          updateId: updateData.updateId,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  const beforeSearch = (e, type) => {
    setIsFilterFlg(true);
    {
      let action;
      switch (type) {
        case 'name':
          action = actionArr[1];
          break;
        case 'koteimei':
          action = actionArr[4];
          break;
        case 'dataStateDrop':
          action = actionArr[5];
          break;
      }
      callMethod(action.methodName, e);
    }
  };
  const callMethod = (methodName, param) => {
    const method = eval(methodName);
    if (typeof method === 'function') {
      method(param);
    }
  };

  const searchByKeyword = (e) => {
    nameKeyword === e.target.value ? getDataByFilter() : setNameKeyWord(e.target.value);
  };

  const searchByDataState = (value) => {
    koteimei === value ? getDataByFilter() : setKoteimei(value);
  };

  const searchByShowFlg = (value) => {
    dataState === value ? getDataByFilter() : setDataState(value);
  };

  // 絞り込み、
  const getDataByFilter = async () => {
    setTblLoading(true); // Waitingダイアログを表示にする
    if (initialTblItemsBk?.length <= 0) {
      return;
    }
    let filterData = {
      processNm: koteimei !== undefined && koteimei !== '全て' ? koteimei : '',
      deviceNm: nameKeyword,
      isUsedFlg: dataState,
    };
    setFilterData(filterData);
    let currentClass = currentRoute?.includes('parent') ? ServiceClass.Parent : ServiceClass.SheetMetal;
    let deviceData = await getDeviceInfo(pageSize, 0, currentClass, ascSortFlg, sortNm, filterData);
    if (deviceData) {
      const deviceDatas = deviceData ? deviceData?.data : [];
      setInitialTableItems(deviceDatas);
      setTotalCount(deviceData?.totalCount);
      setCurrentPage(1);
    }
    setTblLoading(false); // Waitingダイアログを表示にする
    setIsFilterFlg(false);
    props?.updateSelectedRowId(currentRecordKey, 0, currentPage, ascSortFlg, sortNm, filterData);
  };

  const handlePageSizeChange = (current, pagesize) => {
    setPageSize(pagesize);
  };

  // CSV入力情報チェック
  const csvImportDataValidation = (dataList, detailMenuTab, messageRef, workType) => {
    let ret = true;
    // タイトル部分チェック
    let title = dataList[0];
    if (workType !== WorkType.PaCustom && workType !== WorkType.SmCustom) {
      if (!title.includes('ID,コード,工程名,装置コード,装置名,備考,表示')) {
        // 不正なヘッダの情報
        messageRef?.current?.push(<li>{ErrorMessage('ヘッダ情報').E016}</li>);
        return false;
      }
    } else if (workType === WorkType.PaCustom && workType === WorkType.SmCustom) {
      if (!title.includes('ID,コード,工程名,コード,工程名,装置コード,装置名,備考,表示')) {
        // 不正なヘッダの情報
        messageRef?.current?.push(<li>{ErrorMessage('ヘッダ情報').E016}</li>);
        return false;
      }
    }
    // IDの数字チェック
    title = dataList[1]?.split(',');
    if (!isDigit(title[0])) {
      // 数字チェック
      messageRef.current.push(<li>{ErrorMessage('ID').E009}</li>);
      return false;
    }
    // 該当工程名チェック
    let processNm = kouteiMaster?.filter((item) => item.id === selectedData?.processId)?.[0]?.name;
    if (processNm !== title[2]) {
      // 数字チェック
      messageRef.current.push(<li>対象工程名が一致していません</li>);
      return false;
    }
    // 表面処理の場合
    if (workType === WorkType.PaSurface || workType === WorkType.SmSurface) {
      ret = surfaceCSVImportValidation(dataList, detailMenuTab, messageRef);
    } else if (workType === WorkType.SmProgram) {
      // プログラム
      ret = programCSVImportValidation(dataList, detailMenuTab, messageRef);
    } else if (workType === WorkType.SmShearing) {
      // シャーリング
      ret = shearingCSVImportValidation(dataList, detailMenuTab, messageRef);
    } else if (workType === WorkType.SmBlankLaser) {
      // レーザー
      ret = laserCSVImportValidation(dataList, detailMenuTab, messageRef);
    } else if (workType === WorkType.SmBlankPunch) {
      // パンチ
      ret = punchCSVImportValidation(dataList, detailMenuTab, messageRef);
    } else if (workType === WorkType.SmBlankCombi) {
      // 複合機
      ret = combiCSVImportValidation(dataList, detailMenuTab, messageRef);
    } else if (workType === WorkType.SmSecondaryWork) {
      // ２次加工
      ret = secondaryWorkCSVImportValidation(dataList, detailMenuTab, messageRef);
    } else if (workType === WorkType.SmWelding) {
      // 溶接
      ret = weldCSVImportValidation(dataList, detailMenuTab, messageRef);
    } else if (workType === WorkType.SmWeldFinish) {
      // 溶接仕上
      ret = weldFinishCSVImportValidation(dataList, detailMenuTab, messageRef);
    } else if (workType === WorkType.SmBending) {
      // ベンディング
      ret = bendingCSVImportValidation(dataList, detailMenuTab, messageRef);
    } else if (workType === WorkType.SmDeburringAuto) {
      // 自動バリ
      ret = autoDeburCSVImportValidation(dataList, detailMenuTab, messageRef);
    } else if (workType === WorkType.SmDeburringManual) {
      // 手動バリ
      ret = manualDeburCSVImportValidation(dataList, detailMenuTab, messageRef);
    } else if (workType === WorkType.PaInspection || workType === WorkType.SmInspection) {
      // 検査処理の場合
      ret = kensaCSVImportValidation(dataList, detailMenuTab, messageRef);
    } else if (workType === WorkType.PaCustom || workType === WorkType.SmCustom) {
      // 追加工程処理の場合
      ret = customCSVImportValidation(dataList, detailMenuTab, messageRef);
    } else if (workType === WorkType.PaAssenble || workType === WorkType.PaPacking) {
      // 組立 // 梱包
      ret = assenblePackingCSVImportValidation(dataList, detailMenuTab, messageRef);
    }
    return ret;
  };

  // 表面処理のCSVファイルの内容をチェックする
  const surfaceCSVImportValidation = (dataList, detailMenuTab, messageRef) => {
    let ret = true;
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let dantoriIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    let childQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    let dantoriQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
    );
    let workTimeIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    let sizeCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    let quantityCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    let processMstRecord = getProcessMstRecordById(selectedData?.processId);
    const groupDisplayList = getProcessDetailTypesInfo(selectedData?.details?.charges, processMstRecord);
    let activeTab = '';
    // CSV入力情報チェック
    for (var i = 0; i < dataList.length; i++) {
      let no = i + 1 + '行目の';
      if (
        chargesIndex === i ||
        dantoriIndex === i ||
        quantityCoeffIndex === i ||
        dantoriQtyIndex === i ||
        childQtyIndex === i ||
        workTimeIndex === i ||
        sizeCoeffIndex === i
      )
        activeTab = dataList[i];
      if (activeTab === detailMenuTab[0] && chargesIndex === i) {
        // チャージ金額(/h)の値をチェック
        let inputData = [];
        let data = [];
        let chargesItemIndex = chargesIndex + 1;
        let index = 0;
        if (groupDisplayList) {
          Object.entries(groupDisplayList)?.map(([_, groupItem]) => {
            groupItem?.map((subItem) => {
              getChargesDetailValues(subItem?.id, selectedData?.details?.charges)?.map((item, i) => {
                if (parseInt(item) === subItem?.id && i === 0) return;
                if (index > 0) {
                  chargesItemIndex = chargesItemIndex + 3;
                }
                inputData = dataList?.[chargesItemIndex];
                if (inputData && inputData === subItem?.name) {
                  no = chargesItemIndex + 2 + 1 + '行目の';
                  data = dataList?.[chargesItemIndex + 2]?.split(',');
                  for (var x = 0; x < data.length; x++) {
                    if (x % 2 === 0 && data?.[x]?.length <= 0) {
                      // 必須
                      messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
                      ret = false;
                      break;
                    }
                    if (x % 2 === 0 && data?.[x]?.length > 0 && !isDigit(data?.[x])) {
                      // 数字チェック
                      messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E009}</li>);
                      ret = false;
                      break;
                    }
                  }
                }
              });
              index++;
            });
          });
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[1] && dantoriIndex === i) {
        // 段取設定の値をチェック
        let chargesItemIndex = i + 1;
        let inputData = [];
        let index = 0;
        let data = [];
        if (groupDisplayList) {
          Object.entries(groupDisplayList)?.map(([_, groupItem]) => {
            groupItem?.map((subItem) => {
              let groupDetail = getPrepSettingDetailValues(subItem?.id, selectedData?.details?.prepSecs);
              groupDetail?.map((item, i) => {
                if (parseInt(item) === subItem?.id && i === 0) return;
                if (index > 0) {
                  chargesItemIndex = chargesItemIndex + 2;
                }
                inputData = dataList?.[chargesItemIndex];
                if (inputData && inputData == subItem?.name) {
                  no = chargesItemIndex + 1 + 1 + '行目の';
                  data = dataList?.[chargesItemIndex + 1];
                  if (data?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + ErrorMessage(data).E007}</li>);
                    ret = false;
                    return;
                  }
                  if (data?.length > 0 && !isDigit(setTimetoSec(data))) {
                    // フォーマットチェック
                    messageRef.current.push(<li>{no + ErrorMessage(data).E008}</li>);
                    ret = false;
                    return;
                  }
                }
              });
              index++;
            });
          });
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[2] && childQtyIndex + 1 === i) {
        // 子部品サイズ係数をチェック
        let dataMain = dataList?.[i];
        let dataQty = dataMain?.replace(/～/g, '');
        if (processMstRecord?.workType === WorkType.SmSurface && processMstRecord?.class === ServiceClass.SheetMetal) {
          dataQty = dataQty?.replace(/cm2/g, '');
        } else {
          dataQty = dataQty?.replace(/個/g, '');
        }
        let data = dataQty?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[2] && childQtyIndex + 2 === i) {
        // 子部品サイズ係数の値をチェック
        let inputData = [];
        let inVal = '';
        if (processMstRecord?.workType === WorkType.SmSurface && processMstRecord?.class === ServiceClass.SheetMetal) {
          selectedData?.details?.childPartsCoeffWeightThMaster
            ?.filter((item) => !item.isOutOfRange)
            ?.map((item, index) => {
              inputData = dataList?.[i + index]?.split(',');
              no = i + index + 1 + '行目の';
              //係数値
              selectedData?.details?.childPartsCoeff
                ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
                ?.areaCoeffList?.map((area, i) => {
                  inVal = inputData[i + 1];
                  if (inVal?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                    ret = false;
                    return;
                  }
                  if (inVal?.length > 0 && !isDecimal(inVal)) {
                    // 数字チェック
                    messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
                    ret = false;
                    return;
                  }
                });
            });
        } else {
          let data = dataList?.[i]?.split(',');
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && dantoriQtyIndex + 1 === i) {
        // 段取個数係数の値をチェック
        let dataMain = dataList?.[i];
        let dataQty = dataMain?.replace(/～/g, '');
        if (processMstRecord?.workType === WorkType.SmSurface && processMstRecord?.class === ServiceClass.SheetMetal) {
          dataQty = dataQty?.replace(/個/g, '');
        } else {
          dataQty = dataQty?.replace(/kg/g, '');
        }
        let data = dataQty?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && dantoriQtyIndex + 2 === i) {
        // 段取個数係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[4] && workTimeIndex + 1 === i) {
        // 加工時間をチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/cm2/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.areaThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('cm2').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[4] && workTimeIndex + 2 === i) {
        // 加工時間をチェック
        let index = 0;
        let inVal = '';
        let inputData = [];
        if (selectedData?.details?.workTimeThItems) {
          Object.entries(selectedData?.details?.workTimeThItems)?.map(([key, data]) => {
            let groupNm = processMstRecord?.details?.processDetailTypes?.filter(
              (subitem) => subitem.id === parseInt(key) && subitem.isUsed === true
            )?.[0]?.name;
            if (groupNm && groupNm !== '') {
              inputData = dataList?.[workTimeIndex + 2 + index]?.split(',');
              no = workTimeIndex + 2 + index + 1 + '行目の';
              if (inputData && inputData[0] == groupNm) {
                data?.map((row, i) => {
                  inVal = inputData[i + 1];
                  if (inVal?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                    ret = false;
                    return;
                  }
                  if (inVal?.length > 0 && !isDigit(setTimetoSec(inVal))) {
                    // フォーマットチェック
                    messageRef.current.push(<li>{no + ErrorMessage(inVal).E008}</li>);
                    ret = false;
                    return;
                  }
                });
              }
              index++;
            }
          });
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[5] && sizeCoeffIndex + 1 === i) {
        // 重量係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/kg/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.prodWeightCoeffThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('重量係数').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[5] && sizeCoeffIndex + 2 === i) {
        //重量係数の値をチェック
        let inputData = [];
        let inVal = '';
        inputData = dataList?.[sizeCoeffIndex + 2]?.split(',');
        no = sizeCoeffIndex + 2 + 1 + '行目の';
        selectedData?.details?.prodWeightCoeffThItems
          ?.filter((item) => !item.isOutOfRange)
          ?.map((item, index) => {
            inVal = inputData?.[index + 1];
            if (inVal?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
              ret = false;
              return;
            }
            if (inVal?.length > 0 && !isDecimal(inVal)) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
              ret = false;
              return;
            }
          });
        if (!ret) break;
      } else if (activeTab === detailMenuTab[6] && quantityCoeffIndex + 1 === i) {
        //生産個数係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/個/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.productionQtyCoeffThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('生産個数係数').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[6] && quantityCoeffIndex + 2 === i) {
        // 生産個数係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      }
    }
    return ret;
  };

  // プログラムのCSVファイルの内容をチェックする
  const programCSVImportValidation = (dataList, detailMenuTab, messageRef) => {
    let ret = true;
    // プログラムの場合
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let dantoriIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    let workCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    let coeffOtherIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
    );

    let activeTab = '';

    // CSV入力情報チェック
    for (var i = 0; i < dataList.length; i++) {
      let no = i + 1 + '行目の';
      if (chargesIndex === i || dantoriIndex === i || workCoeffIndex === i || coeffOtherIndex === i)
        activeTab = dataList[i];
      if (activeTab === detailMenuTab[0] && chargesIndex + 2 === i) {
        // チャージ金額(/h)の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (data?.[x]?.length > 0 && !isDigit(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E009}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[1] && dantoriIndex + 2 === i) {
        // 段取設定の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x === 0 && data?.[x]?.length > 0 && data?.[x] !== detailMenuTab[4]) {
            // 種類チェック
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E016}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDigit(setTimetoSec(data?.[x]))) {
            // フォーマットチェック
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E008}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[2] && workCoeffIndex + 2 === i) {
        // 加工設定の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (data?.[x]?.length > 0 && !isDigit(setTimetoSec(data?.[x]))) {
            // フォーマットチェック
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E008}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && coeffOtherIndex + 2 === i) {
        // 係数・その他の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x === 0 && data?.[x]?.length > 0 && data?.[x] !== detailMenuTab[5]) {
            // 種類チェック
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E016}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && coeffOtherIndex + 4 === i) {
        // 同一形状・サイズの上限の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (data?.[x]?.length > 0 && !isDigit(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E009}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      }
    }
    return ret;
  };

  // シャーリングのCSVファイルの内容をチェックする
  const shearingCSVImportValidation = (dataList, detailMenuTab, messageRef) => {
    let ret = true;
    // シャーリングの場合
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let dantoriIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    let dantoriSizeCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    let dantoriKosuuIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
    );
    let workCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    let sizeCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    let productCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );

    let weightMst = selectedData?.details?.childPartsCoeffWeightThMaster?.filter((item) => !item.isOutOfRange);
    let activeTab = '';

    // CSV入力情報チェック
    for (var i = 0; i < dataList.length; i++) {
      let no = i + 1 + '行目の';
      if (
        chargesIndex === i ||
        dantoriIndex === i ||
        workCoeffIndex === i ||
        sizeCoeffIndex === i ||
        productCoeffIndex === i ||
        dantoriSizeCoeffIndex === i ||
        dantoriKosuuIndex === i
      )
        activeTab = dataList[i];
      if (activeTab === detailMenuTab[0] && chargesIndex + 2 === i) {
        // チャージ金額(/h)の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (data?.[x]?.length > 0 && !isDigit(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E009}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[1] && dantoriIndex + 2 === i) {
        // 段取設定の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (data?.[x]?.length > 0 && !isDigit(setTimetoSec(data?.[x]))) {
            // フォーマットチェック
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E008}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (
        activeTab === detailMenuTab[2] &&
        i >= dantoriSizeCoeffIndex + 2 &&
        i < (dantoriSizeCoeffIndex + 2) * weightMst?.length
      ) {
        // 子部品サイズ係数の値をチェック
        let dataSize = dataList?.[i]?.replace(/kg/g, '');
        let data = dataSize?.split(',');

        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E007}</li>);
            ret = false;
            break;
          }
          // if (x === 0 && weightMst?.findIndex((weight) => weight.value.toString() === data?.[x]) === -1) {
          //   // 存在チェック
          //   messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E022}</li>);
          //   ret = false;
          //   break;
          // }
          if (x > 0 && !isDecimal(data?.[x])) {
            // フォーマットチェック
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E009}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && dantoriKosuuIndex + 2 === i) {
        // 段取個数係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x === 0 && data?.[x] !== detailMenuTab[3]) {
            // 不正な値
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E016}</li>);
            ret = false;
            break;
          }
          if (x > 0 && !isDecimal(data?.[x])) {
            // フォーマットチェック
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E014}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[4] && workCoeffIndex + 2 === i) {
        // 作業・加工時間の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x === 0 && !isDigit(setTimetoSec(data?.[x]))) {
            // フォーマットチェック
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E008}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && typeof Boolean(data?.[x]) !== 'boolean') {
            // フォーマットチェック
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (
        activeTab === detailMenuTab[5] &&
        i >= sizeCoeffIndex + 2 &&
        i < (sizeCoeffIndex + 2) * weightMst?.length
      ) {
        // サイズ係数の値をチェック
        let dataSize = dataList?.[i]?.replace(/kg/g, '');
        let data = dataSize?.split(',');
        let weightMst = selectedData?.details?.childPartsCoeffWeightThMaster?.filter((item) => !item.isOutOfRange);
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E007}</li>);
            ret = false;
            break;
          }
          // if (x === 0 && weightMst?.findIndex((weight) => weight.value.toString() === data?.[x]) === -1) {
          //   // 存在チェック
          //   messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E022}</li>);
          //   ret = false;
          //   break;
          // }
          if (x > 0 && !isDecimal(data?.[x])) {
            // フォーマットチェック
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E009}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[6] && productCoeffIndex + 2 === i) {
        // 生産個数係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x === 0 && data?.[x] !== detailMenuTab[6]) {
            // 種類チェック
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E016}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(dataList[i - 1].split(',')?.[x]).E014}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      }
    }
    return ret;
  };

  // レーザー処理のCSVファイルの内容をチェックする
  const laserCSVImportValidation = (dataList, detailMenuTab, messageRef) => {
    let ret = true;
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let dantoriIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    let dantoriQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    let materialIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
    );
    let piercingProcessIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    let workSpeedIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    let speedIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    let speedCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[7] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[7]
    );
    let gasCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[8] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[8]
    );
    let sizeCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[9] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[9]
    );
    let quantityCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[10] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[10]
    );
    let twoWorkTimeIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[11] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[11]
    );
    let jointIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[12] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[12]
    );
    let activeTab = '';
    // CSV入力情報チェック
    for (var i = 0; i < dataList.length; i++) {
      let no = i + 1 + '行目の';
      if (
        chargesIndex === i ||
        dantoriIndex === i ||
        quantityCoeffIndex === i ||
        dantoriQtyIndex === i ||
        materialIndex === i ||
        piercingProcessIndex === i ||
        workSpeedIndex === i ||
        speedIndex === i ||
        speedCoeffIndex === i ||
        gasCoeffIndex === i ||
        sizeCoeffIndex === i ||
        twoWorkTimeIndex === i ||
        jointIndex === i
      )
        activeTab = dataList[i];
      if (activeTab === detailMenuTab[0] && chargesIndex + 2 === i) {
        // チャージ金額(/h)の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (data?.[x]?.length > 0 && !isDigit(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E009}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[1] && dantoriIndex + 2 === i) {
        // 段取設定の値をチェック
        let data = dataList?.[i];
        if (data?.length <= 0) {
          // 必須
          messageRef.current.push(<li>{no + ErrorMessage(data).E007}</li>);
          ret = false;
          break;
        }
        if (data?.length > 0 && !isDigit(setTimetoSec(data))) {
          // フォーマットチェック
          messageRef.current.push(<li>{no + ErrorMessage(data).E008}</li>);
          ret = false;
          break;
        }

        if (!ret) break;
      } else if (activeTab === detailMenuTab[2] && dantoriQtyIndex + 1 === i) {
        // 段取個数係数の値をチェック
        let dataMain = dataList?.[i];
        let dataQty = dataMain?.replace(/～/g, '');
        dataQty = dataQty?.replace(/個/g, '');
        let data = dataQty?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[2] && dantoriQtyIndex + 2 === i) {
        // 段取個数係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && materialIndex + 2 === i) {
        // 材料配置の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[4] && piercingProcessIndex + 1 === i) {
        // 1ピアス加工時間の値をチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/t/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.piercingTimeThickThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('板厚').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[4] && piercingProcessIndex + 2 === i) {
        // 1ピアス加工時間の値をチェック
        let index = 0;
        let data = '';
        let inputData = [];
        if (selectedData?.details?.piercingTimeThItems) {
          const usedItems = zaishitsuKubunn?.filter((item) => item.info.isUsed);
          const usedIds = usedItems?.map((item) => item.id);
          Object.keys(selectedData?.details?.piercingTimeThItems)
            ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
            ?.map((key) => {
              inputData = dataList?.[piercingProcessIndex + 2 + index]?.split(',');
              no = piercingProcessIndex + 2 + index + 1;
              let typeNm = zaishitsuKubunn?.find((item) => item.id.toString() === key)?.name;
              if (typeNm === inputData[0]) {
                selectedData?.details?.piercingTimeThItems[key]?.map((item, i) => {
                  data = inputData[i + 1];
                  if (data?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + '行目の' + ErrorMessage(data).E007}</li>);
                    ret = false;
                    return;
                  }
                  // IQX_WEBEST-300 パラメータのCSV入力機能で、入力用CSVファイルに小数点以下の値があると、値の上書きが行われない部分がある。
                  if (x !== 0 && data?.length > 0 && !isDecimal(data)) {
                    // 数字チェック
                    messageRef.current.push(<li>{no + '行目の' + ErrorMessage(data).E016}</li>);
                    ret = false;
                    return;
                  }
                });
              }
              index++;
            });
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[5] && workSpeedIndex + 2 === i) {
        // 加工速度(mm/min)をチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/t/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.workSpeedThickThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('板厚').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[5] && workSpeedIndex + 3 === i) {
        // 加工速度(mm/min)をチェック
        let index = 0;
        let data = '';
        let inputData = [];
        if (selectedData?.details?.workSpeedItems) {
          const usedItems = zaishitsuKubunn?.filter((item) => item.info.isUsed);
          const usedIds = usedItems?.map((item) => item.id);
          Object.keys(selectedData?.details?.workSpeedItems)
            ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
            ?.map((key) => {
              inputData = dataList?.[workSpeedIndex + 3 + index]?.split(',');
              no = workSpeedIndex + 3 + index + 1;
              let typeNm = zaishitsuKubunn?.find((item) => item.id.toString() === key)?.name;
              if (typeNm === inputData[0]) {
                selectedData?.details?.workSpeedItems[key]?.map((item, i) => {
                  data = inputData[i + 1];
                  if (data?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + '行目の' + ErrorMessage(data).E007}</li>);
                    ret = false;
                    return;
                  }
                  if (x !== 0 && data?.length > 0 && !isDigit(data)) {
                    // 数字チェック
                    messageRef.current.push(<li>{no + '行目の' + ErrorMessage(data).E016}</li>);
                    ret = false;
                    return;
                  }
                });
              }
              index++;
            });
        }
        let thickIndex = workSpeedIndex + index + 3;
        inputData = dataList?.[thickIndex];
        if (inputData === '詳細入力') {
          thickIndex = thickIndex + 2;
          for (var t = thickIndex; t < speedIndex; t++) {
            let data = dataList?.[t]?.split(',');
            no = t + 1;
            for (var x = 0; x < data.length; x++) {
              if (x < 2) continue;
              if (data?.[x]?.length <= 0) {
                // 必須
                messageRef.current.push(<li>{no + '行目の' + ErrorMessage(data?.[x]).E007}</li>);
                ret = false;
                break;
              }
              if (x !== 0 && data?.[x]?.length > 0 && !isDigit(data?.[x])) {
                // 数字チェック
                messageRef.current.push(<li>{no + '行目の' + ErrorMessage(data?.[x]).E016}</li>);
                ret = false;
                break;
              }
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[6] && speedIndex + 1 === i) {
        // ケガキ速度,送り速度の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDigit(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[7] && speedCoeffIndex + 1 === i) {
        //加工速度係数の値をチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/mm/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.workSpeedCoeffLengthThMaster?.filter((item) => !item.isOutOfRange)?.length !==
          masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('加工長').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[7] && speedCoeffIndex + 2 === i) {
        //内加工
        // 加工速度係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[7] && speedCoeffIndex + 3 === i) {
        //外加工
        // 加工速度係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[8] && gasCoeffIndex + 1 === i) {
        let data = dataList?.[i]?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.gasCoeffThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('ガス種類').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            let gasType = selectedData?.details?.gasCoeffThMaster?.filter(
              (item) => !item.isOutOfRange && item.name === data?.[x]
            );
            if (gasType?.length === 0) {
              // ガス種類チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[8] && gasCoeffIndex + 2 === i) {
        //ガス係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }

        if (!ret) break;
      } else if (activeTab === detailMenuTab[8] && gasCoeffIndex + 3 === i) {
        // ガス係数の板厚マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/t/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.gasCoeffThickThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('板厚').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[8] && gasCoeffIndex + 4 === i) {
        // ガス係数の値をチェック
        let inputData = [];
        let index = 0;
        let inVal = '';
        if (selectedData?.details?.gasCoeffThItems) {
          const usedItems = zaishitsuKubunn?.filter((item) => item.info.isUsed);
          const usedIds = usedItems?.map((item) => item.id);
          Object.keys(selectedData?.details?.gasCoeffThItems)
            ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
            ?.map((key) => {
              inputData = dataList?.[i + index]?.split(',');
              let typeNm = zaishitsuKubunn?.find((item) => item.id.toString() === key)?.name;
              if (typeNm === inputData[0]) {
                selectedData?.details?.gasCoeffThItems[key]?.map((item, i) => {
                  inVal = inputData[i + 1] !== undefined ? inputData[i + 1] : '';
                  no = gasCoeffIndex + 4 + index + 1;
                  if (inVal?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + '行目の' + ErrorMessage(inVal).E007}</li>);
                    ret = false;
                    return;
                  }
                  let gasType = selectedData?.details?.gasCoeffThMaster?.filter(
                    (item) => !item.isOutOfRange && item.name === inVal
                  );
                  if (gasType?.length === 0) {
                    // 数字チェック
                    messageRef.current.push(<li>{no + '行目の' + ErrorMessage(inVal).E016}</li>);
                    ret = false;
                    return;
                  }
                });
              }
              index++;
            });
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[9] && sizeCoeffIndex + 1 === i) {
        // サイズ係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/cm2/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.prodSizeAreaThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('サイズ係数').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[9] && sizeCoeffIndex + 2 === i) {
        //サイズ係数の値をチェック
        let inputData = [];
        let inVal = '';
        selectedData?.details?.prodSizeWeightThMaster
          ?.filter((item) => !item.isOutOfRange)
          ?.map((item, index) => {
            inputData = dataList?.[i + index]?.split(',');
            no = i + index + 1 + '行目の';
            //係数値
            selectedData?.details?.prodSizeItems
              ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
              ?.areaCoeffList?.map((area, i) => {
                inVal = inputData[i + 1];
                if (inVal?.length <= 0) {
                  // 必須
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                  ret = false;
                  return;
                }
                if (inVal?.length > 0 && !isDecimal(inVal)) {
                  // 数字チェック
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
                  ret = false;
                  return;
                }
              });
          });
        if (!ret) break;
      } else if (activeTab === detailMenuTab[10] && quantityCoeffIndex + 1 === i) {
        //生産個数係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/個/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.productionQtyCoeffThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('生産個数係数').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[10] && quantityCoeffIndex + 2 === i) {
        // 生産個数係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[11] && twoWorkTimeIndex + 2 === i) {
        // 2工程穴加工時間の値をチェック
        let data = dataList?.[i];
        if (data?.length <= 0) {
          // 必須
          messageRef.current.push(<li>{no + ErrorMessage(data).E007}</li>);
          ret = false;
          break;
        }
        if (data?.length > 0 && !isDigit(setTimetoSec(data))) {
          // フォーマットチェック
          messageRef.current.push(<li>{no + ErrorMessage(data).E008}</li>);
          ret = false;
          break;
        }

        if (!ret) break;
      } else if (activeTab === detailMenuTab[12] && jointIndex + 1 === i) {
        //ジョイントバラシマスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/cm2/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.jointQtySizeThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('ジョイントバラシ').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[12] && jointIndex + 2 === i) {
        // ジョイントバラシの値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[12] && jointIndex + 3 === i) {
        //ジョイントバラシの板厚マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/t/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.jointSeparateThickThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('板厚').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[12] && jointIndex + 4 === i) {
        //ジョイントバラシをチェック
        let inputData = [];
        let index = 0;
        let inVal = '';
        if (selectedData?.details?.jointSeparateItems) {
          const usedItems = zaishitsuKubunn?.filter((item) => item.info.isUsed);
          const usedIds = usedItems?.map((item) => item.id);
          Object.keys(selectedData?.details?.jointSeparateItems)
            ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
            ?.map((key) => {
              inputData = dataList?.[i + index]?.split(',');
              let typeNm = zaishitsuKubunn?.find((item) => item.id.toString() === key)?.name;
              if (typeNm === inputData[0]) {
                selectedData?.details?.jointSeparateItems[key]?.map((item, i) => {
                  inVal = inputData[i + 1] !== undefined ? inputData[i + 1] : item.value;
                  no = jointIndex + 4 + index + 1;
                  if (inVal?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + '行目の' + ErrorMessage(inVal).E007}</li>);
                    ret = false;
                    return;
                  }
                  if (inVal?.length > 0 && !isDecimal(inVal)) {
                    // 数字チェック
                    messageRef.current.push(<li>{no + '行目の' + ErrorMessage(inVal).E016}</li>);
                    ret = false;
                    return;
                  }
                });
              }
              index++;
            });
        }
        if (!ret) break;
      }
    }
    return ret;
  };

  // パンチ処理のCSVファイルの内容をチェックする
  const punchCSVImportValidation = (dataList, detailMenuTab, messageRef) => {
    let ret = true;
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let dantoriIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    let dantoriQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    let kanagataQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
    );
    let materialIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    let punchIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    let punchTimeIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    let speedIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[7] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[7]
    );
    let speedKanagataIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[8] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[8]
    );
    let odOvertakeMoldIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[9] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[9]
    );
    let sizeCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[10] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[10]
    );
    let quantityCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[11] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[11]
    );
    let twoWorkTimeIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[12] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[12]
    );
    let jointIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[13] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[13]
    );
    let activeTab = '';
    // CSV入力情報チェック
    for (var i = 0; i < dataList.length; i++) {
      let no = i + 1 + '行目の';
      if (
        chargesIndex === i ||
        dantoriIndex === i ||
        quantityCoeffIndex === i ||
        dantoriQtyIndex === i ||
        materialIndex === i ||
        punchIndex === i ||
        kanagataQtyIndex === i ||
        speedIndex === i ||
        punchTimeIndex === i ||
        speedKanagataIndex === i ||
        odOvertakeMoldIndex === i ||
        sizeCoeffIndex === i ||
        twoWorkTimeIndex === i ||
        jointIndex === i
      )
        activeTab = dataList[i];
      if (activeTab === detailMenuTab[0] && chargesIndex + 2 === i) {
        // チャージ金額(/h)の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (data?.[x]?.length > 0 && !isDigit(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E009}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[1] && dantoriIndex + 2 === i) {
        // 段取設定の値をチェック
        let data = dataList?.[i];
        if (data?.length <= 0) {
          // 必須
          messageRef.current.push(<li>{no + ErrorMessage(data).E007}</li>);
          ret = false;
          break;
        }
        if (data?.length > 0 && !isDigit(setTimetoSec(data))) {
          // フォーマットチェック
          messageRef.current.push(<li>{no + ErrorMessage(data).E008}</li>);
          ret = false;
          break;
        }

        if (!ret) break;
      } else if (activeTab === detailMenuTab[2] && dantoriQtyIndex + 1 === i) {
        // 段取個数係数の値をチェック
        let dataMain = dataList?.[i];
        let dataQty = dataMain?.replace(/～/g, '');
        dataQty = dataQty?.replace(/個/g, '');
        let data = dataQty?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[2] && dantoriQtyIndex + 2 === i) {
        // 段取個数係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && kanagataQtyIndex + 1 === i) {
        // 金型段取時間の本数をチェック
        let dataMain = dataList?.[i];
        let dataQty = dataMain?.replace(/～/g, '');
        dataQty = dataQty?.replace(/本/g, '');
        let data = dataQty?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDigit(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && kanagataQtyIndex + 2 === i) {
        // 金型段取時間の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDigit(setTimetoSec(data?.[x]))) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[4] && materialIndex + 2 === i) {
        // 材料配置の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[5] && punchIndex + 2 === i) {
        // パンチ条件の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          // IQX_WEBEST-300 パラメータのCSV入力機能で、入力用CSVファイルに小数点以下の値があると、値の上書きが行われない部分がある。
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }

        if (!ret) break;
      } else if (activeTab === detailMenuTab[6] && punchTimeIndex + 1 === i) {
        // 1パンチ加工時間の板厚をチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/t/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.punchWorkTimeThickThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('板厚').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[6] && punchTimeIndex + 2 === i) {
        // 1パンチ加工時間の値をチェック
        let index = 0;
        let data = '';
        let inputData = [];
        if (selectedData?.details?.punchWorkTimeItems) {
          const usedItems = zaishitsuKubunn?.filter((item) => item.info.isUsed);
          const usedIds = usedItems?.map((item) => item.id);
          Object.keys(selectedData?.details?.punchWorkTimeItems)
            ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
            ?.map((key) => {
              inputData = dataList?.[punchTimeIndex + 2 + index]?.split(',');
              no = punchTimeIndex + 2 + index + 1;
              let typeNm = zaishitsuKubunn?.find((item) => item.id.toString() === key)?.name;
              if (typeNm === inputData[0]) {
                selectedData?.details?.punchWorkTimeItems[key]?.map((item, i) => {
                  data = inputData[i + 1];
                  if (data?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + '行目の' + ErrorMessage(data).E007}</li>);
                    ret = false;
                    return;
                  }
                  // IQX_WEBEST-300 パラメータのCSV入力機能で、入力用CSVファイルに小数点以下の値があると、値の上書きが行われない部分がある。
                  if (x !== 0 && data?.length > 0 && !isDecimal(data)) {
                    // 数字チェック
                    messageRef.current.push(<li>{no + '行目の' + ErrorMessage(data).E016}</li>);
                    ret = false;
                    return;
                  }
                });
              }
              index++;
            });
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[7] && speedIndex + 2 === i) {
        // 送り速度をチェック
        let data = dataList?.[i];
        if (data?.length <= 0) {
          // 必須
          messageRef.current.push(<li>{no + ErrorMessage(data).E007}</li>);
          ret = false;
          break;
        }
        if (data?.length > 0 && !isDigit(data)) {
          // 数字チェック
          messageRef.current.push(<li>{no + ErrorMessage(data).E016}</li>);
          ret = false;
          break;
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[8] && speedKanagataIndex + 2 === i) {
        // 加工速度をチェック
        let data = dataList?.[i];
        if (data?.length <= 0) {
          // 必須
          messageRef.current.push(<li>{no + ErrorMessage(data).E007}</li>);
          ret = false;
          break;
        }
        if (data?.length > 0 && !isDigit(data)) {
          // 数字チェック
          messageRef.current.push(<li>{no + ErrorMessage(data).E016}</li>);
          ret = false;
          break;
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[9] && odOvertakeMoldIndex + 2 === i) {
        // 追抜き条件をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDigit(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[10] && sizeCoeffIndex + 1 === i) {
        // サイズ係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/cm2/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.prodSizeAreaThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('サイズ係数').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[10] && sizeCoeffIndex + 2 === i) {
        //サイズ係数の値をチェック
        let inputData = [];
        let inVal = '';
        selectedData?.details?.prodSizeWeightThMaster
          ?.filter((item) => !item.isOutOfRange)
          ?.map((item, index) => {
            inputData = dataList?.[i + index]?.split(',');
            no = i + index + 1 + '行目の';
            //係数値
            selectedData?.details?.prodSizeItems
              ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
              ?.areaCoeffList?.map((area, i) => {
                inVal = inputData[i + 1];
                if (inVal?.length <= 0) {
                  // 必須
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                  ret = false;
                  return;
                }
                if (inVal?.length > 0 && !isDecimal(inVal)) {
                  // 数字チェック
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
                  ret = false;
                  return;
                }
              });
          });
        if (!ret) break;
      } else if (activeTab === detailMenuTab[11] && quantityCoeffIndex + 1 === i) {
        //生産個数係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/個/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.productionQtyCoeffThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('生産個数係数').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[11] && quantityCoeffIndex + 2 === i) {
        // 生産個数係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[12] && twoWorkTimeIndex + 2 === i) {
        // 2工程穴加工時間の値をチェック
        let data = dataList?.[i];
        if (data?.length <= 0) {
          // 必須
          messageRef.current.push(<li>{no + ErrorMessage(data).E007}</li>);
          ret = false;
          break;
        }
        if (data?.length > 0 && !isDigit(setTimetoSec(data))) {
          // フォーマットチェック
          messageRef.current.push(<li>{no + ErrorMessage(data).E008}</li>);
          ret = false;
          break;
        }

        if (!ret) break;
      } else if (activeTab === detailMenuTab[13] && jointIndex + 1 === i) {
        //ジョイントバラシマスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/cm2/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.jointQtySizeThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('ジョイントバラシ').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[13] && jointIndex + 2 === i) {
        // ジョイントバラシの値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[13] && jointIndex + 3 === i) {
        //ジョイントバラシの板厚マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/t/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.jointSeparateThickThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('板厚').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[13] && jointIndex + 4 === i) {
        //ジョイントバラシをチェック
        let inputData = [];
        let index = 0;
        let inVal = '';
        if (selectedData?.details?.jointSeparateItems) {
          const usedItems = zaishitsuKubunn?.filter((item) => item.info.isUsed);
          const usedIds = usedItems?.map((item) => item.id);
          Object.keys(selectedData?.details?.jointSeparateItems)
            ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
            ?.map((key) => {
              inputData = dataList?.[i + index]?.split(',');
              let typeNm = zaishitsuKubunn?.find((item) => item.id.toString() === key)?.name;
              if (typeNm === inputData[0]) {
                selectedData?.details?.jointSeparateItems[key]?.map((item, i) => {
                  inVal = inputData[i + 1] !== undefined ? inputData[i + 1] : item.value;
                  no = jointIndex + 4 + index + 1;
                  if (inVal?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + '行目の' + ErrorMessage(inVal).E007}</li>);
                    ret = false;
                    return;
                  }
                  if (inVal?.length > 0 && !isDecimal(inVal)) {
                    // 数字チェック
                    messageRef.current.push(<li>{no + '行目の' + ErrorMessage(inVal).E016}</li>);
                    ret = false;
                    return;
                  }
                });
              }
              index++;
            });
        }
        if (!ret) break;
      }
    }
    return ret;
  };

  // 複合機処理のCSVファイルの内容をチェックする
  const combiCSVImportValidation = (dataList, detailMenuTab, messageRef) => {
    let ret = true;
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let dantoriIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    let dantoriQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    let kanagataQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
    );
    let materialIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    let punchIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    let punchTimeIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    let piercingProcessIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[7] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[7]
    );
    let workSpeedIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[8] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[8]
    );
    let speedIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[9] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[9]
    );
    let speedCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[10] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[10]
    );
    let gasCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[11] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[11]
    );
    let sizeCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[12] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[12]
    );
    let quantityCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[13] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[13]
    );
    let twoWorkTimeIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[14] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[14]
    );
    let jointIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[15] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[15]
    );
    let activeTab = '';
    // CSV入力情報チェック
    for (var i = 0; i < dataList.length; i++) {
      let no = i + 1 + '行目の';
      if (
        chargesIndex === i ||
        dantoriIndex === i ||
        quantityCoeffIndex === i ||
        dantoriQtyIndex === i ||
        kanagataQtyIndex === i ||
        materialIndex === i ||
        punchIndex === i ||
        punchTimeIndex === i ||
        piercingProcessIndex === i ||
        workSpeedIndex === i ||
        speedIndex === i ||
        speedCoeffIndex === i ||
        gasCoeffIndex === i ||
        sizeCoeffIndex === i ||
        twoWorkTimeIndex === i ||
        jointIndex === i
      )
        activeTab = dataList[i];
      if (activeTab === detailMenuTab[0] && chargesIndex + 2 === i) {
        // チャージ金額(/h)の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (data?.[x]?.length > 0 && !isDigit(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E009}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[1] && dantoriIndex + 2 === i) {
        // 段取設定の値をチェック
        let data = dataList?.[i];
        if (data?.length <= 0) {
          // 必須
          messageRef.current.push(<li>{no + ErrorMessage(data).E007}</li>);
          ret = false;
          break;
        }
        if (data?.length > 0 && !isDigit(setTimetoSec(data))) {
          // フォーマットチェック
          messageRef.current.push(<li>{no + ErrorMessage(data).E008}</li>);
          ret = false;
          break;
        }

        if (!ret) break;
      } else if (activeTab === detailMenuTab[2] && dantoriQtyIndex + 1 === i) {
        // 段取個数係数の値をチェック
        let dataMain = dataList?.[i];
        let dataQty = dataMain?.replace(/～/g, '');
        dataQty = dataQty?.replace(/個/g, '');
        let data = dataQty?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[2] && dantoriQtyIndex + 2 === i) {
        // 段取個数係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && kanagataQtyIndex + 1 === i) {
        // 金型段取時間の本数をチェック
        let dataMain = dataList?.[i];
        let dataQty = dataMain?.replace(/～/g, '');
        dataQty = dataQty?.replace(/本/g, '');
        let data = dataQty?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDigit(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && kanagataQtyIndex + 2 === i) {
        // 金型段取時間の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDigit(setTimetoSec(data?.[x]))) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[4] && materialIndex + 2 === i) {
        // 材料配置の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[5] && punchIndex + 1 === i) {
        // パンチ条件の板厚をチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/t/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.punchConditionThickThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('板厚').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[5] && punchIndex + 2 === i) {
        // パンチ条件の値をチェック
        let index = 0;
        let data = '';
        let inputData = [];
        if (selectedData?.details?.punchConditionItems) {
          const usedItems = zaishitsuKubunn?.filter((item) => item.info.isUsed);
          const usedIds = usedItems?.map((item) => item.id);
          Object.keys(selectedData?.details?.punchConditionItems)
            ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
            ?.map((key) => {
              inputData = dataList?.[punchIndex + 2 + index]?.split(',');
              no = punchIndex + 2 + index + 1;
              let typeNm = zaishitsuKubunn?.find((item) => item.id.toString() === key)?.name;
              if (typeNm === inputData[0]) {
                selectedData?.details?.punchConditionItems[key]?.map((item, i) => {
                  data = inputData[i + 1];
                  if (data?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + '行目の' + ErrorMessage(data).E007}</li>);
                    ret = false;
                    return;
                  }
                  // IQX_WEBEST-300 パラメータのCSV入力機能で、入力用CSVファイルに小数点以下の値があると、値の上書きが行われない部分がある。
                  if (x !== 0 && data?.length > 0 && !isDecimal(data)) {
                    // 数字チェック
                    messageRef.current.push(<li>{no + '行目の' + ErrorMessage(data).E016}</li>);
                    ret = false;
                    return;
                  }
                });
              }
              index++;
            });
        }

        if (!ret) break;
      } else if (activeTab === detailMenuTab[6] && punchTimeIndex + 1 === i) {
        // 1パンチ加工時間の板厚をチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/t/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.punchWorkTimeThickThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('板厚').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[6] && punchTimeIndex + 2 === i) {
        // 1パンチ加工時間の値をチェック
        let index = 0;
        let data = '';
        let inputData = [];
        if (selectedData?.details?.punchWorkTimeItems) {
          const usedItems = zaishitsuKubunn?.filter((item) => item.info.isUsed);
          const usedIds = usedItems?.map((item) => item.id);
          Object.keys(selectedData?.details?.punchWorkTimeItems)
            ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
            ?.map((key) => {
              inputData = dataList?.[punchTimeIndex + 2 + index]?.split(',');
              no = punchTimeIndex + 2 + index + 1;
              let typeNm = zaishitsuKubunn?.find((item) => item.id.toString() === key)?.name;
              if (typeNm === inputData[0]) {
                selectedData?.details?.punchWorkTimeItems[key]?.map((item, i) => {
                  data = inputData[i + 1];
                  if (data?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + '行目の' + ErrorMessage(data).E007}</li>);
                    ret = false;
                    return;
                  }
                  // IQX_WEBEST-300 パラメータのCSV入力機能で、入力用CSVファイルに小数点以下の値があると、値の上書きが行われない部分がある。
                  if (x !== 0 && data?.length > 0 && !isDecimal(data)) {
                    // 数字チェック
                    messageRef.current.push(<li>{no + '行目の' + ErrorMessage(data).E016}</li>);
                    ret = false;
                    return;
                  }
                });
              }
              index++;
            });
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[7] && piercingProcessIndex + 1 === i) {
        // 1ピアス加工時間の値をチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/t/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.piercingTimeThickThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('板厚').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[7] && piercingProcessIndex + 2 === i) {
        // 1ピアス加工時間の値をチェック
        let index = 0;
        let data = '';
        let inputData = [];
        if (selectedData?.details?.piercingTimeThItems) {
          const usedItems = zaishitsuKubunn?.filter((item) => item.info.isUsed);
          const usedIds = usedItems?.map((item) => item.id);
          Object.keys(selectedData?.details?.piercingTimeThItems)
            ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
            ?.map((key) => {
              inputData = dataList?.[piercingProcessIndex + 2 + index]?.split(',');
              no = piercingProcessIndex + 2 + index + 1;
              let typeNm = zaishitsuKubunn?.find((item) => item.id.toString() === key)?.name;
              if (typeNm === inputData[0]) {
                selectedData?.details?.piercingTimeThItems[key]?.map((item, i) => {
                  data = inputData[i + 1];
                  if (data?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + '行目の' + ErrorMessage(data).E007}</li>);
                    ret = false;
                    return;
                  }
                  // IQX_WEBEST-300 パラメータのCSV入力機能で、入力用CSVファイルに小数点以下の値があると、値の上書きが行われない部分がある。
                  if (x !== 0 && data?.length > 0 && !isDecimal(data)) {
                    // 数字チェック
                    messageRef.current.push(<li>{no + '行目の' + ErrorMessage(data).E016}</li>);
                    ret = false;
                    return;
                  }
                });
              }
              index++;
            });
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[8] && workSpeedIndex + 2 === i) {
        // 加工速度(mm/min)をチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/t/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.workSpeedThickThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('板厚').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[8] && workSpeedIndex + 3 === i) {
        // 加工速度(mm/min)をチェック
        let index = 0;
        let data = '';
        let inputData = [];
        if (selectedData?.details?.workSpeedItems) {
          const usedItems = zaishitsuKubunn?.filter((item) => item.info.isUsed);
          const usedIds = usedItems?.map((item) => item.id);
          Object.keys(selectedData?.details?.workSpeedItems)
            ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
            ?.map((key) => {
              inputData = dataList?.[workSpeedIndex + 3 + index]?.split(',');
              no = workSpeedIndex + 3 + index + 1;
              let typeNm = zaishitsuKubunn?.find((item) => item.id.toString() === key)?.name;
              if (typeNm === inputData[0]) {
                selectedData?.details?.workSpeedItems[key]?.map((item, i) => {
                  data = inputData[i + 1];
                  if (data?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + '行目の' + ErrorMessage(data).E007}</li>);
                    ret = false;
                    return;
                  }
                  if (x !== 0 && data?.length > 0 && !isDigit(data)) {
                    // 数字チェック
                    messageRef.current.push(<li>{no + '行目の' + ErrorMessage(data).E016}</li>);
                    ret = false;
                    return;
                  }
                });
              }
              index++;
            });
        }
        let thickIndex = workSpeedIndex + index + 3;
        inputData = dataList?.[thickIndex];
        if (inputData === '詳細入力') {
          thickIndex = thickIndex + 2;
          for (var t = thickIndex; t < speedIndex; t++) {
            let data = dataList?.[t]?.split(',');
            no = t + 1;
            for (var x = 0; x < data.length; x++) {
              if (x < 2) continue;
              if (data?.[x]?.length <= 0) {
                // 必須
                messageRef.current.push(<li>{no + '行目の' + ErrorMessage(data?.[x]).E007}</li>);
                ret = false;
                break;
              }
              if (x !== 0 && data?.[x]?.length > 0 && !isDigit(data?.[x])) {
                // 数字チェック
                messageRef.current.push(<li>{no + '行目の' + ErrorMessage(data?.[x]).E016}</li>);
                ret = false;
                break;
              }
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[9] && speedIndex + 1 === i) {
        // ケガキ速度,送り速度の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDigit(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[10] && speedCoeffIndex + 1 === i) {
        //加工速度係数の値をチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/mm/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.workSpeedCoeffLengthThMaster?.filter((item) => !item.isOutOfRange)?.length !==
          masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('加工長').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[10] && speedCoeffIndex + 2 === i) {
        //内加工
        // 加工速度係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[10] && speedCoeffIndex + 3 === i) {
        //外加工
        // 加工速度係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[11] && gasCoeffIndex + 1 === i) {
        let data = dataList?.[i]?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.gasCoeffThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('ガス種類').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            let gasType = selectedData?.details?.gasCoeffThMaster?.filter(
              (item) => !item.isOutOfRange && item.name === data?.[x]
            );
            if (gasType?.length === 0) {
              // ガス種類チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[11] && gasCoeffIndex + 2 === i) {
        //ガス係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }

        if (!ret) break;
      } else if (activeTab === detailMenuTab[11] && gasCoeffIndex + 3 === i) {
        // ガス係数の板厚マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/t/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.gasCoeffThickThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('板厚').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[11] && gasCoeffIndex + 4 === i) {
        // ガス係数の値をチェック
        let inputData = [];
        let index = 0;
        let inVal = '';
        if (selectedData?.details?.gasCoeffThItems) {
          const usedItems = zaishitsuKubunn?.filter((item) => item.info.isUsed);
          const usedIds = usedItems?.map((item) => item.id);
          Object.keys(selectedData?.details?.gasCoeffThItems)
            ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
            ?.map((key) => {
              inputData = dataList?.[i + index]?.split(',');
              let typeNm = zaishitsuKubunn?.find((item) => item.id.toString() === key)?.name;
              if (typeNm === inputData[0]) {
                selectedData?.details?.gasCoeffThItems[key]?.map((item, i) => {
                  inVal = inputData[i + 1] !== undefined ? inputData[i + 1] : '';
                  no = gasCoeffIndex + 4 + index + 1;
                  if (inVal?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + '行目の' + ErrorMessage(inVal).E007}</li>);
                    ret = false;
                    return;
                  }
                  let gasType = selectedData?.details?.gasCoeffThMaster?.filter(
                    (item) => !item.isOutOfRange && item.name === inVal
                  );
                  if (gasType?.length === 0) {
                    // 数字チェック
                    messageRef.current.push(<li>{no + '行目の' + ErrorMessage(inVal).E016}</li>);
                    ret = false;
                    return;
                  }
                });
              }
              index++;
            });
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[12] && sizeCoeffIndex + 1 === i) {
        // サイズ係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/cm2/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.prodSizeAreaThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('サイズ係数').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[12] && sizeCoeffIndex + 2 === i) {
        //サイズ係数の値をチェック
        let inputData = [];
        let inVal = '';
        selectedData?.details?.prodSizeWeightThMaster
          ?.filter((item) => !item.isOutOfRange)
          ?.map((item, index) => {
            inputData = dataList?.[i + index]?.split(',');
            no = i + index + 1 + '行目の';
            //係数値
            selectedData?.details?.prodSizeItems
              ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
              ?.areaCoeffList?.map((area, i) => {
                inVal = inputData[i + 1];
                if (inVal?.length <= 0) {
                  // 必須
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                  ret = false;
                  return;
                }
                if (inVal?.length > 0 && !isDecimal(inVal)) {
                  // 数字チェック
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
                  ret = false;
                  return;
                }
              });
          });
        if (!ret) break;
      } else if (activeTab === detailMenuTab[13] && quantityCoeffIndex + 1 === i) {
        //生産個数係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/個/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.productionQtyCoeffThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('生産個数係数').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[13] && quantityCoeffIndex + 2 === i) {
        // 生産個数係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[14] && twoWorkTimeIndex + 2 === i) {
        // 2工程穴加工時間の値をチェック
        let data = dataList?.[i];
        if (data?.length <= 0) {
          // 必須
          messageRef.current.push(<li>{no + ErrorMessage(data).E007}</li>);
          ret = false;
          break;
        }
        if (data?.length > 0 && !isDigit(setTimetoSec(data))) {
          // フォーマットチェック
          messageRef.current.push(<li>{no + ErrorMessage(data).E008}</li>);
          ret = false;
          break;
        }

        if (!ret) break;
      } else if (activeTab === detailMenuTab[15] && jointIndex + 1 === i) {
        //ジョイントバラシマスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/cm2/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.jointQtySizeThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('ジョイントバラシ').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[15] && jointIndex + 2 === i) {
        // ジョイントバラシの値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[15] && jointIndex + 3 === i) {
        //ジョイントバラシの板厚マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/t/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.jointSeparateThickThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('板厚').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[15] && jointIndex + 4 === i) {
        //ジョイントバラシをチェック
        let inputData = [];
        let index = 0;
        let inVal = '';
        if (selectedData?.details?.jointSeparateItems) {
          const usedItems = zaishitsuKubunn?.filter((item) => item.info.isUsed);
          const usedIds = usedItems?.map((item) => item.id);
          Object.keys(selectedData?.details?.jointSeparateItems)
            ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
            ?.map((key) => {
              inputData = dataList?.[i + index]?.split(',');
              let typeNm = zaishitsuKubunn?.find((item) => item.id.toString() === key)?.name;
              if (typeNm === inputData[0]) {
                selectedData?.details?.jointSeparateItems[key]?.map((item, i) => {
                  inVal = inputData[i + 1] !== undefined ? inputData[i + 1] : item.value;
                  no = jointIndex + 4 + index + 1;
                  if (inVal?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + '行目の' + ErrorMessage(inVal).E007}</li>);
                    ret = false;
                    return;
                  }
                  if (inVal?.length > 0 && !isDecimal(inVal)) {
                    // 数字チェック
                    messageRef.current.push(<li>{no + '行目の' + ErrorMessage(inVal).E016}</li>);
                    ret = false;
                    return;
                  }
                });
              }
              index++;
            });
        }
        if (!ret) break;
      }
    }
    return ret;
  };

  // ２次加工処理のCSVファイルの内容をチェックする
  const secondaryWorkCSVImportValidation = (dataList, detailMenuTab, messageRef) => {
    let ret = true;
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let dantoriIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    let childQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    let dantoriQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
    );
    let workTimeIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    let workTypeIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    let modifyTimeIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    let modifyTimeCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[7] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[7]
    );
    let sizeCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[8] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[8]
    );
    let quantityCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[9] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[9]
    );
    let processMstRecord = getProcessMstRecordById(selectedData?.processId);
    const groupDisplayList = getProcessDetailTypesInfo(selectedData?.details?.charges, processMstRecord);
    let activeTab = '';
    // CSV入力情報チェック
    for (var i = 0; i < dataList.length; i++) {
      let no = i + 1 + '行目の';
      if (
        chargesIndex === i ||
        dantoriIndex === i ||
        quantityCoeffIndex === i ||
        dantoriQtyIndex === i ||
        childQtyIndex === i ||
        workTimeIndex === i ||
        workTypeIndex === i ||
        modifyTimeIndex === i ||
        modifyTimeCoeffIndex === i ||
        sizeCoeffIndex === i
      )
        activeTab = dataList[i];
      if (activeTab === detailMenuTab[0] && chargesIndex === i) {
        // チャージ金額(/h)の値をチェック
        let inputData = [];
        let data = [];
        let chargesItemIndex = chargesIndex + 1;
        let index = 0;
        if (groupDisplayList) {
          Object.entries(groupDisplayList)?.map(([_, groupItem]) => {
            groupItem?.map((subItem) => {
              getChargesDetailValues(subItem?.id, selectedData?.details?.charges)?.map((item, i) => {
                if (parseInt(item) === subItem?.id && i === 0) return;
                if (index > 0) {
                  chargesItemIndex = chargesItemIndex + 3;
                }
                inputData = dataList?.[chargesItemIndex];
                if (inputData && inputData === subItem?.name) {
                  no = chargesItemIndex + 2 + 1 + '行目の';
                  data = dataList?.[chargesItemIndex + 2]?.split(',');
                  for (var x = 0; x < data.length; x++) {
                    if (data?.[x]?.length <= 0) {
                      // 必須
                      messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
                      ret = false;
                      break;
                    }
                    if (data?.[x]?.length > 0 && !isDigit(data?.[x])) {
                      // 数字チェック
                      messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E009}</li>);
                      ret = false;
                      break;
                    }
                  }
                }
              });
              index++;
            });
          });
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[1] && dantoriIndex === i) {
        // 段取設定の値をチェック
        let chargesItemIndex = i + 1;
        let inputData = [];
        let index = 0;
        let data = [];
        if (groupDisplayList) {
          Object.entries(groupDisplayList)?.map(([_, groupItem]) => {
            groupItem?.map((subItem) => {
              let groupDetail = getPrepSettingDetailValues(subItem?.id, selectedData?.details?.prepSecs);
              groupDetail?.map((item, i) => {
                if (parseInt(item) === subItem?.id && i === 0) return;
                if (index > 0) {
                  chargesItemIndex = chargesItemIndex + 2;
                }
                inputData = dataList?.[chargesItemIndex];
                if (inputData && inputData == subItem?.name) {
                  no = chargesItemIndex + 1 + 1 + '行目の';
                  data = dataList?.[chargesItemIndex + 1];
                  if (data?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + ErrorMessage(data).E007}</li>);
                    ret = false;
                    return;
                  }
                  if (data?.length > 0 && !isDecimal(setTimetoSec(data))) {
                    // フォーマットチェック
                    messageRef.current.push(<li>{no + ErrorMessage(data).E008}</li>);
                    ret = false;
                    return;
                  }
                }
              });
              index++;
            });
          });
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[2] && childQtyIndex + 1 === i) {
        // 子部品サイズ係数をチェック
        let dataMain = dataList?.[i];
        let dataQty = dataMain?.replace(/～/g, '');
        dataQty = dataQty?.replace(/cm2/g, '');
        let data = dataQty?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[2] && childQtyIndex + 2 === i) {
        // 子部品サイズ係数の値をチェック
        let inputData = [];
        let inVal = '';
        selectedData?.details?.childPartsCoeffWeightThMaster
          ?.filter((item) => !item.isOutOfRange)
          ?.map((item, index) => {
            inputData = dataList?.[i + index]?.split(',');
            no = i + index + 1 + '行目の';
            //係数値
            selectedData?.details?.childPartsCoeff
              ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
              ?.areaCoeffList?.map((area, i) => {
                inVal = inputData[i + 1];
                if (inVal?.length <= 0) {
                  // 必須
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                  ret = false;
                  return;
                }
                if (inVal?.length > 0 && !isDecimal(inVal)) {
                  // 数字チェック
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
                  ret = false;
                  return;
                }
              });
          });
        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && dantoriQtyIndex + 1 === i) {
        // 段取個数係数の値をチェック
        let dataMain = dataList?.[i];
        let dataQty = dataMain?.replace(/～/g, '');
        dataQty = dataQty?.replace(/個/g, '');
        let data = dataQty?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && dantoriQtyIndex + 2 === i) {
        // 段取個数係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[4] && workTimeIndex + 2 === i) {
        // 加工時間をチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/M/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.sizeThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('板厚').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[4] && workTimeIndex + 3 === i) {
        // 加工時間をチェック
        let index = 0;
        let data = '';
        let inputData = [];
        if (selectedData?.details?.workTimeSizeThItems) {
          Object.keys(selectedData?.details?.workTimeSizeThItems)
            .filter(([sizeDataKey]) => getProcessDetailTypeName(parseInt(sizeDataKey), processMstRecord) !== undefined)
            ?.map(([key, item], rowIndex) => {
              let typeNm = getProcessDetailTypeName(parseInt(key), processMstRecord);
              inputData = dataList?.[workTimeIndex + 3 + index]?.split(',');
              if (typeNm === inputData[0]) {
                no = workTimeIndex + 3 + index + 1 + '行目の';
                selectedData?.details?.workTimeSizeThItems[key]?.map((item, i) => {
                  data = inputData[i + 1];
                  if (data?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + ErrorMessage(data).E007}</li>);
                    ret = false;
                    return;
                  }
                  if (data?.length > 0 && !isDigit(setTimetoSec(data))) {
                    // フォーマットチェック
                    messageRef.current.push(<li>{no + ErrorMessage(data).E008}</li>);
                    ret = false;
                    return;
                  }
                });
              }
              index++;
            });
        }
        // 詳細入力
        let thickIndex = workTimeIndex + index + 3;
        inputData = dataList?.[thickIndex];
        let detailIndex = 0;
        let materialTypeIndex = 0;
        if (inputData === '詳細入力') {
          thickIndex = thickIndex + 3;
          if (selectedData?.details?.workTimeSizeThItems) {
            Object.keys(selectedData?.details?.workTimeSizeThItems)
              .filter(
                ([sizeDataKey]) => getProcessDetailTypeName(parseInt(sizeDataKey), processMstRecord) !== undefined
              )
              ?.map(([key, item], rowIndex) => {
                detailIndex = 2;
                let typeNm = getProcessDetailTypeName(parseInt(key), processMstRecord);
                inputData = dataList?.[thickIndex + materialTypeIndex]?.split(',');
                if (typeNm === inputData[0]) {
                  const usedItems = zaishitsuKubunn?.filter((item) => item.info.isUsed);
                  usedItems?.map((materialTypeItem) => {
                    no = thickIndex + materialTypeIndex + 1 + '行目の';
                    data = dataList?.[thickIndex + materialTypeIndex]?.split(',');
                    for (var x = 0; x < data.length; x++) {
                      if (x < 2) continue;
                      if (data?.[x]?.length <= 0) {
                        // 必須
                        messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
                        ret = false;
                        break;
                      }
                      if (x !== 0 && data?.[x]?.length > 0 && !isDigit(setTimetoSec(data?.[x]))) {
                        // 数字チェック
                        messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
                        ret = false;
                        break;
                      }
                    }
                    materialTypeIndex++;
                  });
                }
              });
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[5] && workTypeIndex + 2 === i) {
        // 加工種類をチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/mm/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.mmThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('加工種類/mm').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[5] && workTypeIndex + 3 === i) {
        // 加工種類/mmをチェック
        let index = 0;
        let data = '';
        let inputData = [];
        if (selectedData?.details?.workTimeSizeHoleThItems) {
          Object.keys(selectedData?.details?.workTimeSizeHoleThItems)
            .filter(([sizeDataKey]) => getProcessDetailTypeName(parseInt(sizeDataKey), processMstRecord) !== undefined)
            ?.map(([key, item], rowIndex) => {
              let typeNm = getProcessDetailTypeName(parseInt(key), processMstRecord);
              inputData = dataList?.[workTypeIndex + 3 + index]?.split(',');
              if (typeNm === inputData[0]) {
                no = workTypeIndex + 3 + index + 1 + '行目の';
                selectedData?.details?.workTimeSizeHoleThItems[key]?.map((item, i) => {
                  data = inputData[i + 1];
                  if (data?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + ErrorMessage(data).E007}</li>);
                    ret = false;
                    return;
                  }
                  if (data?.length > 0 && !isDigit(setTimetoSec(data))) {
                    // フォーマットチェック
                    messageRef.current.push(<li>{no + ErrorMessage(data).E008}</li>);
                    ret = false;
                    return;
                  }
                });
              }
              index++;
            });
        }
        // 詳細入力
        let thickIndex = workTypeIndex + index + 3;
        inputData = dataList?.[thickIndex];
        let detailIndex = 0;
        let materialTypeIndex = 0;
        if (inputData === '詳細入力') {
          thickIndex = thickIndex + 3;
          if (selectedData?.details?.workTimeSizeHoleThItems) {
            Object.keys(selectedData?.details?.workTimeSizeHoleThItems)
              .filter(
                ([sizeDataKey]) => getProcessDetailTypeName(parseInt(sizeDataKey), processMstRecord) !== undefined
              )
              ?.map(([key, item], rowIndex) => {
                detailIndex = 2;
                let typeNm = getProcessDetailTypeName(parseInt(key), processMstRecord);
                inputData = dataList?.[thickIndex + materialTypeIndex]?.split(',');
                if (typeNm === inputData[0]) {
                  const usedItems = zaishitsuKubunn?.filter((item) => item.info.isUsed);
                  usedItems?.map((materialTypeItem) => {
                    no = thickIndex + materialTypeIndex + 1 + '行目の';
                    data = dataList?.[thickIndex + materialTypeIndex]?.split(',');
                    for (var x = 0; x < data.length; x++) {
                      if (x < 2) continue;
                      if (data?.[x]?.length <= 0) {
                        // 必須
                        messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
                        ret = false;
                        break;
                      }
                      if (x !== 0 && data?.[x]?.length > 0 && !isDigit(setTimetoSec(data?.[x]))) {
                        // 数字チェック
                        messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
                        ret = false;
                        break;
                      }
                    }
                    materialTypeIndex++;
                  });
                }
              });
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[6] && modifyTimeIndex + 1 === i) {
        //加工補正時間の値をチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.modifyTimeQtyThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('加工長').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDigit(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[6] && modifyTimeIndex + 2 === i) {
        //加工補正時間の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDigit(setTimetoSec(data?.[x]))) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }

        if (!ret) break;
      } else if (activeTab === detailMenuTab[7] && modifyTimeCoeffIndex + 1 === i) {
        // 加工補正時間係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/cm2/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.mtAreaThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('サイズ係数').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[7] && modifyTimeCoeffIndex + 2 === i) {
        //サイズ係数の値をチェック
        let inputData = [];
        let inVal = '';
        selectedData?.details?.mtWeightThMaster
          ?.filter((item) => !item.isOutOfRange)
          ?.map((item, index) => {
            inputData = dataList?.[i + index]?.split(',');
            no = i + index + 1 + '行目の';
            //係数値
            selectedData?.details?.mtTimeCoeffItems
              ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
              ?.areaCoeffList?.map((area, i) => {
                inVal = inputData[i + 1];
                if (inVal?.length <= 0) {
                  // 必須
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                  ret = false;
                  return;
                }
                if (inVal?.length > 0 && !isDecimal(inVal)) {
                  // 数字チェック
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
                  ret = false;
                  return;
                }
              });
          });
        if (!ret) break;
      } else if (activeTab === detailMenuTab[8] && sizeCoeffIndex + 1 === i) {
        // サイズ係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/cm2/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.prodSizeAreaThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('サイズ係数').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[8] && sizeCoeffIndex + 2 === i) {
        //サイズ係数の値をチェック
        let inputData = [];
        let inVal = '';
        selectedData?.details?.prodSizeWeightThMaster
          ?.filter((item) => !item.isOutOfRange)
          ?.map((item, index) => {
            inputData = dataList?.[i + index]?.split(',');
            no = i + index + 1 + '行目の';
            //係数値
            selectedData?.details?.prodSizeItems
              ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
              ?.areaCoeffList?.map((area, i) => {
                inVal = inputData[i + 1];
                if (inVal?.length <= 0) {
                  // 必須
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                  ret = false;
                  return;
                }
                if (inVal?.length > 0 && !isDecimal(inVal)) {
                  // 数字チェック
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
                  ret = false;
                  return;
                }
              });
          });
        if (!ret) break;
      } else if (activeTab === detailMenuTab[9] && quantityCoeffIndex + 1 === i) {
        //生産個数係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/個/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.productionQtyCoeffThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('生産個数係数').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[9] && quantityCoeffIndex + 2 === i) {
        // 生産個数係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      }
    }
    return ret;
  };

  // 溶接処理のCSVファイルの内容をチェックする
  const weldCSVImportValidation = (dataList, detailMenuTab, messageRef) => {
    let ret = true;
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let dantoriIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    let childQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    let dantoriQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
    );
    let speedTimeIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    let bantsukeIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    let karitsukeThickIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    let karitsukeSizeIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[7] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[7]
    );
    let weldDifficultyCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[8] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[8]
    );
    let sizeCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[9] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[9]
    );
    let quantityCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[10] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[10]
    );

    let processMstRecord = getProcessMstRecordById(selectedData?.processId);
    const groupDisplayList = getProcessDetailTypesInfo(selectedData?.details?.charges, processMstRecord);
    let activeTab = '';
    // CSV入力情報チェック
    for (var i = 0; i < dataList.length; i++) {
      let no = i + 1 + '行目の';
      if (
        chargesIndex === i ||
        dantoriIndex === i ||
        quantityCoeffIndex === i ||
        dantoriQtyIndex === i ||
        childQtyIndex === i ||
        speedTimeIndex === i ||
        bantsukeIndex === i ||
        karitsukeThickIndex === i ||
        karitsukeSizeIndex === i ||
        weldDifficultyCoeffIndex === i ||
        sizeCoeffIndex === i
      )
        activeTab = dataList[i];
      if (activeTab === detailMenuTab[0] && chargesIndex === i) {
        // チャージ金額(/h)の値をチェック
        let inputData = [];
        let data = [];
        let chargesItemIndex = chargesIndex + 1;
        let index = 0;
        if (groupDisplayList) {
          Object.entries(groupDisplayList)?.map(([_, groupItem]) => {
            groupItem?.map((subItem) => {
              getChargesDetailValues(subItem?.id, selectedData?.details?.charges)?.map((item, i) => {
                if (parseInt(item) === subItem?.id && i === 0) return;
                if (index > 0) {
                  chargesItemIndex = chargesItemIndex + 3;
                }
                inputData = dataList?.[chargesItemIndex];
                if (inputData && inputData === subItem?.name) {
                  no = chargesItemIndex + 2 + 1 + '行目の';
                  data = dataList?.[chargesItemIndex + 2]?.split(',');
                  for (var x = 0; x < data.length; x++) {
                    if (data?.[x]?.length <= 0) {
                      // 必須
                      messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
                      ret = false;
                      break;
                    }
                    if (data?.[x]?.length > 0 && !isDigit(data?.[x])) {
                      // 数字チェック
                      messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E009}</li>);
                      ret = false;
                      break;
                    }
                  }
                }
              });
              index++;
            });
          });
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[1] && dantoriIndex === i) {
        // 段取設定の値をチェック
        let chargesItemIndex = i + 1;
        let inputData = [];
        let index = 0;
        let data = [];
        if (groupDisplayList) {
          Object.entries(groupDisplayList)?.map(([_, groupItem]) => {
            groupItem?.map((subItem) => {
              let groupDetail = getPrepSettingDetailValues(subItem?.id, selectedData?.details?.prepSecs);
              groupDetail?.map((item, i) => {
                if (parseInt(item) === subItem?.id && i === 0) return;
                if (index > 0) {
                  chargesItemIndex = chargesItemIndex + 2;
                }
                inputData = dataList?.[chargesItemIndex];
                if (inputData && inputData == subItem?.name) {
                  no = chargesItemIndex + 1 + 1 + '行目の';
                  data = dataList?.[chargesItemIndex + 1];
                  if (data?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + ErrorMessage(data).E007}</li>);
                    ret = false;
                    return;
                  }
                  if (data?.length > 0 && !isDigit(setTimetoSec(data))) {
                    // フォーマットチェック
                    messageRef.current.push(<li>{no + ErrorMessage(data).E008}</li>);
                    ret = false;
                    return;
                  }
                }
              });
              index++;
            });
          });
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[2] && childQtyIndex + 1 === i) {
        // 子部品サイズ係数をチェック
        let dataMain = dataList?.[i];
        let dataQty = dataMain?.replace(/～/g, '');
        dataQty = dataQty?.replace(/cm2/g, '');
        let data = dataQty?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDigit(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[2] && childQtyIndex + 2 === i) {
        // 子部品サイズ係数の値をチェック
        let inputData = [];
        let inVal = '';
        selectedData?.details?.childPartsCoeffWeightThMaster
          ?.filter((item) => !item.isOutOfRange)
          ?.map((item, index) => {
            inputData = dataList?.[i + index]?.split(',');
            no = i + index + 1 + '行目の';
            //係数値
            selectedData?.details?.childPartsCoeff
              ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
              ?.areaCoeffList?.map((area, i) => {
                inVal = inputData[i + 1];
                if (inVal?.length <= 0) {
                  // 必須
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                  ret = false;
                  return;
                }
                if (inVal?.length > 0 && !isDecimal(inVal)) {
                  // 数字チェック
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
                  ret = false;
                  return;
                }
              });
          });
        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && dantoriQtyIndex + 1 === i) {
        // 段取個数係数の値をチェック
        let dataMain = dataList?.[i];
        let dataQty = dataMain?.replace(/～/g, '');
        dataQty = dataQty?.replace(/個/g, '');
        let data = dataQty?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDigit(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && dantoriQtyIndex + 2 === i) {
        // 段取個数係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[4] && speedTimeIndex + 1 === i) {
        // 加工速度(mm/min)をチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/mm/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.workSpeedThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('溶接線長').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[4] && speedTimeIndex + 2 === i) {
        // 加工速度(mm/min)をチェック
        let inVal = '';
        let inputData = [];
        if (selectedData?.details?.workSpeedThItems) {
          const usedIds = processMstRecord?.details?.processDetailTypes
            ?.filter((item) => item.isUsed)
            ?.map((item) => item.id);
          Object.keys(selectedData?.details?.workSpeedThItems)
            ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
            ?.map((key, index) => {
              inputData = dataList?.[speedTimeIndex + 2 + index]?.split(',');
              no = speedTimeIndex + 2 + index + 1 + '行目の';
              let typeNm = processMstRecord?.details?.processDetailTypes?.find(
                (item) => item.id.toString() === key
              )?.name;
              if (typeNm === inputData[0]) {
                selectedData?.details?.workSpeedThItems[key]?.map((item, i) => {
                  inVal = inputData[i + 1];
                  if (inVal?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                    ret = false;
                    return;
                  }
                  if (inVal?.length > 0 && !isDigit(inVal)) {
                    // フォーマットチェック
                    messageRef.current.push(<li>{no + ErrorMessage(inVal).E008}</li>);
                    ret = false;
                    return;
                  }
                });
              }
            });
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[5] && bantsukeIndex + 1 === i) {
        // 仮付時間をチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/t/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.tempInstThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('板厚').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[5] && bantsukeIndex + 2 === i) {
        // 仮付時間をチェック
        let inVal = '';
        let inputData = [];
        if (selectedData?.details?.tempInstThItems) {
          const usedIds = processMstRecord?.details?.processDetailTypes
            ?.filter((item) => item.isUsed)
            ?.map((item) => item.id);
          Object.keys(selectedData?.details?.tempInstThItems)
            ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
            ?.map((key, index) => {
              inputData = dataList?.[bantsukeIndex + 2 + index]?.split(',');
              no = bantsukeIndex + 2 + index + 1 + '行目の';
              let typeNm = processMstRecord?.details?.processDetailTypes?.find(
                (item) => item.id.toString() === key
              )?.name;
              if (typeNm === inputData[0]) {
                selectedData?.details?.tempInstThItems[key]?.map((item, i) => {
                  inVal = inputData[i + 1];
                  if (inVal?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                    ret = false;
                    return;
                  }
                  if (inVal?.length > 0 && !isDigit(setTimetoSec(inVal))) {
                    // フォーマットチェック
                    messageRef.current.push(<li>{no + ErrorMessage(inVal).E008}</li>);
                    ret = false;
                    return;
                  }
                });
              }
            });
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[6] && karitsukeThickIndex + 1 === i) {
        // 取付時間(板厚判断)をチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/t/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.instThickThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('板厚').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[6] && karitsukeThickIndex + 2 === i) {
        // 取付時間(板厚判断)をチェック
        let inVal = '';
        let inputData = [];
        if (selectedData?.details?.instThickThItems) {
          const usedIds = processMstRecord?.details?.processDetailTypes
            ?.filter((item) => item.isUsed)
            ?.map((item) => item.id);
          Object.keys(selectedData?.details?.instThickThItems)
            ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
            ?.map((key, index) => {
              inputData = dataList?.[karitsukeThickIndex + 2 + index]?.split(',');
              no = karitsukeThickIndex + 2 + index + 1 + '行目の';
              let typeNm = processMstRecord?.details?.processDetailTypes?.find(
                (item) => item.id.toString() === key
              )?.name;
              if (typeNm === inputData[0]) {
                selectedData?.details?.instThickThItems[key]?.map((item, i) => {
                  inVal = inputData[i + 1];
                  if (inVal?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                    ret = false;
                    return;
                  }
                  if (inVal?.length > 0 && !isDigit(setTimetoSec(inVal))) {
                    // フォーマットチェック
                    messageRef.current.push(<li>{no + ErrorMessage(inVal).E008}</li>);
                    ret = false;
                    return;
                  }
                });
              }
            });
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[7] && karitsukeSizeIndex + 1 === i) {
        // 取付時間(サイズ判断)をチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/M/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.instSizeThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('サイズ').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[7] && karitsukeSizeIndex + 2 === i) {
        // 取付時間(サイズ判断)をチェック
        let inVal = '';
        let inputData = [];
        if (selectedData?.details?.instSizeThItems) {
          const usedIds = processMstRecord?.details?.processDetailTypes
            ?.filter((item) => item.isUsed)
            ?.map((item) => item.id);
          Object.keys(selectedData?.details?.instSizeThItems)
            ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
            ?.map((key, index) => {
              inputData = dataList?.[karitsukeSizeIndex + 2 + index]?.split(',');
              no = karitsukeSizeIndex + 2 + index + 1 + '行目の';
              let typeNm = processMstRecord?.details?.processDetailTypes?.find(
                (item) => item.id.toString() === key
              )?.name;
              if (typeNm === inputData[0]) {
                selectedData?.details?.instSizeThItems[key]?.map((item, i) => {
                  inVal = inputData[i + 1];
                  if (inVal?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                    ret = false;
                    return;
                  }
                  if (inVal?.length > 0 && !isDecimal(setTimetoSec(inVal))) {
                    // フォーマットチェック
                    messageRef.current.push(<li>{no + ErrorMessage(inVal).E008}</li>);
                    ret = false;
                    return;
                  }
                });
              }
            });
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[8] && weldDifficultyCoeffIndex + 1 === i) {
        //溶接難易度の板厚マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/t/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.weldDifficultyCoeffThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('板厚').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[8] && weldDifficultyCoeffIndex + 2 === i) {
        //溶接難易度をチェック
        let inputData = [];
        let index = 0;
        let inVal = '';
        if (selectedData?.details?.weldDifficultyCoeffThItems) {
          const usedItems = zaishitsuKubunn?.filter((item) => item.info.isUsed);
          const usedIds = usedItems?.map((item) => item.id);
          Object.keys(selectedData?.details?.weldDifficultyCoeffThItems)
            ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
            ?.map((key) => {
              inputData = dataList?.[i + index]?.split(',');
              let typeNm = zaishitsuKubunn?.find((item) => item.id.toString() === key)?.name;
              if (typeNm === inputData[0]) {
                selectedData?.details?.weldDifficultyCoeffThItems[key]?.map((item, i) => {
                  inVal = inputData[i + 1];
                  no = weldDifficultyCoeffIndex + 2 + index + 1;
                  if (inVal?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + '行目の' + ErrorMessage(inVal).E007}</li>);
                    ret = false;
                    return;
                  }
                  if (inVal?.length > 0 && !isDecimal(inVal)) {
                    // 数字チェック
                    messageRef.current.push(<li>{no + '行目の' + ErrorMessage(inVal).E016}</li>);
                    ret = false;
                    return;
                  }
                });
              }
              index++;
            });
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[9] && sizeCoeffIndex + 1 === i) {
        // サイズ係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/cm2/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.prodSizeAreaThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('サイズ係数').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[9] && sizeCoeffIndex + 2 === i) {
        //サイズ係数の値をチェック
        let inputData = [];
        let inVal = '';
        selectedData?.details?.prodSizeWeightThMaster
          ?.filter((item) => !item.isOutOfRange)
          ?.map((item, index) => {
            inputData = dataList?.[i + index]?.split(',');
            no = i + index + 1 + '行目の';
            //係数値
            selectedData?.details?.prodSizeItems
              ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
              ?.areaCoeffList?.map((area, i) => {
                inVal = inputData[i + 1];
                if (inVal?.length <= 0) {
                  // 必須
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                  ret = false;
                  return;
                }
                if (inVal?.length > 0 && !isDecimal(inVal)) {
                  // 数字チェック
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
                  ret = false;
                  return;
                }
              });
          });
        if (!ret) break;
      } else if (activeTab === detailMenuTab[10] && quantityCoeffIndex + 1 === i) {
        //生産個数係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/個/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.productionQtyCoeffThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('生産個数係数').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[10] && quantityCoeffIndex + 2 === i) {
        // 生産個数係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      }
    }
    return ret;
  };

  // 溶接仕上処理のCSVファイルの内容をチェックする
  const weldFinishCSVImportValidation = (dataList, detailMenuTab, messageRef) => {
    let ret = true;
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let dantoriIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    let childQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    let dantoriQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
    );
    let speedTimeIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    let diffFactorCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    let difficultyCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    let sizeCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[7] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[7]
    );
    let quantityCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[8] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[8]
    );

    let processMstRecord = getProcessMstRecordById(selectedData?.processId);
    const groupDisplayList = getProcessDetailTypesInfo(selectedData?.details?.charges, processMstRecord);
    let activeTab = '';
    // CSV入力情報チェック
    for (var i = 0; i < dataList.length; i++) {
      let no = i + 1 + '行目の';
      if (
        chargesIndex === i ||
        dantoriIndex === i ||
        quantityCoeffIndex === i ||
        dantoriQtyIndex === i ||
        childQtyIndex === i ||
        speedTimeIndex === i ||
        diffFactorCoeffIndex === i ||
        difficultyCoeffIndex === i ||
        sizeCoeffIndex === i
      )
        activeTab = dataList[i];
      if (activeTab === detailMenuTab[0] && chargesIndex === i) {
        // チャージ金額(/h)の値をチェック
        let inputData = [];
        let data = [];
        let chargesItemIndex = chargesIndex + 1;
        let index = 0;
        if (groupDisplayList) {
          Object.entries(groupDisplayList)?.map(([_, groupItem]) => {
            groupItem?.map((subItem) => {
              getChargesDetailValues(subItem?.id, selectedData?.details?.charges)?.map((item, i) => {
                if (parseInt(item) === subItem?.id && i === 0) return;
                if (index > 0) {
                  chargesItemIndex = chargesItemIndex + 3;
                }
                inputData = dataList?.[chargesItemIndex];
                if (inputData && inputData === subItem?.name) {
                  no = chargesItemIndex + 2 + 1 + '行目の';
                  data = dataList?.[chargesItemIndex + 2]?.split(',');
                  for (var x = 0; x < data.length; x++) {
                    if (data?.[x]?.length <= 0) {
                      // 必須
                      messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
                      ret = false;
                      break;
                    }
                    if (data?.[x]?.length > 0 && !isDigit(data?.[x])) {
                      // 数字チェック
                      messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E009}</li>);
                      ret = false;
                      break;
                    }
                  }
                }
              });
              index++;
            });
          });
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[1] && dantoriIndex === i) {
        // 段取設定の値をチェック
        let chargesItemIndex = i + 1;
        let inputData = [];
        let index = 0;
        let data = [];
        if (groupDisplayList) {
          Object.entries(groupDisplayList)?.map(([_, groupItem]) => {
            groupItem?.map((subItem) => {
              let groupDetail = getPrepSettingDetailValues(subItem?.id, selectedData?.details?.prepSecs);
              groupDetail?.map((item, i) => {
                if (parseInt(item) === subItem?.id && i === 0) return;
                if (index > 0) {
                  chargesItemIndex = chargesItemIndex + 2;
                }
                inputData = dataList?.[chargesItemIndex];
                if (inputData && inputData == subItem?.name) {
                  no = chargesItemIndex + 1 + 1 + '行目の';
                  data = dataList?.[chargesItemIndex + 1];
                  if (data?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + ErrorMessage(data).E007}</li>);
                    ret = false;
                    return;
                  }
                  if (data?.length > 0 && !isDigit(setTimetoSec(data))) {
                    // フォーマットチェック
                    messageRef.current.push(<li>{no + ErrorMessage(data).E008}</li>);
                    ret = false;
                    return;
                  }
                }
              });
              index++;
            });
          });
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[2] && childQtyIndex + 1 === i) {
        // 子部品サイズ係数をチェック
        let dataMain = dataList?.[i];
        let dataQty = dataMain?.replace(/～/g, '');
        dataQty = dataQty?.replace(/cm2/g, '');
        let data = dataQty?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[2] && childQtyIndex + 2 === i) {
        // 子部品サイズ係数の値をチェック
        let inputData = [];
        let inVal = '';
        selectedData?.details?.childPartsCoeffWeightThMaster
          ?.filter((item) => !item.isOutOfRange)
          ?.map((item, index) => {
            inputData = dataList?.[i + index]?.split(',');
            no = i + index + 1 + '行目の';
            //係数値
            selectedData?.details?.childPartsCoeff
              ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
              ?.areaCoeffList?.map((area, i) => {
                inVal = inputData[i + 1];
                if (inVal?.length <= 0) {
                  // 必須
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                  ret = false;
                  return;
                }
                if (inVal?.length > 0 && !isDecimal(inVal)) {
                  // 数字チェック
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
                  ret = false;
                  return;
                }
              });
          });
        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && dantoriQtyIndex + 1 === i) {
        // 段取個数係数の値をチェック
        let dataMain = dataList?.[i];
        let dataQty = dataMain?.replace(/～/g, '');
        dataQty = dataQty?.replace(/個/g, '');
        let data = dataQty?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && dantoriQtyIndex + 2 === i) {
        // 段取個数係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[4] && speedTimeIndex + 1 === i) {
        // 加工速度(mm/min)をチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/mm/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.workSpeedThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('溶接線長').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[4] && speedTimeIndex + 2 === i) {
        // 加工速度(mm/min)をチェック
        let inVal = '';
        let inputData = [];
        if (selectedData?.details?.workSpeedThItems) {
          const usedIds = processMstRecord?.details?.processDetailTypes
            ?.filter((item) => item.isUsed)
            ?.map((item) => item.id);
          Object.keys(selectedData?.details?.workSpeedThItems)
            ?.filter((listkey) => usedIds?.includes(parseInt(listkey)))
            ?.map((key, index) => {
              inputData = dataList?.[speedTimeIndex + 2 + index]?.split(',');
              no = speedTimeIndex + 2 + index + 1 + '行目の';
              let typeNm = processMstRecord?.details?.processDetailTypes?.find(
                (item) => item.id.toString() === key
              )?.name;
              if (typeNm === inputData[0]) {
                selectedData?.details?.workSpeedThItems[key]?.map((item, i) => {
                  inVal = inputData[i + 1];
                  if (inVal?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                    ret = false;
                    return;
                  }
                  if (inVal?.length > 0 && !isDecimal(inVal)) {
                    // フォーマットチェック
                    messageRef.current.push(<li>{no + ErrorMessage(inVal).E008}</li>);
                    ret = false;
                    return;
                  }
                });
              }
            });
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[5] && diffFactorCoeffIndex + 2 === i) {
        // 溶接難易度をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }

        if (!ret) break;
      } else if (activeTab === detailMenuTab[6] && difficultyCoeffIndex + 2 === i) {
        // 仕上有無をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && typeof Boolean(data?.[x]) !== 'boolean') {
            // Booleanチェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }

        if (!ret) break;
      } else if (activeTab === detailMenuTab[7] && sizeCoeffIndex + 1 === i) {
        // サイズ係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/cm2/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.prodSizeAreaThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('サイズ係数').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[7] && sizeCoeffIndex + 2 === i) {
        //サイズ係数の値をチェック
        let inputData = [];
        let inVal = '';
        selectedData?.details?.prodSizeWeightThMaster
          ?.filter((item) => !item.isOutOfRange)
          ?.map((item, index) => {
            inputData = dataList?.[i + index]?.split(',');
            no = i + index + 1 + '行目の';
            //係数値
            selectedData?.details?.prodSizeItems
              ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
              ?.areaCoeffList?.map((area, i) => {
                inVal = inputData[i + 1];
                if (inVal?.length <= 0) {
                  // 必須
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                  ret = false;
                  return;
                }
                if (inVal?.length > 0 && !isDecimal(inVal)) {
                  // 数字チェック
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
                  ret = false;
                  return;
                }
              });
          });
        if (!ret) break;
      } else if (activeTab === detailMenuTab[8] && quantityCoeffIndex + 1 === i) {
        //生産個数係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/個/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.productionQtyCoeffThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('生産個数係数').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[8] && quantityCoeffIndex + 2 === i) {
        // 生産個数係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      }
    }
    return ret;
  };

  // ベンディン処理のCSVファイルの内容をチェックする
  const bendingCSVImportValidation = (dataList, detailMenuTab, messageRef) => {
    let ret = true;
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let dantoriIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    let programIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    let mageDantoriIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
    );
    let childQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    let dantoriQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    let workTimeIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    let mageCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[7] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[7]
    );
    let modifyTimeIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[8] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[8]
    );
    let modifyTimeCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[9] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[9]
    );
    let sizeCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[10] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[10]
    );
    let quantityCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[11] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[11]
    );
    let processMstRecord = getProcessMstRecordById(selectedData?.processId);
    let activeTab = '';
    // CSV入力情報チェック
    for (var i = 0; i < dataList.length; i++) {
      let no = i + 1 + '行目の';
      if (
        chargesIndex === i ||
        dantoriIndex === i ||
        quantityCoeffIndex === i ||
        dantoriQtyIndex === i ||
        childQtyIndex === i ||
        programIndex === i ||
        mageDantoriIndex === i ||
        workTimeIndex === i ||
        mageCoeffIndex === i ||
        modifyTimeIndex === i ||
        modifyTimeCoeffIndex === i ||
        sizeCoeffIndex === i
      )
        activeTab = dataList[i];
      if (activeTab === detailMenuTab[0] && chargesIndex + 2 === i) {
        // チャージ金額(/h)の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (data?.[x]?.length > 0 && !isDigit(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E009}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[1] && dantoriIndex + 2 === i) {
        // 段取設定の板厚をチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/t/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.basicTimeThickThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('板厚').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[1] && dantoriIndex + 3 === i) {
        //基本段取時間の値をチェック
        let data = '';
        let inputData = [];
        let index = 0;
        if (selectedData?.details?.basicTimeThickItems) {
          Object.entries(selectedData?.details?.basicTimeThickItems)
            ?.filter(([itemKey]) => getProcessDetailTypeName(parseInt(itemKey), processMstRecord) !== undefined)
            ?.map(([key, groupItem]) => {
              inputData = dataList[i + index]?.split(',');
              let groupNm = getProcessDetailTypeName(parseInt(key), processMstRecord);
              if (groupNm === inputData?.[0]) {
                no = i + index + 1 + '行目の';
                selectedData?.details?.basicTimeThickItems?.[parseInt(key)]
                  ?.filter((subitem) => !subitem.isOutOfRange)
                  ?.map((col, index) => {
                    data = inputData[index + 1];
                    if (data?.length <= 0) {
                      // 必須
                      messageRef.current.push(<li>{no + ErrorMessage(data).E007}</li>);
                      ret = false;
                      return;
                    }
                    if (data?.length > 0 && !isDigit(setTimetoSec(data))) {
                      // フォーマットチェック
                      messageRef.current.push(<li>{no + ErrorMessage(data).E008}</li>);
                      ret = false;
                      return;
                    }
                  });
              }
              index++;
            });
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[2] && programIndex + 1 === i) {
        // プログラム作成時間値をチェック
        let dataQty = dataList?.[i]?.replace(/本/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.programCreateTimeCountfThMaster?.filter((item) => !item.isOutOfRange)?.length !==
          masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('本数').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDigit(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[2] && programIndex + 2 === i) {
        // プログラム作成時間の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDigit(setTimetoSec(data?.[x]))) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }

        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && mageDantoriIndex + 1 === i) {
        // 曲長段取時間をチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/mm/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.prepBendTimeLengthMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('曲長').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && mageDantoriIndex + 2 === i) {
        // 曲長段取時間の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDigit(setTimetoSec(data?.[x]))) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }

        if (!ret) break;
      } else if (activeTab === detailMenuTab[4] && childQtyIndex + 1 === i) {
        // 子部品サイズ係数をチェック
        let dataMain = dataList?.[i];
        let dataQty = dataMain?.replace(/～/g, '');
        dataQty = dataQty?.replace(/cm2/g, '');
        let data = dataQty?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[4] && childQtyIndex + 2 === i) {
        // 子部品サイズ係数の値をチェック
        let inputData = [];
        let inVal = '';
        selectedData?.details?.childPartsCoeffWeightThMaster
          ?.filter((item) => !item.isOutOfRange)
          ?.map((item, index) => {
            inputData = dataList?.[i + index]?.split(',');
            no = i + index + 1 + '行目の';
            //係数値
            selectedData?.details?.childPartsCoeff
              ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
              ?.areaCoeffList?.map((area, i) => {
                inVal = inputData[i + 1];
                if (inVal?.length <= 0) {
                  // 必須
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                  ret = false;
                  return;
                }
                if (inVal?.length > 0 && !isDecimal(inVal)) {
                  // 数字チェック
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
                  ret = false;
                  return;
                }
              });
          });
        if (!ret) break;
      } else if (activeTab === detailMenuTab[5] && dantoriQtyIndex + 1 === i) {
        // 段取個数係数の値をチェック
        let dataMain = dataList?.[i];
        let dataQty = dataMain?.replace(/～/g, '');
        dataQty = dataQty?.replace(/個/g, '');
        let data = dataQty?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[5] && dantoriQtyIndex + 2 === i) {
        // 段取個数係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[6] && workTimeIndex + 1 === i) {
        // 作業・加工時間をチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/t/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.workTimeThickThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('板厚').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[6] && workTimeIndex + 2 === i) {
        // 作業・加工時間をチェック
        let inVal = '';
        let inputData = [];
        let index = 0;
        if (selectedData?.details?.workTimeThickItems) {
          Object.entries(selectedData?.details?.workTimeThickItems)
            ?.filter(([itemKey]) => getProcessDetailTypeName(parseInt(itemKey), processMstRecord) !== undefined)
            ?.map(([key, groupItem]) => {
              inputData = dataList[workTimeIndex + index]?.split(',');
              let groupNm = getProcessDetailTypeName(parseInt(key), processMstRecord);
              if (groupNm === inputData?.[0]) {
                selectedData?.details?.workTimeThickItems?.[parseInt(key)]
                  ?.filter((subitem) => !subitem.isOutOfRange)
                  ?.map((col, index) => {
                    inVal = inputData[index + 1];
                    if (inVal?.length <= 0) {
                      // 必須
                      messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                      ret = false;
                      return;
                    }
                    if (x !== 0 && inVal?.length > 0 && !isDigit(setTimetoSec(inVal))) {
                      // 数字チェック
                      messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
                      ret = false;
                      return;
                    }
                  });
              }
              index++;
            });
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[7] && mageCoeffIndex + 1 === i) {
        // 曲げ長さ係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/mm/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.bendTimeCoeffLenThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('kg/mm').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[7] && mageCoeffIndex + 2 === i) {
        //曲げ長さ係数の値をチェック
        let inputData = [];
        let inVal = '';
        selectedData?.details?.bendTimeCoeffWeightThMaster
          ?.filter((item) => !item.isOutOfRange)
          ?.map((item, index) => {
            inputData = dataList?.[i + index]?.split(',');
            no = i + index + 1 + '行目の';
            //係数値
            if (selectedData?.details?.bendTimeCoeffItems) {
              let bendTimeCoeffItems = Object.entries(selectedData?.details?.bendTimeCoeffItems)?.filter(
                ([key, data]) => parseInt(key) === item?.no && !data.isOutOfRange
              )?.[0];
              let bendTimeCoeffs = 0;
              bendTimeCoeffItems?.map((item, index) => {
                if (index === 0) {
                  bendTimeCoeffs = item;
                }
              });
              selectedData?.details?.bendTimeCoeffItems?.[bendTimeCoeffs].map((coef, i) => {
                if (!coef?.isOutOfRange) {
                  inVal = inputData[i + 1];
                  if (inVal?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                    ret = false;
                    return;
                  }
                  if (inVal?.length > 0 && !isDecimal(inVal)) {
                    // 数字チェック
                    messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
                    ret = false;
                    return;
                  }
                }
              });
            }
          });
        if (!ret) break;
      } else if (activeTab === detailMenuTab[8] && modifyTimeIndex + 1 === i) {
        //加工補正時間の値をチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/本/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.modifyTimeQtyThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('加工長').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDigit(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[8] && modifyTimeIndex + 2 === i) {
        //加工補正時間の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDigit(setTimetoSec(data?.[x]))) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }

        if (!ret) break;
      } else if (activeTab === detailMenuTab[9] && modifyTimeCoeffIndex + 1 === i) {
        // 加工補正時間係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/mm/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.modifyCoeffLenThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('kg/mm').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[9] && modifyTimeCoeffIndex + 2 === i) {
        //サイズ係数の値をチェック
        let inputData = [];
        let inVal = '';
        selectedData?.details?.modifyCoeffWeightThMaster
          ?.filter((item) => !item.isOutOfRange)
          ?.map((item, index) => {
            inputData = dataList?.[i + index]?.split(',');
            no = i + index + 1 + '行目の';
            //係数値
            selectedData?.details?.modifyCoeffItems
              ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
              ?.lengthCoeffList?.map((area, i) => {
                inVal = inputData[i + 1];
                if (inVal?.length <= 0) {
                  // 必須
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                  ret = false;
                  return;
                }
                if (inVal?.length > 0 && !isDecimal(inVal)) {
                  // 数字チェック
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
                  ret = false;
                  return;
                }
              });
          });
        if (!ret) break;
      } else if (activeTab === detailMenuTab[10] && sizeCoeffIndex + 1 === i) {
        // サイズ係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/cm2/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.prodSizeAreaThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('サイズ係数').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[10] && sizeCoeffIndex + 2 === i) {
        //サイズ係数の値をチェック
        let inputData = [];
        let inVal = '';
        selectedData?.details?.prodSizeWeightThMaster
          ?.filter((item) => !item.isOutOfRange)
          ?.map((item, index) => {
            inputData = dataList?.[i + index]?.split(',');
            no = i + index + 1 + '行目の';
            //係数値
            selectedData?.details?.prodSizeItems
              ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
              ?.areaCoeffList?.map((area, i) => {
                inVal = inputData[i + 1];
                if (inVal?.length <= 0) {
                  // 必須
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                  ret = false;
                  return;
                }
                if (inVal?.length > 0 && !isDecimal(inVal)) {
                  // 数字チェック
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
                  ret = false;
                  return;
                }
              });
          });
        if (!ret) break;
      } else if (activeTab === detailMenuTab[11] && quantityCoeffIndex + 1 === i) {
        //生産個数係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/個/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.productionQtyCoeffThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('生産個数係数').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[11] && quantityCoeffIndex + 2 === i) {
        // 生産個数係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      }
    }
    return ret;
  };

  //自動バリ処理のCSVファイルの内容をチェックする
  const autoDeburCSVImportValidation = (dataList, detailMenuTab, messageRef) => {
    let ret = true;
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let dantoriIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    let childQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    let dantoriQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
    );
    let workTimeIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    let sizeCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    let quantityCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    let activeTab = '';
    // CSV入力情報チェック
    for (var i = 0; i < dataList.length; i++) {
      let no = i + 1 + '行目の';
      if (
        chargesIndex === i ||
        dantoriIndex === i ||
        quantityCoeffIndex === i ||
        dantoriQtyIndex === i ||
        childQtyIndex === i ||
        workTimeIndex === i ||
        sizeCoeffIndex === i
      )
        activeTab = dataList[i];
      if (activeTab === detailMenuTab[0] && chargesIndex + 2 === i) {
        // チャージ金額(/h)の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (data?.[x]?.length > 0 && !isDigit(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E009}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[1] && dantoriIndex + 2 === i) {
        // 段取設定の値をチェック
        let data = dataList?.[i];
        if (data?.length <= 0) {
          // 必須
          messageRef.current.push(<li>{no + ErrorMessage(data).E007}</li>);
          ret = false;
          break;
        }
        if (data?.length > 0 && !isDigit(setTimetoSec(data))) {
          // フォーマットチェック
          messageRef.current.push(<li>{no + ErrorMessage(data).E008}</li>);
          ret = false;
          break;
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[2] && childQtyIndex + 1 === i) {
        // 子部品サイズ係数をチェック
        let dataMain = dataList?.[i];
        let dataQty = dataMain?.replace(/～/g, '');
        dataQty = dataQty?.replace(/cm2/g, '');
        let data = dataQty?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[2] && childQtyIndex + 2 === i) {
        // 子部品サイズ係数の値をチェック
        let inputData = [];
        let inVal = '';
        selectedData?.details?.childPartsCoeffWeightThMaster
          ?.filter((item) => !item.isOutOfRange)
          ?.map((item, index) => {
            inputData = dataList?.[i + index]?.split(',');
            no = i + index + 1 + '行目の';
            //係数値
            selectedData?.details?.childPartsCoeff
              ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
              ?.areaCoeffList?.map((area, i) => {
                inVal = inputData[i + 1];
                if (inVal?.length <= 0) {
                  // 必須
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                  ret = false;
                  return;
                }
                if (inVal?.length > 0 && !isDecimal(inVal)) {
                  // 数字チェック
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
                  ret = false;
                  return;
                }
              });
          });
        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && dantoriQtyIndex + 1 === i) {
        // 段取個数係数の値をチェック
        let dataMain = dataList?.[i];
        let dataQty = dataMain?.replace(/～/g, '');
        dataQty = dataQty?.replace(/個/g, '');
        let data = dataQty?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && dantoriQtyIndex + 2 === i) {
        // 段取個数係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[4] && workTimeIndex + 1 === i) {
        // 作業・加工時間をチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/cm2/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.areaThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('kg/cm2').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[4] && workTimeIndex + 2 === i) {
        // 作業・加工時間をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDigit(setTimetoSec(data?.[x]))) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[5] && sizeCoeffIndex + 1 === i) {
        // サイズ係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/cm2/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.prodSizeAreaThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('サイズ係数').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[5] && sizeCoeffIndex + 2 === i) {
        //サイズ係数の値をチェック
        let inputData = [];
        let inVal = '';
        selectedData?.details?.prodSizeWeightThMaster
          ?.filter((item) => !item.isOutOfRange)
          ?.map((item, index) => {
            inputData = dataList?.[i + index]?.split(',');
            no = i + index + 1 + '行目の';
            //係数値
            selectedData?.details?.prodSizeItems
              ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
              ?.areaCoeffList?.map((area, i) => {
                inVal = inputData[i + 1];
                if (inVal?.length <= 0) {
                  // 必須
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                  ret = false;
                  return;
                }
                if (inVal?.length > 0 && !isDecimal(inVal)) {
                  // 数字チェック
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
                  ret = false;
                  return;
                }
              });
          });
        if (!ret) break;
      } else if (activeTab === detailMenuTab[6] && quantityCoeffIndex + 1 === i) {
        //生産個数係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/個/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.productionQtyCoeffThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('生産個数係数').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[6] && quantityCoeffIndex + 2 === i) {
        // 生産個数係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      }
    }
    return ret;
  };

  // 手動バリ処理のCSVファイルの内容をチェックする
  const manualDeburCSVImportValidation = (dataList, detailMenuTab, messageRef) => {
    let ret = true;
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let dantoriIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    let childQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    let dantoriQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
    );
    let speedTimeIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    let speedCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    let modifyTimeIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    let modifyTimeCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[7] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[7]
    );
    let diaIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[8] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[8]
    );
    let sizeCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[9] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[9]
    );
    let quantityCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[10] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[10]
    );
    let activeTab = '';
    // CSV入力情報チェック
    for (var i = 0; i < dataList.length; i++) {
      let no = i + 1 + '行目の';
      if (
        chargesIndex === i ||
        dantoriIndex === i ||
        quantityCoeffIndex === i ||
        dantoriQtyIndex === i ||
        speedTimeIndex === i ||
        childQtyIndex === i ||
        modifyTimeIndex === i ||
        modifyTimeCoeffIndex === i ||
        sizeCoeffIndex === i ||
        diaIndex === i ||
        speedCoeffIndex === i
      )
        activeTab = dataList[i];
      if (activeTab === detailMenuTab[0] && chargesIndex + 2 === i) {
        // チャージ金額(/h)の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (data?.[x]?.length > 0 && !isDigit(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E009}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[1] && dantoriIndex + 2 === i) {
        // 段取設定の値をチェック
        let data = dataList?.[i];
        if (data?.length <= 0) {
          // 必須
          messageRef.current.push(<li>{no + ErrorMessage(data).E007}</li>);
          ret = false;
          break;
        }
        if (data?.length > 0 && !isDigit(setTimetoSec(data))) {
          // フォーマットチェック
          messageRef.current.push(<li>{no + ErrorMessage(data).E008}</li>);
          ret = false;
          break;
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[2] && childQtyIndex + 1 === i) {
        // 子部品サイズ係数をチェック
        let dataMain = dataList?.[i];
        let dataQty = dataMain?.replace(/～/g, '');
        dataQty = dataQty?.replace(/cm2/g, '');
        let data = dataQty?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[2] && childQtyIndex + 2 === i) {
        // 子部品サイズ係数の値をチェック
        let inputData = [];
        let inVal = '';
        selectedData?.details?.childPartsCoeffWeightThMaster
          ?.filter((item) => !item.isOutOfRange)
          ?.map((item, index) => {
            inputData = dataList?.[i + index]?.split(',');
            no = i + index + 1 + '行目の';
            //係数値
            selectedData?.details?.childPartsCoeff
              ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
              ?.areaCoeffList?.map((area, i) => {
                inVal = inputData[i + 1];
                if (inVal?.length <= 0) {
                  // 必須
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                  ret = false;
                  return;
                }
                if (inVal?.length > 0 && !isDecimal(inVal)) {
                  // 数字チェック
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
                  ret = false;
                  return;
                }
              });
          });
        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && dantoriQtyIndex + 1 === i) {
        // 段取個数係数の値をチェック
        let dataMain = dataList?.[i];
        let dataQty = dataMain?.replace(/～/g, '');
        dataQty = dataQty?.replace(/個/g, '');
        let data = dataQty?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && dantoriQtyIndex + 2 === i) {
        // 段取個数係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[4] && speedTimeIndex + 1 === i) {
        // 加工速度(mm/min)をチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/t/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.workSpeedThickThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('板厚').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[4] && speedTimeIndex + 2 === i) {
        // 加工速度(mm/min)をチェック
        let inputData = [];
        let inVal = '';
        selectedData?.details?.workSpeedLenThMaster
          ?.filter((item) => !item.isOutOfRange)
          ?.map((item, index) => {
            inputData = dataList?.[speedTimeIndex + 2 + index]?.split(',');
            no = speedTimeIndex + 2 + index + +'行目の';
            selectedData?.details?.workSpeedItems
              ?.filter((itemCoeff) => itemCoeff.lengthId === item.no)?.[0]
              ?.itaatsuCoeffList?.map((itaatsu, i) => {
                inVal = inputData[i + 1];
                if (inVal?.length <= 0) {
                  // 必須
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                  ret = false;
                  return;
                }
                if (x !== 0 && inVal?.length > 0 && !isDecimal(inVal)) {
                  // 数字チェック
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
                  ret = false;
                  return;
                }
              });
          });
        if (!ret) break;
      } else if (activeTab === detailMenuTab[5] && speedCoeffIndex + 1 === i) {
        //加工速度係数の値をチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/mm/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.workSpeedCoeffLengthThMaster?.filter((item) => !item.isOutOfRange)?.length !==
          masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('加工長').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[5] && speedCoeffIndex + 2 === i) {
        //内加工
        // 加工速度係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[5] && speedCoeffIndex + 3 === i) {
        //外加工
        // 加工速度係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[6] && modifyTimeIndex + 1 === i) {
        //加工補正時間の値をチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.modifyTimeQtyThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('加工長').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[6] && modifyTimeIndex + 2 === i) {
        //加工補正時間の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDigit(setTimetoSec(data?.[x]))) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }

        if (!ret) break;
      } else if (activeTab === detailMenuTab[7] && modifyTimeCoeffIndex + 1 === i) {
        // 加工補正時間係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/cm/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.mtAreaThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('kg/cm2').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[7] && modifyTimeCoeffIndex + 2 === i) {
        //サイズ係数の値をチェック
        let inputData = [];
        let inVal = '';
        selectedData?.details?.mtWeightThMaster
          ?.filter((item) => !item.isOutOfRange)
          ?.map((item, index) => {
            inputData = dataList?.[i + index]?.split(',');
            no = i + index + 1 + '行目の';
            //係数値
            selectedData?.details?.mtTimeCoeffItems
              ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
              ?.areaCoeffList?.map((area, i) => {
                inVal = inputData[i + 1];
                if (inVal?.length <= 0) {
                  // 必須
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                  ret = false;
                  return;
                }
                if (inVal?.length > 0 && !isDecimal(inVal)) {
                  // 数字チェック
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
                  ret = false;
                  return;
                }
              });
          });
        if (!ret) break;
      } else if (activeTab === detailMenuTab[8] && diaIndex + 2 === i) {
        // 手動バリ取対象サイズの値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E009}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[9] && sizeCoeffIndex + 1 === i) {
        // サイズ係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/cm2/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (selectedData?.details?.prodSizeAreaThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('サイズ係数').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[9] && sizeCoeffIndex + 2 === i) {
        //サイズ係数の値をチェック
        let inputData = [];
        let inVal = '';
        selectedData?.details?.prodSizeWeightThMaster
          ?.filter((item) => !item.isOutOfRange)
          ?.map((item, index) => {
            inputData = dataList?.[i + index]?.split(',');
            no = i + index + 1 + '行目の';
            //係数値
            selectedData?.details?.prodSizeItems
              ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
              ?.areaCoeffList?.map((area, i) => {
                inVal = inputData[i + 1];
                if (inVal?.length <= 0) {
                  // 必須
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                  ret = false;
                  return;
                }
                if (inVal?.length > 0 && !isDecimal(inVal)) {
                  // 数字チェック
                  messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
                  ret = false;
                  return;
                }
              });
          });
        if (!ret) break;
      } else if (activeTab === detailMenuTab[10] && quantityCoeffIndex + 1 === i) {
        //生産個数係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/個/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.productionQtyCoeffThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('生産個数係数').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[10] && quantityCoeffIndex + 2 === i) {
        // 生産個数係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      }
    }
    return ret;
  };

  // 検査処理のCSVファイルの内容をチェックする
  const kensaCSVImportValidation = (dataList, detailMenuTab, messageRef) => {
    let ret = true;
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let dantoriIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    let childQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    let dantoriQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
    );
    let workTimeIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    let sizeCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    let quantityCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    let processMstRecord = getProcessMstRecordById(selectedData?.processId);
    let activeTab = '';
    // CSV入力情報チェック
    for (var i = 0; i < dataList.length; i++) {
      let no = i + 1 + '行目の';
      if (
        chargesIndex === i ||
        dantoriIndex === i ||
        quantityCoeffIndex === i ||
        dantoriQtyIndex === i ||
        childQtyIndex === i ||
        workTimeIndex === i ||
        sizeCoeffIndex === i
      )
        activeTab = dataList[i];
      if (activeTab === detailMenuTab[0] && chargesIndex + 2 === i) {
        // チャージ金額(/h)の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (data?.[x]?.length > 0 && !isDigit(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E009}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[1] && dantoriIndex + 2 === i) {
        // 段取設定の値をチェック
        let data = dataList?.[i];
        if (data?.length <= 0) {
          // 必須
          messageRef.current.push(<li>{no + ErrorMessage(data).E007}</li>);
          ret = false;
          break;
        }
        if (data?.length > 0 && !isDigit(setTimetoSec(data))) {
          // フォーマットチェック
          messageRef.current.push(<li>{no + ErrorMessage(data).E008}</li>);
          ret = false;
          break;
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[2] && childQtyIndex + 1 === i) {
        // 子部品サイズ係数をチェック
        let dataMain = dataList?.[i];
        let dataQty = dataMain?.replace(/～/g, '');
        if (
          processMstRecord?.workType === WorkType.SmInspection &&
          processMstRecord?.class === ServiceClass.SheetMetal
        ) {
          dataQty = dataQty?.replace(/cm2/g, '');
        } else {
          dataQty = dataQty?.replace(/個/g, '');
        }
        let data = dataQty?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[2] && childQtyIndex + 2 === i) {
        // 子部品サイズ係数の値をチェック
        let inputData = [];
        let inVal = '';
        if (
          processMstRecord?.workType === WorkType.SmInspection &&
          processMstRecord?.class === ServiceClass.SheetMetal
        ) {
          selectedData?.details?.childPartsCoeffWeightThMaster
            ?.filter((item) => !item.isOutOfRange)
            ?.map((item, index) => {
              inputData = dataList?.[i + index]?.split(',');
              no = i + index + 1 + '行目の';
              //係数値
              selectedData?.details?.childPartsCoeff
                ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
                ?.areaCoeffList?.map((area, i) => {
                  inVal = inputData[i + 1];
                  if (inVal?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                    ret = false;
                    return;
                  }
                  if (inVal?.length > 0 && !isDecimal(inVal)) {
                    // 数字チェック
                    messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
                    ret = false;
                    return;
                  }
                });
            });
        } else {
          let data = dataList?.[i]?.split(',');
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && dantoriQtyIndex + 1 === i) {
        // 段取個数係数の値をチェック
        let dataMain = dataList?.[i];
        let dataQty = dataMain?.replace(/～/g, '');
        if (
          processMstRecord?.workType === WorkType.SmInspection &&
          processMstRecord?.class === ServiceClass.SheetMetal
        ) {
          dataQty = dataQty?.replace(/個/g, '');
        } else {
          dataQty = dataQty?.replace(/kg/g, '');
        }
        let data = dataQty?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && dantoriQtyIndex + 2 === i) {
        // 段取個数係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[4] && workTimeIndex + 2 === i) {
        // 作業・加工時間をチェック
        let data = dataList?.[i];
        if (data?.length <= 0) {
          // 必須
          messageRef.current.push(<li>{no + ErrorMessage(data).E007}</li>);
          ret = false;
          break;
        }
        if (data?.length > 0 && !isDigit(setTimetoSec(data))) {
          // フォーマットチェック
          messageRef.current.push(<li>{no + ErrorMessage(data).E008}</li>);
          ret = false;
          break;
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[5] && sizeCoeffIndex + 1 === i) {
        // サイズ係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        if (
          processMstRecord?.workType === WorkType.SmInspection &&
          processMstRecord?.class === ServiceClass.SheetMetal
        ) {
          dataQty = dataQty?.replace(/cm2/g, '');
        } else {
          dataQty = dataQty?.replace(/kg/g, '');
        }
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        let master =
          processMstRecord?.workType === WorkType.SmInspection && processMstRecord?.class === ServiceClass.SheetMetal
            ? selectedData?.details?.prodSizeAreaThMaster
            : selectedData?.details?.prodWeightCoeffThMaster;
        if (master?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('マスター').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[5] && sizeCoeffIndex + 2 === i) {
        //サイズ係数の値をチェック
        let inputData = [];
        let inVal = '';
        if (
          processMstRecord?.workType === WorkType.SmInspection &&
          processMstRecord?.class === ServiceClass.SheetMetal
        ) {
          selectedData?.details?.prodSizeWeightThMaster
            ?.filter((item) => !item.isOutOfRange)
            ?.map((item, index) => {
              inputData = dataList?.[i + index]?.split(',');
              no = i + index + 1 + '行目の';
              //係数値
              selectedData?.details?.prodSizeItems
                ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
                ?.areaCoeffList?.map((area, i) => {
                  inVal = inputData[i + 1];
                  if (inVal?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                    ret = false;
                    return;
                  }
                  if (inVal?.length > 0 && !isDecimal(inVal)) {
                    // 数字チェック
                    messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
                    ret = false;
                    return;
                  }
                });
            });
        } else {
          let data = dataList?.[i]?.split(',');
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[6] && quantityCoeffIndex + 1 === i) {
        //生産個数係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/個/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.productionQtyCoeffThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('生産個数係数').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[6] && quantityCoeffIndex + 2 === i) {
        // 生産個数係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      }
    }
    return ret;
  };

  // 追加工程処理のCSVファイルの内容をチェックする
  const customCSVImportValidation = (dataList, detailMenuTab, messageRef) => {
    let ret = true;
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let dantoriIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    let childQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    let dantoriQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
    );
    let workTimeIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    let sizeCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    let quantityCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    let commissionIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[7] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[7]
    );
    let processMstRecord = getProcessMstRecordById(selectedData?.processId);
    let activeTab = '';
    // CSV入力情報チェック
    for (var i = 0; i < dataList.length; i++) {
      let no = i + 1 + '行目の';
      if (
        chargesIndex === i ||
        dantoriIndex === i ||
        quantityCoeffIndex === i ||
        dantoriQtyIndex === i ||
        childQtyIndex === i ||
        workTimeIndex === i ||
        sizeCoeffIndex === i ||
        commissionIndex === i
      )
        activeTab = dataList[i];
      if (activeTab === detailMenuTab[0] && chargesIndex + 2 === i) {
        // チャージ金額(/h)の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (data?.[x]?.length > 0 && !isDigit(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E009}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[1] && dantoriIndex + 2 === i) {
        // 段取設定の値をチェック
        let data = dataList?.[i];
        if (data?.length <= 0) {
          // 必須
          messageRef.current.push(<li>{no + ErrorMessage(data).E007}</li>);
          ret = false;
          break;
        }
        if (data?.length > 0 && !isDigit(setTimetoSec(data))) {
          // フォーマットチェック
          messageRef.current.push(<li>{no + ErrorMessage(data).E008}</li>);
          ret = false;
          break;
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[2] && childQtyIndex + 1 === i) {
        // 子部品サイズ係数をチェック
        let dataMain = dataList?.[i];
        let dataQty = dataMain?.replace(/～/g, '');
        if (processMstRecord?.workType === WorkType.SmCustom && processMstRecord?.class === ServiceClass.SheetMetal) {
          dataQty = dataQty?.replace(/cm2/g, '');
        } else {
          dataQty = dataQty?.replace(/個/g, '');
        }
        let data = dataQty?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[2] && childQtyIndex + 2 === i) {
        // 子部品サイズ係数の値をチェック
        let inputData = [];
        let inVal = '';
        if (processMstRecord?.workType === WorkType.SmCustom && processMstRecord?.class === ServiceClass.SheetMetal) {
          selectedData?.details?.childPartsCoeffWeightThMaster
            ?.filter((item) => !item.isOutOfRange)
            ?.map((item, index) => {
              inputData = dataList?.[i + index]?.split(',');
              no = i + index + 1 + '行目の';
              //係数値
              selectedData?.details?.childPartsCoeff
                ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
                ?.areaCoeffList?.map((area, i) => {
                  inVal = inputData[i + 1];
                  if (inVal?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                    ret = false;
                    return;
                  }
                  if (inVal?.length > 0 && !isDecimal(inVal)) {
                    // 数字チェック
                    messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
                    ret = false;
                    return;
                  }
                });
            });
        } else {
          // 段取個数係数の値をチェック
          let data = dataList?.[i]?.split(',');
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && dantoriQtyIndex + 1 === i) {
        // 段取個数係数の値をチェック
        let dataMain = dataList?.[i];
        let dataQty = dataMain?.replace(/～/g, '');
        if (processMstRecord?.workType === WorkType.SmCustom && processMstRecord?.class === ServiceClass.SheetMetal) {
          dataQty = dataQty?.replace(/個/g, '');
        } else {
          dataQty = dataQty?.replace(/kg/g, '');
        }
        let data = dataQty?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && dantoriQtyIndex + 2 === i) {
        // 段取個数係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[4] && workTimeIndex + 2 === i) {
        // 作業・加工時間をチェック
        let data = dataList?.[i];
        if (data?.length <= 0) {
          // 必須
          messageRef.current.push(<li>{no + ErrorMessage(data).E007}</li>);
          ret = false;
          break;
        }
        if (data?.length > 0 && !isDigit(setTimetoSec(data))) {
          // フォーマットチェック
          messageRef.current.push(<li>{no + ErrorMessage(data).E008}</li>);
          ret = false;
          break;
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[5] && sizeCoeffIndex + 1 === i) {
        // サイズ係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        if (processMstRecord?.workType === WorkType.SmCustom && processMstRecord?.class === ServiceClass.SheetMetal) {
          dataQty = dataQty?.replace(/cm2/g, '');
        } else {
          dataQty = dataQty?.replace(/kg/g, '');
        }
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        let master =
          processMstRecord?.workType === WorkType.SmCustom && processMstRecord?.class === ServiceClass.SheetMetal
            ? selectedData?.details?.prodSizeAreaThMaster
            : selectedData?.details?.prodWeightCoeffThMaster;
        if (master?.filter((item) => !item.isOutOfRange)?.length !== masterLen) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('マスター').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[5] && sizeCoeffIndex + 2 === i) {
        //サイズ係数の値をチェック
        let inputData = [];
        let inVal = '';
        if (processMstRecord?.workType === WorkType.SmCustom && processMstRecord?.class === ServiceClass.SheetMetal) {
          selectedData?.details?.prodSizeWeightThMaster
            ?.filter((item) => !item.isOutOfRange)
            ?.map((item, index) => {
              inputData = dataList?.[i + index]?.split(',');
              no = i + index + 1 + '行目の';
              //係数値
              selectedData?.details?.prodSizeItems
                ?.filter((itemCoeff) => itemCoeff.weightId === item.no)?.[0]
                ?.areaCoeffList?.map((area, i) => {
                  inVal = inputData[i + 1];
                  if (inVal?.length <= 0) {
                    // 必須
                    messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
                    ret = false;
                    return;
                  }
                  if (inVal?.length > 0 && !isDecimal(inVal)) {
                    // 数字チェック
                    messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
                    ret = false;
                    return;
                  }
                });
            });
        } else {
          let data = dataList?.[i]?.split(',');
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[6] && quantityCoeffIndex + 1 === i) {
        //生産個数係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/個/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.productionQtyCoeffThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('生産個数係数').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[6] && quantityCoeffIndex + 2 === i) {
        // 生産個数係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[7] && commissionIndex + 1 === i) {
        // 委託装置詳細の値をチェック
        let inputData = dataList?.[commissionIndex + 1]?.split(',');
        if (inputData?.[0] === '委託工程') {
          no = commissionIndex + 1 + 1 + '行目の';
          if (x !== 0 && inputData?.[1]?.length > 0 && typeof Boolean(inputData?.[1]) !== 'boolean') {
            // Booleanチェック
            messageRef.current.push(<li>{no + ErrorMessage(inputData?.[1]).E016}</li>);
            ret = false;
            break;
          }
        }
        inputData = dataList?.[commissionIndex + 2]?.split(',');
        no = commissionIndex + 2 + 1 + '行目の';
        let customerNm = '';
        let customers = [];
        if (inputData?.[0] === '取引先(委託先)') {
          customerNm = inputData?.[1];
          customers = props?.getCustomers?.data?.filter((item) => item.name === customerNm);
          if (customers?.length > 0) {
            messageRef.current.push(<li>{no + ErrorMessage(inputData?.[1]).E012}</li>);
            ret = false;
            break;
          }
        }

        inputData = dataList?.[commissionIndex + 3]?.split(',');
        no = commissionIndex + 3 + 1 + '行目の';
        if (inputData?.[0] === '取引先コード') {
          if (customerNm !== '' && inputData?.[1] !== '') {
            customers = props?.getCustomers?.data?.filter(
              (item) => item.code === inputData?.[1] && item.name === customerNm
            );
          } else if (customerNm !== '' && inputData?.[1] === '') {
            customers = props?.getCustomers?.data?.filter((item) => item.name === customerNm);
          } else if (customerNm === '' && inputData?.[1] !== '') {
            customers = props?.getCustomers?.data?.filter((item) => item.code === inputData?.[1]);
          }
          if (customers?.length > 0) {
            messageRef.current.push(<li>{no + ErrorMessage(inputData?.[1]).E012}</li>);
            ret = false;
            break;
          }
        }

        inputData = dataList?.[commissionIndex + 4]?.split(',');
        if (inputData?.[0] === '手入力フラグ') {
          no = commissionIndex + 4 + 1 + '行目の';
          if (x !== 0 && inputData?.[1]?.length > 0 && typeof Boolean(inputData?.[1]) !== 'boolean') {
            // Booleanチェック
            messageRef.current.push(<li>{no + ErrorMessage(inputData?.[1]).E016}</li>);
            ret = false;
            break;
          }
        }

        inputData = dataList?.[commissionIndex + 5]?.split(',');
        no = commissionIndex + 5 + 1 + '行目の';
        if (inputData?.[0] === '初期金額(手入力時)') {
          if (inputData?.[1]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(inputData?.[1]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && inputData?.[1]?.length > 0 && !isDigit(inputData?.[1])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(inputData?.[1]).E016}</li>);
            ret = false;
            break;
          }
        }

        if (!ret) break;
      }
    }
    return ret;
  };

  // 組立・梱包処理のCSVファイルの内容をチェックする
  const assenblePackingCSVImportValidation = (dataList, detailMenuTab, messageRef) => {
    let ret = true;
    let chargesIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[0] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[0]
    );
    let dantoriIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[1] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[1]
    );
    let childQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[2] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[2]
    );
    let dantoriQtyIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[3] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[3]
    );
    let workTimeIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[4] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[4]
    );
    let sizeCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[5] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[5]
    );
    let quantityCoeffIndex = dataList?.findIndex(
      (item, index) => item === detailMenuTab[6] || dataList?.[index]?.split(',')?.[0] === detailMenuTab[6]
    );
    let activeTab = '';
    // CSV入力情報チェック
    for (var i = 0; i < dataList.length; i++) {
      let no = i + 1 + '行目の';
      if (
        chargesIndex === i ||
        dantoriIndex === i ||
        quantityCoeffIndex === i ||
        dantoriQtyIndex === i ||
        childQtyIndex === i ||
        workTimeIndex === i ||
        sizeCoeffIndex === i
      )
        activeTab = dataList[i];
      if (activeTab === detailMenuTab[0] && chargesIndex + 2 === i) {
        // チャージ金額(/h)の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (data?.[x]?.length > 0 && !isDigit(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E009}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[1] && dantoriIndex + 2 === i) {
        // 段取設定の値をチェック
        let data = dataList?.[i];
        if (data?.length <= 0) {
          // 必須
          messageRef.current.push(<li>{no + ErrorMessage(data).E007}</li>);
          ret = false;
          break;
        }
        if (data?.length > 0 && !isDigit(setTimetoSec(data))) {
          // フォーマットチェック
          messageRef.current.push(<li>{no + ErrorMessage(data).E008}</li>);
          ret = false;
          break;
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[2] && childQtyIndex + 1 === i) {
        // 段取個数係数1をチェック
        let dataMain = dataList?.[i];
        let dataQty = dataMain?.replace(/～/g, '');
        dataQty = dataQty?.replace(/個/g, '');
        let data = dataQty?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[2] && childQtyIndex + 2 === i) {
        // 段取個数係数1の値をチェック
        let inputData = [];
        let inVal = '';
        inputData = dataList?.[i]?.split(',');
        no = childQtyIndex + 2 + 1 + '行目の';
        selectedData?.details?.prepCoeffThChildQtyItems
          ?.filter((item) => !item.isOutOfRange)
          ?.map((item, index) => {
            inVal = inputData[index + 1];
            if (inVal?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(inVal).E007}</li>);
              ret = false;
              return;
            }
            if (inVal?.length > 0 && !isDecimal(inVal)) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(inVal).E016}</li>);
              ret = false;
              return;
            }
          });
        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && dantoriQtyIndex + 1 === i) {
        // 段取個数係数2の値をチェック
        let dataMain = dataList?.[i];
        let dataQty = dataMain?.replace(/～/g, '');
        dataQty = dataQty?.replace(/kg/g, '');
        let data = dataQty?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[3] && dantoriQtyIndex + 2 === i) {
        // 段取個数係数2の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[4] && workTimeIndex + 2 === i) {
        // 作業・加工時間をチェック
        let data = dataList?.[i];
        if (data?.length <= 0) {
          // 必須
          messageRef.current.push(<li>{no + ErrorMessage(data).E007}</li>);
          ret = false;
          break;
        }
        if (data?.length > 0 && !isDigit(setTimetoSec(data))) {
          // フォーマットチェック
          messageRef.current.push(<li>{no + ErrorMessage(data).E008}</li>);
          ret = false;
          break;
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[5] && sizeCoeffIndex + 1 === i) {
        // 重量係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/kg/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.prodWeightCoeffThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('サイズ係数').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[5] && sizeCoeffIndex + 2 === i) {
        //重量係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (x === 0) continue;
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[6] && quantityCoeffIndex + 1 === i) {
        //生産個数係数マスターをチェック
        let dataQty = dataList?.[i]?.replace(/～/g, '');
        dataQty = dataQty?.replace(/個/g, '');
        let data = dataQty?.split(',');
        let masterLen = data.length - 1;
        if (
          selectedData?.details?.productionQtyCoeffThMaster?.filter((item) => !item.isOutOfRange)?.length !== masterLen
        ) {
          // マスタ件数チェック
          messageRef.current.push(<li>{no + ErrorMessage('生産個数係数').E015}</li>);
          ret = false;
        } else {
          for (var x = 0; x < data.length; x++) {
            if (x === 0) continue;
            if (data?.[x]?.length <= 0) {
              // 必須
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
              ret = false;
              break;
            }
            if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
              // 数字チェック
              messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
              ret = false;
              break;
            }
          }
        }
        if (!ret) break;
      } else if (activeTab === detailMenuTab[6] && quantityCoeffIndex + 2 === i) {
        // 生産個数係数の値をチェック
        let data = dataList?.[i]?.split(',');
        for (var x = 0; x < data.length; x++) {
          if (data?.[x]?.length <= 0) {
            // 必須
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E007}</li>);
            ret = false;
            break;
          }
          if (x !== 0 && data?.[x]?.length > 0 && !isDecimal(data?.[x])) {
            // 数字チェック
            messageRef.current.push(<li>{no + ErrorMessage(data?.[x]).E016}</li>);
            ret = false;
            break;
          }
        }
        if (!ret) break;
      }
    }
    return ret;
  };

  return (
    <>
      <div
        style={{
          height: 870,
          marginLeft: 10,
        }}
      >
        <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1}>
          <div>
            <Row className="mt-2" align="middle">
              <Col span={2} style={{ textAlign: 'center' }}>
                <label>工程名：</label>
              </Col>
              <Col span={6}>
                <Select
                  className="yousetsu-select search-select-input"
                  // className="yousetsu-select"
                  placeholder="選択"
                  style={{ fontSize: 11, width: 205 }}
                  // onChange={handleSelectVal}
                  onChange={(e) => beforeSearch(e, 'koteimei')}
                  value={koteimei}
                  defaultValue={'全て'}
                  name="koteimei"
                >
                  <Select.Option value={'全て'}>全て</Select.Option>

                  {kouteiMaster?.map((item, index) => (
                    <Select.Option key={index} value={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col span={7}>
                <Input
                  style={{ marginLeft: 20, width: 205 }}
                  // className="input-editable"
                  className="input-editable search-select-input"
                  placeholder="装置名"
                  allowClear
                  onPressEnter={(e) => beforeSearch(e, 'name')}
                  onBlur={(e) => beforeSearch(e, 'name')}
                  // onChange={(e) => {
                  //   if (e.target.value === '') {
                  //     beforeSearch(e, 'name');
                  //   }
                  // }}
                  name="name"
                  id="name"
                  onChange={(e) => beforeSearch(e, 'name')}
                  value={nameKeyword}
                />
              </Col>
              <Col>
                <div>
                  <label>表示：</label>
                  <Select
                    style={{ width: 90 }}
                    onChange={(e) => beforeSearch(e, 'dataStateDrop')}
                    id="dataStateDrop"
                    name="dataStateDrop"
                    value={dataState}
                  >
                    {dataStateList?.map((item, index) => (
                      <Select.Option key={index} id={'dataState' + item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col span={24}>
                <Table
                  showSorterTooltip={false}
                  className="tblCustomerList"
                  columns={tableColumns}
                  scroll={{ y: 590, x: '10vw' }}
                  dataSource={initialTblItems}
                  rowKey={(record) => record?.id}
                  rowClassName={(record) => (record?.id == selectedRowKeys ? 'active-row' : 'data-row')}
                  pagination={{
                    position: ['bottomLeft'],
                    total: totalCount,
                    showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                    // defaultPageSize: 25, // 本番値確認必要
                    defaultCurrent: 1,
                    current: currentPage,
                    pageSize: pageSize,
                    pageSizeOptions: pageSizes,
                    showSizeChanger: true,
                    onShowSizeChange: handlePageSizeChange,
                    onChange: async (page, pageSize) => {
                      setCurrentPage(page);
                    },
                  }}
                  onRow={(record, index) => {
                    return {
                      onClick: (event) => {
                        onRowSelect(record);
                      },
                    };
                  }}
                  onChange={handleTableChange}
                  loading={tblLoading}
                ></Table>
              </Col>
            </Row>
          </div>
          <div>
            <Routes>
              <Route
                exact
                path=":iq3.device"
                element={
                  <DevicesDetails
                    flag={flag}
                    updatedFlag={updateFlag}
                    editMode={editMode}
                    selectedData={selectedData}
                    updatedDetails={updatedDetailsData}
                    getProcessMstRecordById={getProcessMstRecordById}
                    getMaterialTypes={zaishitsuKubunn}
                    updateEditMode={updateEditMode}
                    getProcessMstRecordByWorkType={getProcessMstRecordByWorkType}
                    getCustomers={customers}
                    showEdtConfirmOK={showEdtConfirmOK}
                    showEdtConfirmCancle={showEdtConfirmCancle}
                    csvImportDataValidation={csvImportDataValidation}
                  />
                }
              />
            </Routes>
          </div>
        </SplitPane>
      </div>
      {/* 新規作成 */}
      {isNew
        ? commonModal(
            isNew,
            addNewModalTitle,
            null,
            null,
            600,
            addData,
            closeAddModal,
            addNewModalContent,
            null,
            null,
            true
          )
        : ''}

      {/* 取引先検索 */}

      {customerModal ? (
        <SearchAndGetCustomerData
          customerModal={customerModal}
          selectedCustomerData={getCustomerData}
          title={Customer}
          clientType={ClientType.Contractor}
        />
      ) : (
        ''
      )}
      {/** 更新の確認メッセージ */}
      {isUpdateData
        ? commonModal(
            isUpdateData,
            confirmModalTitle,
            commonFooter(updateDataOk, updateDataCancel),
            null,
            400,
            updateDataOk,
            closeAddModal,
            confirmCreateContent,
            null,
            null,
            false
          )
        : ''}
      {/** 破棄の確認メッセージ */}
      {isDiscardData
        ? commonModal(
            isDiscardData,
            confirmModalTitle,
            commonFooter(discardOk, discardCancel),
            null,
            400,
            discardOk,
            discardCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}

      {isDelete
        ? commonModal(
            isDelete,
            deleteModalTitle,
            commonDeleteFooter(deleteOk, deleteCancel),
            null,
            400,
            deleteOk,
            deleteCancel,
            confirmDeleteContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default DevicesList;
