/**
 * クラス名：追加項目パラメータ
 * 説明：追加項目情報一覧画面
 * 作成者：ケイ
 * 作成日：2023/05/01
 * バージョン：1.0
 */
import { Table, Form, Button, Row, Input, Col, Modal, Select, Space, Image, Radio, DatePicker } from 'antd';
import { Table as RTable } from 'react-bootstrap';
import { CSVLink, CSVDownload } from 'react-csv';
import React, { useRef, useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Outlet, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { StatusCodes } from 'http-status-codes';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  CloseOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  PlusCircleFilled,
  MinusCircleFilled,
} from '@ant-design/icons';
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';

import {
  confirmHakiContent,
  confirmUpdateContent,
  confirmDiscardContent,
  confirmCreateContent,
  confirmModalTitle,
  confirmDeleteContent,
  confirmDiscardAndDeleteContent,
  commonModal,
  commonFooter,
  authorizeError,
  commonDeleteFooter,
  error,
} from '../../../common/CommonModal';
import { paramCommonCost, paramCommonLabel, paramCommonMemo, paramCommonDate } from '../../../common/Constant';
import '../../../../assets/styles/common.css';
import {
  dataStateList,
  today,
  TextArea,
  getAccessToken,
  updateAccessToken,
  formValidatorMode,
  discardChange,
  formatDateString,
  todayTime,
  formatDate,
} from '../../../common/Common';
import AdditionalItemsDetails from './AdditionalItemsDetails';
import checked from '../../../../assets/images/checked.png';
import { createAdditionalItemCSV, getCompanyInfo } from '../../../common/CommonAPI';
import { ErrorMessage } from '../../../common/Message';
import sort_up from '../../../../assets/images/sort_up.png';
import sort_down from '../../../../assets/images/sort_down.png';

dayjs.extend(customParseFormat);
const RadioGroup = Radio.Group;
const dateFormat = 'YYYY-MM-DD';

const inputItemRef = {};

const columns = [
  {
    id: '1',
    title: 'No',
    dataIndex: 'no',
    width: 50,
    render: (id, record, index) => {
      return index + 1;
    },
    className: 'cm-a-right',
  },

  {
    id: '2',
    title: 'コード',
    dataIndex: 'code',
    width: 120,
    sorter: (a, b) => a.code?.toUpperCase()?.localeCompare(b.code?.toUpperCase()),
    sortIcon: ({ sortOrder }) => (
      <>
        <Image
          preview={false}
          src={sort_up}
          style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
        />
        <Image
          preview={false}
          src={sort_down}
          style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
        />
      </>
    ),
    key: 'code',
  },
  {
    id: '3',
    title: '名称',
    dataIndex: 'name',
    width: 150,
    sorter: (a, b) => a.name?.toUpperCase()?.localeCompare(b.name?.toUpperCase()),
    sortIcon: ({ sortOrder }) => (
      <>
        <Image
          preview={false}
          src={sort_up}
          style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
        />
        <Image
          preview={false}
          src={sort_down}
          style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
        />
      </>
    ),
    key: 'name',
  },

  {
    id: '4',
    title: '作成日',
    dataIndex: 'created',
    render: (id, record, index) => {
      return record['created']?.split('T')[0];
    },
    width: 100,
    sorter: (a, b) => a.created?.toUpperCase()?.localeCompare(b.created?.toUpperCase()),
    sortIcon: ({ sortOrder }) => (
      <>
        <Image
          preview={false}
          src={sort_up}
          style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
        />
        <Image
          preview={false}
          src={sort_down}
          style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
        />
      </>
    ),
    key: 'created',
  },
  {
    id: '5',
    title: '更新日',
    dataIndex: 'modified',
    render: (id, record, index) => {
      return record['modified']?.split('T')[0];
    },
    width: 100,
    sorter: (a, b) => a.modified?.toUpperCase()?.localeCompare(b.modified?.toUpperCase()),
    sortIcon: ({ sortOrder }) => (
      <>
        <Image
          preview={false}
          src={sort_up}
          style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
        />
        <Image
          preview={false}
          src={sort_down}
          style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
        />
      </>
    ),
    key: 'modified',
  },
  {
    id: '6',
    title: '備考',
    dataIndex: 'remarks',
    width: 200,
    sorter: (a, b) => a.remarks?.toUpperCase()?.localeCompare(b.remarks?.toUpperCase()),
    sortIcon: ({ sortOrder }) => (
      <>
        <Image
          preview={false}
          src={sort_up}
          style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
        />
        <Image
          preview={false}
          src={sort_down}
          style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
        />
      </>
    ),
    key: 'remarks',
  },
  {
    id: '7',
    title: '表示',
    dataIndex: 'isUsed',
    width: 95,
    render: (index, item) => {
      return item['isUsed'] ? (
        <Image
          preview={false}
          width={13}
          src={checked}
          style={{ marginLeft: '3px' }}
          key={item['key']}
          id={item['id']}
        />
      ) : (
        <></>
      );
    },
    className: 'cm-a-center',
    sorter: (a, b) => a.isUsed - b.isUsed,
    sortIcon: ({ sortOrder }) => (
      <>
        <Image
          preview={false}
          src={sort_up}
          style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
        />
        <Image
          preview={false}
          src={sort_down}
          style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
        />
      </>
    ),
    key: 'isUsed',
  },
  /* {
    id: '8',
    title: '',
    dataIndex: 'tableNo',
    hidden: true,
  }, */
]?.filter((item) => !item.hidden);

const tableColumns = columns?.map((item) => ({
  ...item,
}));

const AdditionalItemsParamList = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    setSplitPaneSize: setSplitPaneSize,
    createNewProcess: createNewProcess,
    showXMLInputModal: showXMLInputModal,
    bulkEditProcess: bulkEditProcess,
    deleteProcess: deleteProcess,
    showCSVInputModal: showCSVInputModal,
    getEditMode: editMode,
    updateEditMode: setEditMode,
    discardChange: discardChange,
    setDiscardChangeMode: setDiscardChangeMode,
    setEditModeCancelConfirm: setEditModeCancelConfirm,
    discardConfirmOk: discardConfirmOk,
    getCsvExportFileName: csvExportFileName,
    getCsvExportData: getCsvExportData,
    submitCsvImportData: submitCsvImportData,
  }));

  const csvExportFileName = `追加項目_${formatDateString(todayTime())}.csv`;

  let checkedRow = [];
  const detailRef = useRef();
  const messageRef = useRef([]);

  const navigate = useNavigate();
  const location = useLocation();

  const additionalItemType = [
    { value: 1, label: '費用' },
    { value: 2, label: 'ラベル' },
    { value: 3, label: 'メモ' },
    { value: 4, label: '日付' },
  ];

  const [paramSelected, setParamSelected] = useState('additional');
  const [paramType, setParamType] = useState('common');
  const [costData, setCostData] = useState([]);
  const [tmpCostData, setTmpCostData] = useState([]);
  const [costFilteredData, setCostFilteredData] = useState([]);
  const [labelData, setLabelData] = useState([]);
  const [tmpLabelData, setTmpLabelData] = useState([]);
  const [labelFilteredData, setLabelFilteredData] = useState([]);
  const [memoData, setMemoData] = useState([]);
  const [tmpMemoData, setTmpMemoData] = useState([]);
  const [memoFilteredData, setMemoFilteredData] = useState([]);
  const [dateData, setDateData] = useState([]);
  const [tmpDateData, setTmpDateData] = useState([]);
  const [dateFilteredData, setDateFilteredData] = useState([]);
  const [tempAdditionalItemData, setTempAdditionalItemData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const [editMode, setEditMode] = useState(false);
  // 発生した処理タイプ
  const [discardChangeMode, setDiscardChangeMode] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [changeRecordKey, setChangeRecordKey] = useState(0);
  const [changeTableNo, setChangeTableNo] = useState();

  const [display, setDisplay] = useState();
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [remarks, setRemarks] = useState('');
  const [created, setCreated] = useState('');
  const [updated, setUpdated] = useState(today);
  const [tableNo, setTableNo] = useState(1);

  const [dataState, setDataState] = useState(1);
  const [keyword, setKeyWord] = useState('');
  const [isFilterFlg, setIsFilterFlg] = useState(false);

  const [outsourcingChecked, setOutsourcingChecked] = useState(false);
  const [invalidFlgChecked, setInvalidFlgChecked] = useState(false);
  const [hasCheckData, setHasCheckData] = useState(true);
  const [chkData, setChkData] = useState(0);
  const [selectedRowsData, setSelectedRowsData] = useState([]);

  const [isBulkEdit, setIsBulkEdit] = useState(false);
  const [isCreateNew, setIsCreateNew] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [visibleCSVInput, setVisibleCSVInput] = useState(false);
  const [visibleCSVOutput, setVisibleCSVOutput] = useState(false);
  const [visibleXMLInput, setVisibleXMLInput] = useState(false);
  const [childTitle, setChildTitle] = useState('');

  const [createConfirm, setCreateConfirm] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);

  // 一括編集
  const [chkrowscount, setChkRowsCount] = useState(0);
  const [bulkEditCostSelRow, setBulkEditCostSelRow] = useState([]);
  const [bulkEditLabelSelRow, setBulkEditLabelSelRow] = useState([]);
  const [bulkEditMemoSelRow, setBulkEditMemoSelRow] = useState([]);
  const [bulkEditDateSelRow, setBulkEditDateSelRow] = useState([]);
  const [bulkEditCostSelKeys, setBulkEditCostSelKeys] = useState([]);
  const [bulkEditLabelSelKeys, setBulkEditLabelSelKeys] = useState([]);
  const [bulkEditMemoSelKeys, setBulkEditMemoSelKeys] = useState([]);
  const [bulkEditDateSelKeys, setBulkEditDateSelKeys] = useState([]);

  const [isCost, setIsCost] = useState(true);
  const [isLabel, setIsLabel] = useState(true);
  const [isMemo, setIsMemo] = useState(true);
  const [isDate, setIsDate] = useState(true);

  const [isBulkEditCost, setIsBulkEditCost] = useState(true);
  const [isBulkEditLabel, setIsBulkEditLabel] = useState(true);
  const [isBulkEditMemo, setIsBulkEditMemo] = useState(true);
  const [isBulkEditDate, setIsBulkEditDate] = useState(true);

  // 新規作成
  const [itemType, setItemType] = useState(1);

  // Content Resize
  const [sizes, setSizes] = useState(['60%']);

  const [showEdtConfirmOK, setShowEdtConfirmOK] = useState();
  const [showEdtConfirmCancle, setShowEdtConfirmCancle] = useState();

  const currentRoute = location.pathname;

  const inputItemRef = {};

  // Idで降順する
  // const descOrderById = (data) => {
  //   if (data?.length > 0) {
  //     let sortedList = data?.sort((a, b) => b.id - a.id);
  //     return sortedList;
  //   } else {
  //     return data;
  //   }
  // };

  async function getAdditionalProcessData() {
    let tempData = [];
    let selectedData = [];
    let costDatas = [];
    let labelDatas = [];
    let memoDatas = [];
    let dateDatas = [];
    try {
      props?.loading(true); // Waitingダイアログを表示にする
      tempData = await getCompanyInfo();
      costDatas = tempData?.length > 0 ? tempData[0].additionalItemCosts?.filter((item) => !item.isDeleted) : [];
      selectedData = costDatas;
      labelDatas = tempData?.length > 0 ? tempData[0]?.additionalItemLabels?.filter((item) => !item.isDeleted) : [];
      memoDatas = tempData?.length > 0 ? tempData[0]?.additionalItemMemos?.filter((item) => !item.isDeleted) : [];
      dateDatas = tempData?.length > 0 ? tempData[0]?.additionalItemDates?.filter((item) => !item.isDeleted) : [];
      //if (customersData?.length > 0) selectedData = customersData[0];

      setCostData(costDatas);
      setCostFilteredData(costDatas);

      setLabelData(labelDatas);
      setLabelFilteredData(labelDatas);

      setMemoData(memoDatas);
      setMemoFilteredData(memoDatas);

      setDateData(dateDatas);
      setDateFilteredData(dateDatas);

      let rowSelectTbl = [];
      let tbl;
      let lastKey;

      switch (true) {
        case costDatas?.length != 0 && costDatas?.filter((item) => item.isUsed)?.length != 0:
          rowSelectTbl = costDatas?.filter((item) => item.isUsed);
          tbl = 1;
          lastKey = rowSelectTbl[0]?.id;
          break;
        case labelDatas?.length != 0 && labelDatas?.filter((item) => item.isUsed)?.length != 0:
          rowSelectTbl = labelDatas?.filter((item) => item.isUsed);
          tbl = 2;
          lastKey = rowSelectTbl[0]?.id;
          break;
        case memoDatas?.length != 0 && memoDatas?.filter((item) => item.isUsed)?.length != 0:
          rowSelectTbl = memoDatas?.filter((item) => item.isUsed);
          tbl = 3;
          lastKey = rowSelectTbl[0]?.id;
          break;
        case dateDatas?.length != 0 && dateDatas?.filter((item) => item.isUsed)?.length != 0:
          rowSelectTbl = dateDatas?.filter((item) => item.isUsed);
          tbl = 4;
          lastKey = rowSelectTbl[0]?.id;
          break;
        default:
          rowSelectTbl = costDatas;
          tbl = 1;
          lastKey = rowSelectTbl[0]?.id;
      }

      // 前回選択された行がある場合
      if (
        props.initialParam === 'additional' &&
        props.selectedRowId !== undefined &&
        props.selectedRowId !== 0 &&
        props.rowSelectTbl !== undefined &&
        props.rowSelectTbl !== 0
      ) {
        lastKey = props.selectedRowId;
        switch (props.rowSelectTbl) {
          case 1:
            rowSelectTbl = costDatas?.filter((item) => item.isUsed);
            tbl = 1;
            break;
          case 2:
            rowSelectTbl = labelDatas?.filter((item) => item.isUsed);
            tbl = 2;
            break;
          case 3:
            rowSelectTbl = memoDatas?.filter((item) => item.isUsed);
            tbl = 3;
            break;
          case 4:
            rowSelectTbl = dateDatas?.filter((item) => item.isUsed);
            tbl = 4;
            break;
          default:
            rowSelectTbl = costDatas;
            tbl = 1;
        }
        let propFilterDatas =
          props.initialParam === 'additional' && props?.filterDatas !== undefined
            ? props?.filterDatas
            : {
                keyword: keyword,
                dataState: dataState,
              };
        setKeyWord(propFilterDatas?.keyword);
        setDataState(propFilterDatas?.dataState);
      }
      setIsFilterFlg(true);

      let record = getSelectedData(rowSelectTbl, lastKey);
      onRowSelectWithoutEditMode(record, tbl);

      setSelectedRowKeys(lastKey);
      setSelectedData(record /* != undefined && record?.length > 0 ? record : [] */);
      setSelectedRowsData(rowSelectTbl);
      setTableNo(tbl);

      navigate(':common.additional');
      setParamSelected('additional');
      setParamType('common');
      setIsCost(true);
      props.setParam(paramType, paramSelected);
      props?.loading(false); // Waitingダイアログを非表示にする
    } catch (e) {
      error(e.message);
      return;
    }
  }

  useEffect(() => {
    getAdditionalProcessData();
  }, [paramType, paramSelected]);

  useEffect(() => {
    if (props.showConfirmMsgs !== undefined) {
      setShowEdtConfirmOK(props.showConfirmMsgs?.editOk);
      setShowEdtConfirmCancle(props.showConfirmMsgs?.editCancel);
    } else {
      setShowEdtConfirmOK(true);
      setShowEdtConfirmCancle(true);
    }
  }, [props.showConfirmMsgs]);

  /* useEffect(() => {
    let tempData = [];
    let selectedData = [];

    tempData =
      localStorage.getItem('paramCommonCost') == undefined ? [] : JSON.parse(localStorage.getItem('paramCommonCost'));
    selectedData =
      localStorage.getItem('paramCommonCost') == undefined ? [] : JSON.parse(localStorage.getItem('paramCommonCost'));
    setCostData(tempData);
    setCostFilteredData(tempData);
    setSelectedRowKeys(1);
    setSelectedData(selectedData != undefined && selectedData?.length > 0 ? selectedData[1] : []);
    setSelectedRowsData(tempData);
    setTableNo(1);
    setDataState(1);

    tempData = [];
    tempData =
      localStorage.getItem('paramCommonLabel') == undefined ? [] : JSON.parse(localStorage.getItem('paramCommonLabel'));
    setLabelData(tempData);
    setLabelFilteredData(tempData);

    tempData = [];
    tempData =
      localStorage.getItem('paramCommonMemo') == undefined ? [] : JSON.parse(localStorage.getItem('paramCommonMemo'));
    setMemoData(tempData);
    setMemoFilteredData(tempData);

    tempData = [];
    tempData =
      JSON.parse(localStorage.getItem('paramCommonDate')) == undefined
        ? []
        : JSON.parse(localStorage.getItem('paramCommonDate'));
    setDateData(tempData);
    setDateFilteredData(tempData);

    navigate(':common.additional');
    setParamSelected('additional');
    setParamType('common');
    setIsCost(true);
    props.setParam(paramType, paramSelected);
  }, [currentRoute]); */

  // フラグ又はキーワードに変更された場合、
  useEffect(() => {
    if (isFilterFlg) {
      // 絞り込み、
      getDataByFilter();
    }
  }, [keyword, dataState, tmpCostData, tmpLabelData, tmpMemoData, tmpDateData, isFilterFlg]);

  // 編集中破棄確認ポップアップに「OK」又は「キャンセル」を押下
  useEffect(() => {
    if (!editMode && !editModeCancelConfirm) {
      let tempData = [];
      switch (discardChangeMode?.no) {
        case discardChange[0]?.no: // 編集中に行を選択した場合、
          tempData = getDataByType(changeTableNo);
          setTableNo(changeTableNo);
          setSelectedRowKeys(changeRecordKey);
          setSelectedData(getSelectedData(tempData, changeRecordKey));
          break;
        case discardChange[1]?.no: // 検索
          getDataByFilter();
          break;
        case discardChange[2]?.no: // 一括編集
        case discardChange[3]?.no: // 新規作成
          tempData = getDataByType(tableNo);
          setSelectedData(getSelectedData(tempData, selectedRowKeys));
          discardChangeMode?.no === discardChange[2]?.no ? bulkEditProcess() : createNewProcess();
          break;
        case discardChange[4]?.no: // CSV入力
          onClickCSVImport();
          break;
        case discardChange[5]?.no: // CSV出力
          onClickCSVExport();
          break;
      }
    }
  }, [editModeCancelConfirm]);

  // 一括編集の行チェックボックスを押下
  useEffect(() => {
    let chkCount =
      bulkEditCostSelRow?.length +
      bulkEditLabelSelRow?.length +
      bulkEditMemoSelRow?.length +
      bulkEditDateSelRow?.length;
    setChkRowsCount(chkCount);
  }, [bulkEditCostSelRow, bulkEditLabelSelRow, bulkEditMemoSelRow, bulkEditDateSelRow]);

  const setSplitPaneSize = (splitSize) => {
    setSizes(splitSize);
  };

  // 入力項目情報
  const inputFormItem = (
    <RTable id="param_detail_tbl" className="propertiesTbl">
      <thead>
        {/* 種類名 */}
        {isCreateNew ? (
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">追加項目名</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <RadioGroup
                name="itemType"
                defaultValue={itemType}
                className="radioCustomerGpEdit"
                onChange={(e) => setItemType(e.target.value)}
                value={itemType}
              >
                {additionalItemType?.map((item, index) => (
                  <Radio key={index} value={item.value}>
                    {item.label}
                  </Radio>
                ))}
              </RadioGroup>
            </td>
          </tr>
        ) : (
          <></>
        )}

        {/* コード */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">コード</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="code"
              id="code"
              value={code}
              type="text"
              ref={(ref) => {
                inputItemRef['code'] = ref;
              }}
              maxLength={50}
              onChange={(e) => setCode(e.target.value)}
            />
          </td>
        </tr>
        {/* 名称 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">名称</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="name"
              id="name"
              value={name}
              type="text"
              ref={(ref) => {
                inputItemRef['name'] = ref;
              }}
              maxLength={50}
              onChange={(e) => setName(e.target.value)}
            />
          </td>
        </tr>
        {/* 作成日 更新日 */}
        {isBulkEdit ? (
          ''
        ) : (
          <>
            <tr className="detail-tbl-row">
              <td className="param-detail-tbl-val-title">
                <label className="param_detail-title-label">作成日</label>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <label className="param_detail-title-label">{created}</label>
              </td>
            </tr>
            <tr className="detail-tbl-row">
              <td className="param-detail-tbl-val-title">
                <label className="param_detail-title-label">更新日</label>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <label className="param_detail-title-label">{updated}</label>
              </td>
            </tr>
          </>
        )}
        {/* 備考 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">備考</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <TextArea
              className={'input-editable'}
              name="remarks"
              id="remarks"
              value={remarks}
              type="text"
              style={{ margin: 5, padding: 5, width: '98.3%' }}
              //autoSize={{ minRows: 3, maxRows: 6 }}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </td>
        </tr>
        {/* 表示 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">表示</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <RadioGroup
              name="isUsed"
              defaultValue={display}
              className="radioCustomerGpEdit"
              onChange={(e) => setDisplay(e.target.value)}
              value={display}
            >
              <Radio value={true}>オン</Radio>
              <Radio value={false}>オフ</Radio>
            </RadioGroup>
          </td>
        </tr>
      </thead>
    </RTable>
  );

  // 選択中行のデータを取得する
  const getSelectedData = (data, key) => {
    let ret = [];
    let record;
    record = data?.find((item) => item.id === key);
    return record ? record : ret;
  };

  // 一覧画面の行を選択した場合、
  const onRowSelect = (record, tblNo) => {
    let selectedData = [];
    selectedData.push({
      id: record.id,
      isUsed: record.isUsed,
      code: record.code,
      name: record.name,
      remarks: record.remarks,
      created: record.created,
      modified: record.modified,
    });
    setChangeRecordKey(record.id);
    setChangeTableNo(tblNo);
    if (editMode) {
      setDiscardChangeMode(discardChange[0]);
      //setEditModeCancelConfirm(true);
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setTableNo(tblNo);
        setSelectedRowKeys(record.id);
        setSelectedData(record);
        navigate(':common.additional');
      }
    } else {
      setTableNo(tblNo);
      setSelectedRowKeys(record.id);
      setSelectedData(record);
      navigate(':common.additional');
    }
    props?.updateSelectedRowId(record?.id, tblNo, 0, 'ASC', 'id', {
      keyword: keyword,
      dataState: dataState,
    });
    props?.resetInitialParam();
  };

  const onRowSelectWithoutEditMode = (record, tblNo) => {
    let selectedData = [];
    selectedData.push({
      id: record.id,
      isUsed: record.isUsed,
      code: record.code,
      name: record.name,
      remarks: record.remarks,
      created: record.created,
      modified: record.modified,
    });
    setChangeRecordKey(record.id);
    setChangeTableNo(tblNo);

    setTableNo(tblNo);
    setSelectedRowKeys(record.id);
    setSelectedData(record);
    navigate(':common.additional');
    props?.updateSelectedRowId(record?.id, tblNo, 0, 'ASC', 'id', {
      keyword: keyword,
      dataState: dataState,
    });
  };

  const handleOkCSVInput = (e) => {
    setVisibleCSVInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const handleOkXMLInput = (e) => {
    setVisibleXMLInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.xml';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  // 削除ボタンを押下
  const deleteProcess = () => {
    let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
    // setEditModeCancelConfirm(moveInEdit);
    if (!moveInEdit) {
      setIsDelete(false);
      deleteOk();
    } else {
      setIsDelete(true);
      setChildTitle('削除');
    }
  };

  // 係数情報をDBに削除するt
  async function deleteAdditionalItemDB(id, tblNo) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'Company/additional';

      const otherParam = {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          tableNo: tblNo,
          additionalItemInfo: {
            id: id,
          },
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 削除確認ポップアップのOKボタンを押下
  const deleteOk = async (e) => {
    let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
    // setEditModeCancelConfirm(moveInEdit);
    if (isDelete || !moveInEdit) {
      setIsDelete(false);
      setEditMode(false);
      props?.loading(true); // Waitingダイアログを表示にする
      let selectedKey = selectedRowKeys;
      let deletedData = getRelatedAdditionalItem(await deleteAdditionalItemDB(selectedKey, tableNo), tableNo);
      // IQX_WEBEST-281 使用しているパラメータなのに削除出来てしまう
      if (deletedData !== undefined && deletedData?.length > 0) {
        let filterdData =
          deletedData !== undefined && deletedData?.length > 0 ? deletedData?.filter((item) => !item.isDeleted) : [];
        setFilteredDataByType(tableNo, filterdData);

        let currentData = filterdData;
        setDataByType(tableNo, currentData);

        //一番上のテーブルの最初の行を選択;
        let rowSelectTbl = [];
        let tbl;

        outerLoop: for (let i = 1; i <= 4; i++) {
          if (i === tableNo && filterdData?.length <= 0) {
            continue outerLoop;
          }
          if (getDataByType(i)?.length > 0) {
            rowSelectTbl = getDataByType(i);
            tbl = i;
            break outerLoop;
          }
        }
        let lastKey = rowSelectTbl[0]?.id;
        let record = getSelectedData(rowSelectTbl, lastKey);
        onRowSelectWithoutEditMode(record, tbl);
      }
      props?.loading(false); // Waitingダイアログを非表示にする
    }
  };

  // 削除確認ポップアップのCandelボタンを押下
  const deleteCancel = (e) => {
    setIsDelete(false);
  };

  const showCSVInputModal = () => {
    setVisibleCSVInput(true);
    setChildTitle('CSV入力');
  };

  const showXMLInputModal = () => {
    setVisibleXMLInput(true);
    setChildTitle('XML入力');
  };

  const resetInputInfo = () => {
    setItemType(1);
    setDisplay();
    setCode('');
    setName('');
    setRemarks('');
    setCreated('');
    setUpdated(today);
  };

  // 無効フラグを押下の場合、
  const searchByDataState = (value) => {
    dataState === value ? getDataByFilter() : setDataState(value);
    setIsFilterFlg(true);
  };

  // キーワードで検索した場合、
  const searchByKeyword = (e) => {
    keyword === e.target.value ? getDataByFilter() : setKeyWord(e.target.value);
    setIsFilterFlg(true);
  };

  // 絞り込み、
  const getDataByFilter = () => {
    let searchBarText = keyword;
    let flag;
    let currentData = [];
    let currentFilterData = [];
    let getAllFilteredData = [];
    for (let i = 1; i <= 4; i++) {
      currentData = getDataByType(i);
      /* if (currentData?.length <= 0) {
        return;
      }if (currentData?.length > 0) { */
      currentFilterData = currentData;
      // キーワードで絞り込み
      if (searchBarText != '') {
        currentFilterData = currentData?.filter(
          (item) =>
            item.code?.toLowerCase().includes(searchBarText?.toLocaleLowerCase()) ||
            item.name?.toLowerCase().includes(searchBarText?.toLowerCase()) ||
            item.remarks?.toLowerCase().includes(searchBarText?.toLowerCase()) ||
            item.created?.toLowerCase().includes(searchBarText?.toLowerCase()) ||
            item.updated?.toLowerCase().includes(searchBarText?.toLowerCase())
        );
      }

      //  無効フラグで絞り込み
      if (dataState === 1 || dataState === 2) {
        dataState === 1 ? (flag = true) : (flag = false);
        currentFilterData = currentFilterData?.filter((item) => item.isUsed === flag);
      }

      // 「費用・ラベル・メモ・日付」を絞り込んだ、纏め情報
      getAllFilteredData.push({ id: i, data: currentFilterData });
    }

    /* let index = getAllFilteredData?.findIndex((item) => item.key === selectedRowKeys && item.tableNo === tableNo);
    // 検索した結果に、選択中行のKeyが無い場合、
    if (index === -1) {
      // 編集中である場合、
      if (editMode) {
        setDiscardChangeMode(discardChange[1]);
        //setEditModeCancelConfirm(true);
        return;
      } */
    // 検索結果がある場合、
    /*  if (getAllFilteredData?.length > 0) {
        index = getAllFilteredData?.length - 1;
        setTableNo(getAllFilteredData[index]?.tableNo);
        setSelectedRowKeys(getAllFilteredData[index]?.key);
        scrollTo('table' + getAllFilteredData[index]?.tableNo);
      } 
    }*/

    // 検索結果の有無チェック、
    /* getAllFilteredData?.length > 0 ? setSelectedData(getAllFilteredData[index]) : setSelectedData(getAllFilteredData); */

    // 一覧に表示するデー
    if (getAllFilteredData !== undefined && getAllFilteredData?.length > 0) {
      for (let i = 0; i < 4; i++) {
        //let tmpData = getAllFilteredData?.filter((item) => item.tableNo === i);
        setFilteredDataByType(getAllFilteredData[i].id, getAllFilteredData[i].data);
      }
    }
    props?.updateSelectedRowId(selectedRowKeys, tableNo, 0, 'ASC', 'id', {
      keyword: keyword,
      dataState: dataState,
    });
    setIsFilterFlg(false);
  };

  const reportModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>確認</p>
    </div>
  );

  const editModalTitle = (
    <div
      style={{
        width: 1300,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const addNewModalTitle = (
    <div
      style={{
        width: 700,
        backgroundColor: '#005fab',
        marginLeft: -10,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  // 案件状態情報をDBに新規登録する
  async function insertAdditionalItemDB(createData) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'Company/additional';
      const otherParam = {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          tableNo: createData.tableNo,
          additionalItemInfo: createData.additionalItemInfo,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  const getRelatedAdditionalItem = (companyData, tableNo) => {
    let tmpData = [];
    switch (tableNo) {
      case 1:
        tmpData = companyData.additionalItemCosts /* ?.filter((item) => !item.isDeleted) */;
        break;
      case 2:
        tmpData = companyData.additionalItemLabels /* ?.filter((item) => !item.isDeleted) */;
        break;
      case 3:
        tmpData = companyData.additionalItemMemos /* ?.filter((item) => !item.isDeleted) */;
        break;
      case 4:
        tmpData = companyData.additionalItemDates /* ?.filter((item) => !item.isDeleted) */;
        break;
    }
    return tmpData;
  };

  // 確認ポップアップのOKボタン押下
  const confirmOk = async (e) => {
    let tempAdditionalItemData = prepareCreateNewInfo();

    let bulkEditSelectedRow = [
      { key: 1, data: bulkEditCostSelRow, tableNo: 1 },
      { key: 2, data: bulkEditLabelSelRow, tableNo: 2 },
      { key: 3, data: bulkEditMemoSelRow, tableNo: 3 },
      { key: 4, data: bulkEditDateSelRow, tableNo: 4 },
    ];
    setCreateConfirm(false);
    if (isCreateNew) {
      let lastIndex = tempAdditionalItemData?.length - 1;
      let tempData = tempAdditionalItemData[lastIndex];
      if (!(await detailRef.current.formValidation(inputItemRef, formValidatorMode.Create, tempData.tableNo))) {
        return;
      }
      props?.loading(true); // Waitingダイアログを表示にする
      let createdData = getRelatedAdditionalItem(await insertAdditionalItemDB(tempData), tempData.tableNo);
      // let currentFilterData = getFilteredDataByType(itemType);
      setDataByType(tempData.tableNo, createdData);
      setFilteredDataByType(
        tempData.tableNo,
        createdData?.filter((item) => !item.isDeleted)
      );
      /* onRowSelectWithoutEditMode(tempData); */
      if (createdData !== undefined && createdData?.length > 0) {
        onRowSelectWithoutEditMode(createdData[0], tempData.tableNo);
      }
      setIsCreateNew(false);

      scrollTo('table' + itemType);
      props?.loading(false); // Waitingダイアログを非表示にする
    } else if (isBulkEdit) {
      setUpdateConfirm(false);
      // 一括編集画面から遷移された場合、
      setIsBulkEdit(false);
      props?.loading(true); // Waitingダイアログを表示にする
      // 選択された全ての対象行を更新、
      for (let i = 0; i < bulkEditSelectedRow?.length; i++) {
        let bulkEditSelected = bulkEditSelectedRow[i].data;
        if (bulkEditSelected?.length > 0) {
          for (let j = 0; j < bulkEditSelected?.length; j++) {
            let item = bulkEditSelected[j];
            if (display === true || display === false) item.isUsed = display;
            if (code) item.code = code;
            if (name) item.name = name;
            if (remarks) item.remarks = remarks;
            item.updated = today;
            await submitData(item, bulkEditSelectedRow[i].tableNo);
          }
        }
      }
      setIsFilterFlg(true);
      props?.loading(false); // Waitingダイアログを非表示にする
    }

    resetInputInfo();
  };

  // 確認ポップアップのCancelボタン押下
  const confirmCancel = () => {
    setUpdateConfirm(false);
    setCreateConfirm(false);
  };

  // 編集中破壊確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmOk();
  };

  // 編集中破壊確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmCancel();
    /* setEditModeCancelConfirm(showEdtConfirmCancle);
    if (!showEdtConfirmCancle) {
      detailRef.current.discardConfirmCancel();
    } */
  };

  // 破壊確認ポップアップのOKボタン押下
  const cancelConfirmOK = () => {
    if (isCreateNew) {
      setIsCreateNew(false);
    } else if (isBulkEdit) {
      setIsBulkEdit(false);
    }
    setCancelConfirm(false);
  };

  // 破壊確認ポップアップのCancelボタン押下
  const cancelConfirmCancel = () => {
    setCancelConfirm(false);
  };

  // 更新確認のOKを押下で、一覧画面のデータを反映する
  const submitData = async (updatedData, tblNo) => {
    var id = updatedData.id;
    var index;
    props?.loading(true); // Waitingダイアログを表示にする
    let updatedInfo = getRelatedAdditionalItem(await updateCompanyData(updatedData, tblNo), tblNo);
    if (updatedInfo !== undefined) setDataByType(tblNo, updatedInfo);
    index = updatedInfo?.length > 0 ? updatedInfo?.findIndex((data) => data.id == id) : id;
    setDataByType(tblNo, updatedInfo);
    setFilteredDataByType(tblNo, updatedInfo);
    if (id === selectedRowKeys && tableNo === tblNo) detailRef.current?.displayDetailInfo(updatedInfo[index]);
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // 取引先情報をDBに更新する
  async function updateCompanyData(updateData, tableNo) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'Company/additional';
      const otherParam = {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          tableNo: tableNo,
          additionalItemInfo: updateData,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 費用・ラベル・メモ・日付によりデータを返す
  const getDataByType = (tableNo) => {
    let tempData = [];
    switch (tableNo) {
      case 1:
        tempData = [...costData];
        break;
      case 2:
        tempData = [...labelData];
        break;
      case 3:
        tempData = [...memoData];
        break;
      case 4:
        tempData = [...dateData];
        break;
    }
    return tempData;
  };

  // 費用・ラベル・メモ・日付によりデータを保存する
  const setDataByType = (tableNo, value) => {
    switch (tableNo) {
      case 1:
        setCostData(value);
        setTmpCostData(value);
        localStorage.setItem('paramCommonCost', JSON.stringify(value));
        break;
      case 2:
        setLabelData(value);
        setTmpLabelData(value);
        localStorage.setItem('paramCommonLabel', JSON.stringify(value));
        break;
      case 3:
        setMemoData(value);
        setTmpMemoData(value);
        localStorage.setItem('paramCommonMemo', JSON.stringify(value));
        break;
      case 4:
        setDateData(value);
        setTmpDateData(value);
        localStorage.setItem('paramCommonDate', JSON.stringify(value));
        break;
    }
  };

  // 絞り込んだ「費用・ラベル・メモ・日付」によりデータを返す
  const getFilteredDataByType = (tableNo) => {
    let tempData = [];
    switch (tableNo) {
      case 1:
        tempData = [...costFilteredData];
        break;
      case 2:
        tempData = [...labelFilteredData];
        break;
      case 3:
        tempData = [...memoFilteredData];
        break;
      case 4:
        tempData = [...dateFilteredData];
        break;
    }
    return tempData;
  };

  // 絞り込んだ「費用・ラベル・メモ・日付」によりデータを保存する
  const setFilteredDataByType = (tableNo, value) => {
    switch (tableNo) {
      case 1:
        setCostFilteredData(value);
        break;
      case 2:
        setLabelFilteredData(value);
        break;
      case 3:
        setMemoFilteredData(value);
        break;
      case 4:
        setDateFilteredData(value);
        break;
    }
  };

  const clickCost = () => {
    setIsCost(!isCost);
  };

  const clickLabel = () => {
    setIsLabel(!isLabel);
  };

  const clickMemo = () => {
    setIsMemo(!isMemo);
  };

  const clickDate = () => {
    setIsDate(!isDate);
  };

  const clickBulkEditCost = () => {
    setIsBulkEditCost(!isBulkEditCost);
  };

  const clickBulkEditLabel = () => {
    setIsBulkEditLabel(!isBulkEditLabel);
  };

  const clickBulkEditMemo = () => {
    setIsBulkEditMemo(!isBulkEditMemo);
  };

  const clickBulkEditDate = () => {
    setIsBulkEditDate(!isBulkEditDate);
  };

  // 行の追加ボタンを押下
  async function addRow(e, tableNo) {
    var tempData;
    // tableNo「1:費用、2:ラベル、3:メモ、4:日付」
    tempData = getDataByType(tableNo);
    // 選択中のレコード
    const selectedData = tempData?.find((i) => i.key === selectedRowKeys);
    // 最大Index
    var maxIndex = tempData?.length - 1;
    //　新レコードの準備
    var newData = {
      key: tempData[maxIndex].key + 1, //　連番で再生成
      id: tempData[maxIndex].id + 1, //　連番で再生成
      no: tempData[maxIndex].no + 1, //　連番で再生成
      display: selectedData.display, //　複写
      code: selectedData.code, //　複写
      name: selectedData.name, //　複写
      remarks: selectedData.remarks, //　複写
      created: selectedData.created, //　複写
      updated: selectedData.updated, //　複写
      tableNo: selectedData.tableNo, //　複写
    };
    // tableNo「1:費用、2:ラベル、3:メモ、4:日付」
    if (tableNo === 1) {
      await setCostData((costData) => [...costData, newData]);
    } else if (tableNo === 2) {
      await setLabelData((labelData) => [...labelData, newData]);
    } else if (tableNo === 3) {
      await setMemoData((memoData) => [...memoData, newData]);
    } else if (tableNo === 4) {
      await setDateData((dateData) => [...dateData, newData]);
    }
    await setSelectedRowKeys(newData.id);
    // 選択中のレコード
    await setSelectedData(newData);
    // Scrollの移動
    scrollTo(`${tableNo}` + `${newData.key}`);
  }

  // Scrollの移動
  const scrollTo = (id) => {
    // Scrollの移動
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };

  // 行の削除ボタンを押下
  const removeRow = (e, tableNo) => {
    var tempData;
    // tableNo「1:費用、2:ラベル、3:メモ、4:日付」
    tempData = getDataByType(tableNo);
    // 1行のみの場合、処理しない
    if (tempData?.length === 1) return;
    // 選択中のIndexを取得する
    const index = tempData?.findIndex((i) => i.key === selectedRowKeys);
    // データを取り除く
    tempData.splice(index, 1);
    // レコードのkey、id、noを連番で再生成する
    tempData?.map((value, index) => {
      value.key = index;
      value.id = index;
      value.no = index + 1;
    });
    //　現状のIndex「選択中のIndexは最大Index以下の場合、最大Indexを利用する」
    var curIndex = tempData?.length <= selectedRowKeys ? tempData?.length - 1 : selectedRowKeys;
    // tableNo「1:費用、2:ラベル、3:メモ、4:日付」
    setDataByType(tableNo, tempData);
    setSelectedRowKeys(curIndex);
    // 選択中のレコード
    setSelectedData(tempData[curIndex]);
  };

  // 編集モードを変更
  const updateEditMode = (mode) => {
    setEditMode(mode);
    props.updateMainParameterEditMode(mode);
  };

  // 新規作成-開始
  const createNewProcess = () => {
    resetInputInfo();
    setIsCreateNew(true);
    setCreated(today);
    setUpdated(today);
    setDisplay(true);
    setChildTitle('追加項目（新規追加）');
  };

  const createNewOK = () => {
    setCreateConfirm(true);
  };

  const createNewCancel = (e) => {
    //setCancelConfirm(true);
    setCancelConfirm(showEdtConfirmCancle);
    if (!showEdtConfirmCancle && isCreateNew) {
      setIsCreateNew(false);
    }
  };

  // 入力した新規情報を取得する
  const prepareCreateNewInfo = (e) => {
    let tempData = getDataByType(itemType);
    let lastIndex = tempData?.length - 1;
    let additionalItemInfo = {
      // estimateDays: estimateDays,
      isUsed: display,
      code: code,
      name: name,
      remarks: remarks,
    };
    tempData.push({
      additionalItemInfo: additionalItemInfo,
      tableNo: itemType,
    });
    /* setTempAdditionalItemData(tempData);
    setCreateConfirm(true); */
    if (itemType == 1) {
      localStorage.setItem('paramCommonCost', JSON.stringify(tempData));
    } else if (itemType == 2) {
      localStorage.setItem('paramCommonLabel', JSON.stringify(tempData));
    } else if (itemType == 3) {
      localStorage.setItem('paramCommonMemo', JSON.stringify(tempData));
    } else if (itemType == 4) {
      localStorage.setItem('paramCommonDate', JSON.stringify(tempData));
    }
    return tempData;
  };

  const createNewTitle = (
    <div
      style={{
        width: 800,
        backgroundColor: '#005fab',
        marginLeft: -10,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const createNewContent = (
    <>
      <Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        {/** プロパティレイアウト */}
        <Col span={24}>
          <div
            style={{
              height: 383,
              // marginTop: 28,
              fontSize: '13.5px',
              overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            {inputFormItem}
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: 0,
        }}
      >
        <Button className="mainButton" onClick={createNewOK} style={{ marginRight: 5 }}>
          登録
        </Button>
        <Button className="cancelButton" onClick={createNewCancel}>
          破棄
        </Button>
      </Row>
    </>
  );
  // 新規作成-終了

  // 一括編集-開始
  const onRowCostCheckboxSelect = (checked, record) => {
    checkedRow = [...checked];
    setBulkEditCostSelRow([...record]);
    setBulkEditCostSelKeys(checkedRow);
    navigate(':common.additional');
  };

  const onRowLabelCheckboxSelect = (checked, record) => {
    checkedRow = [...checked];
    setBulkEditLabelSelRow([...record]);
    setBulkEditLabelSelKeys(checkedRow);
    navigate(':common.additional');
  };

  const onRowMemoCheckboxSelect = (checked, record) => {
    checkedRow = [...checked];
    setBulkEditMemoSelRow([...record]);
    setBulkEditMemoSelKeys(checkedRow);
    navigate(':common.additional');
  };

  const onRowDateCheckboxSelect = (checked, record) => {
    checkedRow = [...checked];
    setBulkEditDateSelRow([...record]);
    setBulkEditDateSelKeys(checkedRow);
    navigate(':common.additional');
  };

  const bulkEditCostRowSelect = {
    onChange: onRowCostCheckboxSelect,
  };

  const bulkEditLabelRowSelect = {
    onChange: onRowLabelCheckboxSelect,
  };

  const bulkEditMemoRowSelect = {
    onChange: onRowMemoCheckboxSelect,
  };

  const bulkEditDateRowSelect = {
    onChange: onRowDateCheckboxSelect,
  };

  const bulkEditProcess = () => {
    resetInputInfo();
    setBulkEditCostSelRow([]);
    setBulkEditLabelSelRow([]);
    setBulkEditMemoSelRow([]);
    setBulkEditDateSelRow([]);
    setBulkEditCostSelKeys([]);
    setBulkEditLabelSelKeys([]);
    setBulkEditMemoSelKeys([]);
    setBulkEditDateSelKeys([]);
    setChkRowsCount(0);
    setIsBulkEdit(true);
    setChildTitle('追加項目（選択したデータをまとめて編集）');
  };

  const bulkEditOK = (e) => {
    setUpdateConfirm(true);
  };

  const bulkEditCancel = (e) => {
    setCancelConfirm(true);
  };

  const bulkEditTitle = (
    <div
      style={{
        width: 1790,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const bulkEditContent = (
    <>
      <Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        {/** 一覧 */}
        <Col span={13}>
          <p style={{ fontSize: '13.5px', marginBottom: 6, marginTop: 0, fontWeight: '700' }}>
            {chkrowscount}件を選択中
          </p>
          <div className="overflow-auto" style={{ height: 750 }}>
            {/* 費用 */}
            <Row style={{ color: 'black', marginTop: 0 }} className="oyabuhin oyabuhin-row" onClick={clickBulkEditCost}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  <label>{additionalItemType[0].label}</label>
                  {isBulkEditCost ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  )}
                </div>
              </Col>
            </Row>
            <div style={{ display: isBulkEditCost ? 'block' : 'none' }}>
              {/* 一覧アリア */}
              <Row style={{ marginTop: 5 }}>
                <Table
                  showSorterTooltip={false}
                  className="drop-down-tbl"
                  columns={columns}
                  rowKey={(record) => record.id}
                  dataSource={costFilteredData}
                  scroll={{ y: 250, x: '10vw' }}
                  rowClassName={(record) => (record.key == bulkEditCostSelKeys ? 'active-row' : 'data-row')}
                  rowSelection={bulkEditCostRowSelect}
                  pagination={false}
                />
              </Row>
            </div>
            {/* ラベル */}
            <Row
              style={{ color: 'black', marginTop: 5 }}
              className="oyabuhin oyabuhin-row"
              onClick={clickBulkEditLabel}
            >
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  <label>{additionalItemType[1].label}</label>
                  {isBulkEditLabel ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  )}
                </div>
              </Col>
            </Row>
            <div style={{ display: isBulkEditLabel ? 'block' : 'none' }}>
              {/* 一覧アリア */}
              <Row style={{ marginTop: 5 }}>
                <Table
                  showSorterTooltip={false}
                  className="drop-down-tbl"
                  columns={columns}
                  rowKey={(record) => record.id}
                  dataSource={labelFilteredData}
                  scroll={{ y: 250, x: '10vw' }}
                  rowClassName={(record) => (record.key == bulkEditLabelSelKeys ? 'active-row' : 'data-row')}
                  rowSelection={bulkEditLabelRowSelect}
                  pagination={false}
                />
              </Row>
            </div>
            {/* メモ */}
            <Row style={{ color: 'black', marginTop: 5 }} className="oyabuhin oyabuhin-row" onClick={clickBulkEditMemo}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  <label>{additionalItemType[2].label}</label>
                  {isBulkEditMemo ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  )}
                </div>
              </Col>
            </Row>
            <div style={{ display: isBulkEditMemo ? 'block' : 'none' }}>
              {/* 一覧アリア */}
              <Row style={{ marginTop: 5 }}>
                <Table
                  showSorterTooltip={false}
                  className="drop-down-tbl"
                  columns={columns}
                  rowKey={(record) => record.id}
                  dataSource={memoFilteredData}
                  scroll={{ y: 250, x: '10vw' }}
                  rowClassName={(record) => (record.key == bulkEditMemoSelKeys ? 'active-row' : 'data-row')}
                  rowSelection={bulkEditMemoRowSelect}
                  pagination={false}
                />
              </Row>
            </div>
            {/* 日付 */}
            <Row style={{ color: 'black', marginTop: 5 }} className="oyabuhin oyabuhin-row" onClick={clickBulkEditDate}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  <label>{additionalItemType[3].label}</label>
                  {isBulkEditDate ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  )}
                </div>
              </Col>
            </Row>
            <div style={{ display: isBulkEditDate ? 'block' : 'none' }}>
              {/* 一覧アリア */}
              <Row style={{ marginTop: 5 }}>
                <Table
                  showSorterTooltip={false}
                  className="drop-down-tbl"
                  columns={columns}
                  rowKey={(record) => record.id}
                  dataSource={dateFilteredData}
                  scroll={{ y: 250, x: '10vw' }}
                  rowClassName={(record) => (record.key == bulkEditDateSelKeys ? 'active-row' : 'data-row')}
                  rowSelection={bulkEditDateRowSelect}
                  pagination={false}
                />
              </Row>
            </div>
          </div>
        </Col>
        {/** プロパティレイアウト */}
        <Col span={11}>
          <div
            style={{
              height: 749,
              marginTop: 28,
              fontSize: '13.5px',
              overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            {inputFormItem}
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: -40,
        }}
      >
        <Button className="mainButton" onClick={bulkEditOK} style={{ marginRight: 5 }}>
          更新
        </Button>
        <Button className="cancelButton" onClick={bulkEditCancel}>
          破棄
        </Button>
      </Row>
    </>
  );
  // 一括編集-終了

  // CSVヘッダの情報
  const getCSVHeaderInfo = () => {
    let str = '';
    columns?.map((col, index) => {
      if (index === 1) {
        str += ',追加項目名';
      }
      if (str.length === 0) {
        str += col.title;
      } else {
        str += ',' + col.title;
      }
    }); // ヘッダの情報
    str = 'ID,' + str;
    return str;
  };

  // CSV出力情報
  const getCsvExportData = () => {
    let str = '';
    str = getCSVHeaderInfo();
    let no = 0;
    costFilteredData.map((data) => {
      // CSV内容
      str +=
        '\n' +
        data.id +
        ',' +
        (no += 1) +
        ',' +
        additionalItemType[0]?.label +
        ',' +
        data.code +
        ',' +
        data.name +
        ',' +
        formatDate(data.created) +
        ',' +
        formatDate(data.modified) +
        ',' +
        data.remarks +
        ',' +
        data.isUsed;
    }); // 費用
    labelFilteredData.map((data) => {
      // CSV内容
      str +=
        '\n' +
        data.id +
        ',' +
        (no += 1) +
        ',' +
        additionalItemType[1]?.label +
        ',' +
        data.code +
        ',' +
        data.name +
        ',' +
        formatDate(data.created) +
        ',' +
        formatDate(data.modified) +
        ',' +
        data.remarks +
        ',' +
        data.isUsed;
    }); // ラベル
    memoFilteredData.map((data) => {
      // CSV内容
      str +=
        '\n' +
        data.id +
        ',' +
        (no += 1) +
        ',' +
        additionalItemType[2]?.label +
        ',' +
        data.code +
        ',' +
        data.name +
        ',' +
        formatDate(data.created) +
        ',' +
        formatDate(data.modified) +
        ',' +
        data.remarks +
        ',' +
        data.isUsed;
    }); // メモ
    dateFilteredData.map((data) => {
      // CSV内容
      str +=
        '\n' +
        data.id +
        ',' +
        (no += 1) +
        ',' +
        additionalItemType[3]?.label +
        ',' +
        data.code +
        ',' +
        data.name +
        ',' +
        formatDate(data.created) +
        ',' +
        formatDate(data.modified) +
        ',' +
        data.remarks +
        ',' +
        data.isUsed;
    }); // 日付
    return str;
  };

  // CSV入力情報
  const submitCsvImportData = async (dataList) => {
    let createdDatas = [];
    let failedDatas = [];
    messageRef.current = [];
    let selectedData = [];
    let costDatas = [];
    let labelDatas = [];
    let memoDatas = [];
    let dateDatas = [];
    let tempDatas = [];
    props?.loading(true); // Waitingダイアログを表示にする
    // ヘッダの情報
    if (JSON.stringify(dataList[0]) !== JSON.stringify(getCSVHeaderInfo())) {
      // 不正なヘッダの情報
      messageRef?.current?.push(<li>{ErrorMessage('1行目').E016}</li>);
    } else {
      for (let i = 1; i < dataList.length; i++) {
        let additionalItemInfo = {
          isUsed: true,
          code: '',
          name: '',
          remarks: '',
        };
        let tempData = {
          additionalItemInfo: additionalItemInfo,
          tableNo: '',
        };
        let data = dataList[i].split(',');
        if (data && data?.[0].length === 0) continue;
        if (!csvImportDataValidation(i + 1, data)) {
          failedDatas.push(dataList[i]);
          continue;
        }

        let tableNo = additionalItemType?.find((item) => item.label === data?.[2])?.value;
        tempData.tableNo = tableNo;
        tempData.additionalItemInfo.id = data?.[0];
        tempData.additionalItemInfo.code = data?.[3];
        tempData.additionalItemInfo.name = data?.[4];
        tempData.additionalItemInfo.remarks = data?.[7];
        tempData.additionalItemInfo.isUsed = data?.[8] ? JSON.parse(data?.[8]?.toLowerCase()) : true; // IQX_WEBEST-301
        tempDatas.push(tempData);
      }
      createdDatas = await createAdditionalItemCSV(tempDatas);
      if (createdDatas !== undefined && createdDatas?.length > 0) {
        costDatas =
          createdDatas?.length > 0 ? createdDatas[0].additionalItemCosts?.filter((item) => !item.isDeleted) : [];
        selectedData = costDatas;
        labelDatas =
          createdDatas?.length > 0 ? createdDatas[0]?.additionalItemLabels?.filter((item) => !item.isDeleted) : [];
        memoDatas =
          createdDatas?.length > 0 ? createdDatas[0]?.additionalItemMemos?.filter((item) => !item.isDeleted) : [];
        dateDatas =
          createdDatas?.length > 0 ? createdDatas[0]?.additionalItemDates?.filter((item) => !item.isDeleted) : [];
        //if (customersData?.length > 0) selectedData = customersData[0];

        setCostData(costDatas);
        setCostFilteredData(costDatas?.filter((item) => item.isUsed === true));

        setLabelData(labelDatas);
        setLabelFilteredData(labelDatas?.filter((item) => item.isUsed === true));

        setMemoData(memoDatas);
        setMemoFilteredData(memoDatas?.filter((item) => item.isUsed === true));

        setDateData(dateDatas);
        setDateFilteredData(dateDatas?.filter((item) => item.isUsed === true));

        let rowSelectTbl = [];
        let tbl;
        let lastKey;

        switch (true) {
          case costDatas?.length != 0 && costDatas?.filter((item) => item.isUsed)?.length != 0:
            rowSelectTbl = costDatas?.filter((item) => item.isUsed);
            tbl = 1;
            lastKey = rowSelectTbl[0]?.id;
            break;
          case labelDatas?.length != 0 && labelDatas?.filter((item) => item.isUsed)?.length != 0:
            rowSelectTbl = labelDatas?.filter((item) => item.isUsed);
            tbl = 2;
            lastKey = rowSelectTbl[0]?.id;
            break;
          case memoDatas?.length != 0 && memoDatas?.filter((item) => item.isUsed)?.length != 0:
            rowSelectTbl = memoDatas?.filter((item) => item.isUsed);
            tbl = 3;
            lastKey = rowSelectTbl[0]?.id;
            break;
          case dateDatas?.length != 0 && dateDatas?.filter((item) => item.isUsed)?.length != 0:
            rowSelectTbl = dateDatas?.filter((item) => item.isUsed);
            tbl = 4;
            lastKey = rowSelectTbl[0]?.id;
            break;
          default:
            rowSelectTbl = costDatas;
            tbl = 1;
            lastKey = rowSelectTbl[0]?.id;
        }

        let record = getSelectedData(rowSelectTbl, lastKey);
        onRowSelectWithoutEditMode(record, tbl);

        setSelectedRowKeys(lastKey);
        setSelectedData(record /* != undefined && record?.length > 0 ? record : [] */);
        setSelectedRowsData(rowSelectTbl);
        setTableNo(tbl);
        setDataState(1);
        setFilteredDataByType(tbl, rowSelectTbl);
        // onRowSelectWithoutEditMode(createdData[createdData?.length - 1], tempData.tableNo);
        props?.updateSelectedRowId(record?.[0]?.id, tbl, 0, 'ASC', 'id', {
          keyword: keyword,
          dataState: dataState,
        });
      }
    }
    Modal.success({
      width: 500,
      className: 'modal-complete csv-modal-complete',
      title: '完了',
      content: (
        <p style={{ fontSize: '13.5px', whiteSpace: 'pre-line', lineHeight: 2 }}>
          {'CSV入力のデータ保存は完了しました。正常：' + tempDatas?.length + '件、異常：' + failedDatas?.length + '件'}
          {messageRef.current?.length > 0 ? (
            <>
              <br></br>
              {'下記の内容をご確認ください。'}
              <br></br>
              <ul>{messageRef.current}</ul>
            </>
          ) : (
            <></>
          )}
        </p>
      ),
    });
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // CSV入力情報チェック
  const csvImportDataValidation = (lineNo, data) => {
    // CSV入力情報チェック
    let no = lineNo + '行目の';

    // 追加項目名
    let input = data[2];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('追加項目名').E007}</li>);
      return false;
    }
    if (additionalItemType.findIndex((item) => item.label === input) === -1) {
      // 不正な追加項目名
      messageRef?.current?.push(<li>{no + ErrorMessage('追加項目名').E016}</li>);
      return false;
    }

    // コード
    input = data[3];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('コード').E007}</li>);
      return false;
    }

    // 名称
    input = data[4];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('名称').E007}</li>);
      return false;
    }

    return true;
  };

  // CSV入力ボタンを押下した場合、
  const onClickCSVImport = () => {
    props?.onClickCSVImport();
  };

  // CSV出力ボタンを押下した場合、
  const onClickCSVExport = () => {
    props?.onClickCSVExport();
  };

  return (
    <>
      {/* 一覧とプロパティ表示 */}
      <div
        style={{
          height: '90.8vh',
          marginLeft: 10,
        }}
      >
        <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1}>
          <div style={{ marginTop: 7 }}>
            {/* 検索アリア */}
            <Space size="middle">
              <Input
                className="input-editable search-select-input"
                placeholder="キーワードを入力"
                allowClear
                onPressEnter={searchByKeyword}
                onBlur={searchByKeyword}
                // onChange={(e) => {
                //   if (e.target.value === '') {
                //     searchByKeyword(e);
                //   }
                // }}
                onChange={searchByKeyword}
                value={keyword}
              />
              <Select
                className="search-select"
                onChange={searchByDataState}
                id="dataStateDrop"
                name="dataStateDrop"
                value={dataState}
              >
                {dataStateList?.map((item, index) => (
                  <Select.Option key={index} id={'dataState' + item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Space>

            <div className="overflow-auto kouteical" style={{ height: 830 }}>
              {/* 費用 */}
              <Row style={{ color: 'black', marginTop: 10 }} className="oyabuhin oyabuhin-row" onClick={clickCost}>
                <Col span={20} className="deli-col">
                  <div style={{ display: 'flex' }}>
                    <label>{additionalItemType[0].label}</label>
                    {isCost ? (
                      <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                    ) : (
                      <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                    )}
                  </div>
                </Col>
              </Row>
              <div style={{ display: isCost ? 'block' : 'none' }}>
                {/* 一覧アリア */}
                <Row style={{ marginTop: 5 }}>
                  <Table
                    showSorterTooltip={false}
                    id="table1"
                    columns={hasCheckData ? tableColumns : chkData}
                    dataSource={costFilteredData}
                    scroll={{ y: 180, x: '10vw' }}
                    rowClassName={(record) =>
                      record.id == selectedRowKeys && 1 === tableNo ? 'active-row' : 'data-row'
                    }
                    pagination={false}
                    className="drop-down-tbl"
                    onRow={(record, index) => {
                      return {
                        onClick: (event) => {
                          onRowSelect(record, 1);
                        },
                      };
                    }}
                  />
                </Row>
                {/* <Row style={{ marginTop: 5 }}>
                  <Space>
                    <PlusCircleFilled
                      style={{ fontSize: 18 , color: '#005fab' }}
                      onClick={(e) => {
                        addRow(e, 1);
                      }}
                    />
                    <MinusCircleFilled
                      style={{ fontSize: 18 , color: '#005fab' }}
                      onClick={(e) => {
                        removeRow(e, 1);
                      }}
                    />
                  </Space>
                </Row> */}
              </div>

              {/* ラベル */}
              <Row style={{ color: 'black', marginTop: 5 }} className="oyabuhin oyabuhin-row" onClick={clickLabel}>
                <Col span={20} className="deli-col">
                  <div style={{ display: 'flex' }}>
                    <label>{additionalItemType[1].label}</label>
                    {isLabel ? (
                      <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                    ) : (
                      <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                    )}
                  </div>
                </Col>
              </Row>
              <div style={{ display: isLabel ? 'block' : 'none' }}>
                {/* 一覧アリア */}
                <Row style={{ marginTop: 5 }}>
                  <Table
                    showSorterTooltip={false}
                    id="table2"
                    columns={hasCheckData ? tableColumns : chkData}
                    dataSource={labelFilteredData}
                    scroll={{ y: 180, x: '10vw' }}
                    rowClassName={(record) =>
                      record.id == selectedRowKeys && 2 === tableNo ? 'active-row' : 'data-row'
                    }
                    pagination={false}
                    className="drop-down-tbl"
                    onRow={(record, index) => {
                      return {
                        onClick: (event) => {
                          onRowSelect(record, 2);
                        },
                      };
                    }}
                  />
                </Row>
                {/* <Row style={{ marginTop: 5 }}>
                  <Space>
                    <PlusCircleFilled
                      style={{ fontSize: 18 , color: '#005fab' }}
                      onClick={(e) => {
                        addRow(e, 2);
                      }}
                    />
                    <MinusCircleFilled
                      style={{ fontSize: 18 , color: '#005fab' }}
                      onClick={(e) => {
                        removeRow(e, 2);
                      }}
                    />
                  </Space>
                </Row> */}
              </div>

              {/* メモ */}
              <Row style={{ color: 'black', marginTop: 5 }} className="oyabuhin oyabuhin-row" onClick={clickMemo}>
                <Col span={20} className="deli-col">
                  <div style={{ display: 'flex' }}>
                    <label>{additionalItemType[2].label}</label>
                    {isMemo ? (
                      <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                    ) : (
                      <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                    )}
                  </div>
                </Col>
              </Row>
              <div style={{ display: isMemo ? 'block' : 'none' }}>
                {/* 一覧アリア */}
                <Row style={{ marginTop: 5 }}>
                  <Table
                    showSorterTooltip={false}
                    id="table3"
                    columns={hasCheckData ? tableColumns : chkData}
                    dataSource={memoFilteredData}
                    scroll={{ y: 180, x: '10vw' }}
                    rowClassName={(record) =>
                      record.id == selectedRowKeys && 3 === tableNo ? 'active-row' : 'data-row'
                    }
                    pagination={false}
                    className="drop-down-tbl"
                    onRow={(record, index) => {
                      return {
                        onClick: (event) => {
                          onRowSelect(record, 3);
                        },
                      };
                    }}
                  />
                </Row>
              </div>

              {/* 日付 */}
              <Row style={{ color: 'black', marginTop: 5 }} className="oyabuhin oyabuhin-row" onClick={clickDate}>
                <Col span={20} className="deli-col">
                  <div style={{ display: 'flex' }}>
                    <label>{additionalItemType[3].label}</label>
                    {isDate ? (
                      <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                    ) : (
                      <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                    )}
                  </div>
                </Col>
              </Row>
              <div style={{ display: isDate ? 'block' : 'none' }}>
                {/* 一覧アリア */}
                <Row style={{ marginTop: 5 }}>
                  <Table
                    showSorterTooltip={false}
                    id="table4"
                    columns={hasCheckData ? tableColumns : chkData}
                    dataSource={dateFilteredData}
                    scroll={{ y: 180, x: '10vw' }}
                    rowClassName={(record) =>
                      record.id == selectedRowKeys && 4 === tableNo ? 'active-row' : 'data-row'
                    }
                    pagination={false}
                    className="drop-down-tbl"
                    onRow={(record, index) => {
                      return {
                        onClick: (event) => {
                          onRowSelect(record, 4);
                        },
                      };
                    }}
                  />
                </Row>
              </div>
            </div>
          </div>
          {/* プロパティ表示 */}
          <div>
            <Routes>
              <Route
                exact
                path=":common.additional"
                element={
                  <AdditionalItemsDetails
                    ref={detailRef}
                    editMode={editMode}
                    selectedData={selectedData}
                    submitData={submitData}
                    tableNo={tableNo}
                    showEdtConfirmOK={showEdtConfirmOK}
                    showEdtConfirmCancle={showEdtConfirmCancle}
                    updateEditMode={updateEditMode}
                  />
                }
              />
            </Routes>
          </div>
        </SplitPane>
      </div>

      {/* 新規作成 */}
      {isCreateNew
        ? commonModal(
            isCreateNew,
            createNewTitle,
            null,
            null,
            800,
            createNewOK,
            createNewCancel,
            createNewContent,
            'addNewCustomerModal',
            null,
            true
          )
        : ''}

      {/* 一括編集 */}
      {isBulkEdit
        ? commonModal(
            isBulkEdit,
            bulkEditTitle,
            null,
            null,
            1790,
            bulkEditOK,
            bulkEditCancel,
            bulkEditContent,
            'updateModalStyle',
            null,
            true
          )
        : ''}

      {/* 削除 */}
      {isDelete
        ? commonModal(
            isDelete,
            confirmModalTitle,
            commonDeleteFooter(deleteOk, deleteCancel),
            null,
            400,
            deleteOk,
            deleteCancel,
            editMode ? confirmDiscardAndDeleteContent : confirmDeleteContent,
            null,
            null,
            false
          )
        : ''}

      {/* CSV取込 */}
      {/* <Modal
        title={reportModalTitle}
        open={visibleCSVInput}
        onOk={handleOkCSVInput}
        onCancel={handleCancel}
        footer={[
          <Button key="file" type="primary" onClick={handleOkCSVInput} className="mainButton">
            OK
          </Button>,
          <Button key="back" onClick={handleCancel} className="cancelButton">
            キャンセル
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p style={{ fontSize: '13.5px' }}>
          PCに保存した情報を読み込みます。<br></br>よろしいでしょうか？
        </p>
      </Modal> */}

      {/* XML呼出 */}
      {/* <Modal
        title={reportModalTitle}
        open={visibleXMLInput}
        onOk={handleOkXMLInput}
        onCancel={handleCancel}
        footer={[
          <Button key="file" type="primary" onClick={handleOkXMLInput} className="mainButton">
            OK
          </Button>,
          <Button key="back" onClick={handleCancel} className="cancelButton">
            キャンセル
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p style={{ fontSize: '13.5px' }}>
          装置情報（時間チャージ、段取設定、加工設定）を初期パラメータ作成情報から
          <br />
          取り込みます。
          <br />
          装置情報は、呼出情報で更新されます。
        </p>
      </Modal> */}

      {/* 登録確認 */}
      {createConfirm
        ? commonModal(
            createConfirm,
            confirmModalTitle,
            commonFooter(confirmOk, confirmCancel),
            null,
            400,
            confirmOk,
            confirmCancel,
            confirmCreateContent,
            null,
            null,
            false
          )
        : ''}

      {/* 更新確認 */}
      {updateConfirm
        ? commonModal(
            updateConfirm,
            confirmModalTitle,
            commonFooter(confirmOk, confirmCancel),
            null,
            400,
            confirmOk,
            confirmCancel,
            confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}

      {/** 破棄の確認メッセージ */}
      {cancelConfirm
        ? commonModal(
            cancelConfirm,
            confirmModalTitle,
            commonFooter(cancelConfirmOK, cancelConfirmCancel),
            null,
            400,
            cancelConfirmOK,
            cancelConfirmCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default AdditionalItemsParamList;
