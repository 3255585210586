/**
 * クラス名：工程マスター一覧画面
 * 説明：工程マスター一覧画面である
 * 作成者：ケイ
 * 作成日：2023/05/01
 * バージョン：1.0
 */
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Table, Button, Row, Input, Col, Modal, Select, Space, Radio, Image } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CloseOutlined } from '@ant-design/icons';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import SplitPane from 'split-pane-react';

import 'split-pane-react/esm/themes/default.css';
import '../../../../assets/styles/common.css';
import checked from '../../../../assets/images/checked.png';
import ProcessMasterDetails from './ProcessMasterDetails';
import {
  confirmHakiContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  authorizeError,
} from '../../../common/CommonModal';
import { kouteiMasterData, pageSizes } from '../../../common/Constant';
import {
  today,
  kouteiMasterTypes,
  getTitleForKey,
  updateAccessToken,
  getAccessToken,
  formValidatorMode,
} from '../../../common/Common.js';
import { getProcessInfo } from '../../../common/CommonAPI';
import { ErrorMessage } from '../../../common/Message';
import { StatusCodes } from 'http-status-codes';
import sort_up from '../../../../assets/images/sort_up.png';
import sort_down from '../../../../assets/images/sort_down.png';
import { ServiceClass, WorkType } from '../../../common/enums.js';

dayjs.extend(customParseFormat);

const { TextArea } = Input;

const ProcessMasterList = forwardRef((props, ref) => {
  const detailRef = useRef();

  const [paramSelected, setParamSelected] = useState('工程マスター');
  const [paramType, setParamType] = useState('共通');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [initialTblItems, setInitialTableItems] = useState([]);
  const [processMaster, setProcessMaster] = useState({});
  const [code, setCode] = useState(0);
  const codeInputRef = useRef(null);

  // 初期表示に表示する項目
  // Content Resize
  const [sizes, setSizes] = useState(['45%']);
  // add new
  const [addNewModal, setAddNewModal] = useState(false);
  // add all
  const [addAllModal, setAddAllModal] = useState(false);
  // add
  const [addModal, setAddModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updateItem, setUpdateItem] = useState([]);
  const [surfaceSelectedRowKeys, setSurfaceSelectedRowKeys] = useState([]);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  //const [currentActivePart, setCurrentActivePart] = useState();
  const [activePartMenu, setActivePartMenu] = useState();

  // 編集モード
  const [editMode, setEditMode] = useState(false);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [changeRecordKey, setChangeRecordKey] = useState(0);
  const [discardOKAction, setDiscardOKAction] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [itemNo, setItemNo] = useState(1);
  const [itemNoTmp, setItemNoTmp] = useState(0);

  const inputItemRef = {};

  const navigate = useNavigate();
  const location = useLocation();
  const currentRoute = location.pathname;

  const partMenuChange = (e, tabFlag, masterData) => {
    /* radioTabChange(e); */
    if (editMode && tabFlag) {
      setDiscardOKAction(e);
      updateEditMode(false);
      // setEditModeCancelConfirm(true);
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        if (e === 'common' || e === 'parent' || editModalTitle === 'iq3') {
          updateEditMode(false);
          radioTabChange(e, initialTblItems, tabFlag);
        }
      }
    } else {
      radioTabChange(e, masterData, tabFlag);
    }
    if (e === 'common') {
      props?.updateCurrentParamSelected('processMaster');
    } else if (e === 'parent') {
      props?.updateCurrentParamSelected('oya_processMaster');
    } else if (e === 'iq3') {
      props?.updateCurrentParamSelected('iq3_processMaster');
    }
    props?.updateSelectedRowId(changeRecordKey, 0, currentPage, 'ASC', 'id', {
      activePartMenu: e,
    });
  };

  const columns = [
    {
      id: '1',
      title: 'No',
      dataIndex: 'No',
      width: 55,
      render: (id, record, index) => {
        return (currentPage - 1) * 25 + (index + 1);
      },
      className: 'cm-a-right',
    },

    {
      id: '2',
      title: '分類',
      dataIndex: 'class',
      width: 80,
      render: (id, record, index) => (record.class === 0 ? '親部品' : 'IQ3'),
      sorter: (a, b) =>
        (a.class === 0 ? '親部品' : 'IQ3')
          ?.toUpperCase()
          ?.localeCompare((b.class === 0 ? '親部品' : 'IQ3')?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'class',
    },
    {
      id: '3',
      title: '種別',
      dataIndex: 'isAdditional',
      width: 80,
      render: (id, record, index) => (record.isAdditional ? '追加' : '標準'),
      sorter: (a, b) =>
        (a.isAdditional ? '追加' : '標準')
          ?.toUpperCase()
          ?.localeCompare((b.isAdditional ? '追加' : '標準')?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'isAdditional',
    },
    {
      id: '4',
      title: 'コード',
      dataIndex: 'code',
      width: 120,
      sorter: (a, b) => a.code?.toUpperCase()?.localeCompare(b.code?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'code',
    },
    {
      id: '5',
      title: '工程名',
      dataIndex: 'name',
      width: 130,
      sorter: (a, b) => a.name?.toUpperCase()?.localeCompare(b.name?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'name',
    },
    {
      id: '6',
      title: '工程タイプ',
      dataIndex: 'type',
      render: (id, record, index) => {
        let matchType = kouteiMasterTypes?.find((type) => type.workType === record.workType);
        return matchType ? matchType.typeName : null;
      },
      width: 130,
      sorter: (a, b) =>
        kouteiMasterTypes
          ?.find((type) => type.workType === a.workType)
          ?.typeName?.toUpperCase()
          ?.localeCompare(kouteiMasterTypes?.find((type) => type.workType === b.workType)?.typeName?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'type',
    },
    {
      id: '7',
      title: '作成日',
      dataIndex: 'created',
      render: (id, record, index) => {
        return record['created']?.split('T')[0];
      },
      width: 100,
      sorter: (a, b) => a.created?.toUpperCase()?.localeCompare(b.created?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'created',
    },
    {
      id: '8',
      title: '更新日',
      dataIndex: 'modified',
      render: (id, record, index) => {
        return record['modified']?.split('T')[0];
      },
      width: 100,
      sorter: (a, b) => a.modified?.toUpperCase()?.localeCompare(b.modified?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'modified',
    },
    {
      id: '9',
      title: '備考',
      dataIndex: 'remarks',
      width: 200,
      render: (id, record, index) => {
        return record.info.remarks;
      },
      sorter: (a, b) => a.info.remarks?.toUpperCase()?.localeCompare(b.info.remarks?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'remarks',
    },
    {
      id: '10',
      title: '表示',
      dataIndex: 'display',
      width: 95,
      render: (text, record) => {
        return record.info.isUsed && <Image preview={false} width={13} src={checked} style={{ marginLeft: '3px' }} />;
      },
      className: 'cm-a-center',
      sorter: (a, b) => a.info.isUsed - b.info.isUsed,
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'isUsed',
    },
  ];

  const initialUpdateValues = [1, 2, 3, 4, 5, 6, 7, 8];
  const tableColumns = columns?.map((item) => ({
    ...item,
  }));
  const updateColumns = columns?.filter((item) => {
    return (
      item.id ==
      initialUpdateValues?.filter((i) => {
        if (i == item.id) {
          return i;
        }
      })
    );
  });

  const getKouteiMasterRelatedInfo = (kouteiDBData) => {
    let kouteiMasterInfo = [];

    kouteiDBData?.forEach((e) => {
      let newRecord = { ...e };
      newRecord.relatedClass = e.class === 0 ? '親部品' : 'IQ3';
      newRecord.isAdditionalRelated = e.isAdditional ? '追加' : '標準';
      let matchType = kouteiMasterTypes?.find((type) => type.workType === e.workType);
      newRecord.relatedType = matchType ? matchType.typeName : null;
      let count = 0;
      let groupedTypes = e.details.processDetailTypes?.reduce((result, type) => {
        if (!result[type.group]) {
          result[type.group] = [];
        }
        result[type.group].push(type);
        return result;
      }, {});

      newRecord.processDetail = Object.keys(groupedTypes)?.map((key, subIndex) => {
        const processDetailTitleObj = getTitleForKey(key, matchType);
        return {
          group: parseInt(key),
          processDetailTitle: processDetailTitleObj.detailName,
          defaultRow: processDetailTitleObj?.defaultRow || 0,
          processDetailValue: groupedTypes[key],
        };
      });
      kouteiMasterInfo.push(newRecord);
    });
    return kouteiMasterInfo;
  };

  const radioTabChange = (currentTabVal, masterData, tabFlag) => {
    let currentTab = currentTabVal;
    if (!tabFlag) {
      currentTab =
        (props.initialParam === 'processMaster' || props.initialParam === 'iq3_processMaster') &&
        props?.filterDatas !== undefined
          ? props?.filterDatas?.activePartMenu
          : currentTabVal;
    }
    //setCurrentActivePart(currentTab);
    setActivePartMenu(currentTab);
    props.setParam(currentTab, paramSelected);
    let filterData;
    if (currentTab === 'common') {
      filterData = masterData;
      navigate(':common.processMaster');
    } else if (currentTab === 'parent') {
      filterData = masterData?.filter((obj) => obj.class === 0);
      navigate(':parent.processMaster');
    } else if (currentTab === 'iq3') {
      filterData = masterData?.filter((obj) => obj.class === 1);
      navigate(':iq3.processMaster');
    }
    setCurrentPage(1);
    setItemNo(1);
    setInitialTableItems(filterData);
    setSelectedRowKeys(filterData != undefined && filterData?.length > 0 ? filterData[0].id : -1);
    setSelectedData(filterData != undefined && filterData?.length > 0 ? filterData[0] : []);
    // 前回選択された行がある場合
    if (
      (props.initialParam === 'processMaster' ||
        props.initialParam === 'oya_processMaster' ||
        props.initialParam === 'iq3_processMaster') &&
      props.selectedRowId !== undefined &&
      props.selectedRowId !== 0
    ) {
      let selectedData = filterData?.filter((item) => item.id === props?.selectedRowId);
      if (selectedData?.length > 0) {
        setSelectedRowKeys(selectedData[0].id);
        setSelectedData(selectedData[0]);
      }
    }
  };

  async function getKouteiMasterInfo() {
    try {
      props?.loading(true); // Waitingダイアログを表示にする
      const kouteiMasterDataDB = await getProcessInfo();
      const kouteiMasterData = getKouteiMasterRelatedInfo(kouteiMasterDataDB);
      /*  window.localStorage.getItem('kouteiMasterData') != undefined
        ? JSON.parse(window.localStorage.getItem('kouteiMasterData'))
        : []; */
      setInitialTableItems(kouteiMasterData);
      setEditMode(false);
      if (currentRoute?.includes('common')) {
        partMenuChange('common', false, kouteiMasterData);
        navigate(':common.processMaster');
        setParamSelected('processMaster');
        setParamType('common');
      } else if (currentRoute?.includes('parent')) {
        partMenuChange('parent', false, kouteiMasterData);
        navigate(':parent.processMaster');
        setParamSelected('oya_processMaster');
        setParamType('parent');
      } else if (currentRoute?.includes('iq3')) {
        partMenuChange('iq3', false, kouteiMasterData);
        navigate(':iq3.processMaster');
        setParamSelected('iq3_processMaster');
        setParamType('iq3');
      }
      props?.loading(false); // Waitingダイアログを非表示にする
    } catch (e) {
      error(e.message);
      return;
    }
  }

  useEffect(() => {
    getKouteiMasterInfo();
  }, [currentRoute]);

  const onSelectChange = (checked, record, index, originNode) => {
    setSurfaceSelectedRowKeys(checked);
  };

  const rowSelection = {
    surfaceSelectedRowKeys,
    onChange: onSelectChange,
  };

  const updateSurface = () => {
    setUpdateItem(kouteiMasterData);
    setIsUpdate(true);
  };

  const updateOK = () => {
    setUpdateConfirm(true);
  };

  const updConfirmOk = () => {
    setIsUpdate(false);
    setUpdateConfirm(false);
    /*  if (!detailRef.current.formValidation(inputItemRef, formValidatorMode.Edit)) {
      return;
    } */
  };

  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    //setIsUpdate(false);
    //setCancelConfirm(false);
    if (discardOKAction === 'rowSelect') {
      setSelectedRowKeys(changeRecordKey);
      setSelectedData(initialTblItems?.find((item) => item.id === changeRecordKey));
      setItemNo(itemNoTmp);
    } else if (discardOKAction === 'add') {
      addNewProcess();
    } else if (discardOKAction === 'all' || discardOKAction === 'parent' || discardOKAction === 'iq3') {
      updateEditMode(false);
      radioTabChange(discardOKAction, initialTblItems);
    }
    setEditMode(false);
  };

  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
    setCancelConfirm(false);
    setEditMode(true);
    // detailRef?.current?.discardConfirmCancel();
  };

  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  const updateCancel = () => {
    setCancelConfirm(true);
  };

  const showAddNewModal = () => {
    if (editMode) {
      setDiscardOKAction('add');
      updateEditMode(false);
      setEditModeCancelConfirm(true);
    } else {
      addNewProcess();
    }
  };

  const addNewProcess = () => {
    setProcessMaster({
      id: '',
      No: '',
      type: '親部品',
      category: '追加',
      code: (initialTblItems?.length + 1).toString().padStart(3, '0'),
      processName: '',
      processType: 'カスタム',
      remark: '',
      processDetail: [
        {
          key: 0,
          processDetailTitle: '追加工程種類',
          processDetailValue: [
            {
              key: 0,
              usage: true,
              detailCode: (1).toString().padStart(3, '0'),
              detailType: [''],
              created: dayjs(today).format('YYYY-MM-DD'),
              modified: dayjs(today).format('YYYY-MM-DD'),
              remark: '',
              useFlg: false,
            },
          ],
        },
      ],
    });
    setAddModal(true);
  };

  const updateClose = () => {
    setIsUpdate(false);
  };

  const onRowSelect = (record, index) => {
    setChangeRecordKey(record.id);
    // IQX_WEBEST-263 板金工程の検査工程で、工程マスターに計算方法の表示があるが、不要な為非表示にしてほしい
    if (record?.class === ServiceClass.SheetMetal && record?.workType === WorkType.SmInspection) {
      record.processDetail = [];
    }
    if (editMode) {
      setDiscardOKAction('rowSelect');
      setItemNoTmp((currentPage - 1) * 25 + (index + 1));
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setSelectedRowKeys(record.id);
        setSelectedData(record);
        setItemNo((currentPage - 1) * 25 + (index + 1));
        updateEditMode(false);
        radioTabChange(discardOKAction, initialTblItems);
      }
    } else {
      setSelectedRowKeys(record.id);
      setSelectedData(record);
      setItemNo((currentPage - 1) * 25 + (index + 1));
    }
    props?.updateSelectedRowId(record?.id, 0, currentPage, 'ASC', 'id', {
      activePartMenu: activePartMenu,
    });
    props?.resetInitialParam();
  };

  // 入力した新規情報を取得する
  const prepareEditedInfo = (updatedData) => {
    let info = {
      isUsed: updatedData.info.isUsed,
      remarks: updatedData.info.remarks,
      sortNum: updatedData.info.sortNum,
    };

    const processDetailTypes = updatedData?.processDetail.flatMap((item) =>
      item.processDetailValue?.map((detailValue) => ({
        id: detailValue.id,
        group: detailValue.group,
        isUsed: detailValue.isUsed,
        code: detailValue.code,
        name: detailValue.name,
        created: detailValue.created,
        modified: detailValue.modified,
        remarks: detailValue.remarks,
      }))
    );

    let details = {
      processQty: updatedData?.details?.processQty,
      processDetailTypes: processDetailTypes,
    };

    let tempData = {
      id: updatedData.id,
      code: updatedData.code,
      name: updatedData.name,
      info: info,
      details: details,
    };
    return tempData;
    // setTempCoefficientData(tempData);
  };

  const submitData = async (updatedData) => {
    var id = updatedData.id;
    var tmpData = [];
    var index;
    tmpData = [...initialTblItems];
    props?.loading(true); // Waitingダイアログを表示にする
    let updatedInfo = await updateProcessData(updatedData);
    index = tmpData?.findIndex((item) => item.id === updatedInfo?.id);
    tmpData[index] = updatedInfo;
    // if (id === selectedRowKeys) detailRef.current?.displayDetailInfo(updatedInfo);
    setInitialTableItems(getKouteiMasterRelatedInfo(tmpData));
    /* index = tmpData?.findIndex((item) => item.id === updatedInfo.id);
    tmpData[index] = updatedData;
    window.localStorage.setItem('kouteiMasterData', JSON.stringify(tmpData)); */
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // 工程情報をDBに更新する
  async function updateProcessData(updateData) {
    let ret = [];
    let data = prepareEditedInfo(updateData);
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'Process';
      const otherParam = {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          id: data.id,
          code: data.code,
          name: data.name,
          info: data.info,
          processDetail: data.details,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  const setSplitPaneSize = (splitSize) => {
    setSizes(splitSize);
  };

  useImperativeHandle(ref, () => ({
    setSplitPaneSize: setSplitPaneSize,
    showAddNewModal: showAddNewModal,
    updateSurface: updateSurface,
    getEditMode: editMode,
    getParamType: activePartMenu,
    updateEditMode: setEditMode,
    setEditModeCancelConfirm: setEditModeCancelConfirm,
    discardConfirmOk: discardConfirmOk,
  }));

  const addAll = () => {
    setAddNewModal(false);
    setAddAllModal(true);
  };

  const addAllOK = () => {
    setAddAllModal(false);
  };

  const addAllCancel = () => {
    setAddAllModal(false);
  };

  const add = () => {
    setAddNewModal(false);
    setAddModal(true);
  };

  const closeModal = () => {
    setAddNewModal(false);
  };

  const resetAddModal = (count) => {
    setProcessMaster({
      category: '追加',
      type: '親部品',
      code: count.toString().padStart(3, '0'),
      processName: '',
      processType: 'カスタム',
      remark: '',
    });
  };

  const addOK = () => {
    let stateData = JSON.parse(JSON.stringify(initialTblItems));
    let count = stateData?.length;
    processMaster.key = count;
    processMaster.No = count + 1;
    stateData.push(processMaster);
    setInitialTableItems(stateData);
    resetAddModal(stateData?.length + 1);
    // onRowSelect(processMaster);
    setSelectedRowKeys(count);
    setSelectedData(processMaster);
    setAddModal(false);
  };

  const addCancel = () => {
    setAddModal(false);
  };

  const editModalTitle = (
    <div
      style={{
        width: 1300,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>選択したデータをまとめて編集</p>
    </div>
  );

  // 編集モードを変更
  const updateEditMode = (mode) => {
    setEditMode(mode);
    props.updateMainParameterEditMode(mode);
  };

  const handlePageSizeChange = (current, pagesize) => {
    setPageSize(pagesize);
  };

  return (
    <>
      <div
        style={{
          height: '90.8vh',
          marginLeft: 10,
        }}
        // className="kouteiMaster"
      >
        <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1}>
          <div style={{ marginTop: 7 }}>
            <Row>
              <div>
                <Row style={{ marginLeft: '10px' }}>
                  <Col>
                    <Radio.Group
                      value={activePartMenu}
                      onChange={(e) => partMenuChange(e.target.value, true, initialTblItems)}
                    >
                      <Radio.Button value="common" style={{ width: 80, textAlign: 'center' }}>
                        <p style={{ fontSize: '13.5px', fontWeight: 500 }} value="common">
                          ALL
                        </p>
                      </Radio.Button>
                      <Radio.Button value="parent" style={{ width: 80, textAlign: 'center' }}>
                        <p style={{ fontSize: '13.5px', fontWeight: 500 }}>親部品</p>
                      </Radio.Button>
                      <Radio.Button value="iq3" style={{ width: 80, textAlign: 'center' }}>
                        <p style={{ fontSize: '13.5px', fontWeight: 500 }}>iQ3板金</p>
                      </Radio.Button>
                    </Radio.Group>
                  </Col>
                </Row>
              </div>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Table
                showSorterTooltip={false}
                className="param_tbListShow"
                rowClassName={(record) => (record.id == selectedRowKeys ? 'active-row' : 'data-row')}
                columns={tableColumns}
                rowKey={(record) => record.id}
                dataSource={initialTblItems}
                scroll={{ y: 590, x: '10vw' }}
                pagination={{
                  position: ['bottomLeft'],
                  total: (record) => record?.length,
                  showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                  // defaultPageSize: 25,
                  defaultCurrent: 1,
                  current: currentPage,
                  pageSize: pageSize,
                  pageSizeOptions: pageSizes,
                  showSizeChanger: true,
                  onShowSizeChange: handlePageSizeChange,
                  onChange: (page) => {
                    setCurrentPage(page);
                  },
                }}
                onRow={(record, index) => {
                  return {
                    onClick: (event) => {
                      onRowSelect(record, index);
                    },
                  };
                }}
              />
            </Row>
          </div>
          <div>
            <Routes>
              <Route
                exact
                path=":common.processMaster"
                element={
                  <ProcessMasterDetails
                    ref={detailRef}
                    editMode={editMode}
                    selectedData={selectedData}
                    paramType={paramType}
                    submitData={submitData}
                    updateEditMode={updateEditMode}
                    itemNo={itemNo}
                  />
                }
              />
              <Route
                exact
                path=":parent.processMaster"
                element={
                  <ProcessMasterDetails
                    ref={detailRef}
                    editMode={editMode}
                    selectedData={selectedData}
                    paramType={paramType}
                    submitData={submitData}
                    updateEditMode={updateEditMode}
                    itemNo={itemNo}
                  />
                }
              />
              <Route
                exact
                path=":iq3.processMaster"
                element={
                  <ProcessMasterDetails
                    ref={detailRef}
                    editMode={editMode}
                    selectedData={selectedData}
                    paramType={paramType}
                    submitData={submitData}
                    updateEditMode={updateEditMode}
                    itemNo={itemNo}
                  />
                }
              />
            </Routes>
          </div>
        </SplitPane>
      </div>
      {/* 新規追加_main */}
      <Modal
        title={
          <div
            style={{
              width: 400,
              backgroundColor: '#005fab',
              marginLeft: -24,
              height: 36,
              marginTop: -20,
              borderTopLeftRadius: 7,
              borderTopRightRadius: 7,
              fontSize: 14,
            }}
          >
            <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>新規追加</p>
          </div>
        }
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        open={addNewModal}
        width={400}
        onOk={addAll}
        onCancel={closeModal}
        footer={[
          <Button key="submit16" onClick={addAll} className="mainButton">
            はい
          </Button>,
          <Button key="back" onClick={add} className="cancelButton">
            いいえ
          </Button>,
        ]}
        className="addData"
      >
        <p>板厚パターンでまとめて作成しますか。</p>
      </Modal>
      {/* まとめて */}
      <Modal
        title={
          <div
            style={{
              width: 455,
              backgroundColor: '#005fab',
              marginLeft: -24,
              height: 36,
              marginTop: -20,
              borderTopLeftRadius: 7,
              borderTopRightRadius: 7,
              fontSize: 14,
            }}
          >
            <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>まとめて新規追加</p>
          </div>
        }
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        open={addAllModal}
        width={455}
        onOk={addAllOK}
        onCancel={addAllCancel}
        footer={[
          <Button key="submit17" onClick={addAllOK} className="mainButton">
            OK
          </Button>,
          <Button key="back" onClick={addAllCancel} className="cancelButton">
            キャンセル
          </Button>,
        ]}
      >
        <p>まとめて作成します。</p>
        <Row>
          <Col span={6} className="material-font">
            表示名称
          </Col>
          <Col span={16}>
            <Select style={{ width: 300 }}></Select>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col span={6} className="material-font">
            金属名称
          </Col>
          <Col span={16}>
            <Select style={{ width: 300 }}></Select>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col span={6} className="material-font">
            比重
          </Col>
          <Col span={16}>
            <Select style={{ width: 300 }}></Select>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col span={6} className="material-font">
            板厚パターン
          </Col>
          <Col span={16}>
            <Select style={{ width: 300 }}></Select>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col span={6} className="material-font">
            重量単価
          </Col>
          <Col span={16}>
            <Input style={{ width: 300 }} />
          </Col>
        </Row>
      </Modal>
      {/* 追加 */}
      <Modal
        className="addKouteiMaster"
        title={
          <div
            style={{
              width: 455,
              backgroundColor: '#005fab',
              marginLeft: -24,
              height: 36,
              marginTop: -20,
              borderTopLeftRadius: 7,
              borderTopRightRadius: 7,
              fontSize: 14,
            }}
          >
            <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>新規追加</p>
          </div>
        }
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        open={addModal}
        width={455}
        onOk={addOK}
        onCancel={addCancel}
        footer={[
          <Button key="submit18" onClick={addOK} className="mainButton">
            OK
          </Button>,
          <Button key="back" onClick={addCancel} className="cancelButton">
            キャンセル
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <td className="param-detail-tbl-val-title">分類</td>
                  <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                    <Select
                      defaultValue="親部品"
                      style={{
                        width: '100%',
                        fontSize: 13,
                      }}
                      className="categoryDDL"
                      value={processMaster.type}
                      onChange={(e) => {
                        {
                          setProcessMaster({ ...processMaster, type: e });
                        }
                      }}
                      options={['親部品', 'iq3', 'iq5']?.map((province) => ({ label: province, value: province }))}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="param-detail-tbl-val-title">種別</td>
                  <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                    <Select
                      defaultValue="追加"
                      disabled
                      style={{
                        width: '100%',
                        fontSize: 13,
                      }}
                      value={processMaster.category}
                      className="categoryDDL"
                      onChange={(e) => {
                        {
                          setProcessMaster({ ...processMaster, category: value });
                        }
                      }}
                      options={['標準', '追加']?.map((province) => ({
                        label: province,
                        value: province,
                      }))}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="param-detail-tbl-val-title">コード</td>
                  <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                    {' '}
                    <Input
                      type="text"
                      data-name="name"
                      style={{ padding: 3 }}
                      value={processMaster.code}
                      ref={codeInputRef}
                      onChange={(e) => {
                        {
                          setProcessMaster({ ...processMaster, code: e.target.value });
                        }
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="param-detail-tbl-val-title">工程名</td>
                  <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                    {' '}
                    <Input
                      type="text"
                      data-name="name"
                      style={{ padding: 3 }}
                      value={processMaster.processName}
                      onChange={(e) => {
                        {
                          setProcessMaster({ ...processMaster, processName: e.target.value });
                        }
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="param-detail-tbl-val-title">工程タイプ</td>
                  <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                    {' '}
                    <Select
                      defaultValue="カスタム"
                      disabled
                      style={{
                        width: '100%',
                        fontSize: 13,
                      }}
                      value={processMaster.processType}
                      className="categoryDDL"
                      onChange={(e) => {
                        {
                          setProcessMaster({ ...processMaster, processType: e.target.value });
                        }
                      }}
                      options={['溶接', '溶接仕上', '表面処理', '組立', '検査', '梱包', 'カスタム']?.map(
                        (province) => ({
                          label: province,
                          value: province,
                        })
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="param-detail-tbl-val-title">備考</td>
                  <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                    {' '}
                    <TextArea
                      type="text"
                      data-name="name"
                      style={{ padding: 3 }}
                      value={processMaster.remark}
                      onChange={(e) => {
                        {
                          setProcessMaster({ ...processMaster, remark: e.target.value });
                        }
                      }}
                    />
                  </td>
                </tr>
              </thead>
            </table>
          </Col>
        </Row>
      </Modal>

      {/* 纏めて編集 */}
      <Modal
        centered
        title={editModalTitle}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        open={isUpdate}
        width={1300}
        onOk={updateOK}
        onCancel={updateClose}
        footer={[null]}
      >
        <Row>
          <Col span={14}>
            <p style={{ fontSize: '13.5px', marginBottom: 5, marginTop: 3, fontWeight: '700' }}>
              {surfaceSelectedRowKeys?.length}件を選択中
            </p>
            <Table
              showSorterTooltip={false}
              style={{ marginTop: 10, marginBottom: -20 }}
              columns={updateColumns}
              rowKey={(record) => record.id}
              dataSource={updateItem}
              rowSelection={rowSelection}
              scroll={{ y: 600, x: '10vw' }}
              pagination={{
                position: ['bottomLeft'],
                total: (record) => record?.length,
                showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                defaultPageSize: 100,
                defaultCurrent: 1,
                current: currentPage,
                onChange: (page) => {
                  setCurrentPage(page);
                },
              }}
            />
          </Col>
          <Col span={10}>
            <table className="table table-bordered" style={{ marginTop: 31 }}>
              <thead>
                <tr style={{ height: 35 }}>
                  <td className="parent-detail-tbl-val-title">表示名称</td>
                  <td colSpan={6} style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                    <input
                      style={{ padding: 3 }}
                      className="input-editable form-control"
                      name="displayName"
                      id="displayName"
                      type="text"
                    />
                  </td>
                </tr>

                <tr style={{ height: 35 }}>
                  <td className="parent-detail-tbl-val-title">名称</td>
                  <td colSpan={6} style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                    <input
                      style={{ padding: 3 }}
                      className="input-editable form-control"
                      name="name"
                      id="name"
                      type="text"
                    />
                  </td>
                </tr>
                <tr style={{ height: 35 }}>
                  <td className="parent-detail-tbl-val-title">板厚</td>
                  <td colSpan={6} style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                    <input
                      style={{ padding: 3 }}
                      className="input-editable form-control"
                      name="thickness"
                      id="thickness"
                      type="text"
                    />
                  </td>
                </tr>
                <tr style={{ height: 35 }}>
                  <td className="parent-detail-tbl-val-title">比重</td>
                  <td colSpan={6} style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                    <input
                      style={{ padding: 3 }}
                      className="input-editable form-control"
                      name="gravity"
                      id="gravity"
                      type="text"
                    />
                  </td>
                </tr>
                <tr style={{ height: 35 }}>
                  <td className="parent-detail-tbl-val-title">重量単価</td>
                  <td colSpan={6} style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                    <input
                      style={{ padding: 3 }}
                      className="input-editable form-control"
                      name="unitPrice"
                      id="unitPrice"
                      type="text"
                    />
                  </td>
                </tr>
                <tr style={{ height: 35 }}>
                  <td className="parent-detail-tbl-val-title">作成日</td>
                  <td colSpan={6} style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                    <input
                      style={{ padding: 3 }}
                      className="input-editable form-control"
                      name="createdDate"
                      id="createdDate"
                      type="text"
                    />
                  </td>
                </tr>
                <tr style={{ height: 35 }}>
                  <td className="parent-detail-tbl-val-title">備考</td>
                  <td colSpan={6} style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                    <input
                      style={{ padding: 3 }}
                      className="input-editable form-control"
                      name="remarks"
                      id="remarks"
                      type="text"
                    />
                  </td>
                </tr>
              </thead>
            </table>
          </Col>
        </Row>
        <Row justify="end" style={{ marginTop: -20, marginBottom: -19 }}>
          <Space>
            <Button onClick={updateOK} id="update" className="mainButton">
              更新
            </Button>

            <Button onClick={updateCancel} className="cancelButton" id="discard">
              破棄
            </Button>
          </Space>
        </Row>
      </Modal>

      {/* 更新確認_Modal */}
      <Modal
        title="確認"
        open={updateConfirm}
        onOk={updConfirmOk}
        onCancel={updConfirmCancel}
        footer={[
          <Button key="submit19" onClick={updConfirmOk} className="mainButton">
            OK
          </Button>,
          <Button key="back" onClick={updConfirmCancel} className="cancelButton">
            キャンセル
          </Button>,
        ]}
      >
        <p>データを更新します。よろしいでしょうか。</p>
      </Modal>

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default ProcessMasterList;
