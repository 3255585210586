/**
 * クラス名：アプリの初期起動画面
 * 説明：最初に起動するファイルであり、ルーティングページを指定する。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import './App.css';
import React, { forwardRef, useEffect } from 'react';
import { BrowserRouter as Router, Route, Redirect, Routes, Navigate, useParams } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';

import Login from './views/Login';
import MainLayout from './components/MainLayout';
import { updateAccessToken } from '../src/views/common/Common';
import { loginPath, getCurrentUserInfo } from '../src/views/common/CommonAPI';
import { ErrorMessage } from '../src/views/common/Message';

const MainLayoutRoutes = ['/home/*', '/:customer/*', '/:parentList/*'];

class App extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    // Activate the event listener
    this.setupBeforeUnloadListener();
    this.setupKeydownListener();
  }

  // Browser閉じるの場合、ログイン情報を削除
  doSomethingBeforeUnload = () => {
    localStorage?.removeItem('iQxWeb_LoginUser');
    // localStorage?.removeItem('iQxWeb_AccessToken');
    return '';
  };

  // Browser閉じるイベント
  setupBeforeUnloadListener = () => {
    window.addEventListener('beforeunload', (ev) => {
      return this.doSomethingBeforeUnload();
    });
  };

  setupKeydownListener = () => {
    document.addEventListener('keydown', this.handleKeydown);
  };

  handleKeydown = (e) => {
    // Check if the pressed key is F5
    if (e.key === 'F5') {
      e.preventDefault();
    }
  };
  render() {
    return (
      <Router>
        <Routes basename="iqx.webestimate">
          {/* ログイン */}
          <Route key="login" path="/login" element={<Login />} />
          {MainLayoutRoutes?.map((path, index) => (
            <Route key={index} path={path} element={<MainLayout />} />
          ))}
          <Route key="login2" path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </Router>
    );
  }
}

export default App;
