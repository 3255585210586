/**
 * クラス名：板金のブランクの1ピアス加工時間画面
 * 作成者：チュー
 * 作成日：2023/06/28
 * バージョン：1.0
 */
import React, { useEffect, forwardRef, useState, useRef } from 'react';
import { Table } from 'react-bootstrap';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Row, Col, Input, Space, Button, Popover } from 'antd';
import { isEmpty } from '@rc-component/mini-decimal/es/numberUtil';
import { commonModal } from '../../../common/CommonModal';
import {
  handleKeyPress,
  updateList,
  sortListByMasterData,
  getInputBorderStyle,
  regexExp,
  toHalfWidth,
  isDigitZenkaku,
  isDigit,
} from '../../../common/Common';

const Device_Blank_PiercingProcessTime = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);

  const [isItaatsu, setIsItaatsu] = useState(false);

  // 板厚
  const [itaatsuTemp, setItaatsuTemp] = useState([]);
  const [itaatsuData, setItaatsuData] = useState([]);

  // const [gasTypeList, setGasTypeList] = useState([]);
  // const [jointList, setJointList] = useState([]);
  // const [kakousokudoItaatsuList, setKakousokudoItaatsuList] = useState([]);
  const [piercingProcessList, setPiercingProcessList] = useState([]);
  const [materialType, setMaterialType] = useState([]);

  const [filterKeys, setFilterKeys] = useState([]);

  const [errIndexVal, setErrIndexVal] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const inputRef = useRef([]);
  // const [punchProcessItaatsuListData, setPunchProcessItaatsuListData] = useState([]);
  // const [listData, setListData] = useState([]);
  useEffect(() => {
    setErrIndexVal([]);
    setErrorMessage('');
    setEditMode(props.editMode);
    if (props?.detailsData?.piercingTimeThickThMaster) {
      setItaatsuData(props?.detailsData?.piercingTimeThickThMaster);
      setItaatsuTemp(props?.detailsData?.piercingTimeThickThMaster);
    }

    if (props?.detailsData?.piercingTimeThItems) {
      setPiercingProcessList(props?.detailsData?.piercingTimeThItems);
    }
    setMaterialType(props.materialTypes);
    const usedItems = props.materialTypes?.filter((item) => item.info.isUsed);

    // Extract the id values from the filtered items
    const usedIds = usedItems?.map((item) => item.id);

    setFilterKeys(usedIds);
    // setGasTypeList(props.gasTypeList);
    // setJointList(props.jointItaatsuList);
    // setKakousokudoItaatsuList(props.kakouSokudoItaatsuList);
    // setPunchProcessItaatsuListData(props.punchProcessItaatsuListData);
    // setListData(props.blankKakousokudolistData);
  }, [
    // props.processName,
    props.editMode,
    props.detailsData,
    props.materialTypes,
    // props.kakouSokudoItaatsuList,
    // props.gasTypeList,
    // props.jointItaatsuList,
    // props.piercingProcessList,
    // props.punchProcessItaatsuListData,
    // props.blankKakousokudolistData,
  ]);

  const addItaatsuModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>板厚</p>
    </div>
  );

  const addData = () => {
    setIsUpdateData(true);
  };

  const closeAddModal = () => {
    if (isItaatsu === true) {
      setIsItaatsu(false);
    }
  };
  const addItaatsu = (id, index) => {
    inputRef.current[index].focus();
    const copyData = itaatsuTemp.slice(); // Create a copy of the original array

    const insertIndex = itaatsuTemp?.findIndex((item) => item.no === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: itaatsuTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setItaatsuTemp(copyData);
    }
  };
  const deleteItaatsu = (id, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);

    if (itaatsuTemp?.filter((item) => item.isOutOfRange === false)?.length > 2) {
      const updatedData = itaatsuTemp?.map((row) => {
        if (row.no === id) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setItaatsuTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };

  const handleItaatsuChange = (event, id, field) => {
    if (regexExp.test(event.target.value) && !isDigitZenkaku(event.target.value)) return;
    const updatedData = itaatsuTemp?.map((row) => {
      if (row.no === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setItaatsuTemp(updatedData);
  };

  const cancelMasterData = () => {
    setErrIndexVal([]);
    setErrorMessage('');
    setItaatsuTemp(itaatsuData);
    setIsItaatsu(false);
  };
  const updateListData = (deletedData, newId) => {
    const list = [
      { key: 1, name: '丸穴径', svalue: '', mvalue: '', lvalue: '' },
      { key: 2, name: '加工周長', svalue: '', mvalue: '', lvalue: '' },
      { key: 3, name: '速度', svalue: '', mvalue: '', lvalue: '' },
    ];
    const updatedData = deletedData?.map((item) => {
      newId?.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
          list: list,
        });
      });

      return item;
    });
    return updatedData;
  };
  const addItaatsuMaster = () => {
    setIsItaatsu(false);

    const deletedId = [];

    itaatsuData?.forEach((item1, index) => {
      const found = itaatsuTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange === true);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = itaatsuTemp
      ?.filter((item) => !item.isOutOfRange && !itaatsuData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    const updatedList = updateList(piercingProcessList, deletedId, newId);
    const sortedList = sortListByMasterData(updatedList, itaatsuTemp);
    // const deletedData = deleteListColData(gasTypeList, deletedId);
    // const deleteData2 = deleteListColData(jointList, deletedId);
    // const deleteData3 = deleteListColData(kakousokudoItaatsuList, deletedId);
    // const deleteData4 = deleteListColData(piercingProcessList, deletedId);
    // const deleteData5 = deleteListColData(punchProcessItaatsuListData, deletedId);
    // const deleteData6 = deleteListColData(listData, deletedId);

    // const updatedData = updateListColData(deletedData, newId);
    // const updatedData2 = updateListColData(deleteData2, newId);
    // const updatedData3 = updateListColData(deleteData3, newId);
    // const updatedData4 = updateListColData(deleteData4, newId);
    // const updatedData5 = updateListColData(deleteData5, newId);
    // const updatedData6 = updateListData(deleteData6, newId);

    // const sortedList = sortListData(updatedData, itaatsuTemp);
    // const sortedList2 = sortListData(updatedData2, itaatsuTemp);
    // const sortedList3 = sortListData(updatedData3, itaatsuTemp);
    // const sortedList4 = sortListData(updatedData4, itaatsuTemp);
    // const sortedList5 = sortListData(updatedData5, itaatsuTemp);
    // const sortedList6 = sortListData(updatedData6, itaatsuTemp);

    setItaatsuData(itaatsuTemp);

    // setGasTypeList(sortedList);
    props.updatePiercingTimeThickThMaster(itaatsuTemp);

    // props.updatedGasTypeList(sortedList);

    // setJointList(sortedList2);
    // props.updatedJointItaatsuList(sortedList2);

    // setKakousokudoItaatsuList(sortedList3);
    // props.updateblankKakouSokudoItaatsuList(sortedList3);

    setPiercingProcessList(sortedList);
    props.updatePiercingTimeThItems(sortedList);

    // setPunchProcessItaatsuListData(sortedList5);
    // props.updatePunchProcessList(sortedList5);
    // setListData(sortedList6);
    // props.updatedBlankKakousokudolistData(sortedList6);
  };

  const isValueOrderCorrect = (e, no, field) => {
    const temp = itaatsuTemp?.filter((i) => i.isOutOfRange === false);

    const insertIndex = temp?.findIndex((item) => item.no === no);
    if (regexExp.test(temp[insertIndex].value) && !isDigitZenkaku(temp[insertIndex].value)) return;
    // IQX_WEBEST-220の対応-START
    if (regexExp.test(temp[insertIndex].value)) {
      // 全角の場合、
      temp[insertIndex].value = toHalfWidth(temp[insertIndex].value); // 半角へ変換
      e.target.value = temp[insertIndex].value;
      handleItaatsuChange(e, no, field);
    }
    if (isNaN(parseFloat(temp[insertIndex].value))) {
      setErrIndexVal([...errIndexVal, insertIndex]);
      setErrorMessage('数字値を入力してください！');
      return;
    }
    // IQX_WEBEST-220の対応-END

    if (insertIndex > 0) {
      const prevValue = parseFloat(temp[insertIndex - 1].value);
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);
      if (currentValue > prevValue && currentValue < nextValue) {
        let temp = [insertIndex - 1, insertIndex, insertIndex + 1];

        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex && item != insertIndex - 1));

        setErrorMessage('前のしきい値より大きい値を入力してください！');
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);

        if (currentValue <= prevValue) {
          setErrorMessage('前のしきい値より大きい値を入力してください！');
        } else if (currentValue >= nextValue) {
          setErrorMessage('次のしきい値より小さい値を入力してください！');
        }
      }
    } else {
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);

      if (currentValue < nextValue) {
        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex));
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);
        setErrorMessage('次のしきい値より小さい値を入力してください！');
      }
    }
  };
  const addContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {itaatsuTemp
                  ?.filter((subitem) => subitem.isOutOfRange === false)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleItaatsuChange(e, item.no, 'value')}
                          onBlur={(e) => isValueOrderCorrect(e, item.no, 'value')}
                          onPressEnter={(e) => isValueOrderCorrect(e, item.no, 'value')}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, itaatsuTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addItaatsu(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteItaatsu(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={addItaatsuMaster}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} id="discard" onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  const itaatsuModal = () => {
    setIsItaatsu(true);
  };
  const piercingProcessListChange = (event, key, no, isCheckZenkaku = true) => {
    if (regexExp.test(event) && !isDigitZenkaku(event)) return;
    if (isCheckZenkaku && regexExp.test(event)) {
      // 全角の場合、
      event = toHalfWidth(event); // 半角へ変換
      if (!isDigit(event)) return;
    }
    // const copyData = [...piercingProcessList];
    // const updatedData = JSON.parse(JSON.stringify(copyData));
    // updatedData[rowIndex].lstData[colIndex].value = event.target.value;

    // setPiercingProcessList(updatedData);

    // props.updatePiercingTimeThItems(updatedData);
    const newList = { ...piercingProcessList };
    const itemIndex = newList[key]?.findIndex((item) => item.no === no);

    if (itemIndex !== -1) {
      newList[key][itemIndex].value = event;
      setPiercingProcessList(newList);
      props.updatePiercingTimeThItems(newList);
    }
  };

  return (
    <>
      <div className="table-container" style={{ marginLeft: 10, marginRight: 10 }}>
        <Table
          style={{
            width:
              itaatsuData?.filter((i) => i.isOutOfRange === false)?.length > 8
                ? '100%'
                : itaatsuData?.filter((i) => i.isOutOfRange === false)?.length * 98 + 196 + 'px',
          }}
        >
          <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
            <td style={{ width: '196px' }} className="tbl-header fixedCol">
              <Row justify="center" className="tbl-header">
                <Space>
                  <label>材質区分</label>
                  <label>/</label>
                  {editMode ? (
                    <>
                      <Button className="cancleButton" onClick={itaatsuModal} style={{ pointerEvents: 'auto' }}>
                        板厚
                      </Button>
                    </>
                  ) : (
                    <>
                      <label>板厚</label>
                    </>
                  )}
                </Space>
              </Row>
            </td>

            {itaatsuData
              ?.filter((subitem) => subitem.isOutOfRange === false)
              ?.map((item, index) => (
                <td key={index} style={{ backgroundColor: '#1063aa', width: '98px' }}>
                  ～{item.value}t
                </td>
              ))}
          </tr>

          {/* {piercingProcessList?.map((row, rowIndex) => (
            <tr style={{ textAlign: 'center' }}>
              <td style={{ color: 'white', width: '196px' }} className="fixedCol">
                {row.materialType}
              </td>

              {row.lstData?.map((col, colIndex) => (
                <td style={{ width: '98px' }}>
                  <Input
                    value={col.value}
                    onChange={(e) => piercingProcessListChange(e, rowIndex, colIndex)}
                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                  />
                </td>
              ))}
            </tr>
          ))} */}
          {Object.keys(piercingProcessList)
            ?.filter((listkey) => filterKeys?.includes(parseInt(listkey)))
            ?.map((key) => (
              <tr key={key} style={{ textAlign: 'center' }}>
                <td style={{ color: 'white', width: '196px' }} className="tbl-header fixedCol">
                  {materialType?.find((item) => item.id.toString() === key)?.name}
                </td>
                {piercingProcessList[key]?.map((item, index) => (
                  <td key={index} style={{ verticalAlign: 'middle', textAlign: 'center', width: 210 }}>
                    <Input
                      value={item.value}
                      onChange={(e) => piercingProcessListChange(e.target.value, key, item.no, false)}
                      onBlur={(e) => piercingProcessListChange(e.target.value, key, item.no)}
                      onPressEnter={(e) => piercingProcessListChange(e.target.value, key, item.no)}
                      onKeyPress={(e) => {
                        handleKeyPress(e, false);
                      }}
                      style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                      className={editMode ? 'input-editable ' : 'input-non-editable'}
                    />
                  </td>
                ))}
              </tr>
            ))}
        </Table>
      </div>

      {/* 板厚 */}
      {isItaatsu
        ? commonModal(
            isItaatsu,
            addItaatsuModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default Device_Blank_PiercingProcessTime;
