import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Row, Col, Space } from 'antd';
import { CaretUpOutlined, CaretDownFilled } from '@ant-design/icons';

import '../../assets/styles/common.css';
import IQ3KouteiCalculate_Program from './IQ3KouteiCalculate_Program';
import IQ3KouteiCalculate_2jikakou from './IQ3KouteiCalculate_2jikakou';
import IQ3KouteiCalculate_MenualBari from './IQ3KouteiCalculate_MenualBari';
import IQ3KouteiCalculate_AutoBari from './IQ3KouteiCalculate_AutoBari';
import IQ3KouteiCalculate_Hyomen from './IQ3KouteiCalculate_Hyomen';
import IQ3KouteiCalculate_Bending from './IQ3KouteiCalculate_Bending';
import IQ3KouteiCalculate_AddtionalProcess from './IQ3KouteiCalculate_AddtionalProcess';
import IQ3KouteiCalculate_Yosetsu from './IQ3KouteiCalculate_Yosetsu';
import IQ3KouteiCalculate_YosetsuFinish from './IQ3KouteiCalculate_YosetsuFinish';
import IQ3KouteiCalculate_Shirring from './IQ3KouteiCalculate_Shirring';
import IQ3KouteiCalculate_Kensa from './IQ3KouteiCalculate_Kensa';
import IQ3KouteiCalculate_BlankLaser from './IQ3KouteiCalculate_BlankLaser';
import IQ3KouteiCalculate_BlankPunch from './IQ3KouteiCalculate_BlankPunch';
import IQ3KouteiCalculate_BlankCombi from './IQ3KouteiCalculate_BlankCombi';
import { EstimateCreationType, WorkType } from '../common/enums';

const KouteiCalculate = forwardRef((props, ref) => {
  const [iq3KouteiCalInfo, setIq3KouteiCalInfo] = useState([]);
  const [iq3SelectedInfo, setIq3SelectedInfo] = useState([]);
  const [iq3SelectedDataFlg, setiq3SelectedDataFlg] = useState(0);
  const [isProgram, setIsProgram] = useState(false);
  const [isSurface, setIsSurface] = useState(false);
  const [isKensa, setIsKensa] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [isShirring, setIsShirring] = useState(false);
  const [isWelding, setIsWelding] = useState(false);
  const [isSecondary, setIsSecondary] = useState(false);
  const [isBending, setIsBending] = useState(false);
  const [isAutoDeburring, setIsAutoDeburring] = useState(false);
  const [isManualDeburring, setIsManualDeburring] = useState(false);
  const [isWeldingFinish, setIsWeldingFinish] = useState(false);
  const [isLaser, setIsLaser] = useState(false);
  const [isPunch, setIsPunch] = useState(false);
  const [isCombi, setIsCombi] = useState(false);
  const [isReCalIQ3, setIsReCalIQ3] = useState(false);

  // 子JSの参照変数定義
  const paSurfaceRef = useRef();
  const paProgramRef = useRef();
  const paBlankLaserRef = useRef();
  const paBlankPunchRef = useRef();
  const paBlankCombiRef = useRef();
  const paNijiRef = useRef();
  const paBendingRef = useRef();
  const paMenualBariRef = useRef();
  const paAutoBariRef = useRef();
  const paYosetsuRef = useRef();
  const paYosetsuFinishRef = useRef();
  const paShirringRef = useRef();
  const paKensaRef = useRef();
  const paAddtionalRef = useRef();

  useEffect(() => {
    checkDisplayPanel();
    if (props?.selectedEstimateData?.esIq3Info?.length > 0) {
      let curRowNo = props.currentRecordNo ? props.currentRecordNo : 1;
      let curIQ3 = props?.selectedEstimateData?.esIq3Info?.filter((item) => item.no === curRowNo);
      if (curIQ3?.length > 0) {
        setIq3KouteiCalInfo(curIQ3?.[0]?.additions);
      }
    }
  }, [
    props?.selectedIQ3DataDetail,
    props?.processMasterData,
    props?.activeTabKey,
    props?.selectedEstimateData,
    props?.iq3ActionForCal,
    props?.selectedProcessSelect,
  ]);

  const sendSelectedDataFlg = (flg) => {
    setiq3SelectedDataFlg(flg);
  };

  const checkDisplayPanel = () => {
    // プログラム
    let programData = props?.processMasterData?.filter((i) => i.workType === WorkType.SmProgram);
    if (programData && programData?.length > 0) {
      let programId = programData[0]?.id;
      let program = props?.selectedIQ3DataDetail?.processSelect?.details?.filter((i) => i.processId === programId);
      if (program?.length > 0) {
        setIsProgram(program[0]?.isUsedForCalc);
      }
    }
    // 表面処理
    let surfaceData = props?.processMasterData?.filter((i) => i.workType === WorkType.SmSurface);
    if (surfaceData && surfaceData?.length > 0) {
      let surfaceId = surfaceData[0]?.id;
      let surface = props?.selectedIQ3DataDetail?.processSelect?.details?.filter((i) => i.processId === surfaceId);
      if (surface?.length > 0) {
        setIsSurface(surface[0]?.isUsedForCalc);
      }
    }
    // 検査処理
    let kensaData = props?.processMasterData?.filter((i) => i.workType === WorkType.SmInspection);
    if (kensaData && kensaData?.length > 0) {
      let kensaId = kensaData[0]?.id;
      let kensa = props?.selectedIQ3DataDetail?.processSelect?.details?.filter((i) => i.processId === kensaId);
      if (kensa?.length > 0) {
        setIsKensa(kensa[0]?.isUsedForCalc);
      }
    }
    // シャーリング処理
    let shirringData = props?.processMasterData?.filter((i) => i.workType === WorkType.SmShearing);
    if (shirringData && shirringData?.length > 0) {
      let shirringId = shirringData[0]?.id;
      let shirring = props?.selectedIQ3DataDetail?.processSelect?.details?.filter((i) => i.processId === shirringId);
      if (shirring?.length > 0) {
        setIsShirring(shirring[0]?.isUsedForCalc);
      }
    }
    // 溶接処理
    let weldingData = props?.processMasterData?.filter((i) => i.workType === WorkType.SmWelding);
    if (weldingData && weldingData?.length > 0) {
      let weldingId = weldingData[0]?.id;
      let welding = props?.selectedIQ3DataDetail?.processSelect?.details?.filter((i) => i.processId === weldingId);
      if (welding?.length > 0) {
        setIsWelding(welding[0]?.isUsedForCalc);
      }
    }

    // ２次加工処理
    let secondaryData = props?.processMasterData?.filter((i) => i.workType === WorkType.SmSecondaryWork);
    if (secondaryData && secondaryData?.length > 0) {
      let secondaryId = secondaryData[0]?.id;
      let secondary = props?.selectedIQ3DataDetail?.processSelect?.details?.filter((i) => i.processId === secondaryId);
      if (secondary?.length > 0) {
        setIsSecondary(secondary[0]?.isUsedForCalc);
      }
    }

    //ベンディング処理
    let bendingData = props?.processMasterData?.filter((i) => i.workType === WorkType.SmBending);
    if (bendingData && bendingData?.length > 0) {
      let bendingId = bendingData[0]?.id;
      let bending = props?.selectedIQ3DataDetail?.processSelect?.details?.filter((i) => i.processId === bendingId);
      if (bending?.length > 0) {
        setIsBending(bending[0]?.isUsedForCalc);
      }
    }

    //自動バリ
    let autoDeburringData = props?.processMasterData?.filter((i) => i.workType === WorkType.SmDeburringAuto);
    if (autoDeburringData && autoDeburringData?.length > 0) {
      let autoDeburringId = autoDeburringData[0]?.id;
      let autoDeburring = props?.selectedIQ3DataDetail?.processSelect?.details?.filter(
        (i) => i.processId === autoDeburringId
      );
      if (autoDeburring?.length > 0) {
        setIsAutoDeburring(autoDeburring[0]?.isUsedForCalc);
      }
    }

    //手動バリ
    let manualDeburringData = props?.processMasterData?.filter((i) => i.workType === WorkType.SmDeburringManual);
    if (manualDeburringData && manualDeburringData?.length > 0) {
      let manualDeburringId = manualDeburringData[0]?.id;
      let manualDeburring = props?.selectedIQ3DataDetail?.processSelect?.details?.filter(
        (i) => i.processId === manualDeburringId
      );
      if (manualDeburring?.length > 0) {
        setIsManualDeburring(manualDeburring[0]?.isUsedForCalc);
      }
    }

    // 溶接仕上
    let weldFinishData = props?.processMasterData?.filter((i) => i.workType === WorkType.SmWeldFinish);
    if (weldFinishData && weldFinishData?.length > 0) {
      let weldFinishDataId = weldFinishData[0]?.id;
      let weldFinish = props?.selectedIQ3DataDetail?.processSelect?.details?.filter(
        (i) => i.processId === weldFinishDataId
      );
      if (weldFinish?.length > 0) {
        setIsWeldingFinish(weldFinish[0]?.isUsedForCalc);
      }
    }

    // レーザー
    let laserData = props?.processMasterData?.filter((i) => i.workType === WorkType.SmBlankLaser);
    if (laserData && laserData?.length > 0) {
      let laserDataId = laserData[0]?.id;
      let laser = props?.selectedIQ3DataDetail?.processSelect?.details?.filter((i) => i.processId === laserDataId);
      if (laser?.length > 0) {
        setIsLaser(laser[0]?.isUsedForCalc);
      }
    }

    // パンチ
    let punchData = props?.processMasterData?.filter((i) => i.workType === WorkType.SmBlankPunch);
    if (punchData && punchData?.length > 0) {
      let punchDataId = punchData[0]?.id;
      let punch = props?.selectedIQ3DataDetail?.processSelect?.details?.filter((i) => i.processId === punchDataId);
      if (punch?.length > 0) {
        setIsPunch(punch[0]?.isUsedForCalc);
      }
    }

    // 複合機
    let combiData = props?.processMasterData?.filter((i) => i.workType === WorkType.SmBlankCombi);
    if (combiData && combiData?.length > 0) {
      let combiDataId = combiData[0]?.id;
      let combi = props?.selectedIQ3DataDetail?.processSelect?.details?.filter((i) => i.processId === combiDataId);
      if (combi?.length > 0) {
        setIsCombi(combi[0]?.isUsedForCalc);
      }
    }

    //追加工程
    let customUsedCnt = 0;
    let customData = props?.processMasterData?.filter((i) => i.workType === WorkType.SmCustom);
    if (customData && customData?.length > 0) {
      for (let i = 0; i < customData?.length; i++) {
        let customDataId = customData[i]?.id;
        let custom = props?.selectedIQ3DataDetail?.processSelect?.details?.filter((i) => i.processId === customDataId);
        if (custom?.length > 0) {
          customUsedCnt++;
        }
      }
      if (customUsedCnt > 0) {
        setIsCustom(true);
      }
    }
  };

  const updateIQ3KouteiCal = (kouteiInfo, workType) => {
    if (props?.selectedEstimateData.esIq3Info == undefined) return iq3KouteiCalInfo;
    let kouteiCalInfoMoto = []; //古い工程積算情報が残っているため、リセット
    let updatedIq3Id = props.currentRecordNo;
    let updatedIq3Index = 0;
    let iq3ProcessSelect = [];
    for (let i = 0; i < props.selectedEstimateData?.esIq3Info?.length; i++) {
      if (props.selectedEstimateData?.esIq3Info[i]?.no == updatedIq3Id) {
        updatedIq3Index = i;
        iq3ProcessSelect = props?.selectedEstimateData?.esIq3Info?.[i].processSelect;
        kouteiCalInfoMoto = props.selectedEstimateData?.esIq3Info?.[i].additions;
      }
    }

    // 更新する工程が工程選択に入っているかチェック
    // 追加工程の場合
    if (workType === WorkType.SmCustom) {
      for (let i = 0; i < kouteiInfo?.length; i++) {
        let info = kouteiInfo[i];
        //　更新する工程を工程積算一覧に検索
        let updateKoutei = kouteiCalInfoMoto?.filter(
          (item) =>
            item.processId == info.processId &&
            // item.no == info.no
            item?.dataItems?.[0]?.processDetailTypeNo === info?.dataItems?.[0]?.processDetailTypeNo &&
            item?.dataItems?.[0]?.processDetailWorkTypeGroup === info?.dataItems?.[0]?.processDetailWorkTypeGroup
        );
        //　削除された工程をもう一度設定した場合
        if (updateKoutei?.length == 0) {
          //工程パターンにチェックしているかチャック、有効の場合に追加
          if (iq3ProcessSelect) {
            let usedForCal = iq3ProcessSelect?.details?.filter(
              (item) => item.processId === info?.processId && item.isUsedForCalc
            );
            if (usedForCal?.length > 0) {
              kouteiCalInfoMoto.push(info);
            }
          }
        } else {
          // 工程選択に入っている工程積算情報を見積データに更新
          kouteiCalInfoMoto?.forEach((data, index) => {
            if (
              data.processId == info.processId &&
              data?.dataItems?.[0]?.processDetailTypeNo === info?.dataItems?.[0]?.processDetailTypeNo &&
              data?.dataItems?.[0]?.processDetailWorkTypeGroup === info?.dataItems?.[0]?.processDetailWorkTypeGroup
              /* && data.no == info.no */
            ) {
              kouteiCalInfoMoto[index] = info;
            }
          });
        }
      }
    } else {
      //　更新する工程を工程積算一覧に検索
      let updateKoutei = kouteiCalInfoMoto?.filter((item) => item.processId == kouteiInfo.processId);
      //　存在しない工程の場合、新規追加
      if (updateKoutei?.length == 0) {
        //工程パターンにチェックしているかチャック、有効の場合に追加
        if (iq3ProcessSelect) {
          let usedForCal = iq3ProcessSelect?.details?.filter(
            (item) => item.processId === kouteiInfo?.processId && item.isUsedForCalc
          );
          if (usedForCal?.length > 0) {
            kouteiCalInfoMoto.push(kouteiInfo);
          }
        }
      } else {
        // 工程選択に入っている工程積算情報を見積データに更新
        kouteiCalInfoMoto?.forEach((data, index) => {
          if (data.processId == kouteiInfo.processId) {
            kouteiCalInfoMoto[index] = kouteiInfo;
          }
        });
      }
    }
    if (props?.selectedEstimateData?.esIq3Info[updatedIq3Index]) {
      props.selectedEstimateData.esIq3Info[updatedIq3Index].additions = kouteiCalInfoMoto;
      // IQX_WEBEST-286 自動：工程積算タブでデータ欄を使用している状態
      let isManual = false;
      for (let i = 0; i < props.selectedEstimateData.esIq3Info[updatedIq3Index]?.additions?.length; i++) {
        let eqAddition = props.selectedEstimateData.esIq3Info[updatedIq3Index]?.additions?.[i];
        if (eqAddition?.dataType) {
          isManual = true;
          break;
        }
      }
      if (isManual) {
        props.selectedEstimateData.esIq3Info[updatedIq3Index].creationType = EstimateCreationType.Manual;
      } else {
        props.selectedEstimateData.esIq3Info[updatedIq3Index].creationType = EstimateCreationType.Auto;
      }
    }
    setIq3KouteiCalInfo(kouteiCalInfoMoto);
    return kouteiCalInfoMoto;
  };

  useImperativeHandle(
    ref,
    () => ({
      getUpdateKouteiCal: updateIQ3KouteiCal,
      getIQ3KouteiCalInfo: () => {
        return iq3KouteiCalInfo;
      },
      getIQ3SelectedFlg: () => {
        return iq3SelectedDataFlg;
      },
    }),
    [iq3KouteiCalInfo, iq3SelectedDataFlg]
  );

  const openAll = () => {
    paProgramRef?.current?.setProgramDisplay(true);
    paBlankLaserRef?.current?.setLaserDisplay(true);
    paBlankPunchRef?.current?.setPunchDisplay(true);
    paBlankCombiRef?.current?.setCombiDisplay(true);
    paNijiRef?.current?.setNijiDisplay(true);
    paBendingRef?.current?.setBendingDisplay(true);
    paMenualBariRef?.current?.setMenualBari(true);
    paAutoBariRef?.current?.setAutoBariDisplay(true);
    paYosetsuRef?.current?.setYosetsuDisplay(true);
    paYosetsuFinishRef?.current?.setYosetsuFinishDisplay(true);
    paSurfaceRef?.current?.setSurfaceDisplay(true);
    paShirringRef?.current?.setShirringDisplay(true);
    paKensaRef?.current?.setIKensadetail(true);
    paAddtionalRef?.current?.setCustomDisplay(true);
  };

  const closeAll = () => {
    paProgramRef?.current?.setProgramDisplay(false);
    paBlankLaserRef?.current?.setLaserDisplay(false);
    paBlankPunchRef?.current?.setPunchDisplay(false);
    paBlankCombiRef?.current?.setCombiDisplay(false);
    paNijiRef?.current?.setNijiDisplay(false);
    paBendingRef?.current?.setBendingDisplay(false);
    paMenualBariRef?.current?.setMenualBari(false);
    paAutoBariRef?.current?.setAutoBariDisplay(false);
    paYosetsuRef?.current?.setYosetsuDisplay(false);
    paYosetsuFinishRef?.current?.setYosetsuFinishDisplay(false);
    paSurfaceRef?.current?.setSurfaceDisplay(false);
    paShirringRef?.current?.setShirringDisplay(false);
    paKensaRef?.current?.setIKensadetail(false);
    paAddtionalRef?.current?.setCustomDisplay(false);
  };

  return (
    <>
      <div className="overflow-auto kouteical KouteiCalculate" style={{ paddingTop: 5 }}>
        {/* IQX_WEBEST-371 板金子部品の「工程入力」と「工程積算」に一括開閉機能が実施できていない。 */}
        <Row className="mb-1" style={{ display: props?.selectedIQ3DataDetail?.additions?.length > 0 ? '' : 'none' }}>
          <Col style={{ paddingLeft: 2 }}>
            <Space size="small">
              <CaretUpOutlined
                style={{ color: 'white', backgroundColor: '#1063aa', fontSize: 18 }}
                onClick={closeAll}
              />

              <CaretDownFilled style={{ color: 'white', backgroundColor: '#1063aa', fontSize: 18 }} onClick={openAll} />
            </Space>
          </Col>
        </Row>
        <Row
          style={{ color: 'black', display: props?.selectedIQ3DataDetail?.additions?.length > 0 ? '' : 'none' }}
          className="oyabuhin oyabuhin-row "
        >
          <Col span={4} className="oyabuhin-col">
            <div style={{ display: 'flex' }}>
              <label style={{ marginLeft: 5, fontWeight: 700 }}>工程名</label>
            </div>
          </Col>
          <Col span={6} className="oyabuhin-col">
            <div>
              <b>工程積算費用</b>
            </div>
          </Col>
          <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
            <div>
              <label style={{ marginLeft: 5, fontWeight: 700 }}> 装置名</label>
            </div>
          </Col>
        </Row>
        {/* プログラム */}
        {isProgram ? (
          <IQ3KouteiCalculate_Program
            ref={paProgramRef}
            editMode={props?.editMode}
            updateIQ3KouteiCal={updateIQ3KouteiCal}
            selectedEstimateData={props?.selectedEstimateData}
            selectedIQ3DataDetail={props?.selectedIQ3DataDetail}
            activeTabKey={props?.activeTabKey}
            iq3ActionForCal={props?.iq3ActionForCal}
            isReCalIQ3={props?.isReCal}
            t
            activePartMenu={props?.activePartMenu}
          ></IQ3KouteiCalculate_Program>
        ) : (
          <></>
        )}
        {/* プログラム */}
        {/* プランク  */}
        {isLaser ? (
          <IQ3KouteiCalculate_BlankLaser
            ref={paBlankLaserRef}
            editMode={props?.editMode}
            updateIQ3KouteiCal={updateIQ3KouteiCal}
            selectedEstimateData={props?.selectedEstimateData}
            selectedIQ3DataDetail={props?.selectedIQ3DataDetail}
            activeTabKey={props?.activeTabKey}
            isReCalIQ3={props?.isReCal}
            activePartMenu={props?.activePartMenu}
            iq3ActionForCal={props?.iq3ActionForCal}
          ></IQ3KouteiCalculate_BlankLaser>
        ) : (
          ''
        )}
        {isPunch ? (
          <IQ3KouteiCalculate_BlankPunch
            ref={paBlankPunchRef}
            editMode={props?.editMode}
            updateIQ3KouteiCal={updateIQ3KouteiCal}
            selectedEstimateData={props?.selectedEstimateData}
            selectedIQ3DataDetail={props?.selectedIQ3DataDetail}
            activeTabKey={props?.activeTabKey}
            isReCalIQ3={props?.isReCal}
            activePartMenu={props?.activePartMenu}
            iq3ActionForCal={props?.iq3ActionForCal}
          ></IQ3KouteiCalculate_BlankPunch>
        ) : (
          ''
        )}
        {isCombi ? (
          <IQ3KouteiCalculate_BlankCombi
            ref={paBlankCombiRef}
            editMode={props?.editMode}
            updateIQ3KouteiCal={updateIQ3KouteiCal}
            selectedEstimateData={props?.selectedEstimateData}
            selectedIQ3DataDetail={props?.selectedIQ3DataDetail}
            activeTabKey={props?.activeTabKey}
            isReCalIQ3={props.isReCal}
            activePartMenu={props?.activePartMenu}
            iq3ActionForCal={props?.iq3ActionForCal}
          ></IQ3KouteiCalculate_BlankCombi>
        ) : (
          ''
        )}
        {/* プランク  */}
        {/* 2次加工  */}
        {isSecondary ? (
          <IQ3KouteiCalculate_2jikakou
            ref={paNijiRef}
            editMode={props?.editMode}
            updateIQ3KouteiCal={updateIQ3KouteiCal}
            selectedEstimateData={props?.selectedEstimateData}
            selectedIQ3DataDetail={props?.selectedIQ3DataDetail}
            activeTabKey={props?.activeTabKey}
            isReCalIQ3={props?.isReCal}
            activePartMenu={props?.activePartMenu}
            iq3ActionForCal={props?.iq3ActionForCal}
          ></IQ3KouteiCalculate_2jikakou>
        ) : (
          ''
        )}
        {/* 2次加工  */}
        {/* ベンディング  */}
        {isBending ? (
          <IQ3KouteiCalculate_Bending
            ref={paBendingRef}
            editMode={props?.editMode}
            updateIQ3KouteiCal={updateIQ3KouteiCal}
            selectedEstimateData={props?.selectedEstimateData}
            selectedIQ3DataDetail={props?.selectedIQ3DataDetail}
            activeTabKey={props?.activeTabKey}
            isReCalIQ3={props?.isReCal}
            activePartMenu={props?.activePartMenu}
            iq3ActionForCal={props?.iq3ActionForCal}
          ></IQ3KouteiCalculate_Bending>
        ) : (
          ''
        )}
        {/* ベンディング   */}
        {/* 手動バリ  */}
        {isManualDeburring ? (
          <IQ3KouteiCalculate_MenualBari
            ref={paMenualBariRef}
            editMode={props?.editMode}
            updateIQ3KouteiCal={updateIQ3KouteiCal}
            selectedEstimateData={props?.selectedEstimateData}
            selectedIQ3DataDetail={props?.selectedIQ3DataDetail}
            activeTabKey={props?.activeTabKey}
            isReCalIQ3={props?.isReCal}
            activePartMenu={props?.activePartMenu}
            iq3ActionForCal={props?.iq3ActionForCal}
          ></IQ3KouteiCalculate_MenualBari>
        ) : (
          ''
        )}
        {/* 手動バリ 
        {/* 自動バリ  */}
        {isAutoDeburring ? (
          <IQ3KouteiCalculate_AutoBari
            ref={paAutoBariRef}
            editMode={props?.editMode}
            updateIQ3KouteiCal={updateIQ3KouteiCal}
            selectedEstimateData={props?.selectedEstimateData}
            selectedIQ3DataDetail={props?.selectedIQ3DataDetail}
            activeTabKey={props?.activeTabKey}
            isReCalIQ3={props?.isReCal}
            activePartMenu={props?.activePartMenu}
            iq3ActionForCal={props?.iq3ActionForCal}
          ></IQ3KouteiCalculate_AutoBari>
        ) : (
          ''
        )}
        {/* 自動バリ  */}
        {/* 溶接  */}
        {isWelding ? (
          <IQ3KouteiCalculate_Yosetsu
            ref={paYosetsuRef}
            editMode={props?.editMode}
            updateIQ3KouteiCal={updateIQ3KouteiCal}
            selectedEstimateData={props?.selectedEstimateData}
            selectedIQ3DataDetail={props?.selectedIQ3DataDetail}
            activeTabKey={props?.activeTabKey}
            isReCalIQ3={props?.isReCal}
            activePartMenu={props?.activePartMenu}
            iq3ActionForCal={props?.iq3ActionForCal}
          ></IQ3KouteiCalculate_Yosetsu>
        ) : (
          ''
        )}
        {/* 溶接仕上 */}
        {isWeldingFinish ? (
          <IQ3KouteiCalculate_YosetsuFinish
            ref={paYosetsuFinishRef}
            editMode={props?.editMode}
            updateIQ3KouteiCal={updateIQ3KouteiCal}
            selectedEstimateData={props?.selectedEstimateData}
            selectedIQ3DataDetail={props?.selectedIQ3DataDetail}
            activeTabKey={props?.activeTabKey}
            isReCalIQ3={props?.isReCal}
            activePartMenu={props?.activePartMenu}
            iq3ActionForCal={props?.iq3ActionForCal}
          ></IQ3KouteiCalculate_YosetsuFinish>
        ) : (
          ''
        )}

        {/* 表面処理  */}
        {isSurface ? (
          <IQ3KouteiCalculate_Hyomen
            ref={paSurfaceRef}
            editMode={props?.editMode}
            updateIQ3KouteiCal={updateIQ3KouteiCal}
            selectedEstimateData={props?.selectedEstimateData}
            selectedIQ3DataDetail={props?.selectedIQ3DataDetail}
            activeTabKey={props?.activeTabKey}
            isReCalIQ3={props?.isReCal}
            activePartMenu={props?.activePartMenu}
            iq3ActionForCal={props?.iq3ActionForCal}
          ></IQ3KouteiCalculate_Hyomen>
        ) : (
          <></>
        )}
        {/* シャーリング  */}
        {isShirring ? (
          <IQ3KouteiCalculate_Shirring
            ref={paShirringRef}
            editMode={props?.editMode}
            updateIQ3KouteiCal={updateIQ3KouteiCal}
            selectedEstimateData={props?.selectedEstimateData}
            selectedIQ3DataDetail={props?.selectedIQ3DataDetail}
            activeTabKey={props?.activeTabKey}
            isReCalIQ3={props?.isReCal}
            activePartMenu={props?.activePartMenu}
            iq3ActionForCal={props?.iq3ActionForCal}
          ></IQ3KouteiCalculate_Shirring>
        ) : (
          ''
        )}
        {/* 検査  */}
        {isKensa ? (
          <IQ3KouteiCalculate_Kensa
            ref={paKensaRef}
            editMode={props?.editMode}
            updateIQ3KouteiCal={updateIQ3KouteiCal}
            selectedEstimateData={props?.selectedEstimateData}
            selectedIQ3DataDetail={props?.selectedIQ3DataDetail}
            activeTabKey={props?.activeTabKey}
            isReCalIQ3={props?.isReCal}
            activePartMenu={props?.activePartMenu}
            iq3ActionForCal={props?.iq3ActionForCal}
          ></IQ3KouteiCalculate_Kensa>
        ) : (
          <></>
        )}
        {/* 追加項目  */}
        {isCustom ? (
          <IQ3KouteiCalculate_AddtionalProcess
            ref={paAddtionalRef}
            editMode={props?.editMode}
            updateIQ3KouteiCal={updateIQ3KouteiCal}
            selectedEstimateData={props?.selectedEstimateData}
            selectedIQ3DataDetail={props?.selectedIQ3DataDetail}
            activeTabKey={props?.activeTabKey}
            isReCalIQ3={props?.isReCal}
            activePartMenu={props?.activePartMenu}
            iq3ActionForCal={props?.iq3ActionForCal}
          ></IQ3KouteiCalculate_AddtionalProcess>
        ) : (
          <></>
        )}
      </div>
    </>
  );
});

export default KouteiCalculate;
