/**
 * クラス名：iq3の装置設定の製品重量係数画面
 * 作成者：チュー
 * 作成日：2023/06/21
 * バージョン：1.0
 */

import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { Row, Col, Input, Button, Space, Popover } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Table } from 'react-bootstrap';
import { isEmpty } from '@rc-component/mini-decimal/es/numberUtil';

import { commonModal } from '../../../common/CommonModal';
import {
  deleteListColData,
  getInputBorderStyle,
  handleKeyPress,
  isDigit,
  isDigitZenkaku,
  regexExp,
  sortListData,
  toHalfWidth,
  updateListColData,
} from '../../../common/Common';

const Device_ProductSize = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);
  const [isArea, setIsArea] = useState(false);
  const [isWeight, setIsWeight] = useState(false);

  const [weightData, setWeightData] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [detailsAreaTemp, setDetailsAreaTemp] = useState([]);
  const [detailsWeightTemp, setDetailsWeightTemp] = useState([]);
  const [detailsList, setDetailsList] = useState([]);

  // マテリアルハンドリング時間
  const [materialHandlingTimeList, setMaterialHandlingTimeList] = useState([]);
  // 段取設定の曲げ係数
  const [correctionTimeFactorList, setcorrectionTimeFactorList] = useState([]);

  // 補正時間係数
  const [bendingTimeFactorLst, setBendingTimeFactorLst] = useState([]);
  // 入力チェック
  const [errIndexVal, setErrIndexVal] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const inputRef = useRef([]);
  useEffect(() => {
    setErrIndexVal([]);
    setErrorMessage('');
    setEditMode(props.editMode);
    if (props?.detailsData?.prodSizeWeightThMaster) {
      setWeightData(props?.detailsData?.prodSizeWeightThMaster);
      setDetailsWeightTemp(props?.detailsData?.prodSizeWeightThMaster);
    }
    if (props?.detailsData?.prodSizeAreaThMaster) {
      setAreaData(props?.detailsData?.prodSizeAreaThMaster);
      setDetailsAreaTemp(props?.detailsData?.prodSizeAreaThMaster);
    }
    if (props?.detailsData?.prodSizeItems) {
      setDetailsList(props?.detailsData?.prodSizeItems);
    }
    setBendingTimeFactorLst(props.bendingTimeFactorLst);
    // マテリアルハンドリング時間
    setMaterialHandlingTimeList(props.materialHandlingTimeList);
    // 段取設定の曲げ係数
    setcorrectionTimeFactorList(props.correctionTimeFactorListData);
  }, [props.weight, props.area, props.editMode, props.iq3SeihinSizeLst, props.bendingTimeFactorLst, props.detailsData]);

  const addArea = () => {
    setErrIndexVal([]);
    setErrorMessage('');
    setIsArea(true);
  };
  const addWeight = () => {
    setErrIndexVal([]);
    setErrorMessage('');
    setIsWeight(true);
  };
  const addAreaModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>面積</p>
    </div>
  );
  const addWeightModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>重量</p>
    </div>
  );

  const addData = () => {
    setIsUpdateData(true);
  };

  // 面積マスタの行追加を押下
  const addAreaRow = (no, index) => {
    inputRef.current[index].focus();
    const copyData = detailsAreaTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsAreaTemp?.findIndex((item) => item.no === no); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: detailsAreaTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsAreaTemp(copyData);
    }
  };

  // 面積マスタの行削除を押下
  const deleteArea = (no, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);
    if (detailsAreaTemp?.filter((item) => !item.isOutOfRange === true)?.length > 2) {
      const updatedData = detailsAreaTemp?.map((row) => {
        if (row.no === no) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setDetailsAreaTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };

  // 重量マスタの行削除ボタン押下
  const deleteWeight = (no, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);
    if (detailsWeightTemp?.filter((item) => !item.isOutOfRange)?.length > 2) {
      const updatedData = detailsWeightTemp?.map((row) => {
        if (row.no === no) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setDetailsWeightTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };

  // 重量マスタの行追加ボタン押下
  const addWeightRow = (no, index) => {
    inputRef.current[index].focus();
    const copyData = detailsWeightTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsWeightTemp?.findIndex((item) => item.no === no); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: detailsWeightTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsWeightTemp(copyData);
    }
  };

  const closeAddModal = () => {
    if (isArea === true) {
      setIsArea(false);
    } else if (isWeight === true) {
      setIsWeight(false);
    }
  };

  // 面積マスタの値を変更した場合、
  const handleAreaChange = (event, no, field) => {
    if (regexExp.test(event.target.value) && !isDigitZenkaku(event.target.value)) return;
    const updatedData = detailsAreaTemp?.map((row) => {
      if (row.no === no) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsAreaTemp(updatedData);
  };

  // 重量マスタ値を変更した場合、
  const handleWeightChange = (event, no, field) => {
    if (regexExp.test(event.target.value) && !isDigitZenkaku(event.target.value)) return;
    const updatedData = detailsWeightTemp?.map((row) => {
      if (row.no === no) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsWeightTemp(updatedData);
  };

  const cancelAreaMaster = () => {
    setDetailsAreaTemp(areaData);
    setIsArea(false);
  };

  // 面積マスタのOKボタン押下、
  const okAreaMaster = () => {
    const deletedId = [];

    areaData?.forEach((item1, index) => {
      const found = detailsAreaTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = detailsAreaTemp
      ?.filter((item) => !item.isOutOfRange && !areaData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    const deleteData = detailsList?.map((item) => {
      return {
        ...item,
        areaCoeffList: deleteListColData(item.areaCoeffList, deletedId),
      };
    });

    const updatedData = deleteData?.map((item) => {
      newId?.forEach((key) => {
        // Add new objects to lstData
        item.areaCoeffList.push({
          no: key,
          value: 1,
          isOutOfRange: false,
        });
      });
      return item;
    });

    const sortedList = updatedData?.map((item) => {
      const sortLstData = [...item.areaCoeffList];
      const sortedLstData = sortListData(sortLstData, detailsAreaTemp);
      return {
        ...item,
        areaCoeffList: sortedLstData,
      };
    });

    setIsArea(false);
    setDetailsAreaTemp(detailsAreaTemp);
    props.updatedArea(detailsAreaTemp);

    setDetailsList(sortedList);
    props.updatediq3ProductSizesList(sortedList);
  };

  const cancelWeightMaster = () => {
    setDetailsWeightTemp(weightData);
    setIsWeight(false);
  };

  // 重量マスタのOKボタン押下
  const okWeightMaster = () => {
    const deletedId = [];

    weightData?.forEach((item1, index) => {
      const found = detailsWeightTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = detailsWeightTemp
      ?.filter((item) => !item.isOutOfRange && !weightData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    const customItem = { weightId: 0, areaCoeffList: getListItemByCustomData(areaData) };

    const deleteData = deleteListColData(detailsList, deletedId, false, 'weightId');
    const updatedData = updateListColData(deleteData, newId, false, { ...customItem }, 'weightId');
    const result = sortListData(updatedData, detailsWeightTemp, false, 'weightId');

    setIsWeight(false);
    setWeightData(detailsWeightTemp);

    setDetailsList(result);
    props.updatedWeight(detailsWeightTemp);

    props.updatediq3ProductSizesList(result);
    // 重量マスター更新により段取設定の曲げ係数一覧更新
    // setcorrectionTimeFactorList(result2);
    // props.updatedcorrectionTimeFactorListData(result2);
    // 重量マスター更新によりマテリアルハンドリング時間一覧更新
    // updateMaterialHandlingListByWeightMaster(deletedId, newId, detailsWeightTemp);
  };

  // 重量マスター更新によりマテリアルハンドリング時間一覧更新
  const updateMaterialHandlingListByWeightMaster = (deletedId, newId, detailsWeightTemp) => {
    const deleteData2 = materialHandlingTimeList?.filter((it) => !deletedId?.includes(it.key));
    const result2 = deleteData2?.map((item) => {
      const list = detailsWeightTemp?.find((listItem) => listItem.id === item.key);

      return {
        ...item,
        max: list ? list.max : item.max,
        lstData: item.lstData?.map((lstItem) => {
          const newListData = {
            key: lstItem.key,
            value: lstItem.value,
          };

          return newListData;
        }),
      };
    });
    newId?.forEach((id) => {
      const list = detailsWeightTemp?.find((item) => item.id === id);

      if (list) {
        const newObj2 = {
          key: id,
          max: list.max,
          isOutOfRange: true,
          lstData: result2[0].lstData?.map((lstItem) => {
            return {
              key: lstItem.key,
              value: '',
            };
          }),
        };

        result2.push(newObj2);
      }
    });

    result2.sort((a, b) => {
      const aIndex = detailsWeightTemp?.findIndex((obj) => obj.id === a.key);
      const bIndex = detailsWeightTemp?.findIndex((obj) => obj.id === b.key);
      return aIndex - bIndex;
    });

    setMaterialHandlingTimeList(result2);
    props.updateMaterialHandlingList(result2);
  };
  const isValueOrderCorrect = (e, no, field, tblName) => {
    let temp = [];
    if (tblName === 'weight') {
      temp = detailsWeightTemp?.filter((i) => i.isOutOfRange === false);
    } else {
      temp = detailsAreaTemp?.filter((i) => i.isOutOfRange === false);
    }

    const insertIndex = temp?.findIndex((item) => item.no === no);
    if (regexExp.test(temp[insertIndex].value) && !isDigitZenkaku(temp[insertIndex].value)) return;
    // IQX_WEBEST-220の対応-START
    if (regexExp.test(temp[insertIndex].value)) {
      // 全角の場合、
      temp[insertIndex].value = toHalfWidth(temp[insertIndex].value); // 半角へ変換
      e.target.value = temp[insertIndex].value;
      tblName === 'weight' ? handleWeightChange(e, no, field) : handleAreaChange(e, no, field);
    }
    if (isNaN(parseFloat(temp[insertIndex].value))) {
      setErrIndexVal([...errIndexVal, insertIndex]);
      setErrorMessage('数字値を入力してください！');
      return;
    }
    // IQX_WEBEST-220の対応-END

    if (insertIndex > 0) {
      const prevValue = parseFloat(temp[insertIndex - 1].value);
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);
      if (currentValue > prevValue && currentValue < nextValue) {
        let temp = [insertIndex - 1, insertIndex, insertIndex + 1];

        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex && item != insertIndex - 1));

        setErrorMessage('前のしきい値より大きい値を入力してください！');
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);

        if (currentValue <= prevValue) {
          setErrorMessage('前のしきい値より大きい値を入力してください！');
        } else if (currentValue >= nextValue) {
          setErrorMessage('次のしきい値より小さい値を入力してください！');
        }
      }
    } else {
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);

      if (currentValue < nextValue) {
        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex));
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);
        setErrorMessage('次のしきい値より小さい値を入力してください！');
      }
    }
  };
  // サイズ係数のcm²ボタンを押下
  const addAreaContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsAreaTemp
                  ?.filter((subitem) => !subitem.isOutOfRange)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleAreaChange(e, item.no, 'value')}
                          onBlur={(e) => isValueOrderCorrect(e, item.no, 'value', 'area')}
                          onPressEnter={(e) => isValueOrderCorrect(e, item.no, 'value', 'area')}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, detailsAreaTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addAreaRow(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteArea(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'} id="update" onClick={okAreaMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelAreaMaster}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  // サイズ係数のkgボタンを押下
  const addWeightContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsWeightTemp
                  ?.filter((subitem) => !subitem.isOutOfRange)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleWeightChange(e, item.no, 'value')}
                          onBlur={(e) => isValueOrderCorrect(e, item.no, 'value', 'weight')}
                          onPressEnter={(e) => isValueOrderCorrect(e, item.no, 'value', 'weight')}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, detailsWeightTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addWeightRow(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteWeight(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'} id="update" onClick={okWeightMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelWeightMaster}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  // サイズ係数のリスト値を変更した場合、
  const changeSizeListData = (value, wId, no) => {
    if (regexExp.test(value) && !isDigitZenkaku(value)) return;
    const copyData = [...detailsList];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    // updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    updatedData?.map((list) => {
      if (list?.weightId === wId) {
        list?.areaCoeffList?.map((data) => {
          if (data.no === no) {
            data.value = value;
          }
        });
      }
    });
    setDetailsList(updatedData);
    props.updatediq3ProductSizesList(updatedData);
  };
  // サイズ係数のリスト値を'0'に変更した場合、
  const changeSizeListDataOnBlur = (value, wId, no) => {
    if (regexExp.test(value) && !isDigitZenkaku(value)) return;
    const copyData = [...detailsList];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData?.map((list) => {
      if (list?.weightId === wId) {
        list?.areaCoeffList?.map((data) => {
          if (data.no === no) {
            value = regexExp.test(value) ? toHalfWidth(value) : value;
            data.value = parseFloat(value) > 0 ? value : 1;
          }
        });
      }
    });
    setDetailsList(updatedData);
    props.updatediq3ProductSizesList(updatedData);
  };

  // 指定データより、リスト情報を作成
  const getListItemByCustomData = (data) => {
    const list = data?.map((item) => ({ no: item.no, value: 1, isOutOfRange: false }));
    return list;
  };

  return (
    <>
      <div className="table-container" style={{ marginLeft: 10, marginRight: 10 }}>
        <Table
          style={{
            width:
              areaData?.filter((i) => !i.isOutOfRange)?.length > 8
                ? '100%'
                : areaData?.filter((i) => !i.isOutOfRange)?.length * 98 + 196 + 'px',
          }}
        >
          <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
            <td style={{ width: '196px' }} className="fixedCol">
              <Row justify="center" className="tbl-header">
                <Space>
                  {editMode ? (
                    <>
                      <Button className="cancleButton" onClick={addWeight} style={{ pointerEvents: 'auto' }}>
                        kg
                      </Button>
                      /
                      <Button className="cancleButton" onClick={addArea} style={{ pointerEvents: 'auto' }}>
                        cm<sup>2</sup>
                      </Button>
                    </>
                  ) : (
                    <>
                      <label>
                        kg / cm<sup>2</sup>
                      </label>
                    </>
                  )}
                </Space>
              </Row>
            </td>

            {areaData
              ?.filter((subitem) => !subitem.isOutOfRange)
              ?.map((item, index) => (
                <td key={index} style={{ backgroundColor: '#1063aa', width: '98px' }}>
                  ～{item.value}cm<sup className="tbl-header">2</sup> {/**IQX_WEBEST-269*/}
                </td>
              ))}
          </tr>

          {detailsList?.map((row, index) => (
            <tr key={index} style={{ textAlign: 'center' }}>
              <td style={{ width: '196px' }} className="tbl-header fixedCol">
                {/* ～{row.max} */}
                {weightData?.filter((wItem) => wItem.no === row?.weightId)?.[0]?.value}kg {/**IQX_WEBEST-269*/}
              </td>

              {row?.areaCoeffList?.map((col, index) => (
                <td key={index} style={{ width: '98px' }}>
                  <Input
                    value={col.value}
                    onChange={(e) => changeSizeListData(e.target.value, row?.weightId, col.no)}
                    onBlur={(e) => changeSizeListDataOnBlur(e.target.value, row?.weightId, col.no)}
                    onPressEnter={(e) => changeSizeListDataOnBlur(e.target.value, row?.weightId, col.no)}
                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                    onKeyPress={(e) => {
                      handleKeyPress(e, false);
                    }}
                  />
                </td>
              ))}
            </tr>
          ))}
        </Table>
      </div>
      {/* 面積 */}
      {isArea
        ? commonModal(
            isArea,
            addAreaModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addAreaContent,
            null,
            null,
            true
          )
        : ''}
      {/* 重量 */}
      {isWeight
        ? commonModal(
            isWeight,
            addWeightModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addWeightContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default Device_ProductSize;
