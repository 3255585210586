/**
 * クラス名：板金のブランク加工速度画面
 * 作成者：チュー
 * 作成日：2023/06/21
 * バージョン：1.0
 */
import React, { useEffect, forwardRef, useState, useRef } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Col, Input, Space, Button, Radio, Popover } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { isEmpty } from '@rc-component/mini-decimal/es/numberUtil';
import { commonModal } from '../../../common/CommonModal';
import {
  getInputBorderStyle,
  handleKeyPress,
  isDigit,
  isDigitZenkaku,
  regexExp,
  sortListByMasterData,
  toHalfWidth,
} from '../../../common/Common';
const Device_Blank_Kakousokudo = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);

  const [isItaatsu, setIsItaatsu] = useState(false);

  const [mode, setMode] = useState('simple');

  const [itaatsu, setItaatsu] = useState([]);
  const [itaatsuTemp, setItaatsuTemp] = useState([]);

  const [btnEnable, setBtnEnable] = useState(true);

  const [listData, setListData] = useState([]);
  const [materialType, setMaterialType] = useState([]);

  // 送り速度
  const [okuriSokudoVal, setOkuriSokudoVal] = useState();
  // ケガキ速度
  const [kegakiSokudoVal, setKegakiSokudoVal] = useState();

  const [filterKeys, setFilterKeys] = useState([]);

  const [errIndexVal, setErrIndexVal] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const [isUsedEasyWorkSpeed, setIsUsedEasyWorkSpeed] = useState(false);

  const inputRef = useRef([]);

  useEffect(() => {
    setErrIndexVal([]);
    setErrorMessage('');
    setEditMode(props.editMode);
    if (props?.detailsData?.workSpeedThickThMaster) {
      setItaatsu(props?.detailsData?.workSpeedThickThMaster);
      setItaatsuTemp(props?.detailsData?.workSpeedThickThMaster);
    }
    setListData(props?.detailsData?.workSpeedItems); // IQX_WEBEST-296 入力値が保持されるように修正
    setMaterialType(props.materialTypes);
    const usedItems = props.materialTypes?.filter((item) => item.info.isUsed);

    // Extract the id values from the filtered items
    const usedIds = usedItems?.map((item) => item.id);

    setFilterKeys(usedIds);
    setOkuriSokudoVal(props?.detailsData?.feedSpeed);
    setKegakiSokudoVal(props?.detailsData?.scribingSpeed);

    // 加工速度の簡易入力・詳細入力の初期表示チェック
    setIsUsedEasyWorkSpeed(props?.detailsData?.isUsedEasyWorkSpeed);
    if (props?.detailsData?.isUsedEasyWorkSpeed) {
      setMode('simple');
    } else {
      setMode('details');
    }
    // if (!props.editMode) {
    // isDetailDisplay(props?.detailsData?.workSpeedItems);
    // }
  }, [props.editMode, props.processName, props.detailsData, props.materialTypes]);

  // IQX_WEBEST-296 簡易入力と詳細入力を切り替える際には値を引き継げるようにしてほしい
  // useEffect(() => {
  //   let detailDisplay = false;
  //   let workSpeedItems = props?.detailsData?.workSpeedItems;
  //   if (workSpeedItems) {
  //     Object.keys(workSpeedItems)
  //       ?.filter((listkey) => filterKeys?.includes(parseInt(listkey)))
  //       ?.map((key) => {
  //         workSpeedItems[key]?.map((item, index) => {
  //           let sameVal = item?.lenThItems?.[0]?.value;
  //           item?.lenThItems?.map((lenItem, index) => {
  //             // if (document.getElementById(key + 'cls' + item.no + 'input' + index)) {
  //             //   document.getElementById(key + 'cls' + item.no + 'input' + index).style.backgroundColor =
  //             //     'rgb(201 132 132 / 0%)';
  //             // }
  //             if (Number(lenItem.value) !== Number(sameVal)) {
  //               detailDisplay = true;
  //               return;
  //             }
  //           });
  //           if (!detailDisplay) {
  //             // IQX_WEBEST-296 簡易入力と詳細入力を切り替える際には値を引き継げるようにしてほしい
  //             if (item?.lenThItems) {
  //               item?.lenThItems?.map((lenItem, index) => {
  //                 lenItem.value = item?.value;
  //               });
  //             }
  //           }
  //         });
  //       });
  //   }
  //   setListData(workSpeedItems); // IQX_WEBEST-296 入力値が保持されるように修正
  // }, [props.editMode]);
  // const isDetailDisplay = (workSpeedItems) => {
  //   let isDetail = false;
  //   if (workSpeedItems) {
  //     Object.keys(workSpeedItems)
  //       ?.filter((listkey) => filterKeys?.includes(parseInt(listkey)))
  //       ?.map((key) => {
  //         workSpeedItems[key]?.map((item, index) => {
  //           let sameVal = item?.lenThItems?.[0]?.value;
  //           item?.lenThItems?.map((lenItem, index) => {
  //             if (document.getElementById(key + 'cls' + item.no + 'input' + index)) {
  //               document.getElementById(key + 'cls' + item.no + 'input' + index).style.backgroundColor =
  //                 'rgb(201 132 132 / 0%)';
  //             }
  //             if (Number(lenItem.value) !== Number(sameVal)) {
  //               isDetail = true;
  //               return;
  //             }
  //           });
  //         });
  //       });
  //   }
  //   if (isDetail) {
  //     setMode('details');
  //     setIsUsedEasyWorkSpeed(false);
  //   } else {
  //     setMode('simple');
  //     setIsUsedEasyWorkSpeed(true);
  //   }
  // };

  const size = () => {
    setErrIndexVal([]);
    setErrorMessage('');
    setIsItaatsu(true);
  };

  const addThicknessModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>板厚</p>
    </div>
  );
  const closeAddModal = () => {
    if (isItaatsu === true) {
      setIsItaatsu(false);
    }
  };

  const cancelMasterData = () => {
    if (isItaatsu === true) {
      setIsItaatsu(false);
      setItaatsuTemp(itaatsu);
    }
  };
  const addData = () => {};
  // 板厚マスタ値更新
  const handleItaatsuChange = (event, id, field) => {
    if (regexExp.test(event.target.value) && !isDigitZenkaku(event.target.value)) return;
    const updatedData = itaatsuTemp?.map((row) => {
      if (row.no === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setItaatsuTemp(updatedData);
  };
  // 板厚マスタ値の行を追加する
  const addItaatsu = (id, index) => {
    inputRef.current[index].focus();
    const copyData = itaatsuTemp.slice(); // create a copy of the original array

    const insertIndex = itaatsuTemp?.findIndex((item) => item.no === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: itaatsuTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setItaatsuTemp(copyData);
    }
  };
  // 板厚マスタ値の行を削除する
  const deleteItaatsu = (id, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);

    if (itaatsuTemp?.filter((item) => item.isOutOfRange === false)?.length > 2) {
      const updatedData = itaatsuTemp?.map((row) => {
        if (row.no === id) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setItaatsuTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };
  const addItaatsuMaster = () => {
    setBtnEnable(false);
    const deletedId = [];

    itaatsu?.forEach((item1, index) => {
      const found = itaatsuTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange === true);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = itaatsuTemp
      ?.filter((item) => !item.isOutOfRange && !itaatsu.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    const updatedList = {};
    let lists = [
      {
        no: 1,
        value: 0,
        isOutOfRange: false,
      },
      {
        no: 2,
        value: 0,
        isOutOfRange: false,
      },
      {
        no: 3,
        value: 0,
        isOutOfRange: false,
      },
    ];
    // Loop through the keys (ids) in the original list
    Object.keys(listData)?.forEach((id) => {
      // 削除
      const updatedItems = listData[id]?.filter((item) => !deletedId?.includes(item.no));

      // 追加
      newId?.forEach((no) => {
        updatedItems.push({
          no,
          value: 0,
          isOutOfRange: false,
          workLenThItems: lists,
          lenThItems: lists,
          roundHoleDiaThItems: lists,
        });
      });
      updatedList[id] = updatedItems;
    });

    const sortedList = sortListByMasterData(updatedList, itaatsuTemp);
    setIsItaatsu(false);
    setItaatsu(itaatsuTemp);
    props.updatedItaatsu(itaatsuTemp);
    setListData(sortedList);
    props.updatedBlankKakousokudolistData(sortedList);
  };
  const isValueOrderCorrect = (e, no, field) => {
    const temp = itaatsuTemp?.filter((i) => i.isOutOfRange === false);

    const insertIndex = temp?.findIndex((item) => item.no === no);
    if (regexExp.test(temp[insertIndex].value) && !isDigitZenkaku(temp[insertIndex].value)) return;
    // IQX_WEBEST-220の対応-START
    if (regexExp.test(temp[insertIndex].value)) {
      // 全角の場合、
      temp[insertIndex].value = toHalfWidth(temp[insertIndex].value); // 半角へ変換
      e.target.value = temp[insertIndex].value;
      handleItaatsuChange(e, no, field);
    }
    if (isNaN(parseFloat(temp[insertIndex].value))) {
      setErrIndexVal([...errIndexVal, insertIndex]);
      setErrorMessage('数字値を入力してください！');
      return;
    }
    // IQX_WEBEST-220の対応-END

    if (insertIndex > 0) {
      const prevValue = parseFloat(temp[insertIndex - 1].value);
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);
      if (currentValue > prevValue && currentValue < nextValue) {
        let temp = [insertIndex - 1, insertIndex, insertIndex + 1];

        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex && item != insertIndex - 1));

        setErrorMessage('前のしきい値より大きい値を入力してください！');
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);

        if (currentValue <= prevValue) {
          setErrorMessage('前のしきい値より大きい値を入力してください！');
        } else if (currentValue >= nextValue) {
          setErrorMessage('次のしきい値より小さい値を入力してください！');
        }
      }
    } else {
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);

      if (currentValue < nextValue) {
        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex));
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);
        setErrorMessage('次のしきい値より小さい値を入力してください！');
      }
    }
  };
  const addItaatsuContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {itaatsuTemp
                  ?.filter((subitem) => subitem.isOutOfRange === false)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleItaatsuChange(e, item.no, 'value')}
                          onBlur={(e) => isValueOrderCorrect(e, item.no, 'value')}
                          onPressEnter={(e) => isValueOrderCorrect(e, item.no, 'value')}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, itaatsuTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addItaatsu(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteItaatsu(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={addItaatsuMaster}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} id="discard" onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  const handleModeChange = (e, listData, isUsedEasyWorkSpeed) => {
    // 簡易入力と詳細入力を連動しないため、コメントアウトする。
    // IQX_WEBEST-265
    // let detailDisplay = false;
    // let isDetail = false;
    // if (listData) {
    //   Object.keys(listData)
    //     ?.filter((listkey) => filterKeys?.includes(parseInt(listkey)))
    //     ?.map((key) => {
    //       listData[key]?.map((item, index) => {
    //         let sameVal = item?.lenThItems?.[0]?.value;
    //         item?.lenThItems?.map((lenItem, index) => {
    //           if (document.getElementById(key + 'cls' + item.no + 'input' + index)) {
    //             document.getElementById(key + 'cls' + item.no + 'input' + index).style.backgroundColor =
    //               'rgb(201 132 132 / 0%)';
    //           }
    //           if (Number(lenItem.value) !== Number(sameVal)) {
    //             detailDisplay = true;
    //             isDetail = true;
    //             return;
    //           }
    //         });
    //         if (detailDisplay) {
    //           // IQX_WEBEST-297 詳細表示で簡易表示をクリックした時だけ赤く表示してほしい
    //           detailDisplay = false;
    //           if (item?.lenThItems) {
    //             item?.lenThItems?.map((lenItem, index) => {
    //               if (document.getElementById(key + 'cls' + item.no + 'input' + index)) {
    //                 document.getElementById(key + 'cls' + item.no + 'input' + index).style.backgroundColor =
    //                   'rgb(201 132 132 / 36%)';
    //               }
    //             });
    //           }
    //         } else {
    //           // IQX_WEBEST-296 簡易入力と詳細入力を切り替える際には値を引き継げるようにしてほしい
    //           if (item?.lenThItems && (e === 'details' || isUsedEasyWorkSpeed === false)) {
    //             item?.lenThItems?.map((lenItem, index) => {
    //               lenItem.value = item?.value;
    //             });
    //           } else if (item?.lenThItems && props?.editMode && (e === 'simple' || isUsedEasyWorkSpeed)) {
    //             item.value = item?.lenThItems?.[0]?.value;
    //           }
    //         }
    //       });
    //     });
    // }
    if (e != '') {
      setMode(e);
      if (e != '' && e === 'simple') {
        setIsUsedEasyWorkSpeed(true);
        props.updateIsUsedEasyWorkSpeedVal(true);
      } else {
        setIsUsedEasyWorkSpeed(false);
        props.updateIsUsedEasyWorkSpeedVal(false);
      }
    } else {
      setIsUsedEasyWorkSpeed(isUsedEasyWorkSpeed);
      if (isUsedEasyWorkSpeed) {
        setMode('simple');
      } else {
        setMode('details');
      }
      props.updateIsUsedEasyWorkSpeedVal(isUsedEasyWorkSpeed);
    }
  };

  const listDataChange = (event, key, no, isCheckZenkaku = true) => {
    if (regexExp.test(event) && !isDigitZenkaku(event)) return;
    if (isCheckZenkaku && regexExp.test(event)) {
      // 全角の場合、
      event = toHalfWidth(event); // 半角へ変換
      if (!isDigit(event)) return;
    }
    const newList = { ...listData };
    const itemIndex = newList[key]?.findIndex((item) => item.no === no);

    if (itemIndex !== -1) {
      newList[key][itemIndex].value = event;
      setListData(newList);
      props.updatedBlankKakousokudolistData(newList);
    }
  };
  const measureTypeListDataChange = (event, key, no, index, measureType, isCheckZenkaku = true) => {
    if (regexExp.test(event) && !isDigitZenkaku(event)) return;
    if (isCheckZenkaku && regexExp.test(event)) {
      // 全角の場合、
      event = toHalfWidth(event); // 半角へ変換
      if (!isDigit(event)) return;
    }
    setBtnEnable(false);
    const newList = { ...listData };
    const itemIndex = newList[key]?.findIndex((item) => item.no === no);

    if (itemIndex !== -1) {
      if (measureType === 'RoundHoleDia') {
        newList[key][itemIndex].roundHoleDiaThItems[index].value = event;
      } else if (measureType === 'Worklen') {
        newList[key][itemIndex].workLenThItems[index].value = event;
      } else if (measureType === 'SpeedOutLen') {
        newList[key][itemIndex].speedOutLen = event;
      } else {
        newList[key][itemIndex].lenThItems[index].value = event;
      }

      setListData(newList);
      props.updatedBlankKakousokudolistData(newList);
    }
  };

  const kegakiSokudoValChange = (e, isCheckZenkaku = true) => {
    if (regexExp.test(e.target.value) && !isDigitZenkaku(e.target.value)) return;
    if (isCheckZenkaku && regexExp.test(e.target.value)) {
      // 全角の場合、
      e.target.value = toHalfWidth(e.target.value); // 半角へ変換
      if (!isDigit(e.target.value)) return;
    }
    setKegakiSokudoVal(e.target.value);
    props.updateKegakiSokudoVal(e.target.value);
  };

  const okuriSokudoValChange = (e, isCheckZenkaku = true) => {
    if (regexExp.test(e.target.value) && !isDigitZenkaku(e.target.value)) return;
    if (isCheckZenkaku && regexExp.test(e.target.value)) {
      // 全角の場合、
      e.target.value = toHalfWidth(e.target.value); // 半角へ変換
      if (!isDigit(e.target.value)) return;
    }
    setOkuriSokudoVal(e.target.value);
    props.updateOkuriSokudoVal(e.target.value);
  };

  return (
    <>
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        <Row>
          <Col span={5}>
            <Radio.Group
              onChange={(e) => {
                // IQX_WEBEST-295「編集」状態に入っていない場合（表示のみ）：ラジオボタンの選択変更ができないように修正。
                if (props?.editMode) {
                  handleModeChange(e.target.value, listData, isUsedEasyWorkSpeed);
                }
              }}
              value={mode}
              style={{ marginBottom: 8 }}
            >
              <Radio.Button value="simple">簡易入力</Radio.Button>
              <Radio.Button value="details">詳細入力</Radio.Button>
            </Radio.Group>
          </Col>
          <Col span={15} style={{ marginTop: 5 }}>
            <Radio.Group
              name="isUsedEasyWorkSpeed"
              defaultValue={true}
              className="radioCustomerGpEdit"
              onChange={(e) => {
                // IQX_WEBEST-295「編集」状態に入っていない場合（表示のみ）：ラジオボタンの選択変更ができないように修正。
                if (props?.editMode) {
                  setIsUsedEasyWorkSpeed(e.target.value);
                  handleModeChange('', listData, e.target.value);
                }
              }}
              value={isUsedEasyWorkSpeed}
            >
              <Radio value={true}>簡易入力値で計算</Radio>
              <Radio value={false}>詳細入力値で計算</Radio>
            </Radio.Group>
          </Col>
        </Row>
        <Row className="qty-table-container">
          <Table
            style={{
              width:
                itaatsu?.filter((i) => i.isOutOfRange === false)?.length > 8
                  ? '100%'
                  : itaatsu?.filter((i) => i.isOutOfRange === false)?.length * 98 + 196 + 'px',
            }}
          >
            <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
              <td style={{ width: '260px' }} className="fixedCol" colSpan={2}>
                <Row justify="center" className="tbl-header">
                  <Space>
                    <label>材質区分</label> <label>/</label>
                    {editMode ? (
                      <>
                        <Button className="cancleButton" onClick={size} style={{ pointerEvents: 'auto' }}>
                          板厚
                        </Button>
                      </>
                    ) : (
                      <>
                        <label>板厚</label>
                      </>
                    )}
                  </Space>
                </Row>
              </td>

              {itaatsu
                ?.filter((subitem) => subitem.isOutOfRange === false)
                ?.map((item, index) => (
                  <td
                    key={index}
                    style={{
                      backgroundColor: '#1063aa',
                      width: mode === 'simple' ? '98px' : '300px',
                    }}
                  >
                    ～{item.value}t
                  </td>
                ))}
            </tr>

            {Object.keys(listData)
              ?.filter((listkey) => filterKeys?.includes(parseInt(listkey)))
              ?.map((key) => (
                <tr key={key} style={{ textAlign: 'center' }}>
                  {mode === 'simple' ? (
                    <>
                      <td className="tbl-header fixedCol2" colSpan={2}>
                        {materialType?.find((item) => item.id.toString() === key)?.name}
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="tbl-header fixedCol2">
                        {materialType?.find((item) => item.id.toString() === key)?.name}
                      </td>
                      <td className="fixedCol3" style={{ backgroundColor: '#d3d2d2' }}>
                        <Row style={{ padding: 0, backgroundColor: '#d3d2d2' }}>
                          {/* 丸穴径(Φ) (≦) */}
                          <Table style={{ marginBottom: 0 }} className="table-no-border-left">
                            <tr style={{ height: 31 }}>
                              <td className="removerightborder removeleftborder removeBottomborder">
                                外加工速度(mm/分)
                              </td>
                            </tr>
                            <tr style={{ height: 31 }}>
                              <td className="removerightborder removeleftborder">丸穴径(Φ) (≦)</td>
                            </tr>
                            <tr style={{ height: 31 }}>
                              <td className="removerightborder removeleftborder">加工周長(mm)(≦)</td>
                            </tr>
                            <tr style={{ height: 31 }}>
                              <td className="removerightborder removeleftborder removeBottomborder">速度(mm/分)</td>
                            </tr>
                          </Table>
                        </Row>
                      </td>
                    </>
                  )}

                  {listData[key]?.map((item, index) => (
                    <>
                      {mode === 'simple' ? (
                        <>
                          <td key={index} style={{ verticalAlign: 'middle', textAlign: 'center', width: 210 }}>
                            <Input
                              value={item.value}
                              onChange={(e) => listDataChange(e.target.value, key, item.no, false)}
                              onBlur={(e) => listDataChange(e.target.value, key, item.no)}
                              onPressEnter={(e) => listDataChange(e.target.value, key, item.no)}
                              onKeyPress={(e) => {
                                handleKeyPress(e, false);
                              }}
                              style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                              className={editMode ? 'input-editable ' : 'input-non-editable'}
                            />
                          </td>
                        </>
                      ) : (
                        <>
                          <td key={index}>
                            <Row style={{ padding: 0 }}>
                              <Table style={{ marginBottom: 0 }} className="table table-no-border-left">
                                <tbody className="listborder">
                                  <tr>
                                    <td
                                      key={index}
                                      style={{ padding: 2 }}
                                      className={'removerightborder removeleftborder'}
                                    >
                                      <Input
                                        value={item.speedOutLen}
                                        onChange={(e) =>
                                          measureTypeListDataChange(
                                            e.target.value,
                                            key,
                                            item.no,
                                            index,
                                            'SpeedOutLen',
                                            false
                                          )
                                        }
                                        onBlur={(e) =>
                                          measureTypeListDataChange(e.target.value, key, item.no, index, 'SpeedOutLen')
                                        }
                                        onPressEnter={(e) =>
                                          measureTypeListDataChange(e.target.value, key, item.no, index, 'SpeedOutLen')
                                        }
                                        style={{
                                          padding: 3,
                                          width: 293,
                                          height: 26,
                                          textAlign: 'center',
                                        }}
                                        className={editMode ? 'input-editable ' : 'input-non-editable'}
                                        onKeyPress={(e) => {
                                          handleKeyPress(e, false);
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    {item.roundHoleDiaThItems?.map((length, index) => (
                                      <>
                                        <td
                                          key={index}
                                          style={{ padding: 2 }}
                                          className={
                                            index === 2 ? 'removerightborder removeleftborder' : 'removeleftborder'
                                          }
                                        >
                                          <Input
                                            value={length.value}
                                            onChange={(e) =>
                                              measureTypeListDataChange(
                                                e.target.value,
                                                key,
                                                item.no,
                                                index,
                                                'RoundHoleDia',
                                                false
                                              )
                                            }
                                            onBlur={(e) =>
                                              measureTypeListDataChange(
                                                e.target.value,
                                                key,
                                                item.no,
                                                index,
                                                'RoundHoleDia'
                                              )
                                            }
                                            onPressEnter={(e) =>
                                              measureTypeListDataChange(
                                                e.target.value,
                                                key,
                                                item.no,
                                                index,
                                                'RoundHoleDia'
                                              )
                                            }
                                            onKeyPress={(e) => {
                                              handleKeyPress(e, false);
                                            }}
                                            style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                                            className={editMode ? 'input-editable ' : 'input-non-editable'}
                                          />
                                        </td>
                                      </>
                                    ))}
                                  </tr>
                                  <tr>
                                    {item.workLenThItems?.map((length, index) => (
                                      <>
                                        <td
                                          key={index}
                                          style={{ padding: 2 }}
                                          className={
                                            index === 2 ? 'removerightborder removeleftborder' : 'removeleftborder'
                                          }
                                        >
                                          <Input
                                            value={length.value}
                                            onChange={(e) =>
                                              measureTypeListDataChange(
                                                e.target.value,
                                                key,
                                                item.no,
                                                index,
                                                'Worklen',
                                                false
                                              )
                                            }
                                            onBlur={(e) =>
                                              measureTypeListDataChange(e.target.value, key, item.no, index, 'Worklen')
                                            }
                                            onPressEnter={(e) =>
                                              measureTypeListDataChange(e.target.value, key, item.no, index, 'Worklen')
                                            }
                                            style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                                            className={editMode ? 'input-editable ' : 'input-non-editable'}
                                            onKeyPress={(e) => {
                                              handleKeyPress(e, false);
                                            }}
                                          />
                                        </td>
                                      </>
                                    ))}
                                  </tr>
                                  <tr>
                                    {item.lenThItems?.map((length, index) => (
                                      <>
                                        <td
                                          key={index}
                                          style={{ padding: 2 }}
                                          className={
                                            index === 2 ? 'removerightborder removeleftborder' : 'removeleftborder'
                                          }
                                        >
                                          <Input
                                            id={key + 'cls' + item.no + 'input' + index}
                                            value={length.value}
                                            onChange={(e) =>
                                              measureTypeListDataChange(
                                                e.target.value,
                                                key,
                                                item.no,
                                                index,
                                                'Len',
                                                false
                                              )
                                            }
                                            onBlur={(e) =>
                                              measureTypeListDataChange(e.target.value, key, item.no, index, 'Len')
                                            }
                                            onPressEnter={(e) =>
                                              measureTypeListDataChange(e.target.value, key, item.no, index, 'Len')
                                            }
                                            style={{
                                              padding: 3,
                                              width: '98.2%',
                                              height: 26,
                                              textAlign: 'center',
                                            }}
                                            className={editMode ? 'input-editable ' : 'input-non-editable'}
                                            onKeyPress={(e) => {
                                              handleKeyPress(e, false);
                                            }}
                                          />
                                        </td>
                                      </>
                                    ))}
                                  </tr>
                                </tbody>
                              </Table>
                            </Row>
                          </td>
                        </>
                      )}
                    </>
                  ))}
                </tr>
              ))}
          </Table>
        </Row>
        <Row className="chargeAmount mt-2">
          <Table style={{ width: '40%' }}>
            <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
              <tr style={{ textAlign: 'center', verticalAlign: 'middle', height: 30 }}>
                <th style={{ width: '20%' }} className="tbl-header">
                  ケガキ速度
                </th>
                <th style={{ width: '20%' }} className="tbl-header">
                  送り速度
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ textAlign: 'center' }}>
                <td>
                  <Input
                    value={kegakiSokudoVal}
                    onChange={(e) => kegakiSokudoValChange(e, false)}
                    onBlur={(e) => kegakiSokudoValChange(e)}
                    onPressEnter={(e) => kegakiSokudoValChange(e)}
                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                    onKeyPress={(e) => {
                      handleKeyPress(e, false);
                    }}
                  />
                </td>
                <td>
                  <Input
                    value={okuriSokudoVal}
                    onChange={(e) => okuriSokudoValChange(e, false)}
                    onBlur={(e) => okuriSokudoValChange(e)}
                    onPressEnter={(e) => okuriSokudoValChange(e)}
                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                    onKeyPress={(e) => {
                      handleKeyPress(e, false);
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </Row>
      </div>

      {/* 板厚 */}
      {isItaatsu
        ? commonModal(
            isItaatsu,
            addThicknessModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addItaatsuContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default Device_Blank_Kakousokudo;
