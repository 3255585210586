/**
 * クラス名：案件状態パラメータ
 * 説明：案件状態情報詳細画面
 * 作成者：ケイ
 * 作成日：2023/05/01
 * バージョン：1.0
 */
import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Radio, Image, DatePicker, Input, ColorPicker } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {
  confirmDiscardContent,
  confirmUpdateContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  error,
} from '../../../common/CommonModal';
import { today, TextArea, formatDate, isDigit, formValidatorMode } from '../../../common/Common';
import checked from '../../../../assets/images/checked.png';
import { ErrorMessage } from '../../../common/Message';
import { getIsExistCodeProjectStatusName } from '../../../common/CommonAPI';

const RadioGroup = Radio.Group;
dayjs.extend(customParseFormat);
const dateFormat = 'YYYY-MM-DD';

const ProjectStatusDetails = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    discardConfirmOk: discardConfirmOk,
    discardConfirmCancel: discardConfirmCancel,
    displayDetailInfo: displayDetailInfo,
    formValidation: formValidation,
  }));

  const inputItemRef = {};

  const editMode = props.editMode;

  const [id, setID] = useState('');
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [isUsed, setIsUsed] = useState();
  const [remarks, setRemarks] = useState('');
  const [sortNum, setSortNum] = useState('');
  const [fontColor, setFontColor] = useState('');
  const [rowColor, setRowColor] = useState('');
  const [created, setCreated] = useState('');
  const [modified, setModified] = useState('');
  const [modifier, setModifier] = useState('');

  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isNoData, setIsNoData] = useState(false);

  // 選択した行の情報を設定
  useEffect(() => {
    let selectedRowData = props.selectedData;
    if (props.selectedData == null) {
      document.getElementById('edit').style.display = 'none';
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
      setIsNoData(true);
    } else if (props.selectedData?.length === 0) {
      // 一覧の検索結果が無い場合、
      resetDetailInfo();
      setIsNoData(true);
      props.updateEditMode(false);
    } else if (id !== selectedRowData?.id) {
      // 一覧の検索結果がある場合、
      displayDetailInfo(selectedRowData);
      setIsNoData(false);
      props.updateEditMode(false);
    }
  }, [props.selectedData]);

  // 編集モードより、「更新、破壊、編集」ボタンの表示・非表示を判断する
  useEffect(() => {
    if (props.editMode) {
      document.getElementById('edit').style.display = 'none';
      document.getElementById('update').style.display = 'block';
      document.getElementById('discard').style.display = 'block';
    } else {
      document.getElementById('edit').style.display = 'block';
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
    }
  }, [props.editMode]);

  // 詳細情報をリセットする。
  const resetDetailInfo = () => {
    setID('');
    setCode('');
    setName('');
    setIsUsed();
    setRemarks('');
    setSortNum('');
    setFontColor('');
    setRowColor('');
    setCreated('');
    setModified('');
    setModifier('');
  };

  // 詳細情報を表示する
  const displayDetailInfo = (data) => {
    setID(data.id);
    setCode(data.code);
    setName(data.name);
    setIsUsed(data.info.isUsed);
    setRemarks(data.info.remarks);
    setSortNum(data.info.sortNum);
    setFontColor(data.info.fontColor);
    setRowColor(data.info.rowColor);
    setCreated(data.created);
    setModified(data.modified);
    setModifier(data.modifier);
  };

  // 編集ボタンの押下
  const editData = () => {
    props.updateEditMode(true);
  };

  // 更新ボタンの押下
  const updateData = () => {
    setUpdateConfirm(props.showEdtConfirmOK);
    if (!props.showEdtConfirmOK) {
      updConfirmOk();
    }
    setIsUpdate(true);
  };

  // 画面の入力チェック
  const formValidation = async (inputItems, procMode) => {
    let ret = true;
    let message = [];
    // コード
    let input = inputItems['code']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('コード').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 名称
    input = inputItems['name']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('名称').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    let statusId = procMode === formValidatorMode.Create ? 0 : id;
    let code = inputItems['code']?.input?.value;
    let name = inputItems['name']?.input?.value;
    if (code !== '' && name !== '') {
      let isExistCodeName = await getIsExistCodeProjectStatusName(statusId, code, name);
      if (isExistCodeName) {
        message.push(<li>{ErrorMessage('コードと名称').E020}</li>);
        input = inputItems['code']?.input;
        input?.classList?.remove('input-error');
        input?.classList?.add('input-error');
        input = inputItems['name']?.input;
        input?.classList.add('input-error');
        ret = false;
      }
    }

    // ソート番号
    input = inputItems['sortNum']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length > 0 && !isDigit(input?.value)) {
      message.push(<li>{ErrorMessage('ソート番号').E009}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    if (message?.length > 0) error(<ul>{message}</ul>);

    return ret;
  };

  // 破壊ボタンの押下
  const discardData = () => {
    //setCancelConfirm(true);
    setCancelConfirm(props.showEdtConfirmCancle);
    if (!props.showEdtConfirmCancle) {
      discardConfirmOk();
    }
  };

  // 更新確認ポップアップのOKボタン押下
  const updConfirmOk = async (e) => {
    setUpdateConfirm(false);
    // 画面の入力チェック
    if (!(await formValidation(inputItemRef, formValidatorMode.Edit))) {
      return;
    }
    if (isUpdate) {
      props.updateEditMode(false);
      setIsUpdate(false);
      submitData();
    }
  };

  // 更新確認ポップアップのCancelボタン押下
  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  // 破壊確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    props.updateEditMode(false);
    displayDetailInfo(props.selectedData);
    setCancelConfirm(false);
  };

  // 破壊確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setCancelConfirm(false);
  };

  // 入力された情報を更新する
  const submitData = () => {
    if (props.selectedData && props.selectedData.id === id) {
      props.selectedData.code = code;
      props.selectedData.name = name;
      props.selectedData.info.isUsed = isUsed;
      props.selectedData.info.remarks = remarks;
      props.selectedData.info.sortNum = sortNum;
      props.selectedData.info.fontColor = fontColor;
      props.selectedData.info.rowColor = rowColor;
      props.selectedData.modified = today;
      props.submitData(props.selectedData);
    }
  };

  return (
    <>
      <div className="d-flex" style={{ marginTop: 9, marginRight: 10 }}>
        <div>
          <Button
            className="mainButton"
            style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
            id="update"
            onClick={updateData}
          >
            更新
          </Button>
        </div>
        <div>
          <Button
            className="cancelButton"
            style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
            id="discard"
            onClick={discardData}
          >
            破棄
          </Button>
        </div>
        <div className="ms-auto">
          <Button className={isNoData ? 'disabledButton' : 'mainButton'} id="edit" onClick={editData}>
            編集
          </Button>
        </div>
      </div>

      <div className="overflow-auto" style={{ height: 760, marginTop: 10 }}>
        <div id="showData">
          <Table id="param_detail_tbl" className="propertiesTbl">
            <thead>
              {/* コード */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">コード</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="code"
                    id="code"
                    value={code}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['code'] = ref;
                    }}
                    maxLength={50}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </td>
              </tr>
              {/* 名称 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">名称</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="name"
                    id="name"
                    value={name}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['name'] = ref;
                    }}
                    maxLength={50}
                    onChange={(e) => setName(e.target.value)}
                  />
                </td>
              </tr>
              {/* ソート番号 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">ソート番号</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="sortNum"
                    id="sortNum"
                    value={sortNum}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['sortNum'] = ref;
                    }}
                    maxLength={Number.MAX_SAFE_INTEGER}
                    onBlur={(e) => setSortNum(props?.onChangeSortNum(e.target.value))}
                    onPressEnter={(e) => setSortNum(props?.onChangeSortNum(e.target.value))}
                    onChange={(e) => setSortNum(props?.onChangeSortNum(e.target.value, false))}
                  />
                </td>
              </tr>
              {/* フォントカラー */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">フォントカラー</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <ColorPicker
                    rootClassName={editMode ? 'input-editable' : 'input-non-editable'}
                    value={fontColor}
                    onChangeComplete={(value) => {
                      setFontColor(value.toHexString());
                    }}
                  />
                </td>
              </tr>
              {/* 親部品リストカラー */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">親部品リストカラー</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <ColorPicker
                    rootClassName={editMode ? 'input-editable' : 'input-non-editable'}
                    value={rowColor}
                    onChangeComplete={(value) => {
                      setRowColor(value.toHexString());
                    }}
                  />
                </td>
              </tr>
              {/* 作成日 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">作成日</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label className="param_detail-title-label">{created ? formatDate(created) : ''}</label>
                </td>
              </tr>
              {/* 更新日 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">更新日</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label className="param_detail-title-label">{modified ? formatDate(modified) : ''}</label>
                </td>
              </tr>
              {/* 更新者 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">更新者</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label className="param_detail-title-label">{modifier}</label>
                </td>
              </tr>
              {/* 備考 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">備考</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <TextArea
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="remarks"
                    id="remarks"
                    value={remarks}
                    type="text"
                    maxLength={2048}
                    style={{ margin: 5, padding: 5, width: '98.3%' }}
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                </td>
              </tr>
              {/* 表示 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">表示</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <RadioGroup
                      id="isUsed"
                      name="isUsed"
                      defaultValue={isUsed}
                      value={isUsed}
                      className="radioCustomerGpEdit"
                      onChange={(e) => setIsUsed(e.target.value)}
                    >
                      <Radio value={true}>オン</Radio>
                      <Radio value={false}>オフ</Radio>
                    </RadioGroup>
                  ) : isUsed ? (
                    <Image preview={false} width={13} src={checked} id="isUsed" />
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            </thead>
          </Table>
          <div>
            {/* 更新確認_Modal */}
            {updateConfirm
              ? commonModal(
                  updateConfirm,
                  confirmModalTitle,
                  commonFooter(updConfirmOk, updConfirmCancel),
                  null,
                  400,
                  updConfirmOk,
                  updConfirmCancel,
                  confirmUpdateContent,
                  null,
                  null,
                  false
                )
              : ''}
            {/* 破棄確認_Modal */}
            {cancelConfirm
              ? commonModal(
                  cancelConfirm,
                  confirmModalTitle,
                  commonFooter(discardConfirmOk, discardConfirmCancel),
                  null,
                  400,
                  discardConfirmOk,
                  discardConfirmCancel,
                  confirmDiscardContent,
                  null,
                  null,
                  false
                )
              : ''}
          </div>
        </div>
      </div>
    </>
  );
});

export default ProjectStatusDetails;
